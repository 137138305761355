<template>
  <div class="w-full">
    <div
      class="fw-full lg:h-full rounded-b-sm overflow-visible px-0 md:px-0 flex min-h-80 flex-col lg:flex-row gap-0 lg:items-stretch"
      :key="`${presentationTemplateId}`"
    >
      <!-- Logo Container -->
      <div
        class="relative basis-full shrink min-w-shrink h-auto lg:h-auto flex flex-col justify-between rounded-bl overflow-hidden grow"
      >
        <div
          :class="[
            'absolute inset-0 pointer-events-none',
            {
              ' bg-surface-0': blendHero === 'light',
              ' bg-surface-900': blendHero === 'dark'
            }
          ]"
          v-if="headingFileId"
        >
          <div
            :style="headingStyle"
            :class="[
              'absolute inset-0 pointer-events-none',
              {
                'opacity-40 ': blendHero === 'light',
                'opacity-70 filter brightness-75 contrast-75 ': blendHero === 'dark'
              }
            ]"
          ></div>
        </div>

        <PresentationAlter
          position="right"
          tooltip="Change hero image"
          v-if="!isInPresentation"
          v-model="headingFileId"
          :actions="
            headingFileId
              ? [
                  {
                    icon: 'brightness',
                    tooltip: 'Brighten background',
                    class: {
                      'opacity-50': blendHero !== 'light',
                      'bg-deep-red-400': blendHero === 'light'
                    },
                    action: () => {
                      blendHero = blendHero === 'light' ? 0 : 'light'
                    }
                  },
                  {
                    icon: 'moon',
                    tooltip: 'Darken background',
                    class: {
                      'opacity-50': blendHero !== 'dark',
                      'bg-deep-red-400': blendHero === 'dark'
                    },
                    action: () => {
                      blendHero = blendHero === 'dark' ? 0 : 'dark'
                    }
                  }
                ]
              : []
          "
        />

        <!-- Logo -->
        <div class="relative m-4 w-fit">
          <template v-if="logoUrl">
            <img
              :class="[
                'rounded max-w-[300px] xl:max-w-[400px] max-h-80',
                {
                  'filter contrast-200 brightness-90 mix-blend-multiply': blendLogo
                }
              ]"
              :src="logoUrl"
              alt="Company Logo"
            />
          </template>
          <template v-else>
            <div
              class="text-4xl font-medium bg-surface-200/80 px-4 py-1 text-left rounded leading-none text-5xl text-surface-900 w-fit"
            >
              {{ companyName }}
            </div>
          </template>

          <PresentationAlter
            tooltip="Change logo"
            v-if="!isInPresentation"
            v-model="logoFileId"
            :actions="
              logoFileId
                ? [
                    {
                      icon: 'wand-magic-sparkles',
                      tooltip: 'Fade logo into background',
                      class: {
                        'opacity-50': !blendLogo,
                        'bg-deep-red-400': blendLogo
                      },
                      action: () => {
                        blendLogo = blendLogo ? 0 : 1
                      }
                    }
                  ]
                : []
            "
          />
        </div>
      </div>

      <!-- Project Details Container -->
      <div
        :class="[
          'xl:basis-[30%] min-w-[300px] shrink grow h-auto relative p-6 bg-flame-white/80 shadow flex flex-col gap-4 justify-between',
          {
            'rounded-br-sm': heroUrlFinal,
            'rounded-b-sm': !heroUrlFinal
          }
        ]"
      >
        <!--     Project information     -->
        <div class="w-full flex flex-col justify-start gap-6 max-w-full">
          <div
            class="flex flex-row xl:flex-col justify-between xl:justify-start items-start gap-6 max-w-full overflow-hidden"
          >
            <div class="flex items-center gap-2 shrink basis-1/2 xl:basis-auto">
              <div class="flex items-center justify-center w-8 h-8 bg-surface-100 rounded-full">
                <font-awesome-icon icon="house-flag" fixed-width class="text-base" />
              </div>
              <div class="flex flex-col">
                <span class="text-sm !text-surface-400 leading-tight">
                  {{ l('Project') }}
                </span>
                <span class="text-lg text-pitch-back font-medium leading-tight">
                  {{ quote_name }}
                </span>
                <span
                  v-if="quote_suite || quote_address"
                  class="text-lg text-pitch-back leading-tight"
                >
                  {{ quote_suite }}
                  {{ quote_address }}
                </span>
                <span v-if="quote_city && quote_prov" class="text-lg text-pitch-back leading-tight">
                  {{ quote_city }}
                  {{ quote_prov }}
                  {{ quote_postal }}
                </span>
              </div>
            </div>

            <div
              v-if="quote_time_expired > 0"
              class="items-center gap-6 basis-1/2 xl:basis-auto flex"
            >
              <div
                class="flex items-center justify-center w-8 h-8 bg-surface-100 lg:hidden rounded-full"
              >
                <font-awesome-icon icon="calendar" fixed-width class="text-base" />
              </div>
              <div class="flex flex-col w-full justify-start">
                <span class="text-sm !text-surface-500">
                  {{ l('Expiration date') }}
                </span>
                <span class="text-lg text-pitch-back">
                  {{ $f.date(quote_time_expired) }}
                </span>
              </div>
            </div>
            <div v-else class="items-center gap-2 basis-1/2 xl:basis-auto flex">
              <div class="flex items-center justify-center w-8 h-8 bg-surface-100 rounded-full">
                <font-awesome-icon icon="calendar" fixed-width class="text-base" />
              </div>
              <div class="flex flex-col">
                <span class="text-sm !text-surface-400 leading-tight">
                  {{ l('Estimate') }}
                </span>
                <span class="text-lg text-pitch-back font-medium leading-tight">
                  {{ $f.date(change_order_time_sent || quote_time_last_modified) }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex items-center gap-2">
            <div class="flex items-start justify-center w-8 h-8 bg-surface-100 rounded-full">
              <Avatar
                :image="userPic"
                shape="circle"
                size="normal"
                class="border border-cool-gray-300"
              />
            </div>
            <div v-if="showPresenter" class="!relative flex flex-col">
              <div
                link
                @click="openChat"
                size="sm"
                class="flex-col !item-start text-left flex justify-center"
              >
                <span class="text-sm !text-surface-400 leading-tight">
                  {{ l('Presented by') }}
                </span>
                <span class="text-lg text-pitch-back font-medium leading-tight">
                  {{ oOwner?.user_fname }} {{ oOwner?.user_lname }}
                </span>
                <div class="text-xs" v-if="!readOnly">
                  <font-awesome-icon icon="message" />
                  {{ l('Chat with me') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--    Expiration and contact      -->
        <!-- Credentials Section -->
        <div
          v-if="creds.length || !isInPresentation"
          class="flex flex-col gap-1 border-t border-surface-300/50 pt-2 mt-1"
        >
          <div
            v-for="(prop, index) in creds"
            :key="`${prop[0]}-${index}`"
            class="flex items-center justify-start py-1 px-0 rounded w-fit relative group"
          >
            <font-awesome-icon
              fixed-width
              icon="badge-check"
              :class="['text-lg !w-6 shrink-0', { 'group-hover:hidden': !isInPresentation }]"
            />
            <button
              v-if="!isInPresentation"
              class="hidden group-hover:block cursor-pointer"
              @click.stop.prevent="removeCredential(index)"
            >
              <font-awesome-icon icon="trash" fixed-width class="text-deep-red-400 !w-6 shrink-0" />
            </button>

            <div class="flex flex-col ml-1 gap-y-2">
              <StringField
                :classes="['text-xs font-medium leading-none -ml-0.5 !max-w-52 text-wrap']"
                :disabled="isInPresentation"
                :value="creds[index][0]"
                @input="(v) => setCred(index, 0, v)"
                placeholder="Credential name"
              />
              <StringField
                v-if="(prop[0] && prop[1]) || !isInPresentation"
                :classes="[
                  'text-sm text-pitch-back leading-none font-light -ml-0.5 -mt-1.5 !max-w-52 text-wrap'
                ]"
                :disabled="isInPresentation"
                :value="creds[index][1]"
                @input="(v) => setCred(index, 1, v)"
                placeholder="Credential ID or #"
              />
            </div>
          </div>

          <div
            @click="addCredential"
            class="bg-surface-200 rounded-sm flex justify-center items-center w-16 gap-2 cursor-pointer py-2 hover:bg-blue-print-400 hover:text-surface-0 !h-fit"
            v-tooltip="
              'Add credential, licence or insurance number? Your client won\'t see this button'
            "
          >
            <font-awesome-icon icon="badge-check" />
            <font-awesome-icon icon="plus" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineOptions } from 'vue'
import { usePresentationManager } from './usePresentationManager'
import StringField from '@/components/ui/Calculator/StringField.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import TranslationMixin from '@/components/quote/presentation/languages/TranslationMixin.js'
import PresentationAlter from '@/components/quote/presentation/PresentationAlter.vue'

defineOptions({
  mixins: [TranslationMixin]
})

// 🛠 Props
const props = defineProps({
  editable: {
    type: Boolean,
    default: true
  },
  refId: {
    required: true
  },
  store: {
    default: 'Quote'
  }
})

const {
  quote_name,
  quote_suite,
  quote_address,
  quote_city,
  quote_prov,
  quote_postal,
  quote_time_expired,
  change_order_time_sent,
  quote_time_last_modified,
  oOwner
} = EntityComputedFields.useEntityComputedFields({
  refId: props.refId,
  store: props.store,
  type: 'quote'
})

// 🏗️ Use the presentation manager composable
const {
  companyName,
  presentationTemplateId,
  creds,
  heroUrlFinal,
  blendHero,
  headingFileId,
  readOnly,
  showPresenter,
  openChat,
  isInPresentation,
  logoUrl,
  logoFileId,
  blendLogo,
  setCred,
  addCredential,
  removeCredential
} = usePresentationManager({
  refId: props.refId,
  store: props.store,
  autoSave: true
})

// 🖼️ Dynamic heading style
const headingStyle = computed(() => {
  const url = heroUrlFinal.value ? `url(${heroUrlFinal.value})` : 'transparent'
  return url
    ? {
        background: `${url} !important`,
        backgroundSize: 'cover !important',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center !important'
      }
    : {}
})
</script>
