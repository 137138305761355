import { enterSuperScope } from '@/router/guards/beforeEnter'
const SuperCompaniesLegacy = () => import('@/components/pages/SuperCompaniesLegacy.vue')
const SuperUsers = () => import('@/components/pages/SuperUsers.vue')
const SuperCompanies = () => import('@/components/pages/SuperCompanies.vue')
const SuperQuote = () => import('@/components/pages/SuperQuote.vue')
const SuperItems = () => import('@/components/pages/SuperItems.vue')
const SuperImport = () => import('@/components/pages/SuperImport.vue')
const SuperDimensions = () => import('@/components/pages/SuperDimensions.vue')
const SuperReports = () => import('@/components/pages/SuperReports.vue')
const SuperSettings = () => import('@/components/pages/SuperSettings.vue')
const SuperItemCopy = () => import('@/components/pages/SuperItemCopy.vue')
const Company = () => import('@/components/pages/Company.vue')
const User = () => import('@/components/pages/User.vue')
const SuperStripeProductMap = () => import('@/components/pages/SuperStripeProductMap.vue')
const SuperFeatureProduct = () => import('@/components/pages/SuperProducts.vue')

// Headers
const SuperQuoteHeader = () => import('@/components/headers/SuperQuoteHeader.vue')
const CompanyHeader = () => import('@/components/headers/CompanyHeader.vue')
const UserHeader = () => import('@/components/headers/UserHeader.vue')

// Menus
const SuperQuoteMenu = () => import('@/components/menus/SuperQuoteMenu.vue')
const CompanyMenu = () => import('@/components/menus/CompanyMenu.vue')
const UserMenu = () => import('@/components/menus/UserMenu.vue')

const meta = {
  scopesAllowed: ['user'],
  menuContext: 'super'
}

const metaWithFilters = {
  ...meta,
  permanentFilters: {
    company_id: 'NULL'
  }
}

export default [
  {
    path: '/super',
    beforeEnter: [enterSuperScope],
    children: [
      {
        path: 'companies',
        alias: ['/super'],
        name: 'Super Companies',
        component: SuperCompanies,
        meta
      },
      {
        path: 'companiesLegacy',
        alias: ['/companiesLegacy'],
        name: 'Super Companies Legacy',
        component: SuperCompaniesLegacy,
        meta
      },
      {
        path: 'company/:id',
        name: 'Edit Company',
        component: Company,
        meta: {
          confirmLeave: true,
          menu: CompanyMenu,
          header: CompanyHeader,
          type: 'company',
          ...meta
        }
      },
      {
        path: 'users',
        alias: ['/users'],
        name: 'Super Users',
        component: SuperUsers,
        meta
      },
      {
        path: '/:scopeRoute/user/:id',
        name: 'Edit User',
        component: User,
        meta: {
          confirmLeave: true,
          menu: UserMenu,
          header: UserHeader,
          type: 'user',
          ...meta
        }
      },
      {
        path: 'items',
        alias: ['/items'],
        name: 'Items',
        component: SuperItems,
        meta: metaWithFilters
      },
      {
        path: 'import',
        alias: ['/import', '/importitems'],
        name: 'Import',
        component: SuperImport,
        meta
      },
      {
        path: 'dimensions',
        alias: ['/dimensions'],
        name: 'Super Dimensions',
        component: SuperDimensions,
        meta: metaWithFilters
      },
      {
        path: 'report/:id?',
        alias: ['/report/:id?', '/reports/:id?'],
        name: 'Super Reports',
        component: SuperReports,
        meta
      },
      {
        path: '/super/stripe-products',
        alias: '/stripe-products',
        name: 'StripeProducts',
        component: SuperStripeProductMap,
        meta
      },
      {
        path: '/super/products',
        alias: '/products',
        name: 'SuperProducts',
        component: SuperFeatureProduct,
        meta
      },
      {
        path: 'settings',
        alias: ['/settings'],
        name: 'System Settings',
        component: SuperSettings,
        meta
      }
    ]
  },
  {
    path: '/super/quote',
    alias: ['/quote', '/project'],
    name: 'Super Quote',
    component: SuperQuote,
    props: {
      type: 'quote'
    },
    meta: {
      confirmLeave: true,
      ...meta,
      ...metaWithFilters,
      header: SuperQuoteHeader,
      menu: SuperQuoteMenu,
      type: 'quote'
    }
  },
  {
    path: '/copyitems',
    alias: ['/copyitems', '/copy'],
    name: 'Copy Items',
    component: SuperItemCopy,
    meta,
    beforeEnter: [enterSuperScope]
  }
]
