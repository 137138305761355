<script setup>
import { defineProps, onBeforeMount, ref, defineOptions, computed } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'
import CurrencyFilter from '@/components/mixins/CurrencyFilter.js'
import { useStore } from 'vuex'
import CostItemVariationSelector from '@/components/quote/item/CostItemVariationSelector.vue'
import TransitionExpand from '@/components/transitions/TransitionExpand.vue'
import FieldSetters from '@/components/composables/EntityFields/FieldSetters.js'
import CostItemHeader from '@/components/bodies/CostItem/Header.vue'
import ThumbGallery from '@/components/quote/presentation/ThumbGallery.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false, default: 'assembly' },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  audience: { type: String, default: null }
})

defineOptions({
  mixins: [CurrencyFilter]
})
// const $store = useStore()

const refId = ref(props.refId || props.reference)
const type = ref(props.type || 'assembly')
const store = ref(props.store || props.storeName)
const $store = useStore()

const { norm, selected, setFields } = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store
})

const refsToSelect = computed(() => {
  if (!norm.value[refId.value]?.parentRefId) {
    return NormalizeUtilities.sortNatural(norm.value)
  }
  return NormalizeUtilities.sortNatural(
    NormalizeUtilities.extractDescendants(norm.value, [refId.value], true)
  )
})
const refsWithSelections = computed(() =>
  refsToSelect.value.filter(
    (ref) =>
      hasSelection(norm.value[ref]) &&
      (!props.audience || getAudience(norm.value[ref]) === props.audience)
  )
)
const allRefsLocked = computed(
  () =>
    refsWithSelections.value.length > 0 &&
    refsWithSelections.value.every((ref) => norm.value[ref]?.oMeta?.lockOptions)
)
const refsByParent = computed(() =>
  refsWithSelections.value.reduce(
    (acc, ref) => ({
      ...acc,
      [norm.value[ref].parentRefId]: [...(acc[norm.value[ref].parentRefId] ?? []), ref]
    }),
    {}
  )
)
onBeforeMount(async () => {
  await selected
  await $store.dispatch(`${store.value}/recalcAddons`, {
    refIds: refsWithSelections.value,
    loading: false
  })
})

const hasSelection = (item) =>
  item.aoAddons.length ||
  item.cost_type_is_variation_parent ||
  item.cost_item_is_optional ||
  item.assembly_is_optional
const getAudience = (item) => item.oMeta?.selectionAudience || 'client'

const toggleLock = (ref, force) => {
  setFields(
    {
      oMeta: {
        ...(norm.value[ref].oMeta || {}),
        lockOptions: force !== undefined ? force : norm.value[ref].oMeta?.lockOptions ? 0 : 1
      }
    },
    ref
  )
}
const toggleOptional = async (ref) => {
  const field = `${norm.value[ref].type}_is_optional`
  setFields(
    {
      [field]: norm.value[ref][field] ? 0 : 1
    },
    ref
  )
}
const setIsIncluded = async (ref, is = 0) => {
  const field = `${norm.value[ref].type}_is_included`
  setFields(
    await FieldSetters[norm.value[ref].type][field]({
      $store,
      store: store.value,
      refId: ref,
      value: is
    }).changes,
    ref
  )
}
const editing = ref(null)
const swap = (ref) => {
  if (norm.value[ref].aoAddons?.length) {
    // swap for addon
  } else {
    // expand for selections
    editing.value = ref === editing.value ? null : ref
  }
}

const selectAddon = (ref, index) => {
  const addon = norm.value?.[ref]?.aoAddons[index]
  if (addon) {
    return $store.dispatch(`${store.value}/selectAddon`, {
      addonId: addon.id ?? addon.livePriceRef,
      addonType: addon.livePriceRef ? 'live_price' : addon.type,
      refId: ref
    })
  }
}

const getOptionGroupName = (ref) => {
  const obj = norm.value[ref]
  if (obj.oMeta?.optionGroupName) {
    return obj.oMeta?.optionGroupName
  }
  for (const addon of obj.aoAddons) {
    if (addon.bulk && addon.bulk.oMeta?.optionGroupName) {
      return addon.bulk.oMeta?.optionGroupName
    }
  }
  return null
}

const setOptionGroupName = (ref, val) => {
  setFields(
    {
      oMeta: {
        ...(norm.value[ref].oMeta || {}),
        optionGroupName: val
      }
    },
    ref
  )
}

const genLink = (...args) => c.link(...args)
const scope = c.getStorage('scope')
const refItemEditor = ref(null)
const editingRefId = ref(null)
const editingItemType = ref(null)
const edit = (ref) => {
  editingRefId.value = ref
  editingItemType.value = 'cost_item'
  refItemEditor.value.open()
}

const makeArray = c.makeArray

const hasUnlockedSelections = () =>
  refsWithSelections.value.some((ref) => !norm.value[ref]?.oMeta?.lockOptions)

const toggleLockAll = () => {
  const lockOptions = hasUnlockedSelections() ? 1 : 0
  const changes = refsWithSelections.value.reduce((acc, ref) => {
    ;((acc[ref] ??= {}).oMeta ??= {}).lockOptions = lockOptions
    return acc
  }, {})
  $store.dispatch(`${store.value}/field`, {
    changes,
    explicit: true,
    skipAudit: true,
    skipLocalAudit: true
  })
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <slot name="empty" v-if="!Object.keys(refsByParent).length">
      <p class="text-xl font-medium rounded px-4 py-4 bg-surface-200/50">
        No {{ audience === 'company' ? 'internal' : 'client' }} selections
      </p>
    </slot>

    <div v-if="Object.keys(refsByParent).length" class="flex justify-start py-2">
      <Btn
        link
        size="sm"
        class="!text-surface-700"
        @click="toggleLockAll"
        v-tooltip="allRefsLocked ? 'Unlock all selections' : 'Lock all selections'"
      >
        <span class="flex items-center gap-2">
          <font-awesome-icon :icon="['far', allRefsLocked ? 'lock-open' : 'lock']" />
          {{ allRefsLocked ? 'Unlock All' : 'Lock All' }}
        </span>
      </Btn>
    </div>

    <div
      v-for="(refs, parentRef) in refsByParent"
      :key="parentRef"
      class="flex flex-col overflow-auto"
    >
      <div
        class="relative gap-2 min-w-[30rem] flex flex-nowrap overflow-hidden font-normal px-2 py-0.5 rounded-t-sm bg-pitch-black text-white text-lg select-none cursor-pointer items-center"
      >
        <div>
          {{ norm[parentRef].asAssemblyPath[norm[parentRef].asAssemblyPath.length - 1] || 'Root' }}
        </div>
        <div
          class="text-xs absolute inset-0 opacity-0 hover:opacity-100 w-full h-full flex justify-start items-center gap-2 px-2 py-0.5 bg-pitch-black"
        >
          <div class="leading-none">Project root</div>
          <div
            v-for="segment in norm[parentRef].asAssemblyPath"
            :key="segment"
            class="flex justify-start items-center gap-2"
          >
            <font-awesome-icon icon="arrow-right" />
            <p class="leading-none">{{ segment }}</p>
          </div>
        </div>
      </div>

      <div
        class="grid min-w-[30rem] grid-cols-11 border-b bg-surface-100 gap-2 w-full overflow-auto leading-tight py-2 text-surface-500 font-medium items-end"
      >
        <span class="col-span-3 text-xs px-2">Selections group name</span>
        <span class="col-span-1 text-xs leading-tight px-2">Lock selection</span>
        <span class="col-span-1 text-xs px-2">Optional</span>
        <span class="col-span-1 text-xs px-2"></span>
        <span class="col-span-4 text-xs px-2">Selected item</span>
        <span class="col-span-1 text-xs px-2">Price</span>
      </div>
      <div class="flex flex-col divide-y divide-surface-300 min-w-[30rem] w-full">
        <div
          v-for="ref in refs"
          :key="ref"
          class="flex flex-col w-full overflow-hidden group"
          :class="[
            '',
            {
              'bg-surface-200/50 rounded-md !border-t-transparent': ref === editing
            }
          ]"
        >
          <div class="grid grid-cols-11 gap-2 p-2 items-center w-full overflow-hidden">
            <div
              class="col-span-3 text-sm text-ellipsis line-clamp-1 flex justify-start items-center gap-2 relative"
            >
              <StringField
                placeholder="Set selection name, ie: 'Kitchen faucet selection', 'Fence material' etc"
                :value="
                  getOptionGroupName(ref) ||
                  norm[ref]?.variation_parent_cost_type_name ||
                  norm[ref]?.cost_type_name ||
                  norm[ref]?.assembly_name
                "
                @input="(val) => setOptionGroupName(ref, val)"
              />
              <Btn
                link
                class="!text-blue-print !absolute !right-0 !bg-white group-hover:flex hidden"
                size="sm"
                :action="() => edit(ref)"
              >
                Edit full item <font-awesome-icon icon="arrow-up-right" />
              </Btn>
            </div>
            <div class="col-span-1">
              <Btn
                link
                rounded
                size="xl"
                :class="{ '!text-surface-300': !norm[ref]?.oMeta?.lockOptions }"
                @click="toggleLock(ref)"
                v-tooltip="
                  norm[ref]?.oMeta?.lockOptions
                    ? 'Selection is locked - click to allow client changes'
                    : 'Selection is unlocked - click to prevent client changes'
                "
              >
                <font-awesome-icon
                  :icon="['far', norm[ref]?.oMeta?.lockOptions ? 'lock' : 'lock-open']"
                />
              </Btn>
            </div>
            <div class="col-span-1 flex items-center">
              <Btn
                v-if="!(norm[ref]?.assembly_is_optional || norm[ref]?.cost_item_is_optional)"
                link
                size="sm"
                class="!text-surface-500"
                @click="toggleOptional(ref)"
                v-tooltip="
                  norm[ref]?.assembly_is_optional || norm[ref]?.cost_item_is_optional
                    ? 'Item is optional'
                    : 'Item is not optional'
                "
              >
                Make optional?
              </Btn>
              <div class="flex justify-start items-center gap-1 text-xs pl-2" v-else>
                <Checkbox
                  :value="norm[ref]?.cost_item_is_included || norm[ref]?.assembly_is_included || 0"
                  @input="(val) => setIsIncluded(ref, val)"
                  size="xl"
                />
                <Btn
                  link
                  class="!pl-1"
                  size="sm"
                  @click="
                    setIsIncluded(
                      ref,
                      norm[ref]?.cost_item_is_included || norm[ref]?.assembly_is_included ? 0 : 1
                    )
                  "
                >
                  {{
                    norm[ref]?.cost_item_is_included || norm[ref]?.assembly_is_included
                      ? 'Included'
                      : 'Excluded'
                  }}
                </Btn>
              </div>
            </div>
            <p class="col-span-1 text-xs"></p>
            <div class="col-span-4 text-right flex justify-start items-center gap-2">
              <template v-if="norm[ref]?.file_ids?.length">
                <ThumbGallery :fileIds="makeArray(norm[ref]?.file_ids)" :numVisible="1" />
              </template>
              <div
                class="w-12 h-12 rounded-sm bg-surface-100 flex justify-center items-center"
                v-else
              >
                <font-awesome-icon icon="swatchbook" />
              </div>

              <template
                v-if="
                  (norm[ref]?.cost_item_is_included || norm[ref]?.assembly_is_included) &&
                  norm[ref]?.quantity_multiplier > 0.01 &&
                  (norm[ref]?.quote_qty_net >= 0.01 || norm[ref]?.cost_item_qty_net >= 0.01)
                "
              >
                <!-- selection for addons, show name -->
                <Choose
                  class="w-2/3 min-w-40"
                  :class="{ 'bg-surface-200': norm[ref]?.oMeta?.lockOptions }"
                  v-if="norm[ref]?.aoAddons?.length"
                  :static-set="
                    norm[ref]?.aoAddons.map((addon, index) => ({
                      value: index,
                      text: `${addon.name} - ${$$(addon.price)}`,
                      html: `<div class='flex justify-between items-center w-full gap-4'><div class='text-md leading-tight'>${addon.name}</div> <div class='font-medium'>${$$(addon.price)}</div></div>`
                    }))
                  "
                  @input="(index) => selectAddon(ref, index)"
                  :return-array="false"
                  :default="null"
                  :disabled="norm[ref]?.oMeta?.lockOptions"
                >
                  <div
                    class="hover:bg-surface-200 cursor-pointer line-clamp-1 border border-pitch-black rounded-sm px-2 py-1 leading-tight text-sm flex justify-between text-left items-center gap-2 w-full"
                  >
                    <p class="line-clamp-1">
                      {{ norm[ref].cost_type_name || norm[ref].assembly_name }}
                    </p>
                    <font-awesome-icon icon="chevron-down" />
                  </div>
                </Choose>
                <div
                  v-else-if="norm[ref]?.oVariations?.variationTypes?.length"
                  @click="swap(ref)"
                  class="hover:bg-surface-200 w-2/3 min-w-40 cursor-pointer border border-pitch-black rounded-sm px-2 py-1 text-sm"
                  :class="{ '!bg-black text-flame-white': editing === ref }"
                >
                  <div class="flex justify-between items-center gap-2 select-none">
                    <p class="line-clamp-1">
                      {{
                        norm[ref].cost_type_name ||
                        norm[ref].oVariations?.selectedItem?.name ||
                        norm[ref].assembly_name
                      }}
                    </p>
                    <font-awesome-icon icon="chevron-down" />
                  </div>
                </div>
                <div
                  v-else-if="norm[ref]?.cost_item_is_optional"
                  class="flex justify-start items-center gap-1"
                >
                  <font-awesome-icon icon="fas fa-circle" class="text-blue-print-400" />
                  Optional&nbsp;•&nbsp;Included
                </div>
              </template>
              <div
                v-else
                class="flex justify-start items-center gap-1"
                v-tooltip="
                  'This item has a zero quantity, which will affect the price. It could also be a result of a parent assembly having a zero quantity.'
                "
              >
                <font-awesome-icon icon="fas fa-circle" class="text-deep-red-400" />
                Excluded
              </div>
            </div>
            <div class="col-span-1 text-md text-right flex justify-end items-center gap-1">
              <div
                class="cursor-help"
                v-tooltip="
                  'This item has a zero quantity, which will affect the price. It could also be a result of a parent assembly having a zero quantity.'
                "
                v-if="
                  (norm[ref]?.type === 'assembly' && norm[ref]?.quote_qty_net < 0.01) ||
                  (norm[ref]?.type === 'cost_item' && norm[ref]?.cost_item_qty_net < 0.01)
                "
              >
                <font-awesome-icon icon="warning" class="text-deep-red-400" />
              </div>
              {{ $$(norm[ref]?.quote_subtotal_net || norm[ref]?.cost_item_price_net) }}
            </div>
          </div>

          <TransitionExpand>
            <div
              v-if="editing === ref"
              class="border-t border-surface-800 py-4 flex justify-end items-start px-12 gap-12"
            >
              <div class="max-w-[450px] flex flex-col gap-2">
                <!--                <div v-if="norm[ref]?.oMeta?.optionGroupName" class="text-2xl">-->
                <!--                  {{ norm[ref]?.oMeta?.optionGroupName }}-->
                <!--                </div>-->
                <!--                <div v-if="norm[ref]?.oMeta?.optionGroupDesc" class="text-sm leading-tight">-->
                <!--                  {{ norm[ref]?.oMeta?.optionGroupDesc }}-->
                <!--                </div>-->
                <!--                <template v-if="norm[ref]?.cost_type_desc">-->
                <!--                  <p class="font-light">{{ norm[ref]?.cost_type_desc }}</p>-->
                <!--                </template>-->
                <!--                <div class="mt-4" v-if="norm[ref]?.aoProperties?.length">-->
                <!--                  <PresentationProperties :object="norm[ref]" />-->
                <!--                </div>-->

                <template v-if="norm[ref]?.file_ids?.length">
                  <Galleria
                    :images="
                      norm[ref]?.file_ids.map((id) => genLink(`file/view/${id}`, {}, true, scope))
                    "
                    numVisible="6"
                    thumbnailsPosition="bottom"
                    hideArrows="true"
                    :pt="{
                      thumbnailContainer: 'w-fit pt-2',
                      thumbnailItem: 'overflow-hidden pr-2'
                    }"
                  />
                </template>
              </div>
              <CostItemVariationSelector
                :object="norm[ref]"
                :store="store"
                :refId="ref"
                :show-highlights="false"
                :reference="ref"
                :editable="true"
                :scroll="false"
                :focusOnMount="false"
                :key="`var-var-${ref}`"
                :upgrading-allowed="true"
                :commitInterval="100"
              />
            </div>
          </TransitionExpand>
        </div>
      </div>
    </div>
    <MiniModal size="sm" :width="500" scrollable ref="refItemEditor">
      <template #header>
        <CostItemHeader
          v-if="editingRefId"
          :type="editingItemType"
          :store="store"
          :refId="editingRefId"
          :key="editingRefId"
        />
      </template>
      <CostItem
        v-if="editingRefId && editingItemType !== 'assembly'"
        :store="store"
        :refId="editingRefId"
        :key="editingRefId"
        :show-title="false"
      />
      <AssemblyBody
        v-else-if="editingRefId && editingItemType === 'assembly'"
        :store="store"
        :refId="editingRefId"
        :key="editingRefId"
        ref="assemblyEditor"
        :show-title="false"
        :show-info="false"
        :show-contents="false"
      />
    </MiniModal>
  </div>
</template>

<style scoped lang="scss"></style>
