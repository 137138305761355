<template>
  <card-section class="p-0">
    <Info
      v-if="
        payoutInterval === 'regular' && counterparty.counterparty_status === 'a' && isPayfacEnabled
      "
      class="mb-4 info"
      :flat="true"
    >
      <div class="flex items-center w-100">
        You qualify for faster settlement times. Go to the
        <a
          href="#"
          @click.prevent="$emit('navigate-to-settings', 'settlement-time')"
          class="text-blue-print-500 hover:text-blue-print-600 underline mx-1"
          >settlement time
        </a>
        setting to switch.
      </div>
    </Info>
    <CounterpartyBalanceCards :balances="defaultBalance" :loading="loading" />
    <TabView
      @on-select="(tab) => onSelect('paymentCurrentTab', tab)"
      class="min-h-[300px]"
      :tabs="paymentTabs"
      :fill="true"
      :default="paymentCurrentTab"
    >
      <TabPanel :header="isPayfacEnabled ? 'Payment' : 'Attempts'">
        <TransactionList
          v-if="balanceAccountIsReady"
          :reload="reload"
          defaultFilter="succeeded"
          :filters="paymentFilters"
          type="intent"
          :balanceAccount="defaultBalanceAccount"
        />
      </TabPanel>
      <TabPanel header="Charges" v-if="!isPayfacEnabled">
        <TransactionList
          v-if="balanceAccountIsReady"
          :reload="reload"
          defaultFilter="succeeded"
          :filters="chargeFilters"
          type="charge"
          :balanceAccount="defaultBalanceAccount"
        />
      </TabPanel>
      <TabPanel header="Payouts">
        <TransactionList
          v-if="balanceAccountIsReady"
          :reload="reload"
          defaultFilter="paid"
          :filters="payoutFilters"
          type="payout"
          :balanceAccount="defaultBalanceAccount"
        />
      </TabPanel>
    </TabView>

    <MiniModal :scrollable="true" :show-close-button="false" ref="payoutModal">
      <CounterpartyPayout
        v-if="balances"
        :type="type"
        @successful="successfulPayout"
        @close="$refs.payoutModal.close()"
        :accounts="accounts"
        :balances="balances"
      ></CounterpartyPayout>
    </MiniModal>

    <MiniModal :scrollable="true" :show-close-button="false" ref="thirdPartyTransferModal">
      <ThirdPartyBankTransfer
        v-if="balances"
        :type="type"
        :payoutInterval="payoutInterval"
        @successful="successfulThirdPartyTransfer"
        @close="$refs.thirdPartyTransferModal.close()"
        :accounts="accounts"
        :balances="balances"
      ></ThirdPartyBankTransfer>
    </MiniModal>
  </card-section>
</template>

<script>
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import CounterpartyMixin from '../CounterpartyMixin'
import TransactionList from '../../transaction/TransactionList.vue'
import CounterpartyPayout from './CounterpartyPayout.vue'
import CounterpartyBalanceCards from './CounterpartyBalanceCards.vue'
import ThirdPartyBankTransfer from './ThirdPartyBankTransfer.vue'
import PayfacMixin from '../../PayfacMixin'

export default {
  name: 'CounterpartyActivity',
  props: {
    owners: {
      type: Array,
      default: () => []
    },
    accounts: {
      type: Array,
      default: () => []
    },
    merchant: {
      type: Object
    },
    counterparty: {
      type: Object
    },
    type: {
      type: String,
      default: 'bank_account',
      validator: (value) => ['bank_account', 'business_account'].includes(value)
    },
    balances: {
      type: Array,
      default: () => []
    },
    balancesLoading: {
      type: Boolean,
      default: true
    },
    defaultBalance: {
      type: Object,
      default: () => ({})
    },
    defaultBalanceAccount: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      payouts: null,
      payments: null,
      payoutCurrentTab: 'paid',
      paymentCurrentTab: 'intent',
      autoPayouts: this.merchant && this.merchant.settings.payout_interval !== 'manual',
      payoutInterval: this.merchant.settings.payout_speed || 'regular',
      reload: false
    }
  },
  methods: {
    /**
     * When selecting a payment tab
     * @param {String} section
     * @param {String} tab
     */
    onSelect(section, tab) {
      this[section] = tab.key
    },
    /**
     * Open payout modal
     */
    createPayout() {
      this.$refs.payoutModal.open()
    },
    /**
     * After payout is successful reload account balances
     */
    async successfulPayout() {
      this.reload = true
      this.$emit('reload-balances')
      this.$refs.payoutModal.close()
      this.reload = false
    },
    /**
     * Load more transactions
     * @param {String} type
     * @param {String} ref
     * @param {String|null} status
     */
    async loadMore(type, ref, status = null) {
      const current = this[ref]
      if (!current || !current.data) return
      const lastItem = current.data.slice(-1).pop()
      const startingAfter = lastItem.id
      this[ref].loading = true
      const { payload } = await this.fetchTransactions({
        type,
        starting_after: startingAfter
      })
      this[ref] = {
        ...current,
        has_more: payload.has_more,
        data: [...current.data, ...payload.data],
        loading: false,
        ...(status ? { status } : {})
      }
    },
    createThirdPartyTransfer() {
      this.$refs.thirdPartyTransferModal.open()
    },
    async successfulThirdPartyTransfer() {
      this.reload = true
      this.$emit('reload-balances')
      this.$refs.thirdPartyTransferModal.close()
      this.reload = false
    },
    handleAccountsUpdated(updatedAccounts) {
      this.$emit('accounts-updated', updatedAccounts)
    }
  },
  computed: {
    balanceAccountIsReady() {
      return !!this.defaultBalanceAccount
    },
    /**
     * The payout schedule interval
     */
    interval() {
      return this.merchant.settings.payout_interval
    },
    /**
     * Available payment tabs
     */
    paymentTabs() {
      let tabs = [
        {
          key: 'intent',
          title: 'Attempts'
        },
        {
          key: 'charge',
          title: 'Charges'
        },
        {
          key: 'payout',
          title: 'Payouts'
        }
        // {
        //   key: 'businessAccountStatements',
        //   title: 'Business Account Statements'
        // }
      ]
      if (this.isPayfacEnabled) {
        tabs = [
          {
            key: 'intent',
            title: 'Payments'
          },
          {
            key: 'payout',
            title: 'Payouts'
          }
          // {
          //   key: 'businessAccountStatements',
          //   title: 'Business Account Statements'
          // }
        ]
      }
      return tabs.map((tab) => {
        const tabData = tab
        if (this[tab.key] && this[tab.key].data) {
          tabData.title = `${tab.title} (${this[tab.key].data.length}${this[tab.key].has_more ? '+' : ''})`
        }
        return tabData
      })
    },
    /**
     * Available payout tabs
     */
    payoutTabs() {
      const tabs = [
        {
          key: 'paid',
          title: 'Successful'
        },
        {
          key: 'payout',
          title: 'Needs attention'
        }
      ]
      return tabs.map((tab) => {
        const tabData = tab
        if (this[tab.key] && this[tab.key].data) {
          tabData.title = `${tab.title} (${this[tab.key].data.length}${this[tab.key].has_more ? '+' : ''})`
        }
        return tabData
      })
    },
    settlementTimes() {
      return [
        {
          text: 'Regular',
          value: 'regular'
        },
        {
          text: 'Fast',
          value: 'fast'
        },
        ...(this.counterpartyCountry === 'us'
          ? [
              {
                text: 'Faster',
                value: 'instant'
              }
            ]
          : [])
      ]
    },
    paymentFilters() {
      if (this.isPayfacEnabled) return []
      return [
        { text: 'Canceled', value: 'canceled' },
        { text: 'Processing', value: 'processing' },
        { text: 'Requires Confirmation', value: 'requires_action' },
        { text: 'Requires Action', value: 'requires_confirmation' },
        { text: 'Requires Payment Method', value: 'requires_payment_method' },
        { text: 'Requires Capture', value: 'requires_capture' },
        { text: 'Succeeded', value: 'succeeded' }
      ]
    },
    chargeFilters() {
      return [
        { text: 'Failed', value: 'failed' },
        { text: 'Pending', value: 'pending' },
        { text: 'Succeeded', value: 'succeeded' }
      ]
    },
    payoutFilters() {
      if (this.isPayfacEnabled) return []
      return [
        { text: 'Paid', value: 'paid' },
        { text: 'Pending', value: 'pending' },
        { text: 'Failed', value: 'failed' },
        { text: 'Canceled', value: 'canceled' }
      ]
    },
    settlementDate() {
      if (this.autoPayouts) {
        return this.payoutScheduleDate
      }
      return null
    }
  },
  components: {
    TransactionList,
    CounterpartyPayout,
    CounterpartyBalanceCards,
    ThirdPartyBankTransfer,
    TabView,
    TabPanel
  },
  mixins: [CounterpartyMixin, PayfacMixin]
}
</script>
