<template>
  <ActivityItemLayout :activity="activity" @onReply="onReply">
    <div
      class="activity-channel-item--message bg-surface-100 rounded-3xl px-4 py-2 flex-1 h-max overflow-x-clip"
    >
      <div
        class="activity-channel-item--message--header w-full flex justify-between items-center cursor-pointer"
        @click="routeToTarget"
      >
        <div class="flex items-center mr-2">
          <font-awesome-icon
            :icon="['fas', 'code-compare']"
            class="text-sm mr-2"
          ></font-awesome-icon>
          <p
            class="max-w-full text-xs block break-words mr-2"
            v-html="`${activityMessage} <b>${targetName ?? ''}</b>`"
          ></p>
          <p
            v-if="showChanges"
            class="text-secondary text-xs cursor-pointer hover:underline flex"
            @click="toggleShowDetails"
          >
            {{ showDetails ? 'Hide details' : 'Show details' }}
            <ProgressSpinner
              v-if="showDetailsLoading"
              :pt="{ root: 'h-4 w-4', spinner: 'w-full h-full' }"
            />
          </p>
        </div>
        <small
          class="activity-channel-item--message--header--time text-xs font-normal text-surface-300 h-max"
        >
          {{ formattedTimeCreatedHour }}
        </small>
      </div>
      <TransitionExpand>
        <change-audit
          v-if="showDetails"
          class="mt-2 text-xs"
          :changes="denormalizedChanges"
          :type="targetType"
        />
      </TransitionExpand>
    </div>
  </ActivityItemLayout>
</template>

<script setup>
import { nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import useActivityItem from '@/components/composables/ActivityItem'
import ActivityItemLayout from '@/components/Activities/ActivityItemType/ActivityItemLayout.vue'
import TransitionExpand from '@/components/transitions/TransitionExpand.vue'
import ProgressSpinner from 'primevue/progressspinner'

// ======== Props ======== //
const props = defineProps({
  activity: {
    type: Object,
    required: true
  },
  prevActivity: {
    type: Object,
    required: true
  }
})
// ====================== //

// ======== Composables ======== //
const store = useStore()
const {
  targetType,
  targetTypeId,
  targetName,
  activityMessage,
  formattedTimeCreatedHour,
  denormalizedChanges,
  showChanges,
  fetchedOTarget
} = useActivityItem(props)
// ====================== //

// ======== Emits ======== //
const emit = defineEmits(['onReply'])
// ====================== //

// ======== Data ======== //
const showDetails = ref(false)
const showDetailsLoading = ref(false)
const detailsFetched = ref(false)
// ====================== //

// ======== Methods ======== //
const onReply = () => {
  emit('onReply')
}
const toggleShowDetails = async () => {
  if (targetType.value === 'change_order' && !detailsFetched.value) {
    try {
      showDetailsLoading.value = true
      const { payload } = await store.dispatch('ChangeOrder/fetch', {
        quick: true,
        force: true,
        id: targetTypeId.value
      })

      console.log({
        aoFullChanges: payload.aoFullChanges,
        aoExplicitChanges: payload.aoExplicitChanges,
        oNormalized: payload.oNormalized
      })

      fetchedOTarget.value = {
        aoFullChanges: payload.aoFullChanges,
        aoExplicitChanges: payload.aoExplicitChanges,
        oNormalized: payload.oNormalized
      }
      await nextTick()
      detailsFetched.value = true
    } catch (err) {
      console.error(err)
      return
    } finally {
      showDetailsLoading.value = false
    }
  }
  showDetails.value = !showDetails.value
}
const routeToTarget = async () => {
  const type = targetType.value.toLowerCase()
  const typeId = targetTypeId.value

  await store.dispatch('go', {
    object: {
      type: type,
      [`${type}_id`]: typeId
    }
  })
}
// ====================== //
</script>

<style scoped></style>
