<template>
  <PageHeader :title="title" :full="type === 'business_account'">
    <template #left>
      <div class="flex flex-col xl:justify-between xl:flex-row">
        <div>
          <div
            v-if="merchant?.status && merchant.status !== 'Active'"
            class="flex flex-row items-center"
          >
            <Badge
              :class="['ml-[-0.75em] px-2', getStatusClass(merchant.status)]"
              :value="merchant.status"
            />
          </div>
        </div>
      </div>
    </template>
    <template #right>
      <div class="flex justify-end">
        <btn-group v-if="showActionButtons" style="margin-left: auto">
          <btn
            :disabled="bodyLoading || autoPayouts"
            @click="$emit('withdraw-money')"
            severity="primary-black"
          >
            <font-awesome-icon :icon="['far', 'dollar-sign']" />
            Transfer to account
          </btn>
          <btn
            size="md"
            :disabled="bodyLoading || autoPayouts"
            @click="$emit('send-to-bank')"
            severity="primary-black"
          >
            <font-awesome-icon :icon="['far', 'arrow-right-arrow-left']" />
            Transfer funds
          </btn>
        </btn-group>
      </div>
    </template>
  </PageHeader>
</template>

<script setup>
import { computed } from 'vue'
import PageHeader from '@/components/layout/page/PageHeader.vue'
import Badge from 'primevue/badge'

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['payments', 'business_account'].includes(value)
  },
  merchant: {
    type: Object,
    required: true
  },
  counterparty: {
    type: Object,
    required: true
  },
  bodyLoading: {
    type: Boolean,
    required: true
  },
  autoPayouts: {
    type: Boolean,
    default: false
  },
  activeTabIndex: {
    type: Number,
    default: 0
  },
  chargesEnabledForBusinessAccount: {
    type: Boolean,
    default: false
  }
})

defineEmits(['withdraw-money', 'send-to-bank'])

const title = props.type === 'business_account' ? 'Business Accounts' : 'Payments'

const showActionButtons = computed(() => {
  if (props.type === 'business_account') {
    return (
      props.activeTabIndex === 0 &&
      (props.counterparty.counterparty_status === 'a' || props.chargesEnabledForBusinessAccount)
    )
  }
  return props.counterparty.counterparty_status === 'a'
})

const getStatusClass = (status) => {
  let className = 'bg-dark'
  switch (status.toLowerCase()) {
    case 'active':
      className = '!bg-matcha-500'
      break
    case 'restricted':
      className = '!bg-deep-red-500'
      break
    case 'charges enabled':
      className = '!bg-yellow-700'
      break
    case 'pending payment':
      className = '!bg-yellow-700'
      break
    default:
      className = '!bg-surface-500'
  }
  return className
}
</script>
