<template>
  <div v-if="activeTab.includes('settings')" icon="gear">
    <Warning :closable="false" class="mt-4">
      <div class="flex flex-col md:flex-row justify-between w-full">
        <div>
          <h2 class="!text-orange-700">Password reset</h2>
          <p>
            <span class="badge warning" v-if="user_is_using_temp_pass"
              >User is using a temporary password</span
            >
            <span class="badge success" v-else>User has updated their password</span>
          </p>
        </div>
        <btn-group>
          <btn
            severity="warning"
            size="lg"
            class="mt-4 md:mt-0 md:ml-8"
            @click="() => $store.dispatch('User/resetPassword', { id: user_id })"
          >
            Reset password
          </btn>
        </btn-group>
      </div>
    </Warning>

    <user-edit :key="refId" :reference="refId" />

    <card-section v-if="showSuperSettings">
      <card>
        <template #title>User status</template>
        <template #icon>
          <font-awesome-icon icon="user" />
        </template>

        <p>
          <span class="badge success" v-if="user_status === 'a'"> User is currently active </span>
          <span class="badge danger" v-else> User is currently inactive </span>
        </p>

        <btn-group>
          <btn
            severity="tertiary"
            class="mt-4"
            v-if="user_status === 'a'"
            :action="() => mark('i')"
          >
            Make user inactive
          </btn>
          <btn severity="tertiary" class="mt-4" v-else :action="() => mark('a')">
            Make user active
          </btn>
        </btn-group>
      </card>
    </card-section>

    <card-section v-if="$store.state.session.user.user_is_super_user">
      <template #title>Companies</template>

      <card-list>
        <card-list-item v-for="company in aoCompanies" :key="company.company_id">
          <div class="flex justify-start items-center" style="width: 100%">
            <btn
              rounded
              severity="tertiary"
              size="sm"
              class="more px-0 p-2 danger mr-2"
              :action="() => removeFromCompany(company.company_id)"
            >
              <font-awesome-icon icon="minus" />
            </btn>

            <span class="mr-3">
              {{ company.company_name || company.company_name_short || 'No name' }}
              <span class="badge warning sm ml-2" v-if="company.user_is_admin"> Admin </span>
            </span>

            <btn-group style="margin-left: auto">
              <btn
                severity="tertiary"
                size="sm"
                v-if="!company.user_is_admin"
                :action="() => toggleAdmin(company)"
              >
                Grant admin privileges
              </btn>
              <btn severity="warning" size="sm" v-else :action="() => toggleAdmin(company)">
                Remove admin privileges
              </btn>
            </btn-group>
          </div>
        </card-list-item>
      </card-list>

      <div class="mt-2 pl-3">
        <choose
          :return-array="false"
          @input="(id) => addToCompany(id)"
          :filters="companyFilters"
          :value="[]"
          allowDeselect="true"
          :allowCreate="false"
          schema="company:company_id"
        >
          <btn severity="primary-black" :stop="false" :prevent="false">
            <template #icon>
              <font-awesome-icon icon="plus" />
            </template>
            Add user to company
          </btn>
        </choose>
      </div>
    </card-section>
  </div>

  <div
    v-if="$store.state.session.scope.company && activeTab.includes('pipeline')"
    style="padding: 0"
    class="full column fixed-layout"
    title="Pipeline"
    iconFontFamily="FaBrands"
    icon="stack-overflow"
  >
    <pipeline
      :isMain="false"
      :filters="{
        pipeline_owner: `user-${user_id}`
      }"
      :showToolbar="false"
      :startColumn="0"
    />
  </div>

  <div v-if="$store.state.session.scope.company && activeTab.includes('rating')">
    <div class="w-full max-w-6xl">
      <contractor-ratings
        v-if="user_id && user_status !== 'l'"
        :isMain="false"
        :contractorId="user_id"
        :showToolbar="false"
        :startColumn="2"
      />
    </div>
  </div>

  <div v-if="$store.state.session.scope.company && activeTab.includes('permissions')">
    <permission-list
      v-if="
        $store.state.session.user?.aUserPerms?.user_permission?.modify > 1 || $store.getters.inSuper
      "
      :permissions="permissions"
      permissionType="user"
      @saved="reload(true)"
      :reference="refId"
      :key="refId"
    />
  </div>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import PermissionList from '../Permissions/PermissionList.vue'
import Pipeline from './Pipeline.vue'
import UserEdit from './UserEdit.vue'
import ContractorRatings from '@/components/bodies/ContractorRatings.vue'
import eventBus from '../../eventBus'

export default {
  name: 'User',
  mixins: [ObjectManipulator('user', true, 'User'), BodyMixin],
  emits: ['saved'],
  data() {
    return {
      startingTab: this.$store.state.session.scope.company ? ['Pipeline'] : ['Settings'],
      loaded: false,
      editing: false
    }
  },
  watch: {},
  computed: {
    activeTab() {
      const rtq = this.$route.query && this.$route.query.tab
      if (rtq) return c.makeArray(rtq)
      return ['settings']
    },
    showSuperSettings() {
      return (
        this.$store.state.session.user.user_is_super_user &&
        !this.$store.state.session.company.user_is_super_user
      )
    },
    companyFilters() {
      return {
        company_id: `!${this.aoCompanies.map((co) => co.company_id).join('&&!')}`
      }
    }
  },
  methods: {
    async toggleAdmin(company) {
      const newAdmin = company.user_is_admin ? 0 : 1
      const method = !newAdmin ? 'removeAdmin' : 'grantAdmin'

      try {
        await this.$store.dispatch(`User/${method}`, {
          id: this.user_id,
          company: company.company_id
        })

        this.$emit('saved')

        this.reload()

        this.$store.dispatch('alert', {
          message: company.user_is_admin
            ? 'That user has its admin privileges revoked'
            : 'That user has been made admin',
          success: true
        })

        return true
      } catch (e) {
        this.$store.dispatch('alert', { message: e.userMessage, error: true })
      }

      return false
    },
    async removeFromCompany(id) {
      await this.$store.dispatch('Company/removeUser', {
        id,
        user: this.user_id
      })

      this.reload()

      return true
    },
    async addToCompany(id) {
      await this.$store.dispatch('Company/addUser', {
        id,
        user: this.user_id
      })

      this.reload()

      return true
    },
    objectForRef(ref) {
      return this.$store.state.User.normalized[ref]
    }
  },
  components: {
    PermissionList,
    Pipeline,
    UserEdit,
    ContractorRatings
  },
  props: {},
  mounted() {
    eventBus.$on(`${this.uid}-selected`, () => {
      this.loaded = true
    })
  },
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
// $tableColor: darken(dodgerblue, 40%);

.content {
  border: 1px solid lightgray;
  // color: $tableColor;
  border-radius: 10px;
  .text-primary {
    cursor: pointer;
  }
}
</style>
