<template>
  <section class="counterparty-activity">
    <CounterpartyActivity
      v-if="merchant"
      ref="activity"
      :merchant="merchant"
      :counterparty="counterparty"
      :accounts="accounts"
      :owners="owners"
      :balances="balances"
      :balancesLoading="balancesLoading"
      :defaultBalance="defaultBalance"
      :defaultBalanceAccount="defaultBalanceAccount"
      type="bank_account"
      @reload-balances="loadBalances"
    />
  </section>
</template>

<script>
import CounterpartyMixin from '../CounterpartyMixin'
import CounterpartyActivity from './CounterpartyActivity.vue'

export default {
  name: 'CounterpartyActivities',
  props: {
    merchant: {
      type: Object
    },
    counterparty: {
      type: Object
    },
    accounts: {
      type: Array
    },
    owners: {
      type: Array
    },
    balances: {
      type: Array,
      default: () => []
    },
    balancesLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      creatingAccount: false,
      loading: false,
      mappedTransactions: null
    }
  },
  computed: {
    /**
     * Get the default balance for bank accounts
     */
    defaultBalance() {
      const payload = this.balances.filter(
        (account) => account.virtual_account_type === 'bank_account'
      )
      return payload.length > 0 ? payload[0] : {}
    },
    /**
     * Get the default balance account token
     */
    defaultBalanceAccount() {
      return this.defaultBalance ? this.defaultBalance.virtual_account_external_token : ''
    }
  },
  components: {
    CounterpartyActivity
  },
  mixins: [CounterpartyMixin],
  methods: {
    handleAccountsUpdated(updatedAccounts) {
      this.$emit('accounts-updated', updatedAccounts)
    },
    /**
     * Load the balances to display
     */
    async loadBalances() {
      try {
        const { payload } = await this.fetchBalances()
        this.$emit('balances-updated', payload)
      } catch (error) {
        console.error('Error loading balances:', error)
      }
    },
    /**
     * Open the payout modal
     */
    openPayoutModal() {
      this.$refs.activity?.$refs.payoutModal.open()
    },
    /**
     * Open the third party transfer modal
     */
    openThirdPartyTransferModal() {
      this.$refs.activity?.$refs.thirdPartyTransferModal.open()
    },
    getTransactionStatus(transaction) {
      if (!transaction.status) return ''

      if (transaction.type === 'payment' && transaction.status === 'pending') {
        return 'Processing'
      }

      if (transaction.type === 'payout' && transaction.status === 'available') {
        return 'Deposited'
      }

      return transaction.status.replaceAll('_', ' ')
    },
    getStatusClass(status) {
      const statusLower = (status || '').toLowerCase()
      const statusMap = {
        available: 'bg-success',
        succeeded: 'bg-success',
        completed: 'bg-success',
        paid: 'bg-success',
        approved: 'bg-success',
        deposited: 'bg-success',
        processing: 'bg-level-yellow',
        pending: 'bg-level-yellow',
        verified: 'bg-level-yellow',
        intransit: 'bg-level-yellow',
        inprogress: 'bg-level-yellow',
        cancelled: 'bg-warning',
        expired: 'bg-warning',
        declined: 'bg-danger',
        failed: 'bg-danger'
      }

      return statusMap[statusLower] || 'bg-dark'
    },
    formatAmount(amount, type) {
      if (!this.isPayfacEnabled || type === 'payout') {
        return amount < 0 ? (amount * -1) / 100 : amount / 100
      }
      return amount
    },
    calculateFees(transaction) {
      const clientFee = transaction.fee_details?.[0]?.amount
        ? this.formatFeeAmount(transaction.fee_details[0].amount)
        : 0

      const applicationFee = transaction.application_fee_amount
        ? this.formatFeeAmount(transaction.application_fee_amount)
        : 0

      const serviceFee = this.formatFeeAmount(transaction.serviceFee || 0)

      return { clientFee, applicationFee, serviceFee }
    },
    formatFeeAmount(amount) {
      return this.isPayfacEnabled ? amount : amount / 100
    },
    mapTransactionData(transactions) {
      if (!transactions) return []

      return transactions.map((transaction) => {
        const displayStatus = this.getTransactionStatus(transaction)
        const statusClass = this.getStatusClass(displayStatus)
        const formattedAmount = this.formatAmount(transaction.amount, transaction.type)
        const { clientFee, applicationFee, serviceFee } = this.calculateFees(transaction)

        return {
          id: transaction.id,
          type: transaction.type || '',
          object: transaction.object || 'payment',
          amount: formattedAmount,
          rawAmount: transaction.amount,
          currency: transaction.currency,
          displayStatus,
          status: transaction.status || '',
          statusClass,
          created: transaction.created,
          metadata: transaction.metadata || {},
          clientFee,
          applicationFee,
          serviceFee,
          failure_message: transaction.failure_message,
          next_action: transaction.next_action,
          // Balance account specific fields
          balancePlatform: transaction.balancePlatform,
          accountHolder: transaction.accountHolder,
          balanceAccount: transaction.balanceAccount,
          transferId: transaction.transferId,
          transactionId: transaction.transactionId,
          category: transaction.category,
          bookingDate: transaction.bookingDate,
          bookingDateTimeZone: transaction.bookingDateTimeZone,
          valueDate: transaction.valueDate,
          valueDateTimeZone: transaction.valueDateTimeZone,
          reference: transaction.reference,
          counterpartyIban: transaction.counterpartyIban,
          counterpartyBic: transaction.counterpartyBic,
          counterpartyAccountNumber: transaction.counterpartyAccountNumber,
          arrival_date: transaction.arrival_date,
          priority: transaction.priority || 'regular'
        }
      })
    },
    async fetchAndMapTransactions() {
      try {
        this.loading = true
        const { payload } = await this.fetchTransactions({
          type: 'payment',
          order: 'transaction_time_created desc',
          balanceAccount: this.defaultBalanceAccount
        })
        this.mappedTransactions = this.mapTransactionData(payload.data)
      } catch (error) {
        console.error('Error fetching transactions:', error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.fetchAndMapTransactions()
  },
  watch: {
    defaultBalanceAccount() {
      this.fetchAndMapTransactions()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.counterparty-activity {
  .card-list-item {
    display: flex !important;
    flex-direction: row !important;
  }
  .loader {
    background: $cool-gray-400;
    width: 100%;
    height: 5rem;
  }
}
</style>
