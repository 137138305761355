import { ref, computed, watch, onMounted, nextTick, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import eventBus from '@/eventBus'
import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import Utilities from '../../../../imports/api/Changes/Utilities.js'
const { mergeChanges } = Utilities

export function usePresentationManager({ refId, store, autoSave = false }) {
  const $store = useStore()

  const { presentation_template_id } = EntityComputedFields.useEntityComputedFields({
    store,
    refId,
    type: 'quote'
  })
  const uid = _.uniqueId('pm')
  const norm = computed(() => $store.state[store].normalized)
  const object = computed(() => norm.value[refId])
  // to set presentationTemplateId you need to use EntityComputedFields composable. This is read only
  const presentationTemplateId = computed({
    get: () => presentation_template_id.value,
    set: (v) => {
      presentation_template_id.value = v
    }
  })
  watch(presentationTemplateId, (is, was) => {
    if (is && is !== was) loadTemplate(is)
  })

  const reviewer = computed(() =>
    object.value.aoReviewers?.find((r) => `${r.user_id}` === `${$store.state.session.user.user_id}`)
  )
  const isReviewer = computed(() => !!reviewer.value)
  const isClient = computed(
    () =>
      String($store.state.session.user?.user_id ?? '') === String(object.value.client_user_id ?? '')
  )
  const isSuperUser = computed(() => $store.state.session.user.user_is_super_user)
  const readOnly = computed(() => {
    if (isSuperUser.value || isClient.value) return false
    if (!isReviewer.value) return true

    const permissions =
      reviewer.value.asPermissions && JSON.parse(String(reviewer.value.asPermissions))
    return permissions?.includes('read')
  })

  // 📊 Reactive State
  const autoSavePresentation = ref(autoSave)
  const slidesEnabled = ref(0)
  const screenIndex = ref(0)
  const unsavedChanges = ref(0)
  const templateFull = ref(null)

  const intermittable = ref({})
  const oPresentationSettings = computed(() => ({
    ...(norm.value?.[refId]?.oPresentationSettings ?? {}),
    ...intermittable.value // for optimistic viewing
  }))
  const presentationSettings = computed({
    get: () => ({
      ...oPresentationSettings.value,
      ...intermittable.value
    }),
    set: async (newSettings) => {
      intermittable.value = {
        ...intermittable.value,
        ...newSettings
      }
      const oPresentationSettings = { ...newSettings }
      // sync to quote, throttle to collapse to single call regardless of how many
      // components implement this composable
      await c.throttle(
        async () =>
          await $store.dispatch(`${store}/field`, {
            changes: {
              [refId]: {
                oPresentationSettings
              }
            },
            skipLocalAudit: true,
            skipAudit: true,
            explicit: true,
            delay: 0
          }),
        { delay: 400, key: JSON.stringify(oPresentationSettings) }
      )
      intermittable.value = {}
    }
  })

  // 🏢 Company Info
  const companyLogoId = computed(() => $store.state.session?.company?.company_logo_file_id || null)
  const companyLogoUrl = computed(() =>
    companyLogoId.value
      ? c.link(`file/view/${companyLogoId.value}`, {}, true, _.getStorage('scope'))
      : null
  )
  const companyName = computed(() => $store.state.session?.company?.company_name || null)

  // 📄 Current Screen
  const currentScreen = computed(() => presentationSettings.value.screens?.[screenIndex.value])

  // 📦 Files and Assets
  const combinedFiles = computed(() => [
    ...c.makeArray(presentationSettings.value.plan_file_ids),
    ...c.makeArray(presentationSettings.value.file_ids),
    ...c.makeArray(presentationSettings.value.alwaysIncludedFileIds)
  ])

  // 📝 Editable Settings
  const editableSettings = (key, defaultValue = null, proccessor = (v) => v) => ({
    get: () =>
      _.imm(
        presentationSettings.value[key] ??
          presentationSettings.value.screens?.[0][key] ?? // backwards compatibility
          defaultValue
      ),
    set: (value) => {
      const prev =
        presentationSettings.value[key] ??
        presentationSettings.value.screens?.[0][key] ?? // backwards compatibility
        defaultValue

      if (prev === value) return // no change

      const ps = { ...presentationSettings.value }
      if (ps.screens?.[0]?.[key]) {
        // deprecated, remove from screens
        delete ps.screens[0][key]
      }
      presentationSettings.value = { ...ps, [key]: proccessor(value) }
      unsavedChanges.value = 1
    }
  })

  const backgroundFileId = computed(editableSettings('backgroundFileId'))
  const backgroundUrlFinal = computed(() => {
    if (!backgroundFileId.value) return null
    return c.link(
      `file/view/${backgroundFileId.value}`,
      { max: _.viewPortSize(), width: _.viewPortSize() },
      true,
      _.getStorage('scope')
    )
  })
  const blendBg = computed(editableSettings('blendBg', 0))

  const headingFileId = computed(editableSettings('headingFileId'))
  const heroUrlFinal = computed(() => {
    if (!headingFileId.value) return null
    return c.link(
      `file/view/${headingFileId.value}`,
      { max: 900, width: 900 },
      true,
      _.getStorage('scope')
    )
  })
  const blendHero = computed(editableSettings('blendHero', 0))
  const cleanAndEscape = (html) => {
    return c.sanitize(html, ['figure', 'oembed'], ['url'])
  }
  const defaultCoverLetter = `<p><strong>Hello {client_name},</strong></p><p>&nbsp;</p><p>Thank you for allowing us to provide you this estimate. This new platform we use allows you to select from multiple options right from this estimate. Please review the items, make your selections below, then approve the estimate to proceed when you are ready. &nbsp;</p><p>&nbsp;</p><p><strong>Approval:&nbsp;</strong></p><ul><li>When approving this proposal, you will be prompted to enter you preferred payment method. We recommend virtual check for convenience, and we offer it for free. Once we review our schedule we can give you further details about the start date if you haven't been given one already.</li></ul><p>&nbsp;</p><p><strong>After approval:</strong></p><p>After approval you will be provided with your own portal to view your signed documents, selections and review the scope of the project at any time. You will also be able to approve any changes, request changes, track progress and review the schedule.</p><p>&nbsp;</p><p><strong>Communication:</strong></p><p>The best way to communicate is through the built-in chat through your project portal. This allows us to make sure nothing is missed and also that someone gets back to your questions or concerns promptly.</p><p>&nbsp;</p><p>We look forward to providing you excellent service and workmanship on this project.</p><p>&nbsp;</p><p>Sincerely,</p><p><i>{estimator}</i></p>`
  const showCosts = computed(editableSettings('showCosts', 0))
  const showQuantities = computed(editableSettings('showQuantities', 1))
  const showAssemblyPrices = computed(editableSettings('showAssemblyPrices', 1))
  const showCostItemPrices = computed(editableSettings('showCostItemPrices', 1))
  const showItemizedPrices = computed(editableSettings('showItemizedPrices', 1))
  const showPresenter = computed(editableSettings('showPresenter', 1))
  const alwaysIncludedFileIds = computed(editableSettings('alwaysIncludedFileIds'))
  const termsAndConditions = computed(editableSettings('termsAndConditions'))
  const coverLetter = computed(editableSettings('coverLetter', '', cleanAndEscape))
  const defaultChangeOrderMessage = computed(editableSettings('defaultChangeOrderMessage'))
  const assemblyInitialState = computed(editableSettings('assemblyInitialState'))
  const creds = computed(editableSettings('props', []))
  const badges = computed(editableSettings('badges', []))
  const showItemSpecificTax = computed(editableSettings('showItemSpecificTax', 0))
  const badgeUrls = computed(() =>
    badges.value.map((id) =>
      c.link(`file/view/${id}`, { max: 200, size: 200 }, c.getStorage('scope'))
    )
  )
  const logoFileId = computed(editableSettings('logoFileId'))
  const blendLogo = computed(editableSettings('blendLogo', 0))

  const logoUrl = computed(() =>
    logoFileId.value
      ? c.link(`file/view/${logoFileId.value}`, {}, true, _.getStorage('scope'))
      : null
  )

  const isInPresentation = computed(() => $store.getters.isGuestUser)

  // 🔍 Watchers
  watch(unsavedChanges, (has) => {
    if (!templateFull.value || !autoSavePresentation.value || !has) return
    unsavedChanges.value = 0
    c.throttle(() => saveTemplate(null, null, false), {
      delay: 700,
      key: templateFull.value.template_id
    })
  })

  // 💾 Template Management
  async function saveTemplate(template = null, message = 'Template saved!', alert = true) {
    unsavedChanges.value = 0
    const payload = await $store.dispatch('Template/save', {
      object: template || getTemplate(),
      go: false,
      alert: false
    })
    const {
      object: { template_id: templateId }
    } = payload
    presentation_template_id.value = templateId //  in case it changed
    if (alert) $store.dispatch('alert', { message })
    await nextTick()
    return payload
  }

  const saveTemplateAsNew = async () => {
    const name = await $store.dispatch('modal/prompt', {
      message: 'What do you want to call your new presentation?'
    })

    if (!name) return false

    const template = {
      ...getTemplate(),
      template_name: name,
      template_id: null,
      template_type_id: 101
    }

    const { object } = await saveTemplate(
      template,
      'Created new presentation, and set this project to use it.'
    )
    await setTemplateValues(object)
  }

  function getTemplate() {
    return _.imm({
      ...(templateFull.value || {}),
      type: 'template',
      template_id: presentationTemplateId.value,
      oMeta: presentationSettings.value
    })
  }

  const loading = ref(0)
  async function loadTemplate(id) {
    if (!id || $store.getters.isGuestUser) return
    loading.value = 1

    // collapse this if it happens multoiple times into one call
    const { object } = await c.throttle(
      async () => await $store.dispatch('Template/fetch', { id }),
      { key: id, delay: 100 }
    )
    await setTemplateValues(object)
    loading.value = 0
  }

  async function setTemplateValues(obj) {
    const presentationSettingsValue = await defaultPresentationSettings(obj.oMeta)
    templateFull.value = { ...obj, oMeta: presentationSettingsValue }
    presentationSettings.value = presentationSettingsValue
  }

  // when other instances of this composable used in different components, save (and trigger the event)
  // then this will synchronize the updated template value among all components
  const templateUpdatedHandler = (obj, updatedUid) => {
    if (uid !== updatedUid) setTemplateValues(obj)
  }

  async function defaultPresentationSettings(meta) {
    const { object: obj } = await $store.dispatch('Quote/buildDefaultObject', {
      embue: { oPresentationSettings: meta }
    })
    return obj.oPresentationSettings
  }

  // 📚 Event Listeners
  onMounted(() => {
    if (presentationTemplateId.value) loadTemplate(presentationTemplateId.value)
  })

  const openChat = () => {
    if (readOnly.value) return
    return $store.commit({
      type: 'SET_PREVIEW',
      preview: {
        channel_type: 'QUOTE_CLIENT',
        channel_type_id: `${object.value.quote_id}-${object.value.client_user_id}`
      }
    })
  }

  const parseMessage = (msg) =>
    msg
      .replace('{quote_type}', object.value.quote_is_change_order ? 'change order' : 'proposal')
      .replace('{client_name}', object.value.oClient?.client_name)
      .replace('{quote_name}', object.value.quote_name)
      .replace('{quote_address}', object.value.quote_address)
      .replace(
        '{estimator}',
        `${object.value.oOwner?.user_fname} ${object.value.oOwner?.user_lname}`
      )
      .replace(
        '{pm}',
        (object.value.oProjectManager &&
          `${object.value.oProjectManager?.user_fname} ${object.value.oProjectManager?.user_lname}`) ||
          'To be determined'
      )
      .replace(
        '{designer}',
        (object.value.oDesigner &&
          `${object.value.oDesigner?.user_fname} ${object.value.oDesigner?.user_lname}`) ||
          'To be determined'
      )

  const processedCoverLetter = computed({
    get: () => c.sanitize(c.processOembedTags(parseMessage(coverLetter.value || '')))
  })
  const processedMessage = computed(() => parseMessage(object.value.change_order_message ?? ''))
  const isChangeOrder = computed(() => !!object.value.quote_is_change_order)

  const getChanges = async () => {
    const [, changes] = await $store.dispatch('ChangeOrder/getChanges', {
      id: object.value.change_order_id
    })

    const merged = mergeChanges(...changes)

    const getObj = (rr) => norm.value[rr]
    const vo = (rr) => {
      const obj = getObj(rr)
      return {
        ...(obj?.oViewOptions?.pres ?? {}),
        ...(obj?.oMeta?.viewOptions?.pres ?? {})
      }
    }
    const press = presentationSettings.value

    const showItem = (refId) => vo(refId).isVisible ?? true
    const showPrice = (refId) =>
      vo(refId).price ?? (press.showItemizedPrices && press.showCostItemPrices) ?? true
    const showQty = (refId) => vo(refId).qty ?? press.showQuantities

    // filter to only the tiems we want
    let objs = Object.values(merged).filter(
      (obj) => (obj.objectType === 'quote' || obj.objectType === 'cost_item') && showItem(obj.refId)
    ) // skip assemblies

    const fieldsToCheck = {
      quote_price_gross: 'Price',
      cost_item_price_net: 'Item price',
      cost_item_qty_net: 'Item quantity',
      cost_type_name: 'Item name',
      cost_type_desc: 'Item description',
      aoProperties: 'Item properties'
    }

    objs = objs.filter(
      (obj) => Object.keys(obj.fieldChanges).some((field) => field in fieldsToCheck) || obj.changed
    )

    objs = objs.map((obj) => {
      const sp = showPrice(obj.refId)
      const sq = showQty(obj.refId)
      obj.descriptiveChanges = {}

      if (obj.changed) {
        obj.descriptiveChanges.changed = `${c.ucfirst(obj.changed)}`
      }

      obj.descriptiveChanges = {
        ...obj.descriptiveChanges,
        ...Object.keys(fieldsToCheck)
          .filter((field) => field in obj.fieldChanges)
          .reduce((acc, field) => {
            const fc = obj.fieldChanges[field]

            if (field.includes('qty') && sq)
              acc[fieldsToCheck[field]] =
                `Quantity - from ${c.format(fc.from, 'number')} to ${c.format(fc.to, 'number')}`
            else if (field.includes('qty') && !sq)
              acc[fieldsToCheck[field]] =
                `Quantity - [${fc.from - fc.to > 0 ? 'arrow-down' : 'arrow-up'}]`

            if (field.includes('price') && sp)
              acc[fieldsToCheck[field]] =
                `Price - from ${c.format(fc.from, 'currency')} to ${c.format(fc.to, 'currency')}`
            else if (field.includes('price') && !sp)
              acc[fieldsToCheck[field]] =
                `Price - [${fc.from - fc.to > 0 ? 'arrow-down' : 'arrow-up'}]`

            if (field.includes('name'))
              acc[fieldsToCheck[field]] = `Item name - from "${fc.from}" to "${fc.to}"`

            return acc
          }, {})
      }

      return obj
    })

    return objs.reduce((acc, obj) => {
      acc[obj.refId] = obj
      return acc
    }, {})
  }

  const goToItem = async (ref) => {
    c.throttle(
      async () => {
        const ancestors = NormalizeUtilities.getAncestors(norm.value, [ref], false)

        // open up all parents first
        ancestors.reverse()
        await _.waterfall(
          ancestors.map((ref) => async () => {
            eventBus.$emit(`open-${ref}`)
            await c.throttle(() => {}, { delay: 50 })
          })
        )

        const element = document.querySelector(`#${ref}`)
        if (!element) return
        c.scrollTo(element)

        const allItems = document.querySelectorAll('.toc-scrollhighlight') // mark the elements with a unique class
        allItems.forEach((item) => {
          item.classList.remove('toc-scrollhighlight')
          item.classList.remove('!border-2')
          item.classList.remove('!border-pitch-black')
        })

        setTimeout(() => {
          element.classList.add('toc-scrollhighlight')
          element.classList.add('!border-2')
          element.classList.add('!border-pitch-black')
        }, 300)
      },
      { delay: 10, key: ref }
    )
  }

  onMounted(() => {
    eventBus.$on('go-to-item', goToItem)
  })
  onUnmounted(() => {
    eventBus.$off('go-to-item', goToItem)
  })

  const setCred = (index, valueIndex, value) => {
    const cr = [...creds.value]
    cr[index][valueIndex] = value
    creds.value = cr
  }
  const addCredential = () => {
    const cr = [...creds.value]
    cr.push(['Credential name', 'License #/membership # (optional)'])
    creds.value = cr
  }
  const removeCredential = (index) => {
    const cr = [...creds.value]
    cr.splice(index, 1)
    creds.value = cr
  }

  // 🏗️ Return API
  return {
    setCred,
    addCredential,
    removeCredential,
    templateUpdatedHandler,
    defaultPresentationSettings,
    isChangeOrder,
    autoSavePresentation,
    slidesEnabled,
    screenIndex,
    oPresentationSettings,
    presentationSettings,
    presentationTemplateId,
    companyLogoUrl,
    companyName,
    currentScreen,
    combinedFiles,
    backgroundUrlFinal,
    heroUrlFinal,
    blendHero,
    showCosts,
    showQuantities,
    showAssemblyPrices,
    showCostItemPrices,
    showItemizedPrices,
    alwaysIncludedFileIds,
    termsAndConditions,
    coverLetter,
    defaultChangeOrderMessage,
    creds,
    badges,
    badgeUrls,
    saveTemplate,
    saveTemplateAsNew,
    loadTemplate,
    headingFileId,
    backgroundFileId,
    logoFileId,
    logoUrl,
    blendLogo,
    openChat,
    getTemplate,
    isInPresentation,
    readOnly,
    showPresenter,
    processedMessage,
    processedCoverLetter,
    unsavedChanges,
    getChanges,
    goToItem,
    templateFull,
    assemblyInitialState,
    loading,
    defaultCoverLetter,
    blendBg,
    isClient,
    showItemSpecificTax
  }
}
