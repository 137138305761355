<template>
  <form class="w-full" @submit.prevent="handleSubmit">
    <div
      v-for="field in aoFields"
      :key="field.custom_field_id"
      class="mb-4 w-full flex flex-col justify-start"
    >
      <div class="w-full" v-if="field.custom_field_type === 'textfield'">
        <label :for="field.custom_field_id" class="block text-sm font-medium mb-1">
          {{ field.custom_field_name }}
        </label>
        <Field
          v-model="formData[field.custom_field_id]"
          :id="field.custom_field_id"
          @valid="onFieldValidation(field.custom_field_id, true)"
          @validation="(event) => onValidation(field.custom_field_id, event)"
          :pt="{ root: '!w-full' }"
          :validate="{
            type: field.custom_field_validation,
            required: field.custom_field_is_required
          }"
          :required="field.custom_field_is_required"
        />
      </div>

      <div class="w-full" v-if="field.custom_field_type === 'textarea'">
        <label :for="field.custom_field_id" class="block text-sm font-medium mb-1">
          {{ field.custom_field_name }}
        </label>
        <Field
          type="textarea"
          class="w-full"
          v-model="formData[field.custom_field_id]"
          :id="field.custom_field_id"
          @valid="onFieldValidation(field.custom_field_id, true)"
          :validate="{
            required: field.custom_field_is_required
          }"
        />
      </div>

      <div class="w-full" v-if="field.custom_field_type === 'radio'">
        <label class="block text-sm font-medium mb-1">{{ field.custom_field_name }}</label>
        <div
          v-for="option in field.uid
            ? getNewOptions(field.uid)
            : getOptions(field.custom_field_id, aoFieldOptions)"
          :key="option.custom_field_option_id"
          class="flex items-center mb-2"
        >
          <RadioButton
            v-model="formData[field.custom_field_id]"
            :inputId="option.custom_field_option_id"
            :value="option.custom_field_option_value"
            class="mr-2"
            @change="onFieldValidation(field.custom_field_id, true)"
          />
          <label :for="option.custom_field_option_id">{{ option.custom_field_option_label }}</label>
        </div>
      </div>

      <div class="w-full" v-if="field.custom_field_type === 'select'">
        <label :for="field.custom_field_id" class="block text-sm font-medium mb-1">
          {{ field.custom_field_name }}
        </label>
        <Dropdown
          v-model="formData[field.custom_field_id]"
          :pt="{
            input: 'px-4 py-3 w-full'
          }"
          :ptOptions="{ mergeOptions: true }"
          placeholder="Make selection"
          :options="
            isPreview ? getNewOptions(field.uid) : getOptions(field.custom_field_id, aoFieldOptions)
          "
          :optionLabel="'custom_field_option_label'"
          :optionValue="'custom_field_option_value'"
          :id="field.custom_field_id"
          class="w-full"
          :required="field.custom_field_is_required"
          @change="onFieldValidation(field.custom_field_id, true)"
        />
      </div>

      <div class="w-full" v-if="field.custom_field_type === 'checkbox'">
        <label class="block text-sm font-medium mb-1">{{ field.custom_field_name }}</label>
        <div
          v-for="option in field.uid
            ? getNewOptions(field.uid)
            : getOptions(field.custom_field_id, aoFieldOptions)"
          :key="option.custom_field_option_id"
          class="flex items-center mb-2"
        >
          <Checkbox
            v-model="formData[field.custom_field_id]"
            :inputId="option.custom_field_option_id"
            :value="option.custom_field_option_value"
            class="mr-2"
            @change="onFieldValidation(field.custom_field_id, true)"
          />
          <label :for="option.custom_field_option_id">{{ option.custom_field_option_label }}</label>
        </div>
      </div>

      <div
        class="w-full h-16 border-2 rounded-md border-dashed border-cool-gray-300 flex items-center justify-center"
        v-if="!field.custom_field_type"
      >
        <p class="text-cool-gray-600 text-sm">Select a field type</p>
      </div>
    </div>

    <Button
      class="mt-4 w-full"
      size="xl"
      severity="primary-black"
      label="Submit"
      type="submit"
      :disabled="!isFormValid"
    >
      {{ l('Submit') }}
    </Button>
  </form>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps } from 'vue'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import RadioButton from 'primevue/radiobutton'
import useForm from '@/components/forms/Form'
import useTranslation from '@/components/composables/Translation'

RadioButton.compatConfig = { MODE: 3 }
Checkbox.compatConfig = { MODE: 3 }
Dropdown.compatConfig = { MODE: 3 }

const props = defineProps({
  aoFields: {
    type: Array,
    required: true
  },
  aoFieldOptions: {
    type: Array,
    required: true
  },
  isPreview: {
    type: Boolean,
    default: false
  }
})

const { l } = useTranslation()

const emit = defineEmits(['submit'])

const { getOptions, getNewOptions, getCustomFieldValues } = useForm()

const formData = ref({})
const validationSchema = ref({})

// Handle form submission
const handleSubmit = async () => {
  if (!isFormValid.value) return
  const customFieldValues = await getCustomFieldValues(formData.value)
  // saveCustomFieldValues(customFieldValues)
  emit('submit', customFieldValues)
}

// Update validation schema based on field validation
const onFieldValidation = (fieldId, isValid) => {
  validationSchema.value[fieldId] = isValid
}

const onValidation = (fieldId, event) => {
  const { valid } = event
  if (!valid) onFieldValidation(fieldId, false)
}

// Computed property to check if the form is valid
const isFormValid = computed(() => {
  return Object.values(validationSchema.value).every((isValid) => isValid === true)
})

onMounted(() => {
  // build out the validation schema
  props.aoFields.forEach((field) => {
    const { custom_field_id: fieldId, custom_field_is_required: isRequired } = field

    if (isRequired) validationSchema.value[fieldId] = false
  })
})
</script>
