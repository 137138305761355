<template>
  <div
    v-if="object"
    @mouseover="hovering = true"
    @mouseout="hovering = false"
    class="flex flex-col border border-surface-200 rounded-md p-6 gap-y-3 grow min-w-[250px] max-w-[300px]"
  >
    <!-- Top row with icon and help -->
    <div class="flex items-center justify-between">
      <icon :icon="['fad', object.icon]" class="text-3xl text-surface-700" />
      <Help v-if="object.description" class="text-sm">
        <template #title>{{ object.title }}</template>
        <p>{{ object.description }}</p>
      </Help>
    </div>

    <!-- Title -->
    <p class="text-surface-600">{{ object.title }}</p>

    <!-- Amount -->
    <p class="text-3xl font-medium text-pitch-black font-header">
      $ {{ $f.currency(object.currentAmount) }}
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  object: {
    type: Object,
    default: null
  }
})

const hovering = ref(false)
</script>
