<template>
  <Page title="Stripe products map">
    <CardSection>
      <div class="flex items-center justify-between !mb-0">
        <div class="flex items-center gap-2">
          <h3>Products</h3>
          <Btn severity="tertiary-borderless" size="sm" :action="fetchUsaFeatureProducts">
            <font-awesome-icon icon="rotate-right" fixed-width />
          </Btn>
        </div>
        <Btn :action="createFeatureProduct" :disabled="!usaProductsLoading">Create new</Btn>
      </div>
      <CardList class="!overflow-auto">
        <DataTable :loading="usaFeatureProductsLoading" :value="usaFeatureProducts" stripedRows>
          <Column field="id" header="Feature Product Id"></Column>
          <Column field="feature_product_name" header="Feature Product Name"></Column>
          <Column field="feature_product_description" header="Feature Product Description"></Column>
          <Column field="stripe_product.id" header="Linked Stripe Product id"></Column>
          <Column field="stripe_product.name" header="Linked Stripe Product Name"></Column>
          <Column header="Remove">
            <template #body="{ data }">
              <Btn severity="danger" :action="() => deleteFeatureProduct(data.id)">
                <font-awesome-icon icon="trash" fixed-width />
              </Btn>
            </template>
          </Column>
          <Column header="Edit">
            <template #body="{ data }">
              <Btn severity="info" :action="() => editFeatureProduct(data)">
                <font-awesome-icon icon="pen" fixed-width />
              </Btn>
            </template>
          </Column>
        </DataTable>
      </CardList>
    </CardSection>
  </Page>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

// ======== Composables ======== //
const store = useStore()
// =========================== //

// ======== Data ======== //
const usaProductsLoading = ref(true)

const usaFeatureProducts = ref([])
const usaFeatureProductsLoading = ref(true)
// =========================== //

// ======== Methods ======== //
const fetchFeatureProducts = async (country) => {
  return store.dispatch('Subscription/getFeatureProducts', {
    country
  })
}
const fetchUsaFeatureProducts = async () => {
  usaFeatureProductsLoading.value = true
  try {
    const { products } = await fetchFeatureProducts('usa')
    usaFeatureProducts.value = products
  } catch (e) {
    console.error(e)
  } finally {
    usaFeatureProductsLoading.value = false
  }
}
const createFeatureProduct = async () => {
  await store.dispatch('modal/open', {
    modal: {
      name: 'SuperCreateFeatureProduct',
      closed: fetchUsaFeatureProducts
    }
  })
}
const editFeatureProduct = (fields) => {
  return store.dispatch('modal/open', {
    modal: {
      name: 'SuperCreateFeatureProduct',
      embue: {
        id: fields.id,
        featureProductName: fields.feature_product_name,
        featureProductDescription: fields.feature_product_description,
        stripeId: fields.stripe_product_id
      },
      closed: fetchUsaFeatureProducts
    }
  })
}
const deleteFeatureProduct = async (id) => {
  await store.dispatch('Subscription/deleteFeatureProduct', {
    id
  })

  return fetchUsaFeatureProducts()
}
// =========================== //

// ======== Lifecycle ======== //
onMounted(() => {
  fetchUsaFeatureProducts()
})
// =========================== //
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
