<template>
  <div>
    <!-- bottom panel -->
    <div class="flex justify-stretch items-stretch h-full">
      <!-- Presentation settings action bar -->
      <!--      <div-->
      <!--        class="flex flex-col md:border-r border-surface-300 pr-10 mr-10 relative overflow-hidden h-auto"-->
      <!--      >-->
      <!--        <div>-->
      <!--          <div class="flex flex-col gap-3">-->
      <!--            <Btn-->
      <!--              class="!w-full"-->
      <!--              size="lg"-->
      <!--              @click="() => selectTab('theme')"-->
      <!--              :severity="tab === 'theme' ? 'primary' : 'tertiary-borderless'"-->
      <!--            >-->
      <!--              Theme settings-->
      <!--            </Btn>-->
      <!--            <Btn-->
      <!--              class="w-full"-->
      <!--              size="lg"-->
      <!--              @click="() => selectTab('settings-estimation')"-->
      <!--              :severity="tab === 'settings-estimation' ? 'primary' : 'tertiary-borderless'"-->
      <!--            >-->
      <!--              Estimation settings-->
      <!--            </Btn>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- Customizations -->
      <div class="flex flex-col justify-center col-span-3 px-4 relative pb-5 w-full">
        <ScrollPanel class="w-full h-full max-h-[75vh] md:max-h-[92vh] pb-10">
          <PresentationSettings :dark="true" :refId="props.refId" />
        </ScrollPanel>
      </div>
    </div>
  </div>
</template>

<script setup>
import PresentationSettings from './PresentationSettings.vue'
import ScrollPanel from 'primevue/scrollpanel'
import { defineProps } from 'vue'

const props = defineProps({
  refId: {
    required: true
  },
  store: {
    default: 'Quote',
    required: true
  },
  type: {
    default: 'quote'
  }
})
</script>
