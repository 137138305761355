<script>
// Components
import { computed, ref, defineExpose, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
import { mixin as clickaway } from 'vue3-click-away'
import InlineCalculator from '@/components/ui/Calculator/Field.vue'
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue'
import DimensionSelector from '@/components/quote/item/DimensionSelector.vue'
import Gallery from './Gallery.vue'
import ItemMaterials from '@/components/Sheets/quote/estimating/ItemMaterials.vue'
import ItemSubcontracted from '@/components/Sheets/quote/estimating/ItemSubcontracted.vue'
import ItemLabor from '@/components/Sheets/quote/estimating/ItemLabor.vue'
import ItemSaving from '@/components/Sheets/quote/estimating/ItemSaving.vue'
import { useExportTools } from '@/components/composables/useExportTools'

// Compositions
import Sheet from './Sheet'
import DrawUtilities from './DrawUtilities'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const { black, stroke } = DrawUtilities

export default {
  name: 'Sheets',

  props: {
    freeze: {
      default: null
    },
    session: {
      type: Object,
      required: true,
      default: () => ({
        save: true,
        key: 'Build16',
        saveCollapseGroups: true
      })
    },
    sheets: {
      type: Array,
      required: true,
      default: (sheets) =>
        sheets.map((sheet, index) => ({
          title: `Sheet ${index}`,
          columns: sheet.columns || [],
          superHeaders: sheet.superHeaders || [],
          sort: {
            sort: [],
            ...(sheet.sort || {})
          },
          collapseGroups: sheet.collapseGroups || {},
          group: {
            group: [],
            showHeadings: true,
            showTotals: true,
            spacing: 50,
            ...(sheet.group || {})
          },
          filter: () => 0, // all going to first sheet
          rows: sheet.rows || []
        }))
    },
    fitToData: {
      default: true
    },
    defaultFormatting: {
      type: Object,
      default: () => ({
        align: 'center',
        verticalAlign: 'center',
        bold: false,
        italic: false,
        fontSize: 14,
        fontFamily: 'Cera Pro, Arial, sans-serif',
        color: black,
        background: 'transparent',
        wordWrap: false,
        format: 'string',
        strokeColor: stroke,
        strokeThickness: 1
      })
    },
    defaultHeadingFormat: {
      type: Object,
      default: () => ({
        align: 'center',
        verticalAlign: 'middle',
        bold: true,
        italic: false,
        fontSize: 10,
        fontFamily: 'Cera Pro, Arial, sans-serif',
        color: black,
        background: 'transparent',
        wordWrap: false,
        format: 'string',
        strokeColor: stroke,
        padding: [0, 0, 2, 0]
      })
    },
    showSideScrollHelper: {
      type: Boolean,
      default: true
    },
    showColumnHeadings: {
      type: Boolean,
      default: true
    },
    showRowHeadings: {
      type: Boolean,
      default: true
    },
    borderRadius: {
      type: Number,
      default: 5
    },
    defaultCellWidth: {
      type: Number,
      default: 100
    },
    defaultCellHeight: {
      type: Number,
      default: 40
    },
    defaultGroupSpacing: {
      type: Number,
      default: 20
    },
    selectedRowOptions: {
      type: Array,
      default: () => [
        {
          name: 'Save to library',
          icon: 'svg:catalogSaveLight',
          action: () => {},
          multiple: true,
          single: true
        },
        {
          name: 'Delete',
          icon: 'trash',
          action: (rows, { sheet }) => {
            sheet.deleteRows(rows)
            sheet.selectedRows.value = []
            sheet.gripRow.value = null
          },
          multiple: true,
          single: true
        },
        {
          name: 'Duplicate',
          icon: 'copy',
          action: (rows, { sheet }) => {
            sheet.duplicateRows(rows)
          },
          multiple: false,
          single: true
        },
        {
          name: 'Turn into assembly',
          icon: 'cubes',
          action: (rows, { sheet }) => {
            sheet.turnIntoAssembly(rows)
          },
          multiple: false,
          single: true
        }
      ]
    }
  },

  mixins: [clickaway],

  components: {
    LoadingIndicator,
    InlineCalculator,
    DimensionSelector,
    Gallery,
    ItemMaterials,
    ItemSubcontracted,
    ItemLabor,
    ItemSaving
  },

  emits: [
    'collapseCollapseGroup',
    'expandCollapseGroup',
    'commit',
    'committed',
    'changes',
    'addedRows',
    'movedRows',
    'removedRows',
    'duplicatedRows',
    'rereferencedRows',
    'createdAssembly',
    'cellSelected',
    'turnItemIntoAssembly',
    'structureChanged',
    'scroll',

    'dragleave*',
    'dragover*',
    'drop*',

    'mousemoveOutOfBoundsRight',
    'mouseoverOutOfBoundsRight',
    'mouseoutOutOfBoundsRight',
    'clickOutOfBoundsRight',
    'mousedownOutOfBoundsRight',
    'mouseupOutOfBoundsRight',
    'dblclickOutOfBoundsRight',

    'mousemoveCanvas',
    'mouseoverCanvas',
    'mouseoutCanvas',
    'clickCanvas',
    'dblclickCanvas',
    'mousedownCanvas',
    'mouseupCanvas',

    'mousemoveCell',
    'mouseoverCell',
    'mouseoutCell',
    'clickCell',
    'dblclickCell',
    'mousedownCell',
    'mouseupCell',

    'cellBlurred',
    'cellFocused',

    'mousemoveSuperHeading',
    'mouseoverSuperHeading',
    'mouseoutSuperHeading',
    'clickSuperHeading',
    'dblclickSuperHeading',
    'mousedownSuperHeading',
    'mouseupSuperHeading',

    'mousemoveColHeading',
    'mouseoverColHeading',
    'mouseoutColHeading',
    'clickColHeading',
    'dblclickColHeading',
    'mousedownColHeading',
    'mouseupColHeading',

    'mousemoveRowHeading',
    'mouseoverRowHeading',
    'mouseoutRowHeading',
    'clickRowHeading',
    'dblclickRowHeading',
    'mousedownRowHeading',
    'mouseupRowHeading',

    'mousemoveColExpander',
    'mouseoverColExpander',
    'mouseoutColExpander',
    'clickColExpander',
    'dblclickColExpander',
    'mousedownColExpander',
    'mouseupColExpander',

    'mousemoveRowExpander',
    'mouseoverRowExpander',
    'mouseoutRowExpander',
    'clickRowExpander',
    'dblclickRowExpander',
    'mousedownRowExpander',
    'mouseupRowExpander',

    'mousemoveSheetHeading',
    'mouseoverSheetHeading',
    'mouseoutSheetHeading',
    'clickSheetHeading',
    'dblclickSheetHeading',
    'mousedownSheetHeading',
    'mouseupSheetHeading',

    'mousemoveCollapseGroupOutOfBounds',
    'mouseoverCollapseGroupOutOfBounds',
    'mouseoutCollapseGroupOutOfBounds',
    'clickCollapseGroupOutOfBounds',
    'dblclickCollapseGroupOutOfBounds',
    'mousedownCollapseGroupOutOfBounds',
    'mouseupCollapseGroupOutOfBounds'
  ],

  setup(props, { emit }) {
    // const deleteRows = () => {};
    // const convertToAssembly = () => {};
    // const convertToItem = () => {};
    // const duplicate = () => {};
    // const saveToCatalog = () => {};
    let sheet = {}

    const selectedRowActions = computed(() => props.selectedRowOptions)
    const reactiveProps = computed(() => props)

    sheet = Sheet.useSheet({
      props: reactiveProps,
      emit,
      selectedRowActions
    })

    const grabRowDetails = computed(() => {
      const id = sheet.getRowId(sheet.gripRow.value)
      const rowData = sheet.getRowData(sheet.gripRow.value)
      return sheet.gripRow.value === null
        ? {}
        : {
            rowIndex: sheet.gripRow.value,
            id,
            rowData
          }
    })
    const grabActions = computed(() =>
      selectedRowActions.value.filter(
        (sra) =>
          (!('single' in sra) || sra.single) && (!('if' in sra) || sra.if(grabRowDetails.value))
      )
    )

    const handleSelectedRowAction = (action, { rows = sheet.selectedRows.value } = {}) => {
      const rowIndexes = rows.map((r) => sheet.getRowFromId(r))
      if (sheet.currentCell[0]) sheet.commitValue()
      sheet.cellFocused.value = false
      return action(rowIndexes, { sheet })
    }

    const gripRow = computed(() => sheet.gripRow.value)
    const gripRowLoading = ref(0)
    const handleGripOptionClick = async (option) => {
      gripRowLoading.value = 40
      sheet.hideGripOptions()
      await option.action([gripRow.value], { sheet })
      gripRowLoading.value = 100
      setTimeout(() => {
        gripRowLoading.value = 0
      }, 100)
    }

    // const gripRowData = computed(() => sheet.getRowData(gripRow.value));

    const progressHandler = (val) => emit('cellTyping', val)

    defineExpose({
      ...sheet,

      selectedRowActions,
      handleSelectedRowAction,
      handleGripOptionClick,
      grabActions,
      grabRowDetails,
      progressHandler
    })

    const refOptions = ref(null)

    const superHeaderColumnNames = computed(() => {
      const sheets = sheet.dataSheets.value
      return sheets.map((sht, sheetIndex) => {
        return sht.superHeaders.map((sh) => {
          const names = []
          let start = 0
          let end = 1
          do {
            for (let col = sh.span[start]; col <= sh.span[end]; col += 1) {
              const title = sheet.getColDef(col, sheetIndex).title
              if (title) names.push(title)
            }
            start += 2
            end += 2
          } while (_.isNumber(sh.span[start]) && _.isNumber(sh.span[end]))
          return names.join(', ')
        })
      })
    })

    const menuButtonClass = ref([
      'last:border-l-0 border-l border-white/30',
      'first:rounded-l-sm last:rounded-r-sm rounded-none bg-primary hover:bg-primary/80 text-white px-4 py-1 h-8 min-h-8'
    ])

    const refGearButton = ref(null)

    const $this = getCurrentInstance().proxy
    const handleCollapsedSuperHeader = () => {
      const classes = [
        '!animate-pulse',
        '!border',
        '!border-blue-print-500',
        '!text-blue-print-500'
      ]
      const cl = refGearButton.value?.$el?.classList
      if (cl) {
        classes.forEach((cla) => cl.add(cla))
        // Hide the tooltip after the specified duration
        c.throttle(
          () => {
            classes.forEach((cla) => cl.remove(cla))
          },
          { delay: 5000 }
        )
      }
    }

    const disableOverscroll = () => {
      document.getElementsByTagName('html')[0].style['overscroll-behavior-x'] = 'none'
    }

    const enableOverscroll = () => {
      document.getElementsByTagName('html')[0].style['overscroll-behavior-x'] = 'initial'
    }

    onMounted(() => {
      disableOverscroll()
      $this.$on('superHeaderCollapsed', handleCollapsedSuperHeader)
    })

    onBeforeUnmount(() => {
      enableOverscroll()
      $this.$off('superHeaderCollapsed', handleCollapsedSuperHeader)
    })

    const showRowOptionsTooltip = ref(false)
    const rowOptionsTooltipHtml = ref('')
    const rowOptionsTooltipStyle = ref({})

    const setRowOptionsTooltipStyleFromTarget = (target) => {
      const { bottom, left } = target.getBoundingClientRect()

      rowOptionsTooltipStyle.value = {
        top: `${bottom + 4}px`,
        left: `${left}px`
      }
    }

    const refRowOptions = ref(null)

    const handleGripMouseoverAndTooltip = (...args) => {
      setRowOptionsTooltipStyleFromTarget(refRowOptions.value)
      rowOptionsTooltipHtml.value = `<strong>Click</strong> for options <br/><strong>Click and hold</strong> to drag`
      showRowOptionsTooltip.value = true
      sheet.handleGripMouseover(...args)
    }

    const handlePlusMouseoverTooltip = () => {
      setRowOptionsTooltipStyleFromTarget(refRowOptions.value)
      rowOptionsTooltipHtml.value = `<strong>Click</strong> to add a new row below`
      showRowOptionsTooltip.value = true
    }

    const handleGripMouseoutAndTooltip = (...args) => {
      showRowOptionsTooltip.value = false
      sheet.handleGripMouseout(...args)
    }

    const handlePlusMouseoutTooltip = () => {
      showRowOptionsTooltip.value = false
    }

    const helperInputHandler = (value) => {
      // to work properly helpers need their values committed emidiately
      sheet.currentCellValue.value = value
      sheet.commitValue()
    }

    const numericCell = computed(
      () => sheet.currentCell.value && sheet.getCellType(...sheet.currentCell.value) === 'number'
    )

    const multiActions = computed(() => {
      let actions =
        sheet.selectedRows.value.length > 1
          ? selectedRowActions.value.filter((sra) => !('multiple' in sra) || sra.multiple)
          : selectedRowActions.value.filter((sra) => !('single' in sra) || sra.single)
      actions = actions.filter((action) => {
        return (
          !action.if ||
          sheet.selectedRows.value.every((id) =>
            action.if({
              id,
              getRowData: () => sheet.getRowData(sheet.getRowFromId(id), sheet.topSheet.value)
            })
          )
        )
      })
      return actions
    })

    const $store = useStore()

    const hoveringSideScrollHelper = ref(0)

    const showExpandAll = computed(() => {
      return sheet.collapsedGroups?.value?.length >= 1
    })

    const route = useRoute()
    const isItemCatalog = computed(() => route.name === 'Items List')
    const { exportObjects } = useExportTools()

    return {
      ...sheet,
      gripRowLoading,
      $store,
      hoveringSideScrollHelper,

      showExpandAll,
      refGearButton,
      selectedRowActions,
      handleSelectedRowAction,
      handleGripOptionClick,
      grabActions,
      grabRowDetails,
      progressHandler,
      refOptions,
      superHeaderColumnNames,
      menuButtonClass,
      handleGripMouseoverAndTooltip,
      handleGripMouseoutAndTooltip,
      handlePlusMouseoutTooltip,
      handlePlusMouseoverTooltip,
      rowOptionsTooltipHtml,
      showRowOptionsTooltip,
      rowOptionsTooltipStyle,
      refRowOptions,
      helperInputHandler,
      numericCell,
      multiActions,
      isItemCatalog,
      exportObjects
    }
  }
}
</script>

<template>
  <!-- Main Container -->
  <div
    class="sheets-container"
    ref="refContainer"
    @click.capture="handleSheetsFocus"
    @wheel.passive="handleScroll"
  >
    <!-- Canvas Element -->
    <canvas id="sheets-canvas" ref="refCanvas" :class="[{ 'opacity-0': !loaded }]" />

    <Skeleton class="h-[3px] user-select-none !bg-blue-print" :style="loadingRowStyle" />

    <div v-if="!loaded" class="p-20">
      <div class="flex flex-col justify-stretch items-stretch h-full w-full gap-4">
        <div class="flex justify-stretch items-stretch h-10 w-full gap-4">
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
        </div>
        <div class="flex justify-stretch items-stretch h-10 w-full gap-4">
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
        </div>
        <div class="flex justify-stretch items-stretch h-10 w-full gap-4">
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
          <Skeleton class="min-h-10" />
        </div>
      </div>
    </div>

    <div
      class="flex flex-col justify-between items-stretch h-full gap-1 text-secondary select-none absolute"
      :style="{
        top: `0px`,
        left: '0px',
        width: `${fullHeadingWidth}px`,
        height: `${sheetHeadingHeight + columnHeadingHeight + superHeadingHeight}px`
      }"
    >
      <div class="flex justify-center items-start w-full">
        <slot name="heading-top-left">&nbsp;</slot>
      </div>
      <div class="flex justify-start items-center w-full gap-1">
        <slot name="heading-bottom-left">
          <template
            v-if="Object.keys(dataSheets?.[topSheet]?.collapseGroups?.groups ?? {}).length > 1"
          >
            <Btn
              tooltip="Expand all groups"
              link
              size="sm"
              class="!text-blue-print !bg-white ml-[4px]"
              :action="expandAll"
              v-if="showExpandAll"
            >
              <font-awesome-icon icon="square-plus" fixed-width /> all
            </Btn>
            <Btn
              tooltip="Collapse all groups"
              link
              size="sm"
              class="!text-blue-print !bg-white ml-[5px]"
              :action="collapseAll"
              v-else
            >
              <font-awesome-icon icon="square-minus" fixed-width /> all
            </Btn>
          </template>
        </slot>
      </div>
    </div>

    <!-- add btn -->
    <div :style="afterSlotStyle">
      <slot name="after"></slot>

      <div v-if="!rowsMap.length && loaded">
        <Btn @click="addRow(-1)" size="xl"><font-awesome-icon icon="plus" />Add first row</Btn>
      </div>
      <!--      <slot name="testing">-->
      <!--        <div class="whitespace-pre max-h-[400px] !overflow-y-scroll">-->
      <!--          {{$store.state.Quote.normalized.nbgkr1yy4g008s8ks8.aoChildren}}-->

      <!--          {{dataSheets?.[0]?.collapseGroups?.groups?.nbgkr1yy4g008s8ks8?.childrenIds}}-->

      <!--          {{dataSheets?.[0]?.collapseGroups?.groups}}-->
      <!--        </div>-->
      <!--      </slot>-->
    </div>

    <!-- add btn -->
    <div class="absolute inset-0 w-full h-full pointer-events-none">
      <slot name="overlay"></slot>
      <div
        @mouseover="hoveringSideScrollHelper = 1"
        @mouseout="hoveringSideScrollHelper = 0"
        :class="[
          {
            hidden: allColsVisible,
            'opacity-95 hover:opacity-100': !allColsVisible
          },
          'rounded-sm bg-pitch-black border-2 border-pitch-black w-[212px] h-[50px] absolute bottom-10 left-1/2 -translate-x-[100px] flex justify-stretch items-stretch p-[4px] gap-[1px] pointer-events-auto  transition-all'
        ]"
      >
        <div
          v-for="(col, index) in dataSheets?.[topSheet]?.columns ?? []"
          :key="index"
          class="rounded-xs bg-surface-600/50 h-full shrink-0 grow-0"
          :class="{
            '!opacity-0': getColWidth(index, topSheet) === 0
          }"
          :style="{ width: `${200 / (dataSheets[topSheet]?.columns?.length ?? 1) - 1}px` }"
        ></div>

        <div
          class="absolute inset-0 w-full h-full p-1 flex justify-center items-center gap-2 text-xs font-medium text-white user-select-none select-none transition-all"
          :class="{
            'opacity-80': hoveringSideScrollHelper,
            'opacity-0': !hoveringSideScrollHelper
          }"
        >
          <font-awesome-icon icon="arrows-left-right" />
          Side scroll helper
        </div>

        <div
          class="absolute inset-0 w-full h-[48px] z-[1000]"
          ref="refSideScrollBar"
          :class="{
            'cursor-grab': !isDraggingHelperX,
            'cursor-grabbing': isDraggingHelperX
          }"
        >
          <div
            class="absolute border-2 border-level-yellow bg-level-yellow/20 rounded-sm h-[46px]"
            ref="refSideScrollHelper"
            :style="{
              left: `${(visibleColRange?.[0] ?? 0) * (200 / (dataSheets[topSheet]?.columns?.length ?? 1)) + 4}px`,
              width: `${(visibleCols?.length - 1 ?? 1) * (200 / (dataSheets[topSheet]?.columns?.length ?? 1)) - 4}px`
            }"
          ></div>
        </div>
      </div>
    </div>

    <div class="insert-indicator relative overflow-visible" :style="insertIndicatorStyle">
      <!--      <div class="absolute -top-4 font-bold text-blue-print">{{movingParentId}}</div>-->
    </div>

    <!-- select boxes -->
    <div class="row-options" ref="refRowOptions" :style="selectBoxStyle">
      <a
        @click="() => handleAddClick(viewingSelectBoxOptions ?? showSelectBox)"
        @mouseover="handlePlusMouseoverTooltip"
        @mouseout="handlePlusMouseoutTooltip"
        class="cursor-pointer hover:bg-surface-200 p-1 rounded-sm w-[20px]"
      >
        <font-awesome-icon icon="fa-solid fa-plus" />
      </a>
      <a
        @mouseover="handleGripMouseoverAndTooltip"
        @mouseout="handleGripMouseoutAndTooltip"
        @mousedown="handleGripMousedown"
        @mouseup="handleGripMouseup"
        ref="refGripClick"
        class="gripclick cursor-pointer hover:bg-surface-200 p-1 rounded-sm w-[20px]"
      >
        <font-awesome-icon icon="fa-solid fa-grip-dots-vertical" />
      </a>
      <label
        class="sheet--selectBox"
        @click.capture.stop.prevent="(e) => handleSelectBoxChange(e, rowsMap[showSelectBox].id)"
      >
        <input
          type="checkbox"
          ref="refSelectHoverCheckbox"
          :checked="false"
          @change="(e) => handleSelectBoxChange(e, rowsMap[showSelectBox].id)"
        />
        <span class="checkmark"></span>
      </label>
    </div>

    <div
      class="text-xs text-surface-200 bg-surface-800 fixed flow-root py-1 px-2 rounded-sm"
      v-if="showRowOptionsTooltip"
      v-html="rowOptionsTooltipHtml || ''"
      :style="rowOptionsTooltipStyle"
    ></div>

    <label
      class="sheet--selectBox bg-white p-2 w-8"
      v-for="row in selectedRows"
      :key="row"
      :style="selectBoxStyles[row]"
      ref="refSelectedRowsCheckboxes"
    >
      <input type="checkbox" :checked="true" @change="(e) => handleSelectBoxChange(e, row)" />
      <span class="checkmark"></span>
    </label>
    <div></div>

    <div
      class="select-none"
      :style="selectedRowActionsStyle"
      v-if="selectedRowActions.length || defaultSelectedRowActions.length"
    >
      <Btn unstyled :class="[...menuButtonClass, '!text-level-yellow']" @click="selectedRows = []">
        &times; {{ selectedRows.length }} selected
      </Btn>
      <Btn
        unstyled
        :class="menuButtonClass"
        :action="() => handleSelectedRowAction(action.action)"
        v-for="action in multiActions"
        :key="action"
      >
        <SvgIcon
          v-if="action.icon && action.icon.includes('svg')"
          :svg="action.icon.split(':')[1]"
        />
        <font-awesome-icon :icon="action.icon" v-else-if="action.icon" />

        <span>
          {{ action.name || '' }}
        </span>
      </Btn>

      <Btn
        unstyled
        size="lg"
        :class="menuButtonClass"
        v-for="action in defaultSelectedRowActions"
        :key="action"
        :action="() => handleSelectedRowAction(action.action)"
      >
        <SvgIcon
          v-if="action.icon && action.icon.includes('svg')"
          :svg="action.icon.split(':')[1]"
        />
        <font-awesome-icon :icon="action.icon" v-else-if="action.icon" />
        <span>
          {{ action.name || '' }}
        </span>
      </Btn>
    </div>

    <Skeleton :style="rowLoadingStyle" />

    <!-- Editable Cell Div -->
    <div
      id="editableDiv"
      ref="refEditableDiv"
      style="pointer-events: none"
      v-show="showEditableDiv && !(showEditableDiv && cellFocused)"
      :style="editableDivStyle"
      class="z-1"
      contenteditable="false"
    />
    <InlineCalculator
      :key="`${currentCell[0]}-${currentCell[1]}`"
      :class="[
        'z-10',
        {
          '!whitespace-nowrap !w-fit !overflow-x-auto !max-w-fit': !showMultiline
        }
      ]"
      v-if="cellFocused && currentCell[0] !== null && currentCell[1] !== null"
      @progress="progressHandler"
      id="calc"
      ref="refCalc"
      :showPocket="showCalcPocket"
      :variables="calcVariables"
      :fit-content="false"
      @focus="handleFocus"
      @input="handleInput"
      @enter="handleEnter"
      @tab="handleTab"
      :multiLine="!!showMultiline"
      :auto-multiline="false"
      :numeric="numericCell"
      :style="calcStyle"
      :value="currentCellValue"
    />

    <!--    <div-->
    <!--      v-for="(dropzone, index) in dropzoneStyle"-->
    <!--      :key="index"-->
    <!--      :style="dropzone.style"-->
    <!--      class="absolute pointer-events-none p-1"-->
    <!--    >-->
    <!--      <div-->
    <!--        :class="[-->
    <!--          'w-full h-full rounded-sm flex justify-center items-center',-->
    <!--          {-->
    <!--            'border border-primary/60  bg-surface-300/60 ':-->
    <!--              dropzone.col != dragoverCell[0] || dropzone.row != dragoverCell[1],-->
    <!--            'border border-blue-print-500/60  bg-blue-print-300/60 ':-->
    <!--              dropzone.col == dragoverCell[0] && dropzone.row == dragoverCell[1]-->
    <!--          }-->
    <!--        ]"-->
    <!--      >-->
    <!--        <font-awesome-icon-->
    <!--          icon="cloud-arrow-down"-->
    <!--          :class="[-->
    <!--            {-->
    <!--              'text-primary': dropzone.col != dragoverCell[0] || dropzone.row != dragoverCell[1],-->
    <!--              'text-blue-print-700':-->
    <!--                dropzone.col == dragoverCell[0] && dropzone.row == dragoverCell[1]-->
    <!--            }-->
    <!--          ]"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->

    <Drop
      ref="refHelperDrop"
      v-if="refEditableDiv && helperComponent"
      v-show="showFieldHelper"
      :fix-to="refEditableDiv"
      :auto-z-index="true"
      unstyled
      containerClass="bg-surface-100 rounded-sm shadow-md after:border-b-transparent translate-y-1 flex flex-col gap-2 max-w-80 p-4"
    >
      <component
        :value="currentCellValue"
        @input="helperInputHandler"
        :key="`${currentCell[0]}-${currentCell[1]}`"
        :is="helperComponent.name"
        v-bind="(helperComponent.props ?? (() => ({})))()"
        ref="refHelperComponent"
      />
    </Drop>

    <!-- Action Div -->
    <div
      class="text-xs items-start justify-end flex pointer-events-none cursor-pointer"
      :style="actionStyle"
      id="actionDiv"
      ref="refActionDiv"
    ></div>
    <div
      :style="{
        left: `${hoverCell[2]?.[0] - 1}px`,
        top: `${hoverCell[2]?.[1] - 1}px`,
        width: `${hoverCell[2]?.[2]}px`,
        height: `${hoverCell[2]?.[3]}px`
      }"
      :class="['absolute p-0 pointer-events-none']"
    >
      <!--      'border border-surface-600/70 rounded-none absolute inset-0':-->
      <!--      !/action|checkbox/.test(`${hoverCell[3] ?? ''}`) ?? true,-->
      <div
        :class="[
          {
            'border-2 border-blue-print/40 rounded-sm absolute inset-0.5':
              /action|checkbox/.test(`${hoverCell[3] ?? ''}`) ?? false
          }
        ]"
      ></div>
    </div>

    <!-- Choose Div -->
    <div :style="chooseStyle" id="chooseDiv" ref="refChooseDiv">
      <Choose
        v-bind="chooseOptions"
        v-if="showChooseDiv"
        ref="refChoose"
        :emitDelay="50"
        :value="currentCellValue"
        :emit-on-destroy="false"
        :emit-on-blur="false"
        @selectedValue="handleChooseSelected"
        :return-array="false"
        :key="currentCell"
      >
        <template>
          <div class="inset-0 absolute"></div>
          <!--          <div class="chooseDiv-inner">{{ currentCellValueFormatted || singleTextDisplay || 'Choose...' }} <span-->
          <!--            class="caret-down"></span></div>-->
        </template>
      </Choose>
    </div>

    <!-- Progress Cell Div -->
    <div id="progressDiv" ref="refProgressDiv" :style="progressDivStyle">
      <div
        v-for="(step, index) in progressSteps"
        :key="step"
        ref="refStep"
        @mouseover="stepMouseoverHandler(index)"
        @mouseout="stepMouseoutHandler(index)"
        @mousedown="stepMouseDownHandler(index)"
        @mouseup="stepMouseupHandler(index)"
      ></div>
    </div>
    <div
      class="progressMessage"
      v-if="hoverStepsText"
      :style="progressMsgStyle"
      :class="[
        'absolute z-1000 flex items-center justify-center py-0.5 px-4 leading-3 gap-2 h-fit !w-fit max-w-50 rounded-md shadow-md',
        'text-flame-white bg-cool-gray-800',
        'font-medium text-sm',
        {
          'bg-purple-200 text-purple-800': progressSteps[hoveringStep].disabled
        }
      ]"
    >
      <div class="" :class="['flex items-center gap-2']">
        <template v-if="progressSteps[hoveringStep].disabled">
          <font-awesome-icon :icon="['fas', 'sparkles']" />
          <template v-if="!hoverCellData.raw[hoveringStep]">
            {{
              progressSteps[hoveringStep].enableDescription ||
              'This is an automated step set by the system.'
            }}
          </template>
          <template v-else>
            {{
              progressSteps[hoveringStep].disableDescription ||
              'This is an automated step set by the system.'
            }}
          </template>
        </template>
        <template v-else-if="!hoverCellData.raw[hoveringStep]">
          {{
            progressSteps[hoveringStep].enableDescription ||
            `Mark as ${progressSteps[hoveringStep].text.toLowerCase()}`
          }}
        </template>
        <template v-else>
          {{
            progressSteps[hoveringStep].disableDescription ||
            `Mark as not ${progressSteps[hoveringStep].text.toLowerCase()}`
          }}
        </template>
      </div>
    </div>

    <!-- Origin Label -->
    <div :style="originLabelStyle" id="originLabel" v-if="showOriginLabel">
      {{ currentCellLabel }}
    </div>

    <!-- Column & Row Expanders -->
    <div id="expanderCol" ref="refExpanderCol" draggable="true"></div>
    <div id="expanderRow" ref="refExpanderRow" draggable="true"></div>

    <!-- Vertical Scrollbar -->
    <div
      class="vertical-scrollbar"
      ref="refVerticalScrollbar"
      v-show="true"
      :style="
        isDraggingY
          ? {
              width: `20px`
            }
          : {}
      "
    >
      <div class="scrollbar-thumb" ref="refScrollbarThumbY"></div>
    </div>

    <!-- Horizontal Scrollbar -->
    <div
      class="horizontal-scrollbar"
      ref="refHorizontalScrollbar"
      v-show="!allColsVisible"
      :style="
        isDraggingX
          ? {
              height: `20px`
            }
          : {}
      "
    >
      <div class="scrollbar-thumb" ref="refScrollbarThumbX"></div>
    </div>

    <!-- Scrollbar Nub -->
    <div class="scrollbar-nub" v-show="true"></div>

    <!-- Display Information -->
    <!--    <div class="display" :style="{ opacity: loadingMessages.length ? 1 : 0 }">-->
    <!--      <LoadingIndicator/>-->
    <!--      {{ loadingMessages[loadingMessages.length - 1] }}-->
    <!--    </div>-->

    <!-- Column Actions Button -->
    <div
      class="absolute bg-surface-300 pointer-events-none rounded-t"
      v-show="showColumnButton && !disableMouse"
      :style="{
        left: `${columnButtonPosition.x - 3}px`,
        top: `${columnButtonPosition.y + 4}px`,
        width: `4px`,
        height: `20px`
      }"
    ></div>
    <button
      @click="refColumnActions.toggle()"
      ref="refColumnButton"
      v-show="showColumnButton && !disableMouse"
      class="absolute overflow-hidden bg-blue-print gap-2 tracking-wide text-left pl-1 text-ellipsis text-[8pt] text-surface-100 flex justify-start items-end pb-1 !h-[20px] p-0 leading-none whitespace-nowrap rounded-t"
      :style="columnButtonStyle"
    >
      {{ columnButtonLabel }}
      <font-awesome-icon icon="fas fa-ellipsis" />
    </button>
    <div
      class="absolute bg-surface-300 pointer-events-none rounded-t"
      v-show="showColumnButton && !disableMouse"
      :style="{
        left: `${columnButtonPosition.x + columnButtonPosition.width - 3}px`,
        top: `${columnButtonPosition.y + 4}px`,
        width: `4px`,
        height: `20px`
      }"
    ></div>

    <!-- Multi-select Indicator -->
    <div
      id="multiSelectIndicator"
      ref="refMultiSelectIndicator"
      v-show="multiSelect !== null"
      :style="selectionRangeStyle"
      :class="mouseDown ? '' : 'selected'"
    ></div>

    <div id="searchContainer" class="searchContainer" :style="searchDivStyle">
      <font-awesome-icon icon="magnifying-glass" class="text-muted text-lg" />
      <div
        class="searchInput"
        id="searchInput"
        ref="refSearchInput"
        @keydown.enter="enterSearchHandler"
        @input="() => handleSearchInput(refSearchInput.innerText)"
        contenteditable="true"
      ></div>

      <a @click="searchPrev()" class="user-select-none cursor-pointer ponter-events-all">
        <font-awesome-icon
          icon="chevron-left"
          class="text-muted text-lg"
          :style="searchResults.length && searchPosition > 0 ? {} : { opacity: 0.2 }"
        />
      </a>
      <a @click="searchNext()" class="user-select-none cursor-pointer ponter-events-all">
        <font-awesome-icon
          icon="chevron-right"
          class="text-muted text-lg"
          :style="
            searchResults.length && searchPosition < searchResults.length - 1
              ? {}
              : { opacity: 0.2 }
          "
        />
      </a>
      <SuggestionButton v-if="searchResults.length" class="info sm" style="white-space: nowrap">
        <span
          >{{ searchPosition > -1 ? `${searchPosition + 1} of ` : ''
          }}{{ searchResults.length }}</span
        >
      </SuggestionButton>
      <a @click="() => filter()"><font-awesome-icon icon="xmark" class="text-muted text-lg" /></a>
      <!--      <font-awesome-icon icon="chevron-left" @click="searchPrev" /> -->
      <!--      <font-awesome-icon icon="chevron-right" @click="searchNext" /> -->
    </div>

    <!-- Action Bars -->
    <BtnBar
      ref="refColumnActions"
      headless
      :fix-to="refColumnButton"
      :show-button="false"
      :actions="columnActionsArray"
    >
    </BtnBar>
    <BtnBar
      ref="refCopyPaste"
      headless
      :fix-to="contextMenuLocation"
      :show-button="false"
      :actions="copyPasteActionsArray"
    >
    </BtnBar>

    <!--- sheet options -->
    <div
      class="flex flex-row gap-1 text-secondary p-1 select-none cursor-pointer"
      v-if="loaded"
      :style="sheetOptionsStyle"
    >
      <div class="sheetOption">
        <slot name="sheetOption"></slot>
      </div>
      <Btn
        link
        rounded
        :action="handleSheetChooser"
        v-tooltip="'Choose sheet...'"
        v-if="dataSheets.length > 1"
      >
        <font-awesome-icon icon="chevron-down" />
      </Btn>
      <Btn
        data-id="searchButton"
        link
        rounded
        :action="handleSheetFilter"
        v-tooltip="'Search sheets...'"
      >
        <font-awesome-icon icon="magnifying-glass" />
      </Btn>
      <Btn
        data-id="visibilitySettingsButton"
        link
        rounded
        :action="() => refOptions.open()"
        v-tooltip="'Toggle column visibility...'"
        ref="refGearButton"
        v-if="dataSheets[topSheet]?.superHeaders?.length > 0"
        class="transition"
      >
        <font-awesome-icon icon="gear" />
      </Btn>
      <Btn
        v-if="!isItemCatalog"
        data-id="exportButton"
        link
        rounded
        :action="() => exportToXlsx()"
        v-tooltip="'Export to Excel (.xlsx)'"
        ref="refExportButton"
        class="transition"
      >
        <font-awesome-icon icon="file-export" />
      </Btn>
      <Btn
        v-if="isItemCatalog"
        data-id="exportButton"
        link
        rounded
        :action="() => exportObjects('cost_type', 'Items', 'ItemExports')"
        v-tooltip="'Export to Excel (.xlsx)'"
        ref="refExportButton"
        class="transition"
      >
        <font-awesome-icon icon="file-export" />
      </Btn>
      <div
        class="ml-1"
        data-id="resetOrderButton"
        v-if="
          dataSheets &&
          topSheet !== null &&
          sheetCollapseGroupsVisible[topSheet] &&
          ((dataSheets[topSheet].sorting && dataSheets[topSheet].sorting.length > 0) ||
            (dataSheets[topSheet].grouping && dataSheets[topSheet].grouping.length > 0))
        "
      >
        <SuggestionButton @click="resetOrder" severity="danger" class="warning sm">
          Reset order
        </SuggestionButton>
      </div>
      <slot name="sheetOptionAfter"></slot>
    </div>

    <!-- Sheet chooser -->
    <Choose
      :key="currentCell.join(',')"
      ref="refSheetChooser"
      :emitDelay="50"
      :value="topSheet"
      :emit-on-destroy="false"
      :emit-on-blur="false"
      :static-set="sheetOptions"
      @selectedValue="handleSheetSelected"
      :return-array="false"
      ><span></span
    ></Choose>

    <div
      class="text-xs rounded-md bg-surface-800/90 text-flame-white py-1 px-2 opacity-80 pointer-events-none hover:opacity-10"
      :style="tooltipStyle"
    >
      <span>{{ tooltip }}</span>
    </div>

    <div class="collapse-group-highlight" :style="collapseGroupHighlightStyle"></div>

    <div class="grab-options" :style="grabStyle">
      <a
        class="grab-option cursor-pointer user-select-none select-none"
        v-for="option in grabActions"
        :key="option"
        @click="handleGripOptionClick(option)"
      >
        <svg-icon :svg="option.icon.replace('svg:', '')" v-if="option.icon.includes('svg')" />
        <font-awesome-icon :icon="option.icon" v-else fixed-width />
        <span>{{ option.name }}</span>
      </a>
    </div>

    <MiniModal v-if="dataSheets[topSheet]?.superHeaders?.length > 0" scrollable ref="refOptions">
      <CardSection>
        <template #title><font-awesome-icon icon="eye" /> Toggle column sections</template>
        <CardList :key="index" v-for="(sheet, index) in dataSheets">
          <CardListField :key="shindex" v-for="(sh, shindex) in dataSheets[topSheet]?.superHeaders">
            <span>
              {{ sh.title }}

              <span class="font-normal text-sm">{{ superHeaderColumnNames[index][shindex] }}</span>
            </span>

            <div class="flex justify-end items-center">
              <InputSwitch
                @click="
                  handleClickSuperHeading({
                    force: true,
                    element: {
                      superHeadingIndex: shindex,
                      sheetIndex: index
                    }
                  })
                "
                :modelValue="`${shindex}` in (collapsedSuperHeaders?.[index] ?? {}) ? false : true"
              />
            </div>
          </CardListField>
        </CardList>

        <!--        <CardList>-->
        <!--          <CardListField-->
        <!--            v-for="sh in dataSheets.superHeaders">-->

        <!--          </CardListField>-->
        <!--        </CardList>-->
      </CardSection>
    </MiniModal>

    <!--    <div :style="{-->
    <!--        ...hoverDisplayStyle,-->
    <!--      }" >{{hoverElementName}}</div>-->
    <Loader :loading="gripRowLoading" />
  </div>
</template>

<style lang="scss" rel="stylesheet/scss">
$bolsterBlue: $blue-print;

.grab-options {
  background: $primary-900;
  color: $primary-100;
  border-radius: 3px;
  width: fit-content;
  min-width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  padding: 0.2em;
  transition: opacity 0.1s ease-out;
  opacity: 0;
  z-index: $z-modal;

  .grab-option {
    font-weight: normal;
    font-size: 0.9em;
    margin: 0.1em 0.1em;
    padding: 0em 0.6em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    flex-direction: row;
    height: 2em;
    max-height: 2em;
    white-space: nowrap;

    &:hover {
      background: $primary-700;
    }

    :first-child {
      margin-right: 0.5em;
      flex: 1em 0;
    }
  }
}

.gripclick {
  cursor: grab;

  &:hover {
    cursor: grab;
  }
}

.insert-indicator {
  background: $bolsterBlue;
  border-radius: 2px;
}

.row-options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;

  height: fit-content;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  width: fit-content;
  max-width: 80px;
  height: 30px;
  margin-top: 5px;
  .sheet--selectBox {
    margin-left: 0.1em;
  }

  > :not(label) {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    align-items: center;
    font-size: 1.1em;
    color: $cool-gray-600;
  }

  input {
    margin: 0 !important;
  }
}

.collapse-group-highlight {
  border: 2px solid rgba($bolsterBlue, 0.4);
  border-radius: 10px;
}

.gutter-reset {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 1em;
}

.selectedRowActionsContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  background: $flame-white;
  width: fit-content;
  height: 2.5em;
  border-radius: 5px;
  box-shadow: $shadow;

  align-items: stretch;
  justify-content: center;
  font-size: 0.9em;

  overflow: hidden;

  white-space: nowrap;
  border: 1px solid $cool-gray-800;
  background: $flame-white;

  > a.button {
    //background: $bolsterBlue;
    //color: $flame-white !important;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.35em;
    padding: 0 1em;
    border-radius: 0;
    cursor: pointer;

    font-weight: 500;

    [data-icon] {
      width: fit-content !important;
      font-size: 1.5em;
      margin-right: 0.5em;
      position: relative;

      &.fa {
        top: -2px;
      }
    }

    background: $cool-gray-950;
    color: #2483e2 !important;

    &.dark {
      color: $cool-gray-300 !important;
      background: $cool-gray-950;
    }

    &:hover {
      background: $cool-gray-700;
      color: $cool-gray-300 !important;
    }

    & + a.button {
      border-left: 1px solid $cool-gray-700;
    }
  }
}

.sheet--selectBox {
  -moz-appearance: checkbox !important; /* For Firefox */
  -webkit-appearance: checkbox !important; /* For WebKit browsers (e.g., Chrome, Safari) */
  appearance: checkbox !important;

  position: relative;
  display: inline-block;

  width: 20px;
  height: 20px;
  input[type='checkbox'] {
    visibility: hidden;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $flame-white;
    border: 1px solid $cool-gray-600;
    border-radius: 0.25em;

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  input[type='checkbox']:checked + .checkmark {
    background-color: $bolsterBlue;
    border: 1px solid $bolsterBlue;

    &:after {
      display: block;
      left: calc(50% - 3.5px);
      top: calc(50% - 8px);
      width: 7px;
      height: 13px;
      border: solid $flame-white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.superHeaderCollapse {
  cursor: pointer;
  padding: 0 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $flame-white;

  [data-icon] {
    top: -1px;
  }
}

/* Mixins for Repeated Styles */
@mixin position-styles {
  position: absolute;
  top: 25px;
  right: 25px;
  padding: 0.1em 0.5em;
  border-radius: 5px;
}

@mixin scrollbar-styles($selector, $axis) {
  #{$selector} {
    position: absolute;
    border: 1px solid $cool-gray-300;
    background: rgba($cool-gray-200, 1);
    @include scrollbar-thumb-styles($axis);
  }
}

@mixin scrollbar-thumb-styles($axis) {
  .scrollbar-thumb {
    position: absolute;
    background: rgba($cool-gray-500, 1);
    border-radius: 10px;

    &:hover,
    &.dragging {
      background: rgba($cool-gray-600, 1);
    }
  }
}

@mixin button-styles {
  position: absolute;
  background-color: $bolsterBlue;
  color: $flame-white;
  border: none;
  border-radius: 10px;
  padding: 2px 7px;
  cursor: pointer;
  font-size: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

@mixin expander-styles {
  background: transparent;
  position: absolute;
  background: $bolsterBlue;
  border-radius: 4px;
}

@mixin origin-label-styles {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: $bolsterBlue;
  color: $flame-white;
  padding: 0.25em 0.5em;
  font-size: 12px;
  font-family:
    Cera Pro,
    Arial,
    sans-serif;
}

@mixin custom-div-styles {
  background: transparent;
  color: darken($bolsterBlue, 15%);
  border: 2px solid $bolsterBlue;
  font-weight: 500;
  text-align: center;
  padding: 0.1em 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  white-space: nowrap;
  font-size: 0.8em;
  user-select: none;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
}

@mixin editable-div-styles {
  border-radius: 0px;
  border: 2px solid $bolsterBlue;
  padding: 1px 2px 1px 4px;
  overflow: hidden;
  z-index: 1;

  &:focus {
    background-color: #fff;
    outline: 0;
    border: 2px solid $bolsterBlue;
    color: #222;
    width: fit-content !important;
    user-select: all;
    pointer-events: all;
  }
}

/* Main Container */
.sheets-container {
  position: relative;
  width: 100%;
  height: 100%;
  flex-basis: 100%;
  inset: 0;

  .inline-calculator-container {
    @include editable-div-styles;
  }

  #progressDiv {
    @include editable-div-styles;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 0;

    border: 0;

    > div {
      pointer-events: all;
      flex: 1 1 0%;
      min-height: 100%;
      opacity: 1;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &.ban {
        background-color: rgba($flame-white, 0.3) !important;
      }

      &.disabled {
        cursor: not-allowed;
      }

      .ban-step {
        border: 2px solid $deep-red-800; /* Red border for the rectangle */
        border-radius: 3px; /* Border radius for the rectangle */
        position: absolute;
        inset: 5px;
        z-index: 1;
        pointer-events: none;

        &::before {
          content: '';
          position: absolute;
          top: -2px;
          left: 0;
          width: 170%; /* Width of the slash */
          height: 2px; /* Thickness of the slash */
          background-color: red;
          transform: rotate(52deg); /* Rotate slash */
          transform-origin: 0 0;
        }
      }
    }
  }

  .progressMessage,
  .canvas-tooltip {
    //z-index: 1000;
    //position: absolute;
    //top: 0;
    //left: 0;
    //height: fit-content;
    //width: fit-content !important;
    //
    //z-index: 100;
    //border-radius: 0.5em;
    //
    //box-shadow: $shadow;
    //
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //
    //text-align: center;
    //
    //padding: 0.25em 1em;
    //
    //color: $flame-white;
    //background-color: $cool-gray-700;
    //
    //font-weight: normal;
    //
    //font-size: 0.7em;
    //
    //&.auto {
    //  font-weight: 500;
    //  color: $purple-500;
    //  background-color: lighten($purple-500, 40%);
    //}
  }

  /* Multi Select Indicator */
  #multiSelectIndicator {
    background: rgba($bolsterBlue, 0.2);
    position: absolute;
    pointer-events: none;

    &.selected {
      border: 2px solid $bolsterBlue;
    }
  }

  /* Canvas Section */
  #sheets-canvas {
    position: absolute;
    inset: 0;
    cursor: cell;
  }

  /* Debug Display */
  .display {
    @include position-styles;
    background: $cool-gray-400;
    color: $cool-gray-700;
    font-weight: 500;
    font-size: 0.9em;
    padding: 0.25em 1em;
    transition: opacity 0.1s ease;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  /* Scrollbars */
  .scrollbar-base {
    position: absolute;
    // border: 1px solid $cool-gray-300;
    background: rgba($cool-gray-200, 0.3);
    z-index: 1000;

    &:hover,
    &.dragging {
      background: rgba($cool-gray-200, 1);
    }
  }

  .scrollbar-thumb-base {
    position: absolute;
    background: rgba($surface-700, 1);
    border-radius: 2px;

    cursor: pointer;

    &:hover,
    &.dragging {
      background: rgba($surface-700, 1);
    }
  }
  $scrollbarWidth: 10px;
  .scrollbar-nub {
    @extend .scrollbar-base;
    height: $scrollbarWidth;
    width: $scrollbarWidth;
    bottom: 0;
    right: 0;
  }

  .vertical-scrollbar {
    @extend .scrollbar-base;
    top: 0;
    right: 0;
    bottom: $scrollbarWidth;
    width: $scrollbarWidth;
    height: calc(100% - $scrollbarWidth);
    transition: all 0.5s ease;

    .scrollbar-thumb {
      @extend .scrollbar-thumb-base;
      top: 0;
      left: 1px;
      width: calc(100% - 1px);
      height: 50px;

      &:hover,
      &.dragging {
        left: 1px;
        width: calc(100% - 1px);
      }
    }

    &:hover {
      width: $scrollbarWidth * 2;
    }
  }

  .horizontal-scrollbar {
    @extend .scrollbar-base;
    left: 0;
    bottom: 0;
    right: $scrollbarWidth;
    width: calc(100% - $scrollbarWidth);
    height: $scrollbarWidth;
    transition: all 0.5s ease;

    .scrollbar-thumb {
      @extend .scrollbar-thumb-base;
      left: 0;
      top: 1px;
      height: calc(100% - 1px);
      width: 50px;

      &:hover,
      &.dragging {
        top: 1px;
        height: calc(100% - 1px);
      }
    }

    &:hover {
      height: $scrollbarWidth * 2;
    }
  }

  /* Column Action Button */
  .column-button {
    @include button-styles;
  }
}

/* Column and Row Expanders */
#expanderCol,
#expanderRow {
  @include expander-styles;

  &#expanderCol {
    cursor: col-resize;
  }

  &#expanderRow {
    cursor: row-resize;
  }
}

/* Origin Label */
#originLabel {
  @include origin-label-styles;
}

/* Action Div */
#actionDiv {
  @include custom-div-styles;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 0.25em;
}

/* Editable Div */
#editableDiv {
  @include editable-div-styles;
}

/* Editable Div */
#chooseDiv {
  @include custom-div-styles;
  position: relative;

  .chooseDiv-inner {
    position: absolute;
    inset: 0;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

#searchContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 30em;
  height: 3em;

  background-color: $flame-white;
  z-index: 100;
  border-radius: 10px;

  box-shadow: $shadow;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.5em;

  > * {
    margin-left: 0.2em;
  }

  :first-child {
    margin-left: 0.1em !important;
  }

  &:last-child {
    margin-right: -0.2em;
  }

  [data-icon] {
    margin-top: -0.1em;
    font-size: 2em;
    color: $cool-gray-400;
    cursor: pointer;
    flex: 0 0 0.7em;
  }

  #searchInput {
    flex: 1 1 100%;
    max-width: unset;
    width: 100%;
    height: 100%;

    padding: 0 1em;

    text-align: left;
    vertical-align: middle;
    display: flex;
    align-items: center;
    outline: none;
  }
}

.sheetOptionsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 20px !important;
  flex: 0 1.5em;
  color: $bolsterBlue !important;

  a.optionsButton {
    font-size: 20px !important;
    flex: 0 1.5em;
    color: $bolsterBlue !important;
    margin-left: 0.25em;
  }

  .non-option {
    color: $cool-gray-800;
    font-size: 0.7em;
  }
}
</style>
