<template>
  <div class="flex flex-col">
    <Swatch
      :show-vendor="true"
      :color="value && Object.keys(value).length ? value : emptyColor"
      :action="openSelector"
    >
      <Btn
        severity="secondary"
        size="sm"
        class="w-full !rounded-none"
        v-if="value && Object.keys(value).length && editable && allowDeselect"
        @click="selectColor({})"
      >
        <font-awesome-icon icon="remove" />
        Clear default color
      </Btn>
    </Swatch>

    <Modal v-if="showModal" ref="selector" :closeable="true" :scrollable="false" size="lg">
      <template #header>
        <font-awesome-icon icon="palette" />
        Color picker
      </template>
      <template #body>
        <div class="flex flex-col gap-y-6 mt-2">
          <!-- Search bar -->
          <InputText
            v-model="searchPhrase"
            placeholder="Find a color name or number"
            class="!w-full"
          />

          <!-- Color list -->
          <div class="@container flex flex-col w-full h-[600px] items-center justify-center">
            <VirtualScroller
              :items="filteredColorGroups"
              :itemSize="100"
              orientation="vertical"
              class="w-full h-full"
            >
              <template v-slot:item="{ item }">
                <div
                  class="grid gap-1 mb-1 @[500px]:grid-cols-2 @[800px]:grid-cols-4 @[1000px]:grid-cols-7"
                >
                  <Swatch
                    v-for="(color, index) of item"
                    :key="index"
                    :color="color"
                    :action="() => selectColor(color)"
                  />
                </div>
              </template>
            </VirtualScroller>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import benjaminmooreLogo from '@/assets/BenjaminMoore-logo.png'
import Swatch from './Swatch.vue'
import benjaminmoore from './benjaminmoore'
import VirtualScroller from 'primevue/virtualscroller'
import InputText from 'primevue/inputtext'

export default {
  name: 'SelectionColor',
  components: {
    Swatch,
    VirtualScroller,
    InputText
  },
  props: {
    allowDeselect: {
      default: true
    },
    editable: {
      default: true
    },
    value: {
      required: true
    },
    placeholder: {
      default: 'Choose a color..'
    },
    vendor: {
      default: () => ({
        groupCount: 7,
        colorGroups: null
      })
    },
    logo: {
      default: ''
    }
  },
  emits: ['input'],
  data() {
    return {
      showModal: false,
      selected: null,
      searchPhrase: '',
      emptyColor: {
        vendor: '',
        vendorId: '',
        number: '',
        data: {},
        family: '',
        contrastColor: 'light',
        int: 1,
        ext: 1,
        sku: '',
        url: '',
        keywords: '',
        name: this.placeholder,
        hex: 'd5dadf'
      }
    }
  },

  computed: {
    logoImport() {
      if (this.logo) {
        return this.logo
      }

      return benjaminmooreLogo
    },
    colorGroups() {
      const groups = []
      Object.values(this.vendor.colorGroups || benjaminmoore.colorGroups).forEach((group) => {
        groups.push(group.colors)
      })
      return groups
    },
    filteredColorGroups() {
      if (!this.searchPhrase) return this.colorGroups

      return this.colorGroups.filter((group) => {
        const keywords = group.reduce(
          (acc, color) => `${acc} ${color.number.toLowerCase()} ${color.name.toLowerCase()}`,
          ''
        )
        return keywords.includes(this.searchPhrase.toLowerCase())
      })
    }
  },

  methods: {
    goToGroup(number) {
      c.scrollTo(this.$refs[`group-${number}`][0])
    },

    async openSelector() {
      if (!this.editable) return

      // optimizes rendering performance
      this.showModal = true
      await this.$nextTick()
      this.$refs.selector.open()
    },

    async closeSelector() {
      this.$refs.selector.close()
      await this.$nextTick()
      this.showModal = false
    },

    selectColor(color) {
      this.selected = color
      this.$emit('input', this.selected)
      if (this.showModal) this.closeSelector()
    }
  }
}
</script>
