<template>
  <modal
    v-bind="$props"
    class="modal-mini prompt"
    classes=""
    size="sm"
    :clickAway="false"
    ref="modal"
    :scrollable="false"
    :isResetPassword="true"
    :zIndex="zIndexComputed"
  >
    <template #header>
      <span class="!text-2xl">{{ modal.title }}</span>
    </template>
    <template #body>
      <div :class="done ? 'flex-middle justify-center' : 'my-5'">
        <CardSection style="width: 100%" class="m-0">
          <template #label>
            <span class="!text-sm" style="display: inline !important">{{ modal.message }}</span>
          </template>
          <template #subLabel>
            <span
              class="text-2xl"
              style="display: inline !important"
              v-if="modal.subMessage && modal.subMessage !== ''"
              >{{ modal.subMessage }}</span
            >
          </template>
          <field
            ref="input"
            @enter="enterHandler"
            v-if="modal.inputType !== 'choose'"
            v-model="value"
            :type="modal.inputType"
            :validate="{
              required: modal.required,
              format: modal.format
            }"
            focus-on-mount
            :format="modal.format"
            :emit-delay="50"
            :placeholder="modal.placeholder"
            class="!bg-flame-white !w-full max-w-none"
          />
          <choose
            :return-array="false"
            ref="input"
            @enter="done"
            v-else
            v-model="value"
            :emit-delay="300"
            :schema="`${modal.entityType}:${modal.entityType}_id`"
            :filters="modal.filters"
            :staticSet="modal.staticSet"
            :zIndexOverride="zIndexComputed + 5"
          />
        </CardSection>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-col justify-end sm:flex-row w-full gap-x-4 gap-y-4">
        <btn severity="tertiary borderless" size="lg" :action="cancel"> Cancel </btn>
        <btn
          ref="saveBtn"
          :disabled="!(!modal.required || value)"
          severity="primary"
          size="lg"
          hotkey="cmd-s"
          :action="done"
        >
          {{ modal.confirmText }}
        </btn>
      </div>
    </template>
  </modal>
</template>

<script>
import ModalMixin from './ModalMixin'

/**
 * Provide a Modal object with an actions object,
 * that has an action function that returns a promise.
 */
export default {
  name: 'Prompt',
  data() {
    return {
      value: this.modal.defaultValue
    }
  },
  mixins: [ModalMixin],
  mounted() {
    c.throttle(
      async () => {
        await this.$nextTick()
        c.focusInput(this.$refs.input)
      },
      { delay: 200 }
    )
  },
  computed: {
    zIndexComputed() {
      return this.$store.state.modal.topZIndex + 5
    },
    component() {
      return c.titleCase(this.type)
    }
  },
  methods: {
    enterHandler() {
      if (this.modal.inputType !== 'textarea') {
        this.done()
      }
    },
    cancel() {
      this.$refs.modal.close({ delay: 0 })
      this.modal.prompt(null)
    },
    done() {
      this.$nextTick(() => {
        this.modal.prompt(this.$refs.input.rawValue)
        this.$refs.modal.close()
      })
    }
  },
  props: {
    closeable: {
      default: false
    },
    expandable: {
      default: false
    },
    mask: {
      default: true
    }
  }
}
</script>

<style lang="scss" rel="text/scss">
.prompt .message-body {
  text-align: center;
}
</style>
