import BtnMixin from '../../mixins/Button'
import _ from '../../../../imports/api/Helpers'
import AutoCost from '../../../../imports/api/AutoCost.js'

// NOTE
// Implemented in composables/ItemAddon.js

export default {
  mixins: [BtnMixin],

  methods: {
    async getAddonFromObject(type, id, addonType = 'replace', preventIf = null) {
      // IF BLANK create new, then add
      let finalId = id
      if (id === 'blank') {
        const object = await this.$store.dispatch('create', {
          type,
          go: false,
          close: false
        })
        if (object === null) return null
        finalId = object[`${type}_id`]
      }

      return {
        ...this.defaultUpgrade,
        type,
        id: finalId,
        price: 0,
        addonType,
        name: '',
        asDimensionsRequired: [], // requiredDimensions,
        preventIf: preventIf || (addonType === 'option' ? 'anywhere' : 'sibling')
      }
    },

    async getSuggestedUpgrades() {
      const filters = {}
      let sameStage = []
      let sameEquation = []

      const idf = (obj) => `${obj.type}_id`
      const id = (obj) => obj[idf(obj)]

      // get other addons, to exclude them from search
      const omitIds = {}
      this.value.forEach((addon) => {
        if (!addon.id) return
        if (!(`${addon.type}_id` in omitIds)) omitIds[`${addon.type}_id`] = []
        omitIds[`${addon.type}_id`].push(addon.id)
      })

      // Add target
      omitIds[`${this.target.type}_id`] = _.cleanArray(
        [...(omitIds[`${this.target.type}_id`] || []), this.target[`${this.target.type}_id`]],
        true
      )

      // Turn it into a filter
      Object.keys(omitIds).forEach((idField) => {
        if (omitIds[idField] && omitIds[idField].length) {
          filters[idField] = `!${omitIds[idField].join('&&!')}`
        }
      })

      // Add dimension measure type as a type of filter
      if (this.target.dimension_measure_type) {
        filters.dimension_measure_type = `${this.target.dimension_measure_type}||NULL`
      }

      if (this.target.stage_id) {
        filters.stage_id = this.target.stage_id

        const { set } = await this.$store.dispatch('CostType/search', {
          filters: _.imm(filters),
          searchTypes: ['cost_type', 'assembly'],
          limit: 10
        })

        sameStage = set
      }

      if (this.target.cost_type_qty_equation) {
        filters.cost_type_qty_equation = `${this.target.cost_type_qty_equation}||NULL`

        const { set } = await this.$store.dispatch('CostType/search', {
          filters: _.imm(filters),
          searchTypes: ['cost_type', 'assembly'],
          limit: 10
        })

        sameEquation = set
      }

      const { set: similarName } = await this.$store.dispatch('CostType/search', {
        filters: _.imm(filters),
        searchPhrase: this.target.cost_type_name || this.target.assembly_name,
        searchTypes: ['cost_type', 'assembly'],
        limit: 10
      })

      this.suggestedUpgrades = Object.values(
        [...similarName, ...sameEquation, ...sameStage].reduce(
          (acc, it) => ({
            ...acc,
            [id(it)]: it
          }),
          {}
        )
      )
    },
    getItemLivePrice(item) {
      return item.live_price_reference
    },
    getItemIndex(item) {
      const type = item.type === 'assembly' ? 'assembly' : 'cost_type'
      if (!item[`${type}_id`] && item.live_price_reference) {
        return `live_price:${item.live_price_reference}`
      }
      return `${type}:${item[`${type}_id`]}`
    },
    getAddonKey(addon) {
      const type =
        addon.type === 'assembly' ? 'assembly' : !addon.livePriceRef ? 'cost_type' : 'live_price'
      return `${type}:${addon.livePriceRef || addon.id}`
    },
    disableAddon(addon) {
      this.disabledAddons = [...this.disabledAddons, this.getAddonKey(addon)]
    },

    modifyAddon(addon, modified) {
      const vals = _.imm(this.valueLocal)
      vals.splice(this.indexOfAddon(addon), 1, modified)
      this.emit(vals)
    },

    removeAddon(index) {
      const newVal = _.imm(this.valueLocal)
      newVal.splice(index, 1)
      // this.valueLocal = newVal;
      this.emit(newVal)
    },

    indexOfAddon(addon) {
      const vl = _.imm(this.valueLocal)
      const found = vl.find((a) => a.type === addon.type && String(a.id) === String(addon.id))
      const index = !found ? -1 : vl.indexOf(found)
      return index
    },

    emit(addons) {
      const list = this.unique(addons)
      this.$emit('input', list)
    },

    async selectItems() {
      const selections = await this.$store.dispatch('openItemSelector', {
        mod: await this.$store.dispatch('Quote/getQuoteMod', {
          refId: this.refId,
          store: this.store
        })
      })

      return selections.reduce((acc, i) => {
        if (i.cost_type_is_addon_group) {
          this.$store.dispatch('alert', {
            message: 'Option group items cannot be upgrades.'
          })
          return acc
        }
        acc.push(`${i.type}:${i[`${i.type}_id`]}`)
        return acc
      }, [])
    },

    async addUpgradeItem(type = null, id = null) {
      let selections = []
      this.addLoading()

      if (!type || !id) {
        try {
          const items = await this.selectItems()
          for (const item of items) {
            if (!AutoCost.isAutoCostObjectId(item)) selections.push(item)
            else {
              try {
                this.$store.dispatch('alert', { message: 'Saving AutoCost item to your catalog.' })
                const [itemType, itemId] = item.split(':')
                const response = await this.$store.dispatch('ajax', {
                  path: 'live_price/saveLivePriceItemAsCostType',
                  data: {
                    service: itemType,
                    live_price_reference: itemId
                  }
                })
                selections.push(response.payload.message)
              } catch (e) {
                this.$store.dispatch('alert', {
                  error: true,
                  message:
                    e.message ||
                    'Could not add live item as addon. Try saving the item to you catalog first.'
                })
              }
            }
          }
        } catch (e) {
          this.endLoading()
          throw e
        }
      } else {
        selections = [`${type}:${id}`]
      }

      // remove from suggested if found
      const suggested = _.imm(this.suggestedUpgrades)
      const inSuggested = suggested.find((addon) => addon.type === type && addon.id === id)
      if (inSuggested) {
        suggested.splice(suggested.indexOf(inSuggested), 1)
        this.suggestedUpgrades = suggested
      }

      const toEmit = []
      await Promise.all(
        selections.map(async (selection) => {
          if (!selection) return

          if (this.valueLocal.map((a) => `${a.type}:${a.id}`).includes(selection)) return

          const [itemType, itemId] = selection.split(':')

          const addonItem = await this.getAddonFromObject(itemType, itemId, 'replace', 'sibling')

          if (!addonItem) return

          toEmit.push(addonItem)
        })
      )

      this.emit([...this.valueLocal, ...toEmit])

      // load new addons
      await this.$store.dispatch('Quote/recalcAddons', { refIds: [this.reference], loading: false })

      this.endLoading()
    },

    unique(addons) {
      const keys = []
      return _.imm(addons)
        .sort((addon) => (addon.bulk ? -1 : 1))
        .filter((addon) => {
          const key = this.getAddonKey(addon)
          const alreadyFound = keys.includes(key)
          keys.push(key)
          return (
            !alreadyFound &&
            ((addon.bulk && Object.keys(addon.bulk).length) ||
              (addon.id && addon.id !== 'undefined') ||
              Boolean(addon.livePriceRef))
          )
        })
    },

    async addOptionalItem() {
      const selections = await this.selectItems()

      const toEmit = []
      await Promise.all(
        selections.map(async (selection) => {
          if (!selection) return

          if (this.valueLocal.map((a) => `${a.type}:${a.id}`).includes(selection)) return

          const [itemType, itemId] = selection.split(':')

          const addonItem = await this.getAddonFromObject(itemType, itemId, 'replace', 'sibling')

          if (!addonItem) return

          toEmit.push(addonItem)
        })
      )

      this.emit([...this.valueLocal, ...toEmit])
    },

    async getCurrentAsBulkAddon() {
      return this.$store.dispatch(`${this.store}/getSelectedAsBulkAddon`, {
        store: this.store,
        refId: this.reference
      })
    },

    /**
     * Approve the replace or optinal item
     * @params object object The audited full object
     *  ie: { type: 'assembly', assembly_id: 123 } etc
     * @params object addon The addon pseudo-object,
     *  ie: { addonType: 'option', type: 'assembly', id: 123 }
     */
    get(addon) {
      return this.$store.dispatch('Quote/selectAddon', {
        addonId: addon.id,
        addonType: addon.type,
        refId: this.reference
      })
    }
  },

  async mounted() {
    if (this.suggestUpgrades) this.getSuggestedUpgrades()

    // if (this.showCurrent) this.currentAddonBulk = await this.getCurrentAsBulkAddon('replace')
  },

  data() {
    return {
      suggestedUpgrades: [],
      disabledAddons: [],
      defaultUpgrade: _.imm(_.defaultAddon),
      scrolledIn: true,
      revertList: [],
      currentAddonBulk: null
    }
  },
  watch: {
    show(v) {
      this.$emit('show', v)
    }
  },
  computed: {
    /**
     * Create object that will be mixed in
     * with any new externally created object
     * @returns {{}}
     */
    embueCreate() {
      const keepFields = [
        'parent_cost_type_id',
        'cost_type_qty_equation',
        'assembly_qty_equation',
        'labor_type_id',
        'labor_type_name',
        'trade_type_id',
        'trade_type_name',
        'stage_id',
        'stage_name',
        'unit_of_measure_id',
        'unit_of_measure_name',
        'unit_of_measure_abbr',
        'dimension_measure_type',
        'cost_type_is_subcontracted',
        'cost_type_has_labor',
        'cost_type_has_materials',
        'cost_type_material_waste_factor_net',
        'cost_type_materials_purchase_qty_per_unit',
        'purchase_unit_of_measure_id',
        'purchase_unit_of_measure_name',
        'purchase_unit_of_measure_abbr'
      ]

      const ind = this.target
      return keepFields.reduce(
        (acc, f) => ({
          ...acc,
          [f]: ind[f] || null
        }),
        {}
      )
    },
    isAnUpgrade() {
      return (
        this.norm[this.reference] &&
        (this.norm[this.reference].addon_is_upgraded || this.norm[this.reference].item_is_upgraded)
      )
      // return this.object.upgradesOriginalKey
      //   && this.object.upgradesOriginalKey !== this.upgradeKey;
    },
    valueLocal() {
      return this.unique(Array.isArray(this.value) ? this.value : []).map((addon) => ({
        ...addon,
        key: this.getAddonKey(addon)
      }))
    },
    show() {
      return this.filteredList.length && this.scrolledIn
    },
    addonList() {
      return this.valueLocal
    },
    filteredList() {
      // Check to see if the item already exists as a sibling or child
      // of the target
      const addons = this.unique([...this.addonList, ...this.revertList]).filter((addon) => {
        const index = this.getAddonKey(addon)
        if (index === this.currentItemIndex) return false
        return (
          !this.disabledAddons.includes(index) &&
          !addon.isgrp &&
          ((addon.addonType === 'replace' && this.upgrades) ||
            (addon.addonType === 'option' && this.optionals))
        )
      })

      if (this.showCurrent && this.currentAddonBulk && !this.target.cost_type_is_addon_group) {
        addons.unshift(this.currentAddonBulk)
      }

      return addons.sort((a) => {
        if (a.bulk && Object.keys(a.bulk).length) return -1
        if (a.bulk && Object.keys(a.bulk).length) return 1
        return 0
      })
    },
    norm() {
      return this.$store.state[this.store].normalized
    },
    target() {
      return this.original || this.norm[this.reference]
    },
    parentRefId() {
      return this.target.parentRefId
    },
    currentItemIndex() {
      return this.getItemIndex(this.target)
    },
    currentItemLivePriceRef() {
      return this.getItemLivePrice(this.target)
    },
    currentPrice() {
      return this.target[
        this.target.type === 'assembly' ? 'quote_subtotal_net' : 'cost_item_price_net_base'
      ]
    }
  },
  props: {
    /**
     * Specify the target, or leave blank
     */
    original: {
      default: null
    },
    suggestUpgrades: {
      default: false
    },
    title: {
      default: 'Other clients chose these upgrades:'
    },
    value: {
      type: Array
    },
    reference: {
      required: false
    },
    store: {
      type: String,
      default: 'Quote'
    },
    editable: {
      default: false
    },
    optionals: {
      default: true
    },
    upgrades: {
      default: true
    },
    dimensions: {
      type: Object
    },
    showCurrent: {
      default: false
    }
  },
  emits: ['input', 'show']
}
