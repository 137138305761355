<script setup>
import { ref, computed, watch, defineProps, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useSidePanel as useSidePanelStore } from '@/stores/sidepanel'
import useServices from '@/components/services/Services'
import useSidePanel from '@/components/layout/panel/SidePanel'
import useSearch from '@/components/composables/Search'
import ScrollPanel from 'primevue/scrollpanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'
import BolsterWordmark from '@/assets/logos/Bolster_Highlight_Yellow.svg'
import BolsterIcon from '@/assets/logos/Bolster_Icon_Fill_Yellow.png'
import BolsterBlackIcon from '@/assets/SVG/Bolster_Icon_Black.svg'
import SidePanelUser from '@/components/layout/panel/SidePanelUser.vue'
import SidePanelContext from '@/components/layout/panel/SidePanelContext.vue'
import { useActivityChannels } from '@/components/composables/ActivityChannels'

defineProps({
  scope: {
    type: String
  }
})

const $store = useStore()
const $route = useRoute()
const $router = useRouter()
const sidePanelStore = useSidePanelStore()
const topContext = ref()
const bottomContext = ref()
const showAllMessages = ref(false)

const { services, serviceItems, menuContext } = useServices()

const { open } = useSearch()

const { allItems, collapsedItems, setStarred } = useSidePanel()

const { getOtherChannelParticipants, getFormattedChannelName, getTotalChannelNotificationsLength } =
  useActivityChannels()

const defineContextMenus = () => {
  if (!services.value) return
  const { topDropDown, bottomDropDown } = services.value
  topContext.value = topDropDown
  bottomContext.value = bottomDropDown
}

const onChannelClick = (channel) => {
  const scopeRoute = $router.currentRoute.value.params.scopeRoute
  const context = $router.currentRoute.value.meta.menuContext || 'company'
  const route = channel
    ? `/${scopeRoute}/${context === 'client' ? 'client-' : ''}messages/${channel.channel_type}/${channel.channel_type_id}`
    : `/${scopeRoute}/${context === 'client' ? 'client-' : ''}messages`
  $router.push(route)
}

const companyId = computed(() => $store.state.session.company?.company_id?.toString())

const channels = computed(() => {
  const channels = Object.values($store.state.activityChat.channels)
    .map((channel) => {
      const channelId = channel.channel_id
      const channelName = getFormattedChannelName(channel)
      const others = getOtherChannelParticipants(channel)
      const totalNotifs = getTotalChannelNotificationsLength(channelId)
      const group = channel.channel_members?.items.length > 2
      const avatar = channel.channel_type === 'CHAT' && !group ? channelName : null
      const image = channel.channel_type === 'SUPPORT' ? BolsterBlackIcon : null
      let icon =
        group && channel.channel_type === 'CHAT'
          ? 'hashtag'
          : channel.channel_type === 'COMPANY'
            ? 'scroll'
            : channel.channel_type === 'COMPANY_GENERAL'
              ? 'walkie-talkie'
              : channel.channel_type === 'COMPANY_COMPANY'
                ? 'shop'
                : channel.channel_type !== 'SUPPORT'
                  ? 'house-building'
                  : null
      return {
        channel,
        channelName,
        channelId,
        others,
        totalNotifs,
        group,
        avatar,
        image,
        icon
      }
    })
    .sort(
      (
        { channel: channelA, totalNotifs: totalNotifsA },
        { channel: channelB, totalNotifs: totalNotifsB }
      ) => {
        if (channelA.channel_type === 'COMPANY') return -1
        if (channelB.channel_type === 'COMPANY') return 1
        if (channelA.channel_type === 'COMPANY_GENERAL') return -1
        if (channelB.channel_type === 'COMPANY_GENERAL') return 1
        if (channelA.channel_type === 'SUPPORT') return -1
        if (channelB.channel_type === 'SUPPORT') return 1
        return totalNotifsA > totalNotifsB ? -1 : 1
      }
    )
  if (!$router.currentRoute.value.params.scopeRoute) return []
  let nonImportantChannelsCount = 0

  return [
    {
      divider: true
    },
    {
      label: 'Messages',
      highlight: false,
      starred: true,
      icon: 'walkie-talkie',
      command: () => onChannelClick(),
      class: 'font-medium text-surface-800',
      containerClass: 'font-medium text-surface-800 !-ml-2'
    },
    ...channels.reduce((acc, channelMeta) => {
      const channel = channelMeta.channel
      if (channel.archived || (channel.company_id && channel.company_id !== companyId.value)) {
        return acc
      }

      if (channelMeta?.totalNotifs <= 0) {
        nonImportantChannelsCount++
      }

      if (
        (!showAllMessages.value && nonImportantChannelsCount > 5) ||
        (channel?.parent_channel_id !== 'NULL' &&
          $store.state.activityChat.channels[channel.parent_channel_id])
      ) {
        return acc
      }

      acc.push({
        label: channelMeta.channelName,
        badge: channelMeta.totalNotifs,
        avatar: channelMeta.avatar,
        image: channelMeta.image,
        members: channelMeta.others,
        icon: !channelMeta.avatar ? channelMeta.icon : null,
        command: () => onChannelClick(channelMeta.channel),
        class: 'text-sm font-normal text-surface-700 dark:text-white/80',
        containerClass: 'font-medium text-surface-800 -ml-1'
      })
      return acc
    }, []),
    ...[
      showAllMessages.value
        ? {}
        : {
            label: 'More',
            icon: 'fas fa-ellipsis',
            command: () => (showAllMessages.value = true),
            class: 'text-surface-800 text-left !font-medium !text-[16px]',
            containerClass: '!-ml-2',
            noStar: true,
            noHide: true
          }
    ]
  ]
})

const bolsterLogo = computed(() => {
  return sidePanelStore.collapsed ? BolsterIcon : BolsterWordmark
})

const menuItems = computed(() => [...collapsedItems.value, ...channels.value])

menuContext.value = $route.meta.menuContext

watch(
  [
    () => $route.meta.menuContext,
    () => $store.state.session.scope,
    () => $store.state.session.company,
    () => $store.state.session.user,
    () => $store.state.session.quote
  ],
  () => {
    menuContext.value = $route.meta.menuContext
    defineContextMenus()
    allItems.value = serviceItems.value
    setStarred()
  },
  {
    immediate: true
  }
)

// @todo not needed if immediate set to true on watcher above
// onMounted(() => {
//   defineContextMenus()
//   allItems.value = serviceItems.value
//   setStarred()
// })

const createDongle = inject('CreateDongle')

const clientContext = computed(() => $router.currentRoute.value?.meta?.menuContext === 'client')

const clientCompanyLogo = computed(() => {
  const companyLogoId = $store.state.session.company.company_logo_file_id
  const logo = c.link(`file/view/${companyLogoId}`, {}, true, _.getStorage('scope'))
  const companyName = $store.state.session.company.company_name_short
  return sidePanelStore.collapsed ? logo || '' : logo || companyName
})
</script>

<template>
  <div class="w-full h-screen flex flex-col justify-stretch pt-safe-0">
    <!-- Side menu bolster logo -->
    <div
      class="pl-4 pr-safe-4 h-[60px] w-full m-0 flex flex-row items-center border-b md:border-none border-cement-600"
      :class="{
        'justify-between': !sidePanelStore.collapsed,
        'justify-center': sidePanelStore.collapsed,
        'md:bg-pitch-black': !clientContext,
        'md:bg-flame-white': clientContext
      }"
    >
      <img
        :src="clientContext ? clientCompanyLogo : bolsterLogo"
        class="hidden md:inline-block h-3/5 aspect-auto"
        alt="Bolster wordmark"
      />
      <span class="md:hidden text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="md:hidden cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>
    <!-- Side menu bolster logo -->

    <!-- Side menu company section -->
    <SidePanelContext
      :key="`${menuContext}-${$store.state.session.company?.company_name}-${$store.state.session.user?.user_id}-${JSON.stringify($store.state.session.scope)}`"
      v-if="topContext"
      :contextMenuItems="topContext.links"
      :title="topContext.title"
    />
    <!-- Side menu company section  -->

    <ScrollPanel class="w-full sidePanel--scroll grow">
      <!-- Side menu searchbar -->
      <div
        v-if="sidePanelStore.shouldShowSidePanelSearch"
        class="w-full sticky top-0 z-10 pl-4 pr-safe-4 py-2 flex justify-center items-stretch gap-2 text-nowrap overflow-hidden bg-cement-100"
        :style="{ 'flex-direction': sidePanelStore.collapsed ? 'column' : 'row' }"
      >
        <div
          @click="() => open()"
          class="w-full flex cursor-text h-10 items-center border bg-cement-100 border-cement-900/30 hover:bg-flame-white hover:border-surface-800 text-cement-900/70 rounded transition"
          :class="{
            'justify-center': sidePanelStore.collapsed
          }"
        >
          <font-awesome-icon
            :icon="['far', 'search']"
            class="text-cement-900/50"
            :class="{
              'ml-2': !sidePanelStore.collapsed
            }"
          />
          <span v-if="!sidePanelStore.collapsed" class="ml-2 text-sm">Search everything</span>
        </div>
        <Button
          @click="createDongle.open()"
          v-tooltip="'Create something great...'"
          unstyled
          outlined
          class="h-100 px-4 text-cement-800 hover:border-pitch-black hover:bg-pitch-black hover:text-level-yellow rounded-sm size-10 transition"
          :class="{ 'mx-auto': sidePanelStore.collapsed }"
        >
          <font-awesome-icon :icon="['fas', 'plus']" />
        </Button>
      </div>
      <!-- Side menu searchbar -->

      <!-- Side menu navigation -->
      <SidePanelMenu :model="menuItems" />
      <!-- Side Menu navigation -->
    </ScrollPanel>

    <!-- Side menu profile section -->
    <SidePanelUser v-if="bottomContext" :contextMenuItems="bottomContext.links" />
    <!-- Side menu profile section -->
  </div>
</template>

<style scoped lang="scss">
.sidePanel--scroll {
  // subtract height of the logo section, context section and profile section
  height: calc(
    100vh - env(safe-area-inset-top, 0px) -
      (theme('spacing.16') + theme('spacing.16') + theme('spacing.12'))
  );
}
</style>
