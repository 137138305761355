import { ref, onBeforeUnmount, onMounted, getCurrentInstance } from 'vue'

export default {
  useCellResize(args) {
    const {
      refExpanderCol,
      refExpanderRow,
      getColWidth,
      showColumnButton,
      rowHeights,
      drawGrid,
      topSheet,
      clearFragments,
      getRowHeight,
      dataSheets,
      refCanvas,
      disableEventType,
      enableEventType,
      getRowId,
      getColumnEndFromCoords,
      getRowEndFromCoords
    } = args

    const startX = ref(0)
    const dragX = ref(0)
    const resizingRow = ref(null)

    const resizingColumn = ref(null)
    const startY = ref(0)
    const dragY = ref(0)

    const handleMousemoveCanvas = (eventData) => {
      const rect = refCanvas.value.getBoundingClientRect()

      if (eventData.clientX === 0 || eventData.clientY === 0) return
      const x = eventData.clientX - rect.left
      const y = eventData.clientY - rect.top

      //   showColumnButton.value = false;
      //   refExpanderCol.value.style.left = `${x}px`;
      //   refExpanderCol.value.style.display = 'block';
      //   dragX.value = x;
      if (startX.value) {
        // Dragging
        showColumnButton.value = false
        refExpanderCol.value.style.left = `${x}px`
        refExpanderCol.value.style.display = 'block'
        dragX.value = x
      }
      if (startY.value) {
        // Dragging
        showColumnButton.value = false
        refExpanderRow.value.style.top = `${y}px`
        refExpanderRow.value.style.display = 'block'
        dragY.value = y
      }
    }

    const handleColResizeHover = (eventData) => {
      if (startX.value) return
      const { x: left, y: top, height } = eventData.element.position

      const expanderColStyle = {
        display: 'block',
        top: `${top}px`,
        left: `${left + 3}px`,
        width: '4px',
        height: `${height}px`
      }
      Object.assign(refExpanderCol.value.style, expanderColStyle)
      const col = getColumnEndFromCoords(left, topSheet.value)
      resizingColumn.value = col

      // let [col, row, rx] = coords;
      // const [left, top, width, height] = bounding;
      // const [x] = event;
      //
      // const colLeft = (rx < 10 ? left : left + width) - 2;
      // const colBeyond = col > (colCount.value - 1);
      //
      // let expanderColStyle = {
      //   display: 'none',
      // };
      //
      // // Handle collapsed columns
      // if (getColWidth(col, topSheet.value) === 0) {
      //   for (let i = col; i >= 0; i -= 1) {
      //     if (getColWidth(i, topSheet.value) > 0) {
      //       col = i;
      //       break;
      //     }
      //   }
      // }
      // let nextShownCol = col - 1;
      // if (getColWidth(nextShownCol, topSheet.value) === 0) {
      //   for (let i = col; i >= 0; i -= 1) {
      //     if (getColWidth(i, topSheet.value) > 0) {
      //       nextShownCol = i;
      //       break;
      //     }
      //   }
      // }
      //
      // if (((isRowFirstOfSheet(row) && !fullHeadingHeight.value) || row < 0)
      //   && (rx < 10 || (width - (rx)) < 10)
      //   && !startX.value
      //   && (rx < 10 ? nextShownCol : col) >= 0
      //   && !colBeyond) {
      //   // Hover over resize indicator
      //   resizingColumn.value = (rx < 10 ? nextShownCol : col);
      //   showColumnButton.value = false;
      //   expanderColStyle = {
      //     display: 'block',
      //     top: `${sheetHeadingHeight.value + superHeadingHeight.value}px`,
      //     left: `${colLeft}px`,
      //     width: '4px',
      //     height: `${(canvasHeight.value || height)}px`,
      //   };
      //   Object.assign(refExpanderCol.value.style, expanderColStyle);
      // } else if (!startX.value) {
      //   // Hover out
      //   refExpanderCol.value.style.display = 'none';
      //   resizingColumn.value = null;
      // } else if (startX.value) {
      //   // Dragging
      //   showColumnButton.value = false;
      //   refExpanderCol.value.style.left = `${x}px`;
      //   refExpanderCol.value.style.display = 'block';
      //   dragX.value = x;
      // }
    }

    const disableRowResizeHover = ref(false)

    const handleRowResizeHover = (eventData) => {
      if (disableRowResizeHover.value) return
      if (startY.value) return
      const { y: top, x: left, width } = eventData.element.position

      const row = getRowEndFromCoords(top, topSheet.value)

      const expanderRowStyle = {
        display: 'block',
        top: `${top + 3}px`,
        left: `${left}px`,
        width: `${width}px`,
        height: '4px'
      }
      Object.assign(refExpanderRow.value.style, expanderRowStyle)
      resizingRow.value = row

      // const rowBeyond = row > (rowCount.value - 1);
      // let expanderRowStyle = {
      //   display: 'none',
      // };
      // const rowTop = (ry < 5 ? top : top + height) - 2;
      // if (((col === 0 && !rowHeadingWidth.value) || col < 0)
      //   && (ry < 5 || (height - (ry)) < 5)
      //   && !startY.value
      //   && (ry < 5 ? row - 1 : row) >= 0
      //   && !rowBeyond) {
      //   // HOver over resize indicator
      //
      // } else if (!startY.value) {
      //   // Hover out
      //   refExpanderRow.value.style.display = 'none';
      //   resizingRow.value = null;
      // } else if (startY.value) {
      //   // Dragging
      //   refExpanderRow.value.style.top = `${y}px`;
      //   refExpanderRow.value.style.display = 'block';
      //   dragY.value = y;
      // }
    }

    const handleRowResizeMouseout = () => {
      if (startY.value) return
      refExpanderRow.value.style.display = 'none'
    }

    const handleColResizeMouseout = () => {
      if (startX.value) return
      refExpanderCol.value.style.display = 'none'
    }

    const handleMouseDownRowResize = (event) => {
      const rect = refCanvas.value.getBoundingClientRect()
      const y = event.clientY - rect.top
      startY.value = y
      dragY.value = y
      refExpanderRow.value.style.width = 'calc(100% - 4px)'

      const transparentPixel = new Image()
      transparentPixel.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
      event.dataTransfer.setDragImage(transparentPixel, 0, 0)

      disableEventType('mouseover')
    }

    const handleMouseDownColResize = (event) => {
      const rect = refCanvas.value.getBoundingClientRect()
      const x = event.clientX - rect.left
      startX.value = x
      dragX.value = x
      refExpanderCol.value.style.height = 'calc(100% - 4px)'

      const transparentPixel = new Image()
      transparentPixel.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
      event.dataTransfer.setDragImage(transparentPixel, 0, 0)

      disableEventType('mouseover')
    }

    const handleMouseUpColResize = () => {
      if (resizingColumn.value === null) {
        startX.value = 0
        dragX.value = 0
        refExpanderCol.value.style.display = 'none'
        return
      }

      const delta = dragX.value - startX.value

      const sheetIndex = topSheet.value
      const sheets = [...dataSheets.value]

      const previousWidth = getColWidth(resizingColumn.value, sheetIndex)

      const newWidth = Math.min(1000, Math.max(40, previousWidth + delta))

      sheets[sheetIndex].colWidths ??= {}
      sheets[sheetIndex].colWidths[resizingColumn.value] = Math.max(40, newWidth)

      dataSheets.value = sheets

      startX.value = 0
      dragX.value = 0
      resizingColumn.value = null
      refExpanderCol.value.style.display = 'none'

      if (!c.eq(delta, 0, 1)) {
        clearFragments(`^${sheetIndex}-`)

        // Redraw the grid
        c.throttle(
          () => {
            drawGrid()
            enableEventType('mouseover')
          },
          {
            delay: 200
          }
        )
      }
    }

    const handleMouseUpRowResize = () => {
      if (resizingColumn.value === null) {
        startY.value = 0
        dragY.value = 0
        refExpanderRow.value.style.display = 'none'
        return
      }

      const delta = dragY.value - startY.value
      const rh = { ...rowHeights.value }

      const newHeight = Math.min(400, Math.max(40, getRowHeight(resizingRow.value) + delta))

      const id = getRowId(resizingRow.value)
      rh[id] = newHeight
      rowHeights.value = rh

      startY.value = 0
      dragY.value = 0
      resizingRow.value = null
      refExpanderRow.value.style.display = 'none'

      if (!c.eq(delta, 0, 1)) {
        // Redraw the grid
        clearFragments(`^${topSheet.value}-`)

        c.throttle(
          () => {
            drawGrid()
            enableEventType('mouseover')
          },
          { delay: 200 }
        )
      }
    }

    const $this = getCurrentInstance().proxy

    onMounted(() => {
      $this.$on('mouseoverRowExpander', handleRowResizeHover)
      $this.$on('mouseoverColExpander', handleColResizeHover)
      $this.$on('mouseoutRowExpander', handleRowResizeMouseout)
      $this.$on('mouseoutColExpander', handleColResizeMouseout)

      refExpanderCol.value?.addEventListener('dragstart', handleMouseDownColResize)
      refExpanderRow.value?.addEventListener('dragstart', handleMouseDownRowResize)
      refExpanderCol.value?.addEventListener('dragend', handleMouseUpColResize)
      refExpanderRow.value?.addEventListener('dragend', handleMouseUpRowResize)

      $this.$on('mouseupColExpander', handleMouseUpColResize)
      $this.$on('mouseupRowExpander', handleMouseUpRowResize)

      refExpanderCol.value?.addEventListener('drag', handleMousemoveCanvas)
      refExpanderRow.value?.addEventListener('drag', handleMousemoveCanvas)

      // refExpanderCol.value.addEventListener('mousedown', handleMouseDownColResize);
      // refExpanderCol.value.addEventListener('mouseup', handleMouseUpColResize);
      //
      // refExpanderRow.value.addEventListener('mouseup', handleMouseUpRowResize);
      // refExpanderRow.value.addEventListener('mousedown', handleMouseDownRowResize);
    })

    onBeforeUnmount(() => {
      $this.$off('mouseoverRowExpander', handleRowResizeHover)
      $this.$off('mouseoverColExpander', handleColResizeHover)
      $this.$off('mouseoutRowExpander', handleRowResizeMouseout)
      $this.$off('mouseoutColExpander', handleColResizeMouseout)

      $this.$off('mouseupColExpander', handleMouseUpColResize)
      $this.$off('mouseupRowExpander', handleMouseUpRowResize)

      refExpanderCol.value?.removeEventListener('drag', handleMousemoveCanvas)
      refExpanderRow.value?.removeEventListener('drag', handleMousemoveCanvas)

      refExpanderCol.value?.removeEventListener('dragstart', handleMouseDownColResize)
      refExpanderRow.value?.removeEventListener('dragstart', handleMouseDownRowResize)

      refExpanderRow.value?.removeEventListener('dragend', handleMouseUpRowResize)
      refExpanderCol.value?.removeEventListener('dragend', handleMouseUpColResize)
    })

    return {
      startX,
      startY,
      resizingRow,
      resizingColumn,
      handleRowResizeHover,
      handleColResizeHover,
      disableRowResizeHover
    }
  }
}
