<script setup>
import {
  ref,
  watch,
  defineModel,
  onBeforeMount,
  onMounted,
  computed,
  provide,
  defineExpose,
  onBeforeUnmount
} from 'vue'
import ItemVariations from '@/components/composables/ItemVariations.js'
import StringField from '@/components/ui/Calculator/StringField.vue'
import _ from '../../../../imports/api/Helpers.js'
import FileList from '@/components/ui/FileList.vue'
import VariationsSheets from '@/components/Sheets/quote/variations/Variations.vue'
import { useStore } from 'vuex'
import Choice from '@/components/ui/Choice/Choice.vue'
import AutoCost from '../../../../imports/api/AutoCost'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  object: { type: Object, required: false }
})

const emit = defineEmits(['close'])

const mobject = defineModel('object')
// const $store = useStore()

const refId = ref(props.refId || props.reference)
const type = ref(props.type || 'cost_item')
const store = ref(props.store || props.storeName)

const refVariationsSheet = ref(null)

const {
  norm,
  variations,
  orderedVtypes,
  target,
  optionGroupName,
  optionGroupDesc,
  selectionAudience,
  addTrait,
  addVariationType,
  removeVariationType,
  removeTrait,
  // commit,
  setupMockAddons,
  loadMissingPermutationItems,
  generatePermutations,
  convertItemToCostType,
  setItemVariation,
  itemsByUid,
  convertCostTypeToVariationItem,
  costTypeIdToUid,
  setItem,
  getPermutationKey,
  getItemFromPermutationArray,
  addVariationItems,
  commit,
  getSavedItems,
  getLivePriceItems,
  usedVariationTypeTraits
} = ItemVariations.useItemVariations({
  object: mobject,
  type: type.value,
  store: store.value,
  refId: refId.value,
  autoload: false
})

provide('itemsByUid', itemsByUid)
provide('getPermutationKey', getPermutationKey)

const itemsDirty = ref(false)
const isDirty = computed(() => itemsDirty.value || variations.value.items?.some((item) => !item.id))
const isDirtyHandler = (is) => {
  itemsDirty.value = is
}

const importLivePriceItems = (allItems) => {
  const livePriceItems = allItems.filter((item) => item.livePriceRef)
  for (const livePriceItem of livePriceItems) {
    syncItemFromCostType(livePriceItem, livePriceItem.uid)

    for (const field in livePriceItem) {
      const value = livePriceItem[field]

      if (/vtype\./.test(field)) {
        const vtype = field.replace('vtype.', '')
        const vtypeIndex = variations.value.variationTypes.findIndex(
          (existingVtype) => existingVtype.name === vtype
        )
        // Add trait if does not exist already for the vtype
        const traitIndex = variations.value.variationTypes[vtypeIndex].traits.findIndex(
          (tr) => (tr.name ?? '').toLowerCase() === (value ?? '').toLowerCase()
        )

        if (traitIndex === -1) {
          addTrait(vtypeIndex, {
            name: value
          })
        }

        setItemVariation(livePriceItem.uid, vtype, value)
      }
    }
  }
}

const saveItems = async () => {
  // save this parent item
  let parentId =
    $store.state[store.value].normalized[refId.value].oVariations?.parentId ??
    $store.state[store.value].normalized[refId.value].cost_type_id

  const isNew = !parentId

  if (!parentId) {
    try {
      ;({
        object: { cost_type_id: parentId }
      } = await $store.dispatch('CostType/save', {
        go: false,
        alert: false,
        object: $store.state[store.value].normalized[refId.value]
      }))
    } catch (e) {
      if (e.message.includes('already')) {
        const newName = `${$store.state[store.value].normalized[refId.value].cost_type_name} COPY ${Date.now()}`
        $store.dispatch(`alert`, {
          message: `There is already an item with that name, so we will call this one ${newName}. Continuing...`
        })
        // change name
        await $store.dispatch(`${store.value}/field`, {
          changes: {
            [refId.value]: {
              cost_type_name: newName
            }
          },
          explicit: true
        })
        ;({
          object: { cost_type_id: parentId }
        } = await $store.dispatch('CostType/save', {
          go: false,
          alert: false,
          object: $store.state[store.value].normalized[refId.value]
        }))
      } else throw e
    }
    $store.dispatch(`${store.value}/field`, {
      changes: {
        [refId.value]: {
          cost_type_id: parentId
        }
      },
      explicit: true
    })
  }

  // if not set, THIS is the parent
  variations.value.parentId = parentId

  // save children
  const { set } = await refVariationsSheet.value.refEntityList.save(
    isDirty.value,
    (row) => !row.livePriceRef,
    importLivePriceItems
  )

  // after new IDS are loaded, commit to store
  commit()

  // Items will now sync with ids, then save the parent so they can be filtered in search and catalog:
  await Promise.all([
    set.length &&
      isNew &&
      $store.dispatch('CostType/partialUpdate', {
        simulteanous: true,
        alert: false,
        selected: set
          .filter((obj) => !obj.variation_parent_cost_type_id)
          .map((obj) => ({
            type: 'cost_type',
            cost_type_id: obj.cost_type_id,
            variation_parent_cost_type_id: parentId
          }))
      }),
    ({
      object: { cost_type_id: parentId }
    } = await $store.dispatch('CostType/partialUpdate', {
      go: false,
      alert: false,
      selected: [
        {
          oMeta: norm.value[refId.value].oMeta ?? {},
          oVariations: variations.value,
          type: 'cost_type',
          cost_type_id: parentId
        }
      ]
    }))
  ])

  $store.dispatch('alert', {
    message: `Saved this item and its variants. ${isNew && store.value === 'Quote' ? 'Save the quote to keep this item inside it.' : ''}`,
    success: true
  })
}

let aborting = false
onBeforeUnmount(() => {
  if (!aborting) {
    commit()
  }
})

const commitAndClose = async () => {
  await saveItems()
  commit()

  aborting = true // already committed don't need to do it again
  emit('close')
}
const commitAndStay = async () => {
  await saveItems()
  commit()
}
const abort = () => {
  aborting = true
  refVariationsSheet.value.refEntityList.flushBackup()
  emit('close')
}

defineExpose({
  commitAndStay,
  saveItems,
  abort,
  commitAndClose
})

onBeforeMount(() => {
  // variations.value.items = [] // @TODO remove just for testing
  if (!variations.value?.items?.length) {
    setupMockAddons()
  }
})
onMounted(async () => {
  if (!variations.value?.items?.length) {
    addPermutationItems(10)
  } else {
    // Should load based on ids
    const costTypeItems = variations.value?.items.filter((item) => !item.livePriceRef)
    const livePriceItems = variations.value?.items.filter((item) => item.livePriceRef)

    await getSavedItems(costTypeItems.map((item) => item.id) ?? [])
    await getLivePriceItems(livePriceItems.map((item) => item.uid) ?? [])
  }
})

const removeItems = ({ ids: uids }) => {
  let newItems = []

  for (let item of variations.value.items) {
    if (!uids.includes(item.uid)) newItems.push(item)
  }

  let load = false
  if (!newItems.length) {
    load = true
    const blankVi = getItemFromPermutationArray({})
    newItems.push(blankVi)
  }

  variations.value.items = newItems

  if (load) {
    const costTypes = newItems.map((vi) => ({
      ...convertItemToCostType(vi)
    }))
    loadItemsIntoSheet(costTypes, 0, null)
  }
}

const loadItemsIntoSheet = (costTypes, position, placeholderRow) => {
  refVariationsSheet.value.refEntityList.importRows(
    costTypes,
    false,
    position,
    true,
    placeholderRow
  )
}

const specificPermutationsList = ref({})
const refSpecificVariants = ref(null)
const addSpecificVariants = async () => {
  variations.value.variationTypes.forEach((vtype) => {
    specificPermutationsList.value[vtype.name] ??= []
  })
  refSpecificVariants.value.open()
}
const generateSpecificPermutations = () => {
  // add all when is empty
  for (const vtype in specificPermutationsList.value) {
    if (specificPermutationsList.value[vtype].length) continue
    specificPermutationsList.value[vtype] = variations.value.variationTypes
      .find((v) => v.name === vtype)
      .traits.map((tr) => tr.name)
  }
  const perms = generatePermutations(specificPermutationsList.value)
  addPermutationItems(perms.length, -1, null, null, perms)
}

const addPermutationItems = async (
  count,
  position = -1,
  duplicateFrom = null,
  placeholderRow = null,
  perms = undefined
) => {
  let costTypes = []

  // for autocost items //
  const rootRefId = c.getNormalizedRootRefId(norm.value)
  const quote = norm.value[rootRefId]
  const company = $store.state.session.company
  const zipcode = AutoCost.getAutoCostZipcode(company, quote)

  const dupes = Array.isArray(duplicateFrom)
    ? duplicateFrom
    : [...(duplicateFrom ? [duplicateFrom] : [])]
  if (dupes.length) {
    const blanks = []
    costTypes = await Promise.all(
      dupes.map(async (dupe) => {
        // associate each cost type with a variation item in parallel
        // generate the uid, and in vitem format, to add to variations.items in parallel
        const blankVi = getItemFromPermutationArray({})
        blanks.push(blankVi)
        let costType = dupe || dupes[0]

        if (dupe.type === 'autocost' && dupe.live_price_reference) {
          const res = await $store.dispatch('CostType/getDefaultLivePriceItem', {
            livePriceRef: dupe.live_price_reference,
            company,
            zipcode
          })
          costType = {
            ...res,
            livePriceRef: res.live_price_reference
          }
        }

        return {
          ...costType,
          uid: blankVi.uid,
          variations: blankVi.variations
        }
      })
    )
    addVariationItems(blanks) // add to variations.items[]
  } else {
    let newItems = loadMissingPermutationItems(perms?.length || count, perms || undefined).newItems // adds to variations.items[] itself
    costTypes = newItems.map((vi) => ({
      ...convertItemToCostType(vi)
    }))
  }

  loadItemsIntoSheet(costTypes, position, placeholderRow)
}
provide('addPermutationItems', addPermutationItems)

const selectItemsFromCatalog = async () => {
  const mod = await $store.dispatch('Quote/getQuoteMod', {
    refId: refId.value,
    store: store.value
  })
  const selected = await $store.dispatch('openItemSelector', {
    mod
  })

  const costTypes = selected.filter((it) => it.type === 'cost_type' || it.type === 'autocost')

  if (costTypes.length < selected.length) {
    $store.dispatch('alert', {
      message:
        'Only items are allowed to be added to variation lists at this time. All assemblies have been removed.'
    })
  }

  if (!costTypes.length) {
    throw new Error('Nothing to add')
  }

  return costTypes
}
const addPermutationFromCatalog = async (position, placeholderRow) => {
  const selected = await selectItemsFromCatalog()
  return addPermutationItems(1, position, selected, placeholderRow)
}

const $store = useStore()
const addPermutationChoice = async (position, duplicatingItem, placeholderRow) => {
  const choice = await $store.dispatch('modal/asyncConfirm', {
    choices: [
      {
        value: 'catalog',
        icon: 'box-open-full',
        title: 'Add from Catalog',
        desc: 'You can add one or multiple of your pre-existing items -- as well as AutoCost items from your catalog with this option.'
      },
      {
        value: 'permutation',
        icon: 'cube',
        title: 'Generate a new item',
        desc: 'This generates another item, replicating most fields from the root item. If any combinations of traits is not in the list yet, it will add those.'
      }
    ]
  })

  if (choice === 'permutation') {
    addPermutationItems(1, position, duplicatingItem, placeholderRow)
  } else {
    addPermutationFromCatalog(position, placeholderRow)
  }
}
provide('addPermutationChoice', addPermutationChoice)

const viewMode = ref('preview')
const shouldLoadItems = ref(false)
watch(viewMode, (is) => {
  shouldLoadItems.value = shouldLoadItems.value || is !== 'preview'
})

const loadInitial = () => {
  if (!variations.value?.items?.length) {
    addPermutationItems(10)
  }
}

watch(shouldLoadItems, (is, was) => {
  if (is && !was) {
    loadInitial()
  }
})

const traitDraggingOver = ref(null)
const traitVtypeDraggingOver = ref(null)
const traitDragging = ref(null)
const traitVtypeDragging = ref(null)
const handleTraitDragstart = (event, vindex, tindex) => {
  traitDragging.value = tindex
  traitVtypeDragging.value = vindex
}
const handleTraitDragover = (event, vindex, tindex) => {
  traitDraggingOver.value = tindex
  traitVtypeDraggingOver.value = vindex
}
const handleTraitDragend = () => {
  if (
    traitDragging.value !== traitDraggingOver.value ||
    traitVtypeDraggingOver.value !== traitVtypeDragging.value
  ) {
    // Take from old position
    const spliced = variations.value.variationTypes[traitVtypeDragging.value].traits.splice(
      traitDragging.value,
      1
    )
    // move to new position
    variations.value.variationTypes[traitVtypeDraggingOver.value].traits.splice(
      traitDraggingOver.value,
      0,
      ...spliced
    )
  }
  traitDragging.value = null
  traitDraggingOver.value = null
  traitVtypeDraggingOver.value = null
  traitVtypeDragging.value = null
}

const scope = _.getStorage('scope')
const imgLink = (id) => c.link(`file/view/${id}`, { max: 100 }, true, scope)

const overVariationType = ref(null)
const mouseoverTrait = ref([null, null])
const traitContainers = ref([])
const refDrop = ref(null)

const componentKey = computed(
  () => `${JSON.stringify(variations.value.variationTypes)}-${variations.value.items?.length}`
)

watch(componentKey, () => {
  refVariationsSheet.value?.refSheet?.reinitialize?.()
})

// This will sync a regular cost type into a variation item, except it will not add the variations., that must
// be done separately via 'setItemVariation'
const syncItemFromCostType = (costType, uid) => {
  setItem(uid, convertCostTypeToVariationItem(costType))
}

// The job of sync items is to react to changes from the entity list, and
// then make sure variations.value.items list is updated based on the changes
// made in the Variations table / entityList component.
// It will also need to update the variation type settings, and add variations type traits
// don't exist yet in variationTypes.x.traits
const syncItems = async ({ fullChanges }) => {
  for (const uid in fullChanges) {
    syncItemFromCostType(fullChanges[uid], uid)

    for (const field in fullChanges[uid]) {
      const value = fullChanges[uid][field]

      if (/vtype\./.test(field)) {
        const vtype = field.replace('vtype.', '')
        const vtypeIndex = variations.value.variationTypes.findIndex(
          (existingVtype) => existingVtype.name === vtype
        )
        // Add trait if does not exist already for the vtype
        const traitIndex = variations.value.variationTypes[vtypeIndex].traits.findIndex(
          (tr) => (tr.name ?? '').toLowerCase() === (value ?? '').toLowerCase()
        )

        if (traitIndex === -1) {
          addTrait(vtypeIndex, {
            name: value
          })
        }

        setItemVariation(uid, vtype, value)
      }
    }
  }
}

const dropPt = {
  root: [
    'absolute left-0 top-0',
    'z-40 transform origin-center',
    'overflow-visible',

    '!bg-surface-100 dark:bg-surface-0',
    '!text-surface-900 dark:text-surface-0/80',
    'dark:border-surface-700',
    'rounded-sm ring  ring-surface-900/30 ring-6',
    'border-0 dark:border',
    // Color

    // Before: Triangle
    'before:absolute before:-top-[9px] before:-ml-[9px] before:left-[calc(var(--overlayArrowLeft,0)+1.25rem)] z-0',
    'before:w-0 before:h-0',
    'before:border-transparent before:border-solid',
    'before:border-x-[8px] before:border-[8px]',
    'before:border-t-0 before:border-b-surface-900/30 dark:before:border-b-surface-700',
    'before:hidden',

    'after:absolute after:-top-[11px] after:-ml-[8px] after:left-[calc(var(--overlayArrowLeft,0)+1.25rem)]',
    'after:w-0 after:h-0',
    'after:border-transparent after:border-solid',
    'after:border-x-[0.5rem] after:border-[0.5rem]',
    'after:border-t-0 after:border-b-surface-900/30 dark:after:border-b-surface-900/30'
  ],
  content: ['p-4', 'overflow-auto']
}

const audienceOptions = ref([
  {
    value: 'client',
    title: 'Client selection',
    icon: 'house-user',
    desc: 'This optionality is intended to be available to the client on their proposal, so they can choose and configure the quote to their liking. This is the default functionality.'
  },
  {
    value: 'company',
    title: 'Internal selection only',
    icon: 'calculator',
    desc: 'To make estimating easier, you can make this variation tool visible to your estimator -- and it would be hidden from your client. For example you might want a way to quickly choose the stud size or bin size, but you would not want your customer to see that.'
  }
])

const rowProcessor = (row) => {
  let processed = row

  if (processed.type === 'cost_type') {
    processed.uid = row.uid ?? costTypeIdToUid.value[processed.cost_type_id]
    const itemVariations = itemsByUid.value[processed.uid].variations
    processed = {
      ...processed,
      ...Object.keys(itemVariations).reduce(
        (acc, iv) => ({
          ...acc,
          [`vtype.${iv}`]: itemVariations[iv]
        }),
        {}
      )
    }
  }

  return processed
}

const refLinkEditor = ref(null)
const editingVtypeLinks = ref(null)

const getVtypeLinksList = (vtype = editingVtypeLinks.value) => {
  const localVariationType = vtype
  const links = variations.value.links || {}
  const result = []

  for (const linkedCostTypeId in links) {
    const link = links[linkedCostTypeId]

    if (link && localVariationType in link) {
      const linkedVariationType = link[localVariationType]
      result.push({
        id: linkedCostTypeId,
        vtype: linkedVariationType
      })
    }
  }

  return result
}
const linkedVariations = ref([])

watch([variations, editingVtypeLinks], () => {
  if (editingVtypeLinks.value) {
    linkedVariations.value = getVtypeLinksList(editingVtypeLinks.value)

    if (!linkedVariations.value.length) addLink(editingVtypeLinks.value)
  }
})

const editVariationLinks = (vtype) => {
  editingVtypeLinks.value = vtype
  refLinkEditor.value.open()
}

const deleteLink = (id, localVtype) => {
  const vars = { ...variations.value }
  delete vars.links[id][localVtype]

  if (!Object.keys(vars.links[id]).length) delete vars.links[id] // if nothing else in there, delete that too

  variations.value = vars
}

const currentLinkHover = ref(null)

const addingLink = ref(null)
const addingLinkItem = ref(null)
const addingLinkVtype = ref(null)
const addingLinkItemName = ref(null)
const addingLinkItemVtypeOptions = ref([])
const loadingLinkItem = ref(0)

const removeMissingItems = (missingItems) => {
  let items = [...(variations.value?.items ?? [])]

  const ids = missingItems.map((mi) => mi.id)
  items = items.filter((item) => !ids.includes(item.id))

  variations.value.items = items
}

watch(addingLinkItem, () => {
  loadingLinkItem.value = 1
  c.throttle(
    async () => {
      const { object } = await $store.dispatch('CostType/fetch', {
        id: addingLinkItem.value,
        fields: [
          'cost_type_name',
          'cost_type_id',
          'cost_type_is_variation_parent',
          'oVariations',
          'asVariationTypes'
        ]
      })
      addingLinkItemVtypeOptions.value = _.makeArray(
        object.oVariations?.variationTypes?.map((vtype) => vtype.name) ?? object.asVariationTypes
      )
      addingLinkItemName.value = object.oMeta?.optionGroupName ?? object.cost_type_name
      loadingLinkItem.value = 0
    },
    { delay: 200 }
  )
})

const inQuoteItemOptions = computed(() => {
  let found = []
  return Object.values(norm.value)
    .filter(
      (item) =>
        item.type === 'cost_item' &&
        Object.keys(item.oVariations).length &&
        (item.oVariations.parentId ?? item?.cost_type_id)
    )
    .map((item) => ({
      value: item.oVariations?.parentId ?? item.cost_type_id,
      text: `${item.oMeta?.optionGroupName ?? item.cost_type_name} ${item.oMeta?.optionGroupName && item.oMeta?.optionGroupName !== item.cost_type_name ? `(${item.cost_type_name})` : ''} (in this estimate)`,
      html: `<div class="flex flex-col"><span class="font-medium">${item.oMeta?.optionGroupName ?? item.cost_type_name}</span><span class="text-xs font-light text-surface-400">ie: ${item.oMeta?.optionGroupName && item.oMeta?.optionGroupName !== item.cost_type_name ? `(${item.cost_type_name})` : ''}  </span></div><span class="font-medium text-sm shrink-0 text-blue-print">In this project</span>`
    }))
    .filter((item) => {
      if (!found.includes(item.value)) {
        found.push(item.value)
        return true
      }
      return false
    })
})

const addLink = (vtype) => {
  addingLink.value = vtype
}

const confirmLink = () => {
  const vars = { ...variations.value }
  vars.links ??= {}
  vars.links[addingLinkItem.value] ??= {}
  vars.links[addingLinkItem.value][addingLink.value] = addingLinkVtype.value
  variations.value = vars
  addingLink.value = null
}
</script>

<template>
  <div class="w-full h-full flex flex-col items-center gap-6 basis-full shrink">
    <div class="flex justify-center items-center w-full shrink-0">
      <SelectionToggle
        v-model="viewMode"
        :options="[
          { value: 'preview', text: 'Step 1: Variation traits', icon: 'eye' },
          { value: 'table', text: 'Step 2: Variants list', icon: 'table' }
        ]"
      />
    </div>

    <MiniModal scrollable ref="refLinkEditor">
      <div class="flex flex-col w-full gap-6">
        <div>
          <Automation icon="fas fa-circle-nodes">
            <p>
              Linking a variation to those on other items is a convenience feature to keep
              selections on the project consistent. When your client selects an option on this item,
              it will automatically update any of the linked items with the same selection.
            </p>

            <p>
              This is useful when you have organized your estimate based on scope, for example you
              might have countertops in the kitchen and in the bathroom. When your client selects
              the material for the kitchen counters, it should automatically set the bathrooms to
              the same material. They can always change back the bathroom to something else, but at
              least it saves them a click for the most common use case.
            </p>

            <p class="font-medium">Give it a try!</p>
          </Automation>
        </div>

        <div class="font-medium text-xl">
          Link "{{ editingVtypeLinks }}" on this item, to other variation items in the same project
        </div>
        <div class="flex flex-col gap-6">
          <div
            v-for="linkedVTypes in linkedVariations"
            :key="linkedVTypes.id"
            class="flex justify-between items-center w-full gap-4 rounded hover:bg-surface-200/40 p-2 select-none cursor-pointer"
            @mouseover="currentLinkHover = linkedVTypes.id"
          >
            <div class="flex flex-col items-center justify-center leading-none basis-10">
              <Btn link rounded v-if="currentLinkHover !== linkedVTypes.id">
                <font-awesome-icon icon="circle-nodes" />
              </Btn>
              <Btn
                link
                rounded
                v-else
                @click="deleteLink(linkedVTypes.id, editingVtypeLinks)"
                class="!text-deep-red-500"
              >
                <font-awesome-icon icon="trash" />
              </Btn>
            </div>

            <div class="flex flex-col items-start justify-center leading-none basis-2/3 shrink">
              <div class="text-sm font-medium text-surface-600">Linked item</div>
              <Name
                :id="linkedVTypes.id"
                type="cost_type"
                class="font-medium text-lg leading-tight line-clamp-2"
              />
            </div>

            <div class="flex flex-col items-start justify-center leading-none basis-1/3 shrink">
              <div class="text-sm font-medium text-surface-600">Linked property</div>
              <div class="font-medium text-lg">
                <font-awesome-icon icon="swatchbook" /> {{ linkedVTypes.vtype }}
              </div>
            </div>
          </div>

          <div
            v-if="addingLink"
            class="flex justify-between items-end w-full gap-4 rounded hover:bg-surface-200/40 p-2 select-none cursor-pointer border-2 border-surface-300 h-20"
          >
            <div class="flex flex-col items-center justify-center leading-none basis-10 h-full">
              <Btn link rounded @click="addingLink = null" size="lg">
                <font-awesome-icon icon="trash" size="xl" />
              </Btn>
            </div>

            <div class="flex flex-col items-start justify-center leading-none basis-2/3 shrink">
              <div class="text-sm font-medium text-surface-600">Linked item</div>
              <Choose
                schema="cost_type:cost_type_id"
                :custom-options="inQuoteItemOptions"
                :key="addingLink"
                :filters="{
                  type: 'cost_type',
                  cost_type_is_variation_parent: 1,
                  company_id: $store.state.session.company?.company_id ?? 'NULL',
                  ...(inQuoteItemOptions.length
                    ? { cost_type_id: `!${inQuoteItemOptions.map((opt) => opt.value).join('&&!')}` }
                    : {})
                }"
                v-model="addingLinkItem"
              />
            </div>

            <div
              class="flex flex-col items-start justify-center leading-none basis-1/3 shrink relative"
            >
              <div class="text-sm font-medium text-surface-600" v-if="addingLinkItem">
                Linked property
              </div>
              <div class="font-medium text-lg leading-none">
                <Loader :loading="loadingLinkItem" />
                <Choose
                  :key="addingLinkItem"
                  v-if="addingLinkItem && (addingLinkItemVtypeOptions?.length || loadingLinkItem)"
                  :disabled="!addingLinkItemVtypeOptions?.length || loadingLinkItem"
                  :static-set="addingLinkItemVtypeOptions.map((text) => ({ text, value: text }))"
                  v-model="addingLinkVtype"
                />
                <template v-else-if="addingLinkItem">
                  <span class="text-sm leading-none"
                    >Can't link this yet. Add variation properties to
                    {{ addingLinkItemName }} first.</span
                  >
                </template>
              </div>
            </div>
            <div class="flex flex-col items-end justify-center leading-none basis-1/5 shrink">
              <Btn
                size="lg"
                severity="primary"
                :action="confirmLink"
                :disabled="!addingLinkItem || !addingLinkVtype"
                >Confirm</Btn
              >
            </div>
          </div>

          <div class="py-2">
            <Btn size="md" severity="secondary" :action="() => addLink(editingVtypeLinks)">
              <font-awesome-icon icon="fas fa-plus" />
              Add new link
            </Btn>
          </div>
        </div>
      </div>
    </MiniModal>

    <div
      v-if="viewMode === 'preview'"
      class="flex flex-col items-center gap-10 overflow-auto w-full h-full pb-20"
    >
      <Automation class="max-w-screen-lg">
        <p class="text-medium text-lg leading-tight">
          <font-awesome-icon icon="swatchbook" /> Variation items are a great way to manage a large
          list of combination options that the customer might choose from that could affect price as
          well as things like labor or materials cost.
        </p>
        <span class="font-medium">Important things to remember:</span>
        <ul class="list-disc ml-5">
          <li>
            Any time a variation is selected by your customer, the quantity of the original item is
            loaded right into the selected variation item, so make sure they are compatible
          </li>
          <li>
            Compatible units will be converted, for example if you have a dimension measured in
            square feet, but are using an item with a square meter unit, the value will be
            automatically converted
          </li>
          <li>
            All the costs and other details will be used from the variation item, so things like
            minimum quantities, minimum labor prices, purchase formats etc can be set on the item in
            the variants list, and will be integrated if and when it is selected
          </li>
        </ul>
      </Automation>
      <div class="flex flex-col items-center justify-start w-full gap-2">
        <StringField
          class="text-2xl font-medium !text-center"
          v-model="optionGroupName"
          placeholder="Selection name ie: 'Bathroom Faucet', 'Countertops' or 'Decking' etc"
        />
        <StringField
          multiline
          class="text-lg !min-h-20 text-surface-500 !text-center"
          v-model="optionGroupDesc"
          placeholder="Provide an explanation or instructions for your customers (optional)"
        />

        <Choice
          icon="eye"
          message="Who should make this selection?"
          v-model="selectionAudience"
          placeholder="Selection visible to client"
          :choices="audienceOptions"
        />
      </div>

      <template v-for="(vtype, index) in variations.variationTypes" :key="vtype">
        <div
          class="flex flex-col justify-start items-start gap-2 p-2 max-w-[900px]"
          @mouseover="overVariationType = index"
        >
          <div class="font-medium font-lg flex justify-start items-center">
            <StringField
              class="min-h-6"
              placeholder="Name variation type ie: 'size', 'color' or 'material'"
              v-model="variations.variationTypes[index].name"
            />
            <template v-if="overVariationType === index">
              <Btn
                link
                rounded
                @click="removeVariationType(index)"
                tooltip="Remove this variation type"
                ><font-awesome-icon icon="trash"
              /></Btn>
              <Btn
                link
                rounded
                tooltip="Hide this variation type from your customer"
                @click="
                  variations.variationTypes[index].hidden = variations.variationTypes[index].hidden
                    ? 0
                    : 1
                "
                :class="{
                  '!text-deep-red-500': vtype.hidden
                }"
                ><font-awesome-icon :icon="vtype.hidden ? 'eye-slash' : 'eye'"
              /></Btn>
              <Btn
                link
                rounded
                tooltip="Lock this variation type so your customer can't change it"
                @click="
                  variations.variationTypes[index].locked = variations.variationTypes[index].locked
                    ? 0
                    : 1
                "
                :class="{
                  '!text-deep-red-500': vtype.locked
                }"
                ><font-awesome-icon :icon="vtype.locked ? 'lock' : 'lock-open'"
              /></Btn>

              <!-- for efficiency do this one differently -->
              <template
                v-if="Object.values(variations.links ?? {}).some((link) => !!link[vtype.name])"
              >
                <Btn
                  link
                  rounded
                  tooltip="Linked to other items. Click to edit."
                  @click="editVariationLinks(vtype.name)"
                  :class="{
                    '!text-blue-print': true
                  }"
                  ><font-awesome-icon icon="fas fa-circle-nodes"
                /></Btn>
              </template>
              <template v-else>
                <Btn
                  link
                  rounded
                  tooltip="Link this selection to other items. For example, if your customer chooses 'granite' in the kitchen, have it link to 'granite' in the bathroom too, automatically!"
                  @click="editVariationLinks(vtype.name)"
                  :class="{
                    '!text-blue-print': false
                  }"
                  ><font-awesome-icon icon="circle-nodes"
                /></Btn>
              </template>

              <Btn
                link
                rounded
                tooltip="Change the image shape"
                @click="
                  variations.variationTypes[index].imageShape =
                    vtype.imageShape === 'circle' ? 'square' : 'circle'
                "
                ><font-awesome-icon :icon="vtype.imageShape"
              /></Btn>
            </template>
            <template v-else>
              <Btn link rounded class="opacity-0"></Btn>
            </template>
          </div>

          <div class="flex flex-wrap max-w-4/5 justify-start items-start gap-4">
            <template v-for="(trait, tindex) in vtype.traits" :key="trait">
              <div
                @dragover="(event) => handleTraitDragover(event, index, tindex)"
                v-if="
                  traitVtypeDraggingOver === index &&
                  traitDraggingOver === tindex &&
                  (traitVtypeDragging !== index || traitDragging !== tindex) &&
                  traitDragging !== null &&
                  traitDragging > tindex
                "
                :class="[
                  'relative size-20 rounded-md border-dashed border-surface-300 border-2 flex items-center justify-center leading-tight font-medium text-center'
                ]"
              >
                Move here
              </div>

              <div
                @mouseover="mouseoverTrait = [index, tindex]"
                :class="[
                  ' !max-w-20 relative',
                  {
                    'cursor-grab': traitDragging !== tindex,
                    'cursor-grabbing': traitDragging === tindex
                  }
                ]"
                draggable="true"
                @dragover="(event) => handleTraitDragover(event, index, tindex)"
                @dragstart="(event) => handleTraitDragstart(event, index, tindex)"
                @dragend="handleTraitDragend"
              >
                <div
                  v-tooltip="
                    !usedVariationTypeTraits?.[vtype.name]?.[trait.name]
                      ? 'This property is not found in any variation items yet'
                      : false
                  "
                  :class="[
                    'relative overflow-hidden flex justify-center items-center',
                    'text-sm leading-none text-center font-medium',
                    'size-20',
                    'border-2',
                    {
                      'ring ring-4 ring-surface-300/50':
                        mouseoverTrait[0] === index && mouseoverTrait[1] === tindex,
                      'rounded-full': vtype.imageShape === 'circle',
                      'rounded-sm': vtype.imageShape !== 'circle',
                      'opacity-40': traitVtypeDragging === index && traitDragging === tindex,
                      ' border-surface-400': usedVariationTypeTraits?.[vtype.name]?.[trait.name],
                      'border-deep-red-500': !usedVariationTypeTraits?.[vtype.name]?.[trait.name]
                    }
                  ]"
                >
                  <img
                    :class="[
                      'absolute inset-0 h-full w-full',
                      {
                        'object-cover': !vtype.backgroundSize || vtype.backgroundSize === 'cover',
                        'object-contain': vtype.backgroundSize === 'contain',
                        'object-fill': vtype.backgroundSize === 'fill'
                      }
                    ]"
                    v-if="trait.imgs?.[0]"
                    :src="imgLink(trait.imgs[0])"
                  />
                  {{ variations.variationTypes[index].traits[tindex].name }}
                </div>

                <div class="text-xs text-center flex justify-center items-center">
                  <StringField
                    class="min-h-6 text-center"
                    placeholder="Variation trait"
                    multiline
                    v-model="variations.variationTypes[index].traits[tindex].name"
                  />
                </div>

                <div
                  @click="refDrop[0]?.open()"
                  v-tooltip="'Click for options • Click and hold to drag'"
                  v-if="mouseoverTrait[0] === index && mouseoverTrait[1] === tindex"
                  :class="[
                    'absolute -top-2 -left-2 size-8 z-100 text-surface-400 bg-surface-200 rounded-full flex justify-center items-center cursor-pointer hover:text-surface-800 hover:bg-surface-300'
                  ]"
                >
                  <font-awesome-icon icon="fas fa-grip-vertical" />
                </div>

                <div
                  ref="traitContainers"
                  :key="`${index}-${tindex}`"
                  v-if="mouseoverTrait[0] === index && mouseoverTrait[1] === tindex"
                ></div>
              </div>

              <div
                @dragover="(event) => handleTraitDragover(event, index, tindex)"
                v-if="
                  traitVtypeDraggingOver === index &&
                  traitDraggingOver === tindex &&
                  (traitVtypeDragging !== index ||
                    (traitDragging !== tindex && traitDragging !== null && traitDragging < tindex))
                "
                :class="[
                  'relative size-20 rounded-md border-dashed border-surface-300 border-2 flex items-center justify-center leading-tight font-medium text-center'
                ]"
              >
                Move here
              </div>
            </template>
            <div>
              <div
                v-tooltip="'Add a new trait for this variation type'"
                @click="addTrait(index)"
                :class="[
                  'flex justify-center items-center text-surface-400  hover:bg-surface-200/90 hover:text-surface-600 cursor-pointer',
                  'size-20',
                  'border-2 border-transparent',
                  ' bg-surface-200/50',
                  {
                    'rounded-full': vtype.imageShape === 'circle',
                    'rounded-sm': vtype.imageShape !== 'circle'
                  }
                ]"
              >
                <font-awesome-icon icon="fas fa-plus" />
              </div>
              <div>&nbsp;</div>
            </div>
          </div>

          <Drop
            v-if="mouseoverTrait[0] !== null && mouseoverTrait[1] !== null"
            :key="`${mouseoverTrait[0]}0${mouseoverTrait[1]}`"
            :fix-to="traitContainers[0]"
            ref="refDrop"
            :pt="dropPt"
          >
            <div class="flex flex-col gap-6 divide-surface-300 min-w-96 max-w-96">
              <div class="flex flex-col gap-2">
                <div class="font-medium">Image</div>
                <div>
                  <FileList
                    v-model="
                      variations.variationTypes[mouseoverTrait[0]].traits[mouseoverTrait[1]].imgs
                    "
                    id-list
                  />
                </div>
              </div>

              <div class="flex flex-col gap-2">
                <div class="font-medium">Name</div>
                <div class="pl-1">
                  <StringField
                    class="max-w-[250px]"
                    v-model="
                      variations.variationTypes[mouseoverTrait[0]].traits[mouseoverTrait[1]].name
                    "
                    placeholder="Name of the trait"
                  />
                </div>
              </div>

              <div class="flex flex-col gap-2">
                <div class="font-medium">Description</div>
                <div class="pl-1">
                  <StringField
                    multiline
                    class="max-w-[250px]"
                    v-model="
                      variations.variationTypes[mouseoverTrait[0]].traits[mouseoverTrait[1]].desc
                    "
                    placeholder="Provide more details for this trait (optional)"
                  />
                </div>
              </div>

              <div class="flex flex-col gap-2">
                <Btn severity="tertiary" :action="() => removeTrait(...mouseoverTrait)">
                  <font-awesome-icon icon="trash" />
                  Remove trait
                </Btn>
              </div>
            </div>
          </Drop>
        </div>
      </template>

      <div
        @click="addVariationType()"
        v-tooltip="'Add a new variation type'"
        class="flex flex-col justify-start items-center gap-2 h-40 bg-surface-200 text-surface-400 p-2 rounded-sm min-w-[275px] h-fit w-fit relative hover:bg-surface-200/90 hover:text-surface-600 cursor-pointer"
      >
        <font-awesome-icon icon="fas fa-plus" />
      </div>

      <div class="p-2 mt-10 flex flex-row items-center justify-center">
        <Btn severity="primary" size="2xl" @click="viewMode = 'table'">
          Next: create or edit {{ Object.keys(itemsByUid).length }} variants
          <font-awesome-icon icon="arrow-right" />
        </Btn>
      </div>
    </div>

    <div v-show="viewMode !== 'preview'" class="w-full h-full relative">
      <!--      <div class="absolute top-0 bottom-0 right-0 bg-surface-200 w-[600px] max-h-full overflow-y-scroll z-[100000]"><div class="whitespace-pre">{{variations}}</div></div>-->
      <VariationsSheets
        ref="refVariationsSheet"
        @change="syncItems"
        @removedRows="removeItems"
        @isDirty="isDirtyHandler"
        @itemsNotFound="removeMissingItems"
        :row-processor="rowProcessor"
        :variations="variations"
        :orderedVtypes="orderedVtypes"
        :target="target"
        :mapVariationItemToCostType="convertItemToCostType"
        :change-handler="() => {}"
      >
        <template #after>
          <div class="py-2 gap-2 flex">
            <Btn severity="secondary" size="md" @click="addPermutationFromCatalog()"
              ><font-awesome-icon icon="box-open-full" />Add items from catalog</Btn
            >
            <Btn severity="secondary" size="md" @click="addPermutationItems(10)"
              ><font-awesome-icon icon="fas fa-plus" />Generate 10 more variants</Btn
            >
            <Btn severity="secondary" size="md" @click="addPermutationItems(100)"
              ><font-awesome-icon icon="fas fa-plus" />Generate 100 more variants</Btn
            >
            <Btn severity="secondary" size="md" :action="addSpecificVariants"
              ><font-awesome-icon icon="fas fa-plus" />Generate specific variants</Btn
            >

            <MiniModal
              title="Generate specific variants"
              icon="swatchbook"
              size="sm"
              ref="refSpecificVariants"
            >
              <div class="flex flex-col gap-6">
                <div
                  v-for="vtype in variations.variationTypes"
                  :key="vtype.name"
                  class="flex flex-col gap-2"
                >
                  <div class="text-lg font-medium">
                    {{ vtype.name }}
                  </div>

                  <div class="flex justify-start items-center gap-4">
                    <div class="flex justify-start items-center gap-2">
                      <Checkbox
                        :value="!specificPermutationsList[vtype.name]?.length ? 1 : 0"
                        @input="
                          (v) => {
                            if (v) specificPermutationsList[vtype.name] = []
                            else
                              specificPermutationsList[vtype.name] = vtype.traits.map(
                                (tr) => tr.name
                              )
                          }
                        "
                      />
                      All
                    </div>
                    <div
                      v-for="trait in vtype.traits"
                      :key="trait.name"
                      class="flex justify-start items-center gap-2"
                    >
                      <Checkbox
                        :value="specificPermutationsList[vtype.name]?.includes(trait.name)"
                        @input="
                          (v) => {
                            if (v) specificPermutationsList[vtype.name].push(trait.name)
                            else
                              specificPermutationsList[vtype.name] = specificPermutationsList[
                                vtype.name
                              ].filter((tr) => tr !== trait.name)
                          }
                        "
                      />
                      {{ trait.name }}
                    </div>
                  </div>
                </div>
              </div>

              <template #footer>
                <div class="flex justify-end items-center gap-2">
                  <Btn
                    severity="tertiary-borderless"
                    size="lg"
                    @click="refSpecificVariants.close()"
                  >
                    Cancel
                  </Btn>
                  <Btn severity="primary" size="lg" :action="generateSpecificPermutations">
                    Generate
                  </Btn>
                </div>
              </template>
            </MiniModal>
          </div>
        </template>
      </VariationsSheets>
    </div>
  </div>
</template>
