<template>
  <div class="p-4">
    <h2 class="text-xl font-semibold mb-1 text-gray-900">Setup your payment methods here.</h2>
    <p class="text-gray-600 mb-6">All payments are safe and secure.</p>

    <div class="flex items-center space-x-4">
      <div
        v-for="method in paymentMethods"
        :key="method.id"
        class="flex items-center border border-gray-300 px-5 py-3 rounded-md cursor-pointer min-w-[240px]"
        @click="selectMethod(method.id)"
      >
        <input
          type="radio"
          :id="method.id"
          name="paymentMethod"
          :checked="selectedMethod === method.id"
          @change="selectMethod(method.id)"
        />
        <label
          :for="method.id"
          class="flex-1 ml-2 flex items-center justify-between text-base text-gray-800"
        >
          <span>{{ method.label }}</span>
          <icon :icon="['far', method.iconClass]" class="text-xl text-gray-700 ml-4" />
        </label>
      </div>
    </div>

    <div class="mt-6">
      <CounterpartyAccounts
        v-if="selectedMethod === 'bankAccount'"
        :type="accountType"
        :title="accountType === 'businessAccounts' ? 'Business Accounts' : 'Bank Accounts'"
        :addBankAccountAllowed="accountType !== 'businessAccounts'"
        :merchant="merchant"
        :accounts="accounts"
        @accounts-updated="handleAccountsUpdated"
      />

      <div v-else class="p-4 border rounded">
        <h3 class="font-semibold text-gray-800 mb-2">{{ activeMethod.label }} Settings</h3>
        <p>
          This is where you'll eventually configure your
          <strong>{{ activeMethod.label }}</strong> payment information. For now, just some
          placeholder text.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import CounterpartyAccounts from './CounterpartyAccounts.vue'

export default {
  name: 'PaymentMethods',
  props: {
    merchant: {
      type: Object,
      required: true
    },
    accounts: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'payments' // can be 'payments' or 'business_account'
    }
  },
  emits: ['accounts-updated'],
  setup(props, { emit }) {
    const paymentMethods = ref([
      {
        id: 'bankAccount',
        label: 'Bank account',
        iconClass: 'university'
      }
      // {
      //   id: 'creditCard',
      //   label: 'Credit or debit card',
      //   iconClass: 'credit-card'
      // },
      // {
      //   id: 'eft',
      //   label: 'EFT',
      //   iconClass: 'arrow-right-arrow-left'
      // }
    ])

    const selectedMethod = ref('bankAccount')

    function selectMethod(methodId) {
      selectedMethod.value = methodId
    }

    const activeMethod = computed(() => {
      return paymentMethods.value.find((m) => m.id === selectedMethod.value) || {}
    })

    const accountType = computed(() => {
      return props.type === 'business_account' ? 'businessAccounts' : 'payments'
    })

    function handleAccountsUpdated(updatedAccounts) {
      emit('accounts-updated', updatedAccounts)
    }

    return {
      paymentMethods,
      selectedMethod,
      selectMethod,
      activeMethod,
      accountType,
      handleAccountsUpdated
    }
  },
  components: {
    CounterpartyAccounts
  }
}
</script>
