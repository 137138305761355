<template>
  <div>
    <InvoiceBody v-if="!loading" :reference="refId" />
  </div>
</template>

<script setup>
import InvoiceBody from '../bodies/Invoice.vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext'

const { loading } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})
</script>
