import { getClientSteps } from './client'
import { getQuoteSteps } from './quote'
import { getInvoiceSteps } from './invoice'
import { getPhoneSteps } from './phone'
import { getEmailSteps } from './email'
import { getChangeOrderSteps } from './changeOrder'
import { getLeadRotationSteps } from './leadRotation'

const baseStep = {
  name: '',
  short: '',
  action: () => {},
  glyph: '',
  color: 'default'
}

export function generateNextSteps(object, store, contextual) {
  let steps = []

  switch (object.type) {
    case 'client':
      steps = [...steps, ...getClientSteps(object, store)]
      break

    case 'quote':
      steps = [...steps, ...getQuoteSteps(object, store, contextual)]
      break

    case 'lead_rotation':
      steps = [...steps, ...getLeadRotationSteps(object, store, contextual)]
      break

    case 'invoice':
      steps = [...steps, ...getInvoiceSteps(object, store)]
      break

    case 'phone':
      steps = [...steps, ...getPhoneSteps(object, store)]
      break

    case 'email':
      steps = [...steps, ...getEmailSteps(object, store)]
      break

    case 'change_order':
      steps = [...steps, ...getChangeOrderSteps(object, store)]
      break

    default:
      break
  }

  return steps.map((s) => ({ ...baseStep, ...s }))
}

export {
  getChangeOrderSteps,
  getClientSteps,
  getEmailSteps,
  getInvoiceSteps,
  getPhoneSteps,
  getQuoteSteps,
  getLeadRotationSteps
}
