<template>
  <div class="absolute inset-0 pointer-events-none" ref="rootElement">
    <div
      v-if="showArrow"
      class="fixed bottom-2 left-1/2 transform -translate-x-1/2 cursor-pointer !pointer-events-auto bg-blue-print-400 p-4 size-12 rounded-sm hover:bg-blue-print-500 flex justify-center items-center text-white"
      @click="scrollDown"
    >
      <font-awesome-icon icon="arrow-down" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const showArrow = ref(false)
let scrollContainer = null
let rootElement = ref(null)

const handleScroll = () => {
  if (!scrollContainer) return

  const scrollableDistance =
    scrollContainer.scrollHeight - scrollContainer.scrollTop - scrollContainer.clientHeight
  console.log(scrollableDistance)
  showArrow.value = scrollableDistance > 5
}

const scrollDown = () => {
  if (!scrollContainer) return

  scrollContainer.scrollBy({
    top: scrollContainer.scrollHeight,
    behavior: 'smooth'
  })
}

onMounted(() => {
  scrollContainer = c.getScrollParent(rootElement.value)

  if (scrollContainer) {
    scrollContainer.addEventListener('scroll', handleScroll, { passive: true })
    handleScroll() // Initial check
  }
})

onBeforeUnmount(() => {
  if (scrollContainer) {
    scrollContainer.removeEventListener('scroll', handleScroll, { passive: true })
  }
})
</script>
