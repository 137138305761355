export default {
  type: 'subscription',
  skipAudit: true,
  fields: {
    mock: {
      type: 'string'
    }
  },

  generateVueActions() {
    return {
      async getStripeCheckoutSession({ dispatch }, { country, featureProductId }) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/getStripeCheckoutSession',
            data: {
              country,
              featureProductId
            }
          },
          { root: true }
        )

        return {
          url: payload.url
        }
      },
      async setDefaultStripeProductForFeatureProduct(
        { dispatch },
        { productId, featureProductId }
      ) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/setDefaultStripeProductForFeatureProduct',
            data: {
              productId,
              featureProductId
            }
          },
          { root: true }
        )

        return payload
      },
      async getStripeProducts({ dispatch }, { country, starting_at }) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/getStripeProducts',
            data: {
              country,
              starting_at
            }
          },
          { root: true }
        )

        return {
          products: payload.products,
          last_product: payload.last_product,
          has_more: payload.has_more
        }
      },
      async getLinkedStripeProducts({ dispatch }, { country }) {
        const { payload } = await dispatch('ajax', {
          path: '/subscription/getLinkedStripeProducts',
          data: {
            country
          }
        })

        return {
          products: payload.products
        }
      },
      async searchStripeProducts({ dispatch }, { filters, searchPhrase }) {
        const { payload } = await dispatch('ajax', {
          path: '/subscription/searchStripeProducts',
          data: {
            searchPhrase,
            country: filters.country
          }
        })

        return {
          set: payload.products,
          ...payload
        }
      },
      async searchStripeSubscriptions({ dispatch }, { filters, searchPhrase }) {
        const { payload } = await dispatch('ajax', {
          path: '/subscription/searchStripeSubscriptions',
          data: {
            searchPhrase,
            country: filters.country
          }
        })

        return {
          set: payload.subscriptions,
          ...payload
        }
      },
      async searchStripeCustomers({ dispatch }, { filters, searchPhrase }) {
        const { payload } = await dispatch('ajax', {
          path: '/subscription/searchStripeCustomers',
          data: {
            searchPhrase,
            country: filters.country
          }
        })

        return {
          set: payload.customers,
          ...payload
        }
      },
      async getFeatureProducts({ dispatch }, { country }) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/getFeatureProducts',
            data: {
              country
            }
          },
          { root: true }
        )

        return {
          products: payload.products
        }
      },
      async createFeatureProduct(
        { dispatch },
        { featureProductName, featureProductDescription, defaultStripeProductId }
      ) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/createFeatureProduct',
            data: {
              featureProductName,
              featureProductDescription,
              featureProductDefaultStripeProductId: defaultStripeProductId
            }
          },
          { root: true }
        )

        return payload
      },
      async editFeatureProduct(
        { dispatch },
        { id, featureProductName, featureProductDescription, defaultStripeProductId }
      ) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/editFeatureProduct',
            data: {
              id,
              featureProductName,
              featureProductDescription,
              featureProductDefaultStripeProductId: defaultStripeProductId
            }
          },
          { root: true }
        )

        return payload
      },
      async deleteFeatureProduct({ dispatch }, { id }) {
        const res = await dispatch(
          'modal/asyncConfirm',
          {
            message: 'Are you sure you would like to delete this feature product?',
            yes: 'Yes',
            no: 'Cancel'
          },
          { root: true }
        )

        if (res) {
          const { payload } = await dispatch(
            'ajax',
            {
              path: '/subscription/removeFeatureProduct',
              data: {
                id
              }
            },
            { root: true }
          )

          return payload
        }

        return null
      },
      async getPublicFeatureProducts({ dispatch }, { country }) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/getPublicFeatureProducts',
            data: {
              country
            }
          },
          { root: true }
        )

        return {
          products: payload.products
        }
      },
      async getCompanySubscribedFeatureProducts({ dispatch }) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/getCompanySubscribedFeatureProducts'
          },
          { root: true }
        )

        return {
          products: payload.products
        }
      },
      async getPublicCompanySubscribedFeatureProducts({ dispatch }) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/getPublicCompanySubscribedFeatureProducts'
          },
          { root: true }
        )

        return {
          products: payload.products
        }
      },
      async setCompanyFeatureProductSubscription(
        { dispatch },
        { featureProductId, subscriptionId, companyId, country }
      ) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/setCompanyFeatureProductSubscription',
            data: {
              featureProductId,
              subscriptionId,
              companyId,
              country
            }
          },
          { root: true }
        )

        return payload
      },
      async linkProductToFeatureProduct(
        { dispatch },
        { productId, productName, featureProductId }
      ) {
        const { payload } = await dispatch(
          'ajax',
          {
            path: '/subscription/linkProductToFeatureProduct',
            data: {
              productId,
              productName,
              featureProductId
            }
          },
          { root: true }
        )

        return payload
      },
      async linkDefaultFeatureProductStripeProduct(
        { dispatch },
        { featureProductId, productId, country }
      ) {
        const { payload } = await dispatch('ajax', {
          path: '/subscription/linkDefaultFeatureProductStripeProduct',
          data: {
            featureProductId,
            productId,
            country
          }
        })

        return payload
      }
    }
  }
}
