import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'
import eventBus from '@/eventBus.js'
import ChangeWatcher from '@/components/Sheets/ChangeWatcher.js'
import Auditing from '../../../../imports/api/Auditing/index.js'
import * as types from '@/store/mutation-types.js'
import Debug from '@/components/Sheets/Debug.js'
import ObjectHelpers from '../../../../imports/api/Objects.js'

const { denormalizeSet } = NormalizeUtilities

export default {
  useEntitySheet(args) {
    const {
      store,
      refId: rootRefId,
      refSheet,
      columns,

      // This maps the columns in the table to the field names in the item
      fieldMapping = {},

      // Equation getters, provides a method to retrieve an equation for each field
      // based on type. If none provided the default one is used
      equationGetters = {
        all: (changedItem, providedStateItem = null) => {
          if (providedStateItem) {
            const stateItem = _.imm(providedStateItem)
            let changedEqs = { ...(changedItem.oEquations || {}) }

            changedEqs = {
              ...changedEqs,

              // Make sure there is one equation for EVERY regular field change in
              // changedItem, as well as one equation for each explicit equation change
              // which is provided in oEquations above in changedEqs
              ...Object.keys(changedItem).reduce(
                (acc, field) => ({
                  ...acc,
                  [field]: (() => {
                    if (field === 'cost_item_qty_net_base') {
                      return 'cost_type_qty_equation' in changedItem
                        ? changedItem.cost_type_qty_equation
                        : stateItem.cost_type_qty_equation
                    }

                    return field in changedEqs ? changedEqs[field] : stateItem[field]
                  })()
                }),
                {}
              ),

              ...('cost_type_qty_equation' in changedItem
                ? {
                    cost_item_qty_net_base:
                      changedItem.cost_type_qty_equation ?? providedStateItem.cost_type_qty_equation
                  }
                : {})
            }

            return changedEqs
          }

          let bulkEquations = { ...(changedItem.oEquations || {}) }
          const equations = {}
          if (changedItem.type === 'cost_item' || changedItem.type === 'cost_type') {
            if ('cost_type_qty_equation' in changedItem)
              equations.cost_item_qty_net_base = changedItem.cost_type_qty_equation ?? ''
            if ('cost_type_hours_per_unit_equation' in changedItem)
              equations.cost_type_hours_per_unit =
                changedItem.cost_type_hours_per_unit_equation ?? ''

            delete bulkEquations.cost_item_qty_net_base
            delete bulkEquations.cost_item_qty_net
            delete bulkEquations.cost_type_hours_per_unit
          }

          return {
            ...bulkEquations,
            ...equations
          }
        }
      },

      equationSetters = {
        all: (item, itemEquations) => {
          const newItem = {}

          newItem.cost_type_qty_equation = itemEquations.cost_item_qty_net_base ?? ''
          newItem.cost_type_hours_per_unit_equation = itemEquations.cost_type_hours_per_unit ?? ''

          return {
            ...newItem,
            oEquations: itemEquations
          }
        }
      },

      // Provide an object where the key is the
      // processed field names (from fieldMapping)
      // of the field, and the value is
      // a function that takes one object as the
      // first argument (with $store, norm, refId,
      // value and equation), and
      // independantly sets the variables based
      // on whatever business logic is required
      // and included in hte function.
      // If there is no field setter defined for
      // a field, then the field will be set using
      // the default /field action.
      // Field setters must return a Promise() that resolves
      // to an object that contains a changeSet object
      // that contains an object with keys of refIds and values
      // of objects with fields and values of downstream
      // changes made by that setter
      fieldSetters = {},

      // Takes reducedItem and fullItem and
      // pre-processes the item and makes it
      // ready to be used as a sheet row
      itemProcessor = (reducedItem) /* , fullItem */ => reducedItem,

      // Takes reducedItem and fulLItem and
      // returns an sheetIndex for which sheet
      // it should go to
      itemSheet = (/* reducedItem, fullItem */) => 0
    } = args

    const { bench, debug } = Debug.useDebug()
    debug.value = true

    const fieldMappingDerived = computed(() => {
      const defaultMapping = {
        type: 'type',
        aoChildren: 'childrenIds',
        refId: ['id', 'refId'],
        parentRefId: ['parentId', 'parentRefId']
      }
      if (Object.keys(fieldMapping).length) {
        return {
          ...Object.keys(fieldMapping).reduce(
            (acc, type) => ({
              ...acc,
              [type]: {
                ...(acc[type] || {}),
                ...fieldMapping[type],
                ...defaultMapping
              }
            }),
            {}
          ),
          any: {
            ...defaultMapping
          }
        }
      }

      return {
        any: Object.values(columns).reduce(
          (acc, col) => ({
            ...acc,
            [col.field]: col.field,
            ...defaultMapping
          }),
          {}
        )
      }
    })
    const getOriginalField = (mappedField, type = 'any') => {
      if (!Object.keys(fieldMapping).length) {
        return mappedField
      }

      const fm = fieldMappingDerived.value

      const typeDerived = type in fm ? type : 'any'

      const index = Object.values(fm[typeDerived]).findIndex(
        (v) => v === mappedField || v.includes(mappedField)
      )

      if (index === -1) {
        return mappedField
      }

      const fields = Object.keys(fm[typeDerived])
      return fields[index]
    }
    const getMappedField = (originalField, type = 'any') => {
      if (!Object.keys(fieldMapping).length) {
        return originalField
      }
      const fm = fieldMappingDerived.value

      return c.makeArray(fm[type]?.[originalField])
    }
    const mapItem = (item, type, addDefaults = false) => {
      const fields = Object.keys(item)
      const mapped = {}

      for (let j = 0; j < fields.length; j++) {
        const originalField = fields[j]
        const mappedFields = getMappedField(originalField, type)

        if (Array.isArray(mappedFields)) {
          const value = item[originalField]
          if (value !== undefined) {
            for (let k = 0; k < mappedFields.length; k++) {
              const m = mappedFields[k]
              mapped[m] = value
            }
          }
        }
      }

      if (addDefaults) {
        mapped.type = type
      }

      return mapped
    }
    const demapItem = (item, type) => {
      const fields = Object.keys(item)
      const mapped = {}

      for (let j = 0; j < fields.length; j += 1) {
        const mappedField = fields[j]
        const originalField = getOriginalField(mappedField, type)

        mapped[originalField] = item[mappedField]
      }

      mapped.refId = item.id
      mapped.parentRefId = item.parentId
      mapped.aoChildren = item.aoChildren

      return mapped
    }

    const $store = useStore()
    const norm = computed(() => ({ ...$store.state[store].normalized }))

    const getType = (item) =>
      (item && (item.type || norm.value[item.id || item.refId]?.type)) || 'cost_item'

    const getTypeFromId = (id) => getType(norm.value[id])

    const getSheetRow = (refId, norm = norm.value) => {
      const item = norm[refId]
      const reducedItem = mapItem(item, item.type)
      const processedItem = itemProcessor(reducedItem, item)
      const sheetIndex = itemSheet(processedItem, item)

      return {
        ...processedItem,
        sheetIndex,
        type: item.type,
        id: item.refId,
        parentId: item.parentRefId,
        childrenIds:
          ((/assembly|quote/.test(item.type) || (item.aoChildren && item.aoChildren.length)) &&
            item.aoChildren) ||
          null
      }
    }

    const sheetRows = computed(() => {
      const norms = norm.value
      const set = NormalizeUtilities.sortNatural(norms)
      const sheetItems = []
      const sheetItemEquations = []

      for (let i = 0; i < set.length; i += 1) {
        if (set[i] === rootRefId) {
          continue
        }
        if (!(set[i] in norms)) continue
        const item = getSheetRow(set[i], norms)
        const sheetIndex = item.sheetIndex
        if (!sheetItems[sheetIndex]) sheetItems[sheetIndex] = []
        if (!sheetItemEquations[sheetIndex]) sheetItemEquations[sheetIndex] = []
        sheetItems[sheetIndex].push(item)

        const getters = equationGetters[item.type] || equationGetters.all
        const eqItem = getters(norms[set[i]])
        const mappedEqItem = mapItem(eqItem, item.type)
        sheetItemEquations[sheetIndex].push(mappedEqItem)
      }

      return {
        items: sheetItems,
        equations: sheetItemEquations
      }
    })

    const normalizedSheetRows = computed(() => {
      const { items, equations } = sheetRows.value

      return {
        items: items.map((shr) =>
          shr.reduce(
            (acc, item) => ({
              ...acc,
              [item.id]: item
            }),
            {}
          )
        ),
        equations: equations.map((shr, sheetIndex) =>
          shr.reduce(
            (acc, item, rowIndex) => ({
              ...acc,
              [items[sheetIndex][rowIndex].id]: item
            }),
            {}
          )
        )
      }
    })

    let bundleChanges = []

    const flushChanges = () => {
      const ch = [...bundleChanges]
      bundleChanges = []

      const merged = NormalizeUtilities.mergeChanges(...ch)

      if (!Object.keys(merged).length) return

      const { values, equations } = mapChangeSet(merged)

      refSheet.value?.setFieldValues(values, equations)
    }

    const changeHandler = (args) => {
      const { changes } = args

      // remove any empty sets
      const reduced = Object.keys(changes).reduce(
        (acc, ref) => ({
          ...acc,
          ...(Object.keys(changes[ref] ?? {}).length ? { [ref]: changes[ref] } : {})
        }),
        {}
      )
      bundleChanges.push(reduced)

      c.throttle(() => flushChanges(), { delay: 400 })
    }

    const {
      loading: storeLoading,
      listen,
      ignore,
      initiate
    } = ChangeWatcher.useChangeWatcher({
      store,
      refId: rootRefId,
      changeHandler
    })
    onMounted(() => initiate())

    const setAsFee = async (refId, row) => {
      const meta = {
        itemType: 'costItem'
      }
      const changeSet = {
        [refId]: {
          oMeta: meta,
          metaType: meta,
          name: refSheet.value.currentCellValue || '',
          cost_type_is_fee: 1,
          isFee: 1,
          markup: 1,
          profit: 1
        }
      }
      await setFields({
        [refId]: {
          cost_type_is_fee: 1,
          cost_type_markup_net: 1 // no profit
        }
      })
      await refSheet.value.setFieldValues(changeSet, {}, false, true)
      refSheet.value.clearFragments(`-row-${row}-col-`)
      refSheet.value.triggerRedraw(100)
    }

    // From Sheets to VUEX
    const setMeta = async ({ key = null, value = null, refId, row, meta: rmeta = null }) => {
      let meta = norm.value[refId].oMeta || {}
      if ((key && value) || rmeta) {
        meta = {
          ...meta,
          ...(key ? { [key]: value } : rmeta ?? {})
        }
      }
      const changeSet = _.imm({
        [refId]: {
          oMeta: meta,
          metaType: meta,
          name: refSheet.value.currentCellValue || ''
        }
      })
      await refSheet.value.setFieldValues(changeSet, {}, false, true)
      refSheet.value.clearFragments(`-row-${row}-col-`)
      refSheet.value.triggerRedraw(100)
    }

    // From Sheets to VUEX
    const setFields = async (values, equations = {}) => {
      // Get all changes, submit complex ones one by one, with audit disabled
      // then submit the rest in bulk
      let bulkChanges = {}
      const eqChain = []
      const fieldChain = []
      const bulkEquations = []

      const context = {
        $store,
        norm: norm.value,
        store
      }

      const refIds = Object.keys(values)
      refIds.forEach((id) => {
        const type = getTypeFromId(id)
        const setters = fieldSetters[type] || {}

        if (!type) return
        const constr = getConstructor(type)

        Object.keys(values[id]).forEach((field) => {
          const actualField = field in constr.fields
          if (!bulkChanges[id] && actualField) bulkChanges[id] = {}
          if (actualField) bulkChanges[id][field] = values[id][field]

          if (actualField && equations[id] && equations[id][field]) {
            if (type in equationSetters && field in equationSetters[type]) {
              eqChain.push(async () => {
                const pld = await equationSetters[type][field]({
                  ...context,
                  norm: context.norm,
                  object: context.norm[id],
                  value: values[id][field],
                  equation: equations[id][field]
                })
                return {
                  changes: {
                    [id]: pld.changes
                  },
                  explicitChanges: {
                    [id]: pld.explicitChanges
                  }
                }
              })
            } else {
              eqChain.push(async () => {
                const pld = await $store.dispatch(`${store}/fieldEquation`, {
                  field,
                  equation: equations[id][field],
                  refId: id
                })

                return {
                  changes: {
                    [id]: pld.changes
                  },
                  explicitChanges: {
                    [id]: pld.explicitChanges
                  }
                }
              })
            }
          }

          // set individuals
          if (field in setters) {
            fieldChain.push(async () => {
              const pld = await setters[field]({
                ...context,
                norm: context.norm,
                object: context.norm[id],
                value: values[id][field],
                equation: (equations[id] && equations[id][field]) || null,
                equations: equations[id]
              })
              return {
                changes: {
                  [id]: pld.changes
                },
                explicitChanges: {
                  [id]: pld.explicitChanges
                }
              }
            })
          } else if (actualField && field in (equations[id] ?? {})) {
            bulkEquations.push({ refId: id, field, equation: equations[id][field] })
          }
        })
      })

      if (
        !Object.keys(bulkChanges).length &&
        !Object.keys(bulkEquations).length &&
        !eqChain.length &&
        !fieldChain.length
      )
        return

      let payloads = await Promise.all([...fieldChain, ...eqChain].map((fn) => fn()))
      let downstream = NormalizeUtilities.mergeChanges(...payloads.map((p) => p?.changes ?? {}))

      // bulkChanges equiv to explicit changes
      bulkChanges = NormalizeUtilities.mergeChanges(
        bulkChanges,
        ...payloads.map((p) => p?.explicitChanges ?? {})
      )
      let fullChanges = NormalizeUtilities.mergeChanges(downstream, bulkChanges)
      let newNorm = NormalizeUtilities.mergeChanges(norm.value, fullChanges)

      const dims = await $store.dispatch('Dimension/getPossibleDimensions')

      const cascadedChanges = NormalizeUtilities.mergeChanges(
        ...Object.keys(fullChanges).map(
          // skip cascade for assemblies because any adjustemnets calculated from children won't work
          (id) =>
            newNorm[id].type === 'assembly'
              ? {}
              : Auditing.cascadeDependencies(newNorm, id, {}, dims)[1]
        )
      )
      downstream = NormalizeUtilities.mergeChanges(downstream, cascadedChanges)

      bundleChanges.push(downstream)

      setStoreChanges(downstream, equations, bulkChanges, rootRefId, refIds)
    }

    let cumulativeDownstream = {}
    let cumulativeEquations = {}
    let cumulativeBulkChanges = {}
    let cumulativeRefIds = []
    const setStoreChanges = (rdownstream, requations, rbulkChanges, rootRefId, rrefIds) => {
      cumulativeDownstream = NormalizeUtilities.mergeChanges(cumulativeDownstream, rdownstream)
      cumulativeEquations = NormalizeUtilities.mergeChanges(cumulativeEquations, requations)
      cumulativeBulkChanges = NormalizeUtilities.mergeChanges(cumulativeBulkChanges, rbulkChanges)
      cumulativeRefIds = [...cumulativeRefIds, ...rrefIds]

      c.throttle(
        async () => {
          // Flush
          let downstream = { ...cumulativeDownstream }
          let equations = { ...cumulativeEquations }
          let bulkChanges = { ...cumulativeBulkChanges }
          const refIds = [...cumulativeRefIds]
          cumulativeDownstream = {}
          cumulativeEquations = {}
          cumulativeBulkChanges = {}
          cumulativeRefIds = []

          let fullChanges = NormalizeUtilities.mergeChanges(downstream, bulkChanges)

          // Merge changes into `newNorm` once to avoid multiple operations
          const downstreamKeys = Object.keys(downstream)
          let newNorm = NormalizeUtilities.mergeChanges(norm.value, fullChanges)

          // Precompute blended equations to avoid repetitive operations
          const blendedEquations = {}
          for (const ref of Object.keys(equations)) {
            const existingEquations = newNorm[ref]?.oEquations || {}
            blendedEquations[ref] = {
              oEquations: Object.assign({}, existingEquations, equations[ref])
            }
          }

          // Merge blended equations
          newNorm = NormalizeUtilities.mergeChanges(newNorm, blendedEquations)

          // Commit updated `newNorm` to the store
          $store.commit({
            type: `${store}/${types.SET_NORMALIZED}`,
            object: newNorm,
            skipDefaulting: true,
            prune: false
          })

          // Combine watcher references into a single array
          const watcherRefs = new Set([...downstreamKeys, ...Object.keys(bulkChanges)])

          // Fetch ChangeManager in parallel to maximize concurrency
          const cmPromise = $store.dispatch(`${store}/verifyChangeManager`, { refId: rootRefId })

          // Ignore interim changes
          ignore()

          // Commit changes in batch to reduce store overhead
          const cm = await cmPromise
          cm.addExplicitChanges(bulkChanges, false)
          cm.addChanges(downstream, false, newNorm)
          cm.triggerWatchers([...watcherRefs], downstream)

          // Build defaults by type efficiently
          const defaultsByType = {}
          for (const refId in fullChanges) {
            const normRef = newNorm[refId]
            const type = normRef.type
            const constr = c.getConstructor(type)
            const fields = constr.fields

            if (!defaultsByType[type]) {
              defaultsByType[type] = {}
            }

            for (const field in fullChanges[refId]) {
              const fieldValue = fullChanges[refId][field]
              if (fieldValue !== null && fields[field]?.defaultSetting) {
                defaultsByType[type][field] = fieldValue
              }
            }
          }

          // Dispatch updates for defaults by type in batches
          if (!inGlobalScope) {
            const defaultTypeKeys = Object.keys(defaultsByType).filter(
              (type) => Object.keys(defaultsByType[type]).length
            )

            await Promise.all(
              defaultTypeKeys.map(async (type) => {
                await $store.dispatch('ajax', {
                  path: `${type}/addDefaults`,
                  data: defaultsByType[type]
                })

                c.throttle(() => $store.dispatch('getBaseValues', { cloak: false }, { root: true }))
              })
            )
          }

          // Re-enable listeners
          listen()

          await auditFull(refIds[0], {})
        },
        { delay: 1000 }
      )
    }

    const inGlobalScope = () => {
      return $store.state.session.user.user_is_super_user && !$store.state.session.user.company_id
    }

    const sameAsOriginal = (id, field, value, original) => {
      const isNumeric = c.isNumericField(field)
      if (!isNumeric && `${value}` === `${original}`) return true

      if (isNumeric && (value ?? true) === (original ?? true) && c.eq(value, original)) return true

      return false
    }

    const audits = ref(0)
    watch(audits, (numAudits) => {
      let val = 0
      if (numAudits > 1) val = 25
      if (numAudits > 2) val = 15
      if (numAudits === 1) val = 45
      if (numAudits === 1) val = 45
      if (numAudits === 0) {
        val = 99
        c.throttle(
          () => {
            auditProgress.value = 0
          },
          { delay: 400 }
        )
      }
      auditProgress.value = val
    })
    const auditProgress = ref(0)

    const auditFull = async (refId, originals = {}) => {
      // audits.value += 1
      // Then trigger a full audit, not immediate, do not await
      const fullAuditPayload = await $store.dispatch(`${store}/auditDependencies`, {
        refId,
        immediate: false,
        delay: 10 * Object.keys(norm.value).length,
        auditId: 'entitySheet->auditFull'
      })

      // audits.value -= 1

      ignore()

      instillChanges([fullAuditPayload], originals)

      listen()
    }

    // From VUEX to Sheets
    const instillChanges = (changePayloads = [], originals = {}) => {
      const responseChanges = {}
      for (let i = 0; i < changePayloads.length; i += 1) {
        const payload = { ...changePayloads[i] }
        const { changeSet } = payload

        if (!payload.audited) continue

        Object.keys(changeSet).forEach((id) => {
          Object.keys(changeSet[id]).forEach((field) => {
            const originalVal = originals[id] && originals[id][field]
            if (sameAsOriginal(id, field, changeSet[id][field], originalVal)) return

            responseChanges[id] = {
              ...responseChanges[id],
              [field]: changeSet[id][field]
            }
          })
        })
      }

      bundleChanges.push(responseChanges)

      return responseChanges
    }

    const mapChangeSet = (changeSet, eqSet = {}, addDefaults = false) => {
      if (!fieldMapping || !Object.keys(fieldMapping).length) {
        return changeSet
      }

      const mapped = {}
      const equations = {}
      const refs = Object.keys(changeSet)

      for (let i = 0; i < refs.length; i += 1) {
        const id = refs[i]
        const obj = norm.value[id]
        if (!obj) continue
        const type = obj.type
        mapped[id] = mapItem(
          {
            ...(changeSet[id] ?? {}),
            ...(addDefaults ? { refId: id } : {})
          },
          type,
          addDefaults
        )

        const getters = equationGetters[obj.type] || equationGetters.all
        const eqItem = getters(changeSet[id], obj)
        const mappedEqItem = mapItem(
          {
            ...eqItem,
            ...(eqSet[id] ?? {})
          },
          obj.type
        )

        equations[id] = mappedEqItem
      }

      return {
        values: mapped,
        equations
      }
    }

    const demapChangeSet = (changeSet) => {
      if (!fieldMapping || !Object.keys(fieldMapping).length) {
        return changeSet
      }

      const mapped = {}
      const equations = {}
      const refs = Object.keys(changeSet)

      for (let i = 0; i < refs.length; i += 1) {
        const id = refs[i]
        const type = norm.value[id].type || 'any'
        mapped[id] = demapItem(changeSet[id], type)

        const setters = equationSetters[type] || equationSetters.all
        const eqItem = setters(mapped[id])

        equations[id] = eqItem
      }

      return {
        values: mapped,
        equations
      }
    }

    const { getConstructor } = ObjectHelpers
    // From Sheets to VUEX
    const changesHandler = async (changes) => {
      bench('changes handler')
      let equations = {}
      let explicit = {}
      let originals = {}

      const values = changes.reduce((acc, change) => {
        const id = change.id // VueStore refId
        const field = getOriginalField(change.field, getType(norm.value[id]))
        const value = change.deformatted
        const eq = change.equation

        if (change.explicit) {
          explicit = {
            ...explicit,
            [id]: {
              ...(explicit[id] || {}),
              [field]: value
            }
          }
        }

        equations = {
          ...equations,
          [id]: {
            ...(equations[id] || {}),
            [field]: eq ?? null // trigger unset with null if not set
          }
        }

        originals = {
          ...originals,
          [id]: {
            ...(originals[id] || {}),
            [field]: change.previous.raw
          }
        }

        return {
          ...acc,
          [id]: {
            ...(acc[id] || {}),
            [field]: value
          }
        }
      }, [])

      if (!Object.keys(values).length) return

      bench('changes handler', 'got values')
      await setFields(values, equations, explicit, originals)
      bench('changes handler', 'set fields done', values)
    }

    const defaultLaborTypeId = ref(null)
    const defaultLaborType = ref({})
    watch(defaultLaborTypeId, async (id) => {
      const { object: laborType } = await $store.dispatch(
        'LaborType/resolveObject',
        { id },
        { root: true }
      )
      defaultLaborType.value = laborType
    })
    const defaultMarkup = ref(null)

    const buildDefaultBlankItems = (rows) => {
      return Promise.all(
        rows.map(async (row) => {
          const isParent = refSheet.value.isRowParent(refSheet.value.getRowFromId(row.id))
          const type = isParent ? 'assembly' : 'cost_item'

          const { object: defaulted } = await $store.dispatch('Quote/buildDefaultObject', {
            type,
            embue: {
              ...(type === 'cost_item'
                ? { cost_matrix_markup_net: $store.getters.defaultMarkup || 1 }
                : {})
            }
          })

          return {
            ...defaulted,
            refId: row.id,
            parentRefId: row.parentId,
            aoChildren: row.childrenIds,
            type
          }
        })
      )
    }

    const normalizeRows = async (rows) =>
      rows.reduce(
        (acc, item) => ({
          ...acc,
          [item.refId]: item
        }),
        {}
      )

    const denormalizeRows = async (normalizedRows, rootChildrenRefs) => {
      const injected = {
        ...norm.value,
        ...normalizedRows
      }

      return denormalizeSet(injected, rootChildrenRefs)
    }

    let accumulatedRows = []
    const addedRowsHandler = async (payload) => {
      const { parentId, position, rows: providedRows } = payload
      accumulatedRows.push(...providedRows)

      c.throttle(
        async () => {
          const rows = [...accumulatedRows]
          accumulatedRows = []

          const defaultedItems = await buildDefaultBlankItems(rows)
          const normalizedItems = await normalizeRows(defaultedItems)
          const children = await denormalizeRows(
            normalizedItems,
            rows.map((row) => row.id)
          )

          const { convertedRefIds, childSet } = await $store.dispatch(`${store}/addChildren`, {
            children,
            refId: parentId, // only add to one parent at a time
            position,
            skipAudit: true
          })

          refSheet.value.rereference(convertedRefIds)
          bundleChanges.push(childSet)

          const { changeSet } = await $store.dispatch(`${store}/auditDependencies`, {
            normalized: _.imm(norm.value),
            immediate: true,
            queue: false,
            auditId: 'addedRows'
          })

          bundleChanges.push(changeSet)
        },
        { delay: 200 }
      )
    }

    const removedRowsHandler = (payload) => {
      const { rows } = payload

      const ids = rows.map((row) => row.id)
      $store.dispatch(`${store}/removeChildren`, {
        refIds: ids
      })
    }

    const duplicatedRowsHandler = async (payload) => {
      const { id: originalId, idMap } = payload

      const { convertedRefIds, changeSet } = await $store.dispatch(`${store}/duplicateItem`, {
        refId: originalId
      })

      // Since the heavy lifting is already done by Sheets, all
      // we need to do is ensure that all fields not visible by sheets are correct
      // and re-map the sheet-created IDs to store-created refIds
      const rerefMap = Object.keys(idMap).reduce(
        (acc, duped) => ({
          ...acc,
          [idMap[duped]]: convertedRefIds[duped]
        }),
        {}
      )

      refSheet.value.rereference(rerefMap)
      bundleChanges.push(changeSet)
    }

    const movedRowsHandler = async (payload) => {
      const { changes, parentId } = payload

      // All we need to send is the new parent and new parents new children
      // and all the old parents will have their children automatically remvoed
      const { changeSet } = await $store.dispatch(`${store}/transferChildren`, {
        childRefIds: changes[parentId].childrenIds,
        parentRefId: parentId
      })

      // const payloads = await Promise.all(
      //   parentChanges.map((ch) =>
      //     $store.dispatch(`${store}/transferChildren`, {
      //       childRefIds: ch.childrenIds,
      //       parentRefId: ch.refId,
      //       skipAudit: true
      //     })
      //   )
      // )
      //
      // const { changeSet } = await $store.dispatch(`${store}/auditDependencies`, {
      //   refId: parentId,
      //   force: true,
      //   immediate: true
      // })
      // const mergedChanges = NormalizeUtilities.mergeChanges(
      //   changes,
      //   changeSet,
      // )

      bundleChanges.push(changeSet)
    }

    const turnItemIntoAssemblyHandler = async (payload) => {
      const { assemblyRow, itemRow, itemRowData } = payload

      // Create assembly basd on assemblyRow
      const parent = norm.value[assemblyRow.parentId]
      let children = parent.aoChildren
      let position = children.indexOf(itemRow.id)

      const override = {
        assembly_name: itemRowData.name,
        type: 'assembly'
      }
      const { object: assembly } = await $store.dispatch('Assembly/buildDefaultObject')

      const { convertedRefIds } = await $store.dispatch(`${store}/addChildren`, {
        children: [
          {
            ...assembly,
            ...override,
            refId: assemblyRow.id,
            parentRefId: assemblyRow.parentId,
            aoChildren: assemblyRow.childrenIds,
            type: 'assembly'
          }
        ],
        refId: assemblyRow.parentId,
        position,
        skipAudit: true
      })

      const newAssemblyRefId = convertedRefIds[assemblyRow.id]
      refSheet.value.rereference(convertedRefIds)

      // move itemRow into assembly
      const { changeSet } = await $store.dispatch(`${store}/transferChildren`, {
        childRefIds: [itemRow.id],
        parentRefId: newAssemblyRefId,
        skipAudit: true
      })
      bundleChanges.push(changeSet)

      const { newSet } = await $store.dispatch(`${store}/auditDependencies`, {
        force: true,
        immediate: true,
        auditId: 'turnIntoAssembly'
      })

      const newAssemblyChangeSet = { [newAssemblyRefId]: newSet[newAssemblyRefId] }
      const { values, equations } = mapChangeSet(newAssemblyChangeSet)

      let ds = [...refSheet.value.dataSheets]

      ;({ dataSheets: ds } = refSheet.value.addSourceRows(
        { dataSheets: ds },
        {
          pointer: assemblyRow.pointer,
          data: values[newAssemblyRefId],
          equations: equations,
          sheetIndex: assemblyRow.sheetIndex,
          id: newAssemblyRefId
        }
      ))
      refSheet.value.setDataSheets(ds)

      // Set fields for each to be safe
      bundleChanges.push(newAssemblyChangeSet)
      // refSheet.value.parseCellData()
      // const rowIndex = refSheet.value.getRowFromId(newAssemblyRefId);
      // refSheet.value.initiateComputedCellValues(rowIndex, rowIndex + 1)
      //
      // refSheet.value.clearFragments(`row-${rowIndex}|row-${rowIndex + 1}`)
      // refSheet.value.triggerRedraw()
    }

    /**
     * Capture when a changes happens in a ObjectSimple/ObjectDistinct/ObjectManipulator component
     * @param refId
     * @param changedStore
     */
    const itemChangedHandler = async ({ refId, store: changedStore }) => {
      if (!(refId in norm.value) || changedStore !== store) return

      await c.throttle(() => {}, { delay: 400 })
      reloadItem(refId)
    }

    const resetHandler = () => {
      refSheet.value.reinitialize()
    }

    const diff = ref({})

    onMounted(() => {
      refSheet.value.$on('changes', changesHandler)
      refSheet.value.$on('addedRows', addedRowsHandler)
      refSheet.value.$on('duplicatedRows', duplicatedRowsHandler)
      refSheet.value.$on('removedRows', removedRowsHandler)
      refSheet.value.$on('movedRows', movedRowsHandler)
      refSheet.value.$on('turnItemIntoAssembly', turnItemIntoAssemblyHandler)
      defaultLaborTypeId.value = 'craftsman-3'
      defaultMarkup.value = $store.getters.defaultMarkup

      eventBus.$on('committed', itemChangedHandler)

      // handle resets
      eventBus.$on('reset', resetHandler)
    })

    const reloadItem = (refId) => {
      bundleChanges.push({
        [refId]: _.imm($store.state[store].normalized[refId])
      })
    }

    onBeforeUnmount(() => {
      refSheet.value.$off('changes', changesHandler)
      refSheet.value.$off('addedRows', addedRowsHandler)
      refSheet.value.$off('duplicatedRows', duplicatedRowsHandler)
      refSheet.value.$off('removedRows', removedRowsHandler)
      refSheet.value.$off('movedRows', movedRowsHandler)
      refSheet.value.$off('turnItemIntoAssembly', turnItemIntoAssemblyHandler)
      eventBus.$off('committed', itemChangedHandler)
      eventBus.$off('reset', resetHandler)
    })
    const importAddedItems = async (refIds, norm, startRow) => {
      bench('importAddedItems')
      const sh = refSheet.value
      const allRefs = NormalizeUtilities.getDescendants(norm, refIds, true)
      const sortedRefs = NormalizeUtilities.sortNatural(norm, allRefs)
      const sheetIndex = sh.getRowSheet(startRow < 1 ? 0 : startRow - 1)

      let ds = [...sh.dataSheets]
      let rm = [...sh.rowsMap]
      let cd = { ...sh.cellData }

      let currentRow = startRow

      const { items, equations } = normalizedSheetRows.value

      bench('importAddedItems', 'going to iterate')
      for (let i = 0; i < sortedRefs.length; i += 1) {
        const id = sortedRefs[i]
        const parent = norm[id].parentRefId
        const groupKey = null

        const isParent = norm[id].type === 'assembly'

        if (isParent) {
          // Create a new collapse group for the new assembly and collapse
          ds[sheetIndex].collapseGroups.groups = {
            ...ds[sheetIndex].collapseGroups.groups,
            [id]: {
              id,
              expanded: false,
              rows: [], // will be sorted out later
              ids: [],
              parentId: parent,
              level: ds[sheetIndex].collapseGroups.groups[parent].level + 1,
              childrenIds: norm[id].aoChildren
            }
          }
          sh.collapseGroup(id)
        }

        // For items at the root level, add them to the parent manually
        if (!refIds.includes(parent)) {
          ds[sheetIndex].collapseGroups.groups[parent].childrenIds = [
            ...ds[sheetIndex].collapseGroups.groups[parent].childrenIds,
            id
          ]
        }

        let fakeOriginalRow = _.uniqueId('ro')
        // Now add the visible rows
        ;({
          dataSheets: ds,
          rowsMap: rm,
          cellData: cd
        } = sh.addNewRow(
          {
            dataSheets: ds,
            rowsMap: rm,
            cellData: cd
          },
          {
            rowIndex: currentRow,
            sheetIndex,
            pointer: fakeOriginalRow,
            id,
            rowData: items[sheetIndex][id] ?? {},
            rowEquations: equations[sheetIndex][id] ?? {},
            parentId: parent,
            groupKey,
            formatting: currentRow.formatting ?? {},
            calculateCells: true
          }
        ))

        if (isParent) {
          ;({ dataSheets: ds, rowsMap: rm } = sh.createParentFromRow(
            {
              dataSheets: ds,
              rowsMap: rm
            },
            {
              rowIndex: currentRow,
              rebuild: false
            }
          ))
        }

        ;({ dataSheets: ds } = sh.addToParent(
          { dataSheets: ds, rowsMap: rm },
          {
            rows: [rm[currentRow]], // only the original
            parentId: parent,
            rebuild: false
          }
        ))

        currentRow += 1
      }

      bench('importAddedItems', 'structure iterate done')
      ;({ dataSheets: ds } = sh.rebuildCollapseGroupsFromParentChild(
        {
          dataSheets: ds,
          rowsMap: rm,
          cellData: cd
        },
        { sheetIndex }
      ))
      bench('importAddedItems', 'collapse group rebuild done')

      // // Set global values
      sh.setDataSheets(ds)
      sh.setRowsMap(rm)
      sh.setCellData(cd)
      bench('importAddedItems', 'set data done')

      sh.parseCellData()
      bench('importAddedItems', 'parse cell data done')
      // sh.initiateComputedCellValues(startRow, startRow + allRefs.length)
      bench('importAddedItems', 'initiateComputedCellValues done')

      await sh.triggerRedraw()
      bench('importAddedItems', 'triggerRedraw done')
    }

    return {
      sheetRows,
      columns,
      diff,
      demapChangeSet,
      mapChangeSet,
      norm,
      setFields,
      auditProgress,
      reloadItem,
      setMeta,
      setAsFee,
      storeLoading,
      importAddedItems
    }
  }
}
