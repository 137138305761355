export default {
  methods: {
    confirmSettlementSpeed(interval) {
      return this.updatePayoutPriority(interval)
    },
    /**
     * Get the class to display for the merchant status badge
     */
    getStatusClass(status) {
      let className = 'bg-dark'
      switch (status.toLowerCase()) {
        case 'active':
          className = '!bg-matcha-500'
          break
        case 'restricted':
          className = '!bg-deep-red-500'
          break
        case 'charges enabled':
          className = '!bg-yellow-700'
          break
        case 'pending payment':
          className = '!bg-yellow-700'
          break
        default:
          className = '!bg-surface-500'
      }
      return className
    },
    /**
     * Load the balances to display
     */
    async loadBalances() {
      try {
        this.balancesLoading = true
        const { payload } = await this.fetchBalances()
        this.balances = payload
        this.balancesLoading = false
      } catch (e) {
        console.error('Error loading balances:', e)
      }
    },
    /**
     * When clicking the warning above the dashboard to add a new
     * bank account switch to that tab
     */
    addExternalAccount() {
      this.tab = ['Activity']
      setTimeout(() => {
        c.scrollTo(document.querySelector('#bankAccounts'))
      }, 600)
    },
    /**
     * Navigate to settings tab and focus on specific element
     */
    navigateToSettings(elementId) {
      this.activeTabIndex = 5

      this.$nextTick(() => {
        setTimeout(() => {
          // Find and focus the element
          const element = document.getElementById(elementId)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            element.focus()
          }
        }, 100)
      })
    },
    /**
     * Handle Withdraw Money button click
     */
    handleWithdrawMoney() {
      const ref = this.type === 'business_account' ? 'activity' : 'activities'
      this.$refs[ref]?.$refs.payoutModal.open()
    },
    /**
     * Handle Send to Bank Account button click
     */
    handleSendToBank() {
      this.$refs.activity?.$refs.thirdPartyTransferModal.open()
    },
    /**
     *  Change the payout interval
     */
    async onChangeSettlementInterval(interval) {
      // T+0
      if (interval === 'instant') {
        return this.$store.dispatch('modal/confirm', {
          message:
            'You are switching to faster settlements. You will be charged 1% service fee for every transaction settlement.',
          actions: {
            confirm: {
              title: 'Confirm',
              action: () => this.confirmSettlementSpeed(interval)
            },
            cancel: {
              title: 'Cancel'
            }
          }
        })
      }
      // T+1
      if (interval === 'fast') {
        return this.$store.dispatch('modal/confirm', {
          message:
            'You are switching to fast settlements. You will be charged 0.5% service fee for every transaction settlement.',
          actions: {
            confirm: {
              title: 'Confirm',
              action: () => this.confirmSettlementSpeed(interval)
            },
            cancel: {
              title: 'Cancel'
            }
          }
        })
      }
      // T+2
      if (interval === 'regular') {
        return this.$store.dispatch('modal/confirm', {
          message: 'You are switching to regular 2 day settlements.',
          actions: {
            confirm: {
              title: 'Confirm',
              action: () => this.confirmSettlementSpeed(interval)
            },
            cancel: {
              title: 'Cancel'
            }
          }
        })
      }
      return this.confirmSettlementSpeed()
    },
    /**
     * Fetch the payee's status
     * @returns {Promise<String>}
     */
    async getPayeeStatus() {
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: '/counterparty/payeeStatus'
        })
        const { status } = payload
        return status
      } catch (e) {
        return 'b'
      }
    },
    /**
     * Fetch a payee
     * @param {Object} data
     * @returns {Promise<*>}
     */
    fetchPayee(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/counterparty/fetchPayee',
        data
      })
    },
    /**
     * Onboard a payee
     * @param {Object} data
     * @returns {Promise<*>}
     */
    onboardPayee(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/counterparty/onboardPayee',
        data
      })
    },
    /**
     * Get the business account terms of service document
     * @param {Object} data
     * @returns {Promise<*>}
     */
    getCounterpartyBusinessAccountTermsOfService(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/counterparty/getBusinessAccountTermsOfService',
        data
      })
    },
    /**
     * Get the previously uploaded business account terms of service
     * @param {Object} data
     * @returns {Promise<*>}
     */
    getCounterpartyUploadedBusinessAccountTermsOfService(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/counterparty/getUploadedBusinessAccountTermsOfService',
        data
      })
    },
    /**
     * Onboard a business account
     * @param {Object} data
     * @returns {Promise<*>}
     */
    onboardCounterpartyBusinessAccount(data = {}) {
      return this.$store.dispatch('ajax', {
        path: '/counterparty/onboardBusinessAccount',
        data
      })
    },
    /**
     * Update a payee
     * @param {Object} data
     * @returns {Promise<*>}
     */
    updatePayee(data = {}) {
      return this.$store.dispatch('ajax', {
        path: 'counterparty/updatePayee',
        data
      })
    },
    /**
     * Update a counterparty settings
     * @param {String} counterpartyId
     * @param {Object} oSettings
     * @returns {Promise<*>}
     */
    updateCounterpartySettings(counterpartyId, oSettings = {}) {
      return this.$store.dispatch('ajax', {
        path: 'counterparty/saveChanges',
        data: [
          {
            counterparty_id: counterpartyId,
            oSettings
          }
        ]
      })
    },
    /**
     * Fetch a payee's virtual account balances
     * @param {Object} data
     * @returns {Promise<*>}
     */
    fetchBalances(data) {
      return this.$store.dispatch('ajax', {
        path: 'counterparty/fetchBalances',
        data
      })
    },
    /**
     * Fetch a payee's transactions to display the full transaction lifecycle
     * @param {Object} data
     * @returns {Promise<*>}
     */
    fetchTransactions(data) {
      return this.$store.dispatch('ajax', {
        path: 'counterparty/fetchTransactions',
        data
      })
    },
    /**
     * Update whether a payee has auto payouts turned on
     * @param {Booean} toggle
     * @returns {Promise<*>}
     */
    updatePayoutSchedule(toggle = true) {
      return this.$store.dispatch('ajax', {
        path: 'payfac/updatePayoutSchedule',
        data: {
          interval: toggle ? 'daily' : 'manual'
        }
      })
    },
    /**
     * Update the transfer speed
     * @param {String} interval
     * @returns {Promise<*>}
     */
    updatePayoutPriority(interval = 'regular') {
      return this.$store.dispatch('ajax', {
        path: 'payfac/updatePayoutPriority',
        data: {
          interval
        }
      })
    },
    /**
     * Manually trigger a payout
     * @param {Object} params
     * @returns {Promise<*>}
     */
    createPayout({
      amount,
      currency,
      sourceType,
      sourceExternalId,
      sourceBalanceAccountId,
      destinationType,
      destinationExternalId,
      destinationBalanceAccountId
    }) {
      return this.$store.dispatch('ajax', {
        path: 'payfac/createPayout',
        data: {
          amount,
          currency,
          sourceType,
          sourceExternalId,
          sourceBalanceAccountId,
          destinationType,
          destinationExternalId,
          destinationBalanceAccountId
        }
      })
    },
    /**
     * Fetch counterparties virtual accounts
     * @returns {Promise<*>}
     */
    payeeRequirements(counterpartyType) {
      return this.$store.dispatch('ajax', {
        path: 'counterparty/payeeRequirements',
        data: {
          counterpartyType
        }
      })
    },
    /**
     * Set the company up for payments creating account and adding external bank account
     */
    async getBusinessAccountTermsOfService(decisionMakerIds) {
      try {
        this.loading = 2
        this.error = null

        const { payload } = await this.handleGetBusinessAccountTermsOfService({
          decisionMakerIds,
          publicToken: null
        })

        if (payload.error) throw payload.error
        this.loading = 0
        return payload
      } catch (e) {
        this.error =
          'An error occurred while setting up your profile. Contact support if this issue persists.'
        this.loading = 0
        return false
      }
    },
    // ------------- CONSUMER FINANCING -------------
    /**
     * Fetch the payee's status
     * @returns {Promise<String>}
     */
    async getFinancerStatus() {
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: '/counterparty/getFinancerStatus'
        })
        const { status } = payload
        return status
      } catch (e) {
        return 'b'
      }
    },
    async setCounterpartyData({ owners, counterparty }) {
      const primaryId =
        owners.length === 1
          ? owners[0].id
          : owners.filter((owner) => owner.primary).map((owner) => owner.id)[0]
      const accountHolder = counterparty.counterparty_external_token
      const decisionMakerIds = owners.map((owner) => owner.id)

      let businessAccountTermsOfService = null
      const country = this.$store.state.session.company.country_abbr
      if (country === 'us')
        businessAccountTermsOfService =
          await this.getBusinessAccountTermsOfService(decisionMakerIds)

      this.$store.dispatch(`payfac/setCounterpartyData`, {
        companyId: this.$store.state.session.company.company_id,
        data: {
          primaryId,
          accountHolder,
          decisionMakerIds,
          ...(country === 'us' ? businessAccountTermsOfService : null)
        }
      })
    }
  }
}
