<template>
  <card-section>
    <Btn
      class="text-info text-center mt-1 w-48 mx-auto"
      severity="tertiary"
      v-if="isPaymentsOnboarded && invoiceStatus === 'o' && counterpartyStatus === 'a'"
      @click="downloadInvoice"
    >
      <font-awesome-icon icon="download" class="mr-2" />
      <template v-if="invoiceStatus === 'e'"> Download Receipt </template>
      <template v-else>
        {{ $t('Download Invoice') }}
      </template>
    </Btn>
    <Btn
      v-if="
        !isPaymentsOnboarded ||
        counterpartyStatus !== 'a' ||
        invoiceStatus === 'e' ||
        invoiceStatus === 'p'
      "
      class="btn-link text-center info mt-2"
      severity="tertiary"
      @click="downloadInvoice"
    >
      <font-awesome-icon icon="download" class="mr-2" />
      <template v-if="invoiceStatus === 'e'"> Download Receipt </template>
      <template v-else>
        {{ $t('Download Invoice') }}
      </template>
    </Btn>
  </card-section>
</template>

<script>
import TranslationMixin from './languages/TranslationMixin'

export default {
  name: 'PresentationContents',
  props: {
    isPaymentsOnboarded: {
      type: Boolean,
      default: false
    },
    counterpartyStatus: {
      type: String,
      default: 's'
    },
    invoiceStatus: {
      type: String,
      default: 'o'
    }
  },
  components: {},
  mixins: [TranslationMixin],
  emits: ['download'],
  data() {
    return {}
  },
  methods: {
    downloadInvoice() {
      this.$emit('download')
    }
  }
}
</script>
