const GenericLeadForm = () => import('@/components/pages/GenericLeadForm.vue')
const InvalidAuth = () => import('@/components/pages/InvalidAuth.vue')
const InvoicePresentation = () => import('@/components/pages/InvoicePresentation.vue')
const LeadFinalization = () => import('@/components/pages/LeadFinalization.vue')
const LeadForm = () => import('@/components/pages/LeadForm.vue')
const Form = () => import('@/components/pages/Form.vue')
const LeadReceived = () => import('@/components/pages/LeadReceived.vue')
const LeadRequest = () => import('@/components/pages/LeadRequest.vue')
const Legal = () => import('@/components/pages/Legal.vue')
const MSA = () => import('@/components/pages/MSA.vue')
const PasswordReset = () => import('@/components/pages/PasswordReset.vue')
const PasswordUpdate = () => import('@/components/pages/PasswordUpdate.vue')
const Pricing = () => import('@/components/pages/Pricing.vue')
const Privacy = () => import('@/components/pages/Privacy.vue')
const QuotePresentation = () => import('@/components/pages/QuotePresentation.vue')
const RatingRequestPage = () => import('@/components/pages/RatingRequest.vue')
const Terms = () => import('@/components/pages/Terms.vue')
const Showcase = () => import('@/components/pages/Showcase.vue')
const PublicCatalog = () => import('@/components/pages/PublicCatalog.vue')
const ClientRatingPresentation = () => import('@/components/pages/ClientRatingPresentation.vue')
const QuoteHeader = () => import('@/components/headers/QuoteHeader.vue')
const CheckoutSuccess = () => import('@/components/pages/CheckoutSuccess.vue')
const CheckoutCancel = () => import('@/components/pages/CheckoutCancel.vue')

export default [
  {
    path: '/pub/lead',
    name: 'Leads',
    meta: {
      public: true
    },
    component: LeadFinalization
  },
  {
    path: '/pub/pricing/:currency/:slug/:type?',
    name: 'Pricing',
    meta: {
      public: true
    },
    component: Pricing
  },
  {
    path: '/terms',
    name: 'Terms',
    alias: ['/pub/terms'],
    meta: {
      public: true
    },
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    alias: ['/pub/privacy'],
    meta: {
      public: true
    },
    component: Privacy
  },
  {
    path: '/legal/msa',
    name: 'MSA',
    alias: ['/pub/legal/msa'],
    meta: {
      public: true
    },
    component: MSA
  },
  {
    path: '/legal',
    name: 'Legal',
    alias: ['/pub/legal'],
    meta: {
      public: true
    },
    component: Legal
  },
  {
    path: '/pub/invalidAuth',
    name: 'Invalid Authorization',
    meta: {
      public: true
    },
    component: InvalidAuth
  },
  {
    path: '/pub/invoice/:id',
    props: {
      type: 'invoice'
    },
    name: 'Invoice Presentation External',
    component: InvoicePresentation,
    meta: {
      confirmLeave: true,
      // reduces scope to maximum user and company and quote
      scopesAllowed: ['user', 'company', 'quote', 'item', 'invoice'],
      fixedLayout: false,
      type: 'invoice'
    }
  },
  {
    path: '/pub/leadReceived',
    name: 'Lead Received',
    meta: {
      public: true
    },
    component: LeadReceived
  },
  {
    // :id is lead_request_token in lead_request table
    path: '/pub/leadRequest/:id',
    name: 'Lead Request',
    meta: {
      public: true
    },
    component: LeadRequest
  },
  {
    path: '/leadForm/:id',
    name: 'Lead Form',
    component: LeadForm,
    meta: {
      public: true
    }
  },
  {
    path: '/customForm/:id',
    name: 'Custom Form',
    component: Form,
    meta: {
      public: true
    }
  },
  {
    path: '/form/:id',
    name: 'Generic Lead Form',
    component: GenericLeadForm,
    meta: {
      public: true
    }
  },
  {
    // :quoteId and companyId
    path: '/pub/Rating/:id/:quoteId/:companyId',
    name: 'Rating Request',
    component: RatingRequestPage,
    props: {
      type: 'quote'
    },
    meta: {
      // reduces scope to maximum user and company and quote
      scopesAllowed: ['user', 'company', 'quote'],
      fixedLayout: false
    }
  },
  {
    path: '/pub/passwordReset',
    name: 'Password Reset',
    component: PasswordReset,
    meta: {
      // reduces scope to maximum user and company and quote
      scopesAllowed: ['user'],
      fixedLayout: true
    }
  },
  {
    path: '/pub/passwordUpdate',
    name: 'Password Update',
    component: PasswordUpdate,
    meta: {
      // reduces scope to maximum user and company and quote
      scopesAllowed: ['user'],
      fixedLayout: true
    }
  },
  {
    path: '/pub/presentation/:id?',
    props: {
      type: 'quote'
    },
    name: 'Presentation External',
    component: QuotePresentation,
    meta: {
      confirmLeave: true,
      // reduces scope to maximum user and company and quote
      scopesAllowed: ['user', 'company', 'quote'],
      fixedLayout: false,
      header: QuoteHeader,
      type: 'quote'
    }
  },
  {
    path: '/pub/company/:companyId',
    name: 'Company Showcase',
    component: Showcase,
    meta: {
      public: true
    }
  },
  {
    path: '/pub/company/:companyId/catalog',
    name: 'Company Catalog',
    component: PublicCatalog,
    meta: {
      public: true
    }
  },
  {
    path: '/pub/client/:clientId/rating',
    name: 'Client Rating Presentation',
    component: ClientRatingPresentation,
    meta: {
      confirmLeave: true,
      // reduces scope to maximum user and company and quote
      scopesAllowed: ['user', 'company', 'quote'],
      fixedLayout: false
    }
  },
  {
    path: '/pub/checkout/success',
    name: 'CheckoutSuccess',
    component: CheckoutSuccess,
    meta: {
      public: true
    }
  },
  {
    path: '/pub/checkout/cancel',
    name: 'CheckoutCancel',
    component: CheckoutCancel,
    meta: {
      public: true
    }
  }
]
