<template>
  <!-- wrapper to save mouse over movement -->
  <div
    class="p-6 bg-transparent -m-2 w-fit h-fit"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
  >
    <div
      class="bg-surface-600 flex flex-col rounded overflow-hidden cursor-pointer w-8"
      :class="{ hidden: !isInQuoteEditor }"
    >
      <div
        v-tooltip="`Edit ${props.object.assembly_name || props.object.cost_type_name}`"
        @click="emit('edit', props.object.refId)"
        class="bg-blue-print-400 p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
      >
        <font-awesome-icon :icon="props.object.type === 'assembly' ? 'cubes' : 'cube'" />
      </div>

      <div
        v-if="isNotInteractiveItem && Object.keys(visibility ?? {}).length"
        @click.stop.prevent="clearVisibility()"
        class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center bg-deep-red-400"
        :class="{ 'opacity-50': !showQty }"
        v-tooltip="'Factory reset item, price and quantity visibility to presentation defaults.'"
      >
        <font-awesome-icon icon="arrow-turn-left" />
      </div>
      <!--      <ItemEmphasisControl-->
      <!--        v-if="props.object.type === 'assembly'"-->
      <!--        :isInQuoteEditor="isInQuoteEditor"-->
      <!--        :object="props.object"-->
      <!--      />-->

      <!--      <div v-if="props.object.type === 'assembly'"-->
      <!--        :class="[btnClass, {-->
      <!--          'opacity-50': assemblyEmphasis < 0,-->
      <!--          'text-level-yellow': assemblyEmphasis > 0,-->
      <!--        }]">-->
      <!--        <Choose-->
      <!--          class="!min-w-0"-->
      <!--          :value="assemblyEmphasis"-->
      <!--          @input="(v) => setField('assembly_emphasis', v)"-->
      <!--          :default="0"-->
      <!--          schema="assembly:assembly_emphasis"-->
      <!--          :allowCreate="false"-->
      <!--          :return-array="false"-->
      <!--          :staticSet="[-->
      <!--                { value: 1, text: 'Emphasize the assembly on proposal' },-->
      <!--                { value: 0, text: 'Show the assembly normally' },-->
      <!--                { value: -1, text: 'Make this assembly appear as an item' },-->
      <!--              ]"-->
      <!--        >-->
      <!--          <template #default="{value}">-->
      <!--            <font-awesome-icon icon="layer-group" />-->
      <!--          </template>-->
      <!--        </Choose>-->
      <!--      </div>-->

      <div
        v-if="props.object.type === 'assembly'"
        @click.stop.prevent="toggleVisibility('assemblyInitialState', assemblyInitialState ? 0 : 1)"
        :class="[btnClass]"
        v-tooltip="collapseTooltip"
      >
        <font-awesome-icon :icon="assemblyInitialState ? 'chevron-down' : 'chevron-right'" />
      </div>

      <div
        @click.stop.prevent="toggleVisibility('isVisible', showItem ? 0 : 1)"
        :class="[btnClass, { 'opacity-50': !showItem }]"
        v-tooltip="visibleTooltip"
      >
        <font-awesome-icon :icon="showItem ? 'eye' : 'eye-slash'" />
      </div>

      <div
        @click.stop.prevent="toggleVisibility('price', showPrice ? 0 : 1)"
        class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
        :class="{ 'opacity-50': !showPrice }"
        v-tooltip="priceTooltip"
        v-if="isNotInteractiveItem"
      >
        <font-awesome-icon icon="dollar-sign" />
      </div>

      <div
        @click.stop.prevent="toggleVisibility('qty', showQty ? 0 : 1)"
        class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
        :class="{ 'opacity-50': !showQty }"
        v-tooltip="quantityTooltip"
        v-if="isNotInteractiveItem"
      >
        <font-awesome-icon icon="ruler-triangle" />
      </div>

      <div
        v-if="
          isNotInteractiveItem &&
          (isOptional ||
            props.object.aoAddons?.length ||
            props.object.oVariations?.variationTypes?.length)
        "
        @click.stop.prevent="toggleLock"
        class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
        :class="{ 'bg-deep-red-400': isLocked, 'opacity-50': !isLocked }"
        v-tooltip="
          isLocked
            ? 'The selections and optionality on this item is locked'
            : 'Unlocked. Clients can make selections for this item'
        "
      >
        <font-awesome-icon :icon="isLocked ? 'far fa-lock' : 'far fa-lock-open'" />
      </div>

      <div
        @click.stop.prevent="toggleOptional"
        class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
        :class="{ 'opacity-50': !isOptional }"
        v-tooltip="isOptional ? 'Make not optional' : 'Make optional'"
        v-if="isNotInteractiveItem"
      >
        <font-awesome-icon icon="fas fa-circle-half-stroke" />
      </div>

      <div
        @click.stop.prevent="
          () => {
            setVisibilityOption(
              'addonsOrientation',
              visibility.addonsOrientation === 'horizontal' ? 'vertical' : 'horizontal'
            )
          }
        "
        class="p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center"
        v-tooltip="orientationTooltip"
        v-if="isNotInteractiveItem && props.object.aoAddons?.length"
      >
        <font-awesome-icon
          :icon="
            visibility.addonsOrientation === 'horizontal' ? 'arrows-left-right' : 'arrows-up-down'
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref, watch } from 'vue'
import eventBus from '@/eventBus'
import { useStore } from 'vuex'

const emit = defineEmits(['edit', 'hovering'])
const props = defineProps({
  isInQuoteEditor: {
    type: Boolean,
    required: true
  },
  object: {
    type: Object,
    required: true
  },
  presentationSettings: {
    type: Object,
    required: true
  }
})
const btnClass =
  'p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center'

const isAssembly = computed(() => props.object.type === 'assembly')
const visibility = computed(() => ({
  ...(props.object.oViewOptions?.pres ?? {}),
  ...(props.object.oMeta?.viewOptions?.pres ?? {})
}))
const showItem = computed(() => visibility.value.isVisible ?? 1)
const showPrice = computed(
  () =>
    visibility.value.price ??
    (props.presentationSettings.showItemizedPrices &&
      ((!isAssembly.value && props.presentationSettings.showCostItemPrices) ||
        (isAssembly.value && props.presentationSettings.showAssemblyPrices)))
)
const showQty = computed(() => visibility.value.qty ?? props.presentationSettings.showQuantities)
const assemblyInitialState = computed(
  () =>
    visibility.value.assemblyInitialState ?? props.presentationSettings.assemblyInitialState ?? 0
)

const collapseTooltip = computed(() =>
  assemblyInitialState.value
    ? 'Assembly expanded initially'
    : 'Assembly collapsed initially (can be expanded by client)'
)
const visibleTooltip = computed(() =>
  showItem.value
    ? `Hide ${props.object.type === 'assembly' ? 'assembly and contents' : 'item'}`
    : `Show ${props.object.type === 'assembly' ? 'assembly and contents' : 'item'}`
)
const priceTooltip = computed(() => (showPrice.value ? 'Hide price' : 'Show price'))
const quantityTooltip = computed(() => (showQty.value ? 'Hide quantity' : 'Show quantity'))
const orientationTooltip = computed(() =>
  visibility.value.addonsOrientation === 'horizontal'
    ? 'Show compact vertical options list'
    : 'Show larger horizontal options list'
)
const isNotInteractiveItem = computed(() => {
  const itemType = props.object.oMeta?.itemType
  const metaTypes = ['gallery', 'text']
  return !metaTypes.includes(itemType)
})

const isLocked = computed(() => props.object.oMeta?.lockOptions ?? 0)
const toggleLock = () => {
  const opt = isLocked.value ? 0 : 1
  setField(
    'oMeta',
    {
      ...(props.object?.oMeta ?? {}),
      lockOptions: opt
    },
    true,
    true
  )
}

const isOptional = computed(() => props.object[`${props.object.type}_is_optional`])
const toggleOptional = () => {
  const opt = isOptional.value ? 0 : 1
  const isAssembly = props.object.type === 'assembly'
  const f = isAssembly ? 'assembly_is_optional' : 'cost_item_is_optional'
  setField(f, opt, true, true)

  // if turning off optionality, make sure it is included so it doesn't cause wierd confusion
  if (!opt) setField(isAssembly ? 'assembly_is_included' : 'cost_item_is_included', 1, true, true)
}

const setField = (fieldName, value, explicit = false, skipAudit = false) => {
  eventBus.$emit('PresentationModifyItem', {
    refId: props.object.refId,
    changes: {
      [fieldName]: value
    },
    skipAudit,
    explicit
  })
}

const clearVisibility = () => {
  setField('oViewOptions', {}, true, true)
  setField(
    'oMeta',
    {
      ...(props.object?.oMeta ?? {}),
      viewSettings: {}
    },
    true,
    true
  )
}

const toggleVisibility = (key, val = null) => {
  const newValue = val ?? (visibility.value[key] ? 0 : 1)
  setVisibilityOption(key, newValue)
}

const $store = useStore()

const setVisibilityOption = async (key, value) => {
  const pres = {
    ...visibility.value,
    [key]: value
  }
  const payload = {
    ...props.object.oViewOptions,
    pres
  }
  setField('oViewOptions', payload, true, true)
  setField(
    'oMeta',
    {
      ...(props.object?.oMeta ?? {}),
      viewSettings: payload
    },
    true,
    true
  )

  // Now save for future
  const type = props.object.type
  const saveType = type === 'assembly' ? 'assembly' : 'cost_type'
  const idField = `${saveType}_id`
  const nameField = `${saveType}_name`
  const id = props.object[idField]
  const name = props.object[nameField]
  if (
    id &&
    props.object.company_id &&
    props.object.cost_type_status !== '@' &&
    props.object.assembly_status !== '@'
  ) {
    $store.dispatch(`${c.titleCase(saveType)}/partialUpdate`, {
      selected: [
        {
          [idField]: id,
          oViewOptions: payload,
          oMeta: {
            ...(props.object?.oMeta ?? {}),
            viewSettings: payload
          },
          type: saveType
        }
      ],
      alert: false
    })
    $store.dispatch('alert', {
      message: `Visibility settings of ${name} saved for next time it is used in an estimate. Save this estimate to save it for this proposal.`,
      success: true
    })
  }
  if (
    id &&
    (!props.object.company_id ||
      props.object.cost_type_status === '@' ||
      props.object.assembly_status === '@')
  ) {
    $store.dispatch('alert', {
      message: `Visibility settings of ${name} are changed for this estimate, but could not be saved for future because this item is not from your catalog. Save it to your catalog first to retain visibility settings. Save this estimate to save it for this proposal.`
    })

    // offer to save parent
    const parent = await $store.dispatch('Assembly/getClosestSavedParent', {
      store: 'Quote',
      refId: props.object.refId
    })
    const assemblyName = parent?.assembly_name

    if (parent) {
      // if found parent assembly, offer to save htat to retain visibliity settings
      c.throttle(
        () =>
          $store.dispatch('alert', {
            warning: true,
            message: `To save the visibility settings of "${name}" you need to save its parent assembly, "${assemblyName}". Save it now?`,
            actions: [
              {
                title: `Save ${assemblyName}`,
                action: () =>
                  $store.dispatch('Assembly/saveFromAssembly', {
                    store: 'Quote',
                    refId: parent.refId,
                    queue: false
                  })
              }
            ]
          }),
        {
          key: parent.parentRefId
        }
      )
    }
  }
}

const hovering = ref(false)
const handleMouseover = () => {
  hovering.value = true
}
const handleMouseout = () => {
  hovering.value = false
}

watch(hovering, (is) => {
  emit('hovering', is)
})
</script>
