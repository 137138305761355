import { useStore } from 'vuex'
import * as xlsx from 'xlsx'

export function useExportTools(global = false) {
  const store = useStore()

  async function exportObjects(object, sheetName, fileName) {
    const payload = {
      path: `/${object}/export`
    }

    // if user is a super-user and exporting global catalog
    if (global && store.state.session.user.user_is_super_user) {
      payload.data = { global: true }
    }

    const { set } = await store.dispatch('ajax', payload)

    if (!set.length) {
      return store.dispatch('alert', {
        message: 'No items to export yet!',
        error: true
      })
    }

    const headings = Object.keys(set[0])

    const wb = xlsx.utils.book_new()

    const aoa = [headings, ...set.reduce((acc, vals) => [...acc, Object.values(vals)], [])]

    const ws = xlsx.utils.aoa_to_sheet(aoa)
    xlsx.utils.book_append_sheet(wb, ws, sheetName)
    xlsx.writeFile(wb, `${fileName}.xlsx`)

    return true
  }

  return {
    exportObjects
  }
}
