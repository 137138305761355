<template>
  <p class="mb-2">Upload a picture of {{ item.item_name }}</p>
  <FileList :forceImage="true" :value="files" @input="onFilesChange" class="fileList" />
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import FileList from '@/components/ui/FileList.vue'
import eventBus from '@/eventBus'

// ======= Props ======== //
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  approval: {
    type: Object,
    required: true
  }
})
// ===================== //

// ==== Composables ==== //
const store = useStore()
// ===================== //

// ======= Data ======== //
const files = ref(props.approval.files || [])
const filesLoading = ref(false)
// ===================== //

// ======= Methods ======== //
const onFilesChange = async (updatedFiles) => {
  filesLoading.value = true

  try {
    c.throttle(
      async () => {
        const approvalId = props.approval.id
        const newFiles = await Promise.all(
          updatedFiles.map(async (file) => {
            // if bolster backend file //
            if (file.type) {
              const fileUrl = c.link(`file/view/${file.file_id}`)
              const fetchResp = await fetch(fileUrl)
              const fetchBlob = await fetchResp.blob()
              const fileObject = new File([fetchBlob], file.file_name)

              const { url, fields } = await store.dispatch(
                'Assignee/getThreeWayApprovalsFilePreSignedPost',
                {
                  fileType: file.file_type
                }
              )
              const formData = new FormData()
              const fileId = fields.key

              Object.entries({ ...fields, file: fileObject }).forEach(([key, value]) => {
                formData.append(key, value)
              })

              await fetch(url, {
                method: 'POST',
                body: formData,
                mode: 'no-cors'
              })

              return {
                id: file.file_id,
                file_url: `${import.meta.env.VITE_S3_THREE_WAY_APPROVALS_BUCKET_ENDPOINT}/${fileId}`,
                file_name: file.file_name,
                file_type: file.file_type
              }
            }
            return file
          })
        )

        const resp = await store.dispatch('Assignee/saveApprovalItemFiles', {
          approvalId,
          files: newFiles.map(({ id, file_url, file_name, file_type }) => ({
            id,
            file_url,
            file_name,
            file_type
          }))
        })

        files.value = newFiles
        eventBus.$emit('approval-action-processed', [resp])
      },
      {
        key: 'ApprovalFiles',
        delay: 500
      }
    )
  } catch (err) {
    console.error(err)
    store.dispatch(
      'alert',
      {
        error: true,
        message: 'Failed to update approval files, please try again'
      },
      { root: true }
    )
  } finally {
    filesLoading.value = false
  }
}
// ===================== //
</script>
