<template>
  <Page>
    <!-- Company has access to payments and has onboarded -->
    <div v-if="!loading">
      <CounterpartyDashboard v-if="hasAccess" :status="status" />

      <template v-else>
        <!-- Company does not have access to payments -->
        <container class="my-4 py-4" v-if="status === 'b'">
          <card-section>
            <card :flat="true">
              <div class="flex flex-col items-center w-100 text-center py-5">
                <h3 class="mb-3">Payments not available</h3>
                <p>
                  You do not currently have access to payments in your area. <br />
                  Contact support to request access.
                </p>
              </div>
            </card>
          </card-section>
        </container>

        <!-- Company has access to payments but has not onboarded -->
        <PaymentsLanding v-else-if="status === 'u'" @start="toggleOnboarding" />
      </template>
    </div>
    <CompanyOnboarding />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import CounterpartyMixin from '../../components/payments/counterparty/CounterpartyMixin'
import PayfacMixin from '@/components/payments/PayfacMixin'
import CounterpartyDashboard from '../payments/counterparty/dashboard/CounterpartyDashboard.vue'
import CompanyOnboarding from '../onboarding/CompanyOnboarding.vue'
import PaymentsLanding from '../payments/PaymentsLanding.vue'
import eventBus from '../../eventBus'

export default {
  name: 'Payments',
  mixins: [PageMixin, CounterpartyMixin, PayfacMixin],
  data() {
    return {
      loading: 1,
      status: 'b'
    }
  },
  methods: {
    toggleOnboarding() {
      eventBus.$emit('toggleCompanyOnboarding')
    },
    async handleKycCompleted() {
      await this.markOnboardingAsComplete()

      this.status = await this.getPayeeStatus()
    }
  },
  async mounted() {
    this.loading = 1

    if (!this.isPaymentsOnboarded) {
      eventBus.$on('kycCompleted', this.handleKycCompleted)
    }

    this.status = await this.getPayeeStatus()
    this.loading = 0
  },
  computed: {
    hasAccess() {
      return this.status !== 'b' && this.status !== 'u'
    }
  },
  components: {
    CounterpartyDashboard,
    CompanyOnboarding,
    PaymentsLanding
  }
}
</script>

<style scoped></style>
