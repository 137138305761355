<template>
  <div class="flex justify-start items-center gap-1 text-sm opacity-80">
    {{ fieldName }} -
    <span class="" v-if="formatType === 'html'"> changed </span>
    <span class="" v-else-if="formatType"
      >from
      {{ formattedFrom }}
      to {{ formattedTo }}
    </span>
    <span class="" v-else-if="/(_id|_owner|_creator)$/.test(field)"> changed </span>
    <span v-else class="">
      {{ desc }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ChangeAuditField',

  /**
   *
   */
  props: {
    /**
     * Field change schema as described in
     * api/Changes/Utilities.changedField
     */
    changes: {
      required: true
    },

    /**
     * Name of field
     */
    field: {
      required: false
    },

    /**
     * entity type
     */
    objectType: {
      required: false,
      default: ''
    },

    /**
     * Fields object as described in the api/schemas/{object}.fields
     */
    fields: {
      default: () => ({}),
      required: true
    }
  },

  computed: {
    fieldName() {
      const a = c.getFieldTitle(this.field, this.objectType)

      return c.format(a, 'ucfirst')
    },

    /**
     * Description of change
     * @returns {*}
     */
    desc() {
      if (c.isJsonField(this.field)) {
        return this.changes.desc || 'changed items.'
      } else if (this.changes.to === null) {
        return 'unset this field'
      } else if (
        this.field in this.fields &&
        /string|int|float/.test(this.fields[this.field].type)
      ) {
        let desc = 'changed'
        if (this.changes.from !== null) {
          desc = `${desc} from "${this.formattedFrom}"`
        }
        desc = `${desc} to "${this.formattedTo}"`

        return desc
      }

      return this.changes.desc
    },

    /**
     *
     * @returns {*}
     */
    formatType() {
      return c.getFieldFormatType(this.objectType, this.field)
    },

    /**
     *
     * @returns {string}
     */
    formattedFrom() {
      return this.changes.from === null ? 'NA' : c.format(this.changes.from, this.formatType)
    },

    /**
     *
     * @returns {string}
     */
    formattedTo() {
      return this.changes.to === null ? 'NA' : c.format(this.changes.to, this.formatType)
    }
  }
}
</script>
