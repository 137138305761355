<template>
  <Container class="full">
    <CardSection>
      <template #title>
        <span class="flex">
          What are you looking for?
          <div class="keyboard-key">⌘F</div>
        </span>
      </template>
      <div>
        <SearchField
          class="full left search-field"
          ref="searchField"
          placeholder="Search for proposals, clients, invoices and more..."
          v-model="searchPhrase"
          :large="true"
        />
      </div>
    </CardSection>

    <container-section width="100%" class="px-3">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-3 justify-center">
        <template v-if="!filteredServices.length">
          <div
            v-for="(col, index) in searchFilter(columns)"
            :key="index"
            :style="{
              maxWidth: '25em'
            }"
            class="col"
          >
            <section
              class="link-group-section"
              v-for="(service, i) in visibleSections(col)"
              :key="i"
            >
              <div class="section-title font-header font-bold mt-5 mb-3">
                {{ service.title }}
              </div>
              <ul class="cc-list-group cc-list-group-dark lg contiguous icon-top">
                <ServiceItem
                  v-for="link in searchFilter(service.links)"
                  :key="link.title"
                  :link="link"
                  :class="{ highlight: link.highlight }"
                >
                </ServiceItem>
              </ul>
            </section>
          </div>
        </template>

        <section v-else class="link-group-section">
          <ul class="cc-list-group cc-list-group-dark lg contiguous icon-top">
            <ServiceItem
              v-for="(link, index) in filteredServices"
              :link="link"
              :key="index"
              :highlight="false"
            >
            </ServiceItem>
          </ul>
        </section>
      </div>
    </container-section>

    <CardSection>
      <Search @close="close" v-model="searchPhrase" />
    </CardSection>
  </Container>
</template>

<script>
import ServiceItem from '@/components/ui/ServiceWaffle/ServiceItem.vue'
import Fuse from 'fuse.js'
import PageMixin from '../mixins/Page'
import Search from '../layout/Search.vue'
import eventBus from '../../eventBus'

export default {
  mixins: [PageMixin],
  name: '',
  data() {
    return {
      numberOfColumns: 3,
      searchPhrase: ''
    }
  },
  watch: {},
  computed: {
    columns() {
      const linksPerColumn = Math.ceil(this.totalLinks / this.numberOfColumns) + 1
      const services = [...this.services]
      return [...Array(this.numberOfColumns)].map((o, i) => {
        let count = 0
        let servicesHere = []
        while (services.length && services[0].links.length + count <= linksPerColumn) {
          count += services[0].links.length
          servicesHere.push(services.shift())
        }
        if (i === this.numberOfColumns - 1 && services.length) {
          servicesHere = [...servicesHere, ...services]
        }
        return servicesHere
      })
    },
    filteredServices() {
      if (!this.searchPhrase) return []

      const flat = this.services.map((s) => s.links).reduce((acc, links) => [...acc, ...links], [])
      const fuse = new Fuse(flat, {
        keys: ['title', 'desc', 'to']
      })
      return fuse.search(this.searchPhrase).map((fu) => fu.item)
    },
    totalLinks() {
      return this.services.reduce((acc, s) => acc + s.links.length, 0)
    },
    isAdmin() {
      const session = this.$store.state.session
      return session.user && session.user.user_is_admin === 1
    },
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    },
    services() {
      return [
        ...(this.$store.state.session.company &&
        (this.$store.state.session.company.country_id === 2 ||
          this.$store.state.session.company.country_id === 1)
          ? [
              {
                title: 'New',
                links: [
                  {
                    title: 'AutoCost',
                    to: 'autocost',
                    svg: 'autocostDark',
                    desc: 'Automate pricing for 130,000+ construction materials.',
                    highlight: true
                  }
                ]
              }
            ]
          : []),
        {
          title: 'Dashboards',
          links: [
            {
              title: 'Pipeline',
              icon: 'columns-3',
              to: 'pipeline',
              desc: 'Overview of active leads, clients, quotes, projects and invoices.'
            },
            {
              title: 'Projects',
              icon: 'house-building',
              to: 'projects',
              desc: 'See all booked or in-progress projects & manage project tasks.'
            },
            ...(this.$store.state.session.company.aoModules.simple_scheduler &&
            this.$store.state.session.company.aoModules.simple_scheduler === '1'
              ? [
                  {
                    title: 'Schedule',
                    icon: 'chart-gantt',
                    to: 'schedule',
                    desc: 'View the schedule for your projects.',
                    highlight: true
                  }
                ]
              : [])
          ]
        },
        {
          title: 'Prospecting',
          links: [
            {
              title: 'Leads',
              to: 'leads',
              icon: 'person-circle-plus',
              desc: `Track, manage & convert
                  leads from the web, phone & voicemail. Manage
                  lead rotations & sales reps. Generate lead
                  forms to put on your website.`
            },
            {
              title: 'Proposals',
              to: 'quotes',
              icon: 'file',
              desc: `Build accurate quotes/estimates with
                  the ease of drag & drop. Submit to general
                  contractors, send by email or get them signed
                  on the spot. Also find completed, closed, declined or rejected
                  projects here.`
            },
            {
              title: 'Lead Sources',
              to: 'lead_sources',
              icon: 'flag',
              desc: `Create and manage lead sources so you can track
                    leads, bookings and profit that come from various marketing campaigns,
                    website lead forms, landing pages and social media etc.`
            },
            {
              title: 'Lead Forms',
              to: 'lead_rotations',
              icon: 'file-pen',
              desc: `Create a lead form to qualify your leads and have them saved directly in Bolster.
                You can put the form on your website, have it as a public page on Bolster, or both.`,
              highlight: true
            },
            {
              title: 'Client import tool',
              to: 'import/clients',
              icon: 'cloud-arrow-down',
              desc: `Upload or import your client or lead list from
                  excel with this tool.`
            }
          ]
        },
        {
          title: 'Clients',
          links: [
            {
              title: 'Clients',
              to: 'clients',
              icon: 'user',
              desc: 'All current & former clients.'
            }
          ]
        },
        {
          title: 'Financial Services',
          links: [
            ...(this.isAdmin || this.isSuperUser
              ? [
                  {
                    title: 'Payments',
                    to: 'payments',
                    icon: 'dollar-sign',
                    desc: 'Setup your account and receive payments directly from your customers. Receive your money quickly, reliably and securely..'
                  }
                ]
              : []),
            {
              title: 'Bolster Capital',
              to: 'capital',
              icon: 'money-bills',
              desc: 'Multiple financing solutions to help you grow your business.'
            },
            ...(this.$store.state.session.company.aoModules.insurance &&
            this.$store.state.session.company.aoModules.insurance === '1'
              ? [
                  {
                    title: 'Insurance',
                    icon: 'shield-check',
                    to: this.getAssurelyUrl(),
                    desc: 'Expect better coverage, better prices, fast service and all the contractor-specific insurance needed to run your business.'
                  }
                ]
              : [])
          ]
        },
        {
          title: 'Legal',
          links: [
            {
              title: 'Legal agreements',
              to: '/pub/legal',
              icon: 'handshake',
              desc: 'Terms and conditions, anti-money laundering and privacy policies.'
            }
          ]
        },
        {
          title: 'Project management',
          links: [
            {
              title: 'Pipeline',
              icon: 'columns-3',
              to: 'pipeline',
              desc: 'Overview of active leads, clients, quotes, projects and invoices.'
            },
            ...(this.$store.state.session.company.aoModules.simple_scheduler &&
            this.$store.state.session.company.aoModules.simple_scheduler === '1'
              ? [
                  {
                    title: 'Schedule',
                    icon: 'chart-gantt',
                    to: 'schedule',
                    desc: 'View the schedule for your projects.',
                    highlight: true
                  }
                ]
              : []),
            {
              title: 'Vendors',
              to: 'vendors',
              icon: 'shop',
              desc: 'Create & manage vendors, subcontractors, sub trades, suppliers and wholesalers'
            },
            {
              title: 'Vendor import tool',
              to: 'vendor_import',
              icon: 'cloud-arrow-down',
              desc: 'Upload or import your vendor list from excel with this tool.'
            },
            {
              title: 'Projects',
              to: 'projects',
              icon: 'house-building',
              desc: 'All booked, in-progress & completed jobs.'
            },
            {
              title: 'Invoices',
              to: 'invoices',
              icon: 'file-invoice-dollar',
              desc: 'Create, send & track invoices.'
            },
            ...(this.$store.state?.session?.company?.aoModules?.company_cam === '1'
              ? [
                  {
                    title: 'CompanyCam',
                    to: 'CompanyCam',
                    icon: 'camera',
                    desc: 'Integrate Bolster with your CompanyCam account to automatically create projects from quotes, import your existing projects, and access CompanyCam images direct from your Bolster quotes.',
                    highlight: true
                  }
                ]
              : [])
          ]
        },
        {
          title: 'Estimating',
          links: [
            {
              title: 'Item & assembly catalog',
              to: 'items',
              icon: ['fas', 'cube'],
              desc: `Manage an existing catalog of
                  20,000+ construction items, import
                  your own or add them one-by-one.
                  Build re-usable assemblies to save time when quoting.`
            },
            {
              title: 'Item import tool',
              to: 'import_tool',
              icon: 'cloud-arrow-down',
              desc: `Upload or import your items list from
                  excel with this tool.`
            },
            {
              title: 'Custom dimensions',
              to: 'dimensions',
              icon: 'drafting-compass',
              desc: `Create, update and delete custom dimension types
                  that you create to link quanitites to formulas using custom dimensions
                  particular in your industry or company.`
            },
            {
              title: 'Custom construction stages',
              to: 'stages',
              icon: 'clipboard-list',
              desc: `Create, update and delete custom construction stages
                  so you can categorize your items, and plan for future schedule placement.`
            },
            {
              title: 'Labor rates',
              to: 'labor',
              icon: 'person-carry-box',
              desc: `Once you've assigned a labor rate
                  type to your items you can change their
                  average costs here. This is for when you
                  have workers and you know their aggregated
                  average labor rates. If you're not sure,
                  you can use our built-in rates.`
            },
            {
              title: 'Estimating & presentation settings',
              to: 'estimating_defaults',
              icon: 'cog',
              desc: `Set default profit, company-wide terms of service, proposal settings,
                  and any other costing/pricing settings related to estimating.`
            },
            {
              title: 'Tax rates management',
              to: 'taxes',
              icon: 'building-columns',
              desc: `Manage your custom sales tax settings, add complex taxes by
                  materials, labor, state/prov etc.`
            },
            ...(this.$store.state.session.company &&
            this.$store.state.session.company.country_id === 2
              ? [
                  {
                    title: 'AutoCost',
                    to: 'autocost',
                    icon: 'layer-plus',
                    desc: 'Automate pricing for 130,000+ construction materials.',
                    highlight: true
                  }
                ]
              : [])
          ]
        },
        {
          title: 'Files',
          links: [
            {
              title: 'Files',
              to: 'files',
              icon: 'folder-open',
              desc: 'Complete drag & drop file storage & management, in the cloud.'
            }
            // {
            //   title: 'Templates & presentations',
            //   to: 'templates',
            //   icon: 'file-code',
            //   desc: `Create & manage proposal presentations,
            //       email templates and
            //       document templates for quotes, invoices, receipts & more.`
            // }
          ]
        },
        {
          title: 'Reporting',
          links: [
            {
              title: 'Reports',
              to: 'reports',
              icon: 'table',
              desc: 'Visualize your proposals, clients and other data to track progress and measure success.'
            }
          ]
        },
        {
          title: 'Team management',
          links: [
            {
              title: 'Users',
              to: 'users',
              icon: 'users-gear',
              desc: 'Manage users, reset passwords, set roles & permissions.'
            },
            {
              title: 'Groups',
              to: 'groups',
              icon: 'users',
              desc: 'Create groups for your users for different teams or lines of business. You can filter by groups in the pipeline, clients, proposals and reports.'
            },
            {
              title: 'Roles',
              to: 'roles',
              icon: 'user-lock',
              desc: "Create, edit and manage user roles with sets of permissions assigned according to a user's position in your company."
            }
          ]
        },
        {
          title: 'My settings',
          links: [
            {
              title: 'Profile',
              to: 'profile',
              icon: 'id-card',
              desc: 'Manage your profile, personal information, picture & change your password.'
            },
            ...(this.$store.state.session.company.aoModules.subscription_management &&
            this.$store.state.session.company.aoModules.subscription_management === '1'
              ? [
                  {
                    title: 'Subscription management',
                    to: 'subscription',
                    icon: 'credit-card',
                    desc: 'Upgrade your subscription package. Manage your payment methods and view subscription invoice payments.',
                    highlight: true
                  }
                ]
              : []),
            {
              title: 'Notifications',
              to: 'notifications',
              icon: 'bullhorn',
              desc: 'See your notifications and activity.'
            },
            {
              title: 'Home',
              to: 'home',
              icon: 'home',
              desc: 'See a list of all your companies, and projects as client view.'
            },
            ...(this.$store.state.session.authorizedUser &&
            this.$store.state.session.authorizedUser.user_is_super_user
              ? [
                  {
                    title: 'Super user',
                    to: 'super',
                    icon: 'super',
                    desc: 'See the super user dashboard.'
                  }
                ]
              : []),

            {
              title: 'Logout',
              to: '/pub/logout',
              icon: ['fas', 'right-from-bracket'],
              desc: 'Sign out of Bolster.'
            }
          ]
        },
        {
          title: 'Help',
          links: [
            {
              title: 'Announcements',
              to: 'announcements',
              icon: 'bullhorn',
              desc: 'See the latest annoucements about CostCertifed!',
              highlight: this.$store.state.session.user.user_has_announcement
            },
            {
              title: 'Support',
              to: 'support',
              icon: 'life-ring',
              desc: `Technical support like: report a bug, suggest a feature, request help or ask a question.
                       Also hire estimators, get billing help, guided help and access a free course on how to use
                       Bolster!`
            }
          ]
        },
        ...(!this.$store.state.session.user.user_is_admin
          ? []
          : [
              {
                title: 'Admin',
                links: [
                  {
                    title: 'Company settings',
                    to: 'company',
                    icon: 'cog',
                    desc: 'Manage company details & information.'
                  },
                  {
                    title: 'Webhooks',
                    to: 'webhooks',
                    icon: 'compress',
                    desc: `Build an event-based connection from Bolster to
                  a webhook aggregator like Zapier or Integromat, or directly to any
                  web-based endpoint for leads, clients, quotes, change-orders and invoices.`
                  },
                  ...(this.$store.state.session.company.aoModules.quickbooks_export &&
                  this.$store.state.session.company.aoModules.quickbooks_export === '1'
                    ? [
                        {
                          title: 'Accounting export settings',
                          to: 'accounting',
                          icon: 'money-bills',
                          desc: 'Manage accounting settings.'
                        }
                      ]
                    : [])
                ]
              }
            ]),
        this.$store.state.help.hideHelp
          ? {
              title: '',
              links: []
            }
          : {
              title: 'Support',
              links: [
                {
                  title: 'Help resources',
                  to: 'support',
                  icon: 'question-circle',
                  desc: 'Video tutorials, and support tickets.'
                }
              ]
            }
      ]
    }
  },
  methods: {
    async getAssurelyUrl() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: `company/getAssurelyUrl/${this.$store.state.session.company.company_id}`
      })

      return payload.url
    },
    close() {
      return this.$store.dispatch('search/close')
    },
    getRouteForString(string) {
      return this.$router.routes.filter((route) => route.name === string)
    },
    async onSearchPhraseChange(phrase) {
      await this.$nextTick()
      await c.throttle(() => {}, { delay: 100 })
      this.searchPhrase = phrase
    },
    searchFilter(filterable) {
      return filterable.filter((needle) => {
        return (
          !this.searchPhrase ||
          this.searchPhrase === '' ||
          JSON.stringify(needle).toLowerCase().includes(this.searchPhrase.toLowerCase())
        )
      })
    },
    visibleSections(column) {
      return column.filter((service) => {
        return (
          !this.searchPhrase ||
          this.searchPhrase === '' ||
          JSON.stringify(service.links).toLowerCase().includes(this.searchPhrase.toLowerCase())
        )
      })
    }
  },
  components: { ServiceItem, Search },
  props: {},
  mounted() {
    this.$nextTick(() => this.$refs.searchField.setFocus())
    eventBus.$on('searchPhrase', this.onSearchPhraseChange)
  },
  beforeUnmount() {
    eventBus.$off('searchPhrase', this.onSearchPhraseChange)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.search-field {
  font-size: 1em;
  > input[type='text'] {
    padding: 1.4em !important;
  }
}
.cc-list-group li {
  border-left: solid 4px $cool-gray-400;
  &.highlight {
    border-left-color: $primary;
  }
}
</style>
