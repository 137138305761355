<template>
  <card-section v-if="quote_id && quote && (invoice_id || quote.quote_uninvoiced_gross > 0)">
    <warning v-if="invoice_id && !hideWarning" class="text-black">
      <span class="ml-4">The project and the amount can not be edited on an invoice.</span>
    </warning>

    <card-list>
      <card-list-field>
        <span>
          {{ $t('Amount') }}
          <small class="text-info">{{ $t('Including any sales or value-added taxes') }}</small>
        </span>

        <field
          :disabled="invoice_id || invoiceSettings.enableItemizedInvoicing"
          class="number form-control"
          schema="invoice:invoice_gross"
          v-model="invoice_gross"
        ></field>
      </card-list-field>

      <card-list-field>
        <span>
          {{ $t('Amount') }} (% {{ $t('of project total') }})
          <br />
          <small v-if="!hidePercentageOfAmount">
            {{ $t('Select an amount based on the percentage of the total job value.') }}
          </small>
        </span>

        <field
          :disabled="invoice_id || invoiceSettings.enableItemizedInvoicing"
          class="number form-control"
          format="percentWhole"
          v-model="percentOfJob"
        ></field>
      </card-list-field>

      <div class="px-6 mb-6 mt-6">
        <MeterGroup :value="computedSegmentArray" />
      </div>

      <card-list-field v-if="!invoice_id">
        <span>
          {{ $t('Amount (% of balance)') }}
          <br />
          <small class="text-muted" v-if="!hidePercentageOfAmount">
            {{ $t('Select a percentage of the remaining uninvoiced amount of the project.') }}
          </small>
        </span>

        <field
          :disabled="invoice_id || invoiceSettings.enableItemizedInvoicing"
          class="number form-control"
          format="percentWhole"
          v-model="computedInvoicePercentage"
        ></field>
      </card-list-field>
    </card-list>
  </card-section>
</template>

<script>
import ObjectDistinct from '../../mixins/ObjectDistinct'
import TranslationMixin from './languages/TranslationMixin'
import MeterGroup from 'primevue/metergroup'

export default {
  name: 'InvoicePresentationAmounts',
  components: {
    MeterGroup
  },
  props: {
    hideWarning: {
      type: Boolean,
      default: false
    },
    hidePercentageOfAmount: {
      type: Boolean,
      default: false
    },
    invoiceSettings: {
      type: Object,
      required: true
    },
    reference: {
      type: String
    }
  },
  mixins: [ObjectDistinct('invoice', true), TranslationMixin],
  data() {},
  computed: {
    currentInvoiceTotal() {
      return 100 / c.toNum(this.invoice_gross, 2)
    },
    percentOfJob: {
      set(value) {
        const val = c.toNum(value) / 100
        this.invoice_gross = Math.min(
          val * this.quote.quote_price_gross,
          this.quote.quote_uninvoiced_gross
        )
      },
      get() {
        return (c.toNum(this.invoice_gross) / c.toNum(this.quote.quote_price_gross)) * 100
      }
    },
    computedInvoicePercentage: {
      set(value) {
        const dec = c.toNum(value) / 100
        const val = Math.min(
          c.toNum(dec) * this.quote.quote_uninvoiced_gross,
          this.quote.quote_uninvoiced_gross
        )
        this.invoice_gross = val
      },
      get() {
        return (c.toNum(this.invoice_gross) / c.toNum(this.quote.quote_uninvoiced_gross)) * 100
      }
    },
    getStatusCategory() {
      if (this.invoice_id && this.invoice_status === 'o') return 'unpaid'
      if (this.invoice_id && this.invoice_status === 'e') return 'paid'
      if (this.invoice_id && (this.invoice_status === 'p' || this.invoice_status === '$'))
        return 'pending'
      return 'uninvoiced'
    },
    computedSegmentArray() {
      if (this.quote) {
        const status = this.getStatusCategory

        // paid amount
        const paidTotal = c.toNum(this.quote.quote_paid_gross, 2)

        // unpaid amount
        let unpaidTotal = c.toNum(this.quote.quote_unpaid_gross, 2)
        if (status === 'unpaid') unpaidTotal -= c.toNum(this.invoice_gross, 2)

        // current invoice total
        const showCurrentInvoice = status !== 'paid' && status !== 'pending'
        const currentInvoiceTotal = showCurrentInvoice ? c.toNum(this.invoice_gross, 2) : 0

        // pending total
        const pendingTotal = this.quote.quote_pending_gross

        // uninvoiced total
        const showUninvoiced = status === 'uninvoiced'
        let uninvoicedTotal = c.toNum(this.quote.quote_uninvoiced_gross, 2)
        if (showUninvoiced) uninvoicedTotal -= c.toNum(this.invoice_gross, 2)

        const quoteTotal = this.quote.quote_total_cost_net

        // segments
        const segment = [
          {
            label: this.l('Paid'),
            value: (paidTotal / quoteTotal) * 100,
            color: '#34d399'
          },
          {
            name: status === 'unpaid' ? this.l('Other Unpaid') : this.l('Unpaid'),
            value: unpaidTotal,
            color: '#fbbf24'
          },
          {
            label: this.l('Current'),
            value: (currentInvoiceTotal / quoteTotal) * 100,
            color: '#60a5fa'
          },
          {
            label: this.l('Pending'),
            value: (pendingTotal / quoteTotal) * 100,
            color: '#c084fc'
          },
          {
            label: status === 'uninvoiced' ? this.l('Remainder') : this.l('Uninvoiced'),
            value: (uninvoicedTotal / quoteTotal) * 100,
            color: 'warning'
          }
        ]
        return segment
      }
      return []
    },

    computedInvoiceGross: {
      set(value) {
        this.invoice_gross = Math.min(c.toNum(value), c.toNum(this.quote.quote_uninvoiced_gross))
      },
      get() {
        return this.invoice_gross
      }
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss" rel="stylesheet/scss"></style>
