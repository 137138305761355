<template>
  <div class="activity-channel-details">
    <Sidebar v-model:visible="show" position="right">
      <template #header>
        <p class="text-xl">{{ channelName }}</p>
        <Spinner :loading="projectLoading" />
      </template>
      <div class="channel-client-info channel-details-card">
        <p class="mb-2">Client info</p>
        <div class="client mb-2">
          <PersonAvatar
            :id="projectObject?.oClient?.client_id"
            :name="projectObject?.oClient?.client_name"
            type="client"
            size="large"
          />
        </div>
        <small class="block">Name</small>
        <small class="block font-semibold mb-2">
          {{ projectObject?.oClient?.client_name || 'N/A' }}
        </small>
        <div class="grid grid-cols-2 mb-2">
          <div>
            <small class="block">Email</small>
            <small class="block font-semibold">
              {{ projectObject?.oClient?.user_email || 'N/A' }}
            </small>
          </div>
          <div>
            <small class="block">Phone</small>
            <small class="block font-semibold">
              {{ projectObject?.oClient?.user_phone || 'N/A' }}
            </small>
          </div>
        </div>
      </div>
      <div class="channel-crew-info channel-details-card">
        <p class="mb-2">Crew info</p>
        <div class="crew flex w-full mb-2">
          <PersonAvatar
            :id="projectObject?.oOwner?.user_id"
            :name="`${projectObject?.oOwner?.user_fname} ${projectObject?.oOwner?.user_lname}`"
            size="large"
          />
        </div>
      </div>
      <div class="channel-project-info channel-details-card">
        <p class="mb-2">Project info</p>
        <small class="block">Project name</small>
        <small class="block font-semibold mb-2">
          {{ projectObject?.quote_name || 'N/A' }}
        </small>
        <small class="block">Address</small>
        <small class="block font-semibold mb-2">
          {{ projectObject?.quote_address || 'N/A' }}
        </small>
      </div>
      <div class="channel-payment-info channel-details-card">
        <p class="mb-2">Payment status</p>
        <small class="block">Total paid</small>
        <small class="block font-semibold mb-2">
          {{ projectObject?.quote_paid_net >= 0 ? projectObject?.quote_paid_net : 'N/A' }}
        </small>
        <div class="grid grid-cols-2 mb-2">
          <div>
            <small class="block">Total invoiced</small>
            <small class="block font-semibold">
              {{
                projectObject?.quote_invoiced_net >= 0 ? projectObject?.quote_invoiced_net : 'N/A'
              }}
            </small>
          </div>
          <div>
            <small class="block">Total un-invoiced</small>
            <small class="block font-semibold">
              {{
                projectObject?.quote_uninvoiced_net >= 0
                  ? projectObject?.quote_uninvoiced_net
                  : 'N/A'
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="channel-schedule-info channel-details-card">
        <p class="mb-2">Schedule</p>
        <div class="grid grid-cols-2 mb-2">
          <div>
            <small class="block">Start</small>
            <small class="block font-semibold">
              {{
                projectObject?.quote_time_project_start
                  ? new Date(projectObject.quote_time_project_start).toLocaleDateString()
                  : 'N/A'
              }}
            </small>
          </div>
          <div>
            <small class="block">End</small>
            <small class="block font-semibold">
              {{
                projectObject?.quote_time_project_end
                  ? new Date(projectObject.quote_time_project_end).toLocaleDateString()
                  : 'N/A'
              }}
            </small>
          </div>
        </div>
      </div>
      <div class="channel-file-info channel-details-card">
        <p class="mb-2">Files</p>
        <FileList
          :idList="true"
          :allowEdit="false"
          :allowCreate="false"
          :allowRemove="false"
          :allowDragging="false"
          :value="projectObject?.file_ids || []"
        />
      </div>
      <div class="channel-budget-info channel-details-card">
        <p class="mb-2">Budget</p>
        <CostChart
          style="max-width: 100%; max-height: 100%; margin-bottom: 1rem"
          textColor="#222222"
          :discount="quoteTotalDiscountNet"
          :labor="quoteLaborPriceNet"
          :materials="quoteMaterialPriceNet"
          :rate="quoteTotalPriceNet"
          :qty="1"
        />
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue'
import Sidebar from 'primevue/sidebar'
import FileList from '@/components/ui/FileList.vue'
import useActivityChannel from '@/components/composables/ActivityChannel'
import CostChart from '@/components/quote/CostChart.vue'

// ======== Props ======== //
const props = defineProps({
  channelType: {
    type: String,
    required: true
  },
  channelTypeId: {
    type: String,
    required: true
  }
})
// ====================== //

// ======== Composables ======== //
const { channelId, channelName, projectLoading, projectObject, fetchProjectDetails } =
  useActivityChannel(props)
// ====================== //

// ======== Data ======== //
const show = ref(false)
// ====================== //

// ======== Computed ======== //
const quoteMaterialPriceNet = computed(() => {
  let materialNet = 0

  if (projectObject.value?.aoChildren) {
    projectObject.value.aoChildren.forEach((item) => {
      materialNet +=
        item[`${item.type === 'cost_item' ? 'cost_item' : 'quote'}_materials_cost_net`] || 0
    })
  }

  return materialNet
})
const quoteLaborPriceNet = computed(() => {
  let laborNet = 0

  if (projectObject.value?.aoChildren) {
    projectObject.value.aoChildren.forEach((item) => {
      laborNet += item[`${item.type === 'cost_item' ? 'cost_item' : 'quote'}_labor_cost_net`] || 0
    })
  }

  return laborNet
})
const quoteTotalDiscountNet = computed(() => {
  return projectObject.value?.quote_discount_net || 0
})
const quoteTotalPriceNet = computed(() => {
  return projectObject.value?.quote_price_net || 0
})
// ====================== //

// ======== Methods ======== //
const toggleShowSidebar = () => {
  show.value = !show.value
}
// ====================== //

// ======== Watchers ======== //
watch(channelId, fetchProjectDetails)
// ====================== //

// ======== Lifecycle ======== //
onMounted(() => {
  fetchProjectDetails()
})
// ====================== //

// ======== Expose ======== //
defineExpose({
  toggleShowSidebar
})
// ====================== //
</script>

<style lang="scss" scoped>
.channel-details-card {
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: $border-radius;
  border: 2px solid $surface-200;
}
</style>
