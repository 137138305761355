import BtnMixin from '../../mixins/Button'
import eventBus from '../../../eventBus'
import { mapState } from 'pinia'
import { useDeviceStore } from '@/stores/device'

const getRowFromEvent = (event) => {
  const $target = $(event.target)
  const indexedElement = $target.is('[data-index]') ? $target : $target.closest('[data-index]')
  return indexedElement.length ? indexedElement[0] : null
} // stuff

/**
 * Slots:
 *  -filterPresetBefore
 *  -filterPresetAfter
 *  -after
 *  -before
 *  -default
 *
 *
 *  Emits:
 *    -loaded (once, after first load)
 *    -searchPhrase (when searchPhrase changes, with value of searchPhrase)
 *    -selected (with array of objects selected)
 *    -endOfSet (when all possible values have been loaded)
 *    -fetching (before going to search/fetch server)
 *    -fetched (after each fetch, with array set fetched)
 *    -filtersChanged (with new filters)
 */
export default {
  name: 'GridMixin',
  outWith: {},
  gotWith: {},

  mixins: [BtnMixin],

  props: {
    saveStateEnabled: {
      default: true
    },

    createNew: {
      default: true
    },

    ignoreFiltersOnKeywordSearch: {
      default: false
    },

    /**
     * @var string search|filter|variable
     * // variable - search with searchPhrase and filter without
     */
    searchMethod: {
      default: 'search'
    },

    showActions: {
      type: Boolean,
      default: true
    },
    /**
     *
     */
    showDefaultFilterPresets: {
      type: Boolean,
      default: false
    },

    /**
     * [{
     *  title,
     *  description,
     *  filters: {},
     * }]
     */
    filterPresets: {
      type: Array,
      default: () => []
    },

    /**
     * The entity type for which this grid displays data
     */
    type: {
      required: false
    },

    /**
     * Whether the entity fields use camel-case or not
     */
    camelCase: {
      required: false,
      default: false
    },

    /**
     * Whether this table is the main table for the page. If it is
     * it will save state data to address bar for example.
     */
    isMain: {
      default: true
    },

    /**
     * Height of rows
     */
    itemSize: {
      default: 63
    },

    /**
     * A list of column names that are visible
     */
    visible: {
      type: Array,
      default: () => []
    },

    /**
     * The starting order of objects, can be changed by user
     */
    order: {
      type: Array,
      default: () => [['company_id', 'desc']]
    },

    /**
     * Filters object to filter objects. Can be changed by user. To make
     * permanent filters use permanentFilters prop.
     */
    filters: {
      type: Object,
      default: () => ({})
    },

    scope: {
      type: Object,
      default: () => ({})
    },

    filterText: {
      type: Object,
      default: () => ({})
    },

    /**
     * Filters that cannot be changed by the user.
     */
    permanentFilters: {
      type: Object,
      default: () => ({})
    },

    /**
     * Force a searchPhrase
     */
    searchPhrase: {
      default: null
    },

    /**
     * Limit for EACH fetch
     */
    limit: {
      type: Number,
      default: 50
    },

    /**
     * Starting offset
     */
    offset: {
      type: Number,
      default: 0
    },

    showToolbar: {
      type: Boolean,
      default: true
    },

    showFilters: {
      type: Boolean,
      default: false
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    multiple: {
      type: Boolean,
      default: false
    },

    doubleClickToOpen: {
      type: Boolean,
      default: true
    },

    star: {
      type: Boolean,
      default: true
    },

    onCreate: {
      type: Function
    }
  },
  emits: [
    'loading',
    'set',
    'selected',
    'endOfSet',
    'searchPhrase',
    'loaded',
    'filterChange',
    'initialized',
    'fetching',
    'fetched',
    'reloaded',
    'rowClick',
    'dblclick',
    'selectedId',
    'selectedIndex',
    'singleClick',
    'closeAllDrops',
    'deselectedId',
    'deselectedIndex'
  ],

  created() {
    this.buildGlobalHandlers()
  },

  mounted() {
    this.initialize()
  },

  beforeUnmount() {
    this.destroyGlobalHandlers()
  },

  watch: {
    loading(l) {
      this.$emit('loading', l)
    },
    set(set) {
      this.$emit('set', set)
    },
    routeQuery() {
      this.loadQueryState()
    },
    filters(a, b) {
      if (_.jsonEquals(a, b)) return
      this.filtersLocal = a
    },
    scope(scope) {
      this.scopeLocal = scope
    },
    limit(limit) {
      this.limitLocal = limit
    },
    offset(offset) {
      this.offsetLocal = offset
    },
    searchPhrase(searchPhrase) {
      this.searchPhraseLocal = searchPhrase
    },

    selectedObjects(current) {
      this.$emit('selected', current)
      eventBus.$emit('gridSelected', {
        [this.typeField]: current
      })
    },
    endOfSet(end) {
      if (end) {
        this.$emit('endOfSet')
      }
    },
    orderAscOrDesc(a, b) {
      if (_.jsonEquals(a, b)) return
      this.throttleReload(this.fetching || this.waitingForReload ? 300 : 0, true, true)
    },
    searchPhraseLocal(a, b) {
      if (a === b || (!a && !b)) return
      this.$emit('searchPhrase', a)
      this.throttleReload(500, false, true)
    },
    async filtersLocal(a, b) {
      if (_.jsonEquals(a, b)) return
      await this.throttleReload(this.fetching || this.waitingForReload ? 300 : 0, true, true)
    },
    async scopeLocal(a, b) {
      if (_.jsonEquals(a, b)) return
      await this.throttleReload(this.fetching || this.waitingForReload ? 300 : 0, true, true)
    },
    permanentFilters(a, b) {
      if (_.jsonEquals(a, b)) return
      this.throttleReload(this.fetching || this.waitingForReload ? 300 : 0, true, true)
    },
    hasFetched() {
      this.$emit('loaded', this.set)
    }
  },

  computed: {
    /**
     *
     */
    routeQuery() {
      return this.$route.query
    },

    /**
     * Styling for preset scroll container
     * @returns string
     */
    presetStyling() {
      return 'max-width: 100%; left: 0;'
    },

    /**
     * To prevent wobble in small format/mobile, add max-width: 100%
     * @returns string
     */
    widthStyling() {
      if (this.smallFormat) {
        return 'width: 100%; max-width: 100%;'
      }

      return `width: ${this.rowWidth}; max-width: unset; min-width: 100%;`
    },

    /**
     * Calculate the total cell widths
     * so we can determine exact width of the table
     * @returns string
     */
    rowWidth() {
      const widths = this.visibleColumns.map((col) => col.width).join(' + ')
      return `CALC(${widths})`
    },

    presetIndex() {
      const found = this.filterPresetsLocal.find((preset) =>
        _.jsonEquals(preset.filters, this.filtersLocal)
      )
      const index = this.filterPresetsLocal.indexOf(found)
      return index === -1 ? null : index
    },

    canCreate() {
      return Object.keys(c.creatableObjects).includes(this.typeField)
    },

    highlighted() {
      let newSet = []
      this.set.forEach((o, i) => {
        if (this.highlightedIds.indexOf(o[this.idField]) > -1) {
          newSet = [...newSet, i]
        }
      })
      return newSet
    },
    selected: {
      get() {
        let newSet = []
        this.set.forEach((o, i) => {
          if (this.selectedIds.indexOf(o[this.idField]) > -1) {
            newSet = [...newSet, i]
          }
        })
        return newSet
      },
      set(indexes) {
        let newSelectedIds = []
        c.makeArray(indexes).forEach((i) => {
          newSelectedIds = [...newSelectedIds, this.set[i][this.idField]]
        })
        this.selectedIds = newSelectedIds
      }
    },

    columnsChooseListColumn() {
      return this.availableColumns.map((c) => ({
        value: c.column, // <<
        text: c.name
      }))
    },
    columnsChooseListSortBy() {
      return this.availableColumns.map((c) => ({
        value: c.sortBy, // <<
        text: c.name
      }))
    },
    orderSetting: {
      get() {
        if (Array.isArray(this.orderLocal) && this.orderLocal.length && this.orderLocal[0].length) {
          return this.orderLocal[0][0]
        }
        return null
      },
      set(o) {
        this.orderLocal = [[o, this.orderAscOrDesc]]
      }
    },
    filtersAtDefault() {
      return c.jsonEquals(this.filters, this.filtersLocal)
    },
    isAtDefaultSetting() {
      return this.filtersAtDefault && c.jsonEquals(this.order, this.orderLocal)
    },
    suggestedFilterColumns() {
      if (!this.columns || !this.columns.length) return []
      const permFilterFields = Object.keys(this.permanentFilters)
      return this.availableColumns.filter(
        (col) => col.suggestedFilter && !permFilterFields.includes(col.sortBy)
      )
    },
    adhocFilterColumns() {
      const existingFiltersVisible = [
        ...Object.keys(this.permanentFilters),
        ...this.suggestedFilterColumns.map((col) => col.sortBy)
      ]
      const constructor = this.getConstructor()
      if (!this.filtersLocal) return []
      return Object.keys(this.filtersLocal)
        .filter((field) => !existingFiltersVisible.includes(field))
        .map((field) => c.getTableColumn(field, constructor))
    },
    columnsPreset() {
      return this.availableColumns.map((col, index) => ({
        value: index,
        text: col.name
      }))
    },
    numberOfFilters() {
      if (!this.filtersLocal) return 0
      return _.difference(Object.keys(this.filtersLocal), Object.keys(this.permanentFilters)).length
    },
    notifyFilters() {
      return !!this.numberOfFilters
    },
    $this() {
      return this
    },
    typeField() {
      // TODO: make generic
      if (this.type === 'clientRating' || this.type === 'contractorRating') {
        return `rating`
      }
      return this.type
    },
    idField() {
      return this.camelCase ? `${this.typeField}Id` : `${this.typeField}_id`
    },
    nameField() {
      return this.camelCase ? `${this.typeField}Name` : `${this.typeField}_name`
    },
    statusField() {
      return this.camelCase ? `${this.typeField}Status` : `${this.typeField}_status`
    },
    previewField() {
      return this.camelCase ? `${this.typeField}Preview` : `${this.typeField}_preview`
    },
    smallFormat() {
      return this.$store.getters.smallFormat
    },
    showInlineAction() {
      return this.smallFormat
    },
    inModal() {
      const closestModal = (parent) => {
        if (parent && parent.$options._componentTag === 'modal') {
          return true
        } else if (parent) {
          return closestModal(parent.$parent)
        }
        return false
      }
      return closestModal(this.$parent)
    },

    selectedObjects() {
      return this.selected.map((index) => this.set[index])
    },

    /**
     * All available columns
     *
     * Array of objects
     * ie: [{
     *    // Individual column id
     *  column: 'quote_preview',
     *    //Column to sort by, server side
     *  sortBy: 'quote_id',
     *    // create the visual representation of the column, defaults as seen
     *  format: (obj) => obj.quote_id,
     *    // number|currency|string|time|datetime etc, see c.format function
     *  formatType: 'number',
     *    // column title
     *  name: 'Quote id',
     *     // whether column appears in table or not, toggle this
     *  visible: true,
     * }
     */
    availableColumns() {
      return [...c.getTableColumns(this.type, this.permanentFilters)]
    },

    /**
     * All columns, with the visible: key point modified
     * to true or false depending on individual column visibility
     */
    columns() {
      const visible = this.visibleLocal
      return this.availableColumns.map((columnSchema) => ({
        ...columnSchema,
        visible: !!(visible.indexOf(columnSchema.column) > -1)
      }))
    },

    /**
     * Column schemas that are visible
     */
    visibleColumns() {
      let vis
      if (this.smallFormat) {
        vis = this.columns.filter((column) => column.column === this.previewField)
      }

      if (!this.smallFormat || !vis.length) {
        return this.columns.filter((column) => this.visibleLocal.includes(column.column))
      }

      return vis
    },
    ...mapState(useDeviceStore, ['isTouch'])
  },

  data() {
    return {
      reloadCount: 0,
      scopeLocal:
        this.scope && Object.keys(this.scope).length ? this.scope : this.$store.state.session.scope,
      visibleLocal: this.visible || [this.nameField, this.statusField],
      filtersLocal: this.filters,
      filterTextLocal: this.filterText,
      orderLocal: this.order,
      limitLocal: this.limit,
      offsetLocal: this.offset,
      nextToken: null,
      searchPhraseLocal: this.searchPhrase,
      filterPresetsLocal: [
        ...this.filterPresets,
        ...(this.showDefaultFilterPresets ? this.getGeneratedPresets() : [])
      ],

      /**
       * Has a fetch ever occurred yet?
       */
      hasFetched: false,

      /**
       * Have we reached the end of all the data?
       */
      endOfSet: false,

      /**
       * Set of actual data points
       */
      set: [],

      /**
       * When filters + searchPhrase point to nothing, this will have
       * results for JUST searchPhrase
       */
      altSet: [],

      /**
       * Whether a fetching is in process or not
       */
      fetching: 0,

      /**
       * All actions for this type of entity
       */
      typeActions: c.getActions(this.typeField),

      /**
       * Whether the order setting is asc or desc in
       * filters settings
       */
      orderAscOrDesc: 'desc',

      /**
       * Array of row indexes that are selected
       */
      selectedIds: [],
      highlightedIds: [],
      viewMode: 'list',
      keyLock: false,
      lastSelected: null,
      uid: _.uniqueId(),
      firstInDom: null,
      lastInDom: null
    }
  },

  methods: {
    setSearchPhrase(v) {
      this.searchPhraseLocal = v
    },

    prepend(object) {
      this.set = [object, ...this.set]
    },

    getConstructor() {
      return c.getConstructor(this.type)
    },

    getGeneratedPresets() {
      const constructor = this.getConstructor()
      return constructor.listPresets || []
    },

    setPreset(preset) {
      this.filtersLocal = _.imm(preset.filters)
      this.filterTextLocal = _.imm(preset.filterText || this.filtersLocal)
    },

    create() {
      if (this.onCreate) {
        return this.onCreate(this.typeField, this.permanentFilters)
      }
      return this.$store.dispatch('create', {
        type: this.typeField,
        embue: {
          ...this.permanentFilters
        }
      })
    },

    setVisibleColumns(visibleColumns) {
      this.visibleLocal = visibleColumns || []
      this.saveState()
    },
    scrollToActiveRow() {
      // If there are ny highlighted or selected rows, scroll to them
      const listToCheck = [...this.highlighted, ...this.selected]

      if (!listToCheck.length) {
        return
      }

      const index = listToCheck[0]
      const $row = $(this.$refs.eventWrapper).find(`[data-index=${index}]`)
      if ($row.length) $row[0].scrollIntoViewIfNeeded()
    },

    removeFilter(filteredColumnName) {
      const { [filteredColumnName]: rest } = this.filters
      this.$emit('filterChange', rest)
    },

    singleFilterTextChange(value, obj) {
      let addedFilter
      const keys = Object.keys(obj)
      const val = obj[keys[0]]

      if (!val || val === '') {
        addedFilter = {}
      } else {
        addedFilter = obj
      }

      // First pull out the filter found in the change
      //  So it doesn't get added on top
      const { [keys[0]]: rest } = this.filterTextLocal
      let newFilters = { ...rest }

      // Only if the filter key is found in the real filters
      //  should it be added.
      if (Object.keys(this.filtersLocal).indexOf(keys[0]) > -1) {
        newFilters = { ...newFilters, ...addedFilter }
      }

      this.filterTextLocal = newFilters
    },

    singleFilterChange(value, obj) {
      let addedFilter
      const keys = Object.keys(obj)
      const val = obj[keys[0]]

      if (!val || val === '') {
        addedFilter = {}
      } else {
        addedFilter = obj
      }

      // First pull out the filter found in the change
      const { [keys[0]]: rest } = _.imm(this.filtersLocal)
      const newFilters = { ...rest, ...addedFilter }

      this.filtersLocal = newFilters
    },

    async initialize() {
      this.addLoading()
      try {
        this.loadSavedState().loadQueryState().setDefaultColumnVisibility()
        this.$emit('initialized')
        await this.throttleReload(0, false, true)
      } finally {
        this.removeLoading()
      }
    },

    async fetch(force = false, start = false) {
      const data = this.getFetchData(start)
      const path = this.$route.path

      // Check everything except limit and offset
      const last = this.gotWith || {}
      const sameRequest =
        this.gotWith &&
        _.jsonEquals(data.filters || {}, last.filters || {}) &&
        _.jsonEquals(data.order || [], last.order || []) &&
        _.jsonEquals(data.scope || null, last.scope || null) &&
        ((data.searchPhrase || null) === (last.searchPhrase || null) ||
          (!data.searchPhrase && !last.searchPhrase))
      if (
        !force &&
        !start &&
        ((this.fetching && _.jsonEquals(data, this.outWith)) || _.jsonEquals(data, this.gotWith))
      ) {
        return this
      }

      this.altSet = []

      const reset = !sameRequest || force

      if (reset) {
        this.deselectAll()
        this.set = []
        this.gotWith = {}
        this.outWith = {}
      }

      this.addLoading()

      this.fetching = 1
      this.outWith = data
      this.$emit('fetching', 1)

      const storeName = _.titleCase(this.type)
      let set = []
      let nextToken = null
      let altSet = []
      if (!storeName) return this

      try {
        const sm =
          this.searchMethod === 'variable'
            ? (data.searchPhrase && 'search') || 'filter'
            : this.searchMethod
        const {
          set: searchedSet,
          score,
          nextToken: next = null
        } = await this.$store.dispatch(`${storeName}/${sm}`, {
          searchPhrase: data.searchPhrase,
          limit: data.limit,
          offset: data.searchPhrase ? 0 : data.offset,
          filters:
            data.searchPhrase && this.ignoreFiltersOnKeywordSearch
              ? this.permanentFilters
              : data.filters,
          order: data.order,
          quick: data.quick,
          minScore: data.searchPhrase ? 10 : 0,
          scope: data.scope,
          nextToken: data.nextToken
        })
        set = searchedSet || []
        nextToken = next

        // If not filtered, show alternate results
        if (
          data.searchPhrase &&
          ((Object.keys(data.filters).length && !searchedSet.length) || score < 20)
        ) {
          const { set: alt } = await this.$store.dispatch(`${storeName}/search`, {
            searchPhrase: data.searchPhrase,
            limit: 5,
            offset: 0,
            filters: {
              ...(set.length
                ? {
                    [this.idField]: set
                      .map((obj) => (obj[this.idField] ? `!${obj[this.idField]}` : ''))
                      .join('&&')
                  }
                : {})
            },
            order: data.order,
            quick: true,
            minScore: 10,
            scope: data.scope
          })
          altSet = alt
        }
      } catch (e) {
        this.$store.dispatch('alert', {
          error: true,
          message: e.userMessage || 'Could not search for some reason. Please try again..'
        })
      } finally {
        this.endLoading()
        this.$emit('fetching', 0)
      }

      this.$nextTick(() => {
        this.fetching = 0
      })

      // If the user has already changed filters again, abort
      if (!c.jsonEquals(this.getFetchData(start), data)) {
        return this
      }
      this.gotWith = data

      // If the path is still the same and it is a main table, save query
      if (this.isMain && path === this.$route.path) {
        this.setQuery()
      }

      this.$emit('fetched', set)
      this.hasFetched = true
      this.saveState()

      if (start) {
        this.deselectAll()
        this.set = set
      } else {
        const currentSet = _.imm(this.set)
        currentSet.splice(data.offset, currentSet.length - data.offset, ...set)
        this.set = currentSet
      }

      this.altSet = altSet
      this.endOfSet = !set.length || set.length < data.limit || data.limit === 0
      this.nextToken = nextToken

      eventBus.$emit('fetched', set)
      return set
    },

    getFetchData(start = false) {
      const isStage = this.type === 'stage'
      const stageOrder = [['stage_order', 'asc']]
      return {
        searchPhrase: this.searchPhraseLocal || '',
        filters: {
          [this.statusField]: '!i&&!d&&!h',
          ..._.imm(this.filtersLocal),
          ...(this.permanentFilters || {}),
          ...(this.$route?.meta?.permanentFilters ?? {})
        },
        order: isStage ? stageOrder : this.orderLocal,
        quick: true,
        limit: this.limitLocal,
        offset: start ? 0 : this.set.length,
        scope: this.scopeLocal && Object.keys(this.scopeLocal).length ? this.scopeLocal : null,
        nextToken: start ? null : this.nextToken
      }
    },

    /**
     *
     */
    fetchMore() {
      if (!this.endOfSet) {
        this.fetch(false, false)
      }
    },

    /**
     * Refetch values
     */
    async reload(force = true, start = true, updateCounts = false) {
      if (force && start && this.type && !this.staticSet) {
        await this.$store.dispatch(`${c.titleCase(this.type)}/clearCache`)
      }
      await this.fetch(force, start)
      this.$emit('reloaded')
      if (updateCounts) this.reloadCount += 1
      return true
    },

    async debounceReload(delay = 700, force = false, start = true) {
      return c.throttle(
        () => {
          // 1 at a time
          if (!this.fetching) {
            this.reload(force, start)
            this.fetching = 1
          }
        },
        {
          delay,
          key: this.uid,
          debounce: true
        }
      )
    },

    async throttleReload(delay = 300, force = false, start = true) {
      this.waitingForReload = true
      await c.throttle(
        () => {
          this.reload(force, start)
        },
        {
          delay,
          key: this.uid
        }
      )
      this.waitingForReload = false
    },

    openFilters() {
      this.$refs.filters.open()
    },

    setOrderBy(columnName) {
      const columnSort =
        (this.orderLocal && this.orderLocal.find((a) => a[0] === columnName)) || null

      if (!columnSort || columnSort[1] === 'asc') {
        this.orderLocal = [[columnName, 'desc']]
      } else {
        this.orderLocal = [[columnName, 'asc']]
      }
      this.throttleReload(this.fetching || this.waitingForReload ? 300 : 0, true, true)
    },

    /**
     * If is mobile, goes through. if not does nothing
     */
    rowTap(event) {
      if (this.isTouch) this.rowClick(event)
    },

    /**
     * Event handler
     * When a row is double clicked/tapped on
     */
    rowClick(event) {
      if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')
      const $target = $(event.target)
      const row = $target.is('[data-index]') ? $target[0] : $target.closest('[data-index]')[0]
      this.$emit('rowClick', row)
      if (row) {
        const index = +row.getAttribute('data-index')
        this.selectSingleRow(this.set[index])
        setTimeout(() => {
          this.keyLock = false
        })
      }
    },

    getObjectFromEvent(event) {
      const row = getRowFromEvent(event)
      const index = row && +row.getAttribute('data-index')
      return index > -1 && this.set.length > index && this.set[index]
    },

    /**
     * Event handler
     * When a row is double clicked/double tapped on
     */
    async rowDoubleClick(event) {
      if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')

      const target = this.getObjectFromEvent(event)
      if (!target) {
        return
      }
      this.$emit('dblclick', target)

      this.addLoading()
      let localType = target.type || this.typeField
      let localId = target[this.idField]
      if (target[`${this.typeField}_object_type`]) {
        localType = target[`${this.typeField}_object_type`]
        localId = target[`${this.typeField}_object_id`]
      }

      if (this.doubleClickToOpen) {
        this.editingItemId = [localId]
        await this.$store.dispatch('edit', {
          type: localType,
          id: localId,
          grid: this,
          opened: () => {
            this.$nextTick(() => {
              this.hideGrid = true
            })
          }
        })
        this.editingItemId = []
        this.hideGrid = false
      }
      this.removeLoading()
    },

    rowShiftClick(event) {
      if (!this.multiple) return this.rowClick(event)
      this.keyLock = true
      if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')
      const $target = $(event.target)
      const row = $target.is('[data-index]') ? $target[0] : $target.closest('[data-index]')[0]
      if (row) {
        setTimeout(() => {
          this.keyLock = false
        })
        this.selectRangeRow(this.set[+row.getAttribute('data-index')])
      }
      return this
    },

    rowCtrlClick(event) {
      if (!this.multiple) return this.rowClick(event)
      this.keyLock = true
      if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')
      const $target = $(event.target)
      const row = $target.is('[data-index]') ? $target[0] : $target.closest('[data-index]')[0]
      if (row) {
        setTimeout(() => {
          this.keyLock = false
        })
        this.selectAdditionalRow(this.set[+row.getAttribute('data-index')])
      }
      return this
    },

    selectSingleRow(obj) {
      if (!this.keyLock) {
        const index = this.set.indexOf(obj)
        this.deselectAll()
        this.selected = [index]
        this.lastSelected = index
        if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')
        this.$emit('selectedId', obj[this.idField])
        this.$emit('selectedIndex', index)
        this.$emit('singleClick', [obj])
      }
    },

    selectSingleRowByIndex(index) {
      if (!this.keyLock) {
        this.deselectAll()
        this.selected = [index]
        this.lastSelected = index
        this.$emit('selectedId', this.set && this.set[index] && this.set[index][this.idField])
        this.$emit('selectedIndex', index)
        if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')
      }
    },

    selectAdditionalRow(obj) {
      this.keyLock = true
      const index = this.set.indexOf(obj)
      const selectedIndex = this.selected.indexOf(index)
      if (selectedIndex > -1) {
        // Deselect
        this.$emit(
          'deselectedId',
          this.set && this.set[selectedIndex] && this.set[selectedIndex][this.idField]
        )
        this.$emit('deselectedIndex', index)
        this.selected.splice(selectedIndex, 1)
        this.selectedIds.splice(selectedIndex, 1)
        this.lastSelected = index
      } else {
        // Select
        this.selected = [...this.selected, index]
        this.$emit('selectedId', this.set && this.set[index] && this.set[index][this.idField])
        this.$emit('selectedIndex', index)
        this.lastSelected = index
      }
      setTimeout(() => {
        this.keyLock = false
      })
    },

    selectRangeRow(obj) {
      const index = this.set.indexOf(obj)
      if (this.lastSelected >= 0) {
        this.keyLock = true
        const startingIndex =
          Math.min(index, this.lastSelected) + (index > this.lastSelected ? 1 : 0)
        const endingIndex = Math.max(index, this.lastSelected) + (index > this.lastSelected ? 1 : 0)
        const range = endingIndex - startingIndex
        const indexes = [...Array(range).keys()]
        const ids = indexes.map((i) => this.set[i + startingIndex][this.idField])
        this.selectedIds = [...this.selectedIds, ...ids]
        ids.forEach((i) => this.$emit('selectedId', i) && this.$emit('selectedIndex', index))
        this.lastSelected = index
        setTimeout(() => {
          this.keyLock = false
        })
      } else {
        this.selectSingleRow(obj)
        this.$emit('selectedId', obj[this.idField])
        this.$emit('selectedIndex', index)
      }
    },

    deselectAll() {
      this.selectedIds.forEach((id) => this.$emit('deselectedId', id))
      this.selected.forEach((index) => this.$emit('deselectedIndex', index))
      this.selectedIds = []
      if (this.viewMode === 'list') eventBus.$emit('closeAllDrops')
    },

    async selectAll() {
      this.selectedIds = this.set.map((o) => {
        if (!o.file_is_folder) return o[this.idField]
      })
      this.selectedIds.forEach((id) => this.$emit('selectedId', id))
      await this.$nextTick()
      this.selected.forEach((index) => this.$emit('selectedIndex', index))
    },

    /**
     * Resize the grid
     */
    resize() {},

    loadDefaults(keepSearchPhrase = false) {
      this.filtersLocal = this.filters
      this.filterTextLocal = this.filterText
      if (!keepSearchPhrase) {
        this.searchPhraseLocal = this.searchPhrase
      }
      this.orderLocal = this.order
      this.limitLocal = this.limit
      this.offsetLocal = this.offset

      return this
    },

    clearFilters(keepSearchPhrase = false) {
      this.filtersLocal = {}
      this.filterTextLocal = {}
      if (!keepSearchPhrase) {
        this.searchPhraseLocal = this.searchPhrase
      }
      this.orderLocal = this.order
      this.limitLocal = this.limit
      this.offsetLocal = 0

      return this
    },

    loadSavedState() {
      if (!this.saveStateEnabled) return this

      const { order, visible } = this.getCache()
      this.orderLocal = order && order.length ? order : this.orderLocal
      this.visibleLocal = visible && visible.length ? visible : this.visibleLocal

      return this
    },

    loadQueryState() {
      const { filters, filterText, searchPhrase, order } = this.getQuery()

      if (this.isMain) {
        this.filtersLocal = filters && Object.keys(filters).length ? filters : this.filtersLocal
        this.filterTextLocal =
          filterText && Object.keys(filterText).length ? filterText : this.filterTextLocal
        this.searchPhraseLocal = searchPhrase || this.searchPhraseLocal
        this.orderLocal = order && order.length ? order : this.orderLocal
      }

      return this
    },

    /**
     * If an entity is created/saved globally and that entity
     * is of the same type of this grid, and this grid is active,
     * this grid should potentially reload to represent the changes.
     */
    shouldDoReloadFromGlobalSave() {},

    async virtualScrollerUpdateHandler(startIndex, endIndex) {
      const setLength = this.set.length
      if (!this.fetching && endIndex && setLength && endIndex >= setLength - 10) {
        await c.throttle(
          async () => {
            await this.fetchMore()
          },
          {
            key: `${this.uid}${this.set.length}`,
            delay: 200
          }
        )
      }
    },

    buildGlobalHandlers() {
      eventBus.$on(`saved-${this.typeField}`, this.reload)
      eventBus.$on('saved', this.shouldDoReloadFromGlobalSave)
      eventBus.$on('deleted', this.shouldDoReloadFromGlobalSave)
      eventBus.$on('marked', this.shouldDoReloadFromGlobalSave)
      eventBus.$on('created', this.shouldDoReloadFromGlobalSave)
      eventBus.$on('resize', this.resize)
    },
    destroyGlobalHandlers() {
      eventBus.$off(`saved-${this.typeField}`, this.reload)
      eventBus.$off('saved', this.shouldDoReloadFromGlobalSave)
      eventBus.$off('deleted', this.shouldDoReloadFromGlobalSave)
      eventBus.$off('marked', this.shouldDoReloadFromGlobalSave)
      eventBus.$off('created', this.shouldDoReloadFromGlobalSave)
      eventBus.$off('resize', this.resize)
    },

    saveState() {
      this.setCache(this.orderLocal, this.visibleLocal)
    },

    clearState() {
      c.removeCacheItem(this.getCacheKey())
    },

    /**
     * If no visibility set, set to default
     */
    setDefaultColumnVisibility() {
      if (!this.visibleLocal.length) {
        const setVisible = this.availableColumns.filter((col) => col.visible)

        const name = this.availableColumns.find((a) => a.column === this.nameField)
        const nameSet = name ? [name] : []

        this.visibleLocal = (setVisible.length ? setVisible : nameSet).map((col) => col.column)
      }

      return this
    },

    /**
     * Gets the variables stored as a get query in address bar
     */
    getQuery() {
      const queryState = this.$route.query
      const currentPath = this.$route.path
      const {
        path = '',
        filters = null,
        filterText = null,
        searchPhrase = null,
        order = null
      } = c.decodeGridQuery(queryState) || {}

      if (path && path.replace(/^\//, '') === currentPath.replace(/^\//, '')) {
        return {
          filters,
          filterText,
          searchPhrase,
          order
        }
      }

      return {
        filters: null,
        filterText: null,
        searchPhrase: null,
        order: null
      }
    },

    /**
     * Sets the address bar with query variables
     */
    setQuery(
      filters = this.filtersLocal,
      filterText = this.filterTextLocal,
      searchPhrase = this.searchPhraseLocal,
      order = this.orderLocal
    ) {
      const query = c.encodeGridQuery(this.$route.path, filters, filterText, searchPhrase, order)
      const fullQuery = {
        ...this.$route.query,
        ...query
      }
      this.$router.push({ query: fullQuery }).catch(() => {})

      return this
    },

    /**
     * Set the cache for order, and visible columns for this table.
     */
    setCache(order = this.orderLocal, visible = this.visibleLocal) {
      c.setCacheItem(
        this.getCacheKey(),
        {
          order,
          visible
        },
        this.typeField,
        this.$store.state.session
      )

      return this
    },

    /**
     * Get the cache for order and visible columns for this table.
     */
    getCache() {
      const { order = null, visible = null } =
        c.getCacheItem(this.getCacheKey(), this.typeField, this.$store.state.session) || {}

      return {
        order,
        visible
      }
    },

    getCacheKey() {
      return `grid.${this.typeField}.${this.$route.path}`
    },

    /**
     * Checks for a forwards scroll and that we actually need to load more data.
     * User can technically scroll to end of data if they scroll fast.
     *
     * @param length
     */
    async requestMore(length) {
      const scroller = this.$refs.gridscroller
      const currentFirstInDom = scroller.first
      const currentLastInDom = scroller.last
      const setLengthDifferential = length - this.limitLocal * 0.8
      let requested = false

      if (this.firstInDom < currentFirstInDom && currentLastInDom > setLengthDifferential) {
        await this.virtualScrollerUpdateHandler(0, length)
        requested = true
      }

      this.firstInDom = currentFirstInDom
      this.lastInDom = currentLastInDom
      return requested
    },
    setSelectedIds(ids) {
      this.selectedIds = ids
    },
    setHighlightedIds(ids) {
      this.highlightedIds = ids
    }
  }
}
