<template>
  <Page title="Stripe products map">
    <CardSection>
      <div class="flex items-center justify-between !mb-0">
        <h3>Product default stripe product mapping</h3>
      </div>
      <CardList class="!overflow-auto">
        <DataTable :loading="usaFeatureProductsLoading" :value="usaFeatureProducts" stripedRows>
          <Column field="id" header="Feature Product Id"></Column>
          <Column field="feature_product_name" header="Feature Product Name"></Column>
          <Column field="default_product_id" header="Stripe Product ID">
            <template #body="{ data }">
              <Choose
                :force="true"
                :return-array="false"
                @input="(productId) => linkDefaultFeatureProductStripeProduct(data.id, productId)"
                :value="data?.stripe_product_id || null"
                schema="subscription:mock"
                searchMethod="searchStripeProducts"
                :formatter="
                  (product) => ({
                    html: `${product.name} (${product?.default_price?.type} ${product.default_price?.unit_amount || 'n/a'} ${product?.default_price?.currency})`,
                    text: `${product.name} (${product?.default_price?.type} ${product.default_price?.unit_amount || 'n/a'} ${product?.default_price?.currency})`,
                    value: product.id
                  })
                "
                :filters="{ country: 'usa' }"
                :btnClass="!data?.stripe_product_id ? '!text-red-600' : ''"
              />
            </template>
          </Column>
        </DataTable>
      </CardList>
    </CardSection>
    <CardSection>
      <div class="flex items-center justify-between !mb-0">
        <h3>Stripe products</h3>
        <div class="flex gap-2">
          <Btn :action="onPrevClick" :disabled="usaProductsLoading || !usaHasPrevious">
            Previous
          </Btn>
          <Btn :action="onNextClick" :disabled="usaProductsLoading || !usaHasNext"> Next </Btn>
        </div>
      </div>

      <CardList class="!overflow-auto">
        <DataTable :loading="usaProductsLoading" :value="usaViewSet" stripedRows>
          <Column field="id" header="Stripe Product Id"></Column>
          <Column field="name" header="Stripe Product Name"></Column>
          <Column field="default_price.unit_amount" header="Default Price">
            <template #body="{ data }">
              {{ data?.default_price?.unit_amount || 'N/A' }} {{ data?.default_price?.currency }}
            </template>
          </Column>
          <Column field="default_price.type" header="Default Price Type">
            <template #body="{ data }">
              {{ data?.default_price?.type || 'N/A' }}
            </template>
          </Column>
          <Column field="linked_product" header="Linked App feature Product">
            <template #body="{ data }">
              <Choose
                :return-array="false"
                @input="
                  (featureProductId) =>
                    linkProductToFeatureProduct(data.id, data.name, featureProductId)
                "
                :value="data?.linked_feature_product_id || null"
                :staticSet="chooseFormattedFeatureProductOptions"
                :btn-class="!data?.linked_feature_product_id ? '!text-red-600' : ''"
              />
            </template>
          </Column>
        </DataTable>
      </CardList>
    </CardSection>
  </Page>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

// ======== Composables ======== //
const store = useStore()
// =========================== //

// ======== Data ======== //
const usaProducts = ref([])
const usaProductsLoading = ref(true)
const usaLastKey = ref('')
const usaHasMore = ref(true)
const usaOffset = ref(0)

const usaFeatureProducts = ref([])
const usaFeatureProductsLoading = ref(true)
// =========================== //

// ======== Computed ======== //
const usaViewSet = computed(() => usaProducts.value.slice(usaOffset.value, usaOffset.value + 25))
const usaHasNext = computed(
  () => usaHasMore.value || usaOffset.value + 25 < usaProducts.value.length
)
const usaHasPrevious = computed(() => usaOffset.value >= 25)
const chooseFormattedFeatureProductOptions = computed(() =>
  usaFeatureProducts.value.map((product) => ({
    text: product.feature_product_name,
    value: product.id
  }))
)
// =========================== //

// ======== Methods ======== //
const onNextClick = async () => {
  if (usaHasNext.value) {
    if (usaOffset.value + 25 >= usaProducts.value.length) await fetchUsaProducts()
    usaOffset.value += 25
  }
}

const onPrevClick = () => {
  if (usaHasPrevious.value) {
    usaOffset.value -= 25
  }
}
const fetchProducts = (country, starting_at) => {
  return store.dispatch('Subscription/getStripeProducts', {
    country,
    starting_at
  })
}
const fetchFeatureProducts = async (country) => {
  return store.dispatch('Subscription/getFeatureProducts', {
    country
  })
}
const fetchUsaProducts = async () => {
  if (!usaHasMore.value) return
  usaProductsLoading.value = true
  try {
    const res = await fetchProducts('usa', usaLastKey.value)
    usaProducts.value.push(...res.products)
    usaLastKey.value = res.last_product.id
    usaHasMore.value = res.has_more
  } catch (e) {
    console.error(e)
  } finally {
    usaProductsLoading.value = false
  }
}
const fetchUsaFeatureProducts = async () => {
  usaFeatureProductsLoading.value = true
  try {
    const { products } = await fetchFeatureProducts('usa')
    usaFeatureProducts.value = products
  } catch (e) {
    console.error(e)
  } finally {
    usaFeatureProductsLoading.value = false
  }
}
const linkProductToFeatureProduct = async (productId, productName, featureProductId) => {
  await store.dispatch('Subscription/linkProductToFeatureProduct', {
    productId,
    productName,
    featureProductId
  })

  usaProducts.value.forEach((product) => {
    if (product.id === productId) product.linked_feature_product_id = featureProductId
  })

  return store.dispatch('alert', {
    message: `Successfully linked ${productName} to feature product ${featureProductId}`
  })
}
const linkDefaultFeatureProductStripeProduct = async (featureProductId, productId) => {
  try {
    await store.dispatch('Subscription/linkDefaultFeatureProductStripeProduct', {
      featureProductId,
      productId,
      country: 'usa'
    })

    usaFeatureProducts.value.forEach((product) => {
      if (product.id === featureProductId) product.stripe_product_id = productId
    })

    return store.dispatch('alert', {
      message: `Successfully linked feature product`
    })
  } catch (err) {
    console.error(err)
  }
}
// =========================== //

// ======== Lifecycle ======== //
onMounted(() => {
  fetchUsaFeatureProducts()
  fetchUsaProducts()
})
// =========================== //
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
