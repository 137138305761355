<template>
  <div class="quote-pres--heading-outer">
    <div
      v-bind="$attrs"
      class="quote-pres--heading"
      :style="headingStyle"
      :key="`a`"
      ref="heading"
    ></div>
    <div class="quote-pres--heading-content" :style="overrideHeadingContainerStyle" :key="`b`">
      <div class="quote-pres--heading-left">
        <div class="quote-pres--heading-title" :style="headingTitleStyle">
          {{ $store.state.session.company.company_name_short }}
        </div>

        <div class="w-full mt-4 text-center md:text-left">
          <span style="font-size: 1.4em; display: block; margin-bottom: 0.1em">
            {{ `Invoice #${invoice_id}` }}
          </span>
          <span>{{ $f.currency(invoice_gross) }}</span>
          <span>&nbsp;•&nbsp;</span>
          <span :class="`text-${invoiceStatus[1]} font-weight-bold`">
            {{ invoiceStatus[0] }}
          </span>
        </div>

        <div class="quote-pres--contact">
          <btn
            class="round btn xs mb-2"
            severity="secondary"
            @click="
              () =>
                $store.dispatch('openLink', { url: $store.state.session.company.company_website })
            "
            v-if="$store.state.session.company.company_website"
          >
            <template #icon>
              <font-awesome-icon icon="browser" />
            </template>
            {{ $store.state.session.company.company_website }}
          </btn>
          <btn
            class="round btn xs"
            severity="secondary"
            @click="
              () =>
                $store.dispatch(
                  'phone/call',
                  {
                    number: $store.state.session.company.company_phone
                  },
                  { root: true }
                )
            "
            v-if="$store.state.session.company.company_phone"
          >
            <template #icon>
              <font-awesome-icon :icon="['fas', 'phone']" />
            </template>
            {{ $f.phone($store.state.session.company.company_phone) }}
          </btn>
        </div>
      </div>
      <div class="quote-pres--heading-right">
        <div>
          <span class="quote-pres--heading-subtitle" :style="headingSubHeadingStyle">
            {{ l('Invoice') }}
          </span>
          <span
            class="quote-pres--heading-subsubtitle"
            v-if="invoice_time_sent"
            :style="headingTextStyle"
          >
            {{ $t('Sent') }}: {{ $f.date(invoice_time_sent) }}
          </span>
          <span
            class="quote-pres--heading-subsubtitle"
            v-if="invoice_time_due"
            :style="headingTextStyle"
          >
            {{ $t('Due') }}: {{ $f.date(invoice_time_due) }}
          </span>
        </div>

        <div @click="goToProposal" style="cursor: pointer">
          <span class="quote-pres--heading-subtitle" :style="headingSubHeadingStyle">
            {{ l('Project') }}
          </span>
          <span class="quote-pres--heading-subsubtitle" :style="headingTextStyle">
            {{ quote.quote_name }}
          </span>
          <span class="quote-pres--heading-subsubtitle" :style="headingTextStyle">
            {{ $f.capitalize(quote.quote_suite ? `${quote.quote_suite} ` : '')
            }}{{ $f.capitalize(quote.quote_address) }}
          </span>
          <span class="quote-pres--heading-subsubtitle" :style="headingTextStyle">
            {{ $f.capitalize(quote.quote_city) }} {{ $f.capitalize(quote.quote_prov) }}
            {{ $f.postal(quote.quote_postal) }}
          </span>
        </div>

        <div>
          <span class="quote-pres--heading-subtitle" :style="headingSubHeadingStyle">
            {{ l('Client') }}
          </span>
          <span
            class="quote-pres--heading-subsubtitle"
            v-if="client.company_name"
            :style="headingTextStyle"
          >
            {{ $f.capitalize(client.company_name) }}
          </span>
          <span class="quote-pres--heading-subsubtitle" :style="headingTextStyle">
            <template v-if="client.company_name">Attn: </template
            >{{ $f.capitalize(client.user_fname) }} {{ $f.capitalize(client.user_lname) }}
          </span>
          <span class="quote-pres--heading-subsubtitle">
            {{ client.user_address }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectDistinct from '../../mixins/ObjectDistinct'
import TranslationMixin from '../../quote/presentation/languages/TranslationMixin'

export default {
  name: 'PresentationHeading',
  mixins: [ObjectDistinct('invoice'), TranslationMixin],
  methods: {
    goToProposal() {
      const routeData = this.$router.resolve({ name: 'Presentation', query: this.$route.query })
      this.$store.dispatch('openLink', { url: window.location.origin + routeData.href })
    }
  },
  computed: {
    quoteTimeLastModified() {
      return this.quote_time_last_modified
    },
    changeOrderTimeSent() {
      return this.change_order_time_sent
    },
    headingStyle() {
      let style = {}
      const blendMode = this.headcoverBackgroundBlendMode || 'normal'
      let url = this.backgroundUrlFinal

      url = url && /^url\(/i.test(url) ? url : `url(${url})`

      if (url && blendMode !== 'normal') {
        style.background = `${url}, linear-gradient(-45deg, ${this.mainPrimaryColor} 0%, ${this.mainSecondaryColor} 100%) !important`
      } else if (url) {
        style.background = `${url} !important`
      } else {
        style.background = `linear-gradient(-45deg, ${this.mainPrimaryColor} 0%, ${this.mainSecondaryColor} 100%) !important`
      }

      if (url) {
        style.backgroundSize = 'cover'
        style.backgroundRepeat = 'no-repeat'
        style.backgroundPosition = 'center'
      }
      style.backgroundBlendMode = `${blendMode} !important`

      style = {
        ...style,
        ...this.overrideHeadingStyle
      }

      return style
    },
    headingTitleStyle() {
      return {
        color: `${this.headingMainHeadingFontColor} !important`
      }
    },
    headingSubHeadingStyle() {
      return {
        color: `${this.headingSubheadingFontColor} !important`
      }
    },
    headingTextStyle() {
      return {
        color: `${this.headingTextFontColor} !important`
      }
    },
    invoiceStatus() {
      if (!this.invoice_status) return ['', 'muted']
      switch (this.invoice_status) {
        case 'o':
          return ['Unpaid', 'danger']
        case '$':
          return ['Pending', 'secondary']
        case 'p':
          return ['Payment on route', 'secondary']
        case 'e':
          return ['Paid', 'success']
        default:
          return ['', 'muted']
      }
    }
  },
  props: {
    overrideHeadingStyle: {
      type: Object,
      default: () => ({})
    },
    overrideHeadingContainerStyle: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '@/theme/presentations/colors.scss';
@import '@/theme/presentations/mixins.scss';
@import '@/theme/presentations/variables/invoice.scss';
@import '@/theme/presentations/structure/invoice.scss';

$headingHeight: 50vh;

.element-replacer {
  height: 5em;
  width: 100%;
  background: transparent;
}
.quote-pres--container {
  min-height: 0;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;

  .quote-pres--fixed-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 5em;
    background: rgba($pitch-black, 0.8);
    z-index: $z-layout + 100;
    padding: 1em;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .quote-pres--fixed-nav-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 5em;
      height: 100%;

      &.quote-pres--fixed-btn-nav-prev {
        color: $cool-gray-300 !important;
        > a {
          background: $cool-gray-300 !important;
          color: $cool-gray-700 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $pitch-black !important;
            color: $flame-white !important;
          }
        }
      }

      &.quote-pres--fixed-btn-nav-next {
        color: $deep-red-800 !important;
        > a {
          background: $deep-red-400 !important;
          color: $deep-red-800 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $deep-red-800 !important;
            color: $flame-white !important;
          }
        }
      }
    }
  }
}

.coverHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .quoteHeading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: right;
    font-weight: bold;
    padding-left: 1em;
    min-width: 230px;
  }
}

.changeOrderMessage {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: center;
}

.coverLetter {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: left;
  white-space: pre-line;
}

#cc.small-format {
  .coverHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .quoteHeading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      min-width: 230px;
      padding-left: 0;
      padding-top: 1em;
    }
  }
}

.quote-pres--heading-outer {
  width: 100%;
}
.quote-pres--heading-content {
  .quote-pres--heading-left {
    position: relative;
    height: 100%;
    margin: 0;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
  }
}

.invoice-pres.quote-pres--sandbox {
  background: $flame-white !important;
  .quote-pres--heading {
    box-shadow: none !important;
  }
  .quote-pres--heading-outer .quote-pres--heading-content {
    .quote-pres--heading-title,
    .quote-pres--heading-subtitle {
      color: $cool-gray-800 !important;
      margin-bottom: 0.3em;
      min-height: 0 !important;
    }
    .quote-pres--heading-subsubtitle {
      color: $cool-gray-600 !important;
      font-weight: 200 !important;
      line-height: 1.5em;
    }
    .quote-pres--contact {
      > a {
        margin-bottom: 1em;
      }
    }
  }
}
</style>
