<template>
  <modal
    v-bind="$props"
    class="modal-mini"
    :size="pictures.length ? 'lg' : 'md'"
    :width="800"
    ref="modal"
    :scrollable="true"
    :full="false"
    :clickAway="true"
    :expandable="false"
  >
    <template #header>{{ $t('Upgrade details') }}</template>
    <template #body>
      <!--   Cost item name   -->
      <div class="relative grid grid-cols-1 md:grid-cols-4 gap-6 mt-6">
        <!--   Left     -->
        <div
          class="flex flex-col justify-start items-start col-span-2 max-w-full"
          v-if="pictures.length"
        >
          <!--    Image gallery    -->
          <div class="overflow-hidden w-full">
            <Galleria
              :images="pictures"
              :numVisible="thumbnailNumber"
              thumbnailsPosition="bottom"
              hideArrows="true"
              :pt="{
                thumbnailContainer: 'w-fit pt-3 ',
                thumbnailItem: 'overflow-hidden pr-2 w-fit',
                thumbnailItemContent: 'w-fit'
              }"
            />
          </div>
          <!--          <div-->
          <!--            class="flex justify-center items-center rounded-md w-full bg-surface-200 h-[350px]"-->
          <!--          >-->
          <!--            <font-awesome-icon :icon="['fas', 'cube']" class="text-7xl text-surface-300" />-->
          <!--          </div>-->
        </div>
        <!--    Right    -->
        <div
          class="col-span-2 flex flex-col justify-start items-stretch gap-4"
          :class="{
            'col-span-2': pictures.length,
            'col-span-4': !pictures.length
          }"
        >
          <div class="text-base text-surface-500">{{ parentName }}</div>
          <div class="text-2xl font-medium">{{ itemName }}</div>
          <!--          <div class="font-medium text-xl">{{ qty }} {{ units }}</div>-->
          <template v-if="description">
            <p class="font-light whitespace-pre-line">{{ description }}</p>
          </template>
          <div
            class="mt-4"
            v-if="properties && properties.length && properties[0] && properties[0][0]"
          >
            <span class="font-medium">{{ $t('Features') }}</span>
            <PresentationProperties :object="object" />
          </div>
          <p class="text-xs py-4 text-pitch-black-500 font-light">
            {{
              $t(
                'Upgrade availability may vary according to individual project circumstances.Pictures are examples and may not exactly represent how it appears on your project.'
              )
            }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <!--     CTA     -->
      <div class="flex flex-col lg:flex-row gap-2 justify-end w-full h-full">
        <Btn size="lg" severity="tertiary-borderless" :action="handleClose">
          <span v-if="addon && !addon?.bulk?.oMeta?.lockOptions">{{ $t('Cancel') }}</span>
          <span v-else>{{ $t('Close') }}</span>
        </Btn>
        <Btn
          v-if="addon && !addon?.bulk?.oMeta?.lockOptions"
          severity="info"
          size="lg"
          class="flex-col !leading-none !gap-1 min-w-40"
          :action="handleSelection"
          :disabled="loading"
        >
          <div class="leading-none">
            {{ $t('Select this') }}
          </div>
          <div class="leading-none text-sm font-light">
            {{ priceAction }}{{ currency(priceTag) }}
          </div>
        </Btn>
      </div>
    </template>
  </modal>
</template>

<script>
import { computed, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import AddonListMixin from '@/components/quote/addons/AddonListMixin'
import PresentationProperties from '@/components/quote/presentation/PresentationProperties.vue'
import Galleria from '@/components/ui/Gallery/Galleria.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter.js'

export default {
  name: 'UpgradeDetails',
  mixins: [ObjectModalMixin, AddonListMixin],
  components: { PresentationProperties, Galleria },
  props: {
    modal: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const { modal } = toRefs(props)
    const { smallFormat } = useMediaQuery()
    const parentName = computed(() => modal.value.parentName)
    const itemName = computed(() => modal.value.itemName)
    const description = computed(() => modal.value.description)
    const pictures = computed(() => modal.value.urls)
    const object = computed(() => modal.value.object)
    const target = computed(() => modal.value.target)
    const properties = computed(() => modal.value.properties)
    const priceTag = computed(() => modal.value.priceTag)
    const priceAction = computed(() => modal.value.priceAction)
    const addon = computed(() => modal.value.addon)
    const loading = ref(false)

    const handleClose = async () => {
      await store.dispatch('modal/closeAll')
    }

    const handleSelection = async () => {
      loading.value = true

      const select = async () =>
        await store.dispatch('Quote/selectAddon', {
          addonType: addon.value.livePriceRef ? 'live_price' : addon.value.type,
          addonId: addon.value.livePriceRef ?? addon.value.id,
          refId: modal.value.refId
        })
      if (
        (target.value.type === 'cost_item' &&
          !target.value.cost_item_is_included &&
          target.value.cost_item_is_optional) ||
        (target.value.type === 'assembly' &&
          !target.value.assembly_is_included &&
          target.value.assembly_is_optional)
      ) {
        await store.dispatch('Quote/toggleIncluded', {
          refId: target.value.refId,
          store: 'Quote',
          included: 1,
          object: target.value
        })
        // can retrun error if it is already selected in this case
        try {
          await select()
        } catch (e) {
          console.debug('no addon found')
        } // eslint-disable-line
      } else await select()

      loading.value = false
      await handleClose()
    }

    const units = computed(() => {
      return object.value.type === 'cost_item' ? object.value.unit_of_measure_abbr : 'each'
    })

    const qty = computed(() => {
      const parent = store.state.Quote.normalized[modal.value.refId]
      return parent?.cost_item_qty_net || parent?.quote_qty_net || 0
    })

    const thumbnailNumber = computed(() => {
      if (pictures.value.length === 1) return 1
      if (smallFormat.value) return 4
      return pictures.value.length > 7 ? 7 : pictures.value.length
    })

    const currency = formatCurrencySymbol

    return {
      currency,
      handleClose,
      handleSelection,
      units,
      qty,
      addon,
      parentName,
      itemName,
      description,
      object,
      properties,
      priceTag,
      pictures,
      loading,
      priceAction,
      smallFormat,
      thumbnailNumber
    }
  }
}
</script>
