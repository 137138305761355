import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useSession = defineStore('session', () => {
  const isFullyAuthenticated = ref(false)
  const usedMagicLink = ref(false)

  function $reset() {
    isFullyAuthenticated.value = false
    usedMagicLink.value = false
  }

  return { isFullyAuthenticated, usedMagicLink, $reset }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSession, import.meta.hot))
}
