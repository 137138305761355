<template>
  <div class="flex flex-col justify-start gap-1">
    <a
      @click.stop.prevent="open = !open"
      class="flex flex-col justify-start gap-1 !select-none cursor-pointer"
    >
      <span
        class="flex justify-start items-center gap-1 font-medium"
        :class="type === 'cost_item' ? '' : 'change-audit--sublabel-assembly'"
      >
        <!--        <font-awesome-icon-->
        <!--          icon="chevron-right"-->
        <!--          :class="['transition-all', { 'rotate-90': open }]"-->
        <!--          fixed-width-->
        <!--          v-if="openable"-->
        <!--        />-->

        <font-awesome-icon
          :icon="
            changed === 'added'
              ? 'fas fa-plus-circle'
              : changed === 'removed'
                ? 'fas fa-minus-circle'
                : 'fas fa-circle'
          "
          :class="[
            'text-sm',
            {
              'text-deep-red-400 ': changed === 'removed',
              'text-blue-print-400 ': changed !== 'removed'
            }
          ]"
        />

        {{ objectName }}</span
      >
    </a>

    <div v-if="changed" class="text-sm opacity-80 ml-5">
      {{ changed === 'added' ? 'Added' : 'Removed' }}
    </div>

    <div v-if="open && openable" class="ml-5 flex flex-col gap-1">
      <change-audit-field
        v-for="(fieldChange, field) in fieldChanges"
        :key="field"
        :field="field"
        :objectType="type"
        :changes="fieldChange"
        :fields="fields"
      />

      <change-audit-object
        v-for="subObject in subObjects"
        :key="subObject.refId"
        :changes="subObject"
        :startOpen="startOpen"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'ChangeAuditObject',
  components: { FontAwesomeIcon },

  props: {
    /**
     * Object change object schema as defined in
     * api/Changes/Utilities.objectContainer
     */
    changes: {
      required: true
    },

    /**
     * Whether to start expanded, or collapsed
     */
    startOpen: {
      default: false
    }
  },

  data() {
    return {
      open: this.startOpen
    }
  },

  methods: {
    filterFields(fieldChanges) {
      const deduplicated = {}
      const titles = []
      const constructor = c.getConstructor(this.type)
      const fieldKeys = Object.keys(fieldChanges)
      const fieldLength = fieldKeys.length

      fieldKeys.forEach((field, index) => {
        // Fields to skip
        if (
          /aoChildren|(_base($|_)|adjustment)/.test(field) &&
          // If it is the only one, and this is our last option,
          // then show it even if it wouldn't normally be shown
          (index < fieldLength - 1 || Object.keys(deduplicated).length)
        ) {
          return
        }

        const title = c.getFieldTitle(field, constructor)
        if (titles.includes(title)) {
          return
        }
        titles.push(title)

        deduplicated[field] = fieldChanges[field]
      })

      return deduplicated
    }
  },

  computed: {
    /**
     * Whether this item was added or removed
     */
    changed() {
      return this.changes.changed || null
    },

    /**
     * Sub objects
     */
    subObjects() {
      return this.changes.subChanges || {}
    },

    /**
     * Sub objects
     */
    fieldChanges() {
      const fieldChanges = this.changes.fieldChanges || {}

      const filtered = this.filterFields(fieldChanges)

      return filtered
    },

    /**
     * Whether there are nested sub-children
     * @returns {number}
     */
    hasChildren() {
      return Object.keys(this.subObjects).length && this.changes.changed !== 'removed'
    },

    /**
     * Whether there are nested field changes
     * @returns {number}
     */
    hasFieldChanges() {
      return Object.keys(this.fieldChanges).length
    },

    openable() {
      return this.hasChildren || this.hasFieldChanges
    },

    /**
     * The name of this object
     * @returns {null|string}
     */
    objectName() {
      return this.changes.referenceName
    },

    /**
     * The entity type of this object
     * @returns {*}
     */
    type() {
      return this.changes.objectType
    },

    /**
     * The fields for this entity type
     * @returns {*}
     */
    fields() {
      return c.getConstructor(this.type).fields
    }
  }
}
</script>
