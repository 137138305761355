<template>
  <Modal
    v-bind="$props"
    ref="modal"
    size="full"
    :full="true"
    :clickAway="false"
    scrollable
    :closeable="false"
  >
    <template #header>
      <h2>Review terms & approval</h2>
    </template>
    <template #body>
      <div class="flex flex-col justify-start items-center w-full">
        <div class="flex flex-col justify-star items-center max-w-[900px]">
          <terms-and-conditions
            v-bind="modal"
            ref="body"
            :termsAccepted="termsAccepted"
            @declined="termsDeclined"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import TermsAndConditions from '../bodies/TermsAndConditions.vue'
import ObjectModalMixin from './ObjectModalMixin'

export default {
  name: 'ModalTermsAndConditions',
  mixins: [ObjectModalMixin],
  methods: {
    termsAccepted({ terms, signature }) {
      return this.modal.saved.call(this, terms, signature)
    },
    termsDeclined(terms) {
      return this.modal.closed.call(this, terms)
    }
  },
  components: { TermsAndConditions },
  props: {
    type: {
      default: 'company'
    }
  }
}
</script>
