<template>
  <div>
    <!-- Franchisor shared catalog -->
    <TraverseParent
      v-if="$store.state.session.company.franchisor_id"
      ref="sharedList"
      :value="value"
      :cost-type-id="null"
      :company="$store.state.session.company.franchisor_company_id"
      :selectedCompany="company"
      :searchPhrase="searchPhrase"
      limitToRoot
      startAs="Shared catalog"
      @input="setParent"
      @open="() => $emit('open')"
    />

    <!-- Company catalog -->
    <TraverseParent
      ref="companyList"
      v-if="$store.state.session.scope.company"
      :value="value"
      :cost-type-id="null"
      :company="$store.state.session.scope.company"
      :selectedCompany="company"
      :searchPhrase="searchPhrase"
      limitToRoot
      :startAs="`${getCompanyName()} catalog`"
      @input="setParent"
      @open="() => $emit('open')"
    />

    <!-- Bolster catalog -->
    <TraverseParent
      v-if="!isFranchisor && !hidePublic"
      ref="autocostlist"
      :value="value"
      :cost-type-id="autocostRootObject.cost_type_id"
      :object="autocostRootObject"
      :company="null"
      :selectedCompany="null"
      :searchPhrase="searchPhrase"
      startAs="AutoCost catalog"
      @input="setParent"
      @open="() => $emit('open')"
    />

    <TraverseParent
      v-if="!hideGlobalPublic"
      ref="ccList"
      :filters="cclistFilter"
      :value="value"
      :cost-type-id="null"
      company="NULL"
      :searchPhrase="searchPhrase"
      :selectedCompany="company"
      startAs="Bolster legacy catalog"
      @input="setParent"
      @open="() => $emit('open')"
    />
  </div>
</template>

<script>
export default {
  name: 'TraverseTree',
  emits: ['input'],
  data() {
    return {
      autocostRootObject: {
        cost_type_id: 'autocost',
        cost_type_name: 'AutoCost catalog',
        hasSubCategories: true
      }
    }
  },
  methods: {
    setParent(parent = null, company = null) {
      this.$emit('input', parent, company)
    },
    getCompanyName() {
      const shared = this.isFranchisor ? ' shared' : ''
      const longName = this.$store.state.session.company.company_name
      const shortName = this.$store.state.session.company.company_name_short
      if (shortName) return shortName + shared
      else if (longName) return longName + shared
      return 'Your'
    }
  },
  computed: {
    isSuperUser() {
      return this.$store.state.session.user && this.$store.state.session.user.user_is_super_user
    },
    cclistFilter() {
      if (!this.isSuperUser) {
        const arrayOfCcCliCat = [
          'co-4r87fy4xfyioc',
          'homedepot-ca',
          'homedepot-usa',
          'menards-com',
          'craftsman-root',
          'co-4rywg9ebso84s'
        ]
        return { cost_type_id: arrayOfCcCliCat }
      }
      return {}
    }
  },
  props: {
    value: {
      required: true
    },
    company: {
      required: true
    },
    searchPhrase: {
      default: ''
    },
    isFranchisor: {
      default: false
    },
    hidePublic: {
      default: false
    },
    hideGlobalPublic: {
      default: true
    },
    modal: {
      default: null
    }
  }
}
</script>
