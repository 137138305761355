<template>
  <div
    class="p-2 pr-4 bg-pitch-black w-fit h-9 text-flame-white font-sans text-sm font-medium flex items-center rounded"
  >
    <font-awesome-icon :icon="type.icon" class="mr-2 text-level-yellow" />
    <span>{{ label }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => ['Upgrades', 'Optional', 'Selections'].includes(value)
  }
})

const iconMap = {
  Selections: {
    icon: 'swatchbook'
  },
  Optional: {
    icon: ['fas', 'fa-circle-half-stroke']
  }
}

const label = computed(() => props.type)
const type = computed(() => iconMap[props.type])
</script>
