<template>
  <!-- Root div -->
  <div
    :style="{ zIndex: zIndexOnOpen }"
    class="absolute bg-flame-white w-screen h-screen animate-pullUpFast"
    v-show="visible"
  >
    <!-- Modal header -->
    <div class="flex flex-row justify-between items-center w-full mx-4 my-4 min-h-12">
      <div
        class="flex flex-row justify-start items-center text-pitch-black font-medium gap-2 *:text-2xl"
      >
        <icon icon="box-open-full" />
        <p>Catalogs</p>
      </div>

      <!-- Header actions -->
      <div class="flex flex-row items-center gap-2 mr-8">
        <!-- Clear selected -->
        <Btn
          size="lg"
          :severity="$refs.body?.selected?.length ? 'tertiary-borderless' : 'tertiary'"
          :action="close"
        >
          {{ $refs.body?.selected?.length ? 'Cancel' : 'Done' }}
        </Btn>
        <Btn v-if="$refs.body?.selected?.length" size="lg" severity="tertiary" :action="clear">
          <font-awesome-icon icon="xmark" />
          Clear {{ $refs.body?.selected?.length }} selected
        </Btn>
        <!-- Add to estimate -->
        <Btn
          v-if="$refs.body?.selected?.length"
          class="animate-shake mr-2"
          size="lg"
          severity="primary"
          :action="done"
        >
          <font-awesome-icon icon="arrow-down-to-bracket" />
          Add {{ $refs.body?.selected?.length }} to estimate
        </Btn>
      </div>
    </div>

    <!-- Modal body -->
    <TraverseBody
      ref="body"
      :startingCompany="$store.state.session.scope.company"
      :modal="this"
      startingParent="NULL"
    />
  </div>
</template>

<script>
import TraverseBody from './TraverseBody.vue'

export default {
  components: {
    TraverseBody
  },
  data() {
    return {
      visible: false,
      company: this.$store.state.session.scope.company,
      zIndexOnOpen: 499
    }
  },
  methods: {
    async open() {
      this.$refs.body.open()
      // If company has switched, set the starting category to the Bolster catalog ('autocost')
      if (this.$store.state.session.scope.company !== this.company) {
        this.$refs.body.parent = 'autocost'
        this.company = this.$store.state.session.scope.company
      }
      this.visible = true
      this.zIndexOnOpen = this.$store.state.modal.topZIndex + 1
    },
    close() {
      this.visible = false
      this.$refs.body.close()
    },
    done() {
      this.visible = false
      this.$refs.body.done()
    },
    clear() {
      this.$refs.body.clearSelected()
    }
  }
}
</script>
