<template>
  <Page title="Progress">
    <Progress
      :items="items"
      :loading="loading"
      :approverId="clientId"
      :disablePayment="!itemizedEnabled || !isPaymentsOnboarded"
      approverRole="client"
    />
  </Page>
</template>

<script setup>
import { provide, computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import Progress from '@/components/progress/Progress.vue'
import Approval from '@/../imports/api/Approval'
import statuses from '@/../imports/api/Statuses'
import { getApprovalsByApprover } from '@/apollo-client/requests/approval'
import eventBus from '@/eventBus'
import useItemizedPayments from '@/components/composables/ItemizedPayments'

const items = ref([])
const loading = ref(0)

const store = useStore()
const project = computed(() => store.state.session.quote)
provide('project', project)

const { itemizedEnabled } = useItemizedPayments()

const clientId = computed(() => project.value.client_id) // store.state.session.user.user_client_id);

const isPaymentsOnboarded = computed(() => {
  return (
    store.state.session.company &&
    store.state.session.company.counterparty_status &&
    (store.state.session.company.counterparty_status === 'a' ||
      store.state.session.company.counterparty_status === '#')
  )
})

const loadProject = async (approvedItems = []) => {
  loading.value = 1
  const { normalized } = await store.dispatch('Quote/fetchNormalized', {
    id: project.value?.quote_id,
    force: true
  })
  const approvals = await getApprovalsByApprover({
    userId: project.value.client_id,
    quoteId: project.value?.quote_id
  })

  const costItems = Object.values(normalized).filter((item) => item.type === 'cost_item')

  const mappedItems = Approval.mapApprovalsToItems(approvals, costItems)
  const adjustedItems = mappedItems.reduce((acc, itemApproved) => {
    const item = approvedItems.find((i) => i.item_id === itemApproved.item_id)
    // make sure they have an invoice id and processing status
    if (item) {
      acc.push({
        ...item,
        invoice_id: item.invoice_id ? item.invoice_id : 1,
        invoice_status: item.invoice_status ? item.invoice_status : statuses.statuses.Pending
      })
    } else {
      acc.push(itemApproved)
    }
    return acc
  }, [])
  items.value = adjustedItems
  loading.value = 0
}

watch(
  project,
  (newValue, oldValue) => {
    console.log('Project updated:', { newValue, oldValue })
    loadProject()
  },
  { deep: true }
)

onMounted(() => {
  loadProject()
  eventBus.$on('checkout-complete', (approvedItems) => {
    loadProject(approvedItems)
  })
  eventBus.$on('approval-action-processed', () => {
    loadProject()
  })
})

onBeforeUnmount(() => {
  eventBus.$off('checkout-complete')
  eventBus.$off('approval-action-processed')
})
</script>
