<template>
  <Page title="Lead form" class="bg-cement-100" bodyClass="justify-center items-center">
    <template #header>
      <div class="flex justify-between mt-4">
        <h1>Lead Form</h1>
        <Btn size="lg" severity="primary-black" to="lead_rotations/custom">
          View or create custom lead form
        </Btn>
      </div>
    </template>
    <div class="lead-form-page-container">
      <div class="lead-form-card">
        <div class="lead-form-card-content">
          <div class="lead-form-card-content-left-container">
            <div class="lead-form-card-content-left">
              <div class="lead-form-sub-heading">Quick start</div>
              <div class="lead-form-heading highlight" style="margin-bottom: 0px">
                Unlock the power of effortless lead generation with your pre-generated
              </div>
              <div class="lead-form-heading highlight bold" style="margin-top: -16px">
                Lead Form Link
              </div>
              <div class="lead-form-sub-heading">
                Copy this link to your website and social media to instantly start receiving leads.
              </div>
              <div class="lead-form-list">
                <div class="lead-form-list-point">
                  <div class="lead-form-list-container icon-container">
                    <font-awesome-icon class="lead-form-list-icon" :icon="['fas', 'copy']" />
                  </div>
                  <div class="lead-form-list-container text-container">
                    <div class="lead-form-list-text">
                      Copy and paste the link on your website, social media profiles, or email
                      campaigns
                    </div>
                  </div>
                </div>
                <div class="lead-form-list-point">
                  <div class="lead-form-list-container icon-container">
                    <font-awesome-icon class="lead-form-list-icon" icon="link" />
                  </div>
                  <div class="lead-form-list-container text-container">
                    <div class="lead-form-list-text">
                      Potential customer used the link to fill out the form will automatically show
                      up in your pipeline
                    </div>
                  </div>
                </div>
                <div class="lead-form-list-point">
                  <div class="lead-form-list-container icon-container">
                    <font-awesome-icon class="lead-form-list-icon" icon="lightbulb-on" />
                  </div>
                  <div class="lead-form-list-container text-container">
                    <div class="lead-form-list-text">
                      You are notified of every new lead, and they are ready to be quoted
                      automatically -
                      <span class="bold">no more data entry</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lead-form-sub-heading lead-form-link" @click="previewForm">
                Preview the form
                <font-awesome-icon icon="arrow-up-right-from-square" />
              </div>
            </div>
          </div>
          <div class="lead-form-card-content-right-container">
            <div class="lead-form-card-content-right">
              <img class="lead-form-card-bg-image" src="../../assets/lead-form-graphic.png" />
              <img
                class="lead-form-card-bg-image-mobile"
                src="../../assets/lead-form-graphic-mobile.png"
              />
              <div class="lead-form-card-right-content">
                <div class="lead-form-card-right-content-text">
                  Copy the link & generate leads anywhere!
                </div>
                <div class="lead-form-card-right-content-link">
                  <div class="lead-form-card-right-content-link-text">{{ formLinkDisplay }}</div>
                  <div class="lead-form-card-right-content-link-text-mobile">
                    {{ $f.truncate(formLinkDisplay, 30) }}
                  </div>
                  <div
                    class="lead-form-card-right-content-link-button leading-tight"
                    @click="copyFormLink"
                  >
                    <font-awesome-icon icon="copy" style="padding-right: 4px" />
                    <span>Copy link</span>
                  </div>
                </div>
                <div class="flex justify-end items-center w-full relative -right-[4.5rem] top-2">
                  <div class="width-[150px] flex flex-col items-center gap-2">
                    <img ref="qrcode" class="size-[100px] rounded-sm" />

                    <Btn
                      unstyled
                      class="bg-level-yellow rounded-sm text-pitch-black py-2 px-3 font-medium hover:bg-level-yellow/80"
                      size="lg"
                      :action="downloadQr"
                    >
                      <font-awesome-icon icon="arrow-down-to-bracket" />
                      Save your QR code...
                    </Btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import QrCodeWithLogo from 'qrcode-with-logos'
import BolsterIcon from '@/assets/logos/Bolster_Icon_Fill_Yellow.png'

export default {
  mixins: [PageMixin],
  mounted() {
    this.generateQR()
  },
  data() {
    return {
      startingTab: 'Your lead form'
    }
  },
  computed: {
    companyId() {
      return this.$store.state.session.company.company_id
    },
    genericFormLink() {
      return `${import.meta.env.VITE_FE_PROTOCOL}${import.meta.env.VITE_FE_BASE}/form/${this.companyId}`
    },
    formLinkDisplay() {
      if (this.genericFormLink.includes('https://')) return this.genericFormLink.substring(8)
      return this.genericFormLink
    }
  },
  watch: {
    genericFormLink(newLink, oldLink) {
      if (newLink !== oldLink) {
        this.generateQR()
      }
    }
  },
  methods: {
    downloadQr() {
      const link = document.createElement('a')
      link.href = this.$refs.qrcode.src

      // Set the download attribute with a default filename
      link.download = 'LeadFormQRCode.jpg'

      // Trigger the download by clicking the link
      link.click()
    },
    generateQR() {
      new QrCodeWithLogo({
        image: this.$refs.qrcode,
        content: this.genericFormLink,
        width: 500,
        logo: {
          src: BolsterIcon
        }
      })
    },
    previewForm() {
      return window.open(this.genericFormLink, '_blank')
    },
    copyFormLink() {
      this.$store.dispatch('copyToClipboard', this.genericFormLink)
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.lead-form-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1435px) {
    overflow-y: scroll;
  }

  @media (max-width: 768px) {
    padding: 0px;
    justify-content: center;
  }

  @media (max-width: 480px) {
    margin-bottom: 50px;
  }

  .lead-form-card {
    width: 100%;
    height: fit-content;
    background-color: white;
    border-radius: 16px;
    box-shadow:
      0px 6px 15px -2px rgba(16, 24, 40, 0.08),
      0px 6px 15px -2px rgba(16, 24, 40, 0.08);
    max-width: 100%;

    @media (max-width: 1435px) {
      max-width: 600px;
    }

    @media (max-width: 480px) {
      border-radius: 0px;
    }

    .lead-form-card-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;

      @media (max-width: 1435px) {
        flex-direction: column;
        align-items: center;
      }

      .lead-form-card-content-left-container {
        width: 100%;
        height: 100%;
        padding: 24px 30px;
        .lead-form-card-content-left {
          width: 100%;
          max-width: 540px;
          .lead-form-sub-heading {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px;
          }
          .lead-form-link {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          .highlight {
            background-color: #fbff00;
          }
          .lead-form-heading {
            font-size: 30px;
            width: fit-content;
            line-height: 125%;
            padding: 8px 16px;
            margin-bottom: 16px;
          }
          .bold {
            font-weight: bold;
          }
          .lead-form-list {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            .lead-form-list-point {
              display: flex;
              flex-direction: row;
              margin-bottom: 12px;

              .lead-form-list-container {
                display: flex;
                justify-content: center;
                align-items: center;
                .lead-form-list-icon {
                  font-size: 30px;
                }
              }
              .icon-container {
                text-align: center;
                padding: 10px;
              }
              .text-container {
                margin-left: 12px;
              }

              .lead-form-list-text {
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .lead-form-card-content-right-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .lead-form-card-content-right {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          position: relative;

          .lead-form-card-bg-image {
            width: 100%;
            height: 100%;
            display: block;
            border-bottom-right-radius: 16px;
            border-top-right-radius: 16px;

            @media (max-width: 1435px) {
              border-bottom-left-radius: 16px;
              border-top-right-radius: 0px;
            }

            @media (max-width: 480px) {
              display: none;
            }
          }

          .lead-form-card-bg-image-mobile {
            display: none;
            width: 100%;
            max-width: 598px;
            @media (max-width: 480px) {
              display: block;
            }
          }

          .lead-form-card-right-content {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            text-align: center;
            top: 48px;

            @media (max-width: 480px) {
              top: 16px;
              padding: 0px 8px;
              width: 100%;
            }
            .lead-form-card-right-content-text {
              color: white;
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 16px;
            }

            .lead-form-card-right-content-link {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              border-radius: 8px;
              border: 1px solid white;
              height: 56px;
              width: 100%;
              max-width: 440px;
              .lead-form-card-right-content-link-text {
                flex: 1;
                padding: 8px 16px;
                font-size: 16px;
                color: #fbff00;
                @media (max-width: 480px) {
                  display: none;
                }
              }
              .lead-form-card-right-content-link-text-mobile {
                display: none;
                flex: 1;
                padding: 8px 16px;
                font-size: 16px;
                color: #fbff00;
                @media (max-width: 480px) {
                  display: inline;
                }
              }
              .lead-form-card-right-content-link-button {
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: #fbff00;
                height: 100%;
                font-size: 20px;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
                font-weight: 500;
                cursor: pointer;
                border-left: 1px solid white;

                &:hover {
                  background-color: #1c1a1b;
                  color: #fbff00;
                }

                &:active {
                  background-color: #495057;
                  color: #fbff00;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
