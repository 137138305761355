<template>
  <Page>
    <!-- Company has access to business accounts has onboarded -->
    <div v-if="!loading">
      <BusinessAccountsDashboard v-if="hasAccess" :status="status" />

      <template v-else>
        <!-- Company does not have access to business Accounts -->
        <container
          class="my-4 py-4"
          v-if="status === 'b' && !isCompanyOnboardedWithoutBusinessAccount"
        >
          <card-section>
            <card :flat="true">
              <div class="flex flex-col items-center w-100 text-center py-5">
                <h3 class="mb-3">Business account not available</h3>
                <p>
                  You do not currently have access to business accounts in your area. <br />
                  Contact support to request access.
                </p>
              </div>
            </card>
          </card-section>
        </container>

        <!-- Company has access to business accounts but has not onboarded -->
        <template v-else-if="status === 'u' || isCompanyOnboardedWithoutBusinessAccount">
          <BusinessAccountsLanding @get-started="toggleOnboarding" />
        </template>
      </template>
    </div>
    <CompanyOnboarding />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import PayfacMixin from '@/components/payments/PayfacMixin'
import CounterpartyMixin from '../../components/payments/counterparty/CounterpartyMixin'
import BusinessAccountsDashboard from '../payments/counterparty/dashboard/BusinessAccountsDashboard.vue'
import CompanyOnboarding from '../onboarding/CompanyOnboarding.vue'
import BusinessAccountsLanding from './BusinessAccountsLanding.vue'
import eventBus from '../../eventBus'

export default {
  name: 'BusinessAccounts',
  mixins: [PageMixin, CounterpartyMixin, PayfacMixin],
  data() {
    return {
      loading: 1,
      status: 'b',
      kycCompleteFlag: 0
    }
  },
  methods: {
    toggleOnboarding() {
      eventBus.$emit('toggleCompanyOnboarding')
    },
    async handleKycCompleted() {
      this.status = await this.getPayeeStatus()

      await this.markOnboardingAsComplete()

      this.kycCompleteFlag = true
    }
  },
  async mounted() {
    this.loading = 1

    if (!this.isPaymentsOnboarded || this.isCompanyOnboardedWithoutBusinessAccount) {
      eventBus.$on('kycCompleted', this.handleKycCompleted)
    }

    this.status = await this.getPayeeStatus()
    this.loading = 0
  },
  computed: {
    hasAccess() {
      return (
        this.status !== 'b' &&
        this.status !== 'u' &&
        (!this.isCompanyOnboardedWithoutBusinessAccount || this.kycCompleteFlag)
      )
    }
  },
  components: {
    BusinessAccountsDashboard,
    CompanyOnboarding,
    BusinessAccountsLanding
  }
}
</script>

<style scoped></style>
