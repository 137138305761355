<template>
  <div
    :style="{ zIndex: zIndexOnOpen }"
    class="absolute flex items-end md:items-center justify-center bg-black/35 w-screen h-screen animate-fadeIn"
    v-show="visible"
  >
    <!-- Modal container -->
    <div
      class="flex flex-col bg-flame-white p-6 w-full md:max-w-[1280px] m-12 h-[98%] md:h-fit rounded-lg animate-pullUpFast"
    >
      <!-- Header -->
      <div
        class="flex flex-row justify-start items-center text-pitch-black font-medium mb-4 *:text-2xl"
      >
        <p>Select a {{ type.replace('_', ' ') }}</p>
      </div>

      <!-- Body -->
      <div class="relative inset-0 h-[600px] overflow-hidden border-t border-b border-surface-200">
        <component
          class="bod"
          ref="body"
          :is="component"
          :multiple="modal?.multiple"
          :filters="filters"
          @selected="(set) => (selected = set)"
        />
      </div>

      <!-- Action buttons -->
      <div class="flex justify-end items-center gap-3 mt-4" style="width: 100%">
        <btn severity="tertiary" size="lg" @click="() => $refs.body.selectAll()">
          <template #icon>
            <font-awesome-icon icon="square-check" />
          </template>
          Select all
        </btn>
        <btn
          severity="tertiary"
          size="lg"
          style="margin-right: auto"
          @click="() => $refs.body.deselectAll()"
        >
          <template #icon>
            <font-awesome-icon icon="square" />
          </template>
          Deselect all
        </btn>
        <btn-group>
          <btn severity="secondary" size="lg" @click="cancel"> Cancel </btn>
          <btn severity="primary-black" size="lg" v-if="selected.length" @click="choose">
            <template #icon>
              <font-awesome-icon icon="check" />
            </template>
            Select {{ selected.length > 1 ? selected.length : '' }}
          </btn>
        </btn-group>
      </div>
    </div>
  </div>
</template>

<script>
// Name the components as TitleType (singular)
import File from '../Files/List.vue'
import Quote from '../bodies/Quotes.vue'
import LeadRotation from '../bodies/LeadRotation.vue'
import eventBus from '../../eventBus'

export default {
  name: 'Selector',
  emits: ['selected'],
  data() {
    return {
      selected: [],
      zIndexOnOpen: 499,
      visible: false
    }
  },
  computed: {
    filters() {
      return this.modal?.filters || {}
    },
    type() {
      return this.modal?.type || 'file'
    },
    listenerId() {
      return this.modal?.listenerId || false
    },
    component() {
      return c.titleCase(this.type)
    }
  },
  methods: {
    cancel() {
      if (this.$refs.body) this.$refs.body.deselectAll()
      this.visible = false
      if (this.listenerId) eventBus.$emit(this.listenerId, [])
    },
    choose() {
      this.$emit('selected', this.selected)
      eventBus.$emit('selected', this.selected)
      if (this.listenerId) eventBus.$emit(this.listenerId, [...this.selected])
      this.visible = false
    },
    open() {
      if (this.$refs.body) this.$refs.body?.deselectAll()
      this.zIndexOnOpen = this.$store.state.modal.topZIndex + 1
      this.visible = true
    }
  },
  components: {
    File,
    Quote,
    LeadRotation
  },
  props: {
    modal: {
      required: true,
      type: Object
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
// @media (min-width: 576px) {
.modal.selector-modal:not(.full) {
  .modal-dialog {
    .modal-content {
      .modal-body {
        // @extend %scrollbar;
        max-height: 70vh;
        height: 70vh;
        overflow-y: hidden !important;
        -webkit-overflow-scrolling: touch;
        padding: 0;
        padding-top: 2em;
      }
    }
  }
}

.modal.selector-modal {
  .modal-body {
    padding-top: 0 !important;

    .grid--floating-bar {
      padding-top: 2em !important;
    }

    .files--outer-container .path {
      top: 5em !important;
    }
  }
}
// }
</style>
