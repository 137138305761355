<template>
  <div class="absolute inset-0 flex flex-col justify-stretch items-stretch h-full">
    <div class="flex flex-col justify-stretch items-stretch h-full w-full overflow-hidden">
      <div class="flex justify-stretch items-stretch basis-full shrink w-full overflow-hidden">
        <template v-if="isQuoteExpired && isInPresentation">
          <container class="pt-5">
            <Warning>
              <template #title>
                <h4>
                  {{ $t('This proposal has expired and is no longer valid') }}
                </h4>
              </template>
              <btn v-if="isClient" class="info lg block" :action="requestRevision">
                <template #icon>
                  <font-awesome-icon icon="comment-question" fixed-width />
                </template>
                {{ $t('Request a new proposal') }}
              </btn>
            </Warning>
          </container>
        </template>

        <template v-else>
          <!-- left -->
          <div class="h-full max-w-fit" v-if="showSidebar">
            <QuotePresentationTOC
              class="mt-14 md:mt-0 !sticky top-0"
              ref="refSidebar"
              :normalized="norm"
              :refId="refId"
              :showSidebar="showSidebar"
            />
          </div>

          <!-- right -->
          <div
            class="h-full basis-full shrink grow flex flex-col items-center overflow-x-hidden overflow-y-auto max-w-full bg-surface-100 relative"
          >
            <!-- hero bg -->

            <div
              v-if="backgroundUrlFinal"
              :class="[
                'absolute inset-0',
                {
                  ' bg-surface-100/50': blendBg === 'light',
                  ' bg-surface-900/50': blendBg === 'dark'
                }
              ]"
              :style="{
                maskImage: `linear-gradient(to bottom, black 20%, transparent 100%)`
              }"
            >
              <div
                :class="[
                  'w-full h-[50%] absolute mix-blend-multiply -z-1',
                  {
                    'opacity-70 filter brightness-75': blendBg === 'dark',
                    'opacity-50': blendBg === 'light'
                  }
                ]"
                :style="{
                  background: `url(${backgroundUrlFinal})`,
                  backgroundSize: `cover`,
                  backgroundRepeat: 'none',
                  backgroundPosition: 'center center',
                  backgroundBlendMode: 'multiply',
                  maskImage: `linear-gradient(to bottom, black 20%, transparent 100%)`
                }"
              ></div>
            </div>

            <PresentationAlter
              tooltip="Change background image"
              v-if="!isInPresentation"
              v-model="backgroundFileId"
              :actions="
                backgroundFileId
                  ? [
                      {
                        icon: 'brightness',
                        tooltip: 'Brighten background',
                        class: {
                          'opacity-50': blendBg !== 'light',
                          'bg-deep-red-400': blendBg === 'light'
                        },
                        action: () => {
                          blendBg = blendBg === 'light' ? 0 : 'light'
                        }
                      },
                      {
                        icon: 'moon',
                        tooltip: 'Darken background',
                        class: {
                          'opacity-50': blendBg !== 'dark',
                          'bg-deep-red-400': blendBg === 'dark'
                        },
                        action: () => {
                          blendBg = blendBg === 'dark' ? 0 : 'dark'
                        }
                      }
                    ]
                  : []
              "
            />

            <div
              class="max-w-[700px] xl:max-w-[1400px] w-full pb-20 flex flex-col items-stretch justify-start gap-16 md:px-12 4xl:px-0"
            >
              <div class="w-full h-full flex-col items-stretch justify-start gap-2 relative">
                <!-- heading-->
                <PresentationHeading
                  v-if="isInPresentation || !isQuotingEngine"
                  :refId="refId"
                  :store="store"
                />

                <!-- badges -->
                <div
                  v-if="badges.length || !isInPresentation"
                  class="flex flex-col gap-2 items-start w-full py-4"
                >
                  <div class="flex items-center justify-center flex-wrap gap-4 col-span-3">
                    <div
                      v-for="(id, index) in badges"
                      :key="index"
                      class="relative rounded-sm group"
                    >
                      <img v-if="id" :src="badgeUrls[index]" class="max-h-20 rounded-sm" />
                      <FileList
                        v-else-if="!isInPresentation"
                        idList
                        startOpen
                        @input="(v) => setBadge(index, ...v)"
                      />

                      <div
                        v-if="!isInPresentation && id"
                        @click.stop.prevent="removeBadge(index)"
                        class="absolute bg-deep-red-400/80 text-surface-0 inset-0 justify-center items-center hidden group-hover:flex cursor-pointer rounded-sm"
                      >
                        <font-awesome-icon icon="trash" />
                      </div>
                    </div>
                    <div
                      v-if="!isInPresentation && (!badges.length || badges.every((b) => b))"
                      @click="addBadge()"
                      class="w-20 h-20 min-w-20 min-h-20 rounded bg-surface-200 hover:bg-blue-print-400 text-surface-700 hover:text-surface-0 text-xl flex justify-center items-center cursor-pointer"
                      v-tooltip="
                        'Add a badge, award or recognition. This button isn\'t visible to your customer'
                      "
                    >
                      <font-awesome-icon icon="plus" />
                    </div>
                    <!--                  <font-awesome-icon icon="trophy" size="2xl" />-->
                    <!--                  <Image-->
                    <!--                    v-for="id in badges"-->
                    <!--                    :key="id"-->
                    <!--                    preview-->
                    <!--                    :src="-->
                    <!--                      c.link(`file/view/${id}`, { max: 200, size: 200 }, c.getStorage('scope'))-->
                    <!--                    "-->
                    <!--                    class="max-h-32"-->
                    <!--                    image-class="rounded-sm mix-blend-multiply"-->
                    <!--                  />-->
                    <!--                  <FileFromId-->
                    <!--                    class="mb-2"-->
                    <!--                    v-for="id in badges"-->
                    <!--                    :fullImage="true"-->
                    <!--                    :key="id"-->
                    <!--                    :id="id"-->
                    <!--                  />-->
                  </div>
                </div>

                <div
                  v-if="!isInPresentation || `${coverLetter ?? processedCoverLetter ?? ''}`.trim()"
                  :class="[
                    'relative w-full',
                    {
                      ' py-4': `${coverLetter ?? ''}`.trim(),
                      '': editCoverLetter
                    }
                  ]"
                >
                  <NotionEditor
                    :class="['z-[499] -ml-[42px]', {}]"
                    :key="`${isInPresentation}-${editCoverLetter}`"
                    @update:modelValue="
                      (v) => {
                        if (!isInPresentation && editCoverLetter) {
                          coverLetter = v
                        }
                      }
                    "
                    :modelValue="
                      isInPresentation || !editCoverLetter ? processedCoverLetter : coverLetter
                    "
                    placeholder="Type or paste content to add a cover letter that will be applied to every project using this theme!"
                    tooltip="Cover letter (theme)"
                    :disabled="isInPresentation || !editCoverLetter"
                  />

                  <PresentationAlter
                    class="z-[499]"
                    position="right"
                    :class="{
                      'pointer-events-none': editCoverLetter
                    }"
                    :show="editCoverLetter"
                    tooltip="Change cover letter that will be applied to every project that uses this theme"
                    v-if="!isInPresentation"
                    :value="false"
                    :actions="[
                      ...(!editCoverLetter
                        ? [
                            {
                              tooltip:
                                'Edit cover letter that will be applied to every project that uses this theme!',
                              icon: 'pencil',
                              action: () => {
                                editCoverLetter = true
                              }
                            }
                          ]
                        : [
                            {
                              tooltip: 'Done editing',
                              icon: 'check',
                              action: () => {
                                editCoverLetter = false
                              }
                            }
                          ])
                    ]"
                  />

                  <div
                    v-if="!`${coverLetter ?? ''}`.trim()"
                    @click.stop.prevent="
                      () => {
                        coverLetter = defaultCoverLetter
                        editCoverLetter = 1
                      }
                    "
                    class="cursor-pointer z-[499] hover:bg-blue-print-400 hover:text-surface-0 rounded-md bg-surface-200 font-light text-surface-900 absolute inset-0 flex justify-center items-center gap-2"
                  >
                    <font-awesome-icon icon="plus" /> Add cover letter
                  </div>
                </div>
                <div
                  class="mt-4 pt-4"
                  :class="{
                    'border-t border-dashed': processedMessage?.length
                  }"
                  v-if="processedMessage?.length"
                  v-html="processedMessage"
                />
              </div>
              <!-- cover letter -->
              <!--              <div-->
              <!--                class="flex flex-col gap-2 px-4 relative"-->
              <!--                v-if="String(processedCoverLetter || processedMessage || '').trim()"-->
              <!--              >-->
              <!--                <div-->
              <!--                  class="mb-2"-->
              <!--                  v-if="processedCoverLetter?.length"-->
              <!--                  v-html="processedCoverLetter"-->
              <!--                />-->
              <!--                <div-->
              <!--                  class="mt-4 pt-4"-->
              <!--                  :class="{-->
              <!--                    'border-t border-dashed': processedMessage?.length-->
              <!--                  }"-->
              <!--                  v-if="processedMessage?.length"-->
              <!--                  v-html="processedMessage"-->
              <!--                />-->
              <!--              </div>-->

              <div v-if="isChangeOrder && changes" class="flex flex-col gap-4 relative">
                <span class="text-2xl font-medium px-2">Changes to approve</span>

                <template v-for="(item, rr) in changes" :key="rr">
                  <div
                    class="flex flex-col justify-start items-start gap-1 px-2 user-select-none select-none"
                  >
                    <a
                      class="flex justify-start items-center gap-2 group cursor-pointer"
                      @click="
                        () => {
                          if (item.parentRefId) goToItem(rr)
                        }
                      "
                    >
                      <font-awesome-icon
                        :icon="
                          item.descriptiveChanges.changed === 'Added'
                            ? 'fas fa-plus-circle'
                            : item.descriptiveChanges.changed === 'Removed'
                              ? 'fas fa-minus-circle'
                              : 'fas fa-circle'
                        "
                        :class="[
                          'text-sm',
                          {
                            'text-deep-red-400 ': item.descriptiveChanges.changed === 'Removed',
                            'text-blue-print-400 ': item.descriptiveChanges.changed !== 'Removed'
                          }
                        ]"
                      />
                      <span class="font-medium leading-tight">{{
                        item.parentRefId ? item.referenceName : 'Project overall'
                      }}</span>
                      <div
                        v-if="item.parentRefId && item.descriptiveChanges.changed !== 'Removed'"
                        class="opacity-0 group-hover:opacity-100 transition-all text-blue-print-400 text-sm"
                      >
                        <font-awesome-icon icon="arrow-down-right" class="" />
                        Take me
                      </div>
                    </a>
                    <div class="flex flex-col gap-1 pl-5 leading-tight">
                      <div
                        v-for="(value, key) in item.descriptiveChanges"
                        class="font-light"
                        :key="key"
                      >
                        <IconText :text="value" />
                      </div>
                    </div>
                  </div>
                </template>
                <!--                <ChangeAudit-->
                <!--                  type="quote"-->
                <!--                  :startOpen="true"-->
                <!--                  :changes="changes"-->
                <!--                />-->
              </div>

              <!-- contents-->
              <div class="flex flex-col gap-2 z-[499]">
                <span class="text-2xl font-medium px-2">Items and selections</span>
                <Selections
                  :store="store"
                  :refId="refId"
                  :isInQuoteEditor="!isInPresentation"
                  :presentationSettings="presentationSettings"
                />
              </div>

              <!-- files -->
              <div v-if="combinedFiles.length" class="flex flex-col gap-2">
                <div class="flex flex-col justify-center col-span-2">
                  <span class="text-2xl font-medium px-2">{{ l('Attached files') }}</span>
                </div>
                <div class="flex gap-4 flex-wrap col-span-3">
                  <FileFromId class="mb-2" v-for="id in combinedFiles" :key="id" :id="id" />
                </div>
              </div>

              <!-- quote notes -->
              <div v-if="notes" class="flex flex-col gap-2">
                <span class="text-2xl font-medium px-2">{{ l('Project notes') }}</span>
                <pre
                  class="whitespace-pre-line text-left text-base font-sans"
                ><Linkify :content="notes" /></pre>
              </div>

              <div class="flex flex-col gap-2">
                <div class="text-2xl font-medium px-2 flex gap-2 items-baseline">
                  {{ l('Project summary') }}
                  <Btn link :action="downloadPdf" size="sm">
                    <font-awesome-icon icon="file-pdf" />
                    Download PDF
                  </Btn>
                </div>
                <!-- price summary -->
                <PriceSummary
                  :changeOrderNotApproved="changeOrderNotApproved"
                  :isInQuoteEditor="!isInPresentation"
                  :presentationSettings="presentationSettings"
                  :mergedSettings="presentationSettings"
                  :normRefId="refId"
                  :quoteId="quote_id"
                  :normalized="norm"
                  class="mx-2 md:mx-0 rounded md:rounded-none"
                />
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- presentation footer -->
      <div
        v-if="!isQuoteExpired"
        class="basis-20 shrink-0 grow-0 w-full border-t-2 border-surface-200"
      >
        <div class="flex flex-row justify-between h-28 w-full p-2">
          <div class="items-center gap-2 hidden xl:flex">
            <!--            <Btn-->
            <!--              severity="tertiary-borderless"-->
            <!--              class="hidden xl:flex items-center gap-2 !px-2 md:px-3"-->
            <!--              :action="toggleSidebar"-->
            <!--            >-->
            <!--              <font-awesome-icon icon="list" fixed-width />-->
            <!--              <span class="text-base hidden md:inline">{{ l('Summary') }}</span>-->
            <!--            </Btn>-->
            <!--            <Btn-->
            <!--              severity="tertiary-borderless"-->
            <!--              class="flex items-center gap-2 !px-2 md:px-3"-->
            <!--              @click="showChat"-->
            <!--            >-->
            <!--              <font-awesome-icon icon="message" fixed-width />-->
            <!--              <span class="text-base hidden md:inline">{{ l('Chat with us') }}</span>-->
            <!--            </Btn>-->
            <!--            <Btn-->
            <!--              severity="tertiary-borderless"-->
            <!--              class="flex items-center gap-2"-->
            <!--              size="xs"-->
            <!--              v-if="-->
            <!--                !isQuoteExpired &&-->
            <!--                canUserApproveQuote &&-->
            <!--                changeOrderNotApproved &&-->
            <!--                !isInQuoteEditor &&-->
            <!--                !isRequestForEstimate-->
            <!--              "-->
            <!--              :disabled="!isReviewed || (!isReviewed && !hasScrolledToLastItem)"-->
            <!--              @click="decline"-->
            <!--            >-->
            <!--              <font-awesome-icon icon="xmark-large" fixed-width />-->
            <!--              <span class="text-base">{{ l('No, thanks') }}</span>-->
            <!--            </Btn>-->

            <!-- Edit toolbar -->
            <PresentationToolbar
              class="hidden md:flex"
              v-if="!isInPresentation"
              v-bind="props"
              :reference="refId"
              :store="store"
              :type="type"
            />
          </div>
          <div class="flex gap-4 items-center flex-row w-full justify-end">
            <div
              class="cursor-pointer flex flex-col gap-x-2 pr-4 border-r border-surface-200 text-right"
            >
              <span class="text-sm font-light text-right leading-tight">
                {{ l('Estimated price') }}
              </span>
              <span v-if="quote_price_gross < 10000000" class="text">
                <span class="text-lg md:text-2xl font-medium text-pitch-black font-header">
                  {{ $c(quote_price_gross) }}
                </span>
              </span>
              <div v-else>
                <span class="text-lg md:text-2xl font-medium text-pitch-black font-header">
                  {{ $c(quote_price_gross) }}
                </span>
              </div>
            </div>
            <div
              class="flex justify-end items-stretch shrink md:mt-0"
              v-if="changeOrderNotApproved && isInPresentation"
            >
              <PresentationApprovalBtn
                v-if="!isRequestForEstimate"
                :reference="refId"
                :hasScrolledToLastItem="hasScrolledToLastItem"
                @bookAndSave="bookAndSave"
                @scrollToItem="goToItem"
              />
              <Btn
                :disabled="requestForEstimateSubmission"
                size="lg"
                :action="handleRequestForEstimate"
                severity="bolster"
                v-else
              >
                {{
                  !requestForEstimateSubmission
                    ? l('Request an estimate')
                    : l('Request for estimate sent')
                }}
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal ref="reviewModal" size="lg" :full="false" :clickAway="true" :expandable="false">
    <template #header>
      <h3>{{ $t('Review all items') }}</h3>
    </template>
    <template #body>
      <ReviewAll
        :presentationSettings="presentationSettings"
        :normalized="norm"
        :parentRefId="refId"
      />
    </template>
  </modal>
</template>

<script setup>
import {
  watch,
  defineProps,
  ref,
  computed,
  onMounted,
  getCurrentInstance,
  onUnmounted,
  nextTick,
  defineAsyncComponent
} from 'vue'
import { useStore } from 'vuex'
import PresentationAlter from '@/components/quote/presentation/PresentationAlter.vue'
PresentationAlter.compatConfig = { MODE: 3 }
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { usePresentationManager } from './usePresentationManager'
import TranslationMixin from '@/mixins/TranslationMixin.js'
import eventBus from '@/eventBus.js'
import { useFileService } from '@/components/composables/UseFileService'
import useCurrencyFilter from '@/components/composables/CurrencyFilter.js'

import PresentationHeading from '@/components/quote/presentation/PresentationHeading.vue'
import Selections from '@/components/quote/presentation/Selections.vue'
import PresentationApprovalBtn from '@/components/quote/presentation/PresentationApprovalBtn.vue'
import ReviewAll from '@/components/quote/review/ReviewAll.vue'
import PresentationToolbar from '@/components/quote/presentation/PresentationToolbar.vue'
import QuotePresentationTOC from '@/components/quote/presentation/QuotePresentationTOC.vue'
import FileList from '@/components/ui/FileList.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PriceSummary from '@/components/quote/presentation/PriceSummary.vue'

const { currencySymbol: $c } = useCurrencyFilter()

const NotionEditor = defineAsyncComponent(
  () => import('@/components/ui/DocumentEditor/NotionEditor.vue')
)
NotionEditor.compatConfig = { MODE: 3 }

defineOptions({
  mixins: [TranslationMixin]
})

const $this = getCurrentInstance().proxy
const props = defineProps(['refId', 'store', 'type'])
const $store = useStore()

const {
  norm,
  quote_state,
  quote_price_gross,
  quote_name,
  quote_id,
  quote_status,
  loading,
  quote_terms,
  quote_time_expired,
  change_order_id,
  plan_file_ids,
  file_ids,
  quote_notes,
  change_order_status,
  change_order_client_has_approved,
  client_id
} = EntityComputedFields.useEntityComputedFields({
  store: props.store,
  refId: props.refId,
  type: props.type
})

const isRequestForEstimate = computed(() => quote_state.value === 'lead')

const {
  goToItem,
  readOnly,
  alwaysIncludedFileIds,
  getChanges,
  isChangeOrder,
  processedCoverLetter,
  coverLetter,
  defaultCoverLetter,
  processedMessage,
  badgeUrls,
  badges,
  backgroundUrlFinal,
  presentationSettings,
  isInPresentation,
  isClient,
  backgroundFileId,
  blendBg
} = usePresentationManager({
  refId: props.refId,
  store: props.store,
  autoSave: true
})

const editCoverLetter = ref(false)
const setBadge = (index, value) => {
  const cr = [...badges.value]
  cr[index] = value
  badges.value = cr
}
const addBadge = () => {
  const cr = [...badges.value]
  cr.push(null)
  badges.value = cr
}
const removeBadge = (index) => {
  const cr = [...badges.value]
  cr.splice(index, 1)
  badges.value = cr
}

const notes = computed(() => c.removeHtml(quote_notes.value))

const isQuoteExpired = computed(
  () =>
    quote_status.value === 'p' && quote_time_expired.value && Date.now() > quote_time_expired.value
)
const changeOrderNotApproved = computed(() => {
  return (
    (change_order_status.value !== 'k' || !change_order_client_has_approved.value) &&
    change_order_status.value !== 'd' &&
    !readOnly.value
  )
})
const requestRevision = () =>
  $store.dispatch('ChangeOrder/requestRevision', {
    id: change_order_id.value,
    message: $this.l('How can we improve this proposal for you?')
  })

const refSidebar = ref(null)
const showSidebar = ref(false)

const isQuotingEngine = computed(() => {
  return $store.state.session.user.user_is_super_user && !$store.state.session.scope.company
})

const changes = ref(null)

watch(
  isChangeOrder,
  async (is, was) => {
    if (is && !was) changes.value = await getChanges()
  },
  { immediate: true }
)

// Event listeners
const togglePresentationToc = (isVisible) => {
  showSidebar.value = isVisible
}

onMounted(() => {
  eventBus.$on('toggle-presentation-sidebar', togglePresentationToc)
})

onUnmounted(() => {
  eventBus.$off('toggle-presentation-sidebar', togglePresentationToc)
})

const combinedFiles = () => {
  return [
    ...c.makeArray(plan_file_ids.value),
    ...c.makeArray(file_ids.value),
    ...c.makeArray(alwaysIncludedFileIds.value)
  ]
}

const downloadPdf = async () => {
  await $this.renderDoc()
  $this.savePDF(`${quote_name.value.replace(/[^0-9a-zA-Z_-]/, '_')}`)
}

const paymentTerms = ref(null)

const hasScrolledToLastItem = ref(false)

const book = async () => {
  let terms = 'No specific terms.'
  let additionalTerms = presentationSettings.value.termsAndConditions || ''
  if (additionalTerms) {
    additionalTerms += '\r\n'
  }
  additionalTerms += quote_terms.value || ''

  const { terms: approvedTerms, signature } = await $store.dispatch(
    'sign/termsAndConditions',
    {
      id: change_order_id.value,
      additionalTerms,
      normalized: norm.value,
      paymentTerms: paymentTerms.value || 'pay',
      presentationSettings: presentationSettings.value,
      parentRefId: props.refId,
      language: $this.l
    },
    { root: true }
  )
  terms = approvedTerms

  try {
    loading.value = 1

    await $store.dispatch('Quote/clearRequestQueue')

    await $store.dispatch('Quote/saveAndBook', {
      refId: props.refId,
      go: false,
      requireChanges: true,
      changeOrderId: change_order_id.value,
      additionalTerms: presentationSettings.value.termsAndConditions,
      termsAccepted: terms,
      getTermsApproval: false,
      language: $this.l,
      signature
    })

    $store.dispatch(
      'alert',
      {
        message: `${$this.l('Thank you for booking')}! ${$this.l('Please wait while we process your approval')}.`
      },
      { root: true }
    )

    await nextTick()

    const quoteId = quote_id.value
    const quoteName = quote_name.value?.replace(/\s+/, '').replace(/[^A-Za-z0-9-]/, '')
    $this.$router.push(`/${quoteId}-${quoteName}/customer/project/progress`)
    await $store.dispatch('removeLoading')
    $store.dispatch('getBaseValues')

    return true
  } catch (err) {
    loading.value = 0
    console.log(err)
    $store.dispatch('alert', {
      message: $this.l('There was an error booking this project. Please try again.'),
      error: 1
    })
    // return this.$store.dispatch('to', `${scopeRoute}/project`);
    return true
  }
}

const { getPreSignedPost } = useFileService()

const uploadToS3 = async () => {
  await $this.renderDoc()
  const fileName = `${change_order_id.value}`
  const key = `${client_id.value}/${quote_id.value}/${fileName}.pdf`
  const file = await $this.doc.output('blob')

  // Get the pre-signed URL
  const { url, fields } = await getPreSignedPost(key)

  // Create FormData and append the fields and file
  const formData = new FormData()
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('file', file)

  // Upload the file to S3
  return await fetch(url, {
    method: 'POST',
    body: formData,
    mode: 'no-cors'
  })
}

const bookAndSave = async () => {
  await book()
  await uploadToS3()
}

const requestForEstimateSubmission = ref(false)
const handleRequestForEstimate = async () => {
  try {
    await $store.dispatch('Quote/requestForEstimate', {
      id: quote_id.value
    })
    $store.dispatch('alert', {
      text: 'Your request for an estimate has been submitted successfully.'
    })
    requestForEstimateSubmission.value = true
  } catch (e) {
    $store.dispatch('alert', {
      type: 'error',
      message: 'Could not submit a request for estimate.'
    })
    requestForEstimateSubmission.value = false
  }
}
</script>
