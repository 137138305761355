<template>
  <div class="main-container relative pointer-events-none group">
    <div
      class="editor-container editor-container_balloon-editor editor-container_include-block-toolbar"
      ref="editorContainerElement"
    >
      <div class="editor-container__editor">
        <div ref="editorElement" class="!pointer-events-auto relative">
          <!--          <div-->
          <!--            v-if="tooltip"-->
          <!--            class="rounded-br-sm rounded-tl-[2px] text-sm animate-fadeIn hidden group-hover:flex absolute top-0 left-0 bg-blue-print-400/80 px-1 py-0.5 text-surface-0 font-bold leading-none"-->
          <!--          >-->
          <!--            {{ tooltip }}-->
          <!--          </div>-->
          <ckeditor
            :key="disabled"
            v-if="editor && config"
            :editor="editor"
            :model-value="modelValue"
            @update:modelValue="(v) => (modelValue = v)"
            :config="config"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
    <FileList ref="fileList" v-show="false" />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, toRefs, defineModel } from 'vue'
import { Ckeditor } from '@ckeditor/ckeditor5-vue'
import FileList from '@/components/ui/FileList.vue'

const fileList = ref(null)

import {
  BalloonEditor,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BlockQuote,
  BlockToolbar,
  Bold,
  CloudServices,
  Code,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageInline,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  Paragraph,
  SpecialCharacters,
  Strikethrough,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Plugin,
  ButtonView
} from 'ckeditor5'

import 'ckeditor5/ckeditor5.css'

class CustomImageUpload extends Plugin {
  init() {
    const editor = this.editor

    // Add a button to the toolbar
    editor.ui.componentFactory.add('customImageUpload', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        label: 'Insert image from Bolster drive, or your device',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path d="M448 80c8.8 0 16 7.2 16 16V415.8l-5-6.5-136-176c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3L202 340.7l-30.5-42.7C167 291.7 159.8 288 152 288s-15 3.7-19.5 10.1l-80 112L48 416.3l0-.3V96c0-8.8 7.2-16 16-16H448zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/>
</svg>`,
        tooltip: true
      })

      // Handle button click
      view.on('execute', async () => {
        if (!fileList.value) {
          console.error('FileList component is not available.')
          return
        }

        try {
          const files = c.makeArray(await fileList.value?.requestFile()) // Call requestFile()

          if (!files.length) return

          files.forEach((file) => {
            const link = c.link(`file/view/${file.file_id}`, {}, true, _.getStorage('scope'))
            editor.model.change((writer) => {
              const imageElement = writer.createElement('imageBlock', {
                src: link
              })
              editor.model.insertContent(imageElement, editor.model.document.selection)
            })
          })
        } catch (error) {
          console.error('Error inserting image:', error)
        }
      })

      return view
    })
  }
}

/**
 * Create a free account with a trial: https://portal.ckeditor.com/checkout?plan=free
 */
const LICENSE_KEY = 'GPL' // or <YOUR_LICENSE_KEY>.

const isLayoutReady = ref(false)
const props = defineProps({
  tooltip: {
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    required: false,
    default: 'Type or paste your content here!'
  }
})

const modelValue = defineModel()

const { disabled } = toRefs(props)

const editor = BalloonEditor

const config = computed(() => {
  if (!isLayoutReady.value) {
    return null
  }

  return {
    toolbar: {
      items: [
        'heading',
        '|',
        'fontSize',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'horizontalLine',
        'link',
        'insertTable',
        'highlight',
        'blockQuote',
        '|',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        '|',
        'customImageUpload'
      ],
      shouldNotGroupWhenFull: false
    },
    plugins: [
      Alignment,
      AutoImage,
      AutoLink,
      Autosave,
      BlockQuote,
      BlockToolbar,
      Bold,
      CloudServices,
      Code,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      Highlight,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      Paragraph,
      SpecialCharacters,
      Strikethrough,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      CustomImageUpload
    ],
    blockToolbar: [
      'customImageUpload',
      '|',
      'fontSize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      '|',
      'link',
      'insertTable',
      '|',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent'
    ],
    fontFamily: {
      supportAllValues: true
    },
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5'
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6'
        }
      ]
    },
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage'
      ]
    },
    licenseKey: LICENSE_KEY,
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    placeholder: props.placeholder,
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    }
  }
})

onMounted(() => {
  isLayoutReady.value = true
})
</script>

<style lang="scss" rel="stylesheet/scss">
@media print {
  body {
    margin: 0 !important;
  }
}

.editor-container_include-block-toolbar {
  margin-left: 42px;
}

.ck.ck-balloon-panel.ck-powered-by-balloon {
  display: none;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable) {
  //@apply rounded-sm;
  //@apply transition-all;
  //@apply hover:ring-4;
  //@apply hover:ring-blue-print-400/80;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  //@apply ring-4;
  //@apply ring-blue-print-400/80;
  @apply border-0;
  @apply shadow-none;
}

.ck.ck-editor__editable.ck-content {
  .image.mr-auto-ck {
    @apply mr-auto;
    margin-left: unset;
  }
  .image.mx-auto-ck {
    @apply mx-auto;
  }
  .image.ml-auto-ck {
    @apply ml-auto;
    margin-right: unset;
  }
}

.ck-body-wrapper {
  z-index: 10000;
}
</style>
