<template>
  <ListItem classes="!mb-0" :condensed="true">
    <div class="grid grid-cols-1 gap-2 md:gap-0 md:grid-cols-5 gap-2 w-full p-4 md:p-0">
      <div v-tooltip="item.text" class="w-full flex flex-col md:flex-row md:items-center">
        <label class="block md:hidden mb-2">Name:</label>
        <Field
          :pt="{ root: '!w-full !min-w-auto lg:!min-w-full lg:!w-full md:border-0' }"
          v-model="item.text"
        />
      </div>

      <div class="col-span-1 flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Assignees:</label>
        <Assignees
          @assign="(assignees) => onAssignment(assignees, item, 'id', updateList)"
          :asField="asField"
          btnClass="!h-10"
          v-model="assignees"
        />
      </div>

      <div class="flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Name:</label>
        <StartDate
          :displayEndDate="true"
          :item="item"
          :asField="asField"
          :input="(date, field) => adjustTaskDateRange(item, date, field)"
        />
      </div>

      <div class="flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Status:</label>
        <Choose
          class="w-full"
          placeholder="Select status"
          btnClass="md:border-0 justify-between w-full md:!rounded-none hover:md:!bg-cement-300"
          v-model="item.item_status"
          @input="handleUpdateStatus"
          :staticSet="
            statuses.map((s) => ({
              text: s.name,
              value: s.status
            }))
          "
        />
      </div>

      <div class="flex flex-col md:flex-row justify-center md:items-center">
        <label class="block md:hidden mb-2">Priority:</label>
        <Choose
          class="w-full"
          placeholder="Set priority"
          btnClass="md:border-0 justify-between w-full md:!rounded-none hover:md:!bg-cement-300"
          v-model="item.priority"
          @input="(value) => handleSaveItemField(value, 'item_priority', item.id)"
          :staticSet="
            priorities.map((s) => ({
              text: s.label,
              value: s.key
            }))
          "
        />
      </div>
    </div>
  </ListItem>
</template>

<script setup>
import { defineProps, toRefs, computed } from 'vue'
import ListItem from './ListItem.vue'
import useTask from '@/components/schedule/Task'
import { priorities, statuses } from '@/components/schedule/Task'
import StartDate from '@/components/schedule/fields/StartDate.vue'
import Assignees from '@/components/schedule/fields/Assignees.vue'
import useBreakpoint from '@/components/composables/Breakpoint'

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

const { item } = toRefs(props)

const assignees = computed(() => item.value.assignees || [])

const { handleSaveItemField, onAssignment, adjustTaskDateRange } = useTask()
const { isMobile, isTablet } = useBreakpoint()

const asField = computed(() => {
  return isTablet.value || isMobile.value
})
</script>
