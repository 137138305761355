import { computed, toValue, watch, ref, onBeforeMount } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import QuoteAddons from '../../../imports/api/schemas/QuoteAddons.js'
import { useStore } from 'vuex'
import _ from '../../../imports/api/Helpers.js'
import AutoCost from '../../../imports/api/AutoCost.js'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'

const {
  getAddonFromObject,
  getAddons,
  getDescField,
  getNameField,
  getPriceField,
  getCostField,
  getEquationField,
  getQuantityField,
  getMarkupField,
  getAddonQtyType,
  addonCanAdoptTargetQuantity,
  auditForAddon
} = QuoteAddons

export default {
  useItemAddons(payload) {
    const {
      refId: rrefId,
      store: rstore,
      type: rtype,
      object: robject,
      autoCommit = false,
      autoload = true
    } = payload

    const mockQty = ref(100)

    const refId = computed(() => toValue(rrefId) ?? 'root')
    const store = computed(() => toValue(rstore))
    const type = computed(() => toValue(rtype))
    const $store = useStore()

    const {
      norm,
      aoAddons,
      oDimensions = {},
      cost_type_is_addon_group = {},
      oMeta,
      cost_type_name,
      cost_type_desc
    } = EntityComputedFields.useEntityComputedFields({
      object: robject,
      refId,
      type,
      store
    })

    const target = robject && robject.value ? robject : computed(() => norm.value[refId.value])

    const optionGroupName = computed({
      get() {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) return target.value.cost_type_name

        return oMeta.value?.optionGroupName
      },
      set(name) {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) cost_type_name.value = name
        else {
          oMeta.value = {
            ...oMeta.value,
            optionGroupName: name
          }
        }
      }
    })

    const optionGroupDesc = computed({
      get() {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) return target.value.cost_type_desc

        return oMeta.value?.optionGroupDesc
      },
      set(name) {
        // for backwards compat
        if (target.value.cost_type_is_addon_group) cost_type_desc.value = name
        else {
          oMeta.value = {
            ...oMeta.value,
            optionGroupDesc: name
          }
        }
      }
    })

    // Allow deep mutations locally
    const addons = ref([..._.imm(aoAddons.value ?? [])])
    // Each addon has a twin in items, even if it isn't saved yet
    const items = ref(addons.value.map(() => ({}))) // start with dummy objs

    const reloadItems = async () => {
      const toFetch = addons.value
        .filter((a) => (a.type && a.id) || a.livePriceRef)
        .map((a) => ({
          id: a.livePriceRef || a.id,
          type: a.livePriceRef ? 'live_price' : a.type === 'assembly' ? 'assembly' : 'cost_type'
        }))

      const list = await getAddons({
        addons: toFetch,
        dispatch: $store.dispatch,
        norm: norm.value,
        company: $store.state.session.company
      })

      const ordered = []
      for (let i = 0; i < addons.value.length; i++) {
        const a = addons.value[i]

        if (a.livePriceRef) {
          const item = list?.live_price?.[a.livePriceRef] ?? items.value[i]
          ordered[i] = {
            ...item,
            ...(item.type === 'assembly' ? { assembly_name: a.name } : { cost_type_name: a.name }),
            ...(item.type === 'assembly' ? { quote_notes: a.desc } : { cost_type_desc: a.desc })
          }
        } else if (a.id && a.type) {
          const item = list[a.type]?.[a.id] ?? items.value[i]
          ordered[i] = {
            ...item,
            ...(item.parent_cost_type_id?.includes('autocost')
              ? {
                  ...(item.type === 'assembly'
                    ? { assembly_name: a.name }
                    : { cost_type_name: a.name }),
                  ...(item.type === 'assembly'
                    ? { quote_notes: a.desc }
                    : { cost_type_desc: a.desc })
                }
              : {})
          }
        } else {
          ordered[i] =
            items.value[i] && Object.keys(items.value[i]).length
              ? items.value[i]
              : addons.value[i].bulk || {}
        }
      }

      items.value = ordered

      reaudit()
    }
    onBeforeMount(() => {
      if (autoload) reloadItems()
    })

    const setProfit = async (index, val) => {
      const selectedItem = items.value[index]
      const method = `${selectedItem.type !== 'assembly' ? 'CostItem' : 'Quote'}/setTargetMarkup`
      const addon = addons.value[index]

      if (val === null) {
        // Unset
        const markup = target.value[getMarkupField(target.value)]

        const {
          // eslint-disable-next-line no-unused-vars
          assembly_markup_percentage_adjustment,
          // eslint-disable-next-line no-unused-vars
          quote_markup_percentage_adjustment,
          // eslint-disable-next-line no-unused-vars
          cost_matrix_markup_net,
          // eslint-disable-next-line no-unused-vars
          cost_item_markup_net_adjusted,
          ...embue
        } = addon.embue

        addons.value[index] = {
          markup,
          hasOverriddenMarkup: false,
          embue: embue
        }
      } else {
        const markup = c.marginToMarkup(val / 100)

        const { explicitChanges } = await $store.dispatch(method, {
          targetMarkup: markup,
          object: { ...items.value[index] }
        })

        addons.value[index] = {
          markup,
          hasOverriddenMarkup: true,
          embue: {
            ...addon.embue,
            ...explicitChanges
          }
        }
      }

      triggerAudit(index)
    }

    const commit = async (saveAll = false) => {
      const copy = getCopyOfTarget()
      // save unsaved items
      const itemsToSave = items.value
        .filter(
          (item) =>
            saveAll ||
            !mockItems.value.find((mi) => mi[getNameField(copy)] === item[getNameField(item)])
        )
        .reduce(
          (acc, item, index) => ({
            ...acc,
            ...(!item[`${item.type === 'assembly' ? 'assembly' : 'cost_type'}_id`]
              ? {
                  [index]: {
                    ...item,
                    ...(addons.value[index].embue ?? {}),
                    [getNameField(item)]: addons.value[index].name,
                    [getDescField(item)]: addons.value[index].desc,
                    [getEquationField(item)]:
                      addons.value[index].equation ?? item[getEquationField(item)],
                    file_ids: addons.value[index].file_id ? [addons.value[index].file_id] : [],
                    addonIndex: index,
                    livePriceRef: addons.value[index].livePriceRef,
                    type: item.type === 'assembly' ? 'assembly' : 'cost_type'
                  }
                }
              : {
                  [index]: {
                    ...item,
                    ...(addons.value[index].embue ?? {}),
                    [getNameField(item)]: addons.value[index].name,
                    [getDescField(item)]: addons.value[index].desc,
                    file_ids: addons.value[index].file_id ? [addons.value[index].file_id] : [],
                    addonIndex: index,
                    type: item.type === 'assembly' ? 'assembly' : 'cost_type'
                  }
                })
          }),
          {}
        )

      let set = []
      if (Object.values(itemsToSave).length) {
        const saveSet = Object.values(itemsToSave).filter(
          (item) => !(item.livePriceRef && !AutoCost.isCraftsmanLaborId(item.livePriceRef))
        )
        ;({ set } = await $store.dispatch('CostItem/partialUpdate', {
          selected: saveSet // will save according to type
        }))

        // set the ids in the list of addons
        const indexes = Object.keys(saveSet)
        for (let i = 0; i < set.length; i++) {
          const index = indexes[i]
          const item = set[i]
          addons.value[item.addonIndex || index].id =
            item[`${item.type === 'assembly' ? 'assembly' : 'cost_type'}_id`] || item.refId
        }
      }

      // then set the addons in the item
      aoAddons.value = _.imm(addons.value)
    }
    if (autoCommit) watch(addons, () => commit())

    const isAssembly = computed(() => type.value === 'assembly')
    const isAddonGroup = computed({
      get: () => !isAssembly.value && cost_type_is_addon_group.value,
      set: (is) => {
        if (!isAssembly.value) cost_type_is_addon_group.value = is
      }
    })

    const mockItems = computed(() => {
      const copy = getCopyOfTarget()

      return [
        {
          ...copy,
          mockName: 'Super duper dollar discount option',
          [getNameField(copy)]: 'Super duper dollar discount option',
          [getDescField(copy)]: '',
          type: 'cost_type'
        },
        {
          ...copy,
          mockName: 'The middle class fancy option',
          [getNameField(copy)]: 'The middle class fancy option',
          [getDescField(copy)]: '',
          type: 'cost_type'
        },
        {
          ...copy,
          mockName: 'Ultra luxury option',
          [getNameField(copy)]: 'Ultra luxury option',
          [getDescField(copy)]: '',
          type: 'cost_type'
        }
      ]
    })
    const setupMockAddons = () => {
      mockItems.value.forEach((item) => addAddonFromItem(item, true))

      // Finishing touches
      addons.value[0].rating = 1
      addons.value[1].rating = 4
      addons.value[2].rating = 3
    }

    const isAddonItem = computed({
      get: () => !!aoAddons.value?.length || !!addons.value?.length || isAddonGroup.value
    })

    const parent = computed(() => norm.value[norm.value[refId.value].parentRefId] ?? {})
    const parentDimensions = computed(() => parent.value?.oDimensions ?? {})
    const parentName = computed(
      () => (parent.value?.assembly_name || parent.value?.quote_name) ?? 'the parent assembly'
    )
    const localDimensions = computed(() => {
      return type.value === 'assembly' ? oDimensions.value : {}
    })
    // Convert an item or assembly into an addon object
    const convertItemToAddon = (item, target, addon = {}) => {
      return getAddonFromObject({
        addon,
        auditedObject: item,
        target,
        mockQty: mockQty.value
      })
    }

    // List methods
    const removeAddon = (index) => addons.value.splice(index, 1) && items.value.splice(index, 1)

    const getCopyOfTarget = () => {
      const copy = _.imm(norm.value[refId.value])

      // unset id and change name
      if (copy.type === 'assembly') {
        copy.assembly_id = null
        copy.assembly_name = `${copy.assembly_name} copy${items.value.filter((o) => o.assembly_name?.includes(copy.assembly_name)).length || ''}`
      } else {
        copy.cost_type_id = null
        copy.cost_type_name = `${copy.cost_type_name} copy${items.value.filter((o) => o.cost_type_name?.includes(copy.cost_type_name)).length || ''}`
      }

      copy.refId = NormalizeUtilities.generateNormalizedRefId(copy.type)

      copy.aoAddons = []

      return copy
    }

    const auditAddons = async (
      itemsLocal = items.value,
      addonsLocal = addons.value,
      targetLocal = target.value
    ) =>
      Promise.all(
        itemsLocal.map((item, index) =>
          auditForAddon({
            target: targetLocal,
            fetched: itemsLocal[index],
            addon: addonsLocal[index],
            dispatch: $store.dispatch,
            norm: norm.value,
            refId: refId.value,
            getters: $store.getters,
            store: store.value,
            mockQty: mockQty.value
          })
        )
      )

    watch(mockQty, () => auditAddons())

    const reaudit = async (indexes = Object.keys(items.value)) => {
      const itemList = []
      const addonList = []

      for (let i = 0; i < indexes.length; i++) {
        itemList.push(items.value[indexes[i]])
        addonList.push(addons.value[indexes[i]])
      }

      const updated = await auditAddons(itemList, addonList)

      const newAddons = [...addons.value]
      const newItems = [...items.value]
      for (let i = 0; i < indexes.length; i++) {
        const index = indexes[i]
        const newAddon = convertItemToAddon(updated[i], target.value, addonList[i])
        newAddons[index] = newAddon
        newItems[index] = updated[i]
      }

      addons.value = newAddons
      items.value = newItems
    }

    let unaudited = []
    const triggerAudit = (index) => {
      if (!unaudited.includes(index)) unaudited.push(index)

      c.throttle(
        () => {
          const un = _.uniq([...unaudited])
          unaudited = []

          reaudit(un)
        },
        { delay: 1000 }
      )
    }

    const addAddonFromItem = async (item, skipAudit = false) => {
      if (!/cost_item|cost_type|assembly/.test(item.type))
        throw new Error('Item must be cost item or assembly only')

      item.refId = NormalizeUtilities.generateNormalizedRefId(item.type)

      let addon = convertItemToAddon(item, target.value)

      if (!skipAudit) {
        const [audited] = await auditAddons([item], [addon])
        // Now redo addon with audited val
        addon = convertItemToAddon(audited, target.value)

        addons.value.push(addon)
        items.value.push(audited)

        return
      }

      addons.value.push(addon)
      items.value.push(item)
    }

    const addAddon = async (type = null, id = null, skipAudit = false) => {
      let fetched = {}
      if ((id === null || type === null) && norm.value[refId.value].type !== 'assembly') {
        // copy target item, change the name
        fetched = getCopyOfTarget()
      } else if (AutoCost.isAutoCostObjectId(id)) {
        if (AutoCost.isCraftsmanLaborId(id)) {
          try {
            await $store.dispatch('ajax', {
              path: 'live_price/saveLivePriceItemAsCostType',
              data: {
                live_price_reference: id
              }
            })
          } catch (e) {
            console.error(e)
          }
          const { set } = await $store.dispatch('CostType/search', {
            filters: { live_price_reference: id },
            limit: 1
          })
          fetched = set[0]
        } else {
          const root = c.getNormalizedRootRefId(norm.value)
          const rootObject = norm.value?.[root]
          const company = $store.state.session.company
          if (!company || !root || !rootObject)
            return $store.dispatch('alert', {
              error: true,
              message: `Quote context not found for ${id}, skipping addon`
            })
          const zipcode = AutoCost.getAutoCostZipcode(company, rootObject)

          try {
            fetched = await $store.dispatch('CostType/getDefaultLivePriceItem', {
              livePriceRef: id,
              company,
              zipcode
            })
          } catch (err) {
            console.error(err)
          }

          if (!fetched) return
        }
      } else {
        ;({ object: fetched } = await $store.dispatch(`${c.titleCase(type)}/fetch`, { type, id }))
      }
      const item =
        fetched.type === 'cost_type'
          ? (await convertFromCostTypeToCostItem(fetched)).object
          : fetched

      return addAddonFromItem(item, skipAudit)
    }

    const duplicateAddon = (index, pos = -1) => {
      const addon = addons.value[index]
      const item = items.value[index]

      // A copy has to be a different item id, so remove the id
      const newItem = {
        ...item,
        [`${item.type}_id`]: null,
        refId: null
      }

      const newAddon = getAddonFromObject({
        addon: {
          ...addon,
          id: null,
          uid: null
        },
        target: target.value,
        auditedObject: newItem,
        mockQty: mockQty.value
      })

      items.value.splice(pos, 0, newItem)
      addons.value.splice(pos, 0, newAddon)
    }

    const convertFromCostTypeToCostItem = (costType) => {
      return $store.dispatch('CostType/convertFromCostTypeToCostItem', costType)
    }

    return {
      isAddonItem,
      parent,
      parentDimensions,
      parentName,
      localDimensions,
      addons,
      items,
      addAddon,
      removeAddon,
      setupMockAddons,
      getNameField,
      getEquationField,
      getQuantityField,
      getPriceField,
      getCostField,
      getAddonFromObject,
      getAddons,
      getDescField,
      getMarkupField,
      getAddonQtyType,
      addonCanAdoptTargetQuantity,
      auditAddons,
      reaudit,
      convertFromCostTypeToCostItem,
      duplicateAddon,
      commit,
      optionGroupName,
      optionGroupDesc,
      mockQty,
      triggerAudit,
      setProfit
    }
  }
}
