<template>
  <modal v-bind="$props" ref="modal" :full="true" :clickAway="true" :expandable="false">
    <template #header>
      <font-awesome-icon icon="file" />
      File
    </template>
    <template #body>
      <card-section>
        <file ref="body" :object="object" :filters="filters" :id="id" />
      </card-section>
    </template>
    <template v-if="bodyIsDirty" #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveAndCloseAndStay"
        ref="saveOrCancel"
        saveText="Save & close"
        :saveIsPrimary="false"
        @cancel="cancelClick"
      >
      </save-or-cancel>
    </template>
  </modal>
</template>

<script>
import File from '../bodies/File.vue'
import ObjectModalMixin from '../modals/ObjectModalMixin'
import SaveOrCancel from '../ui/SaveOrCancel.vue'

export default {
  name: 'ModalFile',
  mixins: [ObjectModalMixin],
  methods: {},
  components: { File, SaveOrCancel },
  props: {
    type: {
      default: 'file'
    },
    go: {
      default: false
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss">
.file-modal {
  .modal-body {
    overflow-x: visible !important;
    padding: 0px !important;
  }
}
</style>
