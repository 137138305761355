<template>
  <div
    class="top-0 h-full shrink-0 flex-col items-stretch justify-start xl:min-w-0 w-fit flex fixed xl:sticky"
    :class="{
      'min-w-[470px]': showSidebarLocal,
      'max-w-0 hidden': !showSidebarLocal
    }"
  >
    <div
      class="md:hidden absolute top-0 w-screen h-full bg-pitch-black/50"
      v-show="showSidebarLocal"
    />
    <div class="quote-presentation-toc--wrapper w-fit h-full static" v-show="showSidebarLocal">
      <div
        class="absolute xl:sticky top-0 left-0 h-full w-[80vw] md:w-[400px] bg-flame-white flex flex-col border-r-2 border-r-surface-200 box-border shadow-lg xl:shadow-none"
      >
        <!--   Header section  -->
        <div class="w-full flex-none">
          <!--    Search bar    -->
          <div class="w-full flex items-center p-4 justify-center bg-flame-white gap-4">
            <!--            <Btn-->
            <!--              v-if="!isQuotingEngine"-->
            <!--              severity="tertiary-borderless"-->
            <!--              tooltip="Show PDF"-->
            <!--              class="grow-0 shrink-0 !size-10"-->
            <!--              size="lg"-->
            <!--              @click="$emit('update-show-summary', 1)"-->
            <!--            >-->
            <!--              <font-awesome-icon icon="file-pdf" size="lg" />-->
            <!--            </Btn>-->
            <IconField iconPosition="left" class="w-full">
              <InputIcon>
                <font-awesome-icon :icon="['far', 'search']" class="text-pitch-black text-lg" />
              </InputIcon>
              <InputText
                v-model="searchQuery"
                ref="searchInput"
                :placeholder="'Search'"
                class="h-full text-base font-light"
                :ptOptions="{ mergeProps: true }"
                :pt="{
                  root: '!h-10 !text-cool-gray-700 !hover:border-pitch-black !hover:bg-pitch-black !hover:text-level-yellow !rounded-md'
                }"
              />
            </IconField>
            <Btn
              tooltip="Hide sidebar"
              class="grow-0 shrink-0 !size-10 flex md:hidden"
              size="lg"
              severity="primary"
              @click="toggleSidebar"
            >
              <font-awesome-icon icon="arrow-left" size="lg" />
            </Btn>
          </div>
        </div>
        <!-- Content section -->
        <div
          class="h-full max-h-fit"
          :class="{
            'top-36': $route.query?.tab === 'Preview' || $route.name === 'Project preview',
            'top-20': $route.path.includes('presentation')
          }"
        >
          <div
            class="w-[80vw] md:w-[400px] py-4 px-3 md:px-6 overflow-y-auto h-full"
            :class="{
              'pb-[180px]': $route.query?.tab === 'Preview',
              'pb-[170px]': $route.name === 'Project preview',
              'pb-[110px]': $route.path.includes('presentation')
            }"
          >
            <RecursiveTOCItem
              v-for="(ref, index) in filteredGroups"
              :key="ref"
              :ref-id="ref"
              :parent-ref-id="ref"
              :normalized="normalized"
              :disabled="[]"
              :active-item="activeItem"
              :open-sections="openSections"
              :index="index"
              store="Quote"
              :search-query="searchQuery"
              :itemIcons="itemIcons"
              :presentationSettings="presentationSettings"
              @update:openSections="updateOpenSections"
              @item-click="scrollTo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import InputIcon from 'primevue/inputicon'
import InputText from 'primevue/inputtext'
import IconField from 'primevue/iconfield'
import RecursiveTOCItem from '@/components/quote/presentation/RecursiveTOCItem.vue'
import useSelections from '@/components/composables/UseSelections'
import UseSelections from '@/components/composables/UseSelections'
import { useStore } from 'vuex'
import { getCurrentInstance, computed } from 'vue'

export default {
  setup(props) {
    const $store = useStore()
    const $this = getCurrentInstance().proxy
    const norm = computed(() => $store.state[props.store].normalized)

    const { shouldShowItem } = UseSelections(norm.value)

    const children = Object.values(norm.value[props.refId]?.aoChildren || [])
    const orderedGroups = _.uniq(children).filter((ref) => shouldShowItem(ref))

    const query = computed(() => $this.searchQuery.toLowerCase())

    const filteredGroups = computed(() => {
      const filterRecursive = (subRef, accumulator) => {
        const subRefName = $this.getNormalizedName(subRef)?.toLowerCase()

        if (subRefName?.includes(query.value)) {
          accumulator.push(subRef)
        }

        if (norm.value[subRef]?.aoChildren?.length > 0) {
          norm.value[subRef].aoChildren.forEach((childRef) => {
            filterRecursive(childRef, accumulator)
          })
        }

        return accumulator
      }

      const filtered = []

      orderedGroups.forEach((subRef) => {
        const matches = filterRecursive(subRef, [])
        if (matches.length > 0) {
          filtered.push(subRef)
        }
      })
      return query.value ? filtered : orderedGroups
    })

    return { groups: orderedGroups, norm: norm.value, normalized: norm.value, filteredGroups }
  },
  name: 'QuotePresentationTOC',
  emits: ['toggle-toc'],
  props: {
    refId: {
      required: true
    },
    store: {
      required: true
    },
    showSidebar: {
      default: true
    }
  },
  data() {
    return {
      tocOpen: true,
      scroller: null,
      scrollCallback: null,
      searchQuery: '',
      activeItem: null,
      openSections: [],
      itemIcons: {
        Optional: {
          icon: 'fas fa-circle-half-stroke'
        },
        Selections: {
          icon: 'swatchbook'
        }
      },
      showSidebarLocal: this.showSidebar
    }
  },
  created() {
    // Listen for the event from Selections
    eventBus.$on('toggle-section-from-selections', ({ ref, isOpen }) => {
      this.$set(this.openSections, ref, isOpen)
    })
    eventBus.$on('toggle-presentation-sidebar', () => {
      this.showSidebarLocal = !this.showSidebarLocal
    })
  },
  components: { IconField, InputText, InputIcon, RecursiveTOCItem },
  computed: {
    selections() {
      return useSelections(this.normalized)
    }
  },
  methods: {
    toggleSection(ref) {
      if (this.openSections.includes(ref)) {
        this.openSections = this.openSections.filter((section) => section !== ref)
      } else if (this.normalized[ref].type === 'assembly') {
        this.openSections.push(ref)
      }

      eventBus.$emit('toggle-section-from-toc', { ref, isOpen: this.isSectionOpen(ref) })
    },
    isSectionOpen(ref) {
      return this.openSections.includes(ref)
    },
    updateOpenSections(newOpenSections) {
      this.openSections = newOpenSections
    },
    async scrollTo(ref) {
      this.activeItem = ref
      eventBus.$emit(`go-to-item`, ref)
    },
    getNormalizedName(ref) {
      return (
        this.normalized[ref]?.item_name ||
        this.normalized[ref]?.assembly_name ||
        this.normalized[ref]?.cost_type_name
      )
    },
    itemType(ref) {
      if (this.normalized[ref].cost_item_is_optional || this.normalized[ref].assembly_is_optional)
        return 'Optional'
      if (
        (this.normalized[ref].aoAddons && this.normalized[ref].aoAddons.length) ||
        this.normalized[ref].cost_type_is_variation_parent
      )
        return 'Selections'
      return null
    },
    toggleSidebar() {
      this.showSidebarLocal = !this.showSidebarLocal
      this.$emit('toggle-toc', this.showSidebarLocal)
    }
  },
  async mounted() {
    // Initialize only the first-level sections as open
    this.openSections = Object.keys(this.normalized).filter((ref) => {
      const item = this.normalized[ref]
      return item.type === 'assembly' && item.asAssemblyPath.length < 2
    })

    await this.$nextTick()
    this.scroller = document.querySelector('.scroll-container--container')
    const anchors = document.getElementsByClassName('toc-anchor')
    const content = document.querySelector('.quote-pres--content')
    const toc = document.querySelector('.quote-presentation--selections-wrapper')
    this.scrollCallback = () => {
      anchors.forEach((anchor) => {
        const distanceToTop = anchor.getBoundingClientRect().top
        const contentTop = content.getBoundingClientRect().top
        const el = document.getElementById(`title-${anchor.id}`)
        const { parent } = el.dataset
        const container = document.querySelector(`#container-${parent}`)
        const classes = el.classList
        // mark toc section as active
        if (distanceToTop < 1000 && distanceToTop > 0) {
          classes.add('active')
          // open if the section is triggered
          if (!container.classList.contains('open')) {
            container.classList.add('open')
          }
        } else {
          classes.remove('active')
        }

        // close if past the section
        if (
          distanceToTop < -1 * (anchor.offsetHeight + 200) &&
          container.classList.contains('open')
        ) {
          container.classList.remove('open')
        }

        if (contentTop < 0) {
          toc.classList.remove('trigger')
        }
      })
    }
    await this.$nextTick()
  },
  beforeUnmount() {
    eventBus.$off('toggle-section-from-selections')
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.quote-presentation-toc--wrapper {
  position: relative;
  .quote-presentation-toc--item {
    &:not(.open) {
      .quote-presentation-toc--item--sub {
        display: none;
      }
    }
    > .quote-presentation-toc--title {
      color: $cool-gray-700;
      font-size: 0.9em;
      font-weight: 500;
      display: flex;
    }
    .quote-presentation-toc--item--sub {
      > .quote-presentation-toc--title {
        color: $cool-gray-600;
        font-size: 0.8em;
        display: flex;
      }

      &.disabled {
        > .quote-presentation-toc--title {
          color: $cool-gray-400 !important;
        }
      }
    }
  }
}
</style>
