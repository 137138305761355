<template>
  <div class="monthly-statements">
    <StatementList
      :statements="statements"
      :loading="loading"
      @view-statement="openStatementDetails"
      @download-statement="downloadStatement"
    />

    <Modal
      v-model:visible="showStatementModal"
      size="lg"
      :scrollable="false"
      :start-open="false"
      :showTopClose="false"
      @close="closeStatementModal"
    >
      <template #body>
        <StatementDetails
          v-if="selectedStatement"
          :statement="selectedStatement"
          :is-printing="isPrinting"
          @print="printStatement"
          @download="downloadStatement"
        />
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/modals/Modal.vue'
import StatementList from './StatementList.vue'
import StatementDetails from './StatementDetails.vue'

const props = defineProps({
  statementSource: {
    type: Number,
    required: true
  }
})

const store = useStore()
const statements = ref([])
const loading = ref(false)
const showStatementModal = ref(false)
const selectedStatement = ref(null)
const isPrinting = ref(false)

const fetchStatements = async () => {
  try {
    loading.value = true
    const response = await store.dispatch('ajax', {
      path: '/account_statement/getStatementList',
      data: {
        statement_source: props.statementSource
      }
    })

    if (response.payload?.status === 'success') {
      statements.value = response.payload.data || []
    } else {
      console.error('Invalid response format or unsuccessful status')
      statements.value = []
    }
  } catch (error) {
    console.error('Error fetching statements:', error)
    statements.value = []
  } finally {
    loading.value = false
  }
}

const fetchStatementByExternalId = async (externalId) => {
  try {
    loading.value = true
    const response = await store.dispatch('ajax', {
      path: '/account_statement/getStatement',
      data: {
        external_id: externalId
      }
    })

    if (response.payload?.status === 'success') {
      selectedStatement.value = response.payload.data || []
    } else {
      console.error('Invalid response format or unsuccessful status')
      statements.value = []
    }
  } catch (error) {
    console.error('Error fetching statements:', error)
    statements.value = []
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchStatements()
})

const openStatementDetails = async (externalId) => {
  await fetchStatementByExternalId(externalId)
  showStatementModal.value = true
}

const closeStatementModal = () => {
  showStatementModal.value = false
}

const downloadStatement = async (statement) => {
  try {
    loading.value = true

    if (!selectedStatement.value || selectedStatement.value.id !== statement.id) {
      await fetchStatementByExternalId(statement.external_id)
    }

    showStatementModal.value = true
    await new Promise((resolve) => setTimeout(resolve, 500))

    window.print()

    showStatementModal.value = false
  } catch (error) {
    console.error('Error downloading statement:', error)
  } finally {
    loading.value = false
  }
}

const printStatement = () => {
  isPrinting.value = true
  window.print()
  setTimeout(() => {
    isPrinting.value = false
  }, 1000)
}
</script>
