<script setup>
import { ref, computed, defineEmits, defineExpose, watch } from 'vue'
import EntityList from '@/components/Sheets/quote/list/EntityList.vue'
import { useStore } from 'vuex'
import _ from '../../../imports/api/Helpers.js'
import moment from 'moment'
import User from '../../../imports/api/schemas/User.js'

const $store = useStore()

const emit = defineEmits(['isDirty'])
const refEntityList = ref(null)

const editingItem = ref(null)
const refModal = ref(null)

const loading = ref(0)

const itemEditorProps = ref(null)

// const modalChanges = ref({})
const editItem = async (id, type = 'cost_type') => {
  loading.value = 1
  deselectAll()
  const store = _.titleCase(type)
  let { object } = await $store.dispatch(`${store}/fetch`, {
    id
  })
  if (object.cost_type_is_parent) {
    loading.value = 0
    return
  }
  object = {
    ...object,
    ...(refEntityList.value?.fullChanges?.[id] ?? {})
  }
  editingItem.value = id
  const { refId } = await $store.dispatch(`${store}/selectBlank`, {
    object: object,
    type
  })
  itemEditorProps.value = { store, type, refId }
  setTimeout(() => refModal.value.open())

  setTimeout(() => {
    loading.value = 0
  })
}

const deselectAll = async () => {
  await Promise.all([
    ...Object.keys($store.state.Company.normalized).map((r) =>
      $store.dispatch(`Company/deselect`, { refId: r })
    ),
    ...Object.keys($store.state.User.normalized).map((r) =>
      $store.dispatch(`User/deselect`, { refId: r })
    )
  ])
}

const columns = ref([
  {
    field: 'name',
    mapField: ({ type }) => (type === 'company' ? 'company_name' : 'user_name'),

    formatting: {
      width: 250,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    }
  },

  {
    field: 'metaType',
    title: ' ',
    action: (cellValue, rowData) => {
      editItem(rowData.id, rowData.type)
    },
    actionText: '',
    tooltip: 'Edit...',
    formatting: {
      width: 40,
      align: 'center',
      borders: {
        right: {
          thickness: 1
        },
        left: {
          thickness: 1
        }
      },
      preventDefaultDraw: true,
      draw: ({
        ctx,
        clipTo,
        drawIcon,
        lightGrayTrans,
        // lightGray,
        getRowData
      }) => {
        let icon = 'cube'

        let color = lightGrayTrans
        const { id } = getRowData()
        const obj = { ...refEntityList.value.items[id] }

        icon = 'shop'
        if (obj.type === 'user') {
          icon = 'user'
        }
        drawIcon(ctx, clipTo, icon, color)
        return ctx
      }
    }
  },
  {
    idField: true,
    field: 'id',
    title: 'ID',
    mapField: ({ type }) => (type === 'company' ? 'company_id' : 'user_id')
  },
  {
    title: 'Country',
    mapTo: 'country',
    choose: {
      schema: 'company:country_id'
    },
    field: 'country_id'
  },
  {
    title: 'Email',
    mapField: ({ type }) => (type === 'company' ? 'company_email' : 'user_email')
  },
  {
    title: 'Phone',
    formatting: {
      format: 'phone'
    },
    mapField: ({ type }) => (type === 'company' ? 'company_phone' : 'user_phone')
  },
  {
    title: 'Signup date',
    field: 'signup',
    mapField: ({ type }) => (type === 'company' ? 'company_time_created' : 'user_time_created'),
    formatting: {
      align: 'center',
      format: 'date'
    }
  },
  {
    title: 'Day #',
    computedValue: ({ company_time_created = null, user_time_created = null, signup = null }) => {
      const started = company_time_created ?? user_time_created ?? signup
      return moment().diff(moment(started), 'days')
    },
    formatting: {
      align: 'right',
      format: 'number'
    }
  },
  {
    title: 'Subscription ID',
    field: 'stripe_subscription_id',
    disabled: ({ type }) => type !== 'company'
  },
  {
    title: 'Status',
    field: 'company_status',
    disabled: ({ type }) => type !== 'company'
  },
  {
    title: 'In trial',
    field: 'company_is_in_trial',
    disabled: ({ type }) => type !== 'company'
  },
  {
    title: 'Has onboarded',
    field: 'company_has_onboarded',
    disabled: ({ type }) => type !== 'company'
  },
  {
    title: 'Counterparty status',
    field: 'counterparty_status',
    disabled: ({ type }) => type !== 'company'
  },
  {
    title: '# quotes',
    mapField: ({ type }) => (type === 'company' ? 'company_count_quotes' : '0'),
    formatting: {
      align: 'right',
      format: 'number'
    },
    conditionalFormatting: (val) => {
      if (val < 1)
        return {
          background: c.getCssColor('deep-red-400'),
          color: '#FFFFFF'
        }
      if (val < 3)
        return {
          background: c.getCssColor('orange-500'),
          color: '#000000'
        }

      return {
        background: c.getCssColor('green-500'),
        color: '#ffffff'
      }
    },
    disabled: ({ type }) => type !== 'company'
  },
  { field: 'type', hidden: true }
])

const superHeaders = computed(() => [
  // {
  //   title: 'Materials costing',
  //   icon: 'box-taped',
  //   span: [9, 18],
  //   formatting: {
  //     background: c.getCssColor('materials').replace(/\)$/, ',0.2)')
  //   },
  //   expanded: false
  // },
  // {
  //   title: 'Labor costing',
  //   icon: 'user-helmet-safety',
  //   span: [20, 25],
  //   formatting: {
  //     background: c.getCssColor('labor').replace(/\)$/, ',0.2)')
  //   },
  //   expanded: false
  // },
  // {
  //   title: 'Subcontractor costing',
  //   icon: 'truck-arrow-right',
  //   span: [27, 29],
  //   formatting: {
  //     background: c.getCssColor('subcontractor').replace(/\)$/, ',0.2)')
  //   },
  //   expanded: false
  // }
])

const collapseGroups = {
  sortField: 'signup',
  rootId: null,
  isParent: ({ type }) => type === 'company',
  getParent: ({ company_id }) => company_id,
  isExpanded: () => false,
  parentFormatting: {
    preset: 'heading',
    bold: true
  }
}

// const openParents = ref(['NULL'])
const filters = ref({
  company_is_vendor_count: '<1'
})

const isDirtyHandler = (isDirty) => emit('isDirty', isDirty)
const save = () => refEntityList.value?.save?.()

const create = async (payload = {}) => {
  let chosenType = null
  let isCategory = false
  if (payload?.duplicating) {
    const isAssembly = payload.addedFrom.item.type === 'assembly'

    if (isAssembly) chosenType = 'assembly'
    else chosenType = 'item'

    isCategory = chosenType === 'item' && Object.values(payload.rowItems)?.[0]?.cost_type_is_parent
  } else {
    chosenType = await $store.dispatch('modal/asyncConfirm', {
      choices: [
        {
          title: 'New user',
          icon: 'user',
          desc: 'Create a regular item, that has some sort of cost and price associated to it. You can add items to your estimates or assemblies.',
          value: 'user'
        },
        {
          title: 'New company',
          icon: 'shop',
          desc: 'Create an assembly which is just a templated group of items and other assemblies. You can re-use your assemblies inside of other assemblies, or just add them to new estimates.',
          value: 'company'
        }
      ],
      message: 'Choose what you would like to create'
    })
  }

  if (!chosenType) throw new Error('Aborted') // will trigger entitylist to delete placeholder

  deselectAll()
  switch (chosenType) {
    case 'company':
      await createCompany(payload, isCategory)
      break
    case 'user':
      await createUser(payload)
      break
  }
}

const createCompany = async (payload = {}) => {
  const { rowItems = null, rowIndex: rrow = null } = payload

  let placeholderRow = Object.keys(rowItems ?? {})?.[0] ? +Object.keys(rowItems ?? {})?.[0] : null
  let rowIndex = rrow ?? +(Object.keys(rowItems ?? {})?.[0] ?? refEntityList.value.rows.length) // stick at bottom if not provided

  const { object: embue } = (await $store.dispatch('Company/create', { type: 'company' })) ?? {
    object: null
  }
  if (!embue) return

  const item = {
    country_id: 2,
    currency_id: 2,
    company_name: `Company name ${Date.now()}`,
    company_name_short: `Company name ${Date.now()}`,
    company_default_markup: 1.43,
    company_minimum_quote_margin: 0.2,
    ...embue
  }

  c.throttle(() => deselectAll(), { delay: 250 })
  refEntityList.value.reloadItem(item, placeholderRow, +rowIndex)
}

const createUser = async (payload) => {
  const { rowItems = null, parentId = null, rowIndex: rrow = null } = payload

  const { object: embue } = (await $store.dispatch('User/create', {
    type: 'user',
    embue: {
      company_id: parentId || null
    }
  })) ?? { object: null }
  if (!embue) return

  let placeholderRow = Object.keys(rowItems ?? {})?.[0] ? +Object.keys(rowItems ?? {})?.[0] : null
  let rowIndex = rrow ?? +(Object.keys(rowItems ?? {})?.[0] ?? refEntityList.value.rows.length) // stick at bottom if not provided

  refEntityList.value.reloadItem(embue, +placeholderRow, +rowIndex)
}

const rowProcessor = (row) => {
  let processed = row
  // if (processed.type === 'cost_type') {
  //   ;[{ a: processed }] = Auditing.cascadeDependencies({ a: processed }, 'a')
  // }
  return processed
}

const limit = ref(50)
const expandCollapseGroupHandler = ({ collapseGroupId, rowIndex }) => {
  // get row of parent, add
  fetchCompanyUsers(collapseGroupId, rowIndex)
}

const sort = ref([
  ['company_time_created', 'desc'],
  ['type', 'asc'],
  ['parent_cost_type_id', 'desc'],
  ['assembly_name', 'asc'],
  ['cost_type_name', 'asc']
])

const offsets = ref({})
const lastFetchedOffsets = ref({})
const finishedGroups = ref([])
const awaiting = {}

const fetchCompanyUsers = async (collapseGroupId) => {
  if (collapseGroupId === 'null' || !collapseGroupId || collapseGroupId === 'root') return // don't handle root fetch
  if (finishedGroups.value.includes(collapseGroupId)) return // don't handle finished groups

  if (awaiting[collapseGroupId]) await awaiting[collapseGroupId]

  const offset = (offsets.value[collapseGroupId] ??= 0)

  // Don't search the same set twice
  if (lastFetchedOffsets.value?.[collapseGroupId] === offset) return
  lastFetchedOffsets.value[collapseGroupId] = offset

  const run = async () => {
    const grp = refEntityList.value.refSheet.dataSheets[0].collapseGroups.groups[collapseGroupId]
    refEntityList.value?.refSheet?.setLoadingRow(grp.rows[grp.rows.length - 1])
    const { set } = await $store.dispatch(`User/search`, {
      filters: {
        ...filters.value,
        user_company_ids: `INSET${collapseGroupId}`
      },
      // searchPhrase: sp,
      limit: limit.value,
      offset: offset,
      order: sort.value
    })
    if (set.length < limit.value) finishedGroups.value.push(collapseGroupId)
    offsets.value[collapseGroupId] += set.length

    const rowIndex = grp.rows[grp.rows.length - 1] + 1
    await refEntityList.value.importRows(set, false, rowIndex, true, null, collapseGroupId)
    refEntityList.value?.refSheet?.setLoadingRow(null)
  }

  awaiting[collapseGroupId] = run()

  return awaiting[collapseGroupId]
}

const groups = computed(() =>
  Object.values(refEntityList.value?.refSheet?.dataSheets?.[0]?.collapseGroups?.groups ?? {})
)
const firstVisibleRow = computed(() => refEntityList.value?.refSheet?.visibleRowRange?.[0] ?? -1)
const lastVisibleRow = computed(() => refEntityList.value?.refSheet?.visibleRowRange?.[1] ?? -1)

const visibleExpandedGroups = computed(() => {
  const [fvr, lvr] = [firstVisibleRow.value, lastVisibleRow.value]

  return groups.value.filter((group) => {
    if (!group.id || group.id === null || group.id === 'NULL' || group.id === 'null') return false // don't handle root fetch
    if (group?.rows?.length <= 1) return false // first row is just the parent's row, means it's not expanded

    const lastGroupRow = group?.rows?.[group.rows?.length - 1]

    if (lastGroupRow >= fvr && lastGroupRow <= lvr) {
      return true
    }

    return false
  })
})

watch(visibleExpandedGroups, (groups) => {
  for (let group of groups) {
    fetchCompanyUsers(group.id)
  }
})

const rowOptions = ref([
  ...Object.values(User.actions)
    .filter((act) => act.action && act.title)
    .map((act) => ({
      name: act.title,
      icon: act.icon,
      action: (rows, { sheet }) => {
        const objs = [refEntityList.value?.items?.[sheet.getRowData(rows[0]).id]]
        return $store.dispatch(act.action, {
          objects: objs,
          selected: objs,
          action: act.action,
          scope: $store.state.session.scope
        })
      },
      multiple: act.multiple,
      single: true,
      if: ({ id }) => {
        const item = refEntityList.value?.items?.[id]
        return item && item.type === 'user' && (act.visible?.(item, $store) ?? 1)
      }
    }))
])

defineExpose({
  save,
  refEntityList
})
</script>

<template>
  <EntityList
    ref="refEntityList"
    showCategories
    @isDirty="isDirtyHandler"
    type="company"
    title="Companies and users"
    :filters="filters"
    :rowProcessor="rowProcessor"
    :create="create"
    icon="box-open-full"
    :columns="columns"
    fetchMethod="search"
    :collapseGroups="collapseGroups"
    :superHeaders="superHeaders"
    @expandCollapseGroup="expandCollapseGroupHandler"
    :limit="limit"
    :freeze="1"
    :sort="sort"
    :selectedRowOptions="rowOptions"
  >
    <template #sheetOptionAfter>
      <Btn severity="tertiary" :action="() => create({ rowIndex: 0 })">
        <font-awesome-icon icon="plus" />
        Create
      </Btn>
    </template>
    <template #overlay>
      <Loader :loading="loading" />
    </template>
    <template #after>
      <div class="py-2">
        <Btn
          :action="() => create()"
          unstyled
          size="sm"
          class="min-w-[250px] opacity-80 hover:opacity-100 bg-surface-200 text-surface-500 rounded-md hover:bg-surface-300 hover:text-surface-900 text-sm py-2 font-medium"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
          Create new item
        </Btn>
      </div>
    </template>
  </EntityList>
</template>
