// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'stage_duration',

  skipAudit: true,

  fields: {
    stage_order_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      visible: false
    },
    stage_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'stage',
      visible: false
    },
    quote_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'quote',
      visible: false
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company',
      visible: false
    },
    stage_duration: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      visible: false
    }
  }
}
