<template>
  <div class="h-auto flex flex-col items-end justify-stretch gap-1 grow min-w-fit">
    <Btn
      v-if="!isReadyToApprove"
      severity="info"
      size="2xl"
      :disabled="!!(approvalBtnLoading || loadingItemReviewal)"
      class="w-auto h-auto shrink-0 !py-2 !px-4"
      :action="handleReviewButtonClick"
    >
      <div class="flex justify-center items-center gap-2 leading-none">
        <span class="text-base md:text-base">{{ reviewButtonText }}</span>
        <span
          class="text-xs text-surface-100 font-light md:ml-0"
          v-if="reviewStarted && !hasScrolledToLastItem"
        >
          {{ reviewProgress }}
        </span>
      </div>
      <font-awesome-icon icon="chevron-right" fixed-width />
    </Btn>
    <Btn
      v-else
      severity="info"
      size="2xl"
      :disabled="!!(approvalBtnLoading || loadingItemReviewal)"
      class="h-auto shrink-0 !py-2 !px-4 w-full min-w-64"
      :action="handleReviewButtonClick"
    >
      <div class="flex justify-center items-center gap-2 leading-none">
        <span class="text-base md:text-base">{{ reviewButtonText }}</span>
        <span
          class="text-xs text-surface-100 font-light md:ml-0"
          v-if="reviewStarted && !hasScrolledToLastItem"
        >
          {{ reviewProgress }}
        </span>
      </div>
      <font-awesome-icon icon="chevron-right" fixed-width />
    </Btn>
    <Btn
      link
      unstyled
      v-if="reviewButtonText !== 'Start approval'"
      severity="info"
      :disabled="!!(approvalBtnLoading || loadingItemReviewal)"
      class="py-2 w-full"
      @click="handleReviewButtonClick"
    >
      Skip and approve now
      <font-awesome-icon icon="arrow-right" />
    </Btn>
  </div>
</template>

<script setup>
import { defineProps, computed, onMounted, toRef, defineEmits, ref } from 'vue'
import { useStore } from 'vuex'
import useItemReviewal from '@/components/composables/ItemReviewal'
import useSelections from '@/components/composables/UseSelections'

// emits
const emit = defineEmits(['bookAndSave', 'scrollToItem'])

// props
const props = defineProps({
  reference: {
    type: String,
    required: true
  },
  hasScrolledToLastItem: {
    type: Boolean,
    default: false
  }
})

// refs
const reference = toRef(props, 'reference')
const hasScrolledToLastItem = toRef(props, 'hasScrolledToLastItem')
const approvalBtnLoading = ref(0)

// composables
const $store = useStore()
const {
  loadingItemReviewal,
  getReviewalItems,
  setSession,
  setItemsForReviewal,
  upsertReviewal,
  getNextItemIdForReviewal,
  isReviewed,
  reviewProgress,
  reviewStarted
} = useItemReviewal()

// computed variables
const normalized = computed(() => $store.state.Quote.normalized)
const quote = computed(() => normalized.value[reference.value])
const isReadyToApprove = computed(
  () => (hasScrolledToLastItem.value && reviewStarted.value) || isReviewed.value
)
const reviewButtonText = computed(() => {
  if (isReadyToApprove.value) {
    return 'Approve'
  } else if (reviewStarted.value) {
    return 'Review next option'
  } else {
    return 'Start approval'
  }
})

const { interactiveRefs } = useSelections(normalized.value)
const scrollToItem = () => {
  const lastReviewedItemId = getNextItemIdForReviewal()
  if (lastReviewedItemId && !isReviewed.value) {
    reviewStarted.value = true
    emit('scrollToItem', lastReviewedItemId)
  }
}

// methods
const handleReviewButtonClick = async () => {
  // if action taking place make it so user cannot init action
  if (approvalBtnLoading.value) return

  // is ready to approve so handle approve
  if (isReadyToApprove.value) {
    emit('bookAndSave')
    return
  }

  // reviewal is done
  if (isReviewed.value) return

  // start reviewal process
  if (!reviewStarted.value) {
    reviewStarted.value = true
    scrollToItem()
    return
  }

  approvalBtnLoading.value = 1
  const currentItemIdForReview = getNextItemIdForReviewal()
  // mark the items as reviewed
  await upsertReviewal({
    itemId: currentItemIdForReview,
    isReviewed: true
  })

  // scroll to the next item
  scrollToItem()
  approvalBtnLoading.value = 0
}

onMounted(async () => {
  // set the session data for item reviewal
  setSession({
    changeOrderId: quote.value.change_order_id,
    quoteId: quote.value.quote_id
  })

  // set the items that need to be reviewed
  setItemsForReviewal(interactiveRefs.value)

  // fetch all the current items that have been reviewed
  await getReviewalItems()
})
</script>
