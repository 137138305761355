<template>
  <div class="statement-list">
    <DataTable
      v-if="statements?.length"
      :value="statements"
      stripedRows
      show-gridlines
      paginator
      :rows="20"
      :rowsPerPageOptions="[20, 50, 100, 200]"
      :loading="loading"
      scrollable
      scroll-height="flex"
    >
      <Column
        v-for="col in columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        class="min-w-40 max-w-80 truncate"
        :sortable="col.sortable"
      >
        <template #header v-if="col.hint">
          <div class="order-last ml-auto pl-2" v-tooltip.top="col.hint">
            <font-awesome-icon icon="circle-question" />
          </div>
        </template>
        <template #body="{ data, field }">
          <template v-if="field === 'external_id'">
            <button
              class="text-blue-500 hover:text-blue-700 hover:underline text-left"
              v-tooltip.bottom="'View Statement'"
              @click="$emit('view-statement', data[field])"
            >
              {{ data[field] }}
            </button>
          </template>
          <template v-else-if="getColumnType(field) === 'date'">
            {{ formatDateTime(data[field]) }}
          </template>
          <template v-else-if="getColumnType(field) === 'currency'">
            {{ formatCurrency(data[field]) }}
          </template>
          <template v-else-if="getColumnType(field) === 'text'">
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <Column header="Actions" :exportable="false">
        <template #body="{ data }">
          <div class="flex gap-2 justify-center">
            <button
              class="p-2 hover:bg-surface-100 rounded-full transition-colors"
              v-tooltip.bottom="'Download Statement'"
              @click="$emit('download-statement', data)"
            >
              <font-awesome-icon :icon="['far', 'download']" class="text-surface-600" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
    <div v-else class="flex justify-center items-center">
      <div class="flex flex-col justify-center items-center">
        <font-awesome-icon icon="magnifying-glass" class="text-6xl text-surface-200 mb-8" />
        <h2>No statements found.</h2>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

defineProps({
  statements: {
    type: Array,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
})

defineEmits(['view-statement', 'download-statement'])

const columns = [
  { field: 'external_id', header: 'ID', type: 'text' },
  { field: 'from_time', header: 'From', type: 'date', sortable: true },
  { field: 'to_time', header: 'To', type: 'date', sortable: true },
  { field: 'opening_available_balance', header: 'Opening Balance', type: 'currency' },
  { field: 'closing_available_balance', header: 'Closing Balance', type: 'currency' }
]

const getColumnType = (field) => {
  return columns.find((col) => col.field === field)?.type || ''
}

const formatDateTime = (timestamp) => {
  return new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

const formatCurrency = (amount) => {
  if (typeof amount === 'string') return amount
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount)
}
</script>
