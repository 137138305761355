<template>
  <Page>
    <!-- Warnings -->
    <danger
      class="xs error-card mt-8"
      :flat="true"
      :rounded="false"
      :hideTitle="true"
      :noBorder="true"
      v-if="requirements.counterpartyStatus === 's'"
    >
      <span
        >Your business account has not been verified and therefore you cannot receive payments
        yet.</span
      >
    </danger>

    <CounterpartyDashboardHeader
      type="business_account"
      :merchant="merchant"
      :counterparty="counterparty"
      :bodyLoading="bodyLoading"
      :autoPayouts="autoPayouts"
      :activeTabIndex="activeTabIndex"
      :chargesEnabledForBusinessAccount="chargesEnabledForBusinessAccount"
      @withdraw-money="handleWithdrawMoney"
      @send-to-bank="handleSendToBank"
    />

    <Transition
      enter-active-class="transition-opacity duration-300 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <PayfacDashboardSkeleton v-if="bodyLoading" class="mt-6" />

      <TabView v-else :scrollable="true" v-model:activeIndex="activeTabIndex" class="mt-6">
        <!-- Activities -->
        <TabPanel v-if="!bodyLoading">
          <template #header> Activities </template>
          <CounterpartyActivity
            ref="activity"
            type="business_account"
            :merchant="merchant"
            :counterparty="counterparty"
            :accounts="bankAccounts"
            :owners="owners"
            :balances="balances"
            :balancesLoading="balancesLoading"
            :defaultBalance="defaultBalance"
            :defaultBalanceAccount="defaultBalanceAccount"
            @reload-balances="loadBalances"
            @navigate-to-settings="navigateToSettings"
          />
        </TabPanel>

        <!-- Payment Methods -->
        <TabPanel v-if="!bodyLoading">
          <template #header> Payment Methods </template>
          <PaymentMethods
            :merchant="merchant"
            :accounts="bankAccounts"
            type="business_account"
            @accounts-updated="handleAccountsUpdated"
          />
        </TabPanel>

        <!-- Monthly Statements -->
        <TabPanel v-if="!bodyLoading">
          <template #header> Statements </template>
          <MonthlyStatements :statementSource="defaultBalance?.virtual_account_id" />
        </TabPanel>

        <!-- Required Actions -->
        <TabPanel v-if="!bodyLoading">
          <template #header>
            Required Actions
            <font-awesome-icon
              v-if="badgeRequirementCount"
              :icon="['fas', 'circle-exclamation']"
              class="text-deep-red-500 text-xs self-start ml-1"
            />
          </template>
          <CounterpartyRequirements
            :merchant="merchant"
            :owners="owners"
            :counterparty="counterparty"
            :requirements="businessAccountRequirements"
          />
        </TabPanel>

        <!-- Profile -->
        <TabPanel v-if="!bodyLoading">
          <template #header> Profile </template>
          <CounterpartyProfile :merchant="merchant" />
        </TabPanel>

        <!-- Owners -->
        <TabPanel v-if="!bodyLoading">
          <template #header> Owners </template>
          <CounterpartyOwnerList
            :existingOwners="owners"
            :merchant="merchant"
            :counterparty="counterparty"
          />
        </TabPanel>

        <!-- Settings -->
        <TabPanel v-if="!bodyLoading" ref="settingsTab">
          <template #header> Settings </template>
          <CounterpartySettings :merchant="merchant" :counterparty="counterparty" ref="settings" />
        </TabPanel>
      </TabView>
    </Transition>
  </Page>
</template>

<script>
import CounterpartyDashboardHeader from './CounterpartyDashboardHeader.vue'
import CounterpartyActivity from './CounterpartyActivity.vue'
import CounterpartyRequirements from './CounterpartyRequirements.vue'
import MonthlyStatements from './MonthlyStatements.vue'
import CounterpartyMixin from '../CounterpartyMixin'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import PayfacMixin from '@/components/payments/PayfacMixin'
import CounterpartyProfile from '@/components/payments/counterparty/dashboard/CounterpartyProfile.vue'
import CounterpartyOwnerList from '@/components/payments/counterparty/dashboard/CounterpartyOwnerList.vue'
import CounterpartySettings from '@/components/payments/counterparty/dashboard/CounterpartySettings.vue'
import PayfacDashboardSkeleton from './PayfacDashboardSkeleton.vue'
import PaymentMethods from './PaymentMethods.vue'

export default {
  name: 'CounterpartyDashboard',
  data() {
    return {
      activeTabIndex: 0,
      bodyLoading: false,
      owners: [],
      counterparty: {},
      merchant: {},
      bankAccounts: {},
      requirements: {},
      ownerErrors: [],
      balances: [],
      balancesLoading: true,
      type: 'business_account',
      autoPayouts: false
    }
  },
  /**
   * Init loading the counterparty details
   */
  async created() {
    this.bodyLoading = true
    // first fetch the full payee
    const { payload } = await this.fetchPayee()
    // includes sub merchant data (external details), counterparty (our entity) and bank accounts
    const { merchant, counterparty, bankAccounts, owners } = payload
    this.merchant = merchant
    this.counterparty = counterparty
    this.bankAccounts = bankAccounts
    this.owners = owners
    this.autoPayouts = merchant && merchant.settings.payout_interval !== 'manual'
    // Now fetch and see if there are any requirements to complete merchant KYC
    const { payload: requirements } = await this.payeeRequirements()
    this.requirements = requirements

    // Load balances
    try {
      const { payload: balances } = await this.fetchBalances()
      this.balances = balances
      this.balancesLoading = false
    } catch (e) {
      console.error('Error loading balances:', e)
    }

    this.bodyLoading = false
    this.ownerErrors = this.owners.reduce((acc, owner) => [...acc, ...owner.errors], [])
  },
  computed: {
    /**
     * Tally the requirements to display as badge count
     */
    badgeRequirementCount() {
      const requirements = this.businessAccountRequirements
      if (!requirements) return null

      const paymentCount = requirements.paymentRequirements?.length || 0
      const payoutCount = requirements.payoutRequirements?.length || 0

      return paymentCount + payoutCount || null
    },
    businessAccountRequirements() {
      if (!this.requirements?.paymentRequirements) {
        return this.requirements
      }

      const paymentRequirements = this.requirements.paymentRequirements.filter(
        (requirement) => requirement.message !== 'Add bank account'
      )

      return {
        ...this.requirements,
        paymentRequirements
      }
    },
    /**
     * Get the default balance for business accounts
     */
    defaultBalance() {
      const payload = this.balances.filter(
        (account) => account.virtual_account_type === 'business_account'
      )
      return payload.length > 0 ? payload[0] : {}
    },
    /**
     * Get the default balance account token
     */
    defaultBalanceAccount() {
      return this.defaultBalance ? this.defaultBalance.virtual_account_external_token : ''
    },
    chargesEnabledForBusinessAccount() {
      return (
        this.type === 'business_account' &&
        this.defaultBalance?.virtual_account_type === 'business_account' &&
        this.counterparty?.counterparty_status === '#'
      )
    }
  },
  mixins: [CounterpartyMixin, PayfacMixin],
  methods: {
    /**
     * Handle accounts updated from child component
     */
    handleAccountsUpdated(accounts) {
      this.bankAccounts = accounts
    }
  },
  components: {
    CounterpartySettings,
    CounterpartyOwnerList,
    CounterpartyProfile,
    CounterpartyActivity,
    CounterpartyRequirements,
    MonthlyStatements,
    TabView,
    TabPanel,
    PayfacDashboardSkeleton,
    PaymentMethods,
    CounterpartyDashboardHeader
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-container.full-width {
  .cc-container.container-element {
    max-width: 100% !important;
  }
}

.error-card {
  min-height: 64px;
}

.p-skeleton {
  @apply bg-gray-100;

  &::after {
    @apply bg-gradient-to-r from-transparent via-white/20 to-transparent;
    animation: skeleton-loading 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

// Improve tab transitions
.p-tabview-panels {
  @apply transition-all duration-200;
}

.p-tabview-panel-active {
  @apply animate-fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
