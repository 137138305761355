/**
 * This file is for storing all the client services/features offered in the client portal
 * topDropDown - is for the side panel top context menu options
 * bottomDropDown - is for the side panel bottom context menu options
 *
 * This content was placed here as one central location to change the user type features, services and menu items
 */

const services = ({ store }) => {
  const topDropDown = {
    title: 'Super User',
    links: [
      {
        label: 'Home',
        command: () => store.dispatch('to', '/home')
      }
    ]
  }

  const bottomDropDown = {
    title: `${store.state.session.user.user_fname} ${store.state.session.user.user_lname}`,
    links: [
      {
        label: 'Edit profile',
        command: () => store.dispatch('to', 'profile')
      },
      {
        label: 'Manage users',
        command: () => store.dispatch('to', 'users')
      },
      {
        separator: true
      },
      {
        label: 'Home',
        command: () => store.dispatch('to', '/home')
      },
      {
        label: 'Logout',
        command: () => store.dispatch('logout')
      }
    ]
  }

  const features = [
    {
      title: 'Super Admin',
      icon: 'user-shield',
      links: [
        {
          title: 'Users',
          icon: 'user',
          to: 'users',
          desc: "Manage users, reset passwords, impersonate, resend invites.'",
          starred: true
        },
        {
          title: 'Companies + users',
          tag: 'Beta',
          icon: 'shop',
          to: '/super/companies',
          desc: 'Create and manage companies',
          starred: true
        },
        {
          title: 'Companies (legacy)',
          icon: 'shop',
          to: 'companiesLegacy',
          desc: 'Create and manage companies',
          starred: true
        },
        {
          title: 'Quoting engine',
          icon: 'cubes',
          to: 'quote',
          desc: 'Create and manage quotes',
          starred: false
        },
        {
          title: 'Item & assembly catalog',
          icon: ['fal', 'box-open-full'],
          to: 'items',
          desc: 'Create and manage items',
          starred: false
        },
        {
          title: 'Import Items',
          icon: 'cloud-arrow-up',
          to: 'import',
          desc: 'Manage imports',
          starred: false
        },
        {
          title: 'Dimensions',
          icon: 'drafting-compass',
          to: 'dimensions',
          desc: 'Manage dimensions',
          starred: false
        },
        {
          title: 'Reports',
          icon: 'table',
          to: 'report',
          desc: 'View reports',
          starred: false
        },
        {
          title: 'Stripe product mapping',
          icon: 'link',
          to: 'stripe-products',
          desc: 'Manage stripe product mappings',
          starred: false
        },
        {
          title: 'Products',
          icon: 'shopping-bag',
          to: 'products',
          desc: 'Manage products',
          starred: false
        },
        {
          title: 'System settings',
          icon: 'gear',
          to: 'settings',
          desc: 'Manage system settings',
          starred: false
        }
      ]
    }
  ]
  return {
    features,
    topDropDown,
    bottomDropDown
  }
}

export { services }
