<template>
  <div class="basis-full h-full w-full flex flex-col justify-stretch items-center">
    <PageHeader title="Catalog" full class="px-4 md:px-8 py-4">
      <!-- Refresh -->
      <template #left>
        <Btn v-if="!loading" rounded link class="hidden md:inline-flex text-md" :action="reload">
          <font-awesome-icon icon="arrows-rotate" v-tooltip="'Reload table...'" />
        </Btn>
      </template>

      <template #middle>
        <SelectionToggle
          v-model="viewMode"
          :options="[
            { value: 'list', text: 'Edit', icon: 'table' },
            { value: 'catalog', text: 'Gallery', icon: 'rectangle-vertical-history' }
          ]"
        />
      </template>

      <!-- Search, toggle categories, and create new -->
      <template #right v-if="viewMode === 'list'">
        <Btn
          :severity="isDirty ? 'bolster' : 'secondary'"
          :disabled="!isDirty"
          hotkey="cmd-s"
          size="lg"
          :action="save"
        >
          {{ isDirty ? 'Save changes' : 'No changes to save' }}
        </Btn>
      </template>
    </PageHeader>
    <!-- Main body-->
    <TraverseBody
      :key="loadKey"
      v-if="viewMode === 'catalog'"
      ref="body"
      :startingCompany="$store.state.session.scope.company"
      startingParent="NULL"
    />
    <ItemList
      class="basis-full shrink-1 grow-1 w-full h-full"
      v-else
      ref="body"
      :key="loadKey"
      @isDirty="isDirtyHandler"
    />
  </div>
</template>

<script>
import TraverseBody from '@/components/ui/Traverse/TraverseBody.vue'
import PageMixin from '../../mixins/Page.js'
import PageHeader from '@/components/layout/page/PageHeader.vue'
import ItemList from './ItemList.vue'

export default {
  mixins: [PageMixin],
  data() {
    return {
      loading: 1,
      createToggle: false,
      viewMode: 'list',
      isDirty: false,
      loadKey: 1
    }
  },
  components: {
    PageHeader,
    TraverseBody,
    ItemList
  },
  mounted() {
    this.loading = 0
  },
  computed: {
    isSuper() {
      const session = this.$store.state.session
      return session.authorizedUser && session.authorizedUser.user_is_super_user
    }
  },
  methods: {
    reload() {
      this.loadKey++
    },
    save() {
      return this.$refs.body.save?.()
    },
    isDirtyHandler(isDirty) {
      this.isDirty = isDirty
    }
  }
}
</script>
