<template>
  <div class="flex flex-col w-full bg-gray-50 min-h-full">
    <h2 class="text-base font-medium mb-4 text-center">Transfer funds to external bank account</h2>

    <div class="flex flex-col flex-1 px-6">
      <div class="flex flex-col items-center mb-8">
        <div class="flex justify-center items-baseline">
          <field
            ref="amountField"
            v-model="amount"
            @input="checkMax"
            format="currency"
            placeholder="$ 0.00"
            class="text-4xl border-none p-0 w-40 text-center focus:ring-0 focus:outline-none"
            @blur="handleFieldValidation('amount')"
          />
          <span class="text-3xl text-gray-500">USD</span>
        </div>
        <div class="text-sm text-gray-500 mt-1">
          Available balance: ${{ formatCurrency(sourceBalance?.total_balance) }}
        </div>
        <div v-if="fieldErrors.amount" class="text-xs font-medium text-red-500 mt-1 mb-2 px-6">
          {{ fieldErrors.amount }}
        </div>
      </div>

      <div class="!w-full bg-white rounded-lg mb-4">
        <span class="text-sm text-gray-400 p-2">From</span>
        <choose
          v-model="sourceAccountId"
          @selectedValue="setBalanceSource"
          :full="true"
          :class="{ 'border-red-500': fieldErrors.sourceAccountId }"
          :allowCreate="false"
          :allowDeselect="false"
          :forceNotEmpty="true"
          :staticSet="sourceAccounts"
        >
          <template #default="{ text }">
            <Btn @click="open" size="lg" class="!border-0 w-full" severity="tertiary">
              {{ text }}
              <font-awesome-icon icon="chevron-down" class="ml-2" />
            </Btn>
          </template>
        </choose>
      </div>
      <div
        v-if="fieldErrors.sourceAccountId"
        class="text-xs font-medium text-red-500 mt-1 mb-2 px-6"
      >
        {{ fieldErrors.sourceAccountId }}
      </div>

      <div class="flex flex-col items-center w-full mb-8">
        <p class="text-base font-medium mb-4 text-center">Choose your speed of transfer</p>
        <div class="grid grid-cols-3 gap-4 px-16 w-full">
          <div
            v-for="option in transferPriorityOptions"
            :key="option.value"
            @click="priority = option.value"
            :class="[
              'flex flex-col items-center p-3 border cursor-pointer transition-colors bg-white',
              priority === option.value ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
            ]"
          >
            <font-awesome-icon :icon="option.icon" class="text-4xl mb-2 text-blue-500" />
            <div class="font-medium text-blue-500">{{ option.text }}</div>
            <div class="text-sm text-gray-500">{{ option.days }}</div>
            <div class="text-xs text-gray-400">{{ option.fee }}</div>
          </div>
        </div>
      </div>
      <div v-if="fieldErrors.priority" class="text-xs font-medium text-red-500 mt-1 mb-2 px-6">
        {{ fieldErrors.priority }}
      </div>

      <div class="flex flex-col space-y-2">
        <p class="text-base font-medium text-center mb-2">
          Enter external bank account information below
        </p>

        <card-list>
          <card-list-field class="!bg-white mb-2">
            <span class="text-sm font-bold">Account holder name</span>
            <field
              v-model="bankAccount.accountHolder.fullName"
              placeholder="Full name"
              class="w-full p-3 border rounded-lg"
              :class="{ 'border-red-500': fieldErrors['bankAccount.accountHolder.fullName'] }"
              @blur="handleFieldValidation('accountHolderName')"
            />
            <template #below>
              <div
                v-if="fieldErrors['bankAccount.accountHolder.fullName']"
                class="text-xs font-medium text-red-500 mt-1 text-left"
              >
                {{ fieldErrors['bankAccount.accountHolder.fullName'] }}
              </div>
            </template>
          </card-list-field>

          <card-list-field class="!bg-white mb-2">
            <span class="text-sm font-bold">Account number</span>
            <field
              v-model="bankAccount.accountIdentification.accountNumber"
              placeholder="1234-5678-0987"
              class="w-full p-3 border rounded-lg"
              :class="{
                'border-red-500': fieldErrors['bankAccount.accountIdentification.accountNumber']
              }"
              @blur="handleFieldValidation('accountNumber')"
              @input="numericInputOnly($event, 'accountNumber')"
            />
            <template #below>
              <div
                v-if="fieldErrors['bankAccount.accountIdentification.accountNumber']"
                class="text-xs font-medium text-red-500 mt-1 text-left"
              >
                {{ fieldErrors['bankAccount.accountIdentification.accountNumber'] }}
              </div>
            </template>
          </card-list-field>

          <card-list-field class="!bg-white mb-2">
            <span class="text-sm font-bold">Routing number</span>
            <field
              v-model="bankAccount.accountIdentification.routingNumber"
              placeholder="123456789"
              class="w-full p-3 border rounded-lg"
              :class="{
                'border-red-500': fieldErrors['bankAccount.accountIdentification.routingNumber']
              }"
              @blur="handleFieldValidation('routingNumber')"
              @input="numericInputOnly($event, 'routingNumber')"
            />
            <template #below>
              <div
                v-if="fieldErrors['bankAccount.accountIdentification.routingNumber']"
                class="text-xs font-medium text-red-500 mt-1 text-left"
              >
                {{ fieldErrors['bankAccount.accountIdentification.routingNumber'] }}
              </div>
            </template>
          </card-list-field>

          <card-list-field class="!bg-white">
            <span class="text-sm font-bold">Description</span>
            <field
              v-model="description"
              placeholder="Add a description for this transfer"
              class="w-full p-3 border rounded-lg"
              :class="{ 'border-red-500': fieldErrors.description }"
              @blur="handleFieldValidation('description')"
            />
            <template #below>
              <div
                v-if="fieldErrors.description"
                class="text-xs font-medium text-red-500 mt-1 text-left"
              >
                {{ fieldErrors.description }}
              </div>
            </template>
          </card-list-field>
        </card-list>
      </div>

      <div class="text-right mt-4">
        <btn-group>
          <Btn
            @click="confirmTransfer"
            :disabled="!verified || hasErrors"
            size="lg"
            severity="primary-black"
            class="text-nowrap"
          >
            <span class="hidden md:inline"> Confirm </span>
          </Btn>
          <Btn size="lg" severity="secondary" ref="btn" @click="$emit('close')"> Cancel </Btn>
        </btn-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { z } from 'zod'
import { get } from 'lodash'
import { useFormFields } from '@/composables/useFormFields'

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  accounts: {
    type: Array,
    default: () => []
  },
  balances: {
    type: Array,
    default: () => []
  },
  payoutInterval: {
    type: String,
    default: 'regular'
  }
})

const emit = defineEmits(['successful', 'error', 'selectedValue', 'close'])
const store = useStore()

const amount = ref(0)
const priority = ref(props.payoutInterval)
const description = ref('')

const sourceAccounts = ref([])
const sourceAccount = ref(null)
const sourceBalance = ref(null)
const sourceAccountId = ref(null)
const bankAccount = ref({
  accountHolder: {
    fullName: ''
  },
  accountIdentification: {
    type: '',
    accountNumber: ''
  }
})

const amountField = ref(null)

const formErrors = ref({})

const commonSchema = z.object({
  amount: z.number().positive('Amount must be greater than 0'),
  description: z.string().min(1, 'Description is required'),
  sourceAccountId: z.string().min(1, 'Source account is required').or(z.number()).transform(String)
})

const usAccountSchema = z.object({
  accountHolder: z.object({
    fullName: z.string().min(1, 'Account holder name is required')
  }),
  accountIdentification: z.object({
    routingNumber: z
      .string()
      .min(1, 'Routing number is required')
      .regex(/^\d{9}$/, 'Must be exactly 9 digits')
      .transform((val) => String(val)),
    accountNumber: z
      .string()
      .min(1, 'Account number is required')
      .regex(/^\d{4,17}$/, 'Must be between 4 and 17 digits')
      .transform((val) => String(val))
  })
})

const canadianAccountSchema = z.object({
  accountHolder: z.object({
    fullName: z.string().min(1, 'Account holder name is required')
  }),
  accountIdentification: z.object({
    institutionNumber: z
      .string()
      .min(1, 'Institution number is required')
      .regex(/^\d{3}$/, 'Must be exactly 3 digits')
      .transform((val) => String(val)),
    transitNumber: z
      .string()
      .min(1, 'Transit number is required')
      .regex(/^\d{5}$/, 'Must be exactly 5 digits')
      .transform((val) => String(val)),
    accountNumber: z
      .string()
      .min(1, 'Account number is required')
      .regex(/^\d{7,12}$/, 'Must be between 7 and 12 digits')
      .transform((val) => String(val))
  })
})

const isUS = computed(() => {
  return store.state.session.company.country_abbr.toLowerCase() === 'us'
})

const hasErrors = computed(() => {
  return Object.keys(formErrors.value).length > 0
})

const verified = computed(() => {
  if (
    !amount.value ||
    !sourceAccountId.value ||
    amount.value <= 0 ||
    !bankAccount.value.accountHolder.fullName ||
    !description.value ||
    amount.value > sourceBalance.value?.total_balance
  ) {
    return false
  }
  return true
})

const commonFields = {
  sourceAccountId: {
    name: 'Source Account',
    path: 'sourceAccountId',
    schema: z.string().min(1, 'Source account is required')
  },
  accountHolderName: {
    name: 'Account Holder Name',
    path: 'bankAccount.accountHolder.fullName',
    schema: z.string().min(1, 'Account holder name is required')
  },
  accountType: {
    name: 'Account Type',
    path: 'bankAccount.accountIdentification.accountType',
    schema: z.union([
      z.string().nonempty({ message: 'Please select an account type' }),
      z.number().refine((val) => !isNaN(val), { message: 'Please select an account type' })
    ])
  },
  amount: {
    name: 'Amount',
    path: 'amount',
    schema: z.number().positive('Amount must be greater than 0'),
    customValidation: (value, formData) => {
      if (value > formData.sourceBalance?.total_balance) {
        return `Amount cannot exceed available balance of $${formData.sourceBalance.total_balance}`
      }
      return null
    }
  },
  description: {
    name: 'Description',
    path: 'description',
    schema: z.string().min(1, 'Description is required')
  }
}

const usFields = {
  routingNumber: {
    name: 'Routing Number',
    path: 'bankAccount.accountIdentification.routingNumber',
    schema: z
      .string()
      .min(1, 'Routing number is required')
      .regex(/^\d{9}$/, 'Must be exactly 9 digits')
  },
  accountNumber: {
    name: 'Account Number',
    path: 'bankAccount.accountIdentification.accountNumber',
    schema: z
      .string()
      .min(1, 'Account number is required')
      .regex(/^\d{4,17}$/, 'Must be between 4 and 17 digits')
  },
  priority: {
    name: 'Transfer Priority',
    path: 'priority',
    schema: z.enum(['regular', 'fast', 'instant'], {
      errorMap: () => ({ message: 'Please select a transfer priority' })
    })
  }
}

const canadianFields = {
  institutionNumber: {
    name: 'Institution Number',
    path: 'bankAccount.accountIdentification.institutionNumber',
    schema: z
      .string()
      .min(1, 'Institution number is required')
      .regex(/^\d{3}$/, 'Must be exactly 3 digits')
  },
  transitNumber: {
    name: 'Transit Number',
    path: 'bankAccount.accountIdentification.transitNumber',
    schema: z
      .string()
      .min(1, 'Transit number is required')
      .regex(/^\d{5}$/, 'Must be exactly 5 digits')
  },
  accountNumber: {
    name: 'Account Number',
    path: 'bankAccount.accountIdentification.accountNumber',
    schema: z
      .string()
      .min(1, 'Account number is required')
      .regex(/^\d{7,12}$/, 'Must be between 7 and 12 digits')
  },
  schema: z.enum(['regular', 'fast'], {
    errorMap: () => ({ message: 'Please select a transfer priority' })
  })
}

const fields = computed(() => ({
  ...commonFields,
  ...(isUS.value ? usFields : canadianFields)
}))

const {
  errors: fieldErrors,
  validateField,
  validateAll,
  handleSubmit
} = useFormFields(fields.value)

const handleFieldValidation = (fieldName) => {
  let value = get(
    {
      bankAccount: bankAccount.value,
      amount: amount.value,
      description: description.value,
      sourceAccountId: sourceAccountId.value,
      priority: priority.value,
      sourceBalance: sourceBalance.value
    },
    fields.value[fieldName].path
  )

  if (
    fieldName.includes('Number') ||
    fieldName === 'transitNumber' ||
    fieldName === 'institutionNumber'
  ) {
    value = String(value)
  }

  validateField(fieldName, value, {
    sourceBalance: sourceBalance.value
  })
}

const validateForm = () => {
  const allData = {
    ...bankAccount.value,
    amount: amount.value,
    description: description.value,
    sourceAccountId: sourceAccountId.value,
    priority: priority.value
  }

  const fullSchema = (isUS.value ? usAccountSchema : canadianAccountSchema).merge(commonSchema)
  return validateAll(allData, fullSchema)
}

const submitTransfer = async () => {
  const payoutData = {
    sourceBalanceAccountId: sourceBalance.value?.virtual_account_external_token,
    bankAccount: {
      accountHolder: bankAccount.value.accountHolder,
      accountIdentification: {
        ...bankAccount.value.accountIdentification,
        type: isUS.value ? 'usLocal' : 'caLocal'
      }
    },
    amount: amount.value * 100,
    currency: store.state.session.company.currency_iso,
    description: description.value,
    priority: priority.value
  }

  const { payload } = await store.dispatch('ajax', {
    path: 'payfac/createPayout',
    data: payoutData
  })

  if (payload.error) {
    throw new Error(payload.error.message)
  }

  return payload
}

const confirmTransfer = () => {
  handleSubmit(
    submitTransfer,
    validateForm,
    () => {
      store.dispatch('alert', {
        message: 'Transfer was successfully initiated.',
        error: false
      })
      emit('successful')
    },
    (error) => {
      store.dispatch('alert', {
        message: error.message || 'An error occurred while processing the transfer.',
        error: true
      })
      emit('error', error)
    }
  )
}

const checkMax = (value) => {
  if (sourceBalance.value?.total_balance && value > sourceBalance.value.total_balance) {
    formErrors.value.amount = `Amount cannot exceed available balance of $${sourceBalance.value.total_balance} ${isUS.value ? 'USD' : 'CAD'}`
    amount.value = sourceBalance.value.total_balance
  } else {
    delete formErrors.value.amount
  }
}

const localAccounts = ref([...props.accounts])
const localBalances = ref([...props.balances])

const setBalanceSource = (id) => {
  sourceAccount.value = localAccounts.value.find((account) => account.id === id)
  sourceBalance.value = localBalances.value.find((balance) => balance.virtual_account_id === id)
  sourceAccountId.value = id
}

onMounted(() => {
  nextTick(() => {
    amountField.value?.focus()
  })

  sourceAccounts.value = localAccounts.value.map((a) => ({
    value: a.id,
    text: `${a.name} (**** ${a.last4})`
  }))

  sourceAccounts.value = sourceAccounts.value.filter((ba) => {
    const account = localBalances.value.find((balance) => balance.virtual_account_id === ba.value)
    return account?.virtual_account_type === props.type
  })

  const defaultBalance = localBalances.value.find(
    (balance) => balance.virtual_account_type === props.type
  )
  if (defaultBalance) {
    sourceAccount.value =
      localAccounts.value.find((account) => account.id === defaultBalance.virtual_account_id) ||
      null
    sourceBalance.value = defaultBalance
    sourceAccountId.value = defaultBalance.virtual_account_id
  }
})

watch(
  () => props.accounts,
  (newAccounts) => {
    localAccounts.value = [...newAccounts]
  },
  { deep: true }
)

watch(
  () => props.balances,
  (newBalances) => {
    localBalances.value = [...newBalances]
  },
  { deep: true }
)

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value || 0)
}

const transferPriorityOptions = computed(() => {
  const baseOptions = [
    { value: 'regular', text: 'Regular', icon: 'balloon', days: '2-3 days', fee: '0% fee' },
    { value: 'fast', text: 'Fast', icon: 'car-side', days: '1-2 days', fee: '0.5% fee' },
    { value: 'instant', text: 'Faster', icon: 'rocket', days: '1 day', fee: '1% fee' }
  ]

  return baseOptions
})

const numericInputOnly = (event, field) => {
  const value = typeof event === 'object' ? event.target.value : event
  const numericValue = String(value).replace(/\D/g, '')

  bankAccount.value.accountIdentification[field] = numericValue
}
</script>

<style lang="scss" scoped>
.field {
  &.border-red-500 {
    border-color: rgb(239 68 68);
  }
}

.choose {
  &.border-red-500 {
    border-color: rgb(239 68 68);
  }
}
</style>
