<template>
  <a
    @contextmenu.prevent="topDropDown.toggle"
    @click.prevent="topDropDown.toggle"
    class="w-full h-11 min-h-11 py-2 pl-4 pr-safe-4 flex hover:bg-cement-400 items-center flex-row border-b border-cement-600 text-nowrap overflow-hidden cursor-pointer transition"
    :class="[contextSpacing]"
  >
    <span v-if="!sidePanelStore.collapsed" class="font-medium text-sm">
      {{ $f.truncate(stringTitle, 24) }}
    </span>
    <font-awesome-icon :icon="['far', 'gear']" />
    <ContextMenu ref="topDropDown" :model="contextMenuItems" />
  </a>
</template>

<script setup>
import { computed, ref } from 'vue'
import ContextMenu from 'primevue/contextmenu'
import { useSidePanel } from '@/stores/sidepanel'

const sidePanelStore = useSidePanel()

const props = defineProps({
  contextMenuItems: {
    type: Array
  },
  title: {}
})

const stringTitle = computed(() =>
  typeof props.title === 'function' ? props.title() : props.title
)

const topDropDown = ref()

const contextSpacing = computed(() => {
  return sidePanelStore.collapsed ? 'justify-center' : 'justify-between'
})
</script>
