<template>
  <div
    ref="stickyTop"
    class="relative xl:mb-0 group/visibility"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
    :class="{
      hidden: !showItem && !isInQuoteEditor,
      'bg-surface-0': !isAssembly || smallFormat,
      'bg-transparent !rounded-tl-md': isAssembly && !smallFormat,
      'xl:rounded-tl-md': firstChild
    }"
  >
    <div
      :class="[
        'relative flex flex-col',
        {
          'opacity-20': isInQuoteEditor && !showItem
        }
      ]"
    >
      <div class="border-transparent">
        <div
          class="h-full flex flex-col xl:flex-row justify-start items-stretch xl:flex-row xl:justify-between xl:items-stretch gap-2 flex-wrap xl:flex-nowrap w-full"
          :class="{
            'opacity-30 pointer-events-none': disabled,
            'flex-row flex-nowrap !items-start':
              isAssembly && !optional && !addons?.length && !variation,
            'flex-col': !(isAssembly && !optional && !addons?.length && !variation)
          }"
        >
          <div class="flex-col justify-center w-full xl:w-5/12 gap-2 flex grow max-w-full">
            <!--    Item name    -->
            <div class="w-full flex flex-col justify-center h-full gap-2">
              <div
                class="h-full flex flex-col justify-start items-start text-pitch-black gap-2 xl:mt-0 px-2 xl:px-4"
              >
                <div class="flex justify-start items-center gap-0 transition-all">
                  <font-awesome-icon
                    :icon="'chevron-right'"
                    fixed-width
                    size="sm"
                    :class="[
                      'min-w-3 min-h-3 grow-0 shrink-0 max-w-3 max-h-3 p-2 rounded transition-all ease-in-out hover:text-surface-0 hover:bg-blue-print-400',
                      { 'rotate-90': showAssembly }
                    ]"
                    v-if="isAssembly"
                  />
                  <StringField
                    v-if="
                      (groupName || isInQuoteEditor) &&
                      addons?.length &&
                      included &&
                      !isAddonGroupLike
                    "
                    v-tooltip="
                      !groupName ? 'Not visible for your client unless entered by you' : false
                    "
                    placeholder="Enter a selection name for this group of options, ie: Kitchen faucet etc"
                    :style="
                      isAssembly && !smallFormat
                        ? {
                            fontSize: `${assemblyHeadingSize}pt`
                          }
                        : {}
                    "
                    :class="[
                      'text-lg',
                      {
                        ' font-medium': isAssembly,
                        hidden: !isInteractiveAssembly && isAssembly
                      }
                    ]"
                    multiline
                    :disabled="!isInQuoteEditor"
                    v-model="groupName"
                  />
                  <StringField
                    v-else
                    :style="
                      isAssembly && !smallFormat
                        ? {
                            fontSize: `${assemblyHeadingSize}pt`
                          }
                        : {}
                    "
                    :class="[
                      'text-lg',
                      {
                        ' font-medium': isAssembly,
                        hidden: !isInteractiveAssembly && isAssembly
                      }
                    ]"
                    :disabled="!isInQuoteEditor"
                    multiline
                    v-model="name"
                  />
                </div>

                <div
                  v-if="included && addons?.length && qty && !isAddonGroupLike"
                  :class="[
                    'group relative  flex items-stretch justify-start z-8 cursor-pointer rounded bg-flame-white overflow-hidden transition-all min-h-28 h-fit w-full xl:max-w-[480px]',
                    {
                      ' !border-2 border-blue-print-400/80': included && !isAddonGroupLike,
                      ' !border border-surface-300 hover:border-blue-print-400 hover:ring-2 hover:ring-blue-print-400':
                        !included
                    }
                  ]"
                  @click="$refs.addonList.viewDetails()"
                >
                  <div :class="['basis-1/3 grow-0']" v-if="thumbUrls?.[0]">
                    <div
                      :class="[
                        'overflow-hidden bg-contain bg-no-repeat mix-blend-multiply h-full w-full'
                      ]"
                      :style="{
                        backgroundImage: `url(${thumbUrls?.[0]})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                      }"
                    ></div>
                  </div>

                  <div
                    class="flex flex-col gap-3 items-start justify-between basis-2/3 shrink p-2 px-3 text-center"
                  >
                    <div class="flex flex-col gap-1 justify-start items-stretch">
                      <span
                        class="text-left line-clamp-3 leading-tight overflow-ellipsis font-medium"
                        >{{ selectionName }}</span
                      >

                      <div
                        class="text-left line-clamp-5 leading-tight overflow-ellipsis font-light text-sm text-surface-800"
                        v-if="desc"
                      >
                        {{ desc }}
                      </div>

                      <PresentationProperties
                        class="text-sm text-left"
                        v-if="showFullDescription && object.aoProperties?.length"
                        :object="object"
                      />
                    </div>

                    <Btn
                      v-if="!isAddonGroupLike"
                      severity="tertiary-borderless"
                      class="justify-start items-center !px-0 !bg-transparent !py-0 !h-fit"
                      size="item"
                      :action="() => $refs.addonList.viewDetails()"
                    >
                      <div
                        :class="[
                          ' animate-fadeIn font-light gap-1 items-center text-xs',
                          {
                            'flex xl:hidden xl:group-hover:flex': !included,
                            'flex text-blue-print-400': included
                          }
                        ]"
                      >
                        <font-awesome-icon icon="fas fa-circle" />{{
                          $t('Selected')
                        }}&nbsp;--&nbsp;{{ $t('view details') }}
                        <font-awesome-icon icon="arrow-up-right" />
                      </div>
                      <!--                  <font-awesome-icon icon="arrow-up-right" />-->
                    </Btn>
                  </div>
                </div>
                <div
                  class="flex flex-col justify-start items-start gap-1"
                  v-else-if="
                    (fileUrls?.length || desc || object.aoProperties?.[0]?.[0]) &&
                    (!isAssembly || showAssembly || addons?.length || optional)
                  "
                >
                  <div class="flex gap-3 px-1 flex-wrap">
                    <div
                      class="flex !items-start xl:mt-0 h-28 max-h-28 w-28 max-w-28 xl:max-w-40 xl:h-28 xl:w-40 basis-28 xl:basis-40 shrink rounded-sm overflow-hidden"
                      :class="{ 'filter grayscale': isInQuoteEditor && !showItem }"
                      v-if="fileUrls?.[0] && !isGallery"
                    >
                      <ThumbGallery
                        :disabled="addonsCalcLoading?.loading && isAssembly"
                        :key="refId"
                        :thumbSize="200"
                        :fileIds="fileIds"
                        :thumbClass="[
                          'xl:mt-0 h-28 max-h-28 w-28 max-w-28 xl:max-w-40 xl:h-28 xl:w-40 basis-28 xl:basis-40 rounded-sm'
                        ]"
                        :numVisible="3"
                      />
                    </div>

                    <div
                      :class="[
                        'flex flex-col justify-start items-start gap-0',
                        {
                          'basis-3/5  shrink grow': fileUrls?.[0] && !isGallery,
                          'basis-full shrink-0 ': !(fileUrls?.[0] && !isGallery)
                        }
                      ]"
                    >
                      <StringField
                        ref="ellipsisDiv"
                        :classes="[
                          'block !leading-snug overflow-hidden !font-light -mt-1 -ml-0.5 text-sm',
                          {
                            '!line-clamp-none': showFullDescription,
                            '!line-clamp-6': !showFullDescription
                          }
                        ]"
                        v-if="desc"
                        v-model="desc"
                        :disabled="!isInQuoteEditor"
                        multiline
                      />
                      <PresentationProperties
                        class="text-left text-sm"
                        v-if="showFullDescription && object.aoProperties?.[0]?.[0]"
                        :object="object"
                      />
                      <Btn
                        link
                        class="!pl-1 pr-4 !pt-0.5 !pb-0.5 !text-blue-print-400/70 !bg-transparent"
                        :action="toggleReadMore"
                        size="lg"
                        v-if="shouldShowReadMore"
                      >
                        {{ showFullDescription ? 'Less' : 'More' }}

                        <font-awesome-icon icon="chevron-up" v-if="showFullDescription" />
                        <font-awesome-icon icon="chevron-down" v-if="!showFullDescription" />
                      </Btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--    Image container    -->
          <div
            :class="[
              {
                'xl:pb-4': !isAssembly && (optional || addons?.length || variation),
                'min-w-[60%] xl:min-w-[40%] shrink-0 ': !(
                  isAssembly &&
                  !optional &&
                  !addons?.length &&
                  !variation
                )
              },
              'flex w-full grow shrink flex-col-reverse xl:!flex-col justify-stretch items-stretch gap-4 xl:max-w-[450px] px-2 xl:px-4 !h-auto pt-1'
            ]"
          >
            <!--    Quantity and price (desktop)    -->
            <div
              class="flex justify-stretch items-stretch gap-2 text-lg tabular-nums justify-self-center !h-auto"
              v-if="!isTextBlock && !isGallery"
            >
              <div
                class="w-full flex justify-end items-center"
                v-if="
                  variation &&
                  showVariationSelector &&
                  !Object.keys(object.oVariations?.selectedItem?.variations ?? {}).length &&
                  !isLocked
                "
              >
                <div
                  @click.stop.prevent="openVariations()"
                  class="text-sm text-pitch-black bg-level-yellow px-3 py-1 flex gap-2 items-center cursor-pointer rounded-sm border border-pitch-black"
                >
                  <font-awesome-icon icon="fas fa-circle" />{{ $t('Please make a selection') }}
                  <font-awesome-icon icon="arrow-up-right" />
                </div>
              </div>
              <div
                :class="[
                  'flex justify-end items-center w-full font-light text-surface-600 gap-0 text-base'
                ]"
                v-else
              >
                <!--                <font-awesome-icon icon="far fa-lock" class="text-surface-400" v-if="isLocked" />-->
                <template v-if="optional">
                  <span v-if="included" class=""
                    >{{ $t('Optional') }}
                    <template v-if="showPrice && !showQty">&nbsp;•&nbsp;</template></span
                  >
                  <span v-else class="flex justify-end items-center gap-1"
                    ><font-awesome-icon icon="fas fa-circle" class="text-deep-red-400 text-sm" />{{
                      $t('Not included')
                    }}</span
                  ></template
                >
                <template v-if="(included || !optional) && (showQty || showPrice)">
                  <template v-if="optional && showQty">&nbsp;•&nbsp;</template>
                  <template v-if="showQty && qty"
                    >{{ $f.number(qty) }}&nbsp;<span class="lowercase">{{ units }}</span></template
                  >
                  <template v-if="showPrice && showQty && qty">&nbsp;•&nbsp;</template>
                  <template v-if="showPrice">{{
                    price ? $(price) : qty ? $t('Included') : $t('Not applicable')
                  }}</template>
                </template>
                <template v-else-if="!showPrice && (!showQty || !qty) && !optional">{{
                  qty ? $t('Included') : $t('Not included')
                }}</template>
              </div>
            </div>

            <!-- variations -->
            <div
              v-if="variation && showVariationSelector"
              class="flex flex-col justify-start items-start gap-4"
            >
              <div
                class="flex flex-row justify-end items-start gap-2 cursor-pointer select-none w-full flex-wrap"
                @click.stop.prevent="openVariations()"
              >
                <template
                  v-if="Object.keys(object.oVariations?.selectedItem?.variations ?? {}).length"
                >
                  <div
                    :class="[
                      variationTraitWidth,
                      'flex flex-col justify-center items-end text-center gap-1'
                    ]"
                  >
                    <span
                      :class="[
                        variationTraitWidth,
                        'text-[9pt] font-medium text-center text-surface-600 whitespace-break-spaces h-8 leading-none flex flex-col justify-end items-center'
                      ]"
                    >
                    </span>
                    <div
                      :class="[
                        variationTraitClass,
                        'bg-flame-white rounded-sm border-2 border-blue-print-400 flex justify-center items-center relative'
                      ]"
                    >
                      <div
                        class="text-xs w-full h-full leading-tight flex flex-col justify-center items-center gap-1 font-light !text-pitch-black"
                      >
                        {{ $t('Change selection') }} <font-awesome-icon icon="arrow-up-right" />
                      </div>
                    </div>
                  </div>
                  <template v-for="trait of selectedTraits" :key="trait.name">
                    <div
                      v-if="!trait.hidden"
                      :class="[
                        variationTraitWidth,
                        'flex flex-col justify-center items-end text-center gap-1'
                      ]"
                    >
                      <span
                        :class="[
                          variationTraitWidth,
                          'w-full text-[9pt] font-light text-center text-surface-600 whitespace-break-spaces h-8 leading-none flex flex-col justify-end items-center rounded-sm'
                        ]"
                        >{{ trait.vtypeName }}</span
                      >
                      <div
                        :class="[
                          variationTraitClass,
                          'border-2 border-pitch-black flex justify-center items-center relative overflow-hidden shadow-sm',
                          {
                            'rounded-full': trait.imageShape === 'circle',
                            'rounded-sm': trait.imageShape !== 'circle'
                          }
                        ]"
                      >
                        <img
                          v-if="trait.img"
                          :class="[
                            'h-full w-full',
                            {
                              'object-cover':
                                !trait.backgroundSize || trait.backgroundSize === 'cover',
                              'object-contain': trait.backgroundSize === 'contain',
                              'object-fill': trait.backgroundSize === 'fill'
                            }
                          ]"
                          :src="imgLink(trait.img)"
                        />
                        <div
                          v-else
                          class="text-xs w-full h-full leading-none flex flex-col justify-center items-center"
                        >
                          {{ trait.name }}
                        </div>
                      </div>

                      <div
                        class="text-xs w-full h-full leading-none flex flex-col justify-center items-center max-w-20 whitespace-break-spaces"
                        v-if="trait.img"
                      >
                        {{ trait.name }}
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>

            <!-- compact vertical addons -->

            <template v-if="showOptionality && !isLocked">
              <!--     Optional       -->
              <btn
                unstyled
                class="flex flex-col items-center gap-1 justify-start w-full"
                v-if="optional"
                :action="toggleOptional"
              >
                <div
                  :class="[
                    'flex flex-col text-lg items-stretch justify-start text-center w-full px-3 p-4 rounded hover:ring-2 hover:ring-deep-red-400 transition-all cursor-pointer',
                    {
                      'border border-surface-300 ': included,
                      'border-2 border-deep-red-400': !included
                    }
                  ]"
                >
                  <div class="font-medium text-lg">
                    {{ $t('No, thanks') }}
                  </div>
                  <div class="font-light text-base">
                    <template v-if="included">-{{ $(price) }}</template>
                  </div>
                </div>

                <div
                  v-if="!addons?.length"
                  :class="[
                    'flex flex-col text-lg items-stretch justify-start text-center w-full px-3 p-4 rounded hover:ring-2 hover:ring-blue-print-400 transition-all cursor-pointer',
                    {
                      'border border-surface-300 ': !included,
                      'border-2 border-blue-print-400 ring-2 ring-blue-print-400': included
                    }
                  ]"
                >
                  <div class="font-medium text-xl whitespace-break-spaces leading-tight">
                    {{ name }}
                  </div>
                  <div class="font-light text-base">
                    <template v-if="!included">{{ $(includePrice) }}</template>
                  </div>
                </div>
              </btn>
              <!--        Color input        -->
              <div class="optional-toggle px-2 xl:px-4" v-if="input && colorInput">
                <a @click.stop.prevent="() => $refs.selectionColor.openSelector()">
                  <span class="toggle-title font-medium">
                    Color selection:
                    <span class="font-light" v-if="colorInputData?.name">{{
                      colorInputData.name
                    }}</span></span
                  >
                  <span class="toggle-price">
                    <SelectionColor
                      ref="selectionColor"
                      :allow-deselect="false"
                      class="compact text-sm max-w-48"
                      v-model="colorInputData"
                    />
                  </span>
                </a>
              </div>
              <!--        Text input       -->
              <div
                v-if="input && textInput"
                class="flex flex-col justify-start items-stretch py-2 px-2 xl:px-4"
              >
                <div class="toggle-title text-sm font-medium xl:text-base">
                  {{ textInputMessage }}:
                </div>
                <Field
                  :class="{ 'validate-invalid': !textInputValue }"
                  class="w-full h-12 rounded-sm"
                  v-model="textInputValue"
                  placeholder="Type here..."
                  :validate="{ required: textInputRequired }"
                />
              </div>
            </template>

            <div
              class="optional-toggle w-full flex-col"
              v-if="
                visibility.addonsOrientation !== 'horizontal' &&
                showOptionality &&
                addons &&
                addons.length &&
                !disabled &&
                !isLocked
              "
              :class="{
                disabled,
                'filter grayscale ': isInQuoteEditor && !showItem,
                'bg-flame-white': !isAssembly,
                'bg-transparent': isAssembly
              }"
            >
              <QuotePresentationAddonList
                class=""
                ref="addonList"
                orientation="vertical"
                :title="false"
                :reference="object.refId"
                :optionals="false"
                :dimensions="dimensions"
                :showCurrent="true"
                :upgrades="true"
                :showHeadline="false"
                :value="addons"
              />
            </div>
          </div>
        </div>
        <div v-if="isGallery" class="p-3">
          <ItemGallery
            v-if="itemPictures.length || images.length || fileUrls.length"
            :images="fileUrls"
            :pt="{ indicators: 'hidden xl:flex flex-row justify-center flex-wrap' }"
          />
        </div>
        <!--   Upgrades/Addons   -->
        <div
          class="optional-toggle w-full flex-col py-2 xl:py-6 xl:pl-2"
          v-if="
            visibility.addonsOrientation === 'horizontal' &&
            showOptionality &&
            addons &&
            addons.length &&
            !disabled &&
            !isLocked
          "
          :class="{
            disabled,
            'filter grayscale ': isInQuoteEditor && !showItem,
            'bg-flame-white': !isAssembly,
            'bg-transparent': isAssembly
          }"
        >
          <QuotePresentationAddonList
            class=""
            orientation="horizontal"
            :title="false"
            :reference="object.refId"
            :optionals="false"
            :dimensions="dimensions"
            :showCurrent="true"
            :upgrades="true"
            :showHeadline="false"
            :value="addons"
          />
        </div>

        <!--    Quantity and price (mobile)    -->
      </div>

      <modal
        ref="variations"
        v-if="showOptionality"
        class="modal-mini"
        :size="desc || properties?.length || variationImages?.length ? 'lg' : 'md'"
        :scrollable="true"
        :full="false"
        :clickAway="true"
        :expandable="false"
      >
        <template #header>
          {{ name }}
        </template>
        <template #body>
          <div class="selection-body h-full w-full flex justify-center overflow-hidden">
            <div class="selection-container flex flex-col w-full max-w-screen-xl gap-6">
              <div class="flex flex-col lg:flex-row gap-6 justify-stretch items-start">
                <!--   Left     -->
                <div
                  class="flex flex-col justify-start items-start col-span-2 max-w-full gap-4 basis-1/2"
                >
                  <p v-if="desc" class="font-light">{{ desc }}</p>
                  <div
                    class="mt-4"
                    v-if="properties && properties.length && properties[0] && properties[0][0]"
                  >
                    <span class="font-medium">{{ $t('Features') }}</span>
                    <PresentationProperties :object="object" />
                  </div>
                  <!--    Image gallery    -->
                  <div class="overflow-hidden w-full" v-if="fileUrls.length">
                    <Galleria
                      :images="fileUrls"
                      :numVisible="6"
                      thumbnailsPosition="bottom"
                      hideArrows="true"
                      :pt="{
                        thumbnailContainer: 'w-fit pt-3 ',
                        thumbnailItem: 'overflow-hidden pr-2 w-fit',
                        thumbnailItemContent: 'w-fit'
                      }"
                    />
                  </div>
                </div>
                <!--    Right    -->
                <div class="basis-1/2 grow flex flex-col justify-start items-center">
                  <CostItemVariationSelector
                    v-bind="$props"
                    :reference="refId"
                    :store="store"
                    :deselectOnDestroy="false"
                    :object="object"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <!--     CTA     -->
          <div
            class="flex flex-col lg:flex-row gap-2 justify-end xl:justify-between items-center w-full h-full"
          >
            <span class="text-xl font-medium flex justify-end items-center">
              <span v-if="showQty">
                {{ $f.number(qty) }} <span class="lowercase">{{ units }}</span>
              </span>
              <span v-if="showQty && showPrice" class="text-surface-400">&nbsp;•&nbsp;</span>
              <span v-if="showPrice">{{ $(price, 2) }}</span>
            </span>
            <div class="flex gap-2">
              <Btn severity="tertiary-borderless" size="lg" @click="closeVariations()">
                Cancel
              </Btn>
              <Btn severity="primary" size="lg" @click="closeVariations()">
                Confirm selections
              </Btn>
            </div>
          </div>
        </template>
      </modal>
    </div>
    <!--  Hidden item banner  -->
    <div
      class="absolute text-pitch-black inset-0 flex items-center justify-center"
      :class="{
        'block !opacity-100': isInQuoteEditor && !showItem,
        hidden: !isInQuoteEditor || showItem
      }"
    >
      <p class="bg-surface-900 text-flame-white px-8 py-4 shadow rounded">
        <font-awesome-icon :icon="['fas', 'eye-slash']" class="mr-1" />
        Item hidden from the client
      </p>
    </div>
    <!-- Visibility controls -->
    <Portal>
      <VisibilityControls
        @edit="editHandler"
        @hovering="(is) => (hovering = is)"
        v-if="showVisibilityOptions"
        :class="[
          {
            'opacity-100': hovering
          },
          'transition-all flex absolute z-[1000]'
        ]"
        :style="{
          top: `${rootRect.top - 10}px`,
          left: `${rootRect.left + rootRect.width}px`
        }"
        :isInQuoteEditor="isInQuoteEditor"
        :object="object"
        :presentationSettings="presentationSettings"
      />
    </Portal>
    <MiniModal size="sm" :width="500" scrollable ref="refItemEditor" v-if="editing">
      <template #header>
        <CostItemHeader
          :type="object.type"
          :store="store"
          :refId="object.refId"
          :key="object.refId"
        />
      </template>
      <CostItem
        v-if="!isAssembly"
        ref="refCostItemBody"
        :store="store"
        :refId="object.refId"
        :key="object.refId"
        :show-title="false"
      />
      <AssemblyBody
        v-else-if="isAssembly"
        ref="refAssemblyBody"
        :store="store"
        :refId="object.refId"
        :key="object.refId"
        :show-title="false"
        :show-info="false"
        :show-contents="false"
      />
    </MiniModal>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  computed,
  ref,
  watch,
  inject,
  onMounted,
  defineAsyncComponent
} from 'vue'
import TranslationMixin from './languages/TranslationMixin'
import CostItemVariationSelector from '../item/CostItemVariationSelector.vue'
import ItemInputsMixin from '../item/ItemInputsMixin'
import QuotePresentationAddonList from './QuotePresentationAddonList.vue'
import ItemPicturesMixin from './ItemPicturesMixin'
import PresentationProperties from './PresentationProperties.vue'
// import CostItemFlag from '@/components/quote/presentation/CostItemFlag.vue'
import SelectionItemMixin from '@/components/quote/presentation/SelectionItemMixin'
import useItemReviewal from '@/components/composables/ItemReviewal'
import StringField from '@/components/ui/Calculator/StringField.vue'
import ItemGallery from '@/components/quote/presentation/ItemGallery.vue'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'
import ItemVariations from '@/components/composables/ItemVariations'
import VisibilityControls from '@/components/quote/presentation/VisibilityControls.vue'
import _ from '../../../../imports/api/Helpers.js'
import { useMediaQuery } from '@/composables/mediaQuery'
import CostItemHeader from '@/components/bodies/CostItem/Header.vue'
import ThumbGallery from '@/components/quote/presentation/ThumbGallery.vue'
import Portal from 'primevue/portal'
import eventBus from '@/eventBus.js'
import Galleria from '@/components/ui/Gallery/Galleria.vue'

export default {
  name: 'SelectionsItem',
  mixins: [TranslationMixin, ItemInputsMixin, SelectionItemMixin, ItemPicturesMixin],
  data() {
    return {
      editing: false,
      variationTraitClass: 'w-16 min-w-16 min-h-16 h-16 xl:w-20 xl:min-w-20 xl:h-20 xl:min-h-20',
      variationTraitWidth: 'w-16 min-w-16 xl:w-20 xl:min-w-20',
      hovering: false,
      showVisibilityOptions: false,
      rootRect: {},
      fullRemoval: [],
      notOptional: [],
      showVisOptions: 0,
      visoptions: [
        {
          key: 'price',
          name: 'Price',
          desc: 'If hidden, the price will be hidden from the buyer.'
        },
        {
          key: 'qty',
          name: 'Quantity',
          desc: 'If hidden, the quantity will be hidden from the buyer.'
        }
      ],
      assemblyEmphasis: 0
    }
  },
  mounted() {
    eventBus.$on('showingVisibilityControls', this.hide)
  },
  beforeUnmount() {
    eventBus.$off('showingVisibilityControls', this.hide)
  },
  watch: {
    hovering: {
      handler(is, was) {
        const rect = this.$refs.stickyTop?.getBoundingClientRect?.()
        this.rootRect = rect || this.rootRect

        if (is && !was) {
          eventBus.$emit('showingVisibilityControls', { refId: this.refId })
          this.showVisibilityOptions = true
        } else
          c.throttle(
            () => {
              if (!this.hovering) this.showVisibilityOptions = false
            },
            { key: this.refId, delay: 1000 }
          )
      }
    }
  },
  computed: {
    fileIds() {
      return c.makeArray(this.object?.file_ids)
    },
    isLocked() {
      return (
        (this.object.oMeta?.lockOptions ?? 0) || this.object.oMeta?.selectionAudience === 'company'
      )
    },
    isAddonGroupLike() {
      return (
        this.object.cost_type_is_addon_group ||
        (this.object.cost_item_price_net < 0.01 && this.object.cost_item_qty_net > 0.01)
      )
    },
    firstChild() {
      return this.norm[this.object.parentRefId]?.aoChildren.indexOf(this.refId) === 0
    },
    depth() {
      return (this.object?.asAssemblyPath?.length || 0) + 1
    },
    assemblyHeadingSize() {
      const baseSize = 16 // Base heading size for depth 1
      const scaleFactor = 1.1 // Controls the linearity of size reduction
      return baseSize / Math.pow(scaleFactor, this.depth - 1)
    },
    included: {
      get() {
        return (!this.isAssembly && this.object.cost_item_is_included) ||
          (this.isAssembly && this.object.assembly_is_included)
          ? 1
          : 0
      },
      set() {
        this.toggleOptional()
      }
    },
    selectedTraits() {
      const chosen = this.object.oVariations?.selectedItem?.variations ?? {}

      return Object.keys(chosen).map((vtypeName) => {
        const vt = this.object.oVariations?.variationTypes.find((vtype) => vtype.name === vtypeName)
        const trait = vt?.traits.find((trait) => trait.name === chosen[vtypeName])

        return {
          ...vt,
          vtypeName,
          ...trait,
          img: trait?.imgs?.[0] || null
        }
      })
    },
    selectionName: {
      get() {
        return this.isAssembly ? this.object.assembly_name : this.object.cost_type_name
      },
      set(value) {
        // If the item is a selection it could have an option group name
        const itemNameField = this.isAssembly ? 'assembly_name' : 'cost_type_name'
        this.setField(itemNameField, value)
      }
    },
    groupName: {
      get() {
        return this.object?.oMeta?.optionGroupName
      },
      set(value) {
        // If the item is a selection it could have an option group name
        this.setField('oMeta', {
          ...(this.object?.oMeta ?? {}),
          optionGroupName: value
        })
      }
    },
    name: {
      get() {
        return this.groupName || this.selectionName
      },
      set(value) {
        if (this.groupName) this.groupName = value
        if (this.selectionName) this.selectionName = value
      }
    },
    desc: {
      get() {
        // If the item is a selection it could have an option group desc
        const groupDesc = this.getOptionGroupMeta(this.object)?.optionGroupDesc ?? null
        const itemDesc = this.object.cost_type_desc || this.object.quote_notes || ''
        // groupDesc should override itemDesc if it exists
        return groupDesc || itemDesc
      },
      set(value) {
        // If the item is a selection it could have an option group desc
        const hasGroupDesc = this.object.oMeta?.optionGroupDesc
        const fieldName = hasGroupDesc ? 'oMeta' : 'cost_type_desc'
        const descValue = hasGroupDesc ? { ...this.object.oMeta, optionGroupDesc: value } : value
        this.setField(fieldName, descValue)
      }
    },
    visibility() {
      return {
        ...(this.object.oViewOptions?.pres ?? {}),
        ...(this.object.oMeta?.viewOptions?.pres ?? {})
      }
    },
    showPrice() {
      return (
        this.visibility.price ??
        (this.presentationSettings.showItemizedPrices &&
          ((!this.isAssembly && this.presentationSettings.showCostItemPrices) ||
            (this.isAssembly && this.presentationSettings.showAssemblyPrices)))
      )
    },
    showQty() {
      return this.visibility.qty ?? this.presentationSettings.showQuantities
    },
    showItem() {
      return this.visibility.isVisible ?? 1
    },
    // if all variations are locked or hidden, selector should not be shown
    showVariationSelector() {
      if (!this.object) return true
      const isCompanyUser = this.$store.getters.isCompanyUser
      const isInternalSelection = (this.object?.oMeta?.selectionAudience || 'client') === 'company'
      if (isInternalSelection && !isCompanyUser) return false

      // filter through variation types to see if one is not hidden or locked
      let response = false
      Object.values(this.object.oVariations?.variationTypes || []).forEach((variationType) => {
        // if response is already true or variationTypeOptions does not exist - skip
        if (response) return

        // if a variationType is not locked or hidden change response to show selector
        if (!variationType.hidden && !variationType.locked) {
          response = true
        }
      })
      return response
    },
    flagType() {
      if (this.optional) return 'Optional'
      if ((this.addons && this.addons.length) || this.variation) return 'Selections'
      return null
    },
    showQuantityAndPrice() {
      return (
        (this.qty || !this.included) &&
        (this.showQty || this.showPrice) &&
        !this.isTextBlock &&
        !this.isGallery
      )
    },
    showProperties() {
      return (
        this.object.aoProperties?.length &&
        this.object.aoProperties.some(
          (group) => Array.isArray(group) && group.some((property) => property.length)
        )
      )
    }
  },
  methods: {
    hide() {
      this.showVisibilityOptions = false
    },
    async editHandler() {
      this.editing = true
      await this.$nextTick()
      this.$refs.refItemEditor.open()
    },
    handleMouseout() {
      this.hovering = false
    },
    handleMouseover() {
      this.hovering = true
    },
    toggleOptional() {
      return this.$store.dispatch('Quote/toggleIncluded', {
        refId: this.refId,
        store: 'Quote',
        included:
          (!this.isAssembly && this.object.cost_item_is_included) ||
          (this.isAssembly && this.object.assembly_is_included)
            ? 0
            : 1,
        object: this.object
      })
    },
    formatCurrencySymbol,
    $: formatCurrencySymbol,
    previewImage() {
      this.$refs.gallery.preview(this.fileUrls[0])
    },
    modifyAddons(addons) {
      if (!this.$store.getters.isGuestUser) {
        this.setField('aoAddons', addons)
      }
    },
    getOptionGroupMeta(obj) {
      if (obj.oMeta && typeof obj.oMeta === 'object' && Object.keys(obj.oMeta).length) {
        return obj.oMeta
      }
      if (Array.isArray(obj.aoAddons)) {
        for (const addon of obj.aoAddons) {
          if (
            addon?.bulk?.oMeta &&
            typeof addon.bulk.oMeta === 'object' &&
            Object.keys(addon.bulk.oMeta).length
          ) {
            return addon.bulk.oMeta
          }
        }
      }

      return null
    },
    toggleReadMore() {
      this.showFullDescription = !this.showFullDescription
    }
  },
  components: {
    Portal,
    CostItemHeader,
    PresentationProperties,
    QuotePresentationAddonList,
    SelectionColor: defineAsyncComponent(
      () => import('@/components/ui/SelectionColor/SelectionColor.vue')
    ),
    CostItemVariationSelector,
    // CostItemFlag,
    StringField,
    ItemGallery,
    VisibilityControls,
    ThumbGallery,
    Galleria
  },
  props: {
    showAssembly: {
      type: Boolean,
      default: false
    },
    showOptionality: {
      default: true
    },
    maxHeight: {
      default: '30em'
    },
    disabled: {
      default: false
    },
    presentationSettings: {
      type: Object
    },
    isInQuoteEditor: {
      type: Boolean,
      default: false
    },
    isInteractiveAssembly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { reviewalItems } = useItemReviewal()
    const instance = getCurrentInstance().proxy
    const { smallFormat } = useMediaQuery()
    const refId = computed(() => props.refId)
    const store = computed(() => props.store)

    const showFullDescription = ref(false)
    onMounted(() => {
      showFullDescription.value = instance.object?.aoAddons?.length ? 1 : 0
    })

    const ellipsisDiv = ref(null)

    // Computed property to check if ellipsis is active
    const isEllipsisActive = computed(() => {
      const desc = instance.desc // eslint-disable-line
      if (ellipsisDiv.value?.refPlaceholder) {
        return (
          ellipsisDiv.value?.refPlaceholder.scrollHeight >
          ellipsisDiv.value?.refPlaceholder.clientHeight
        )
      }
      return false
    })
    const shouldShowReadMore = computed(() =>
      (isEllipsisActive.value || instance.object.aoProperties?.length > 3) &&
      !instance.object?.aoAddons?.length
        ? 1
        : 0
    )

    const { selectedVariationTraitImages, object: rObject } = ItemVariations.useItemVariations({
      refId,
      store,
      object: instance.object,
      type: 'CostItem'
    })

    const galleryActiveIndex = ref(0)
    const itemReviewal = computed(() =>
      reviewalItems.value.find((reviewal) => reviewal.itemId === instance.object.refId)
    )
    const isItemViewed = computed(() => {
      return itemReviewal.value && itemReviewal.value.isReviewed
    })
    const itemStatus = computed(() => {
      return isItemViewed.value ? 'Viewed' : 'Not yet viewed'
    })
    const itemStatusIcon = computed(() => {
      return isItemViewed.value ? 'circle-check' : 'circle'
    })
    const itemStatusClass = computed(() => {
      return isItemViewed.value ? 'text-surface-400' : 'text-blue-print'
    })
    const itemImages = computed(() => {
      let aoImages = []
      let fileIds = []

      if (rObject.value?.aoImages) {
        aoImages = rObject.value.aoImages.map((image) => {
          if (rObject.value.live_price_reference) {
            return `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${image}`
          }
          return image.file_url
        })
      }

      if (rObject.value?.file_ids) {
        fileIds =
          _.makeArray(rObject.value?.file_ids).map((id) =>
            c.link(`file/view/${id}`, {}, true, _.getStorage('scope'))
          ) ?? []
      }

      return [...aoImages, ...fileIds]
    })
    const variationImages = computed(() => [
      ...(itemImages.value || []),
      ...(selectedVariationTraitImages.value || [])
    ])

    const scope = _.getStorage('scope')
    const imgLink = (id) => c.link(`file/view/${id}`, { max: 200, size: 200 }, true, scope)
    watch(variationImages, () => (galleryActiveIndex.value = 0))

    const addonsCalcLoading = inject('addonsCalcLoading')

    return {
      addonsCalcLoading,
      galleryActiveIndex,
      itemReviewal,
      isItemViewed,
      itemStatus,
      itemStatusIcon,
      itemStatusClass,
      variationImages,
      itemImages,
      imgLink,
      smallFormat,
      ellipsisDiv,
      showFullDescription,
      isEllipsisActive,
      shouldShowReadMore
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.selection-graphic,
.selection-text {
  @media (max-width: 576px) {
    max-width: 100vw;
  }
  @media (max-width: 768px) {
    max-width: 100vw;
  }
}

.selection-text {
  flex: 2;
}

@media (max-width: 992px) {
  .selection-text {
    flex: 2.5;
  }
}

.selection-list-item {
  @media (max-width: 768px) {
    > div {
      flex-direction: column;
    }
  }
}

#app.small-format {
  .selection-list-item {
    .selection-graphic {
      text-align: center;
      margin: 0 auto;
      max-width: 100vw;
    }
    .selection-text {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}
</style>
