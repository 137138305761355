<script setup>
import { ref, computed, defineProps, toValue, watchEffect, defineModel } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'

import StringField from '@/components/ui/Calculator/StringField.vue'
import ItemSaving from '@/components/Sheets/quote/estimating/ItemSaving.vue'

const props = defineProps({
  refId: { type: String, required: false },
  reference: { type: String, required: false },
  type: { type: String, required: false },
  store: { type: String, required: false },
  storeName: { type: String, required: false },
  showTitle: { type: Boolean, default: true },
  showSaveButton: { type: Boolean, default: true }
})

const mobject = defineModel('object')

const refId = ref(props.refId || props.reference)
const type = ref(props.type || 'cost_item')
const store = ref(props.store || props.storeName)

const refSaveBtn = ref(null)
const refItemSaving = ref(null)

const obj = EntityComputedFields.useEntityComputedFields({
  object: mobject,
  refId,
  type,
  store
})

const saveable = computed(
  () => !obj.oMeta?.value?.itemType || obj.oMeta?.value?.itemType === 'costItem'
)

const {
  cost_type_id = ref(null),
  cost_type_name = ref(''),
  cost_type_hash = ref(null),
  usingStore,
  assembly_id = ref(null),
  assembly_name = ref(''),
  assembly_status = ref(null),
  cost_type_status = ref(null)
} = obj

const name = computed({
  get() {
    return type.value === 'assembly' ? assembly_name : cost_type_name
  },
  set(v) {
    if (type.value === 'assembly') {
      assembly_name.value = v
    } else {
      cost_type_name.value = v
    }
  }
})
const enteredName = ref(name.value)

const saveChanges = async (asNew) => {
  const action =
    type.value === 'cost_item' ? 'CostType/saveFromCostItem' : 'Assembly/saveFromAssembly'
  const r = await $store.dispatch(action, {
    refId: refId.value,
    store: store.value,
    asSuper: false,
    asNew,
    quiet: true
  })
  hasSaved.value = true
  return r
}

const hasSaved = ref(false)

const handleSave = () => {
  if (!cost_type_id.value && !assembly_id.value) return saveChanges(true)
  return saveChanges(false)
}

const currentHash = ref(null)
const $store = useStore()
watchEffect(async () => {
  if (type.value === 'assembly') return
  const object = { ...toValue(obj.object) }
  const hash = await $store.dispatch('CostType/getVersionHash', {
    object,
    coerceType: 'cost_type'
  })
  currentHash.value = hash
})

const diff = computed(() => currentHash.value !== cost_type_hash.value || assembly_id?.value)
const isSaved = computed(
  () =>
    (cost_type_id?.value || assembly_id?.value) &&
    cost_type_status?.value !== '@' &&
    assembly_status?.value !== '@'
)

const saveIcon = computed(() => {
  if (hasSaved.value) return 'svg:catalogSaved'
  if (!isSaved.value) return 'svg:catalogAdd'
  if (diff.value) return 'svg:catalogSave'
  return 'svg:catalogSaved'
})
const saveLabel = computed(() => {
  if (hasSaved.value && assembly_id?.value) return 'Saved assembly'
  if (hasSaved.value && cost_type_id?.value) return 'Saved item'
  if (!isSaved.value) return 'Add to catalog'
  if (diff.value) return 'Saved in catalog. Save changes?'
  return 'Same as saved version'
})
</script>

<template>
  <div class="flex flex-row justify-between gap-6 w-full">
    <div class="ml-2 flex justify-start items-center">
      <StringField
        class="font-medium text-xl min-w-[300px]"
        placeholder="Name your item"
        multiline
        :validate="{ required: true }"
        :value="enteredName"
        @blur="(newName) => (name.value = newName)"
      />
    </div>

    <Btn
      v-if="showSaveButton && usingStore && saveable"
      ref="refSaveBtn"
      :disabled="saveIcon === 'svg:catalogSaved'"
      :action="handleSave"
      :severity="saveIcon === 'svg:catalogSaved' ? null : 'tertiary'"
      :class="[
        '!px-2 !py-2 mr-2 border-none hover:border min-w-fit',
        {
          '!bg-transparent !text-pitch-black': saveIcon === 'svg:catalogSaved'
        }
      ]"
      size="xs"
    >
      <Icon :icon="saveIcon" />
      {{ saveLabel }}
    </Btn>

    <Drop
      v-if="$refs.refSaveBtn?.$el && usingStore"
      :fixTo="$refs.refSaveBtn.$el"
      ref="refItemSaving"
      unstyled
      containerClass="bg-surface-100 rounded-sm shadow-md after:border-b-transparent translate-y-1 flex flex-col gap-2 max-w-80 p-4"
    >
      <ItemSaving :refId="refId" :type="type" :store="store" v-if="usingStore" />
    </Drop>
  </div>
</template>
