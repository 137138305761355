// Core imports
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import store from './store'
import $h from './Helpers'

// Router
import router, { registerGlobalGuards } from '@/router'

// Initialize Amplify
import '@/config/amplify.js'
import { addCapacitorListeners } from '@/config/capacitor'

// External imports
import PrimeVue from 'primevue/config'
import moment from 'moment'
import AsyncComputed from 'vue-async-computed'
import Vue3TouchEvents from 'vue3-touch-events'
import VueObserveVisibility from 'vue-observe-visibility'
import contenteditable from 'vue-contenteditable'
import VueClickAway from 'vue3-click-away'
import FontAwesomeIcon from './fontAwesome'

// Theme imports
import bolsterTheme from '@/theme/presets/bolster'

// Filter imports
import filters from './filters'

import vueZxcvbn from '@/plugins/vue-zxcvbn'
import vuePosthog from '@/plugins/vue-posthog'

// Graphql appsync libraries
import { createApolloProvider } from '@vue/apollo-option'
import { client } from './apollo-client/apollo-config'

// Localization libraries
import { createI18n } from 'vue-i18n'
import messages from './languages'

// Directives imports
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import ScrollPanel from 'primevue/scrollpanel'
import ToastService from 'primevue/toastservice'

// Components
import Page from '@/components/layout/page/Page.vue'
import Icon from './components/ui/Icon.vue'
import Hotkey from './components/ui/Hotkey.vue'
import IconText from '@/components/ui/IconText.vue'
import SvgIcon from './components/ui/Icon/SvgIcon.vue'
import Btn from './components/ui/Btn.vue'
import BtnGroup from './components/ui/BtnGroup.vue'
import BtnBar from './components/ui/BtnBar.vue'
import Badge from './components/ui/Badge.vue'
import Count from './components/ui/Count.vue'
import Fade from './components/transitions/TransitionFade.vue'
import Steps from './components/ui/Steps.vue'
import SpinContainer from './components/ui/SpinContainer.vue'
import Spinner from './components/ui/Spinner.vue'
import Toggle from './components/ui/Toggle.vue'
import Warning from './components/ui/Cards/Warning.vue'
import Automation from './components/ui/Cards/Automation.vue'
import Danger from './components/ui/Cards/Danger.vue'
import Help from './components/ui/Help.vue'
import Info from './components/ui/Cards/Info.vue'
import InfoTooltip from './components/ui/Info.vue'
import PanelCard from './components/ui/Panel.vue'
import ImageSelector from './components/ui/ImageSelector.vue'
import Container from './components/ui/Container.vue'
import ContainerSection from './components/ui/ContainerSection.vue'
import ScrollContainer from './components/ui/ScrollContainer.vue'
import Card from './components/ui/Cards/Card.vue'
import CardSection from './components/ui/Cards/CardSection.vue'
import Checkbox from './components/ui/Checkbox.vue'
import CardList from './components/ui/Cards/CardList.vue'
import CardListItem from './components/ui/Cards/CardListItem.vue'
import CardListButton from './components/ui/Cards/CardListButton.vue'
import CardListSelect from './components/ui/Cards/CardListSelect.vue'
import CardListHeader from './components/ui/Cards/CardListHeader.vue'
import CardListField from './components/ui/Cards/CardListField.vue'
import CardListCollapse from './components/ui/Cards/CardListCollapse.vue'
import ScrollTrigger from './components/ui/ScrollTrigger.vue'
import SearchField from './components/ui/SearchField.vue'
import Checkmark from './components/ui/Checkmark.vue'
import LineItem from './components/quote/LineItem.vue'
import LineItemList from './components/quote/LineItemList.vue'
import Field from './components/ui/Field.vue'
import Calculator from './components/ui/Calculator.vue'
import Choose from './components/ui/Choose/Choose.vue'
import Modal from './components/modals/Modal.vue'
import Drop from './components/ui/Drop.vue'
import FloatingToolbar from './components/ui/FloatingToolbar.vue'
import Tabs from './components/ui/Tabs.vue'
import TabPane from './components/ui/TabPane.vue'
import Group from './components/transitions/TransitionList.vue'
import ChangeAudit from './components/ChangeAudit/ChangeAudit.vue'
import ChangeAuditField from './components/ChangeAudit/ChangeAuditField.vue'
import ChangeAuditObject from './components/ChangeAudit/ChangeAuditObject.vue'
import ClientDetails from './components/ui/ClientDetails.vue'
import QuoteDetails from './components/ui/QuoteDetails.vue'
import FilterField from './components/ui/FilterField.vue'
import InvoiceDetails from './components/ui/InvoiceDetails.vue'
import RatingCreatorDetails from './components/ratings/RatingCreatorDetails.vue'
import ContentEditable from './components/ui/ContentEditable.vue'
import ItemSummary from './components/quote/ItemSummary/ItemSummary.vue'
import ItemSummaryAssembly from './components/quote/ItemSummary/ItemSummaryAssembly.vue'
import ItemSummaryCostItem from './components/quote/ItemSummary/ItemSummaryCostItem.vue'
import Panel from './components/ui/SidePanel/Panel.vue'
import SidePanel from './components/ui/SidePanel/SidePanel.vue'
import Performance from './components/ui/Performance.vue'
import List from './components/ui/List/List.vue'
import ListGroup from './components/ui/ListGroup/ListGroup.vue'
import ListGroupItem from './components/ui/ListGroup/ListGroupItem.vue'
import Name from './components/ui/Name.vue'
import Thumb from './components/ui/Thumb.vue'
import QuotePresentationItems from './components/quote/presentation/QuotePresentationItems.vue'
import Reveal from './components/ui/Cards/Reveal.vue'
import SelectionToggle from './components/ui/SelectionToggle.vue'
import SubNav from './components/ui/SubNav.vue'
import SeeMore from './components/ui/SeeMore.vue'
import ObjectSummary from './components/ui/ObjectSummary.vue'
import MiniModal from './components/modals/MiniModal.vue'
import TimeRangeSelector from './components/ui/TimeRangeSelector.vue'
import Traverse from './components/ui/Traverse/Traverse.vue'
import TraverseParent from './components/ui/Traverse/TraverseParent.vue'
import ToRoute from './components/ui/ToRoute.vue'
import MaterialsList from './components/quote/MaterialsList/MaterialsList.vue'
import Wizard from './components/ui/steps/Steps.vue'
import WizardStep from './components/ui/steps/Step.vue'
import QuoteSummary from './components/quote/summary/QuoteSummary.vue'
import QuoteSummaryItems from './components/quote/summary/QuoteSummaryItems.vue'
import QuoteSummaryAssembly from './components/quote/summary/QuoteSummaryAssembly.vue'
import QuoteSummaryCostItem from './components/quote/summary/QuoteSummaryCostItem.vue'
import SelectionsItem from './components/quote/presentation/SelectionsItem.vue'
import SuggestionButton from './components/ui/SuggestionButton.vue'
import LoadingIndicator from './components/ui/LoadingIndicator.vue'
import AvatarGroup from 'primevue/avatargroup'
AvatarGroup.compatConfig = { MODE: 3 }
import Avatar from 'primevue/avatar'
Avatar.compatConfig = { MODE: 3 }
import Button from 'primevue/button'
Button.compatConfig = { MODE: 3 }
import ButtonGroup from 'primevue/buttongroup'
ButtonGroup.compatConfig = { MODE: 3 }
import PrimePanel from 'primevue/panel'
PrimePanel.compatConfig = { MODE: 3 }
import PersonAvatar from '@/components/ui/PersonAvatar.vue'
import Skeleton from 'primevue/skeleton'
import InputSwitch from 'primevue/inputswitch'
InputSwitch.compatConfig = { MODE: 3 }
import Carousel from 'primevue/carousel'
Carousel.compatConfig = { MODE: 3 }
import CalculatorFormatted from '@/components/ui/Calculator/CalculatorFormatted.vue'
CalculatorFormatted.compatConfig = { MODE: 3 }

import ItemizedListCostItem from './components/invoice/itemized/ItemizedListCostItem.vue'
import ItemizedListAssembly from './components/invoice/itemized/ItemizedListAssembly.vue'

import ReviewAllCostItem from './components/quote/review/ReviewAllCostItem.vue'
import ReviewAllAssembly from './components/quote/review/ReviewAllAssembly.vue'
import Tag from 'primevue/tag'
import Rating from 'primevue/rating'
Rating.compatConfig = { MODE: 3 }

// component library imports
import Dialog from 'primevue/dialog'

import './theme/css/style.css'
import InputText from 'primevue/inputtext'
InputText.compatConfig = { MODE: 3 }
import Loader from '@/components/ui/Loader.vue'
import Inplace from 'primevue/inplace'
Inplace.compatConfig = { MODE: 3 }
import EntitySelect from '@/components/ui/EntitySelect.vue'
import Choice from '@/components/ui/Choice/Choice.vue'
Choice.compatConfig = { MODE: 3 }

// Used recursively
import CostItem from '@/components/bodies/CostItem.vue'
CostItem.compatConfig = { MODE: 3 }
import CostType from '@/components/bodies/CostType.vue'
CostType.compatConfig = { MODE: 3 }

// Required for circular dependencies
import AssemblyBody from '@/components/bodies/Assembly.vue'
AssemblyBody.compatConfig = { MODE: 3 }

// set moment configuration
moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday at] LT',
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'D MMM YYYY'
  }
})

const pinia = createPinia()

// instantiate the app
const app = createApp(App)

// add global helpers
app.config.c = $h

// external
app.use(AsyncComputed)
app.use(Vue3TouchEvents)
app.use(VueObserveVisibility)
app.use(contenteditable)
app.use(VueClickAway)

// graphql apollo
const apolloProvider = new createApolloProvider({
  defaultClient: client
})
app.use(apolloProvider)

// setup locale
const i18n = new createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true
})
app.use(i18n)

app.use(vueZxcvbn)
app.use(vuePosthog)

// Add directives
app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)
// Add components

// External components
app.component('font-awesome-icon', FontAwesomeIcon)

// These need to be imported because they are recursive
//  and make errors otherwise
app.component('LineItem', LineItem)
app.component('LineItemList', LineItemList)
app.component('Traverse', Traverse)
app.component('CostItem', CostItem)
app.component('CostType', CostType)

// These are imported for convenience - GENERICS
app.component('Page', Page)
app.component('Icon', Icon)
app.component('Hotkey', Hotkey)
app.component('IconText', IconText)
app.component('SvgIcon', SvgIcon)
app.component('Checkbox', Checkbox)
app.component('CardList', CardList)
app.component('CardListItem', CardListItem)
app.component('CardListButton', CardListButton)
app.component('CardListSelect', CardListSelect)
app.component('CardListCollapse', CardListCollapse)
app.component('CardListField', CardListField)
app.component('CardListHeader', CardListHeader)
app.component('Card', Card)
app.component('CardSection', CardSection)
app.component('ClientDetails', ClientDetails)
app.component('QuoteDetails', QuoteDetails)
app.component('InvoiceDetails', InvoiceDetails)
app.component('RatingCreatorDetails', RatingCreatorDetails)
app.component('ImageSelector', ImageSelector)
app.component('Container', Container)
app.component('ScrollContainer', ScrollContainer)
app.component('ContainerSection', ContainerSection)
app.component('SpinContainer', SpinContainer)
app.component('Spinner', Spinner)
app.component('Steps', Steps)
app.component('Badge', Badge)
app.component('Btn', Btn)
app.component('BtnGroup', BtnGroup)
app.component('BtnBar', BtnBar)
app.component('Toggle', Toggle)
app.component('Drop', Drop)
app.component('Fade', Fade)
app.component('Warning', Warning)
app.component('Automation', Automation)
app.component('Help', Help)
app.component('Info', Info)
app.component('PanelCard', PanelCard)
app.component('InfoTooltip', InfoTooltip)
app.component('Danger', Danger)
app.component('SearchField', SearchField)
app.component('Checkmark', Checkmark)
app.component('Loader', Loader)

// These are imported for convenience
app.component('Count', Count)
app.component('Field', Field)
app.component('Calculator', Calculator)
app.component('Choose', Choose)
app.component('FilterField', FilterField)
app.component('Modal', Modal)
app.component('Group', Group)
app.component('Tabs', Tabs)
app.component('TabPane', TabPane)
app.component('ItemSummary', ItemSummary)
app.component('ItemSummaryAssembly', ItemSummaryAssembly)
app.component('ItemSummaryCostItem', ItemSummaryCostItem)
app.component('List', List)
app.component('SuggestionButton', SuggestionButton)
app.component('ListGroup', ListGroup)
app.component('ListGroupItem', ListGroupItem)
app.component('Name', Name)
app.component('Thumb', Thumb)
app.component('FloatingToolbar', FloatingToolbar)
app.component('ChangeAudit', ChangeAudit)
app.component('ChangeAuditObject', ChangeAuditObject)
app.component('ChangeAuditField', ChangeAuditField)
app.component('ContentEditable', ContentEditable)
app.component('SelectionToggle', SelectionToggle)
app.component('Performance', Performance)
app.component('SidePanel', SidePanel)
app.component('SubNav', SubNav)
app.component('SeeMore', SeeMore)
app.component('ToRoute', ToRoute)
app.component('TraverseParent', TraverseParent)
app.component('Panel', Panel)
app.component('LoadingIndicator', LoadingIndicator)
app.component('Avatar', Avatar)
app.component('AvatarGroup', AvatarGroup)
app.component('PersonAvatar', PersonAvatar)

app.component('ItemizedListCostItem', ItemizedListCostItem)
app.component('ItemizedListAssembly', ItemizedListAssembly)
app.component('QuotePresentationItems', QuotePresentationItems)
app.component('ObjectSummary', ObjectSummary)
app.component('MaterialsList', MaterialsList)
app.component('Reveal', Reveal)
app.component('ReviewAllCostItem', ReviewAllCostItem)
app.component('ReviewAllAssembly', ReviewAllAssembly)

app.component('ScrollTrigger', ScrollTrigger)
app.component('TimeRangeSelector', TimeRangeSelector)
app.component('MiniModal', MiniModal)
app.component('Wizard', Wizard)
app.component('WizardStep', WizardStep)
app.component('QuoteSummary', QuoteSummary)
app.component('QuoteSummaryItems', QuoteSummaryItems)
app.component('QuoteSummaryAssembly', QuoteSummaryAssembly)
app.component('QuoteSummaryCostItem', QuoteSummaryCostItem)
app.component('SelectionsItem', SelectionsItem)
app.component('AssemblyBody', AssemblyBody)
// eslint-disable-next-line vue/no-reserved-component-names
app.component('Button', Button)
app.component('Choice', Choice)
app.component('ButtonGroup', ButtonGroup)
app.component('InputText', InputText)
app.component('Skeleton', Skeleton)
app.component('InputSwitch', InputSwitch)
app.component('Inplace', Inplace)

// add component library components
// eslint-disable-next-line vue/no-reserved-component-names
app.component('Dialog', Dialog)
app.component('SplitterPanel', SplitterPanel)
app.component('Splitter', Splitter)
app.component('ScrollPanel', ScrollPanel)
app.component('Tag', Tag)
app.component('PrimePanel', PrimePanel)
app.component('Rating', Rating)
app.component('EntitySelect', EntitySelect)
app.component('CalculatorFormatted', CalculatorFormatted)

// Add filters
app.config.globalProperties.$f = filters

// add router and guards
app.use(router)
registerGlobalGuards()
addCapacitorListeners(router)
// add component library
app.use(PrimeVue, {
  unstyled: true,
  pt: bolsterTheme
})
app.use(ToastService)
// add the vuex store
app.use(store)
app.use(pinia)
// mount the app
app.mount('#app')
