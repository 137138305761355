import { computed } from 'vue'
import { useRoute } from 'vue-router'
export default () => {
  const route = useRoute()

  const isCustomerPreview = computed(() => route.fullPath.includes('customer/project/preview'))
  const isReviewer = computed(() => route.fullPath.includes('/review'))
  const isContractorPreview = computed(() => route.fullPath.includes('tab=Preview'))
  const isInPersonApproval = computed(() => route.fullPath.includes('pub/presentation'))

  const isInPresentation = computed(() => {
    return (
      isCustomerPreview.value ||
      isContractorPreview.value ||
      isInPersonApproval.value ||
      isReviewer.value
    )
  })

  return {
    isCustomerPreview,
    isContractorPreview,
    isInPersonApproval,
    isInPresentation
  }
}
