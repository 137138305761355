<template>
  <div
    :class="{ hidden: isAssembly && !showAssembly && !isInQuoteEditor }"
    class="overflow-clip first-child:rounded-tl-md"
  >
    <Accordion
      :selectOnFocus="false"
      v-if="isAssembly"
      :multiple="true"
      :pt="{
        root: [
          'rounded overflow-clip md:overflow-visible md:border-l-2 md:border-t-2 transition-all ',
          {
            'border-surface-200 rounded overflow-clip md:rounded-none md:overflow-visible md:mt-0 md:rounded-tl-lg':
              activeIndex.length,
            'border-transparent rounded': !activeIndex.length
          }
        ]
      }"
      v-model:active-index="activeIndex"
    >
      <AccordionTab
        :selections="true"
        :disabled="disabledList.includes(refId)"
        :pt="{
          content: 'h-full',
          header: [
            {
              '!overflow-hidden md:rounded-tl-md static': activeIndex.length
            }
          ],
          headerAction: [
            'px-2 md:px-4 !py-3 xl:p-3 text-pitch-black border-2 border-transparent border-b-2 md:border-b-0 md:mb-0 pb-2 !bg-surface-0',
            {
              '!md:rounded-tl-md md:static': activeIndex.length,
              'rounded-md': !activeIndex.length
            }
          ]
        }"
      >
        <template #headericon><span></span></template>
        <template #header>
          <div
            class="flex flex-col items-stretch justify-start w-full font-normal"
            @mouseover="hovering = true"
            @mouseout="stopHovering"
          >
            <div class="-m-2 md:-m-4" :id="refId">
              <SelectionsItem
                v-bind="
                  parentExpanded && activeIndex.length
                    ? { 'data-selections-item-scroll-ref': refId }
                    : {}
                "
                class="md:py-3 py-1"
                :showAssembly="isAssembly && activeIndex.length"
                :ref-id="refId"
                :parent-ref-id="parentRefId"
                :dimensions="dimensions"
                :presentation-settings="presentationSettings"
                :editable="editable"
                :store="store"
                :disabled="disabled || disabledList.includes(refId)"
                :isInQuoteEditor="isInQuoteEditor"
                :isInteractiveAssembly="isInteractiveAssembly"
              />
            </div>
          </div>
        </template>
        <div
          v-if="showAssembly"
          :class="[
            'flex justify-stretch items-stretch gap-0 h-full w-full bg-surface-0',
            {
              'border-t-[8px] border-surface-100 xl:border-0': smallFormat
            }
          ]"
        >
          <div
            :class="[
              '!w-[8px] min-w-[8px] shrink-0 !h-auto bg-gradient-to-b from-surface-0 to-surface-100 opacity-80 hidden md:block'
            ]"
          >
            &nbsp;
          </div>
          <div class="flex flex-col gap-2 md:gap-0 w-full bg-surface-100 rounded-tl-md">
            <div
              v-for="(childRef, index) of groups"
              :key="childRef"
              :class="[
                'md:mb-0  md:rounded-none md:overflow-visible',
                {
                  ' rounded-tl-md': index === 0
                }
              ]"
            >
              <RecursiveSelectionsItem
                :class="{
                  ' !rounded-tl-md': index === 0
                }"
                :parentExpanded="parentExpanded && activeIndex.length"
                v-if="!incrementalLoading || loadedRefs.refs.includes(childRef)"
                :ref-id="childRef"
                :parent-ref-id="norm[childRef].parentRefId"
                :dimensions="dimensions"
                :presentation-settings="presentationSettings"
                :editable="editable"
                :store="store"
                :interactiveRefs="interactiveRefs"
                :isInQuoteEditor="isInQuoteEditor"
                :disabledList="disabledList"
              />
            </div>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
    <div
      v-else
      class="md:overflow-visible md:border-l-2 md:border-t-2 border-surface-100"
      :class="parentRefId.startsWith('as_') ? '' : 'rounded md:rounded-none'"
      :id="refId"
    >
      <SelectionsItem
        class="py-3"
        :ref-id="refId"
        :parent-ref-id="parentRefId"
        :dimensions="dimensions"
        :presentation-settings="presentationSettings"
        :editable="editable"
        :store="store"
        :disabled="disabled || disabledList.includes(refId)"
        :isInQuoteEditor="isInQuoteEditor"
      />
    </div>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import SelectionsItem from './SelectionsItem.vue'
import eventBus from '@/eventBus'
import useSelections from '@/components/composables/UseSelections'
import { ref, onMounted, onBeforeUnmount, inject, getCurrentInstance } from 'vue'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'
import { useMediaQuery } from '@/composables/mediaQuery.js'
import { useDeviceStore } from '@/stores/device.js'

export default {
  name: 'RecursiveSelectionsItem',
  components: {
    SelectionsItem,
    Accordion,
    AccordionTab
  },
  emits: ['add-to-disabled-list', 'remove-from-disabled-list'],
  data() {
    return {
      activeIndices: [],
      hovering: false,
      activeIndex: []
    }
  },
  props: {
    parentExpanded: {
      type: Boolean,
      default: true
    },
    refId: {
      type: String,
      required: true
    },
    dimensions: {
      type: Object
    },
    presentationSettings: {
      type: Object,
      required: true
    },
    editable: {
      type: [Number, Boolean]
    },
    store: {
      type: String,
      required: true
    },
    interactiveRefs: {
      type: Array,
      required: true
    },
    isInQuoteEditor: {
      type: Boolean,
      required: true
    },
    disabledList: {
      type: Array,
      required: true
    }
  },
  watch: {
    defaultExpandState: {
      handler(is, was) {
        if (is !== was) {
          this.updateExpandState()
        }
      },
      immediate: true
    },
    isIncluded: {
      handler(is, was) {
        if (is && !was) {
          this.activeIndex = [0]
          eventBus.$emit('remove-from-disabled-list', this.refId)
        } else if (was && !is) {
          this.activeIndex = []
          eventBus.$emit('add-to-disabled-list', this.refId)
        }
      },
      immediate: true
    }
  },
  computed: {
    defaultExpandState() {
      return (
        this.visibility?.assemblyInitialState ??
        this.presentationSettings?.assemblyInitialState ??
        0
      )
    },
    showAssembly() {
      return this.visibility.isVisible ?? 1
    },
    visibility() {
      return {
        ...(this.object.oViewOptions?.pres ?? {}),
        ...(this.object.oMeta?.viewOptions?.pres ?? {})
      }
    },
    norm() {
      return this.$store.state[this.store].normalized
    },
    object() {
      return this.norm[this.refId]
    },
    parentRefId() {
      return this.object.parentRefId
    },
    selections() {
      return useSelections(this.norm)
    },
    groups() {
      const order = this.selections.preferredOrder.value
      return _.uniq(this.object.aoChildren)
        .filter((ref) => this.selections.shouldShowItem(ref))
        .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    },
    isAssembly() {
      return this.object.type === 'assembly'
    },
    isInteractiveAssembly() {
      return Array.isArray(this.interactiveRefs) && this.interactiveRefs.includes(this.refId)
    },
    isOptionalAssembly() {
      return this.isAssembly && this.object.assembly_is_optional
    },
    hasAssemblyDescription() {
      return this.isAssembly && this.object.quote_notes
    },
    hasImgs() {
      return this.isAssembly && c.makeArray(this.object.file_ids ?? []).length
    },
    isIncluded() {
      return this.isAssembly && this.object.assembly_is_included
    },
    disabled() {
      return false
    }
  },
  methods: {
    updateExpandState() {
      this.activeIndex = this.defaultExpandState ? [0] : []
    },
    $: formatCurrencySymbol,

    stopHovering() {
      setTimeout(() => {
        this.hovering = false
      }, 3000)
    }
  },
  setup(props) {
    const { smallFormat } = useMediaQuery()
    const $this = getCurrentInstance().proxy
    const deviceStore = useDeviceStore()

    const open = () => {
      $this.activeIndex = [0]
    }
    onMounted(() => {
      eventBus.$on(`open-${props.refId}`, open)
    })

    onBeforeUnmount(() => {
      eventBus.$off(`open-${props.refId}`, open)
    })

    const loadedRefs = inject('loadedRefs')
    const incrementalLoading = ref(!deviceStore.isTouch) // makes it faster on mobile and desktop

    setTimeout(() => {
      incrementalLoading.value = false
    }, 1000)

    requestAnimationFrame(() => {
      $this.updateExpandState()
    })
    return {
      incrementalLoading,
      smallFormat,
      loadedRefs,
      deviceStore
    }
  }
}
</script>
