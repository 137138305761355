<script setup>
import { defineProps, ref, computed } from 'vue'

import Galleria from 'primevue/galleria'
Galleria.compatConfig = { MODE: 3 }

const props = defineProps({
  fileIds: {
    type: Array
  },
  thumbClass: {
    default: ['w-12 h-12 rounded-sm']
  },
  thumbSize: {
    default: 200
  },
  fullSize: {
    default: 500
  },
  containerClass: {
    default: 'flex justify-start items-center flex-wrap gap-1'
  },
  numVisible: {
    type: Number,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const activeIndex = ref(0)
const displayCustom = ref(false)

const imageClick = (index) => {
  if (props.disabled) return
  activeIndex.value = index
  displayCustom.value = true
}
const genLink = (...args) => c.link(...args)
const scope = c.getStorage('scope')
const collapsedPics = computed(() =>
  props.numVisible === null ? 0 : props.fileIds.length - props.numVisible
)
</script>

<template>
  <div>
    <Galleria
      v-model:activeIndex="activeIndex"
      v-model:visible="displayCustom"
      :value="props.fileIds"
      :numVisible="1"
      containerStyle="max-width: 850px"
      :circular="true"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="false"
    >
      <template #item="slotProps">
        <img
          :src="genLink(`file/view/${slotProps.item}`, { size: props.fullSize }, true, scope)"
          style="width: 100%; display: block"
        />
      </template>
      <template #thumbnail>
        <!--        <img :src="genLink(`file/view/${slotProps.item}`, { size: props.thumbSize }, true, scope)"  />-->
      </template>
    </Galleria>

    <div v-if="props.fileIds" :class="[containerClass]">
      <template v-for="(id, index) of fileIds" :key="index">
        <div
          @click.stop.prevent="imageClick(index)"
          :class="[
            thumbClass,
            'relative overflow-hidden !bg-cover bg-no-repeat bg-center group cursor-pointer',
            {
              '!hidden': numVisible !== null && index + 1 > numVisible
            }
          ]"
          :style="{
            background: `url(${genLink(`file/view/${id}`, { size: props.thumbSize, max: props.thumbSize }, true, scope)})`
          }"
        >
          <!--          <img :src="genLink(`file/view/${id}`, { size: props.thumbSize }, true, scope)" @click="imageClick(index)"-->
          <!--               :class="['absolute inset-0 object-cover']"  />-->
          <!-- preload -->
          <img
            :src="genLink(`file/view/${id}`, { size: props.fullSize }, true, scope)"
            class="hidden"
          />

          <div
            v-if="numVisible === null || index + 1 <= numVisible"
            class="bg-pitch-black/50 text-white absolute inset-0 justify-center items-center flex opacity-0 group-hover:opacity-100 transition-all"
          >
            <font-awesome-icon icon="arrow-up-right" />
          </div>

          <div
            class="px-0.5 py-0.25 rounded-sm bg-pitch-black text-white text-xs absolute bottom-0 right-0 font-light cursor-pointer"
            v-if="collapsedPics > 0 && index + 1 === numVisible"
          >
            +{{ collapsedPics }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
