<template>
  <Drop targetAttachment="top right" position="top right" offset="5px 5px">
    <template #button>
      <div
        v-if="!asField"
        class="flex justify-center items-center uppercase font-normal cursor-pointer hover:bg-cement-300 h-full w-full"
      >
        {{ startDateFormatted }} <template v-if="displayEndDate">- {{ endDateFormatted }}</template>
      </div>
      <Btn :pt="{ root: '!border-cool-gray-500' }" severity="tertiary" v-else class="w-full h-10">
        {{ startDateFormatted }} <template v-if="displayEndDate">- {{ endDateFormatted }}</template>
      </Btn>
    </template>

    <div class="flex flex-col gap-1">
      <div>
        <label for="start-date" class="block mb-1 text-sm"> Select start date </label>
        <Calendar
          inputId="start-date"
          v-model="dates[0]"
          :pt="{
            input: '!border-r-0 !rounded-r-none'
          }"
          :ptOptions="{ mergeProps: true }"
          showIcon
          placeholder="Start date"
          @update:modelValue="() => input(dates[0], 'start_date')"
        >
          <template #dropdownicon>
            <font-awesome-icon :icon="['far', 'chevron-down']" />
          </template>
        </Calendar>
      </div>
      <div v-if="selectEndDate">
        <label for="end-date" class="block mb-1 text-sm"> Select end date </label>
        <Calendar
          inputId="end-date"
          v-model="dates[1]"
          :pt="{
            input: '!border-r-0'
          }"
          :ptOptions="{ mergeProps: true }"
          showIcon
          placeholder="End date"
          @update:modelValue="() => input(dates[1], 'end_date')"
        >
          <template #dropdownicon>
            <font-awesome-icon :icon="['far', 'chevron-down']" />
          </template>
        </Calendar>
      </div>
    </div>
  </Drop>
</template>

<script setup>
import { defineProps, computed, toRefs } from 'vue'
import Calendar from 'primevue/calendar'
import moment from 'moment'

Calendar.compatConfig = { MODE: 3 }

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  input: {
    type: Function
  },
  displayEndDate: {
    type: Boolean,
    default: false
  },
  selectEndDate: {
    type: Boolean,
    default: true
  },
  asField: {
    type: Boolean,
    default: false
  }
})

const { item } = toRefs(props)

const startDate = computed(() => new Date(item.value.start_date))
const endDate = computed(() => new Date(item.value.end_date))

const dates = computed(() => [startDate.value, endDate.value]) //ref([new Date(item.value.start_date), new Date(item.value.end_date)])

const startDateFormatted = computed(() => moment(dates.value[0]).format('MM/DD'))

const endDateFormatted = computed(() => moment(dates.value[1]).format('MM/DD'))
</script>
