<template>
  <div v-if="!loading" class="absolute inset-0">
    <!-- Contents -->
    <PresentationContents v-bind="props" />

    <!-- Editing -->
    <Sidebar
      v-if="!isInPresentation"
      v-model:visible="settingsOpen"
      position="bottom"
      :modal="false"
      :dismissable="false"
      :showCloseIcon="false"
      :blockScroll="true"
      :autoZIndex="false"
      :pt="{
        root: 'max-h-screen min-h-[400px] bg-surface-0 text-surface-900 shadow-lg',
        content: '!pb-0 !overflow-y-visible !py-4',
        header: '!px-5 !py-0 h-20 bg-transparent border-b border-surface-600',
        mask: {
          class: '!bottom-0 !top-auto quote-presentation-mask pointer-events-none',
          style: {
            zIndex: $store.state.modal.topZIndex - 1
          }
        }
      }"
      :ptOptions="{ mergeProps: true }"
      style="height: auto"
    >
      <template #header>
        <QuotePresentationSettingsHeader v-bind="props" @cancel="settingsOpen = 0" />
      </template>

      <QuotePresentationSettings v-bind="props" />
    </Sidebar>
  </div>

  <div
    v-else
    class="absolute inset-0 flex flex-col gap-1 justify-center items-center bg-pitch-black"
  >
    <p class="text-surface-0 font-medium">Preparing your estimate...</p>
    <div class="relative w-full">
      <Loader :loading="1" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, reactive, provide } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { usePresentationManager } from './usePresentationManager'
import PresentationContents from './PresentationContents.vue'
import Sidebar from 'primevue/sidebar'
import QuotePresentationSettings from './QuotePresentationSettings.vue'
import QuotePresentationSettingsHeader from './QuotePresentationSettingsHeader.vue'
import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'

const props = defineProps(['refId', 'store', 'type'])
const $store = useStore()

const { norm, loading, selected, waiter, change_order_id } =
  EntityComputedFields.useEntityComputedFields({
    store: props.store,
    refId: props.refId,
    type: props.type
  })

const { isInPresentation, isClient } = usePresentationManager({
  refId: props.refId,
  store: props.store
})

onMounted(async () => {
  if ((await selected) && isInPresentation.value && isClient.value) {
    $store.dispatch('ChangeOrder/markMultiple', {
      markAs: 'seen',
      id: change_order_id.value,
      go: false,
      alert: false
    })
  }
})

const loadedRefs = reactive({ refs: [] })
const addonsCalcLoading = reactive({ loading: 1 })

const rec = async () => {
  {
    addonsCalcLoading.loading = 1
    await $store.dispatch('Quote/recalcAddons', {
      force: true,
      store: props.store
    })
    addonsCalcLoading.loading = 0
  }
}
// Load items selectively
const selectiveLoad = () => {
  c.throttle(rec, { delay: 50 })
  const refs = NormalizeUtilities.sortNatural(norm.value)
  const chunks = _.chunk(refs, 10)
  _.waterfall([
    ...chunks.map((chunk) => async () => {
      await c.throttle(
        () => {
          loadedRefs.refs.push(...chunk)
        },
        { delay: 100 }
      )
    })
  ])
}
onMounted(async () => {
  await waiter
  selectiveLoad()
})
provide('loadedRefs', loadedRefs)
provide('addonsCalcLoading', addonsCalcLoading)
</script>
