<script setup>
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fetchAuthSession } from 'aws-amplify/auth'
import { useStore } from 'vuex'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { useAuthentication } from '@/composables/authentication.js'
import { useSession } from '@/stores/session'
import InputOtp from 'primevue/inputotp'
import bolsterLogo from '@/assets/logos/Bolster_Highlight_Yellow.svg'
import PasswordField from '@/components/ui/fields/PasswordField.vue'
import Btn from '@/components/ui/Btn.vue'
import ForgotPassword from '@/components/modals/auth/ForgotPassword.vue'
import SetPassword from '@/components/modals/auth/SetPassword.vue'
import GoogleSignIn from '@/components/identityProviders/buttons/GoogleSignIn.vue'
import AppleSignIn from '@/components/identityProviders/buttons/AppleSignIn.vue'
import { useDeviceStore } from '@/stores/device'

InputOtp.compatConfig = { MODE: 3 }

const { login, logout, magicLogin, confirmChallengeLogin, externalLogin, tokenExchange } =
  useAuthentication()

const deviceStore = useDeviceStore()

const props = defineProps({
  leadRequestFlow: {
    default: false
  }
})

const router = useRouter()
const route = useRoute()

const store = useStore()
const sessionStore = useSession()

const whitelabel = computed(() => route.query.whitelabel)

const otpModal = ref(null)
const fpModal = ref(null)
const setPass = ref(null)
const resendToken = ref(null)

const username = ref('')
const password = ref('')
const requiredReset = ref(false)
const otp = ref(null)

const usernameIsPhone = computed(() => {
  return isValidPhoneNumber(username.value, 'US')
})

// Until we can get the Amplify OAuth callback working with deep links...
const shouldDisplaySocialLogin = computed(() => {
  return !deviceStore.isNative
})

const btn = ref(null)

const handleSubmit = async () => {
  return btn.value.triggerClick()
}

async function authenticate() {
  if (username.value !== '' && password.value !== '') {
    try {
      store.dispatch('addLoading')
      let result
      if (usernameIsPhone.value) {
        const parsedNum = parsePhoneNumber(username.value, 'US')
        result = await login(parsedNum.format('E.164'), password.value)
      } else {
        result = await login(username.value, password.value)
      }
      const session = await fetchAuthSession()

      switch (result.nextStep.signInStep) {
        case 'DONE':
          await completeSignIn(session, true)
          break
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          setPass.value.open()
          break
        case 'RESET_PASSWORD':
          requiredReset.value = true
          fpModal.value.open()
          break
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
          otpModal.value.open()
          break
        default:
          console.log('Unhandled sign in step:')
          console.log(result.nextStep.signInStep)
      }
    } catch (e) {
      if (e.name === 'LimitExceededException') {
        await store.dispatch('alert', {
          error: true,
          message: e.message
        })
      } else {
        // Obfuscate the error for the user
        await store.dispatch('alert', {
          error: true,
          message: 'Incorrect email and/or password'
        })
        console.debug(e)
      }
    } finally {
      requestAnimationFrame(() => store.dispatch('endLoading'))
    }
  }
}

async function submitOtp() {
  try {
    await confirmChallengeLogin(otp.value)
    const session = await fetchAuthSession()
    await completeSignIn(session, true)
  } catch (e) {
    if (e.name === 'CodeMismatchException') {
      await store.dispatch('alert', {
        error: true,
        message: 'The code you entered was incorrect. Please try again.'
      })
    } else {
      await store.dispatch('alert', {
        error: true,
        message: 'An error occurred while signing you in. Please try again.'
      })
    }
  }
}

function redirect() {
  // If we didn't use a magic link, check and strip the magic stuff from the URL to prevent account soft-lockout
  if (!sessionStore.usedMagicLink) {
    route.query.magic = undefined
    route.query.username = undefined
    route.query.token = undefined
  }

  if (route.query.redirect) {
    const { redirect, ...remainder } = route.query
    router.push({ path: redirect, query: remainder })
  } else {
    router.push({ name: 'Home', query: route.query })
  }
}

async function passwordSet() {
  setPass.value.close()
  const session = await fetchAuthSession()
  await completeSignIn(session, true)
}

/**
 * Exchanges the Cognito tokens for Bolster session value stuff and then
 * redirects
 *
 * @param {AuthSession} session
 * @param {Boolean} fullyAuthenticated
 * @return {Promise<void>}
 */
async function completeSignIn(session, fullyAuthenticated = false) {
  try {
    await tokenExchange()
    sessionStore.isFullyAuthenticated = fullyAuthenticated
    redirect()
  } catch (e) {
    await store.dispatch('alert', {
      error: true,
      message: 'An error occurred while signing you in. Please try again.'
    })
    await logout()
  }
}

async function externalSignIn(provider) {
  await externalLogin(provider)
}

async function resendLoginLink() {
  await store
    .dispatch('ajax', {
      path: '/auth/resendLoginLink',
      data: {
        username: route.query.username,
        redirect: route.query.redirect ?? '/'
      }
    })
    .then(() => {
      store.dispatch('alert', {
        message: 'Check your email for a new link'
      })
    })
}

function loginAfterReset(user) {
  fpModal.value.close()
  username.value = user.username
  password.value = user.password
  authenticate()
  route.query.stage = undefined
}

onBeforeMount(async () => {
  const { magic, username, token } = route.query
  if (magic && !sessionStore.usedMagicLink) {
    // use this to prevent revealing the login fields
    sessionStore.usedMagicLink = true
    try {
      const { nextStep } = await magicLogin(username)

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        const { isSignedIn } = await confirmChallengeLogin(token)
        if (isSignedIn) {
          const session = await fetchAuthSession()
          await completeSignIn(session, false)
        } else {
          throw new Error('Invalid token')
        }
      }
    } catch (e) {
      console.error(e)
      if (e.message === 'Invalid token' || e.name === 'EmptyChallengeResponse') {
        resendLoginLink()
        resendToken.value.open()
      } else {
        // Weird check here but we need it for super edge cases ¯\_(ツ)_/¯
        if (e.name !== 'UserAlreadyAuthenticatedException') {
          await store.dispatch('alert', {
            error: true,
            message: 'Unable to sign you in using that link. Please request a new one.'
          })
        }
      }
      sessionStore.usedMagicLink = false
    }
  }
})

onMounted(() => {
  if (route.query.stage === 'confirm' && route.query.username) {
    username.value = route.query.username
    fpModal.value.open()
  }
})
</script>

<template>
  <!-- Background for large displays -->
  <div
    class="fixed grid grid-cols-1 lg:grid-cols-5 w-screen h-screen bg-cement-50 bg-[url('/src/assets/loginbg.png')] bg-cover bg-no-repeat bg-right overflow-auto scrollbar-hide"
    :class="{ 'bg-none': whitelabel }"
  >
    <!-- Background containing fields and buttons, and the mobile background on small displays -->
    <div
      class="col lg:col-span-2 h-full flex flex-col justify-center items-center px-safe-6 lg:px-safe-16 bg-cover bg-[url('/src/assets/loginbg_mobile.png')] bg-no-repeat lg:bg-none bg-pitch-black bg-right"
      :class="{ 'bg-none': whitelabel }"
    >
      <div v-if="sessionStore.usedMagicLink" class="flex text-white grow items-center">
        <font-awesome-icon icon="spinner" size="2xl" spin />
      </div>
      <div v-else class="w-full lg:max-w-[30vw] animate-pullUp grow content-center">
        <!-- Logo and welcome -->
        <div class="flex flex-col pt-safe-4 pb-4 gap-4 md:gap-6 justify-start w-full">
          <div class="h-full flex flex-col justify-center !my-0 px-1">
            <img
              v-if="!whitelabel"
              :src="bolsterLogo"
              alt="Bolster by CostCertified Logo"
              class="h-[60px] lg:h-[80px] w-[196px] lg:w-[258px]"
            />
            <div class="text-white text-[46px] lg:text-5xl my-12">Welcome</div>
          </div>

          <div class="flex flex-col justify-center items-stretch gap-2">
            <!-- Email -->
            <label for="username" class="text-flame-white mx-1 !my-0">Username</label>
            <input
              id="username"
              name="username"
              type="text"
              placeholder="Enter your email or phone number"
              autocomplete="username"
              v-model="username"
              class="!h-field rounded-sm !text-lg !text-left !font-medium w-full px-5"
            />
          </div>

          <div class="flex flex-col justify-center items-stretch gap-2">
            <!-- Password -->
            <label for="password" class="text-flame-white font-light">Password</label>
            <PasswordField
              context="login"
              v-model="password"
              @submit="handleSubmit"
              placeholder="Enter your password"
              class="!h-field rounded-sm !text-lg !text-left !font-medium w-full"
            />
          </div>

          <div class="flex flex-col justify-center items-stretch gap-2">
            <Btn
              class="w-full h-field !font-medium"
              size="lg"
              severity="primary-yellow-login"
              ref="btn"
              :action="authenticate"
            >
              Login
            </Btn>
          </div>

          <!-- Forgot password -->
          <a
            v-if="!props.leadRequestFlow"
            class="text-white w-full bg-transparent font-light !px-1 mt-3 cursor-pointer hover:underline focus:ring-transparent text-right transition"
            @click="fpModal.open"
          >
            Forgot your password?
          </a>

          <div
            v-if="shouldDisplaySocialLogin"
            class="flex flex-col justify-center items-center gap-y-2 lg:gap-y-4"
          >
            <div class="text-white">
              <p>-- OR --</p>
            </div>
            <AppleSignIn @click.native="externalSignIn('Apple')" />
            <GoogleSignIn @click.native="externalSignIn('Google')" />
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-center gap-4 pb-safe-2 text-xs text-white font-light">
        <a href="https://www.bolsterbuilt.com/terms-and-conditions" target="_blank">
          Terms of Service
        </a>
        <a href="https://www.bolsterbuilt.com/privacy-policy" target="_blank">Privacy Policy</a>
      </div>
    </div>
    <MiniModal ref="fpModal">
      <ForgotPassword
        :provide-username="username"
        :required-reset="requiredReset"
        :start-stage="route.query.stage"
        @login="loginAfterReset"
        @cancel="fpModal.close"
      />
    </MiniModal>
    <MiniModal ref="setPass">
      <SetPassword :username="username" @password-set="passwordSet" />
    </MiniModal>
    <MiniModal ref="otpModal">
      <div class="flex flex-col gap-y-4 items-center">
        <h2>Enter the OTP as displayed in your authenticator app</h2>
        <InputOtp v-model="otp" :length="6" integer-only autocomplete="one-time-code" />
        <div>
          <Btn
            class="w-full h-field !font-medium"
            size="lg"
            severity="primary-yellow-login"
            ref="btn"
            :action="submitOtp"
          >
            Continue
          </Btn>
        </div>
      </div>
    </MiniModal>
    <MiniModal ref="resendToken">
      <div class="flex flex-col gap-y-4 items-center text-center">
        <div
          class="w-fit rounded-full border-2 border-red-500 text-red-500 px-6 pt-[27px] pb-[28px]"
        >
          <font-awesome-icon icon="shield-xmark" fixed-width size="2xl" />
        </div>
        <p class="text-2xl font-medium">Your login link is invalid</p>
        <p>
          The link that you attempted to use is either expired or invalid. We have sent you a new
          link to your email.
        </p>
      </div>
    </MiniModal>
  </div>
</template>
