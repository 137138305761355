import ObjectSimple from './ObjectSimple'
import _ from '../../../imports/api/Helpers'
import eventBus from '../../eventBus'

/**
 * This is used with objects that utilize
 * the vuex global state, ie: quote;
 * It allows for change tracking, auditing etc.
 *
 * Requirements:
 *
 *    -'id': either the id prop must be passed, or the object prop.
 *      the id is the id of the object, which will be fetched
 *      on mount.
 *    -'object': alternatively the object prop can be provided which
 *      represents the prefetched object, which negates our requirement
 *      to fetch the object again.
 *
 *  -Lifecycle functions:
 *    (can be overriden in the component htis mixin is added to, put these
 *      functions in methods: {})
 *    -beforeSave() - called before saves
 *    -afterSave() - called after saves
 *    -beforeSelect() - called before selecting
 *    -afterSelect() - called after selection is complete
 *  Emits:
 *    -reload
 *    -reloaded
 *    -saving
 *    -saved
 *    -select
 *    -selected
 *    -fetched
 */
export default function generate(type, trackChanges = true, storeName = c.titleCase(type)) {
  const objSimple = ObjectSimple(type, trackChanges, storeName)

  // Get mapped fields
  // All
  const constructor = c.getConstructor(type)
  const fields = constructor.fields || {}
  const allFields = Object.keys(fields)

  // Equation fields
  const equationFields = allFields.filter((f) => fields[f].type === 'float')

  // Mapped/child fields
  const mappedFields = allFields.filter(
    (f) =>
      c.shouldMapField(f, constructor) || (c.isJsonField(f) && /array|object/.test(fields[f].type))
  )

  // Id mapped fields
  const idFields = allFields.filter((f) => /_id$/.test(f) && fields[f].mapTo && f !== `${type}_id`)

  const objectManipulator = {
    name: `ObjectManipulator-${type}`,

    mixins: [objSimple],

    beforeUnmount() {
      this.beforeDestroyProcedure()
      if (this.changeManager) {
        this.$store.dispatch(`${this.storeName}/destroyWatchers`, {
          refId: this.rootRefId,
          callback: this.storeChanged
        })
      }
    },

    // This is overridden below, do not set anything here.
    computed: {},

    watch: {
      selected(selected, previous) {
        if (!previous && selected) {
          this.initializeObjectManipulator()
        }
      },

      reference(refId) {
        if (refId !== this.refId) {
          this.selected = false
          this.select()
        }
      }
    },

    methods: {
      /**
       * Integrate changes from subcomponents
       * @param api
       */
      integrateSubComponentChanges(api) {
        this.$store.dispatch(`${this.storeName}/field`, {
          changes: api[4],
          explicit: api[5],
          // skipAudit: true,
          // skipLocalAudit: false,
          refId: this.refId
        })
      },
      /**
       * This needs to be called after this component
       * has been selected.  It will initialize the watcher that
       * watches for changes
       * in the VUEX store this component relates to
       * and add the changes as they come.  It also sets up various
       * other fields and initializations.
       *
       * @returns {Promise<boolean>}
       */
      async initializeObjectManipulator() {
        if (this.objectManipulatorInitialized) {
          return true
        }
        this.objectManipulatorInitialized = 1

        // Add tags
        Object.keys(this.tags).forEach((k) => {
          this[k] = this.tags[k]
        })

        await this.buildUpstreamChangeWatcher()

        // If prop 'object' was given, original gets created. If
        // this object actually exists in normalized because
        // it needed to be 'full' or forced 'fetch'
        // then we should use the allFetched
        // version as the original instead of
        // this.original.
        const idField = `${this.type}_id`
        const allFetched = await this.$store.dispatch(`${this.storeName}/getAllFetched`)
        if (this.original && Object.keys(this.original).length && this[idField] in allFetched) {
          this.original = false
          this.checkIfDirty()
        }
        await this.$nextTick(() => {
          const refId = this.refId ?? this.rootRefId
          const isBlank = !this.norm[refId][idField]
          this.buildChangeWatcher(isBlank)
        })

        this.$nextTick(() => {
          this.$emit('selected')
          eventBus.$emit(`${this.uid}-selected`)
          eventBus.$emit(`selected-${this.type}-${this.refId}`)
        })

        this.selectedProcedureRan = 1

        return true
      },

      /**
       * Initializes the watcher of the VUEX store
       * so that those changes can be imported here as
       * they come.
       *
       * @returns {Promise<void>}
       */
      buildUpstreamChangeWatcher() {
        this.$store.dispatch(`${this.storeName}/setWatchers`, {
          refId: this.refId,
          store: this.storeChanged
        })
        return Promise.resolve()
      },

      /**
       * Initializes the watcher of the VUEX store
       * so that those changes can be imported here as
       * they come.
       *
       * @returns {Promise<void>}
       */
      destroyUpstreamChangeWatcher() {
        this.changeManager.unwatch(this.storeChanged)

        return Promise.resolve()
      },

      /**
       * Fill changes from upstream/VUEX store
       * @param {object} state
       * @param force
       * @returns {methods}
       */
      fillUpstreamChanges(state, force = false) {
        const changedFields = force ? Object.keys(state) : this.getChangedFields(state)

        const changeLog = {
          changed: [],
          notChanged: []
        }
        changedFields.forEach((field) => {
          const fieldKey = `intercepted_${field}`

          const shouldNotUpdate =
            !(fieldKey in this) ||
            _.jsonEquals(this[fieldKey], state[field]) ||
            this[fieldKey] === state[field]

          changeLog[`${!shouldNotUpdate ? 'changed' : 'notChanged'}`].push({
            parts: [
              fieldKey in this,
              _.jsonEquals(this[fieldKey], state[field]),
              this[fieldKey] === state[field]
            ],
            new: state[field],
            current: this[fieldKey],
            fieldKey
          })

          if (shouldNotUpdate) {
            return
          }

          this[fieldKey] = state[field]
        })

        this.$emit('change', this.cast())
        this.$emit('changed', this.cast())

        return this
      },

      /**
       * Listener for when store is changed, implementing upstream changes
       * @param force
       * @returns {Promise<methods>}
       */
      async storeChanged(force = false) {
        if (!(this.refId in this.norm)) {
          return this
        }

        let newState = this.norm[this.refId]
        let stateStringified = JSON.stringify(newState)
        let state = JSON.parse(stateStringified)

        if (force || !_.jsonEquals(stateStringified, this.lastUpstreamSyncState)) {
          this.fillUpstreamChanges(state, force)
        }
        this.lastUpstreamSyncState = stateStringified

        newState = null
        stateStringified = null
        state = null

        this.$emit('storeChanged')

        return this
      },

      /**
       * Initializations
       * @returns {methods}
       */
      async beforeMountProcedure() {
        const idField = `${this.type}_id`
        if (
          this.useRouteId &&
          !this.id &&
          (!Object.keys(this.object || {}).length || !this.object[idField]) &&
          !this.reference &&
          this.$route.params.id &&
          !this.inModal
          /* && String(this.$route.fullPath).toLowerCase()
            .includes(`${c.titleCase(this.type).toLowerCase()}/`) */
        ) {
          this.idLocal = this.$route.params.id.replace(/_PERIOD_/g, '.')
        } else if (!c.isempty(this.object) && idField in this.object && this.object[idField]) {
          this.idLocal = this.object[idField]
        } else if (this.reference && idField in this.norm[this.reference]) {
          this.idLocal = this.norm[this.reference][idField]
        } else if (this.id) {
          this.idLocal = this.id
        } else {
          this.idLocal = null
        }

        if (c.isempty(this.id) && c.isempty(this.object) && c.isempty(this.reference)) {
          console.warn(`For the ObjectManipulator mixin, you must provide
          either the 'object' or 'id' prop to manipulate an object.
          Alternatively the id can be retrieved
          from the 'id' prop in the $router/$route/path.  If no object or id
          is provided, this will provision a blank object.`)
        }

        // If we are passing a reference, we need to check that the
        //  referenceId exists in the storeName (provided above), otherwise
        //  it could be in a different store and the store could have been left out
        //  or set to the Type, so give a warning.
        if (this.reference) {
          if (
            $(this.$el).length &&
            this.reference &&
            !(this.refId in this.$store.state[this.storeName].normalized)
          ) {
            throw new Error(`The 'reference' passed to this component
            does not correlate to any reference ids or objects in
            $store.state.${this.storeName}, make sure you provide a
            third argument in the ObjectManipulatorMixin to
            specify the correct store/module to check in.
            ie: component = {
              name: 'MyComponent',
              mixins: [ObjectManipulator('${type}', false, -->'${this.storeName}'<--)],
              ...,
            }`)
          }
        }

        try {
          await this.select()
        } catch (e) {
          this.$store.dispatch('alert', {
            message:
              `${e.userMessage || ''} Please go back, refresh your browser and try again.` ||
              'There was an error loading that item. Please go back, refresh your browser and try again.',
            error: 1,
            timeout: 10000
          })

          const modal = $(this.$el).closest('.modal')
          if (modal.length && modal.__vue__ && modal.__vue__.close) {
            modal.__vue__.close()
          }
          // else if (this.$store.state.session.isLoggedIn) {
          //   this.$router.go(-1)
          // }

          throw e
        }

        return this
      },

      /**
       * Initialize change watcher etc
       * @returns {methods}
       */
      afterSelect() {
        return this
      },

      /**
       * Overrides ObjectSimple.getField
       * Field should have computed property.
       * @param field
       * @returns {any}
       */
      getField(field) {
        if (field in this) {
          return this[field]
        }
        return null
      },

      /**
       * Set field directly to computed setter
       * @param field
       * @param value
       * @returns {self}
       */
      setField(field, value) {
        this[field] = value

        return this
      },

      /**
       * Uses the route to determine when to show the latest sent change order
       * @returns {boolean}
       */
      shouldShowLatestSentQuote() {
        return this.$store.getters.isGuestUser
      },

      /**
       * Selection process, overrides ObjectSimple.select
       * @param forceBlank
       * @param embue
       * @returns {object<refId>}
       */
      async select(forceBlank = false, embue = {}) {
        if (this.selected) {
          return { refId: this.refId }
        }

        this.addBodyLoading()
        this.beforeSelect()

        this.$emit('select')
        this.$emit('selecting')
        eventBus.$emit(`selecting-${this.type}-${this.refId}`)

        let id = this.idLocal

        // If an object is provided to load from, that is either full=true
        // or not required to be full, and that we aren't force to fetch
        // on each reload, then go ahead and fillFetch the object
        // to avoid a server call.
        const allFetched = await this.$store.dispatch(`${this.storeName}/getAllFetched`)
        if (
          !forceBlank &&
          id &&
          Object.keys(this.object).length &&
          (this.object.full || !this.forceFull) &&
          // If we already have it, use that
          !(String(id) in allFetched) &&
          !this.forceFetch &&
          !this.reference
        ) {
          const { id: gotId } = await this.$store.dispatch(`${this.storeName}/fillFetch`, {
            object: this.object
          })
          id = gotId
        }

        // If we required to fetch, or object not full and we require a full,
        // or if only id was provided, we do a select existing, and send forceFull
        // and forceFetch flags so it knows what it needs to do
        // If it is already normalized and selected, then use the normalized one
        // which selectExisting will do automatically.
        // If neither id nor reference nor object are provided,
        // then build out and select a totally blank object of this 'this.type'
        if (!this.reference) {
          const { refId } =
            !forceBlank && id
              ? await this.$store.dispatch(`${this.storeName}/selectExisting`, {
                  id,
                  type: this.type,
                  button: this.$refs.spinner,
                  force: this.forceFetch,
                  full: this.forceFull,
                  filters: this.filters,
                  override: false,
                  showLatestSentQuote: this.shouldShowLatestSentQuote()
                })
              : await this.$store.dispatch(`${this.storeName}/selectBlank`, {
                  button: this.$refs.spinner,
                  object: this.object || embue,
                  type: this.type
                })

          this.refId = refId
        }

        await this.$nextTick()
        await this.storeChanged(true)

        this.selected = true

        this.endLoading()
        this.endBodyLoading()

        return { refId: this.refId }
      }
    },

    data() {
      return {
        /**
         * Last sync of state received
         */
        lastUpstreamSyncState: '',

        /**
         * So we don't do watchers twice etc
         */
        objectManipulatorInitialized: 0,

        /**
         * Always starts as 0 in object manipulator
         */
        selected: 0,

        /**
         * Will trigger a full audit on field changes
         */
        enableFullAudit: 1,

        /**
         * Will trigger a local audit on field changes
         */
        enableLocalAudit: 1
      }
    },

    /**
     * See ObjectSimple.js for explanation of
     * props. These overwrite those props primarily
     * because ObjectManipulator has different defaults
     */
    props: {
      deselectOnDestroy: {
        required: false,
        default: true
      },

      useRouteId: {
        required: false,
        default: true
      },

      forceFull: {
        required: false,
        type: Boolean,
        default: true
      },

      forceFetch: {
        required: false,
        type: Boolean,
        default: false
      },

      /**
       * Provide the object to manipulate here, or provide the id above
       */
      object: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },
    emits: [
      'selected',
      'change',
      'changed',
      'storeChanged',
      'select',
      'selecting',
      'linkedObjectChanging',
      'linkedObjectChanged'
    ]
  }

  let existingFields = []

  const addRootFields = (component) => {
    existingFields = [
      ...existingFields,

      ...Object.keys(component.methods || {}),
      ...Object.keys(component.computed || {}),
      ...Object.keys(component.data || {}),
      ...Object.keys(component.props || {})
    ]
    ;(component.mixins || []).forEach((mixin) => addRootFields(mixin))
  }

  addRootFields(objectManipulator)

  objectManipulator.computed = {
    /**
     * Allow subcomponents to communicate through v-model
     */
    subComponentInterface: {
      get() {
        return [this.norm[this.refId], [], [], []]
      },
      set(changeArray) {
        this.integrateSubComponentChanges(changeArray)
      }
    },
    /**
     * Map computed fields that call on their respective
     * intercept fields.
     *
     * For each field in the schema we also have an intercept
     * field that is updated whenever there is upstream changes
     * from the VUEX store this component represents.
     *
     * By having an intercept field for each field, rather than
     * one object having all the field values, we can trigger only computed
     * fields to update that have had changes.  If we simply added the changes
     * to some object like objectLocal, it would trigger ALL the computed
     * properties to update.
     */

    /**
     * Normalized fields
     */
    ...allFields.reduce((acc, f) => {
      const fieldSchema = fields[f]
      const fieldType = fieldSchema && fieldSchema.type
      const formatType = fieldSchema && fieldSchema.format

      return {
        ...acc,

        ...(!existingFields.includes(f)
          ? {
              [f]: {
                get() {
                  let val = this[`intercepted_${f}`]

                  // Always return the value in the type it is meant to be
                  if (val && fieldType === 'string') {
                    val = String(val)
                  } else if (
                    val !== null &&
                    (/percent|currency|number/.test(formatType) || /int|float/.test(fieldType))
                  ) {
                    val = _.toNum(val)
                  } else if (fieldType === 'object') {
                    val = c.getDefault(fieldSchema, val || {})
                  } else if (fieldType === 'array') {
                    val = val || []
                  }

                  return val
                },
                set(val) {
                  const deformattedVal = this.getDeformattedFieldValue(f, val)
                  const norm = this.norm[this.refId]
                  const stateVal = norm && norm[f]

                  if (
                    !norm ||
                    stateVal === deformattedVal ||
                    c.jsonEquals(stateVal, deformattedVal)
                  ) {
                    return
                  }

                  const changes = {
                    [f]: deformattedVal
                  }
                  this.$store.dispatch(`${this.storeName}/field`, {
                    changes,
                    explicit: true,
                    skipAudit: !this.enableFullAudit,
                    skipLocalAudit: !this.enableLocalAudit,
                    refId: this.refId
                  })

                  _.throttle(
                    () => {
                      // this.$emit('change', {
                      //   ...norm,
                      //   ...changes,
                      // });
                      // Emit change
                      eventBus.$emit('change', {
                        refId: this.refId,
                        store: this.storeName
                      })
                      eventBus.$emit('changed', {
                        refId: this.refId,
                        store: this.storeName
                      })
                    },
                    { key: this.refId, delay: 100 }
                  )

                  this.addDefaults(f)
                }
              }
            }
          : {})
      }
    }, {}),

    /**
     * Equation fields
     * Add equation fields `${field}_equation`
     */
    ...equationFields.reduce(
      (acc, f) => ({
        ...acc,

        ...(!existingFields.includes(`${f}_equation`)
          ? {
              [`${f}_equation`]: {
                get() {
                  return (this.intercepted_oEquations && this.intercepted_oEquations[f]) || null
                },
                set(text) {
                  this.$store.dispatch(`${this.storeName}/fieldEquation`, {
                    field: f,
                    equation: text,
                    refId: this.refId
                  })
                }
              }
            }
          : {})
      }),
      {}
    ),

    /**
     * Mapped, children fields
     *
     * Add special mapped fields for fields that refer to refIds
     aoChildren, aoVendors
     Also deals with fields that needn't map to a specific
     object type, but are objects or arrays like aoProperties

     Returns a manipulation-safe field in the non-hungarian version
     for example, with the field 'aoProperties', this will also create a field
     called 'properties'.  Properties will return an immutable object based on
     aoProperties, so from the component you can do: this.properties.splice(1, 1) etc
     without getting a 'do not change vuex state outside of a mutation handler'.
     And changing it, this.properties = []; similarly maps it back into normalized format.
     */
    ...mappedFields.reduce((acc, f) => {
      const schema = f && fields && f in fields && fields[f]
      const isArray = schema.type === 'array'
      const shouldMap = c.shouldMapField(f, type)

      return {
        ...acc,

        ...(!existingFields.includes(_.hungarianToCamelCase(f))
          ? {
              [_.hungarianToCamelCase(f)]: {
                get() {
                  const defaultValue = isArray ? [] : {}
                  const intercepted = this[`intercepted_${f}`]

                  if (!shouldMap) {
                    return _.imm(intercepted) || defaultValue
                  }

                  const norm = this.norm
                  const refId = this.refId
                  const object = norm && refId in norm && norm[refId]

                  if (!object || !object[f]) {
                    return defaultValue
                  }

                  const mappedObjects = c.makeArray(_.imm(object[f])).map((r) => norm[r] || {})

                  if (isArray) {
                    return mappedObjects
                  } else if (mappedObjects.length) {
                    return mappedObjects[0]
                  }

                  return defaultValue
                },
                set(children) {
                  const childrenArray = c.makeArray(children)

                  if (shouldMap) {
                    this.$store.dispatch(`${this.storeName}/replaceChildren`, {
                      refId: this.refId,
                      children: childrenArray,
                      field: f
                    })
                  } else {
                    this[f] = isArray ? childrenArray : childrenArray[0]
                  }
                }
              }
            }
          : {})
      }
    }, {}),

    /**
     * ID mapped fields
     *  Add mapped _id fields like client_id => clientId
     for automated fetching of sub objects
     fetched changes emit 'linkedObjectChanged'
     */
    ...idFields.reduce(
      (acc, f) => ({
        ...acc,

        ...(!existingFields.includes(_.camelCase(f))
          ? {
              [_.camelCase(f)]: {
                get() {
                  return this[`intercepted_${f}`] || null
                },
                async set(id) {
                  const schema = f in fields && fields[f]
                  const mapTo = schema.mapTo

                  const titleMapTo = _.titleCase(mapTo)
                  const camelMapTo = _.camelCase(mapTo)

                  const nameField = f.replace('_id', '_name')
                  const abbrField = f.replace('_id', '_abbr')

                  // If they are the same abort;

                  if (c.fieldComparison(this[f], id, f, fields, false, false)) {
                    return
                  }

                  this[f] = id

                  const mappedObjectExists =
                    camelMapTo in this &&
                    (typeof this[camelMapTo] === 'object' || this[camelMapTo] === null)
                  const nameFieldExists = nameField in this
                  const abbrFieldExists = abbrField in this

                  let mappedObject = {}
                  let nameValue = null
                  let abbrValue = null

                  if (id && (mappedObjectExists || nameFieldExists)) {
                    const { object } = await this.$store.dispatch(`${titleMapTo}/fetch`, {
                      id
                    })

                    mappedObject = object
                    nameValue = object[nameField] || nameValue
                    abbrValue = object[abbrField] || abbrValue
                  }

                  this.$emit(`linkedObjectChanging:${mapTo}`, {
                    field: f,
                    object: mappedObject,
                    type: mapTo
                  })
                  this.$emit('linkedObjectChanging', {
                    field: f,
                    object: mappedObject,
                    type: mapTo
                  })

                  if (mappedObjectExists) {
                    this[camelMapTo] = mappedObject
                  }

                  if (nameFieldExists) {
                    this[nameField] = nameValue
                  }

                  if (abbrFieldExists) {
                    this[abbrField] = abbrValue
                  }

                  this.$emit(`linkedObjectChanged:${mapTo}`, {
                    field: f,
                    type: mapTo,
                    object: mappedObject
                  })
                  this.$emit('linkedObjectChanged', { field: f, type: mapTo, object: mappedObject })
                }
              }
            }
          : {})
      }),
      {}
    ),

    casted() {
      return this.cast(false)
    }
  }

  return objectManipulator
}
