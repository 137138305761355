// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'phase',

  skipAudit: true,

  fields: {
    phase_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      visible: false
    },
    phase_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      visible: true
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company',
      visible: false
    },
    predecessor_phase_id: {
      type: 'int',
      title: 'Occurs after',
      filter: false,
      format: false,
      mapTo: 'phase',
      visible: true
    },
    phase_status: {
      type: 'string',
      filter: true,
      format: false
    },
    phase_creator: {
      type: 'int',
      filter: true,
      format: false
    },
    phase_owner: {
      type: 'int',
      filter: true,
      format: false
    },
    phase_time_created: {
      type: 'date',
      filter: true,
      format: false,
      mapTo: false
    },
    phase_time_last_modified: {
      type: 'date',
      filter: true,
      format: false,
      mapTo: false
    }
  }
}
