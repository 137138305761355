<template>
  <div class="activity-channel flex flex-col h-full w-full max-w-screen">
    <div class="activity-channel-header" v-if="showHeader">
      <ActivityChannelHeader
        ref="activityHeaderRef"
        :channelType="channelType"
        :channelTypeId="channelTypeId"
      />
    </div>
    <Panel
      ref="welcomeBanner"
      :collapsed="false"
      class="activity-channel-description px-6 py-2 mb-4 bg-cement-100"
      :pt="{
        header: 'flex items-center justify-between',
        content: 'py-2'
      }"
      toggleable
    >
      <template #header>
        <div class="flex items-center gap-2">
          <p class="font-medium text-inherit" v-html="channelWelcomeTitle"></p>
        </div>
      </template>
      <template>
        <p class="text-surface-600 text-sm" v-html="channelDescription"></p>
      </template>
    </Panel>
    <div v-if="activityChannelTabs?.length > 1" class="activity-channel-tabs">
      <ActivityChannelTabs
        :channelId="channelId"
        :channelIds="activityChannelTabs"
        @channelTabSelected="onChannelTabSelected"
      />
    </div>
    <div
      ref="activityMessagesContainerRef"
      @scroll="onMessagesScroll"
      class="activity-channel--body overflow-y-auto flex-1 mt-2"
    >
      <div
        v-if="!hasMoreActivities && hasMoreLegacyActivities && showFetchLegacy"
        class="activity-channel--show-legacy flex flex-col items-center"
      >
        <a
          class="text-center text-sm w-full block text-secondary mb-5 cursor-pointer"
          @click="onLoadLegacyActivities"
        >
          Load previous...
        </a>
      </div>
      <div class="activity-channel--items flex flex-col items-center">
        <div
          v-if="loading || !channel"
          class="activity-channel--loader sticky top-2 translate-y-16 h-0 z-10"
        >
          <ProgressSpinner
            style="width: 36px; height: 36px"
            strokeWidth="5"
            animationDuration="1s"
          />
        </div>
        <ActivityChannelItem
          v-for="(activity, index) in activities"
          :activity="activity"
          :prevActivity="activities[index - 1]"
          :parentIntersectionObserver="intersectionObserver"
          :channelId="channelId"
          :key="index"
          @onReply="onReply(activity)"
          @onEdit="onEdit(activity)"
          @onDelete="onDelete(activity)"
        />
      </div>
    </div>
    <div class="activity-channel-footer w-full flex justify-center items-center">
      <ActivityChannelFooter ref="activityFooterRef" :channelId="channelId" />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import ProgressSpinner from 'primevue/progressspinner'
import Panel from 'primevue/panel'
import useActivityChannel from '@/components/composables/ActivityChannel'
import ActivityChannelFooter from '@/components/Activities/ActivityChannelFooter.vue'
import ActivityChannelItem from '@/components/Activities/ActivityChannelItem.vue'
import ActivityChannelHeader from '@/components/Activities/ActivityChannelHeader.vue'
import ActivityChannelTabs from '@/components/Activities/ActivityChannelTabs.vue'

// ======== Props ======== //
const props = defineProps({
  channelType: {
    type: String,
    required: true
  },
  channelTypeId: {
    type: String,
    required: true
  },
  showHeader: {
    type: Boolean,
    default: true
  }
})
// ====================== //

// ======== Composables ======== //
const store = useStore()
const {
  loading,
  channelId,
  channel,
  channelType,
  channelTypeId,
  fetchChannelActivities,
  initChannelActivities,
  destroyChannelActivities,
  postSeenActivity,
  activityChannelTabs
} = useActivityChannel(props)
// ====================== //

// ======== Refs ======== //
const activityMessagesContainerRef = ref(null)
const activityFooterRef = ref(null)
const welcomeBanner = ref(null)
// ====================== //

// ======== Data ======== //
const showFetchLegacy = ref(true)
const intersectionObserver = ref(null)
// ====================== //

// ======== Computed ======== //
const hasMoreActivities = computed(() => {
  return store.getters['activityChat/getChannelHasMoreActivities'](channelId.value)
})
const hasMoreLegacyActivities = computed(() => {
  return store.getters['activityChat/getChannelHasMoreLegacyActivities'](channelId.value)
})
const activities = computed(() => {
  return store.getters['activityChat/getChannelActivities'](channelId.value)
})
const channelWelcomeTitle = computed(() => {
  const channelTitles = {
    COMPANY: 'Welcome to your activities channel!',
    COMPANY_GENERAL: 'Welcome to your general channel!',
    COMPANY_COMPANY: 'Welcome to the vendor channel!',
    QUOTE: 'Welcome to the internal project channel!',
    QUOTE_CLIENT: 'Welcome to the client project channel!',
    INVOICE: 'Welcome to the invoice channel!',
    SUPPORT: 'Bolster support crew <span class="text-xl">👷🏻‍♂️🛠️</span>',
    CHAT: 'Welcome!'
  }

  return channelTitles[channelType.value]
})
const channelDescription = computed(() => {
  const channeltexts = {
    COMPANY: `
      This channel groups all company members and lets parties know of the company activities. Additionally, resources and messages can be shared amongst each other.
      <br/><br/>
      Type a message below to get started!
    `,
    COMPANY_GENERAL: `
      This channel groups all company members and allows for sharing resources and messages amongst each other.
      <br/><br/>
      Type a message below to get started!
    `,
    COMPANY_COMPANY: `
      This channel groups all company members from both the vendor and you company.
      <br/><br/>
      Type a message below to get started!
    `,
    QUOTE: `
      This channel groups all contracting project members and keeps you updated on the project activities. Additionally, resources and messages can be shared amongst each other.
      <br/><br/>
      Type a message below to get started!
    `,
    QUOTE_CLIENT: `
      This channel groups the client and contracting project members and keeps you updated on the client project activities. Additionally, resources and messages can be shared amongst each other.
      <br/><br/>
      Type a message below to get started!
    `,
    INVOICE: `
      This channel groups the relevant invoicing members and keeps you updated on the invoice activities. Additionally, resources and messages can be shared amongst each other.
      <br/><br/>
      Type a message below to get started!
    `,
    SUPPORT: `
      We are all about having a concrete connection with each of our members -- so you can nail it every time. Tell us any issues that we can hammer away at for you.
      <br/><br/>
       The Bolster support crew is on-site <b>Mountain Time business hours, 8 am to 5 pm</b>, and ready to drill-down into any of your questions, guide you through the app, or help you raise the roof on your business.
      <br/><br/>
      We can't wait to see what you build with Bolster -- just tell us how we can help!
    `,
    CHAT: `
      This is a private chat for members to share messages and resources.
      <br/><br/>
      Type a message below to get started!`
  }

  return channeltexts[channelType.value]
})
// ====================== //

// ======== Methods ======== //
// const openBanner = () => {
//   welcomeBanner.value.d_collapsed = false
// }
const onLoadLegacyActivities = () => {
  return store.dispatch('activityChat/getChannelLegacyActivities', {
    channelType: channelType.value,
    channelTypeId: channelTypeId.value,
    limit: 15
  })
}
const onMessagesScroll = async (event) => {
  const scrollHeight = event.target.scrollHeight
  const scrollTop = event.target.scrollTop

  if (scrollTop <= 0 && !loading.value) {
    if (hasMoreActivities.value) {
      await fetchChannelActivities()

      // maintain scroll positions for consistency //
      const newScrollTop = event.target.scrollTop
      const newScrollHeight = event.target.scrollHeight

      event.target.scrollTo({
        top: newScrollTop + newScrollHeight - scrollHeight
      })
    }
  }
}
const onIntersectionObserverChange = async (items) => {
  for (const item of items) {
    if (item.isIntersecting) {
      const activityId = item.target.id.split('activity-')[1]
      await postSeenActivity(activityId)
      intersectionObserver.value.unobserve(item.target)
    }
  }
}
const onReply = (activity) => {
  activityFooterRef.value.replyToMessage(activity)
}
const onEdit = (activity) => {
  activityFooterRef.value.editMessage(activity)
}
const onDelete = async (activity) => {
  const shouldDelete = await store.dispatch('modal/asyncConfirm', {
    message: 'Are you sure you would like to delete this activity?',
    yes: 'Delete',
    no: 'Cancel'
  })

  console.log(activity)

  if (shouldDelete) {
    await store.dispatch('activityChat/removeActivity', {
      channelId: channelId.value,
      activityId: activity.activity_id
    })
  }
}
const onChannelTabSelected = (channelId) => {
  const index = channelId.indexOf('-')
  const newChannelType = channelId.substring(0, index)
  const newChannelTypeId = channelId.substring(index + 1)
  channelType.value = newChannelType
  channelTypeId.value = newChannelTypeId
}
const mountHandler = async () => {
  // init channel //
  await initChannelActivities()

  // scroll to bottom //
  const activityMessageElm = activityMessagesContainerRef.value
  activityMessageElm.scrollTop = activityMessageElm.scrollHeight - activityMessageElm.clientHeight

  // init intersection observer //
  intersectionObserver.value = new IntersectionObserver(onIntersectionObserverChange, {
    root: activityMessagesContainerRef.value,
    rootMargin: '0px',
    threshold: 0.5
  })
}
const unmountHandler = async () => {
  // destroy intersectionObserver //
  intersectionObserver.value?.disconnect()

  await destroyChannelActivities()
}
// ====================== //

// ======== Watchers ======== //
watch(channelId, async () => {
  loading.value = true
  // openBanner()
  await unmountHandler()
  await mountHandler()
})
// ====================== //

// ======== Lifecycle ======== //
onMounted(mountHandler)
onBeforeUnmount(unmountHandler)
// ====================== //
</script>

<style lang="less" scoped></style>
