import { ref } from 'vue'

export function useTouchScroll() {
  const lastTouchX = ref(null)
  const lastTouchY = ref(null)

  function handleTouchStart(event) {
    if (event.touches.length === 1) {
      // Single touch
      lastTouchX.value = event.touches[0].clientX
      lastTouchY.value = event.touches[0].clientY
    }
  }

  function handleTouchEnd() {
    lastTouchX.value = null
    lastTouchY.value = null
  }

  function handleTouchMove(event) {
    if (event.touches.length === 1) {
      // Single touch
      event.preventDefault()
      const currentX = event.touches[0].clientX
      const currentY = event.touches[0].clientY
      const movement = {}
      if (lastTouchY.value !== null) {
        movement.deltaY = -(currentY - lastTouchY.value)
      }
      if (lastTouchX.value !== null) {
        movement.deltaX = -(currentX - lastTouchX.value)
      }
      lastTouchX.value = currentX
      lastTouchY.value = currentY

      return movement
    }
  }

  return {
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove
  }
}
