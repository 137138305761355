<template>
  <div v-if="!loading" class="overview">
    <div class="flex flex-wrap gap-8">
      <template v-for="card in balanceCards" :key="card.title">
        <BalanceCard v-if="card.visible" :object="card" />
      </template>
    </div>
  </div>
  <div v-else class="grid grid-cols-3 gap-6">
    <Skeleton width="100%" height="8rem"></Skeleton>
    <Skeleton width="100%" height="8rem"></Skeleton>
    <Skeleton width="100%" height="8rem"></Skeleton>
  </div>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useCounterpartyBalances } from './useCounterpartyBalances'
import Skeleton from 'primevue/skeleton'
import BalanceCard from './BalanceCard.vue'

const store = useStore()

const props = defineProps({
  balances: {
    type: Object,
    default() {
      return {}
    }
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const isPayfacEnabled = computed(() => {
  return (
    store.state.session.company &&
    store.state.session.company.aoModules &&
    parseInt(store.state.session.company.aoModules.payments_v2, 10) === 1
  )
})

const { balanceCards, initializeBalanceCards } = useCounterpartyBalances()

onMounted(() => {
  initializeBalanceCards(props.balances, isPayfacEnabled.value)
})
</script>
