export default {
  accordiontab: {
    root: {
      class: 'mb-0'
    },
    header: ({ props, context }) => ({
      class: [
        // State
        { 'select-none pointer-events-none cursor-default opacity-60': props?.disabled },

        //Font
        'leading-none',

        // Sizing
        {
          '!p-0': props.smallHeader
        },

        // Color
        { 'text-surface-900': context.active },

        // Transition
        'transition duration-200 ease-in-out',
        'transition-shadow duration-200',

        // States
        'hover:text-surface-900',
        'focus:outline-none focus:outline-offset-0 focus-visible:ring focus-visible:ring-primary-400/50 ring-inset dark:focus-visible:ring-primary-300/50', // Focus

        // Misc
        'cursor-pointer no-underline select-none',
        {
          'border-none': props.noBorder
        },

        // Disabled
        {
          'opacity-30': props.disabled
        }
      ]
    }),
    headerAction: ({ props }) => ({
      class: [
        // Alignments
        'p-5',
        'flex items-start',
        'relative',
        'font-medium',
        {
          'p-5': !props.selections,
          '!p-0': props.noPadding
        }
      ]
    }),
    headerIcon: {
      class: 'inline-block mr-2 h-8'
    },
    headerTitle: {
      class: 'leading-none text-lg md:text-2xl'
    },
    content: ({ props }) => ({
      class: [
        // Spacing
        {
          'p-2': !props.selections,
          'pr-0': props.selections
        },

        // Color
        'text-surface-700 dark:text-surface-0/80'
      ]
    }),
    transition: {
      enterFromClass: 'max-h-0',
      enterActiveClass:
        'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
      enterToClass: 'max-h-[1000px]',
      leaveFromClass: 'max-h-[1000px]',
      leaveActiveClass:
        'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
      leaveToClass: 'max-h-0'
    }
  }
}
