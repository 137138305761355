<template>
  <Container class="text-center center" id="register-content">
    <card-section>
      <template #title>
        <span class="pt-5">
          <font-awesome-icon icon="check" />
          <span>Please confirm your details</span>
        </span>
      </template>
      <card-list>
        <card-list-field>
          <span>First name</span>
          <field
            ref="fname"
            v-model="user_fname"
            schema="client:user_fname"
            :validate="{ required: true }"
          ></field>
        </card-list-field>
        <card-list-field>
          <span>Last name</span>
          <field
            ref="lname"
            v-model="user_lname"
            schema="client:user_lname"
            :validate="{ required: true }"
          ></field>
        </card-list-field>
        <card-list-field>
          <span>Phone number</span>
          <div>
            <btn
              class="btn round xs"
              @click="primaryExtension = true"
              v-if="primaryExtension !== true && !user_phone_ext"
            >
              <template #icon>
                <font-awesome-icon icon="plus" />
              </template>
              Extension
            </btn>
          </div>
          <field
            ref="phone"
            v-model="user_phone"
            schema="client:user_phone"
            :format="true"
            :validate="{ required: true }"
            :validateImmediately="true"
            @validation="(o) => (verifiedPhone = o.valid && !o.empty && o.value !== '')"
            :class="{ '!border-red-400 border-2 text-red-500': !phoneIsValid }"
          ></field>
        </card-list-field>
        <card-list-field v-if="primaryExtension === true || user_phone_ext">
          <span>Extension</span>
          <field v-model="user_phone_ext" schema="client:user_phone_ext"></field>
        </card-list-field>
        <card-list-field>
          <span>Email address</span>
          <field
            ref="email"
            v-model="user_email"
            :emit-delay="200"
            schema="client:user_email"
            :validate="{
              required: true,
              format: 'email'
            }"
            :validateImmediately="true"
            @validation="(a) => (emailValid = a.valid)"
          ></field>
        </card-list-field>
      </card-list>
    </card-section>
    <card-section>
      <template #label>Address</template>
      <card-list>
        <card-list-field>
          <span>Address</span>
          <field v-model="user_address" />
        </card-list-field>
        <card-list-field>
          <span>Suite</span>
          <field v-model="user_suite" />
        </card-list-field>
        <card-list-field>
          <span>City</span>
          <field v-model="user_city" />
        </card-list-field>
        <card-list-field>
          <span>State/Province</span>
          <choose
            :return-array="false"
            :allowDeselect="false"
            schema="client:province_id"
            v-model="province_id"
          />
        </card-list-field>
        <card-list-field>
          <span>Postal/Zip Code</span>
          <field v-model="user_postal" />
        </card-list-field>
      </card-list>

      <card-list>
        <template #title>
          Please accept our updated terms and conditions so we can continue to build and release new
          features
        </template>
        <AcceptTerms
          :showInlineTerms="false"
          :forceView="false"
          v-model="acceptedTOS"
          @terms-loaded="termsLoaded"
        />
      </card-list>
    </card-section>

    <spinner :loading="loading" v-if="loading" :size="'3em'" class="scroll-trigger-container" />
    <Btn v-show="!loading" class="round info mt-3" :disabled="!isValid" @click="save">
      Confirm
    </Btn>
  </Container>
</template>

<script>
import AcceptTerms from '../legal/AcceptTerms.vue'
import { isValidPhoneNumber } from 'libphonenumber-js'

export default {
  mixins: [],
  components: {
    AcceptTerms
  },

  props: {
    leadData: {
      type: Object,
      required: true
    }
  },
  emits: ['registeredClientId'],

  data() {
    return {
      loading: false,
      acceptedTOS: 0,
      terms: '',
      user_fname: '',
      user_lname: '',
      user_phone: '',
      user_phone_ext: '',
      user_email: '',
      user_address: '',
      user_suite: '',
      user_city: '',
      province_id: null,
      user_postal: '',
      primaryExtension: false,
      emailValid: false,
      verifiedPhone: null,
      scope: {}
    }
  },

  computed: {
    isValid() {
      return (
        this.emailValid &&
        this.user_fname &&
        this.user_lname &&
        this.verifiedPhone &&
        this.acceptedTOS === 1
      )
    },
    phoneIsValid() {
      if (this.user_phone) {
        return isValidPhoneNumber(this.user_phone, this.user_country?.toUpperCase())
      }
      return true
    }
  },

  methods: {
    init() {
      if (!this.leadData.oReference || !this.leadData.oReference.client) {
        return
      }
      const client = this.leadData.oReference.client
      Object.keys(client).forEach((key) => {
        this[`user_${key}`] = client[key] || ''
      })
    },
    async save() {
      this.loading = true
      try {
        const { payload } = await this.$store.dispatch('ajax', {
          path: 'lead_request/importLead',
          data: {
            lead_request_id: this.leadData.lead_request_id,
            user_fname: this.user_fname,
            user_lname: this.user_lname,
            user_phone: this.user_phone,
            user_phone_ext: this.user_phone_ext,
            user_email: this.user_email,
            user_address: this.user_address,
            user_suite: this.user_suite,
            user_city: this.user_city,
            province_id: this.province_id,
            user_postal: this.user_postal,
            user_terms: this.terms,
            user_terms_time_accepted: Date.now()
          }
        })

        this.$emit('registeredClientId', payload.client_id)

        this.loading = false
      } catch (e) {
        this.$store.dispatch(
          'alert',
          {
            error: true,
            message: e.userMessage || e.message
          },
          { root: true }
        )
        this.loading = false

        throw e
      }
    },
    termsLoaded(termsText) {
      this.terms = termsText
    }
  },
  created() {
    this.init()
  }
}
</script>
