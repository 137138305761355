<template>
  <Container :gap="false" size="5xl" v-if="!bodyLoading">
    <div class="flex flex-row items-center text-2xl gap-4 mt-12 mb-3">
      <font-awesome-icon icon="shop" />
      <h1>Company settings</h1>
    </div>
    <div class="bg-surface-200 w-full h-px" />
    <card-section>
      <template #title>
        <span>Logo</span>
      </template>
      <image-selector v-model="company_logo_file_id" />
    </card-section>

    <card-section>
      <template #title>
        <span>Company details</span>
      </template>
      <card-list>
        <card-list-field>
          <span> Legal company name </span>
          <field
            schema="company:company_name"
            :validate="{ required: true }"
            v-model="company_name"
          />
        </card-list-field>
        <card-list-field>
          <span> Company short name </span>
          <field
            schema="company:company_name_short"
            :validate="{ required: true }"
            v-model="company_name_short"
          />
        </card-list-field>
        <card-list-field>
          <span> Company email </span>
          <field
            schema="company:company_email"
            :validate="{ format: 'email' }"
            v-model="company_email"
          />
        </card-list-field>
        <card-list-field>
          <span> Company phone </span>
          <field schema="company:company_phone" format="phone" v-model="company_phone" />
        </card-list-field>
        <card-list-field :mobileStack="true">
          <span> Company type </span>
          <choose
            size="md"
            multiple
            :validate="{ required: false }"
            :filters="{ company_id: `${company_id}||NULL` }"
            schema="company:trade_type_ids"
            v-model="trade_type_ids"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section v-if="!company_id">
      <template #title>
        <span> Primary contact </span>
      </template>
      <card-list>
        <card-list-field>
          <span>User</span>

          <choose
            size="md"
            :return-array="false"
            schema="user:user_id"
            v-model="userId"
            :validate="{ required: true }"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <template #title>
        <span>Company address</span>
      </template>

      <card-list>
        <card-list-field>
          <span>Address</span>
          <field :validate="{ required: false }" v-model="company_address" />
        </card-list-field>

        <card-list-field>
          <span></span>
          <field v-model="company_suite" />
        </card-list-field>

        <card-list-field>
          <span>City</span>
          <field :validate="{ required: false }" v-model="company_city" />
        </card-list-field>

        <card-list-field>
          <span>Country</span>

          <choose
            size="md"
            :return-array="false"
            schema="user:country_id"
            v-model="country_id"
            :default="2"
            :validate="{ required: false }"
            :forceNotEmpty="true"
          />
        </card-list-field>

        <card-list-field>
          <span>State/Province</span>
          <choose
            size="md"
            :return-array="false"
            :allowDeselect="false"
            schema="vendor:province_id"
            :filters="{ country_id }"
            v-model="province_id"
          />
        </card-list-field>

        <card-list-field>
          <span>Postal/Zip Code</span>
          <field v-model="company_postal" />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <template #title>
        <span>Currency</span>
      </template>
      <card-list>
        <card-list-field>
          <span>Currency</span>
          <choose
            size="md"
            :return-array="false"
            :allowDeselect="false"
            :validate="{ required: true }"
            schema="company:currency_id"
            v-model="currency_id"
          />
        </card-list-field>
        <card-list-field>
          <span>Tax</span>
          <choose
            size="md"
            :return-array="false"
            :allowDeselect="true"
            :filters="{ province_id: `${province_id}||NULL` }"
            schema="company:tax_id"
            v-model="tax_id"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <template #title>
        <span>Extra details</span>
      </template>
      <card-list>
        <card-list-field>
          <span>Company website</span>
          <field
            :validate="{
              type: 'url'
            }"
            v-model="company_website"
          />
        </card-list-field>

        <card-list-field>
          <span>
            Business registration number
            <small> (US: EIN, CA: GST#) </small>
          </span>
          <field v-model="company_business_number" />
        </card-list-field>

        <card-list-field>
          <span>Workers comp. insurance ID number</span>
          <field v-model="company_wcb_number" />
        </card-list-field>

        <card-list-field>
          <span
            >Liability company and number
            <small> (&lt;insurer&gt;:&lt;number&gt;) </small>
          </span>
          <field v-model="company_insurance_number" />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section>
      <template #title>
        <span>Company bio</span>
      </template>
      <ClassicEditor size="md" v-model="companyBio" basic />
    </card-section>

    <card-section>
      <template #title>
        <span>Company social</span>
      </template>
      <card-list>
        <card-list-field>
          <div class="flex !flex-row !items-center !justify-start">
            <font-awesome-icon :icon="['fab', 'facebook']" class="mr-2 !my-0" /> Facebook
          </div>
          <field
            :validate="{
              type: 'url'
            }"
            @blur="(val) => updateOMeta('facebook', val)"
            v-model="oMeta.facebook"
          />
        </card-list-field>
        <card-list-field>
          <div class="flex !flex-row !items-center !justify-start">
            <font-awesome-icon :icon="['fab', 'x-twitter']" class="mr-2 !my-0" /> X (twitter)
          </div>
          <field
            :validate="{
              type: 'url'
            }"
            @blur="(val) => updateOMeta('x-twitter', val)"
            v-model="oMeta['x-twitter']"
          />
        </card-list-field>
        <card-list-field>
          <div class="flex !flex-row !items-center !justify-start">
            <font-awesome-icon :icon="['fab', 'linkedin']" class="mr-2 !my-0" /> LinkedIn
          </div>
          <field
            :validate="{
              type: 'url'
            }"
            @blur="(val) => updateOMeta('linkedin', val)"
            v-model="oMeta.linkedin"
          />
        </card-list-field>
        <card-list-field>
          <div class="flex !flex-row !items-center !justify-start">
            <font-awesome-icon :icon="['fab', 'instagram']" class="mr-2 !my-0" /> Instagram
          </div>
          <field
            :validate="{
              type: 'url'
            }"
            @blur="(val) => updateOMeta('instagram', val)"
            v-model="oMeta.instagram"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <CardSection>
      <CardList>
        <CardListField>
          <span>
            <span>Company lead form</span>
            <small> Embed a custom form into the lead form. </small>
          </span>
          <Choose schema="lead_rotation:lead_rotation_name" v-model="lead_rotation_id" />
        </CardListField>
      </CardList>
    </CardSection>

    <card-section>
      <company-estimating-settings :key="refId" :reference="refId" />
    </card-section>

    <card-section>
      <CompanyProjectSettings
        v-if="schedulerEnabled"
        :current="scheduleSettings"
        @updated="onProjectSettingsChange"
      />
    </card-section>

    <card-section v-if="$store.state.session.user.user_is_super_user" class="!mt-0">
      <template #title>
        <span>Account managers</span>
      </template>
      <template #label>
        <span class="text-blue-print-700 text-sm">(Only visible to superusers)</span>
      </template>
      <card-list>
        <card-list-field :mobileStack="true">
          <span>
            Customer Success Manager
            <small class="text-info">
              The Customer Success Manager assigned to support this company.
            </small>
          </span>
          <choose
            size="md"
            class="ml-5"
            :multiple="false"
            :staticSet="accountManagerIds"
            @close="handleAccountManagerClose"
            v-model="chosenAccountManagers.csm"
          />
        </card-list-field>
        <card-list-field :mobileStack="true">
          <span>
            Relationship Manager
            <small class="text-info">
              The Relationship Manager assigned to support this company.
            </small>
          </span>
          <choose
            size="md"
            class="ml-5"
            :multiple="false"
            :staticSet="accountManagerIds"
            @close="handleAccountManagerClose"
            v-model="chosenAccountManagers.rm"
          />
        </card-list-field>
        <card-list-field :mobileStack="true">
          <span>
            Account Executive
            <small class="text-info">
              The Account Executive assigned to support this company.
            </small>
          </span>
          <choose
            size="md"
            class="ml-5"
            :multiple="false"
            :staticSet="accountManagerIds"
            @close="handleAccountManagerClose"
            v-model="chosenAccountManagers.ae"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section v-if="$store.state.session.user.user_is_super_user && !newCompany">
      <template #title>
        <span>Onboarding information</span>
      </template>
      <template #label>
        <span class="text-blue-print-700 text-sm">(Only visible to superusers)</span>
      </template>
      <div class="border border-surface-300 rounded p-6">
        <div class="flex flex-col gap-y-6 *:flex *:flex-row *:justify-between *:gap-x-4">
          <div>
            <div class="flex flex-col">
              <p>Monthly quotes sent</p>
              <small class="text-surface-400"
                >The rough number of quotes this company sends per month</small
              >
            </div>
            <p>{{ formatOnboardingData(onboardingData?.get('quotes_per_month')) }}</p>
          </div>
          <div>
            <div class="flex flex-col">
              <p>Quotes that turn into jobs</p>
              <small class="text-surface-400"
                >The percentage of quotes this company turns into booked jobs, on average</small
              >
            </div>
            <p>{{ onboardingData?.get('quotes_booked') || 'N/A' }}</p>
          </div>
          <div>
            <div class="flex flex-col">
              <p>Seasonal business</p>
              <small class="text-surface-400"
                >Whether or not this company operates seasonally</small
              >
            </div>
            <p>{{ onboardingData?.get('is_seasonal') || 'N/A' }}</p>
          </div>
          <div>
            <div class="flex flex-col">
              <p>Yearly revenue</p>
              <small class="text-surface-400">The average yearly revenue this company earns</small>
            </div>
            <p>{{ formatOnboardingData(onboardingData?.get('yearly_revenue'), true) }}</p>
          </div>
          <div>
            <div class="flex flex-col">
              <p>Work subcontracted</p>
              <small class="text-surface-400"
                >The percentage of work subcontracted for this company's booked jobs</small
              >
            </div>
            <p>{{ onboardingData?.get('work_subcontracted_percent') || 'N/A' }}</p>
          </div>
          <div>
            <div class="flex flex-col">
              <p>Number of employees</p>
              <small class="text-surface-400">The number of employees in this company</small>
            </div>
            <p>{{ onboardingData?.get('employees') || 'N/A' }}</p>
          </div>
          <div>
            <div class="flex flex-col">
              <p>Number of employees using Bolster</p>
              <small class="text-surface-400"
                >The number of employees in this company using Bolster</small
              >
            </div>
            <p>{{ onboardingData?.get('employees_using_software') || 'N/A' }}</p>
          </div>
        </div>
      </div>
    </card-section>

    <card-section v-if="$store.state.session.user.user_is_super_user">
      <template #title>
        <span>Company admin settings</span>
      </template>
      <template #label>
        <span class="text-blue-print-700 text-sm">(Only visible to superusers)</span>
      </template>
      <card-list>
        <card-list-field :mobileStack="true">
          <span>
            Account lock
            <small class="text-info">
              After doing a demo, you can unlock this contractors account so they can access their
              account. They cannot access their account without this being unlocked.
            </small>

            <small class="text-danger">
              If the account is locked, they will not be able to access their account, regardless of
              status, whether they are in free-trial or subscribed.
            </small>
          </span>
          <div>
            <SelectionToggle
              v-model="company_is_locked"
              :options="[
                {
                  text: 'Locked',
                  value: 1
                },
                {
                  text: 'Unlocked',
                  value: 0
                }
              ]"
            />
          </div>
        </card-list-field>
        <card-list-field :mobileStack="true">
          <span>
            Company has onboarded
            <small class="text-info">
              If not onboarded, users will have to complete an onboarding form when logging into
              their company for the first time.
            </small>
          </span>
          <div>
            <SelectionToggle
              v-model="company_has_onboarded"
              :options="[
                {
                  text: 'Onboarded',
                  value: 1
                },
                {
                  text: 'Not Onboarded',
                  value: 0
                }
              ]"
            />
          </div>
        </card-list-field>

        <card-list-field :mobileStack="true">
          <span>
            Trial time starts
            <small class="text-info">
              Based on the start time, this free trial will expire on
              <strong>
                {{
                  $f.date(
                    (company_time_trial_start || company_time_created) + 1000 * 60 * 60 * 24 * 14
                  )
                }}
              </strong>
            </small>
          </span>
          <DatePicker v-model="company_time_trial_start" />
        </card-list-field>

        <card-list-field>
          <span>
            Stripe subscription ID
            <small class="text-danger">
              For lifetime access or other grandfathered subscriptions use "grandfathered_lifetime".
            </small>
          </span>
          <field schema="company:stripe_subscription_id" v-model="stripe_subscription_id" />
        </card-list-field>

        <card-list-field>
          <span> Stripe customer ID/'s </span>
          <Choose
            :disabled="customerLinkLoading"
            :return-array="true"
            :value="asCompanyStripeCustomers"
            @input="setCompanyStripeCustomers"
            schema="subscription:mock"
            searchMethod="searchStripeCustomers"
            :formatter="
              (customer) => ({
                html: `${customer.name} (${customer.id})`,
                text: `${customer.name} (${customer.id})`,
                value: customer.id
              })
            "
            :filters="{ country: 'usa' }"
            searchPlaceholder="Input a customer name"
            :btnClass="!asCompanyStripeCustomers ? '!text-red-600' : ''"
            multiple
          />
        </card-list-field>

        <card-list-field>
          <span> Stripe payment method ID </span>
          <field schema="company:stripe_payment_method" v-model="stripe_payment_method" />
        </card-list-field>

        <card-list-field :mobileStack="true" v-if="company_id">
          <span>
            Modules
            <small class="text-info"> Company's access to modules </small>
          </span>
          <choose
            size="md"
            class="ml-5"
            schema="company:aoModules"
            :multiple="true"
            v-model="chosenModules"
            @close="handleModulesClose"
            :filters="{ module_id: '!24' }"
          />
        </card-list-field>

        <card-list-field :mobileStack="true" v-if="!newCompany">
          <span>
            Switch to Adyen Payments
            <small class="text-info">
              Switch this company from Stripe to Adyen payments. The Stripe module will be removed
              and the Adyen module added.
            </small>

            <small class="text-danger">
              This will remove the company's counterparty information, they will need to undergo the
              payments onboarding process again.
            </small>
          </span>
          <span>
            <Btn severity="danger" size="lg" @click="confirmSwitchToAdyen"> Switch </Btn>
          </span>
        </card-list-field>
      </card-list>
    </card-section>

    <card-section v-if="$store.state.session.user.user_is_super_user && !newCompany">
      <template #title>
        <span>Franchise settings</span>
      </template>
      <template #label>
        <span class="text-blue-print-700 text-sm">(Only visible to superusers)</span>
      </template>
      <card-list>
        <card-list-field :mobileStack="true" v-if="!company_is_franchisor">
          <span>
            Convert to franchisor
            <small>
              Franchisors are blank parent companies to franchisees. They can see special data
              metrics on their franchisees, create shared catalog items, and login to each
              franchisee company.
            </small>
            <small class="!text-deep-red-700">
              Once a company has been converted to a franchisor, it cannot be undone! Only convert
              newly created, blank companies to franchisors.
            </small>
          </span>
          <span>
            <Btn severity="danger" size="lg" @click="confirmCreateFranchisor"> Convert </Btn>
          </span>
        </card-list-field>
        <card-list-field :mobileStack="true" v-if="company_is_franchisor">
          <span>
            Franchisees
            <small class="text-info"> The companies that are under this franchisor. </small>
            <small class="!text-deep-red-700">
              Warning: Changes will be applied as soon as you select them!
            </small>
          </span>
          <choose
            size="md"
            class="ml-5"
            schema="company:company_id"
            :return-array="true"
            :multiple="true"
            v-model="chosenFranchisees"
            @close="handleFranchiseesClose"
          />
        </card-list-field>
      </card-list>
    </card-section>

    <card-section v-if="$store.state.session.user.user_is_super_user">
      <template #title>
        <span>Feature management</span>
      </template>
      <template #label>
        <span class="text-blue-print-700 text-sm">(Only visible to superusers)</span>
      </template>
      <FeatureManagement :companyIsAmerican="isAmerican" :companyId="company_id" />
    </card-section>
  </Container>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import FeatureManagement from '@/components/bodies/FeatureManagement.vue'
import CompanyEstimatingSettings from '../company/CompanyEstimatingSettings.vue'
import DatePicker from '../ui/DatePicker.vue'
import CompanyProjectSettings from '../company/CompanyProjectSettings.vue'
import { workdays } from '../schedule/fields/defaultWorkdays'
import { defineAsyncComponent } from 'vue'

const ClassicEditor = defineAsyncComponent(
  () => import('@/components/ui/DocumentEditor/ClassicEditor.vue')
)
export default {
  name: 'CompanyEdit',
  components: {
    ClassicEditor,
    CompanyEstimatingSettings,
    DatePicker,
    CompanyProjectSettings,
    FeatureManagement
  },

  props: {
    deselectOnDestroy: {
      default: false
    },
    newCompany: {
      default: false
    }
  },

  mixins: [ObjectManipulator('company', false), BodyMixin /* AutoSave */],

  data() {
    return {
      vendors: [],
      searchLoading: 0,
      emailValid: false,
      searchByPhone: false,
      chosen: false,
      chosenUser: null,
      chosenCompany: null,
      noEmail: false,
      primaryExtension: false,
      chosenModules: '',
      chosenFranchisees: [],
      accountManagerIds: [],
      stripeCustomers: this.object.asCompanyStripeCustomers,
      customerLinkLoading: false,
      chosenAccountManagers: {
        csm: '',
        rm: '',
        ae: ''
      }
    }
  },

  computed: {
    companyBio: {
      get() {
        return this.company_bio
      },
      set(val) {
        this.company_bio = c.sanitize(val)
        return val
      }
    },
    userId: {
      get() {
        return this.user_id
      },
      async set(id) {
        this.user_id = id

        const { object: user } = await this.$store.dispatch('User/resolveObject', { id })

        const coalesce = [
          'localization_id',
          'country_id',
          'province_id',
          'currency_id',
          'timezone_id',
          ['company_address', 'user_address'],
          ['company_suite', 'user_suite'],
          ['company_postal', 'user_postal'],
          ['company_city', 'user_city'],
          ['company_phone', 'user_phone']
        ]

        coalesce.forEach((field) => {
          const fa = c.makeArray(field)
          const toField = fa[0]
          const fromField = fa.length > 1 ? fa[1] : fa[0]

          this[toField] = this[toField] || user[fromField] || null
        })
      }
    },
    companyScope() {
      return this.$store.state.session.scope.company && this.$store.state.session.company
    },
    scheduleSettings() {
      return {
        asWorkdays:
          this.asWorkdays && this.asWorkdays.length > 0
            ? this.asWorkdays.map((i) => parseInt(i, 10))
            : workdays,
        oWorkingHours: this.oWorkingHours
      }
    },
    schedulerEnabled() {
      return (
        this.$store.state.session.company.aoModules &&
        this.$store.state.session.company.aoModules.simple_scheduler &&
        this.$store.state.session.company.aoModules.simple_scheduler === '1'
      )
    },
    isAmerican() {
      return this.country_id && this.country_id === 2
    },
    onboardingData() {
      const onboarding = this.$store.state.session.company.oMeta?.onboarding
      if (!onboarding) return null

      const formatted = new Map()
      Object.keys(onboarding).forEach((key) => {
        if (key === 'quotes_booked' || key === 'work_subcontracted_percent') {
          formatted.set(key, `${onboarding[key] * 100}%`)
        } else if (key === 'is_seasonal') {
          formatted.set(key, onboarding[key] ? 'Seasonal' : 'Not Seasonal')
        } else {
          formatted.set(key, onboarding[key])
        }
      })
      return formatted
    }
  },
  methods: {
    async setCompanyStripeCustomers(val) {
      this.customerLinkLoading = true
      const removed =
        this.stripeCustomers?.reduce((acc, cur) => {
          if (!(val || []).includes(cur)) acc.push(cur)
          return acc
        }, []) || []

      const added =
        val?.reduce((acc, cur) => {
          if (!(this.stripeCustomers || []).includes(cur)) acc.push(cur)
          return acc
        }, []) || []

      console.log(removed, added)

      for (const customerId of removed) {
        try {
          await this.$store.dispatch('Company/unlinkStripeCustomer', {
            companyId: this.company_id,
            stripeCustomerId: customerId
          })

          this.stripeCustomers = this.stripeCustomers.filter((customer) => customer !== customerId)

          this.$store.dispatch('alert', {
            error: false,
            message: `Successfully unlinked customer ${customerId}`
          })
        } catch (error) {
          console.error(error)
          this.$store.dispatch('alert', {
            error: true,
            message: `Failed to unlink customer ${customerId}`
          })
        }
      }

      for (const customerId of added) {
        try {
          await this.$store.dispatch('Company/linkStripeCustomer', {
            companyId: this.company_id,
            stripeCustomerId: customerId
          })

          this.stripeCustomers.push(customerId)

          this.$store.dispatch('alert', {
            error: false,
            message: `Successfully linked customer ${customerId}`
          })
        } catch (error) {
          console.error(error)
          this.$store.dispatch('alert', {
            error: true,
            message: `Failed to link customer ${customerId}`
          })
        }
      }

      this.customerLinkLoading = false
    },
    updateOMeta(field, event) {
      const val = event.target.value
      const oMeta = {
        ...this.oMeta,
        [field]: val
      }
      this.setField('oMeta', oMeta)
    },
    onProjectSettingsChange(settings) {
      Object.keys(settings).forEach((k) => {
        this[k] = settings[k]
      })
    },
    async makeChosenModules() {
      if (this.company_id) {
        this.chosenModules = await this.$store.dispatch('Company/getEnabledModuleIds', {
          company_id: this.company_id
        })
      }
    },
    async handleModulesClose() {
      await this.$store.dispatch('Company/setEnabledModuleIds', {
        company_id: this.company_id,
        ids: this.chosenModules
      })
    },
    async handleFranchiseesClose() {
      await this.$store.dispatch('Company/setFranchisees', {
        company_id: this.company_id,
        franchisee_ids: this.chosenFranchisees
      })
      this.makeChosenFranchisees()
    },
    async confirmSwitchToAdyen() {
      await this.$store.dispatch('modal/confirm', {
        message:
          'Are you sure you want to switch this company to Adyen payments? They will need to undergo the payments onboarding process again.',
        actions: {
          confirm: {
            title: 'Continue',
            action: () => this.switchToAdyen()
          },
          cancel: {
            title: 'Cancel'
          }
        }
      })
    },
    async confirmCreateFranchisor() {
      await this.$store.dispatch('modal/confirm', {
        message:
          'Are you sure you want to convert this company to a franchisor? This action cannot be undone.',
        actions: {
          confirm: {
            title: 'Convert',
            action: () => this.createFranchisor()
          },
          cancel: {
            title: 'Cancel'
          }
        }
      })
    },
    async getModuleIDByHandle(handle) {
      const { set } = await this.$store.dispatch('Module/search', {
        searchPhrase: handle,
        fields: ['module_id', 'module_handle']
      })
      return Object.values(set).find((m) => m.module_handle === handle).module_id
    },
    async switchToAdyen() {
      // Fetch the module ids for the Stripe and Adyen modules
      const stripeID = await this.getModuleIDByHandle('invoice_payments')
      const adyenID = await this.getModuleIDByHandle('payments_v2')

      // Deactivate the company's Stripe counterparty
      try {
        await this.$store.dispatch('ajax', {
          path: '/counterparty/deactivateCounterparty',
          data: {
            company_id: this.company_id,
            counterparty_type: 'stripe'
          }
        })
      } catch (e) {
        throw new Error('Failed to deactivate Stripe counterparty')
      }

      // Exclude stripe_id it from the new list of enabled modules
      // Add adyen_id to the list of enabled modules
      const newModules = this.chosenModules.split(',').filter((m) => m !== stripeID.toString())
      newModules.push(adyenID.toString())

      // Update this company's chosen modules
      await this.$store.dispatch('Company/setEnabledModuleIds', {
        company_id: this.company_id,
        ids: newModules.toString()
      })
      this.makeChosenModules()
      window.location.reload()
    },
    async createFranchisor() {
      await this.$store.dispatch('Company/createFranchisor', {
        company_id: this.company_id
      })
      this.$router.go()
    },
    /**
     * Create an object of the form { value, text } for a given user.
     * where value = their user_id and text = their full name.
     */
    makeAccountManagerOption(user) {
      if (!user.user_fname) return null
      return {
        value: user.user_id,
        text: `${user.user_fname} ${user.user_lname ? user.user_lname : ''}`
      }
    },
    /**
     * Retrieve all account manager ids as an array of { value, text } objects.
     * Note that account managers are equivalent to superusers in this context.
     */
    async getAccountManagerIds() {
      if (!this.$store.state.session.user.user_is_super_user) return
      const { payload } = await this.$store.dispatch('ajax', {
        path: '/user/getSuperUsers'
      })
      Object.values(payload).forEach((user) => {
        this.accountManagerIds.push(this.makeAccountManagerOption(user))
      })
      this.accountManagerIds = this.accountManagerIds.filter((user) => user !== null)
    },
    /**
     * Query for the saved object of chosen account managers.
     */
    async makeChosenAccountManagers() {
      if (this.company_id && this.$store.state.session.user.user_is_super_user) {
        const oAccountManagers = await this.$store.dispatch('Company/getChosenAccountManagerIds', {
          company_id: this.company_id
        })
        if (oAccountManagers) {
          this.chosenAccountManagers.csm = oAccountManagers.csm
          this.chosenAccountManagers.rm = oAccountManagers.rm
          this.chosenAccountManagers.ae = oAccountManagers.ae
        }
      }
    },
    /**
     * Query for franchisees.
     */
    async makeChosenFranchisees() {
      if (this.company_id && this.$store.state.session.user.user_is_super_user) {
        const franchiseeIds = await this.$store.dispatch('Company/getFranchiseeIds', {
          company_id: this.company_id
        })
        if (franchiseeIds?.value !== null) {
          this.chosenFranchisees = franchiseeIds
        }
      }
    },
    /**
     * Called when the account manager choose is closed.
     * Passes the currently scoped company_id and an object
     * of CSM, RM, and AE user_ids
     */
    async handleAccountManagerClose() {
      // Throttle to ensure the choose v-model has updated first
      c.throttle(
        () => {
          this.$store.dispatch('Company/setChosenAccountManagerIds', {
            company_id: this.company_id,
            oAccountManagers: {
              csm: this.chosenAccountManagers.csm,
              rm: this.chosenAccountManagers.rm,
              ae: this.chosenAccountManagers.ae
            }
          })
        },
        { delay: 1000 }
      )
    },
    formatOnboardingData(data, currency = false) {
      if (!data) return 'N/A'
      const range = data.split('-')
      if (range.length && range.length > 1) {
        return (
          (currency ? '$' : '') +
          Number(range[0]).toLocaleString() +
          ' - ' +
          (currency ? '$' : '') +
          Number(range[1]).toLocaleString()
        )
      } else if (range.length) {
        return (currency ? '$' : '') + Number(range[0]).toLocaleString()
      }
      return 'N/A'
    }
  },
  async mounted() {
    // Populate account manager choose with options
    this.getAccountManagerIds()
    // Get selected options chooses
    c.throttle(
      () => {
        this.makeChosenModules()
        this.makeChosenAccountManagers()
        this.makeChosenFranchisees()
      },
      { delay: 1000 }
    )
  }
}
</script>
