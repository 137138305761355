<template>
  <div v-if="templateFull && !templateLoading" class="flex justify-between w-full">
    <div class="col-span-1 flex items-center h-full">
      <Choose
        :key="`settings${presentationTemplateId}`"
        data-label="choose_presentation"
        :return-array="false"
        schema="quote:presentation_template_id"
        :filters="{ template_type_id: 101 }"
        :allowCreate="false"
        v-model="presentationTemplateId"
        :multiple="false"
        :allowDeselect="false"
      >
        <template #default="{ text }">
          <Btn
            :pt="{ root: '!justify-start' }"
            size="lg"
            link
            :stop="false"
            :prevent="false"
            :loading="loading || templateLoading"
          >
            <span>Theme:</span>
            <font-awesome-icon icon="keynote" fixed-width />
            {{ text || 'Default' }}
            <font-awesome-icon icon="chevron-down" />
          </Btn>
        </template>
      </Choose>
      <Btn size="lg" link :action="saveTemplateAsNew">
        <font-awesome-icon icon="plus" />Create new theme
      </Btn>
    </div>
    <div class="flex justify-between items-center col-span-3 px-4">
      <span class="gap-2 flex w-full justify-end">
        <Btn link size="lg" :action="cancel">
          <font-awesome-icon icon="times" />
          <spam class="hidden md:block">Close</spam>
        </Btn>
      </span>
    </div>
  </div>
  <div v-else class="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
    <div class="col-span-1">
      <Skeleton height="2.5em" width="100%" class="!bg-pitch-black" />
    </div>
    <div class="col-span-3">
      <Skeleton height="2.5em" width="100%" class="!bg-pitch-black" />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { usePresentationManager } from '@/components/quote/presentation/usePresentationManager.js'
import { useStore } from 'vuex'

const emit = defineEmits(['cancel'])

const props = defineProps({
  tab: {
    type: String,
    default: 'settings-presentation'
  },
  refId: {
    required: true
  },
  store: {
    default: 'Quote',
    required: true
  },
  type: {
    default: 'quote'
  }
})

const { loading } = EntityComputedFields.useEntityComputedFields({
  store: props.store,
  refId: props.refId,
  type: props.type
})

const {
  unsavedChanges,
  saveTemplateAsNew,
  saveTemplate,
  templateFull,
  presentationTemplateId,
  loading: templateLoading
} = usePresentationManager({
  refId: props.refId,
  store: props.store,
  autoSave: true
})

const $store = useStore()
const cancel = async () => {
  if (
    unsavedChanges.value &&
    (await $store.dispatch('modal/asyncConfirm', {
      message: 'Save your changes?'
    }))
  ) {
    await saveTemplate()
  }

  emit('cancel')
}
</script>
