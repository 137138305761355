import { ref, watch } from 'vue'
import * as xlsx from 'xlsx'
import Fuse from 'fuse.js'
import { useFields } from '@/components/importers/fields'

export function useImportSpreadsheet({ global, schema }) {
  const workbook = ref(null)
  const sheetData = ref([])
  const columnsMapToField = ref({})
  const selectedSheet = ref(null)
  const resetting = ref(false)
  const globalVendorFieldFound = ref(false)

  const { supportedFields } = useFields(schema)

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  async function uploadFileHandler(event) {
    const file = event.files[0]
    const isSpreadsheet =
      /^(application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet|application\/vnd\.ms-excel|application\/csv|text\/csv|application\/vnd\.oasis\.opendocument\.spreadsheet|application\/excel|application\/x-excel|application\/x-msexcel|application\/x-dos_ms_excel)$/.test(
        file.type
      )

    if (isSpreadsheet) {
      const reader = new FileReader()

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        workbook.value = xlsx.read(data, { type: 'array' })
      }

      reader.readAsArrayBuffer(file)
    } else {
      throw new Error('File is not of the supported spreadsheet types')
    }
  }

  async function downloadImportTemplate() {
    const wb = xlsx.utils.book_new()
    const templateFields = supportedFields.value.filter((sf) => sf.inTemplate !== false)
    const header = templateFields.map((sf) => `${sf.header} (${sf.desc})`)
    const required = templateFields.map((sf) => (sf.required ? 'Required' : 'Optional'))
    const example = templateFields.map((sf) => (sf.example ? `Ex. ${sf.example}` : ''))

    const ws = xlsx.utils.aoa_to_sheet([header, required, example])
    xlsx.utils.book_append_sheet(wb, ws, `${capitalizeFirstLetter(schema)} import template`)
    xlsx.writeFile(wb, `${capitalizeFirstLetter(schema)}ImportTemplate.xlsx`)
  }

  async function selectSheet(sheetName) {
    sheetData.value = xlsx.utils.sheet_to_json(workbook.value?.Sheets[sheetName])

    fieldMapper()
  }

  function fieldMapper() {
    let foundColumns = _.uniq(
      sheetData.value.reduce((acc, obj) => [...acc, ...Object.keys(obj).map((f) => String(f))], [])
    )

    // If a superuser is doing a global import, check for vendor fields attached to items
    // Global items shouldn't have vendors attached to them, so filter vendor fields out
    if (global) {
      const filteredColumns = foundColumns.filter(
        (column) => !column.toLowerCase().includes('vendor')
      )
      globalVendorFieldFound.value = foundColumns.length !== filteredColumns.length
      foundColumns = filteredColumns
    }

    columnsMapToField.value = {}

    const fuse = new Fuse(supportedFields.value, {
      minMatchCharLength: 2,
      keys: ['header', 'keywords', 'desc']
    })

    foundColumns
      .filter((column) => !column.toLowerCase().includes('vendor name'))
      .forEach((f) => {
        const exact = supportedFields.value.find(
          (sf) =>
            sf.field ===
            String(f)
              .toLowerCase()
              .replace(/_index$/, '')
        )

        if (exact) {
          columnsMapToField.value[f] = exact.field
          return
        }

        if (/EMPTY/.test(f)) {
          columnsMapToField.value[f] = null
        }

        const ao = fuse.search(f)
        const existingKeys = Object.keys(columnsMapToField.value)
        const filtered = ao.filter((o) => !existingKeys.includes(o.item.field))
        if (filtered.length) {
          columnsMapToField.value[f] = filtered[0].item.field
          return
        }

        // Includes
        const parts = f.split(/\s|_|-/)
        const alreadyAssigned = Object.values(columnsMapToField.value)

        let foundIncludes = supportedFields.value.filter(
          (sf) => !alreadyAssigned.includes(sf.field)
        )

        foundIncludes = foundIncludes.filter((sf) =>
          parts.some((part) => `${sf.field}`.includes(part))
        )

        if (foundIncludes.length) {
          columnsMapToField.value[f] = foundIncludes[0].field
          return
        }

        columnsMapToField.value[f] = null
      })
  }

  function reset() {
    resetting.value = true
    workbook.value = null
    sheetData.value = []
    selectedSheet.value = null
    columnsMapToField.value = {}
    globalVendorFieldFound.value = false
    resetting.value = false
  }

  watch(selectedSheet, (sheet) => {
    if (!resetting.value) {
      return selectSheet(sheet)
    }
  })

  return {
    workbook,
    sheetData,
    selectedSheet,
    selectSheet,
    columnsMapToField,
    globalVendorFieldFound,
    uploadFileHandler,
    downloadImportTemplate,
    reset
  }
}
