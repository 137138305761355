<template>
  <div class="alerts--container">
    <transition-group name="list-complete" tag="div" class="alerts--gutter">
      <alert
        :alert="alert"
        v-for="alert in alerts"
        :key="`${String(alert.text) || String(alert.message)}${String(alert.uid)}`"
        class="list-complete-item alerts--alert-item"
      />
      <div
        v-for="loading in loadings"
        :key="loading.message"
        class="list-complete-item alerts--alert-item relative max-w-[300px] rounded-sm px-4 py-3 flex justify-start items-center font-medium bg-pitch-black text-white overflow-hidden"
      >
        {{ loading.message }}
        <Loader :loading="Math.max(1, (loading.progress / loading.total) * 100)" exact />
      </div>
    </transition-group>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert.vue'
import Loader from '@/components/ui/Loader.vue'

export default {
  name: 'Alerts',
  computed: {
    alerts() {
      return this.$store.state.alert.alerts
    },
    loadings() {
      return this.$store.state.general.loading
    }
  },
  components: { Alert, Loader }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.alerts--container {
  z-index: 9999999999;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  padding: 3em 2em 5em 2em;
  pointer-events: none;
  display: flex;
  flex-direction: column-reverse;

  @media (max-width: 992px) {
    width: 100vw;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .alerts--alert-item {
    margin-top: 0.5em;
    transition: all 1s;
  }

  .list-complete-item {
    transition: all 1s;
    display: flex;
    right: 2em;
    position: relative;
  }
  .list-complete-enter, .list-complete-leave-to
      /* .list-complete-leave-active for <2.1.8 */ {
    opacity: 0;
    transform: translateY(3em);
  }
  .list-complete-leave-active {
    position: absolute;
    right: 0;
    transform: translateY(-3em);
    opacity: 0;
  }
}
</style>
