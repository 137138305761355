<template>
  <div class="flex flex-col justify-center items-stretch gap-2">
    <div class="flex flex-col justify-center items-stretch gap-12 py-6" v-if="!foundClients.length">
      <div class="flex flex-col justify-stretch gap-2">
        <div class="flex flex-col bg-surface-200/80 rounded-md m-1">
          <div
            class="flex justify-between items-center gap-2 p-2 px-4"
            v-if="!(chosen && chosenOption?.id)"
          >
            <div class="flex justify-start items-center gap-6 basis-4/5">
              <font-awesome-icon icon="house-flag" size="2xl" />

              <StringField
                ref="emailOrPhone"
                placeholder="Enter a mobile phone or email"
                :multiline="false"
                @keydown="(e) => focusHandler('firstNameField', e)"
                v-model="emailOrPhone"
                :class="[
                  'h-13 !py-2 px-4 !w-full !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap',
                  {
                    '!border-blue-print !border-solid': !emailOrPhone
                  }
                ]"
              />
            </div>
            <div class="flex justify-end items-end gap-2">
              <font-awesome-icon
                icon="sms"
                size="xl"
                v-if="isEmail === null || isEmail === false"
              />
              <span v-if="isEmail === null">or</span>
              <font-awesome-icon icon="envelope" size="xl" v-if="isEmail === null || isEmail" />
              <font-awesome-icon
                icon="fas fa-check"
                size="xl"
                v-if="isEmail !== null"
                class="text-blue-print animate-fadeIn"
              />
            </div>
          </div>

          <template v-if="chosen && chosenOption?.id">
            <div class="flex justify-between items-center gap-2 p-4 px-4">
              <div class="flex justify-start items-center gap-4 basis-4/5">
                <PersonAvatar
                  :id="chosenOption.id"
                  :name="chosenOption.name"
                  :type="chosenOption.type"
                  size="large"
                  :shape="chosenOption.type === 'user' ? 'circle' : 'square'"
                />
                <div class="flex flex-col gap-1">
                  <div class="text-lg font-medium leading-none">
                    {{ client_company_id ? `${company_name} • ` : '' }}
                    {{ user_fname }} {{ user_lname }}
                  </div>
                  <div class="text-xs font-medium text-surface-500 leading-none">
                    {{ client_company_id ? 'company' : 'user' }}
                  </div>
                </div>
              </div>
              <font-awesome-icon
                icon="fas fa-check"
                size="xl"
                v-if="isEmail !== null"
                class="text-blue-print animate-fadeIn"
              />
            </div>
          </template>
          <template v-else-if="!chosen && (emailOrPhone || emailSkipped) && displayClientDetails">
            <div class="flex justify-between items-center gap-2 p-2 px-4">
              <div class="flex justify-start items-center gap-4 basis-4/5">
                <font-awesome-icon icon="circle-user" size="2xl" class="pr-4" />
                <StringField
                  placeholder="First name"
                  :multiline="false"
                  @keydown="(e) => focusHandler('lastNameField', e)"
                  v-model="user_fname"
                  ref="firstNameField"
                  :class="[
                    'h-13 !py-2 px-4 !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap',
                    {
                      '!border-blue-print !border-solid !w-1/2': !user_fname
                    }
                  ]"
                />
                <StringField
                  placeholder="Last name"
                  :multiline="false"
                  ref="lastNameField"
                  v-model="user_lname"
                  @keydown="
                    (e) => {
                      focusHandler('mobilePhone', e)
                      focusHandler('email', e)
                    }
                  "
                  :class="[
                    'h-13 !py-2 px-4  !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap grow',
                    {
                      ' !w-1/2': !user_lname
                    }
                  ]"
                />
              </div>

              <div class="flex justify-end items-end gap-2">
                <font-awesome-icon
                  icon="fas fa-check"
                  size="xl"
                  v-if="user_fname"
                  class="text-blue-print animate-fadeIn"
                />
              </div>
            </div>
          </template>

          <template
            v-if="!chosen && (emailOrPhone || emailSkipped) && displayClientDetails && isEmail"
          >
            <div class="flex justify-between items-center gap-2 p-2 px-4">
              <div class="flex justify-start items-center gap-4 basis-4/5">
                <font-awesome-icon icon="sms" size="2xl" class="pr-4" />
                <StringField
                  placeholder="Mobile phone"
                  :multiline="false"
                  v-model="user_phone"
                  ref="mobilePhone"
                  :class="[
                    'w-full h-13 !py-2 px-4 !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap',
                    { '!border-red-400 border-2 text-red-500': !phoneIsValid }
                  ]"
                />
              </div>

              <div class="flex justify-end items-end gap-2">
                <font-awesome-icon
                  icon="fas fa-check"
                  size="xl"
                  v-if="user_phone"
                  class="text-blue-print animate-fadeIn"
                />
              </div>
            </div>
          </template>
          <template
            v-if="
              !chosen && (emailOrPhone || emailSkipped) && displayClientDetails && isEmail === false
            "
          >
            <div class="flex justify-between items-center gap-2 p-2 px-4">
              <div class="flex justify-start items-center gap-4 basis-4/5">
                <font-awesome-icon icon="envelope" size="2xl" class="pr-4" />
                <StringField
                  placeholder="Email"
                  :multiline="false"
                  v-model="user_email"
                  ref="email"
                  :class="[
                    'w-full h-13 !py-2 px-4 !max-w-unset font-medium items-center justify-center text-xl whitespace-nowrap'
                  ]"
                />
              </div>

              <div class="flex justify-end items-end gap-2">
                <font-awesome-icon
                  icon="fas fa-check"
                  size="xl"
                  v-if="user_email"
                  class="text-blue-print animate-fadeIn"
                />
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="flex flex-col justify-stretch gap-2" v-if="clientChosen">
        <div class="text-xl font-medium">Name this estimate</div>
        <field
          schema="quote:quote_name"
          ref="quoteNameField"
          :validate="{ required: false }"
          v-model="name"
          placeholder="Use the address or client name if you're not sure..."
          class="!w-full h-14"
        />
      </div>
    </div>

    <div class="flex justify-end mt-5" v-if="!emailOrPhone && !emailSkipped">
      <btn-group style="margin-left: auto">
        <!--        <btn size="lg" :action="() => $emit('closeModal')" severity="tertiary-borderless">-->
        <!--          Cancel-->
        <!--        </btn>-->
        <btn size="lg" :action="skipEmailEntry" severity="tertiary-borderless"> Don't know </btn>
        <btn size="lg" :action="() => {}" severity="primary"> Next </btn>
      </btn-group>
    </div>
  </div>

  <div v-if="foundClients.length" class="flex flex-col gap-2 m-1">
    <div class="font-medium text-lg">A client with those details already found</div>
    <div class="flex flex-col bg-surface-200/80 rounded-md">
      <div class="flex justify-between items-center gap-2 p-4 px-4">
        <div class="flex justify-start items-center gap-4 basis-4/5">
          <PersonAvatar
            :id="foundClients[0].client_id"
            :name="foundClients[0].client_name"
            :type="'client'"
            size="large"
            :shape="foundClients[0].client_company_id ? 'square' : 'circle'"
          />
          <div class="flex flex-col gap-1">
            <div class="text-lg font-medium leading-none">
              {{ foundClients[0].client_name }}
              {{ foundClients[0].user_fname }} {{ foundClients[0].user_lname }}
            </div>
            <div class="text-xs font-medium text-surface-500 leading-none">
              {{ foundClients[0].client_company_id ? 'company' : 'residential client' }}
            </div>
          </div>
        </div>

        <Btn
          v-if="!getReviewer"
          link
          class="!text-blue-print"
          :to="`/client/${foundClients[0].client_id}`"
          >Open client<font-awesome-icon icon="fas fa-arrow-right" size="xl"
        /></Btn>
        <Btn v-else link class="!text-blue-print" :action="() => addReviewer(foundClients[0])">
          Add reviewer
        </Btn>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-4" v-if="(emailOrPhone || emailSkipped) && !foundClients?.length">
    <!--    <div v-if="!emailSkipped" class="flex flex-col gap-4">-->
    <!--      <h4>Start with an email</h4>-->
    <!--      <card-list>-->
    <!--        <card-list-field>-->
    <!--          <span>-->
    <!--            Email-->
    <!--            <small-->
    <!--              >Emails are required to send estimates <br />-->
    <!--              (but you can always add one later!)</small-->
    <!--            >-->
    <!--          </span>-->

    <!--          <field-->
    <!--            schema="client:user_email"-->
    <!--            :emit-delay="0"-->
    <!--            :validate="validationSchema.user_email"-->
    <!--            v-model="email"-->
    <!--            @submit="emailNextHandler"-->
    <!--          />-->
    <!--        </card-list-field>-->
    <!--      </card-list>-->
    <!--      <div v-if="!continued" class="flex justify-end">-->
    <!--        <btn-group>-->
    <!--          <Btn size="lg" severity="secondary" ref="btn" @click="skipEmailEntry"> Skip </Btn>-->
    <!--          <Btn-->
    <!--            size="lg"-->
    <!--            severity="primary-black"-->
    <!--            ref="btn"-->
    <!--            :loading="searchLoading"-->
    <!--            :disabled="!emailValid"-->
    <!--            @click="emailNextHandler"-->
    <!--          >-->
    <!--            Next-->
    <!--          </Btn>-->
    <!--        </btn-group>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-if="displaySearchResults && !chosen">
      <div v-if="searchLoading && !choiceOptions.length" class="flex flex-col gap-2">
        <div v-for="i in 3" :key="i" class="flex flex-row items-center gap-2 p-4">
          <Skeleton shape="circle" size="4rem" />
          <div class="flex flex-col gap-2 grow">
            <Skeleton shape="rectangle" width="5rem" />
            <Skeleton shape="rectangle" width="12rem" height="2rem" />
            <Skeleton shape="rectangle" width="4rem" />
          </div>
          <Skeleton shape="rectangle" width="12rem" height="2rem" />
        </div>
      </div>
      <div v-else-if="choiceOptions.length && !searchLoading" class="flex flex-col gap-2">
        <div class="text-xl font-medium">Choose from existing accounts</div>
        <div class="grid grid-nogutter bg-surface-200/80 rounded-md flex flex-col gap-1 p-2">
          <template v-for="(user, index) in choiceOptions" :key="`${user.name}`">
            <div
              class="col-12 rounded-md bg-transparent hover:bg-surface-300/30 pr-3"
              v-if="!chosen || chosenOption.id === user.id"
            >
              <div
                class="flex flex-row items-center justify-start p-2 gap-3"
                :class="{
                  'border-top-1 surface-border': index !== 0,
                  hidden: chosen && chosenOption.id !== user.id
                }"
              >
                <div class="md:w-10rem relative">
                  <PersonAvatar
                    :id="user.id"
                    :name="user.name"
                    :type="user.type"
                    size="xlarge"
                    :onClick="
                      () =>
                        chooseClient(
                          ...[
                            (user.type === 'user' && user.object) ||
                              (user.type === 'company' && user.object.user),
                            user.type === 'company' && user.object,
                            user.type === 'client' && user.object
                          ]
                        )
                    "
                    :shape="user.clientType === 'user' ? 'circle' : 'square'"
                  />
                </div>
                <div
                  class="flex flex-col md:flex-row justify-content-between md:align-items-center flex-1 gap-4"
                >
                  <div class="flex flex-row md:flex-col justify-content-between align-items-start">
                    <div class="flex flex-col justify-center items center gap-1">
                      <div class="text-xl font-medium text-900 leading-none">
                        <font-awesome-icon
                          :icon="user.type === 'company' ? 'briefcase' : 'circle-user'"
                          size="sm"
                        />
                        {{ user.name }}
                      </div>
                      <span class="font-medium text-surface-500 text-xs leading-none">{{
                        user.type
                      }}</span>
                      <div class="text-sm text-surface-400 leading-none" v-if="user.rating">
                        {{ user.rating || 'No rating yet' }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column md:align-items-middle gap-5">
                  <Checkbox
                    size="xxl"
                    @input="
                      (val) => {
                        if (val) {
                          chooseClient(
                            ...[
                              (user.type === 'user' && user.object) ||
                                (user.type === 'company' && user.object.user),
                              user.type === 'company' && user.object,
                              user.type === 'client' && user.object
                            ]
                          )
                        } else {
                          chosen = false
                        }
                      }
                    "
                    :value="chosenUser === user.object ? 1 : 0"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="flex justify-end mt-5" v-if="choiceOptions.length && !chosen">
          <btn-group style="margin-left: auto">
            <!--        <btn size="lg" :action="() => $emit('closeModal')" severity="tertiary-borderless">-->
            <!--          Cancel-->
            <!--        </btn>-->
            <btn
              size="lg"
              :action="
                () => {
                  displaySearchResults = false
                  displayClientDetails = true
                  chosen = false
                }
              "
              severity="tertiary-borderless"
            >
              None of these options match...
            </btn>
          </btn-group>
        </div>
      </div>
    </div>

    <div v-if="displayClientDetails && user_fname" class="flex flex-col">
      <template v-if="!chosen">
        <!--        <card-section>-->
        <!--          <template #label>-->
        <!--            <span> Contact </span>-->
        <!--          </template>-->
        <!--          <card-list>-->
        <!--            <card-list-field>-->
        <!--              <span>First name</span>-->
        <!--              <field-->
        <!--                :validate="validationSchema.user_fname"-->
        <!--                v-model="user_fname"-->
        <!--                schema="client:user_fname"-->
        <!--                ref="userFirstName"-->
        <!--                @field-mounted="firstNameMounted"-->
        <!--              />-->
        <!--            </card-list-field>-->
        <!--            <card-list-field>-->
        <!--              <span>Last name</span>-->
        <!--              <field-->
        <!--                :validate="validationSchema.user_lname"-->
        <!--                v-model="user_lname"-->
        <!--                schema="client:user_lname"-->
        <!--              />-->
        <!--            </card-list-field>-->
        <!--            <card-list-field>-->
        <!--              <span>Phone number</span>-->
        <!--              <div>-->
        <!--                <btn-->
        <!--                  size="sm"-->
        <!--                  severity="tertiary"-->
        <!--                  class="mr-2"-->
        <!--                  @click="primaryExtension = true"-->
        <!--                  v-if="primaryExtension !== true && !user_phone_ext"-->
        <!--                >-->
        <!--                  <font-awesome-icon icon="plus" />-->
        <!--                </btn>-->
        <!--              </div>-->
        <!--              <field-->
        <!--                :validate="{ required: false }"-->
        <!--                v-model="user_phone"-->
        <!--                schema="client:user_phone"-->
        <!--                format="phone"-->
        <!--              ></field>-->
        <!--            </card-list-field>-->
        <!--            <card-list-field v-if="primaryExtension === true || user_phone_ext">-->
        <!--              <span>Extension</span>-->
        <!--              <field-->
        <!--                v-model="user_phone_ext"-->
        <!--                schema="client:user_phone_ext"-->
        <!--                class="max-w-20"-->
        <!--              ></field>-->
        <!--            </card-list-field>-->
        <!--          </card-list>-->
        <!--        </card-section>-->

        <!-- commercial /res -->
        <card-section>
          <!--          <template #label>-->
          <!--            <span>Client type</span>-->
          <!--          </template>-->
          <selection-toggle
            :pt="stpt"
            class="ml-1 w-full"
            :options="commercialOptions"
            v-model="clientIsCommercial"
          />
          <card-list v-show="clientIsCommercial">
            <card-list-field>
              <span> Company name </span>
              <field
                schema="company:company_name"
                :validate="validationSchema.company_name"
                v-model="company_name"
              />
            </card-list-field>
            <card-list-field>
              <span> Company type </span>
              <choose
                multiple
                :validate="{ required: false }"
                schema="company:trade_type_ids"
                v-model="trade_type_ids"
              />
            </card-list-field>
          </card-list>
        </card-section>

        <card-section>
          <template #label>
            <span class="flex gap-2 items-center"
              ><font-awesome-icon icon="map-location" /> Address</span
            >
          </template>

          <div>
            <AddressForm v-model="clientAddress" :required="false" :inQuote="true" class="w-full" />
          </div>
        </card-section>
      </template>

      <card-section>
        <card-list>
          <card-list-field>
            <span>
              <div class="inline-flex gap-2 items-center">
                <font-awesome-icon icon="circle-user" />
                Account owner
              </div>
              <small>
                Choose the user that will be primarily responsible for managing this relationship.
                This contact and all quotes and invoices for them will show up in their lists. You
                can reassign this contact to someone else later.
              </small>
            </span>
            <choose
              :return-array="false"
              schema="client:client_owner"
              v-model="client_owner"
              :filters="{
                company_ids: `INSET${$store.state.session.company.company_id}`
              }"
              :allowDeselect="false"
              :default="$store.state.session.user.user_id"
            />
          </card-list-field>

          <card-list-field>
            <span>
              <div class="inline-flex gap-2 items-center">
                <font-awesome-icon icon="flag-swallowtail" />
                Lead source
              </div>
              <small> Where did this client/lead come from? (optional) </small>
              <small>
                If you received this client/lead from a particular marketing campaign or even by
                referral, you can track what you receive from each type of advertising so that you
                can determine your ROI.
              </small>
            </span>

            <choose
              :validation="{ required: false }"
              :return-array="false"
              schema="client:lead_source_id"
              v-model="lead_source_id"
            />
          </card-list-field>
        </card-list>
      </card-section>
      <!--      <card-section>-->
      <!--        <card-list>-->
      <!--          <card-list-field>-->
      <!--            <span>-->
      <!--              Notes-->
      <!--              <small>-->
      <!--                Provide notes about this contact, including what they are looking for, or any-->
      <!--                requests they have.-->
      <!--              </small>-->
      <!--            </span>-->

      <!--            <field type="textarea" v-model="activity_desc"></field>-->
      <!--          </card-list-field>-->
      <!--        </card-list>-->
      <!--      </card-section>-->

      <!--      <CardSection>-->
      <!--        <PrimePanel toggleable header="Advanced" collapsed>-->
      <!--          <card-list>-->
      <!--            <card-list-field>-->
      <!--              <span>Localization</span>-->
      <!--              <choose-->
      <!--                :return-array="false"-->
      <!--                :default="$store.state.session.user.localization_id"-->
      <!--                schema="user:localization_id"-->
      <!--                v-model="localization_id"-->
      <!--              />-->
      <!--            </card-list-field>-->
      <!--            <card-list-field>-->
      <!--              <span>-->
      <!--                Lead source-->
      <!--                <small> Where did this client/lead come from? (optional) </small>-->
      <!--                <small>-->
      <!--                  If you received this client/lead from a particular marketing campaign or even by-->
      <!--                  referral, you can track what you receive from each type of advertising so that you-->
      <!--                  can determine your ROI.-->
      <!--                </small>-->
      <!--              </span>-->

      <!--              <choose-->
      <!--                :validation="{ required: false }"-->
      <!--                :return-array="false"-->
      <!--                schema="client:lead_source_id"-->
      <!--                v-model="lead_source_id"-->
      <!--              />-->
      <!--            </card-list-field>-->
      <!--          </card-list>-->
      <!--        </PrimePanel>-->
      <!--      </CardSection>-->

      <!--      <Btn :action="saveClient" size="lg" severity="primary-black" class="w-full mt-4">-->
      <!--        {{ getReviewer ? 'Save client and add as reviewer' : 'Save' }}-->
      <!--      </Btn>-->
    </div>
    <div class="flex justify-end mt-5">
      <btn-group style="margin-left: auto">
        <btn size="lg" :action="() => $emit('closeModal')" severity="tertiary-borderless">
          Cancel
        </btn>
        <btn
          size="lg"
          :action="saveClient"
          :disabled="!user_fname && !(user_email || user_phone)"
          severity="primary"
          hotkey="cmd-s"
        >
          {{ getReviewer ? 'Save client and add as reviewer' : 'Save client' }}
        </btn>
      </btn-group>
    </div>
  </div>
</template>

<script>
import BodyMixin from '@/components/mixins/Body'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import Btn from '@/components/ui/Btn.vue'
import Field from '@/components/ui/Field.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'
import SelectionToggle from '@/components/ui/SelectionToggle.vue'
import AddressForm from '@/components/ui/forms/AddressForm.vue'
import { isValidPhoneNumber } from 'libphonenumber-js'

export default {
  mixins: [ObjectManipulator('client', true), BodyMixin],
  emits: ['addReviewer'],
  mounted() {
    c.throttle(() => this.$refs.emailOrPhone?.focus(), { delay: 50 })
  },
  data() {
    return {
      estimateFor: 'existing',
      name: '',
      id: null,
      loading: 0,
      rfq: 0,
      emailOrPhone: null,
      isEmail: null,
      firstName: '',
      lastName: '',

      foundClients: [],
      foundUsers: [],

      email: null,
      emailSkipped: false,
      displayClientDetails: false,
      displayAlreadyFound: false,
      showClientDetails: false,
      displaySearchResults: false,
      continued: false,
      clientIsCommercial: false,
      primaryExtension: false,
      clients: [],
      searchLoading: 0,
      chosen: false,
      chosenUser: null,
      chosenCompany: null,
      countryFilters: {
        country_id: this.$store.state.session.company.country_id
      },
      commercialOptions: [
        {
          icon: 'house-user',
          text: 'Residential',
          value: false
        },
        {
          icon: 'shop',
          text: 'Commercial',
          value: true
        }
      ],

      stpt: {
        button: ({ context, props }) => ({
          class: [
            'relative',
            // Font
            'leading-none',

            // Flex Alignment
            'inline-flex items-center align-bottom text-center',

            // Spacing
            'px-6 py-4 text-xl !font-medium w-1/2 text-center justify-center',

            // Shape
            'rounded-sm grow',

            // Color
            {
              'bg-transparent': !context.active,
              'text-cool-gray-700 dark:text-white/80': !context.active,
              'border-transparent': !context.active,
              '!bg-pitch-black text-white shadow-md': context.active
            },
            // Invalid State
            { 'border-red-500 dark:border-red-400': props.invalid },

            // States
            {
              'hover:bg-white hover:text-pitch-black hover:shadow-md dark:hover:bg-surface-800/80 ':
                !context.active
            },
            { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
            // Transition
            'transition duration-200',

            // Misc
            'cursor-pointer select-none overflow-hidden'
          ]
        })
      }
    }
  },
  watch: {
    async emailOrPhone(val, old) {
      if (val === old) return
      if (val) {
        this.displayClientDetails = false
        this.showClientDetails = false
        this.chosen = false
        this.searchLoading = 1
        this.displaySearchResults = true
      }
      c.throttle(() => this.setEmailOrPhone(val), { delay: 50 })
    },

    foundClients(found) {
      if (!found?.length) return
      if (found.length > 0) {
        this.displayAlreadyFound = true
      }
    },
    choiceOptions(ops) {
      if (ops > 1 && !this.foundClients?.length) this.displaySearchResults = true
    }

    // email(val) {
    //   this.continued = false
    //   this.displaySearchResults = false
    //   this.clients = []
    //   this.foundUsers = []
    //   this.chosenUser = null
    //   this.chosen = false
    //   if (this.emailValid) {
    //     this.user_email = val
    //   }
    // }
  },
  computed: {
    clientAddress: {
      get() {
        return {
          line1: this.user_address || '',
          line2: this.user_suite || '',
          city: this.user_city,
          country: this.country_id,
          state: this.province_id,
          postal_code: this.user_postal
        }
      },
      set(v) {
        this.user_address = v.line1
        this.user_suite = v.line2
        this.user_city = v.city
        this.country_id = v.country
        this.province_id = v.state
        this.user_postal = v.postal_code
      }
    },
    emailValid() {
      if (this.emailOrPhone) {
        return /^\S+@\S+\.\S+$/.test(this.emailOrPhone)
      }

      return false
    },
    chosenOption() {
      return this.choiceOptions.find(
        (co) =>
          (co.type === 'company' && String(co.id) === String(this.client_company_id)) ||
          (co.type === 'client' && String(co.id) === String(this.client_id)) ||
          (co.type === 'user' && String(co.id) === String(this.client_user_id))
      )
    },
    choiceOptions() {
      const options = []

      if (this.clients.length) {
        const cl = this.clients[0]
        if (cl.client_company_id) {
          options.push({
            name: cl.client_name,
            id: cl.client_id,
            rating: cl.client_user_rating,
            type: 'client',
            clientType: 'company',
            object: this.clients[0]
          })
        } else {
          options.push({
            name: cl.client_name,
            id: cl.client_id,
            rating: cl.client_user_rating,
            type: 'client',
            clientType: 'user',
            object: this.clients[0]
          })
        }

        return options
      }

      const companies = this.companies.map((u) => ({
        name: `${u.company_name}`,
        id: u.company_id,
        rating: u.company_rating,
        type: 'company',
        clientType: 'company',
        userId: u.user_id,
        object: u
      }))

      const users = this.foundUsers.map((u) => ({
        name: `${u.user_fname} ${u.user_lname}`,
        id: u.user_id,
        rating: u.user_rating,
        type: 'user',
        clientType: 'user',
        object: u
      }))

      return [...companies, ...users]
    },
    /**
     * Provide optional validation schema that checks
     * for correct formatting and that all required
     * values have been provided. If null it will sue
     * the (src/api/schema/{type}.js).fields validation
     * values to check against.
     */
    validationSchema() {
      const common = {
        user_fname: {
          required: true
        },
        user_email: {
          type: 'email'
        }
      }

      if (this.client_status === 'l') {
        return {
          ...common,
          user_fname: {
            required: true
          }
        }
      } else if (this.client_user_id || this.client_company_id) {
        return {}
      }

      if (this.clientIsCommercial && this.client_status !== 'l') {
        common.company_name = {
          required: true
        }
      }

      return {
        ...common,
        user_lname: {
          required: true
        }
      }
    },
    companies() {
      if (!this.foundUsers.length || this.getReviewer) {
        return []
      }

      return this.foundUsers.reduce(
        (acc, u) => [
          ...acc,
          ...u.aoCompanies
            .filter((co) => co.company_id && !acc.find((coo) => coo.company_id === co.company_id))
            .map((co) => ({
              ...co,
              user: u
            }))
        ],
        []
      )
    },
    address() {
      if (this.user_address && this.user_city) {
        return `${this.user_address ? this.user_address : ''} ${
          this.user_city ? this.user_city : ''
        } ${this.user_prov ? this.user_prov : ''} ${this.user_postal ? this.user_postal : ''} `
      }
      return ''
    },
    phoneIsValid() {
      if (this.user_phone) {
        return isValidPhoneNumber(this.user_phone, this.user_country?.toUpperCase())
      }
      return true
    }
  },
  methods: {
    async setEmailOrPhone(val) {
      if (!val) {
        this.isEmail = null
        return
      }

      if (val.includes('@')) {
        this.isEmail = true
        this.user_email = val

        const [foundClient = [], foundUser = []] = await Promise.all([
          await this.findUsingEmail('client', this.emailOrPhone),
          await this.findUsingEmail('user', this.emailOrPhone)
        ])

        this.foundClients = foundClient
        this.foundUsers = foundUser
      } else {
        this.isEmail = false
        this.emailOrPhone = c.format(val, 'phone')
        this.user_phone = this.emailOrPhone

        const [foundClient = [], foundUser = []] = await Promise.all([
          await this.findUsingPhone('client'),
          await this.findUsingPhone('user')
        ])
        this.foundClients = foundClient
        this.foundUsers = foundUser
      }

      if (!this.foundClients.length && !this.foundUsers.length) {
        this.displayClientDetails = true
        this.$nextTick(() => {
          this.$refs.firstNameField?.focus()
        })
      }
      this.searchLoading = 0
    },
    focusHandler(field, e) {
      if (e.key !== 'Tab' && e.key !== 'Enter') return

      setTimeout(() => this.$refs[field]?.focus(), 250)
    },
    skipEmailEntry() {
      this.emailSkipped = true
      this.emailOrPhone = null
      this.user_email = null
      this.displayClientDetails = true
      this.showClientDetails = true
      this.displaySearchResults = false
    },
    async firstNameMounted() {
      await this.$refs.userFirstName.focus()
    },
    async addReviewer(client) {
      let userProv
      if (client.province_id) {
        const { set } = await this.$store.dispatch('Province/find', {
          by: {
            province_id: client.province_id
          }
        })
        userProv = set[0].province_name
      }

      const reviewerObject = {
        client_status: 'a',
        client_id: client.client_id,
        type: 'client',
        user_fname: client.user_fname,
        user_lname: client.user_lname,
        user_phone: client.user_phone,
        user_email: client.user_email,
        user_suite: client.user_suite,
        user_address: client.user_address,
        user_city: client.user_city,
        user_prov: userProv,
        user_postal: client.user_postal,
        country_abbr: client.country_abbr
      }
      this.$emit('addReviewer', reviewerObject)
    },
    async saveClient() {
      // Set the Client status based on whether they have an email or not
      this.client_status = 'a'
      if (!this.getReviewer) {
        const { object: client } = await this.save()

        const disp = this.$store.dispatch
        const comm = this.$store.commit

        c.throttle(
          async () => {
            const choice = await disp('modal/asyncConfirm', {
              message: 'Client successfully created!',
              choices: [
                {
                  icon: 'file-signature',
                  title: 'New estimate',
                  desc: `Create a new estimate for this client.`,
                  value: 'quote'
                },
                {
                  icon: 'circle-user',
                  title: 'Client profile',
                  desc: `View details about this newly created client.`,
                  value: 'go'
                },
                // {
                //   icon: 'message',
                //   title: 'Message client',
                //   desc: `Send this client a message.`,
                //   value: 'message'
                // },
                {
                  icon: 'plus',
                  title: 'Create another client',
                  desc: `Add another client to your account.`,
                  value: 'new'
                },
                {
                  icon: 'list',
                  title: 'See in list',
                  desc: `See list of all clients and leads.`,
                  value: 'list'
                }
              ]
            })

            if (!choice || choice === 'message') {
              comm({
                type: 'SET_PREVIEW',
                preview: {
                  type: 'user',
                  id: client.client_user_id
                }
              })
            } else if (choice === 'quote') {
              disp('Client/quote', {
                object: client
              })
            } else if (choice === 'new') {
              disp('create', {
                type: 'client',
                go: false
              })
            } else if (choice === 'go') {
              disp('to', `/client/${client.client_id}`)
            } else if (choice === 'list') {
              disp('go', {
                object: client
              })
            }
          },
          { delay: 200 }
        )

        this.$emit('closeModal')
      } else {
        const { payload: savedClient } = await this.save()
        this.addReviewer(savedClient)
      }
    },
    async chooseClient(user = null, company = null, client = null) {
      if (client) {
        this.chosenUser = client
        this.fillUpstreamChanges(
          {
            ...client,
            user_fname: client.client_fname,
            user_lname: client.client_lname
          },
          true
        )
        this.chosen = true
        if (this.getReviewer) {
          const reviewerObject = {
            client_id: client.client_id,
            type: 'client'
          }

          if (client.client_user_id) {
            const { object: us } = await this.$store.dispatch('Client/fetch', {
              id: client.client_id
            })

            if (us) {
              reviewerObject.user_fname = us.user_fname
              reviewerObject.user_lname = us.user_lname
              reviewerObject.user_phone = us.user_phone
              reviewerObject.user_email = us.user_email
              reviewerObject.user_suite = us.user_suite
              reviewerObject.user_address = us.user_address
              reviewerObject.user_city = us.user_city
              reviewerObject.user_prov = us.user_prov
              reviewerObject.user_postal = us.user_postal
            }
          }

          this.$emit('addReviewer', reviewerObject)
        }
      } else {
        this.user_lname = user.user_lname
        this.user_fname = user.user_fname
        this.client_company_id = company?.company_id ?? null
        this.company_name = company?.company_name
        this.client_status = 'a'
        this.client_user_id = user.user_id
        this.chosen = true
      }

      this.displayClientDetails = true
    },
    async checkIfExists() {
      this.searchLoading = 1
      this.appUsers = []
      this.clients = []

      this.clients = await this.findUsingEmail('client')

      if (this.clients.length) {
        this.searchLoading = 0
        return
      }

      this.appUsers = await this.findUsingEmail('user')

      if (this.appUsers.length) {
        this.searchLoading = 0
        return
      }
      this.searchLoading = 0
      this.displayClientDetails = true
    },

    async findUsingPhone(entityType = 'client') {
      if (!this.emailOrPhone) return []

      const titleType = c.titleCase(entityType)
      const { set } = await this.$store.dispatch(`${titleType}/find`, {
        by: {
          user_phone: c.deformat(this.emailOrPhone, 'phone')
        }
      })
      return set
    },
    async findUsingEmail(entityType = 'user', email = this.user_email) {
      if (!email) return []

      const titleType = c.titleCase(entityType)
      const { set } = await this.$store.dispatch(`${titleType}/find`, {
        by: {
          user_email: email
        }
      })
      return set
    }
  },
  components: {
    AddressForm,
    SelectionToggle,
    StringField,
    Btn,
    Field
  },
  props: {
    getReviewer: {
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
