import { computed } from 'vue'
import eventBus from '@/eventBus'
import useUserMeta from '@/components/composables/UserMeta.js'

export default {
  useOnboardingMilestones() {
    const { setMetaItem, getMetaItem } = useUserMeta()

    const currentPoints = computed(() => getMetaItem('onboardingMilestones')?.points ?? 0)
    const milestonesList = computed(() => getMetaItem('onboardingMilestones')?.list ?? [])

    const isMilestoneComplete = (name) =>
      !!milestonesList.value.find((m) => m.name === name || m.id === name)

    const completeMilestone = (milestone) => {
      const { points = 0, name } = milestone

      if (isMilestoneComplete(name)) return

      // emit regardless if already done for a celebration
      eventBus.$emit('milestoneComplete', milestone)

      const milestones = getMetaItem('onboardingMilestones') ?? {}

      milestones.list = milestones.list ?? []
      milestones.list.push(milestone)
      milestones.points += points ?? 0
      milestones.latestAchieved = name

      setMetaItem('onboardingMilestones', {
        ...milestones
      })
    }

    return {
      milestonesList,
      isMilestoneComplete,
      completeMilestone,
      currentPoints
    }
  }
}
