<script setup>
import { defineProps, toRefs, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import useSearch from '@/components/composables/Search'

const $store = useStore()
const { close } = useSearch()

const props = defineProps({
  result: {
    type: Object
  },
  type: {
    type: String
  },
  searchTypes: {
    type: Object
  }
})

const edit = (result) => {
  const { type } = result
  const id = result[`${type}_id`]
  close()
  return $store.dispatch('edit', { type, id })
}

const { result } = toRefs(props)

const component = defineAsyncComponent(
  () => import(`../ui/Previews/${c.titleCase(result.value.type)}.vue`)
)
</script>

<template>
  <div
    @click="() => edit(result)"
    class="px-3 py-2 cursor-pointer hover:bg-cement-400 flex border-b border-cement-400 gap-14"
  >
    <component :is="component" :object="result" />
  </div>
</template>
