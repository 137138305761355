<template>
  <div v-if="statement?.BankToCustomerStatement" class="print:p-0 print:shadow-none">
    <div class="grid grid-cols-2 gap-6 mb-2">
      <div class="flex items-start">
        <div class="flex flex-col">
          <div class="flex flex-col mb-4">
            <img :src="bolsterLogo" alt="Bolster Logo" class="w-32 h-15 object-contain" />
          </div>
          <div class="flex flex-col space-y-1">
            <p class="text-lg font-semibold">{{ recipientName }}</p>
            <p>{{ recipientAddress?.streetName }}</p>
            <p>
              {{ recipientAddress?.townName }}, {{ recipientAddress?.state }}
              {{ recipientAddress?.postCode }}
            </p>
            <p>{{ recipientAddress?.country }}</p>
          </div>
        </div>
      </div>

      <!-- Account Statement Header -->
      <div>
        <div class="flex flex-col items-end">
          <div class="w-[400px] space-y-2">
            <div class="grid grid-cols-[140px_1fr] items-center">
              <h3 class="text-3xl font-bold whitespace-nowrap">Account Statement</h3>
              <div></div>
            </div>
            <div class="grid grid-cols-[140px_1fr] items-center">
              <div>Statement ID:</div>
              <div>{{ statement.BankToCustomerStatement.statement.id }}</div>
            </div>
            <div class="grid grid-cols-[140px_1fr] items-center">
              <div>Message ID:</div>
              <div>{{ statement.BankToCustomerStatement.groupHeader.messageId }}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Balances -->
      <div class="text-gray-600 space-y-2 self-start pt-2">
        <div class="grid grid-cols-[140px_1fr] items-center">
          <div>Opening Balance:</div>
          <div>{{ formatBalanceAmount(getBalanceByType('OPBD')) }}</div>
        </div>
        <div class="grid grid-cols-[140px_1fr] items-center">
          <div>Closing Balance:</div>
          <div>{{ formatBalanceAmount(getBalanceByType('CLBD')) }}</div>
        </div>
        <div class="grid grid-cols-[140px_1fr] items-center">
          <div>Available Balance:</div>
          <div>{{ formatBalanceAmount(getBalanceByType('CLAV')) }}</div>
        </div>
      </div>

      <!-- Statement Details -->
      <div class="text-gray-600 self-start pt-2">
        <div class="flex flex-col items-end">
          <div class="w-[400px] space-y-2">
            <div class="grid grid-cols-[140px_1fr] items-center">
              <div>Statement Date:</div>
              <div>
                {{
                  formatDateTime(statement.BankToCustomerStatement.statement.fromToDate.toDateTime)
                }}
              </div>
            </div>
            <div class="grid grid-cols-[140px_1fr] items-center">
              <div>Period:</div>
              <div>
                {{
                  formatDateTime(
                    statement.BankToCustomerStatement.statement.fromToDate.fromDateTime
                  )
                }}
                -
                {{
                  formatDateTime(statement.BankToCustomerStatement.statement.fromToDate.toDateTime)
                }}
              </div>
            </div>
            <div class="grid grid-cols-[140px_1fr] items-center">
              <div>Account ID:</div>
              <div>{{ statement.BankToCustomerStatement.statement.account.id.Othr.id }}</div>
            </div>
            <div class="grid grid-cols-[140px_1fr] items-center">
              <div>Currency:</div>
              <div>{{ statement.BankToCustomerStatement.statement.account.currency }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transactions Table -->
    <StatementTransactions
      :transactions="statement.BankToCustomerStatement.statement.Ntry"
      :opening-balance="getBalanceByType('OPBD')"
      :closing-balance="getBalanceByType('CLBD')"
      :is-printing="isPrinting"
    />

    <!-- Additional Information -->
    <div class="border border-gray-200 bg-gray-50 p-6 rounded-lg mb-6">
      <p class="text-gray-600">
        {{ statement.BankToCustomerStatement.groupHeader.additionalInformation }}
      </p>
    </div>

    <!-- Footer -->
    <div class="border-t border-gray-200 pt-6">
      <!-- Contact Information -->
      <div class="flex justify-between items-start text-sm text-gray-600">
        <div>
          <p class="font-semibold mb-1">Contact us:</p>
          <p>General inquiries:</p>
          <p>info@costcertified.com</p>
        </div>
        <div class="text-right">
          <p>Support:</p>
          <p>support@costcertified.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import StatementTransactions from './StatementTransactions.vue'
import bolsterLogo from '@/assets/logos/Bolster_logo_BW.png'

const props = defineProps({
  statement: {
    type: Object,
    required: true
  },
  isPrinting: {
    type: Boolean,
    default: false
  }
})

defineEmits(['print', 'download'])

const formatDateTime = (timestamp) => {
  return new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

const formatCurrency = (amount) => {
  if (typeof amount === 'string') return amount
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount)
}

const getBalanceByType = (type) => {
  const balance = props.statement?.BankToCustomerStatement.statement.balance.find(
    (b) => b.type.codeOrProperty.code === type
  )
  return balance
    ? {
        amount: balance.amount,
        creditDebit: balance.creditDebitIndicator
      }
    : null
}

const formatBalanceAmount = (balance) => {
  if (!balance) return 'N/A'
  const amount = balance.creditDebit === 'DBIT' ? -balance.amount : balance.amount
  return formatCurrency(amount)
}

const recipientName = computed(() => {
  return props.statement?.BankToCustomerStatement?.groupHeader?.messageRecipient?.name || ''
})

const recipientAddress = computed(() => {
  const address =
    props.statement?.BankToCustomerStatement?.groupHeader?.messageRecipient?.address || {}
  return {
    streetName: address.addressLine || '',
    postCode: address.postalCode || '',
    townName: address.city || '',
    state: address.state || '',
    country: address.country || ''
  }
})
</script>
