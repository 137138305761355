<template>
  <page v-if="!bodyLoading">
    <div class="quote-pres--sandbox invoice-pres pt-4" :class="{ touch: deviceStore.isTouch }">
      <!-- heading -->
      <PresentationHeading
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :type="type"
        v-model="subComponentInterface"
      />

      <!-- contents -->
      <PresentationContents
        v-bind="$props"
        :reference="refId"
        :store="storeName"
        :type="type"
        v-model="subComponentInterface"
      />
    </div>
  </page>
  <div class="w-full h-screen flex items-center justify-center" v-else>
    <LoadingIndicator />
  </div>
</template>

<script>
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue'
import PresentationContents from '@/components/invoice/presentation/PresentationContents.vue'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import TranslationMixin from '@/components/invoice/presentation/languages/TranslationMixin'
import PresentationHeading from '@/components/invoice/presentation/PresentationHeading.vue'
import eventBus from '@/eventBus'
import { useDeviceStore } from '@/stores/device'

export default {
  name: 'CustomerDashboardInvoice',
  setup() {
    const deviceStore = useDeviceStore()

    return { deviceStore }
  },
  mixins: [ObjectManipulator('invoice', false), TranslationMixin],

  components: {
    PresentationContents,
    LoadingIndicator,
    PresentationHeading
  },

  methods: {
    async sel() {
      this.addLoading()
      this.originalSettings = this.presentationSettings
    }
  },

  mounted() {
    if (!this.selected) {
      eventBus.$once(`${this.uid}-selected`, this.sel)
    } else {
      this.sel()
    }
  },

  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`, this.sel)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
@import '@/theme/presentations/colors.scss';
@import '@/theme/presentations/mixins.scss';
@import '@/theme/presentations/variables/invoice.scss';
@import '@/theme/presentations/structure/invoice.scss';

$headingHeight: 50vh;

.element-replacer {
  height: 5em;
  width: 100%;
  background: transparent;
}
.quote-pres--container {
  min-height: 0;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;

  .quote-pres--fixed-nav {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 5em;
    background: rgba($pitch-black, 0.8);
    z-index: $z-layout + 100;
    padding: 1em;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .quote-pres--fixed-nav-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 5em;
      height: 100%;

      &.quote-pres--fixed-btn-nav-prev {
        color: $cool-gray-300 !important;
        > a {
          background: $cool-gray-300 !important;
          color: $cool-gray-700 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $pitch-black !important;
            color: $flame-white !important;
          }
        }
      }

      &.quote-pres--fixed-btn-nav-next {
        color: $deep-red-800 !important;
        > a {
          background: $deep-red-400 !important;
          color: $deep-red-800 !important;
        }

        &:hover {
          color: $flame-white !important;
          > a {
            background: $deep-red-800 !important;
            color: $flame-white !important;
          }
        }
      }
    }
  }
}

.coverHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .quoteHeading {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: right;
    font-weight: bold;
    padding-left: 1em;
    min-width: 230px;
  }
}

.changeOrderMessage {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: center;
}

.coverLetter {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: left;
  white-space: pre-line;
}

#cc.small-format {
  .coverHeader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    .quoteHeading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      min-width: 230px;
      padding-left: 0;
      padding-top: 1em;
    }
  }
}
</style>
