<template>
  <div class="absolute inset-0 flex flex-col items-stretch justify-stretch">
    <FocusBar defaultClosePath="/customer/project/progress" hide-save-buttons v-if="showFocusBar">
      <template #middle>
        <div v-if="!smallFormat" class="flex justify-center items-center w-full h-16">
          <h4>{{ quoteName }}</h4>
        </div>
      </template>
    </FocusBar>
    <div class="relative h-full w-full basis-full shrink">
      <EntitySelect type="quote" store="Quote" :id="quoteId">
        <template #default="{ refId }">
          <QuotePresentation :trackChanges="false" :refId="refId" store="Quote" type="quote" />
        </template>
      </EntitySelect>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import QuotePresentation from '@/components/quote/presentation/QuotePresentation.vue'
import FocusBar from '@/components/layout/header/FocusBar.vue'
import { useMediaQuery } from '@/composables/mediaQuery'
const { smallFormat } = useMediaQuery()

const store = useStore()
const quoteId = computed(() => store.state.session.quote.quote_id)
const quoteName = computed(() => store.state.session.quote.quote_name)
const showFocusBar = computed(() => !/p/.test(store.state.session.quote.quote_status))
</script>
