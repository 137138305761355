//import posthog from 'posthog-js'
import LogRocket from 'logrocket'

import { PostHogSymbol } from './symbol'
import DummyPostHog from './dummyPlug'

export default {
  install(app) {
    let posthogInstance
    if (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') {
      posthogInstance = LogRocket
    } else {
      posthogInstance = new DummyPostHog()
    }
    /*
    posthogInstance.init(import.meta.env.VITE_POSTHOG_TOKEN, {
      api_host: 'https://us.i.posthog.com'
    })
    */
    // Definitely not posthog
    posthogInstance.init('5xf2ya/costcertified')

    app.config.globalProperties.$posthog = posthogInstance
    app.provide(PostHogSymbol, posthogInstance)
  }
}
