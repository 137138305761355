<template>
  <div class="mb-8 overflow-x-auto">
    <DataTable
      v-if="transactions?.length"
      :value="transactions"
      class="p-datatable-sm"
      responsiveLayout="scroll"
    >
      <Column
        v-for="col in transactionColumns.filter((col) => col.show !== false)"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        class="min-w-[150px]"
      >
        <template #body="slotProps">
          <template v-if="getTransactionColumnType(slotProps.field) === 'text'">
            {{ getNestedValue(slotProps.data, slotProps.field) }}
          </template>
          <template v-else-if="getTransactionColumnType(slotProps.field) === 'currency'">
            {{
              formatTransactionAmount(
                slotProps.data[slotProps.field],
                slotProps.data.creditDebitIndicator
              )
            }}
          </template>
          <template v-else-if="getTransactionColumnType(slotProps.field) === 'date'">
            {{ formatDateTime(slotProps.data[slotProps.field].dateTime) }}
          </template>
          <template v-else-if="getTransactionColumnType(slotProps.field) === 'status'">
            <Tag
              :value="getStatusDetails(slotProps.data[slotProps.field]).label"
              :severity="getStatusDetails(slotProps.data[slotProps.field]).severity"
            />
          </template>
          <template v-else-if="getTransactionColumnType(slotProps.field) === 'runningBalance'">
            {{ formatCurrency(slotProps.data.runningBalance) }}
          </template>
        </template>
      </Column>
    </DataTable>
    <div v-else class="flex justify-center items-center">
      <div class="flex flex-col justify-center items-center">
        <font-awesome-icon icon="magnifying-glass" class="text-4xl text-surface-200 mb-4" />
        <h3>No transactions found.</h3>
      </div>
    </div>
  </div>
</template>

<script setup>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

defineProps({
  transactions: {
    type: Array,
    required: true
  },
  openingBalance: {
    type: Object,
    required: true
  },
  closingBalance: {
    type: Object,
    required: true
  },
  isPrinting: {
    type: Boolean,
    default: false
  }
})

const transactionColumns = [
  { field: 'BookgDt', header: 'Date', type: 'date' },
  { field: 'NtryDtls.TxDtls.Refs.TxId', header: 'Reference #', type: 'text' },
  { field: 'amount', header: 'Amount', type: 'currency' },
  { field: 'Sts', header: 'Status', type: 'status' }
]

const getTransactionColumnType = (field) => {
  return transactionColumns.find((col) => col.field === field)?.type || ''
}

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

const formatDateTime = (timestamp) => {
  return new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

const formatCurrency = (amount) => {
  if (typeof amount === 'string') return amount
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount)
}

const formatTransactionAmount = (amount, creditDebitIndicator) => {
  const numAmount = creditDebitIndicator === 'DBIT' ? -amount : amount
  return formatCurrency(numAmount)
}

const getStatusDetails = (status) => {
  const statusMap = {
    BOOK: { label: 'Completed', severity: 'success' },
    PDNG: { label: 'Pending', severity: 'warning' },
    RJCT: { label: 'Rejected', severity: 'danger' }
  }
  return statusMap[status] || { label: status, severity: 'info' }
}
</script>
