import { computed, getCurrentInstance } from 'vue'
import Draw from './Draw'

export default {
  useDrawGrid(args) {
    const {
      rowsMap,
      dataSheets,
      defaultFormatting,
      defaultHeadingFormat,
      scaleFactor,
      totalGutterWidth,
      collapseGroupGutterPositionsVisible,

      getColDef,
      getCellValue,
      getCellFormatting,
      getCellType,
      getSheet,

      formatCellValue,

      isRowFirstOfSheet,
      isRowLastOfSheet,

      freezeCol,
      applyOrDraw,
      getRowHeight,
      rowHeadingWidth,

      getRowSheet,
      sheetHeadingHeight,
      maxSheetWidth,
      canvasWidth,
      sheetOptionsOffsets,
      getColLabel,
      defaultGroupTotalsFormatting,
      groupMap,
      defaultGroupHeadingFormatting,

      mainCtx,
      canvasHeight,
      getCellValueFormatted,
      borderRadius,
      fullHeadingWidth,
      cellPositionsVisible,
      rowPositionsVisible,
      rowHeadingBoundingRect,
      sheetPositionsVisible,
      columnHeadingPositions,

      groupPositionsVisible,
      collapsedGroupRows,
      collapsedGroups,
      isCellDisabled,
      clearFragments,

      loaded,
      getRowData
    } = args

    const drawUtils = Draw.useDraw({
      defaultFormatting,
      scaleFactor
    })

    const {
      drawText,
      drawIcon,
      measureText,

      // concrete,
      lightGrayTrans,
      lightGray,
      blue,
      blueTrans,
      interpolateColor
    } = drawUtils

    const radiusScaled = computed(() => borderRadius * scaleFactor.value)

    const getStepsText = (steps, value) => {
      let text = ''
      let completeGroups = []
      const stepsComplete = [...steps].reverse().filter((step, index) => {
        const reverseValue = [...value].reverse()
        if (reverseValue[index] < 1 || _.intersection(completeGroups, step.groups).length)
          return false

        completeGroups = [...completeGroups, ...(step.groups || [])]
        return true
      })

      let incompleteGroups = []
      const stepsIncomplete = [...steps].filter((step, index) => {
        if (value[index] > 0 || _.intersection(incompleteGroups, step.groups).length) return false

        incompleteGroups = [...incompleteGroups, ...(step.groups || [])]
        return true
      })

      // From last of each group
      const completeText = stepsComplete
        .reverse()
        .reduce((acc, step) => `${acc ? `${acc}, ` : ''}${step.text}`, '')

      // From first of each group
      const incompleteText = stepsIncomplete.reduce(
        (acc, step) => `${acc ? `${acc}, ` : ''}${step.text}`,
        ''
      )

      const replaceLastComma = (str, replacement) => {
        if (!str.includes(',')) return str
        const lastCommaIndex = str.lastIndexOf(',')
        if (lastCommaIndex === -1) {
          return str // Return the original string if there's no comma
        }
        return str.substring(0, lastCommaIndex) + replacement + str.substring(lastCommaIndex + 1)
      }

      if (completeText && !incompleteText) {
        text = replaceLastComma(completeText, ' and')
      } else if (incompleteText && !completeText) {
        text = `Not ${replaceLastComma(incompleteText, ' or')}`
      } else if (incompleteText && completeText) {
        text = `${replaceLastComma(completeText, ' and')}, but not ${replaceLastComma(incompleteText, ' or')}`
      }

      text = c.ucfirst(text.trim().toLowerCase())

      return text
    }

    const drawGutter = (ctx) => {
      const grps = [...collapseGroupGutterPositionsVisible.value]

      for (let i = 0; i < grps.length; i += 1) {
        const grp = grps[i]

        const collapsed = collapsedGroups.value.includes(grp.id)
        const color = collapsed ? blue : lightGrayTrans
        ctx.beginPath()
        ctx.roundRect(grp.button.x, grp.button.y, grp.button.width, grp.button.height, 3)
        // ctx.beginPath();
        // ctx.rect(grp.x,
        //   (grp.y + fullHeadingHeight.value),
        //   grp.width,
        //   grp.height);
        ctx.strokeStyle = color // Change 'blue' to your desired fill color
        ctx.stroke()
        ctx.closePath()

        const text = collapsed ? '+' : '-'
        drawText(
          ctx,
          [
            grp.button.x,
            grp.button.y, // nudge for lower case to middle
            grp.button.width,
            grp.button.height
          ],
          text,
          {
            color,
            fontSize: 20,
            align: 'center',
            verticalAlign: 'middle'
          }
        )

        const notEmpty = grp.line.y + grp.line.height > grp.button.y + grp.button.height
        if (!collapsed && notEmpty) {
          const curveRadius = 10

          ctx.beginPath()
          ctx.moveTo(grp.line.x, grp.line.y)
          ctx.lineTo(grp.line.x, grp.line.y + grp.line.height - curveRadius)
          ctx.quadraticCurveTo(
            grp.line.x,
            grp.line.y + grp.line.height,
            grp.line.x + curveRadius,
            grp.line.y + grp.line.height
          )
          ctx.lineTo(grp.line.x + 20, grp.line.y + grp.line.height)
          ctx.strokeStyle = lightGrayTrans // Change 'blue' to your desired fill color
          ctx.stroke()
          ctx.closePath()
        }
      }
    }

    const drawGridLines = (ctx, spacing = 20) => {
      const canvas = ctx.canvas
      const width = canvas.width
      const height = canvas.height

      // Set the color and width of the grid lines
      ctx.strokeStyle = '#ccc'
      ctx.lineWidth = 1

      // Draw vertical grid lines
      for (let x = 0; x <= width; x += spacing) {
        ctx.beginPath()
        ctx.moveTo(x, 0)
        ctx.lineTo(x, height)
        ctx.stroke()
      }

      // Draw horizontal grid lines
      for (let y = 0; y <= height; y += spacing) {
        ctx.beginPath()
        ctx.moveTo(0, y)
        ctx.lineTo(width, y)
        ctx.stroke()
      }
    }

    const drawGrid = () => {
      const ctx = mainCtx.value
      if (!ctx) return

      ctx.clearRect(0, 0, canvasWidth.value, canvasHeight.value)

      drawGui(ctx)
      drawCells(ctx)
    }

    const $this = getCurrentInstance().proxy
    const triggerRedraw = (delay = 100) => {
      if (!loaded.value) return new Promise((r) => r())

      return c.throttle(
        () => {
          $this.$nextTick(() => {
            drawGrid()
          })
        },
        { delay }
      )
    }

    // watchEffect(() => drawGrid());

    const drawGroupTotalCell = (ctx, clipTo, sheetIndex, grpKey, col) => {
      const gm = { ...groupMap.value }
      const group = { ...gm[grpKey] }
      const row = group.rows[0]
      const cols = [...dataSheets.value[sheetIndex].columns]

      const lastCol = col === cols.length - 1
      const firstCol = col === 0

      const formatting = defaultGroupTotalsFormatting.value
      formatting.align = cols?.[col]?.formatting?.align || defaultGroupTotalsFormatting.value.align

      drawCellGrid(
        ctx,
        clipTo,
        col,
        row,
        [0, 0, lastCol ? radiusScaled.value : 0, firstCol ? radiusScaled.value : 0],
        [1, lastCol ? 1 : 0, 1, 1],
        formatting
      )

      drawText(
        ctx,
        clipTo,
        formatCellValue(rowsMap.value[row].columns[col], col, row, group.totals[col]),
        formatting
      )
    }

    const drawGroupHeadingCell = (ctx, clipTo, sheetIndex, grpKey, col) => {
      const gm = { ...groupMap.value }
      const group = { ...gm[grpKey] }
      const row = group.rows[0]
      const cols = [...dataSheets.value[sheetIndex].columns]

      const lastCol = col === cols.length - 1
      const firstCol = col === 0

      const formatting = defaultGroupHeadingFormatting.value

      if (lastCol) {
        // eslint-disable-next-line no-unused-vars
        const { right: Omit, ...rest } = formatting.borders
        formatting.borders = rest
      }

      if (firstCol) {
        // eslint-disable-next-line no-unused-vars
        const { left: Omit, ...rest } = formatting.borders
        formatting.borders = rest
      }

      drawCellGrid(
        ctx,
        clipTo,
        col,
        row,
        [firstCol ? radiusScaled.value : 0, lastCol ? radiusScaled.value : 0, 0, 0],
        [1, lastCol ? 1 : 0, 0, firstCol ? 1 : 0],
        formatting
      )

      if (firstCol) {
        drawText(ctx, clipTo, c.ucfirst(group.title), formatting)
      }
    }

    const drawGroups = (ctx) => {
      const gp = { ...groupPositionsVisible.value }
      const groupKeys = Object.keys(gp)

      for (let i = 0; i < groupKeys.length; i += 1) {
        const grpkey = groupKeys[i]
        const grpPos = gp[grpkey]
        const sheetIndex = grpPos.sheetIndex
        const { y: headingY } = grpPos.heading
        const { y: totalsY } = grpPos.totals

        const cv = { ...grpPos.columns }
        const cvk = Object.keys(cv)

        const height = getRowHeight()

        for (let j = 0; j < cvk.length; j += 1) {
          const col = +cvk[j]
          const { x, width } = cv[col]
          if (width < 1) continue

          // headings
          applyOrDraw(
            `${sheetIndex}-grp-${grpkey}-heading-${col}`,
            ctx,
            [0, 0, width, height],
            drawGroupHeadingCell,
            [sheetIndex, grpkey, col],
            [x, headingY]
          )

          // footings
          applyOrDraw(
            `${sheetIndex}-grp-${grpkey}-total-${col}`,
            ctx,
            [0, 0, width, height],
            drawGroupTotalCell,
            [sheetIndex, grpkey, col],
            [x, totalsY]
          )
        }
      }
    }

    const drawGui = (ctx) => {
      drawGutter(ctx)
      drawRowHeadings(ctx)
      drawSheetHeadings(ctx)
      drawGroups(ctx)
      drawSortButtons(ctx)
      drawGroupingButtons(ctx)
    }

    const drawColumnHeading = (ctx, clipTo, sheetIndex, colIndex) => {
      const hformat = {
        ...defaultHeadingFormat,
        verticalAlign: 'bottom'
      }
      const colLabel = getColLabel(colIndex, sheetIndex)
      drawText(ctx, clipTo, colLabel, hformat)
    }

    const shCaretHeight = 5
    const drawSuperHeaders = (ctx, sheetIndex) => {
      const chp = { ...columnHeadingPositions.value }
      const shkeys = Object.keys(chp[sheetIndex].superHeadings)

      for (let i = 0; i < shkeys.length; i += 1) {
        const shIndex = +shkeys[i]
        const pos = chp[sheetIndex].superHeadings[shIndex]
        const { x, y, width, fullWidth, height, collapsed } = pos

        applyOrDraw(
          `${sheetIndex}-superHeader-${shIndex}`,
          ctx,
          [collapsed ? 0 : fullWidth - width, 0, fullWidth - 2, height + shCaretHeight],
          drawSuperHeader,
          [sheetIndex, shIndex],
          [x + 2, y]
        )
      }
    }

    const drawSuperHeader = (ctx, clipTo, sheetIndex, shIndex) => {
      const shs = { ...columnHeadingPositions.value }
      const sh = shs[sheetIndex].superHeadings[shIndex]

      const { collapsed, data } = sh

      const formatting = data.formatting || {}
      const fillStyle = formatting.background || c.getCssColor('cement-300')

      const radius = 5
      const [x, y, width, height] = clipTo
      const boxHeight = height - shCaretHeight

      if (data.hidden) return
      if (collapsed) {
        ctx.fillStyle = fillStyle
        ctx.globalCompositeOperation = 'multiply'
        ctx.beginPath()
        ctx.roundRect(x, y, width, boxHeight, radius)
        ctx.fillStyle = fillStyle
        ctx.fill()
        ctx.beginPath()

        ctx.beginPath()
        const topLeft = [Math.floor(x + width * (1 / 5)), y + boxHeight]
        const topRight = [Math.ceil(x + width * (4 / 5)), y + boxHeight]
        const bottomPoint = [Math.ceil(x + width * (1 / 2)), y + boxHeight + shCaretHeight]
        ctx.moveTo(...topLeft)
        ctx.lineTo(...topRight)
        ctx.lineTo(...bottomPoint)
        ctx.lineTo(...topLeft)
        ctx.fillStyle = fillStyle
        ctx.fill()
        ctx.beginPath()
        // drawCircleWithCaret(ctx, x + radius - 1, y + radius - 1, radius)

        // drawText(ctx, [x + 7, y + 7, 10, 10], '+', {
        //   color: c.blendColors('#000000', fillStyle, 0.9),
        //   align: 'center',
        //   verticalAlign: 'middle',
        //   fontSize: '16',
        //   bold: true
        // })
        const icon = data.icon ?? 'square-plus'
        drawIcon(
          ctx,
          [x + 9, y + 10, 6, 6],
          icon,
          formatting.color ?? c.blendColors('#000000', fillStyle, 0.4)
        )
        // drawText(ctx, [x + 9, y + 10, 12, 12], 'R')
        ctx.globalCompositeOperation = 'normal'
      } else {
        ctx.beginPath()
        ctx.roundRect(x, y, width, boxHeight, radius)
        ctx.fillStyle = fillStyle
        ctx.fill()
        ctx.beginPath()

        drawText(ctx, [x + 23, y, width - 28, boxHeight], data.title, {
          ...defaultHeadingFormat,
          ...formatting,
          color: formatting.color ?? c.blendColors('#000000', fillStyle, 0.4),
          verticalAlign: 'middle'
        })

        drawIcon(
          ctx,
          [x + 9, y + 10, 6, 6],
          data.icon ?? 'square-minus',
          formatting.color ?? c.blendColors('#000000', fillStyle, 0.4)
        )
      }
    }

    const drawColumnHeadings = (ctx, sheetIndex) => {
      const chp = { ...columnHeadingPositions.value }

      const chpkeys = Object.keys(chp[sheetIndex].colHeadings)
      for (let i = 0; i < chpkeys.length; i += 1) {
        const colIndex = +chpkeys[i]
        const pos = chp[sheetIndex].colHeadings[colIndex]
        const { x, y, width, height } = pos

        applyOrDraw(
          `${sheetIndex}-col-${colIndex}-heading`,
          ctx,
          [0, 0, width, height],
          drawColumnHeading,
          [sheetIndex, colIndex],
          [x, y]
        )
      }
    }

    const drawSheetHeading = (ctx, clipTo, sheetIndex) => {
      const color = c.getCssColor('surface-700')
      const sheet = getSheet(sheetIndex)

      const titleX = totalGutterWidth.value + rowHeadingWidth.value / 3
      const titleY = 0

      if (dataSheets.value.length > 0) {
        const formatting = {
          bold: true,
          color,
          align: 'left',
          verticalAlign: 'middle',
          fontSize: 20
        }

        drawIcon(
          ctx,
          [titleX + 15, titleY + 13, 1, 1],
          sheet.icon ?? 'sheet',
          c.getCssColor('surface-400')
        )

        const titleFormatting = {
          ...formatting,
          bold: true,
          fontSize: 20
        }
        drawText(
          ctx,
          [titleX + 30, titleY, canvasWidth.value / 3, 50],
          sheet.title,
          titleFormatting
        )

        sheetOptionsOffsets.value = {
          ...sheetOptionsOffsets.value,
          [sheetIndex]: titleX + measureText(sheet.title, titleFormatting).width + 30 + 10
        }
      }
    }

    const drawSheetHeadings = (ctx) => {
      const spv = { ...sheetPositionsVisible.value }
      const sheetIndexes = Object.keys(spv)
      const width = maxSheetWidth.value + fullHeadingWidth.value

      for (let spvIndex = 0; spvIndex < sheetIndexes.length; spvIndex += 1) {
        const sheetIndex = +sheetIndexes[spvIndex]
        const { y, x } = spv[sheetIndex]

        applyOrDraw(
          `${sheetIndex}-heading`,
          ctx,
          [0, 0, width, sheetHeadingHeight.value],
          drawSheetHeading,
          [sheetIndex],
          [x, y]
        )

        drawColumnHeadings(ctx, sheetIndex)

        drawSuperHeaders(ctx, sheetIndex)
      }
    }

    const drawRowHeadings = (ctx) => {
      const { x } = rowHeadingBoundingRect.value

      const rp = Object.keys(rowPositionsVisible.value)
      const rhw = rowHeadingWidth.value

      const hformat = { ...defaultHeadingFormat }

      for (let rowIndex = 0; rowIndex < rp.length; rowIndex += 1) {
        const row = +rp[rowIndex]
        const { y, height } = rowPositionsVisible.value[rp[rowIndex]]
        drawText(ctx, [x, y, rhw, height], String(row + 1), {
          ...hformat,
          verticalAlign: 'middle'
        })
      }
    }

    const drawCells = (ctx) => {
      const collapsedRows = [...collapsedGroupRows.value]
      const cellsVisible = { ...cellPositionsVisible.value }

      const rows = Object.keys(cellsVisible)

      for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
        const row = +rows[rowIndex]
        if (collapsedRows.includes(row)) continue

        const cols = Object.keys(cellsVisible[row])
        const sheetIndex = getRowSheet(row)

        const sheet = getSheet(sheetIndex)
        const endCol = sheet.columns.length - 1
        const isGrouping = sheet.grouping.length

        const lastOfSheet = isRowLastOfSheet(row)
        const firstOfSheet = isRowFirstOfSheet(row)

        // drawn
        const drawTop = 1
        const drawLeft = 1
        let drawRight = 0
        let drawBottom = 0

        let tl = 0
        let tr = 0
        let br = 0
        let bl = 0

        for (let colIndex = 0; colIndex < cols.length; colIndex += 1) {
          const col = +cols[colIndex]
          const cell = cellsVisible[row][col]

          drawRight = col === endCol
          drawBottom = lastOfSheet

          if (!isGrouping) {
            drawBottom = lastOfSheet

            tl = firstOfSheet && col === 0 ? radiusScaled.value : 0
            tr = firstOfSheet && col === endCol ? radiusScaled.value : 0
            br = lastOfSheet && col === endCol ? radiusScaled.value : 0
            bl = lastOfSheet && col === 0 ? radiusScaled.value : 0
          }

          const { x, y, width, height } = cell
          if (width === 0 || height === 0) continue

          applyOrDraw(
            `${sheetIndex}-row-${row}-col-${col}`,
            ctx,
            [0, 0, width, height],
            drawCell,
            () => [
              col,
              row,
              sheetIndex,
              [tl, tr, br, bl],
              [drawTop, drawRight, drawBottom, drawLeft],
              getCellFormatting(col, row) // formatting
            ],
            [x, y]
          )
        }
      }
    }

    const redrawCells = (cells, forceRedraw = true) => {
      const collapsedRows = [...collapsedGroupRows.value]
      const cellsVisible = { ...cellPositionsVisible.value }
      let row
      const wholeRow =
        cells.length > 4 &&
        cells.every((c) => {
          if (row === null) row = c[1]
          return row === c[1]
        })

      if (wholeRow) {
        clearFragments(`-${row}-`)
        triggerRedraw(0)
        return
      } else if (forceRedraw) {
        const regex = cells.map(([col, row]) => `row-${row}-col-${col}`).join('|')
        clearFragments(regex)
      }

      for (let i = 0; i < cells.length; i += 1) {
        const [col, row] = cells[i]

        if (!cellsVisible[row]?.[col] || collapsedRows.includes(row)) continue

        const sheetIndex = getRowSheet(row)

        const sheet = getSheet(sheetIndex)
        const endCol = sheet.columns.length - 1
        const isGrouping = sheet.grouping.length

        const lastOfSheet = isRowLastOfSheet(row)
        const firstOfSheet = isRowFirstOfSheet(row)

        // drawn
        const drawTop = 1
        const drawLeft = 1
        let drawRight = 0
        let drawBottom = 0

        let tl = 0
        let tr = 0
        let br = 0
        let bl = 0

        const cell = cellsVisible[row][col]

        drawRight = col === endCol
        drawBottom = lastOfSheet

        if (!isGrouping) {
          drawBottom = lastOfSheet

          tl = firstOfSheet && col === 0 ? radiusScaled.value : 0
          tr = firstOfSheet && col === endCol ? radiusScaled.value : 0
          br = lastOfSheet && col === endCol ? radiusScaled.value : 0
          bl = lastOfSheet && col === 0 ? radiusScaled.value : 0
        }

        const { x, y, width, height } = cell
        if (width === 0 || height === 0) continue

        const formatting = getCellFormatting(col, row)

        mainCtx.value.clearRect(x - 1, y - 1, width, height)
        applyOrDraw(
          `${sheetIndex}-row-${row}-col-${col}`,
          mainCtx.value,
          [0, 0, width, height],
          drawCell,
          [
            col,
            row,
            sheetIndex,
            [tl, tr, br, bl],
            [drawTop, drawRight, drawBottom, drawLeft],
            formatting
          ],
          [x, y]
        )
      }
    }

    const drawGroupingButton = (ctx) => {
      const x = 0
      const y = 0
      const size = 10

      // Draw the circular button to the left of the sorting column header
      ctx.beginPath()
      ctx.arc(x + size, y + size, size - 2, 0, 2 * Math.PI) // Increased radius to 7
      ctx.fillStyle = '#e3e3e3' // Replace this with the actual color code for $cool-gray-300
      ctx.fill()
      ctx.closePath()

      ctx.font = 'bold 8px arial' // `${bold ? 'bold ' : ''}${italic ? 'italic ' : ''}${fontSize}px ${fontFamily}`;
      ctx.fillStyle = '#bbbbbb' // Replace this with the actual color code for $cool-gray-300
      ctx.fillText('G', x + 7, y + size + 3)
    }

    const drawGroupingButtons = (ctx) => {
      const chp = { ...columnHeadingPositions.value }
      const sheets = Object.keys(chp)

      for (let sheeti = 0; sheeti < sheets.length; sheeti += 1) {
        const sheetIndex = sheets[sheeti]

        // Check if the sortingColumn is set
        const visCols = Object.keys(chp[sheetIndex].colHeadings)
        const sortingCols = dataSheets.value[sheetIndex].sorting.map((sor) => sor[0])
        const groupings = dataSheets.value[sheetIndex].grouping

        for (let i = 0; i < groupings.length; i += 1) {
          const grouping = groupings[0]
          if (grouping === null || !visCols.includes(`${grouping}`)) return

          const { x, y, height } = chp[sheetIndex].colHeadings[grouping]

          const buttonOffsetX = sortingCols.includes(grouping) ? 20 : 0

          applyOrDraw(
            'groupbutton',
            ctx,
            [0, 0, 20, 20],
            drawGroupingButton,
            [],
            [x + buttonOffsetX, y + (height - 2 - 20)]
          )
        }
      }
    }

    const drawSortButton = (ctx, clipTo, direction) => {
      const size = 10
      let x = 0
      const y = 0

      // Find the position of the sorting column header; assume you have a method for this

      // Draw the circular button to the left of the sorting column header
      ctx.beginPath()
      ctx.arc(x + size, y + size, size - 2, 0, 2 * Math.PI) // Increased radius to 7
      ctx.fillStyle = '#e3e3e3' // Replace this with the actual color code for $cool-gray-300
      ctx.fill()
      ctx.closePath()

      // Draw the arrow
      const arrowSize = 3
      ctx.beginPath()
      ctx.fillStyle = '#bbbbbb'
      x += size
      if (direction === 'asc') {
        // Draw a down arrow
        ctx.moveTo(x, y + size - arrowSize)
        ctx.lineTo(x + arrowSize, y + size + arrowSize)
        ctx.lineTo(x - arrowSize, y + size + arrowSize)
      } else {
        // Draw an up arrow
        ctx.moveTo(x, y + size + arrowSize)
        ctx.lineTo(x + arrowSize, y + size - arrowSize)
        ctx.lineTo(x - arrowSize, y + size - arrowSize)
      }
      ctx.closePath()
      ctx.fill()
    }

    const drawSortButtons = (ctx) => {
      const chp = { ...columnHeadingPositions.value }
      const sheets = Object.keys(chp)

      for (let sheeti = 0; sheeti < sheets.length; sheeti += 1) {
        const sheetIndex = sheets[sheeti]

        // Check if the sortingColumn is set
        const visCols = Object.keys(chp[sheetIndex].colHeadings)
        const sortings = Object.values(dataSheets.value[sheetIndex].sorting)

        for (let i = 0; i < sortings.length; i += 1) {
          const sorting = sortings[i][0]
          if (sorting === null || !visCols.includes(`${sorting}`)) continue

          const { x, y, height } = chp[sheetIndex].colHeadings[sorting]

          applyOrDraw(
            'sortbutton',
            ctx,
            [0, 0, 20, 20],
            drawSortButton,
            [sortings[i][1]],
            [x, y + (height - 2 - 20)]
          )
        }
      }
    }

    const drawCell = (
      ctx,
      [xOffset, yOffset, width, height],
      col,
      row,
      sheetIndex,
      radii = undefined,
      drawSides = undefined
    ) => {
      const x = xOffset
      const y = yOffset

      if (width < 1) {
        return [xOffset, yOffset, width, height]
      }

      const disabled = isCellDisabled(col, row)

      const text = getCellValueFormatted(col, row)
      const type = getCellType(col, row)
      const formatting = getCellFormatting(col, row)

      // Draw rounded rectangle for specific cells
      drawCellGrid(ctx, [x, y, width, height], col, row, radii, drawSides, formatting)

      const value = getCellValue(col, row)
      const colDef = getColDef(col, sheetIndex)
      if (formatting.draw && !disabled) {
        formatting.draw({
          ...drawUtils,

          getRowData: () => getRowData(row),
          cell: [col, row],
          text,
          value,

          ctx,
          clipTo: [x, y, width, height],
          formatting,
          colDef
        })
      }

      const drawDefault = !formatting.draw || !formatting.preventDefaultDraw

      if (drawDefault && !disabled) {
        if (type === 'progress') {
          drawProgress(ctx, [x, y, width, height], value, formatting, colDef)
        } else if (type === 'attachments') {
          drawAttachments(ctx, [x, y, width, height], value, {}, colDef, [col, row])
        } else if (type === 'checkbox') {
          drawCheckbox(ctx, [x, y, width, height], value, {}, colDef)
        } else if (text) {
          drawText(ctx, [x, y, width, height], text, formatting)
        }
      }

      const onDraw = getColDef(col, getRowSheet(row))?.onDraw
      if (onDraw) {
        c.throttle(
          () => {
            const drawCell = (drawCol, drawRow) => redrawCells([[drawCol, drawRow]], true)
            onDraw({ col, row, drawCell, redrawCells })
          },
          { delay: 100, key: `${col}:${row}` }
        )
      }

      return [xOffset, yOffset, width, height]
    }

    const drawCellGrid = (ctx, clipTo, col, row, radii = [0, 0, 0, 0], drawSides = [], format) => {
      const { background, strokeColor, strokeThickness, borders = {} } = format

      const [x, y, width, height] = clipTo

      // Determine which corners to round
      const [tl, tr, br, bl] = radii

      const isFreezeCol = freezeCol.value === col
      let [drawTop = 1, drawRight = null, drawBottom = null, drawLeft = 1] = drawSides

      const getDraw = (border, draw) => {
        if (border && 'thickness' in border) return border.thickness

        if (draw === null) return 0

        return draw
      }

      // Override with borders settings
      drawTop = getDraw(borders.top, drawTop)
      drawRight = isFreezeCol ? 1 : getDraw(borders.right, drawRight)
      drawBottom = getDraw(borders.bottom, drawBottom)
      drawLeft = getDraw(borders.left, drawLeft)

      const drawCtx = ctx
      drawCtx.beginPath()
      drawCtx.roundRect(x, y, width, height, [tl, tr, br, bl])
      drawCtx.fillStyle = background
      drawCtx.fill()
      drawCtx.beginPath()

      if (drawTop) {
        drawCtx.beginPath()
        drawCtx.moveTo(x, y + tl)
        drawCtx.quadraticCurveTo(x, y, x + tl, y)
        drawCtx.lineTo(x + width - tr, y)
        drawCtx.quadraticCurveTo(x + width, y, x + width, y + tr)

        drawCtx.strokeStyle = (borders.top && borders.top.color) || strokeColor
        drawCtx.lineWidth = (borders.top && borders.top.thickness) || strokeThickness
        drawCtx.stroke()
      }

      if (drawRight) {
        drawCtx.beginPath()
        const lineThickness = isFreezeCol
          ? 3
          : (borders.right && borders.right.thickness) || strokeThickness

        const ns = isFreezeCol ? 0 : 0
        drawCtx.moveTo(x - ns + width, y + tr)
        drawCtx.lineTo(x - ns + width, y + height - br)

        drawCtx.strokeStyle = (borders.right && borders.right.color) || strokeColor
        drawCtx.lineWidth = lineThickness
        drawCtx.stroke()
        drawCtx.lineWidth = 1
      }

      if (drawBottom) {
        drawCtx.beginPath()
        drawCtx.moveTo(x, y + height - bl)
        drawCtx.quadraticCurveTo(x, y + height, x + bl, y + height)

        drawCtx.lineTo(x + width - br, y + height)

        drawCtx.quadraticCurveTo(x + width, y + height, x + width, y + height - br)

        drawCtx.strokeStyle = (borders.bottom && borders.bottom.color) || strokeColor
        drawCtx.lineWidth = (borders.bottom && borders.bottom.thickness) || strokeThickness
        drawCtx.stroke()
      }

      if (drawLeft) {
        drawCtx.beginPath()
        drawCtx.moveTo(x, y + tl)
        drawCtx.lineTo(x, y + height - bl)

        drawCtx.strokeStyle = (borders.left && borders.left.color) || strokeColor
        drawCtx.lineWidth = (borders.left && borders.left.thickness) || strokeThickness
        drawCtx.stroke()
      }
    }

    // eslint-disable-next-line no-unused-vars
    const drawCheckbox = (ctx, clipTo, value, formatting = {}, colDef) => {
      const [x, y, width, height] = clipTo

      const defaultBackground = 'transparent'
      const defaultColor = interpolateColor('#f8f9fa', blue, 1 / 5)

      const cDef = colDef.checkbox.checked ?? {}
      const ucDef = colDef.checkbox.unchecked ?? {}

      const iconChecked = cDef.icon ?? null
      const iconUnchecked = ucDef.icon ?? null

      const backgroundChecked = cDef.background ?? cDef.color ?? defaultColor
      const colorChecked =
        cDef.color ??
        (cDef.background && cDef.background !== defaultBackground ? cDef.background : blueTrans)

      const colorUnchecked = ucDef.color ?? lightGrayTrans

      let color = colorUnchecked
      let background

      let icon = iconUnchecked
      ctx.globalCompositeOperation = 'multiply'

      if (value) {
        // reverse colors
        color = colorChecked
        background = backgroundChecked

        ctx.beginPath()
        ctx.roundRect(x + 3, y + 3, width - 6, height - 6, 2)
        ctx.fillStyle = background
        ctx.fill()
        ctx.beginPath()

        icon = iconChecked
      }

      drawIcon(ctx, clipTo, icon, color)
      ctx.globalCompositeOperation = 'normal'
    }

    const imgcache = {}

    const drawAttachments = (ctx, clipTo, value, four, five, cell) => {
      const ids = c.makeArray(value)
      const color = ids.length ? blueTrans : lightGrayTrans
      if (ids.length) {
        const size = Math.max(clipTo[3], clipTo[2]) * 4 // make it crisp
        const url = c.link(`file/view/${ids[0]}`, { size }, true)

        const draw = (drawimg) => {
          const canvasWidth = clipTo[2] - 4
          const canvasHeight = clipTo[3] - 4

          const imgAspectRatio = drawimg.width / drawimg.height // Image's aspect ratio
          const canvasAspectRatio = canvasWidth / canvasHeight // Canvas's aspect ratio

          let sx, sy, sWidth, sHeight

          if (imgAspectRatio > canvasAspectRatio) {
            // Image is wider than canvas
            sHeight = drawimg.height
            sWidth = drawimg.height * canvasAspectRatio
            sx = (drawimg.width - sWidth) / 2 // Center crop horizontally
            sy = 0
          } else {
            // Image is taller than canvas
            sWidth = drawimg.width
            sHeight = drawimg.width / canvasAspectRatio
            sx = 0
            sy = (drawimg.height - sHeight) / 2 // Center crop vertically
          }
          ctx.globalAlpha = 0.7
          ctx.globalCompositeOperation = 'multiply'
          ctx.drawImage(
            drawimg,
            sx,
            sy,
            sWidth,
            sHeight,
            clipTo[0] + 2,
            clipTo[1] + 2,
            clipTo[2] - 4,
            clipTo[3] - 4
          )
          ctx.globalAlpha = 1
          ctx.globalCompositeOperation = 'source-over'
        }

        if (imgcache[url]) draw(imgcache[url])
        else {
          const img = new Image()
          img.src = url
          imgcache[url] = img
          img.onload = () => {
            draw(img)
            redrawCells([cell], true)
          }
          img.onerror = () => drawIcon(ctx, clipTo, 'camera', color)
        }
      } else drawIcon(ctx, clipTo, 'plus', color)
    }

    const drawProgress = (ctx, clipTo, recdValue, formatting, colDef) => {
      const value = Array.isArray(recdValue) ? recdValue : c.makeArray(recdValue)
      const [x, y, width, height] = clipTo

      const showBar = !!colDef.progress.showBar

      let completedBarLength = 0
      const padding = showBar ? 3 : 0
      const segmentWidth = (width - padding * 2) / colDef.progress.steps.length

      for (let i = 0; i < colDef.progress.steps.length; i += 1) {
        const step = colDef.progress.steps[i]
        const segmentX = x + padding + segmentWidth * i
        const segmentY = y + padding / 2

        const bgColor = step.underlay?.background ?? blue
        // const textColor = step.underlay?.text ?? '#ffffff'
        const inactiveColor = step.underlay?.inactive ?? c.hexWithOpacity(lightGray, 0.6)

        if (value[i] > 0) {
          if (!showBar) {
            ctx.beginPath()
            ctx.roundRect(segmentX + 2.5, segmentY + 2.5, segmentWidth - 5, height - 5, 3)
            ctx.fillStyle = bgColor // barColor;
            ctx.fill()
            ctx.beginPath()
            ctx.globalCompositeOperation = 'multiply'
            drawIcon(ctx, [segmentX, y, segmentWidth, height], step.underlay?.icon, lightGrayTrans)
            ctx.globalCompositeOperation = 'normal'
          } else {
            ctx.beginPath()
            ctx.roundRect(segmentX + 1, segmentY + 1, segmentWidth - 2, height - 14, 2)
            ctx.fillStyle = c.hexWithOpacity(blue, 0.2) // barColor;
            ctx.fill()
            ctx.beginPath()
            drawIcon(ctx, [segmentX, y - 4, segmentWidth, height + 5], step.underlay?.icon, bgColor)
            completedBarLength = segmentWidth * (i + 1)
          }
        } else {
          if (!showBar) {
            drawIcon(ctx, [segmentX, y, height, segmentWidth], step.underlay?.icon, inactiveColor)
          } else {
            drawIcon(
              ctx,
              [segmentX, y - 4, segmentWidth, height + 5],
              step.underlay?.icon,
              inactiveColor
            )
          }
        }

        // if (step.underlay?.square) {
        //   ctx.beginPath()
        //   ctx.roundRect(segmentX + 5, segmentY + 3, (segmentWidth * step.underlay.square) - 10, height - 10 - (showBar ? 4 : 0), 3)
        //   ctx.globalCompositeOperation = 'multiply'
        //   ctx.strokeStyle = lightGrayTrans
        //   ctx.stroke()
        //   ctx.beginPath()
        //   ctx.globalCompositeOperation = 'normal'
        // }
      }

      if (showBar) {
        ctx.roundRect(x + padding, y + height - 2 - 7, width - padding * 2, 7, 5)
        ctx.fillStyle = c.hexWithOpacity(lightGray, 0.2)
        ctx.fill()
        ctx.beginPath()

        if (completedBarLength) {
          ctx.roundRect(x + padding, y + height - 2 - 7, completedBarLength, 7, 5)
          ctx.fillStyle = blue
          ctx.fill()
        }
      }

      //
      //
      // if (complete && (colDef.progress.showComplete ?? true)) {
      //   ctx.globalCompositeOperation = 'multiply'
      //   ctx.fillStyle = c.hexWithOpacity(blue, 0.8) // barColor;
      //   ctx.fillRect(x, y, width, height)
      //   ctx.globalCompositeOperation = 'normal'
      //   drawText(ctx, clipTo, text, {
      //     ...formatting,
      //     fontSize: 9,
      //     color: c.hexWithOpacity('#ffffff', 0.99),
      //     wordWrap: true
      //   })
      //   // drawCheckmark(ctx, (x + width) - 12, y + 2, 10, '#fff');
      // } else {
      //   ctx.globalCompositeOperation = 'multiply'
      //   for (let i = 0; i < colDef.progress.steps.length; i += 1) {
      //     const step = colDef.progress.steps[i]
      //
      //     const segmentX = x + (segmentWidth * i)
      //     const segmentY = y
      //
      //     const mainColor = colDef.progress.steps[i].underlay?.color ?? blue
      //     const transColor = c.hexWithOpacity('#FFFFFF', 0.90)
      //
      //
      //     if (value[i] > 0) {
      //       ctx.fillStyle = mainColor // barColor;
      //       ctx.roundRect(segmentX + 2.5, segmentY + 2.5, segmentWidth - 5, height - 5, 3)
      //       ctx.fill()
      //       // ctx.fillRect(segmentX, segmentY, segmentWidth + 0.05, height)
      //     }
      //
      //     if (step.underlay) {
      //       const iconColor = value[i] > 0 ? transColor : lightGrayTrans
      //       // Draw overlays
      //       const drawRoundedRectangle = (rectangleHeight, rectangleWidth) => {
      //         // Set properties for the rectangle
      //         ctx.strokeStyle = lightGrayTrans // Color of the rectangle
      //         ctx.lineWidth = 1 // Thickness of the rectangle's border
      //         ctx.lineJoin = 'round' // Border radius effect
      //         ctx.lineCap = 'round' // Rounded corners
      //
      //         // Define the rectangle dimensions and position
      //         const padding = 5 // Padding around the rectangle
      //         const rheight = rectangleHeight - padding * 2 // Height adjusted for padding
      //         const rwidth = rectangleWidth - padding * 2 // Width adjusted for padding
      //         const xx = segmentX + padding // X coordinate
      //         const yy = segmentY + padding // Y coordinate
      //         const br = 3 // Border radius size
      //
      //         // Draw the rectangle with rounded corners
      //         ctx.beginPath()
      //         ctx.moveTo(xx + br, yy) // Adjust starting point for border radius
      //         ctx.lineTo(xx + rwidth - br, yy)
      //         ctx.arcTo(xx + rwidth, yy, xx + rwidth, yy + br, br)
      //         ctx.lineTo(xx + rwidth, yy + rheight - br)
      //         ctx.arcTo(xx + rwidth, yy + rheight, xx + rwidth - br, yy + rheight, br)
      //         ctx.lineTo(xx + br, yy + rheight)
      //         ctx.arcTo(xx, yy + rheight, xx, yy + rheight - br, br)
      //         ctx.lineTo(xx, yy + br)
      //         ctx.arcTo(xx, yy, xx + br, yy, br)
      //         ctx.stroke()
      //       }
      //
      //       // Example usage
      //       if (step.underlay?.square) {
      //         drawRoundedRectangle(height, segmentWidth * (step.underlay.square || 1))
      //       }
      //
      //       drawIcon(ctx, [segmentX, y, height, segmentWidth], step.underlay.icon, '#FFFFFF')
      //     }
      //   }
      // }
    }

    return {
      drawGrid,
      getStepsText,
      drawGridLines,
      triggerRedraw,
      redrawCells
    }
  }
}
