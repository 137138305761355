<template>
  <div class="signature-field flex flex-col gap-2">
    <label
      for="signature"
      :class="[
        'block font-medium text-lg',
        {
          'text-blue-print-500': !signature,
          'text-surface-800': signature
        }
      ]"
      >Enter your full name as signature to continue:</label
    >
    <InputText
      :class="{
        'ring-4 ring-blue-print-400/80': !signature
      }"
      v-model="signature"
      type="text"
      aria-label="Signature"
      placeholder="Full name"
      class="!w-full !p-2"
    />
    <div class="mt-3 !min-h-20 flex items-center gap-2 py-6" v-if="signature">
      <span class="font-light">Signature:</span>
      <div v-if="signature" class="text-4xl font-signature font-bold">{{ signature }}</div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import InputText from 'primevue/inputtext'
InputText.compatConfig = { MODE: 3 }

export default {
  name: 'SignatureField',
  components: {
    InputText
  },
  emits: ['signature'],
  setup(props, { emit }) {
    const signature = ref('')

    watch(signature, (newVal) => {
      emit('signature', newVal)
    })

    return { signature }
  }
}
</script>
