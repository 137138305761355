<template>
  <div style="position: absolute; inset: 0">
    <Sheets
      ref="sheet"
      v-if="hasItems"
      :border-radius="5"
      :default-cell-height="40"
      @rowsSelected="rowSelectedHandler"
      @changes="changesHandler"
      :selectedRowActions="selectedRowActions"
      :freeze="1"
      :sheets="[
        {
          title: 'Materials to purchase',
          sort: {
            sort: [
              [7, 'asc'],
              [2, 'asc'],
              [0, 'asc']
            ]
          },
          group: {
            group: [],
            showHeadings: true,
            showTotals: true,
            spacing: 50
          },
          superHeaders: [
            {
              title: 'Classification',
              span: [2, 4],
              formatting: {
                background: '#75ac3080'
              }
            },
            // {
            //   title: 'Budgeted • per unit',
            //   span: [4, 6],
            //   formatting: {
            //     background: '#75ac3080',
            //   },
            // },
            // {
            //   title: 'Budgeted • per qty',
            //   span: [8, 10],
            //   formatting: {
            //     background: '#30ac4080',
            //   },
            // },
            {
              title: 'Purchase details',
              span: [7, 10],
              formatting: {
                background: '#1f8c74b0'
              }
            }
          ],
          rows: sortedItems.materialItems,
          columns: materialsColumns
        },
        {
          title: 'Materials provided by subcontractors',
          sort: {
            sort: [
              [7, 'asc'],
              [2, 'asc'],
              [0, 'asc']
            ]
          },
          group: {
            group: [],
            showHeadings: true,
            showTotals: true,
            spacing: 50
          },
          superHeaders: [
            {
              title: 'Classification',
              span: [2, 4],
              formatting: {
                background: '#75ac3080'
              }
            },
            // {
            //   title: 'Budgeted • per unit',
            //   span: [4, 6],
            //   formatting: {
            //     background: '#75ac3080',
            //   },
            // },
            // {
            //   title: 'Budgeted • per qty',
            //   span: [8, 10],
            //   formatting: {
            //     background: '#30ac4080',
            //   },
            // },
            {
              title: 'Purchase details',
              span: [7, 10],
              formatting: {
                background: '#1f8c74b0'
              }
            }
          ],
          rows: sortedItems.subItems,
          columns: subColumns
        }
      ]"
    />

    <div class="p-4" v-else>
      <Info :closable="false">No material items.</Info>
    </div>

    <MiniModal
      size="sm"
      :width="500"
      @close="editing = false"
      v-if="showMiniModal"
      ref="itemEditor"
    >
      <CostItemBody
        v-if="editing"
        @close="() => $refs.itemEditor.close()"
        :store="storeName"
        :reference="editingReference"
        :key="editingReference"
      />

      <Btn class="block xl square btn" @click="() => $refs.itemEditor.close()"> Close </Btn>
    </MiniModal>
  </div>
</template>

<script>
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import CostItemBody from '@/components/bodies/CostItem.vue'
import Sheets from '../../Sheets/Sheets.vue'
import { isNotItem } from '@/../imports/api/Item'

const conditionalFormatting = (value, cell, row) => {
  if (row.cost_type_is_subcontracted > 0) {
    return {
      preset: 'muted'
    }
  }

  return {}
}

export default {
  name: 'MaterialsList',
  mixins: [ObjectManipulator('quote', false), BodyMixin /* AutoSave */],
  data() {
    return {
      selectedRows: {},
      selectedIds: [],
      editingReference: null,
      editing: false,
      showMiniModal: false,
      searchPhrase: ''
    }
  },
  watch: {},
  computed: {
    selectedRowSavedState() {
      if (!this.selectedIds.length) return null

      if (this.selectedIds.length === 1) {
        const id = this.selectedIds[0]

        const type = this.norm[id].type

        if (type !== 'cost_item') return null

        if (!this.norm[id].cost_type_id) return 'addToCatalog'
      }

      return 'saveToCatalog'
    },
    savedMessage() {
      switch (this.selectedRowSavedState) {
        case 'addToCatalog':
          return 'Save to catalog'
        case 'saveToCatalog':
          return 'Save changes'
        default:
          return 'Save item'
      }
    },
    selectedRowActions() {
      return [
        {
          name: 'Edit item',
          icon: 'pencil',
          action: this.editItem
        }
      ]
    },
    subColumns() {
      return [...this.materialsColumns.slice(0, 8)]
    },
    materialsColumns() {
      return [
        {
          title: 'Material name',
          field: 'cost_type_name',
          conditionalFormatting,
          formatting: {
            width: 250,
            align: 'left',
            bold: true
          }
        },
        {
          title: 'Progress',
          field: 'progress',
          conditionalFormatting,
          formatting: {
            width: 120
          },
          progress: {
            colorRange: '#0b4bff',
            steps: [
              {
                text: 'Ordered',
                groups: ['a', 'b'],
                underlay: {
                  icon: 'cart',
                  square: 0
                }
              },
              {
                text: 'Item received',
                groups: ['a'],
                underlay: {
                  icon: 'boxCheck',
                  square: 0
                }
              },
              {
                text: 'Paid vendor',
                groups: ['b'],
                underlay: {
                  icon: 'paid',
                  square: 0
                }
              }
            ]
          }
        },
        {
          field: 'stage_id',
          choose: {
            schema: 'stage:stage_id'
          },
          conditionalFormatting,
          formatting: {
            width: 100,
            align: 'left',
            color: '#888'
          }
        },
        {
          title: 'SKU',
          field: 'cost_type_sku',
          formatting: {
            width: 75,
            align: 'left',
            color: '#888'
          }
        },
        {
          title: 'Location',
          field: 'cost_item_location',
          formatting: {
            width: 150,
            align: 'left',
            color: '#888'
          }
        },
        {
          title: 'Dimensional qty',
          titleColSpan: 2,
          field: 'cost_item_qty_net',
          conditionalFormatting,
          formatting: {
            format: 'number',
            width: 50,
            align: 'right',
            bold: true,
            color: '#666',
            borders: {
              right: {
                thickness: 0
              }
            }
          }
        },
        {
          field: 'unit_of_measure_id',
          choose: {
            schema: 'unit_of_measure:unit_of_measure_id',
            order: [
              ['unit_of_measure_is_linkable', 'desc'],
              [
                'unit_of_measure_is_metric',
                this.$store.state.session.company.country_id <= 2 ? 'asc' : 'desc'
              ]
            ]
          },
          conditionalFormatting,
          formatting: {
            width: 50,
            align: 'left',
            color: '#888',
            borders: {
              left: {
                thickness: 0
              }
            }
          }
        },

        {
          choose: {
            schema: 'vendor:vendor_id'
          },
          field: 'vendor_id',
          formatting: {
            width: 150
          }
        },

        {
          title: 'Purchase qty',
          titleColSpan: 2,
          field: 'purchase_cost_item_qty_net',
          formatting: {
            preset: 'footing',
            format: 'number',
            width: 50,
            align: 'right',
            bold: true,
            borders: {
              right: {
                thickness: 0
              }
            }
          }
        },
        {
          field: 'purchase_unit_of_measure_id',
          choose: {
            schema: 'unit_of_measure:unit_of_measure_id',
            order: [['unit_of_measure_is_linkable', 'asc']]
          },
          formatting: {
            preset: 'heading',
            width: 50,
            align: 'left',
            borders: {
              left: {
                thickness: 0
              }
            }
          }
        },

        // TODO put in context menu
        // {
        //   title: 'Cost per purchase unit',
        //   field: 'cost_item_cost_per_purchase_unit_net',
        //   formatting: {
        //     format: 'currency',
        //     width: 75,
        //     align: 'right',
        //   },
        // },

        {
          title: 'Purchase cost',
          field: 'cost_item_materials_cost_net',
          formatting: {
            preset: 'footing',
            format: 'currency',
            width: 100,
            align: 'right',
            bold: false,
            color: '#666'
          }
        }
      ]
    },
    sortedItems() {
      const refIds = Object.keys(this.norm)
      const items = Object.values(this.norm)
      const materialItems = []
      const materialsFields = this.materialsColumns.map((col) => col.field)
      const subItems = []

      for (let i = 0; i < items.length; i += 1) {
        if (isNotItem(items[i])) continue
        if (
          items[i].type === 'cost_item' &&
          items[i].cost_type_has_materials &&
          !c.eq(items[i].cost_item_qty_net, 0)
        ) {
          const item = materialsFields.reduce(
            (acc, field) => ({
              ...acc,
              [field]: items[i][field]
            }),
            {}
          )

          item.purchaseQtySet = !c.eq(item.purchase_unit_of_measure_id, item.unit_of_measure_id)
          item.cost_item_purchase_qty_net = !item.purchaseQtySet
            ? item.cost_item_qty_net
            : Math.max(
                item.cost_item_qty_net > 0 ? 1 : 0,
                c.divide(item.cost_item_qty_net, item.cost_type_materials_purchase_qty_per_unit)
              )

          item.aoLocation = items[i].aoLocation || []
          item.cost_item_location =
            (items[i].aoLocation[items[i].aoLocation.length - 1] &&
              items[i].aoLocation[items[i].aoLocation.length - 1].name) ||
            ''

          item.vendor_id = items[i].vendor_id || items[i].vendor_name

          item.progress = [
            /* 0, 0, */
            /p|c/.test(String(items[i].cost_item_materials_status)) ? 1 : 0,
            items[i].cost_item_materials_status === 'c' ? 1 : 0,
            items[i].cost_item_is_materials_purchased
          ]

          item.cost_item_has_locked_price = 1

          // Needs this for back and forth setting and getting
          item.id = refIds[i]

          if (items[i].cost_type_is_subcontracted) {
            subItems.push(item)
          } else {
            materialItems.push(item)
          }
        }
      }

      return {
        subItems,
        materialItems
      }
    },
    items() {
      if (!this.searchPhrase) {
        return this.sortedItems
      }

      const sp = this.searchPhrase.toLowerCase()
      return this.sortedItems.filter((it) => JSON.stringify(it).toLowerCase().includes(sp))
    },
    hasItems() {
      return (
        this.items &&
        ((this.items.subItems && this.items.subItems.length > 0) ||
          (this.items.materialItems && this.items.materialItems.length > 0))
      )
    }
  },
  methods: {
    async changesHandler(changes) {
      let equations = {}
      let explicit = {}
      let originals = {}

      const values = changes.reduce((acc, change) => {
        const refId = change.id // VueStore refId
        const field = change.field
        const value = change.deformatted
        const eq = change.equation

        if (change.explicit) {
          explicit = {
            ...explicit,
            [refId]: {
              ...(explicit[refId] || {}),
              [field]: value
            }
          }
        }

        if (eq) {
          equations = {
            ...equations,
            [refId]: {
              ...(equations[refId] || {}),
              [field]: eq
            }
          }
        }

        originals = {
          ...originals,
          [refId]: {
            ...(originals[refId] || {}),
            [field]: change.previous.raw
          }
        }

        return {
          ...acc,
          [refId]: {
            ...(acc[refId] || {}),
            [field]: value
          }
        }
      }, [])

      const responseChanges = await this.setFields(values, equations, explicit, originals)
      this.$refs.sheet.setFieldValues(responseChanges)
    },
    async setFields(values, equations = {}, explicit = {}) {
      const setters = {
        cost_item_qty_net: (refId, value, equation = null) =>
          this.$store.dispatch('CostItem/setQty', {
            store: this.storeName,
            refId,
            qty: value,
            equation,
            auditLocal: false,
            auditFull: false
          }),
        purchase_cost_item_qty_net: (refId, value) => {
          const object = this.norm[refId]

          if (value < object.cost_type_minimum_qty_net) {
            this.$store.dispatch('alert', {
              error: true,
              message: `${object.cost_type_name} has a minimum qty of ${c.format(object.cost_type_minimum_qty_net, 'number')}`
            })

            return {}
          }

          const qtyPer = c.n(
            object.cost_type_materials_purchase_qty_per_unit === null
              ? 1
              : object.cost_type_materials_purchase_qty_per_unit,
            1
          )
          const wastage = 1 + c.n(object.cost_type_material_waste_factor_net, 0)
          const withoutWastage = c.divide(value, wastage)

          const net = withoutWastage * qtyPer

          return this.$store.dispatch('CostItem/setQty', {
            store: this.storeName,
            refId,
            qty: net,
            auditLocal: false,
            auditFull: false
          })
        },
        cost_item_materials_cost_net: (refId, value) =>
          this.$store.dispatch('CostItem/setMaterialsCost', {
            store: this.storeName,
            refId,
            materialsCost: value,
            holdPrice: true,
            auditLocal: false,
            auditFull: false
          }),
        progress: (refId, value) =>
          this.$store.dispatch('Quote/field', {
            changes: {
              [refId]: {
                cost_item_is_materials_purchased: value[2],
                cost_item_materials_status: value[0] ? (value[1] ? 'c' : 'p') : 'o'
              }
            },
            explicit: true,
            skipAudit: true,
            skipLocalAudit: true
          })
      }

      // Get all changes, submit complex ones one by one, with audit disabled
      // then submit the rest in bulk
      const bulkChanges = {}
      const eqChain = []
      const fieldChain = []

      const refIds = Object.keys(values)
      refIds.forEach((refId) => {
        Object.keys(values[refId]).forEach((field) => {
          if (equations[refId] && equations[refId][field]) {
            eqChain.push(() =>
              this.$store.dispatch('Quote/fieldEquation', {
                field,
                equation: equations[refId][field],
                refId
              })
            )
          }

          // set individuals
          if (field in setters) {
            fieldChain.push(() =>
              setters[field](
                refId,
                values[refId][field],
                (equations[refId] && equations[refId][field]) || null
              )
            )
          } else {
            // collect the rest to sumit at once
            if (!bulkChanges[refId]) bulkChanges[refId] = {}
            bulkChanges[refId][field] = values[refId][field]
          }
        })
      })

      // do bulk
      if (Object.keys(bulkChanges).length) {
        fieldChain.push(() =>
          this.$store.dispatch('Quote/field', {
            changes: bulkChanges,
            explicit,
            immediate: true,
            skipAudit: true,
            skipLocalAudit: true
          })
        )
      }

      let payloads = await Promise.all([...fieldChain, ...eqChain].map((fn) => fn()))

      const auditPayloads = await Promise.all(
        refIds.map((refId) =>
          this.$store.dispatch('Quote/auditLocalDependencies', {
            refId,
            immediate: true
          })
        )
      )

      // Then trigger a full audit, not immediate, do not await
      this.$store.dispatch('Quote/auditDependencies', {
        refId: refIds[0],
        immediate: false
      })

      payloads = [...payloads, ...auditPayloads]

      const sameAsOriginal = (refId, field, value) => {
        const original = values[refId] && values[refId][field]

        if (!original) return false

        const isNumeric = c.isNumericField(field)
        if (!isNumeric && `${value}` === `${original}`) return true

        if (isNumeric && c.eq(value, original)) return true

        return false
      }

      const responseChanges = {}
      for (let i = 0; i < payloads.length; i += 1) {
        const payload = _.imm(payloads[i])
        const { changeSet } = payload

        if (!payload.audited) continue

        Object.keys(changeSet).forEach((refId) => {
          Object.keys(changeSet[refId]).forEach((field) => {
            if (sameAsOriginal(refId, field, changeSet[refId][field])) return

            responseChanges[refId] = {
              ...responseChanges[refId],
              [field]: changeSet[refId][field]
            }
          })
        })
      }

      return responseChanges
    },
    rowSelectedHandler(payload) {
      const { ids, rows } = payload

      this.selectedRows = rows
      this.selectedIds = ids
    },

    async editItem({ id: refId }) {
      this.showMiniModal = true
      this.editing = false
      await this.$nextTick()
      this.editingReference = refId
      this.editing = true
      this.$refs.itemEditor.open()
    },
    setStatus(item, status) {
      this.$store.dispatch(`${this.storeName}/field`, {
        changes: {
          cost_item_materials_status: status || 'o'
        },
        explicit: true,
        refId: item.refId
      })
    },
    setIsPurchased(item, isPurchased) {
      this.$store.dispatch(`${this.storeName}/field`, {
        changes: {
          cost_item_is_materials_purchased: isPurchased
        },
        explicit: true,
        refId: item.refId
      })
    }
  },
  components: {
    Sheets,
    CostItemBody
  },
  props: {
    deselectOnDestroy: {
      default: false
    }
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.materials-ctn {
  margin-bottom: 0 !important;
}
.materials-list {
  max-width: calc(100vw - 70px);
}
.sidepanel-shown {
  .materials-list {
    max-width: calc(100vw - 300px);
  }
}

@media (max-width: 576px) {
  .item-table tbody tr td.name {
    min-width: 11em !important;
  }

  .scroll-container--container {
    padding-left: 0 !important;
  }
}
</style>
