<template>
  <a
    class="flex flex-col justify-end w-full aspect-square rounded cursor-pointer transition hover:opacity-75"
    :style="{ backgroundColor: `#${color.hex}` }"
    @click.stop.prevent="action"
    v-tooltip="color.number ? `#${color.number}` : ''"
  >
    <slot />
    <div class="flex flex-col w-full">
      <div
        class="flex flex-col justify-center items-center text-center py-2 gap-1 w-full bg-white/50"
      >
        <p v-if="showVendor" class="text-xs font-light text-black">{{ vendorName }}</p>
        <p class="text-sm font-light text-black">{{ color.name.split(' - ')?.[1] }}</p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'Swatch',
  props: {
    showVendor: {
      default: false
    },
    color: {
      required: true
    },
    vendorName: {
      default: 'Benjamin Moore'
    },
    action: {
      default: () => {}
    }
  },
  emits: ['select'],
  computed: {
    style() {
      return {
        backgroundColor: `#${this.color.hex}`,
        color: `${this.color.contrastColor === 'dark' ? 'black' : 'white'}`
      }
    }
  }
}
</script>
