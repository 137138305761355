<template>
  <div v-if="set.length > 0" class="search-result-container" :id="id">
    <h5>{{ title }}</h5>
    <component
      :is="component"
      v-for="(result, index) in set"
      :object="result"
      :key="`${result}-${index}`"
      :middle="false"
      class="my-3"
      @click="() => $emit('click')"
    />
    <div v-if="!endOfSet" class="nav-link">
      <a @click.prevent.stop="fetchMore" class="add-btn sm default">Show more results...</a>
    </div>
    <hr />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: '',
  emits: ['click', 'set', 'loaded', 'endOfSet', 'error'],
  data() {
    return {
      /**
       * Array of objects
       * ie: [{
       *    // Individual column id
       *  column: 'quote_preview',
       *    //Column to sort by, server side
       *  sortBy: 'quote_id',
       *    // create the visual representation of the column, defaults as seen
       *  format: (obj) => obj.quote_id,
       *    // number|currency|string|time|datetime etc, see c.format function
       *  formatType: 'number',
       *    // column title
       *  name: 'Quote id',
       *     // whether column appears in table or not, toggle this
       *  visible: true,
       * }
       */
      /**
       * Array of objects we are building the table from
       */
      set: [],
      /**
       * Current offset of search, useful for searchMore() function pagination
       */
      offset: 0,
      /**
       * The max number of objects retrieved in each set.
       */
      limit: 5,
      /**
       * The count of the number of objects last fetch()'ed, useful for
       *   determining if there will be anymore entries
       *   (<limit above, then no, ==limit, then yes)
       */
      lastFetchCount: 0,
      /**
       * Flag to quickly show if all the data points for the filtered/searched set
       *   has been retreived.  Useful for pagination.
       */
      endOfSet: false,
      /**
       * The length of the last search pharse. Useful for determining if the user is
       * deleting the searchString
       */
      lastSearchLength: 0,
      /**
       * mutable filters
       */
      localFilters: this.filters
    }
  },
  watch: {
    set(v) {
      this.$emit('set', v)
    },
    searchPhrase() {
      if (this.searchPhrase.length > this.lastSearchLength && this.searchPhrase.length > 0) {
        c.throttle(
          () => {
            this.fetchNew()
          },
          {
            delay: 1000,
            key: this.title
          }
        )
      } else {
        this.offset = 0
        this.set = []
      }
      this.lastSearchLength = this.searchPhrase.length
    }
  },
  computed: {
    id() {
      return c.idFromText(this.title)
    },
    component() {
      return defineAsyncComponent(() => import(`./Previews/${c.titleCase(this.type)}.vue`))
    }
  },
  methods: {
    getFetchData() {
      return {
        searchPhrase: this.searchPhrase,
        filters: this.localFilters,
        limit: this.limit,
        offset: this.offset
      }
    },
    fetch() {
      // if (this.$refs && this.$refs.refreshBtn) this.$refs.refreshBtn.addLoading();
      let type = this.type
      if (this.type === 'Assembly') {
        type = 'Quote'
      } else if (this.type === 'CostType') {
        type = 'Cost_Type'
      }
      const searchPhrase = this.searchPhrase
      const limit = this.limit
      const offset = this.offset
      const data = this.getFetchData()
      const dataJson = JSON.stringify(data)

      // if (this.$refs && this.$refs.refreshBtn) this.$refs.refreshBtn.addLoading();
      this.endOfSet = false
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(`${_.titleCase(type)}/search`, {
            ...data,
            searchPhrase,
            limit,
            offset
          })
          .then(({ set: object }) => {
            // Just in case we have multiple requests going out for many
            //  different filters, sorts or searches, check that
            //  the settings that made this request are still the same
            //  otherwise there is another request probably still come in
            const newDataJson = JSON.stringify(this.getFetchData())
            // this.$refs.refreshBtn.endLoading();

            if (newDataJson === dataJson) {
              this.set.splice(offset, 0, ...object)
              this.offset = this.set.length

              this.endOfSet = object.length < limit || limit === 0

              if (this.endOfSet) {
                this.$emit('loaded')
                this.$emit('endOfSet')
              } else {
                this.$emit('loaded')
              }

              object = null
            }
            resolve()
          })
          .catch((error) => {
            if (this.$refs.refreshBtn) this.$refs.refreshBtn.endLoading()
            this.$emit('error', error)
            reject()
          })
      })
    },
    fetchNew() {
      this.offset = 0
      this.set = []
      this.limit = 5
      if (this.searchPhrase.length > 0) {
        this.fetch()
      }
    },
    fetchMore() {
      if (this.offset && !this.endOfSet) {
        this.limit = 45
        this.fetch()
      }
    }
  },
  props: {
    title: {
      type: String
    },
    type: {
      type: String,
      required: true
    },
    searchPhrase: {
      type: String,
      required: true
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  mounted() {
    if (this.type === 'Assembly') {
      this.localFilters = {
        ...this.filters
      }
    }
    this.fetchNew()
  },
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.search-result-container {
  h5 {
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.5;
  }
  margin-top: 4em;
}
</style>
