<template>
  <div
    :class="[
      {
        muted: isLinked || isCalculated
      },
      'relative flex flex-col gap-2 py-4 px-1 justify-start items-stretch hover:bg-surface-900/5'
    ]"
    v-if="Object.keys(dimTypes).length"
    class="area-dimension--container"
  >
    <div
      :class="[
        'leading-tight font-medium',
        {
          'text-lg': full,
          hidden: full
        }
      ]"
    >
      <slot name="before"></slot>
      {{ $f.truncate(name || 'No name', 25) }}
      <slot name="after"></slot>
    </div>
    <div class="flex justify-between text-left items-center flex-nowrap">
      <div class="flex flex-col justify-center items-start gap-2 basis-1/4 shrink">
        <div
          :class="[
            'leading-tight font-medium',
            {
              'text-lg': full,
              hidden: !full
            }
          ]"
        >
          <slot name="before"></slot>
          {{ $f.truncate(name || 'No name', 25) }}
          <slot name="after"></slot>
        </div>

        <DimensionBadge
          ref="dimBadge"
          size="sm"
          :show-suggested-measure="false"
          :dim="dimensionType"
        />

        <div class="leading-3 text-surface-400 text-xs line-clamp-2" v-tooltip="requiredBy">
          <!--      <font-awesome-icon icon="flag" />-->
          Required by {{ numRequiredBy || requiredBy }} {{ numRequiredBy ? 'items' : '' }}
        </div>
      </div>

      <template v-if="full">
        <div class="flex justify-start items-stretch h-full gap-6 basis-[400px] grow-0 shrink-0">
          <div
            class="flex flex-col h-full justify-center items-center leading-none w-[100px] basis-[100px] gap-1 hover:opacity-100 overflow-visible"
            :class="{
              ' opacity-50': !isLinked
            }"
          >
            <div class="font-medium text-xs text-surface-500">Link</div>
            <Choice
              v-model="isLinked"
              :choices="[
                {
                  value: true,
                  title: 'Link to parent',
                  desc: `Linking this dimension means that whenever this assembly is added into an estimate it will automatically inherit the dimension from the parent project or assembly.

                    If this dimension is not set in the parent, the system will automatically require it to be provided.`,
                  icon: 'link-horizontal'
                },
                {
                  value: false,
                  title: 'Manual input',
                  desc: `Making this a manual input dimension means that whenever this assembly is added to an estimate, the system will require it to be manually provided.

                    You can set a default value to save time. For example, you might set default 'Ceiling Height' to 8', and then you only need to change it when in a non-standard situation.`,
                  icon: 'link-horizontal-slash'
                }
              ]"
            >
              <template #default="{ choose }">
                <div
                  ref="linkChoice"
                  @click="choose"
                  :class="[
                    'text-sm  leading-none text-center px-1 py-0.5',
                    {
                      'text-surface-500': !isLinked,
                      'font-medium': isLinked
                    },
                    'cursor-pointer',
                    'shrink-0 min-w-10 rounded-sm !border-2 border-transparent',
                    'hover:border-2 hover:border-blue-print-500'
                  ]"
                >
                  <font-awesome-icon
                    :icon="isLinked ? 'link-horizontal' : 'link-horizontal-slash'"
                  />
                  <template v-if="isLinked"> Inherits from {{ parentName }} </template>
                  <template v-else> Not linked </template>
                </div>
              </template>
            </Choice>
          </div>

          <div
            class="flex flex-col justify-center items-center leading-none w-[100px] basis-[100px] gap-1 hover:opacity-100 overflow-visible"
            :class="{
              ' opacity-50': !isCalculated
            }"
          >
            <template v-if="!isLinked">
              <div class="font-medium text-xs text-surface-500">Formula</div>
              <div
                class="text-sm leading-none text-center px-1 py-0.5 overflow-ellipsis whitespace-nowrap overflow-hidden max-w-full"
                @click.capture.stop.prevent="handleShowCalc"
                @dblclick.capture.stop.prevent="handleShowCalc"
                v-tooltip="
                  isLinked ? 'Unlink from parent first' : isCalculated ? value.equation : false
                "
                :class="[
                  {
                    'text-surface-500': !isCalculated,
                    'font-medium font-mono !text-pitch-black': isCalculated
                  },
                  'cursor-pointer',
                  'shrink-0 min-w-10 rounded-sm !border-2 border-transparent',
                  'hover:border-2 hover:border-blue-print-500'
                ]"
              >
                <template v-if="isLinked"> Linked to parent </template>
                <template v-else-if="!isCalculated"> Manual input </template>
                <template v-if="isCalculated">
                  = {{ value.equation.replace('\r\n', ' ') }}
                </template>
              </div>
            </template>
          </div>

          <div
            class="flex flex-col justify-center items-center leading-none text-center w-[100px] basis-[100px] gap-1 opacity-50 hover:opacity-100 shrink-1 !overflow-visible"
          >
            <template v-if="store === 'Assembly' && !isCalculated">
              <div class="font-medium text-xs text-surface-500 text-center">Default value</div>
              <Choice
                v-model="inputValue"
                @choice="handleBlur"
                :choices="[
                  {
                    value: value.value || '1',
                    title: value.value
                      ? `Use value ${value.value}${value.measure} as default`
                      : 'Set a default starting value',
                    desc: `Set a default starting value for things that are standard so you don't have to input it each time.`,
                    icon: 'link-horizontal'
                  },
                  {
                    value: '0',
                    title: 'Require input each time',
                    desc: `Some dimensions need to be changed each time. Remove the default to require a value each time.

                    The system will automatically prompt the estimator to input the value each time the assembly is added to an estimate.`,
                    icon: 'link-horizontal-slash'
                  }
                ]"
              >
                <template #default="{ choose }">
                  <div
                    ref="linkChoice"
                    @click="choose"
                    :class="[
                      'text-sm  leading-none text-center px-1 py-0.5',
                      {
                        'text-surface-500': !value.value,
                        'font-medium': value.value
                      },
                      'cursor-pointer',
                      'shrink-0 min-w-10 rounded-sm !border-2 border-transparent',
                      'hover:border-2 hover:border-blue-print-500'
                    ]"
                  >
                    <div class="text-sm leading-none text-center px-1 py-0.5" v-if="!value.value">
                      No default
                    </div>
                    <div class="text-sm leading-none text-center px-1 py-0.5" v-else>
                      {{ value.value }} {{ value.measure }}
                    </div>
                  </div>
                </template>
              </Choice>
            </template>
          </div>
        </div>
      </template>

      <div class="flex flex-row justify-end items-center h-full !overflow-visible grow shrink-0">
        <div
          v-if="value.error"
          class="cursor-pointer hover:bg-deep-red-500/10 rounded-md p-2"
          @click="
            () => {
              inputValue = '1'
              handleBlur()
            }
          "
          v-tooltip="
            'This is part of a circular reference! Repair references for quantities to calculate properly by not referencing other dimensions in this formula.'
          "
        >
          <font-awesome-icon icon="warning" size="xl" class="text-deep-red-500" />
        </div>
        <span v-if="(!isRoot || isAssemblyRoot) && isLinked && !isCalculated">
          <btn
            v-tooltip="
              `This dimension is derived off the value of other dimensions. Change the following dimensions instead: ${usingDimensions.join(', ')}`
            "
            link
            rounded
            class="!text-blue-print"
            @click="isLinked = false"
          >
            <font-awesome-icon icon="link-horizontal" />
          </btn>
        </span>

        <span v-else-if="isCalculated">
          <btn
            v-tooltip="
              `This dimension is derived off the value of other dimensions. Change the following dimensions instead: ${usingDimensions.join(', ')}`
            "
            link
            rounded
            class="!text-blue-print"
            @click="isLinked = false"
          >
            <font-awesome-icon icon="link-horizontal" />
          </btn>
        </span>

        <span
          v-tooltip="
            `This dimension is set manually. Click to inherit the dimension value from ${parentName} instead.`
          "
          class="cursor-pointer"
          v-if="!full && (!isRoot || isAssemblyRoot) && !isLinked && !isCalculated"
        >
          <btn
            link
            rounded
            class="muted more cursor-pointer !text-surface-400"
            @click="isLinked = true"
          >
            <font-awesome-icon icon="link-horizontal-slash" />
          </btn>
        </span>

        <template v-if="!isLinked && (!isCalculated || showCalc)">
          <InlineCalculator
            ref="calc"
            v-if="showCalc"
            :class="['shrink-0 min-w-8', inputSize]"
            :format="value.measure === 'ft' ? 'imperial' : 'number'"
            :variables="dimensions"
            :measure="measure"
            v-model="inputValue"
            @blur="handleBlur"
            placeholder="0"
          />
          <div
            v-else
            @click.capture.stop.prevent="handleShowCalc"
            @dblclick.capture.stop.prevent="handleShowCalc"
            :class="[
              'cursor-pointer text-right',
              'shrink-0  min-w-8 rounded-sm !border-2 border-transparent',
              'hover:border-2 hover:border-blue-print-500',
              inputSize
            ]"
          >
            {{ formattedValue }}
          </div>

          <choose
            :allowDeselect="false"
            :staticSet="measuresPreset"
            v-model="measure"
            :returnArray="false"
            :default="defaultMeasure"
          >
            <template #default="{ text }">
              <span
                :class="[
                  'leading-3 cursor-pointer p-1 h-fit border-2 border-transparent rounded-sm hover:border-blue-print-500',
                  inputSize
                ]"
              >
                {{ text }}
              </span>
            </template>
          </choose>
        </template>
        <a
          :class="[
            'px-1 text-center leading-3 cursor-pointer text-blue-print hover:text-blue-print/80 whitespace-nowrap opacity-75',
            inputSize
          ]"
          @click="isLinked = false"
          v-else-if="!isCalculated"
          v-tooltip="
            `Using ${$f.truncate(parentName, 50)} 🔗 ${value.abbr} • Click to unlink and set manually...`
          "
        >
          {{ formattedValue }} {{ measureLocal }}
        </a>
        <a
          v-tooltip="`Using formula = ${value.equation} • Click to change or set manually...`"
          :class="[
            'px-1 text-center leading-3 cursor-pointer text-blue-print hover:text-blue-print/80 whitespace-nowrap opacity-75',
            inputSize
          ]"
          @click="showCalc = true"
          v-else
        >
          {{ formattedValue }} {{ measureLocal }}
        </a>
      </div>
    </div>

    <div
      :class="[
        'pointer-events-none absolute left-0 -right-0 top-0 bottom-0 rounded-lg border-2 transition-all',
        {
          ' border-pitch-black': tempHighlight,
          ' border-blue-print-500': !tempHighlight
        }
      ]"
      v-if="isHighlighted || tempHighlight"
    ></div>
    <div
      class="pointer-events-none absolute left-0 right-0 top-1 bottom-1 rounded-lg border-2 border-deep-red-500"
      v-else-if="!value.value && !isLinked && !usingDimensions?.length"
    ></div>
  </div>
</template>

<script>
import DimensionMixin from './DimensionMixin'
import DimensionBadge from '../../ui/DimensionBadge.vue'
import InlineCalculator from '@/components/ui/Calculator/Field.vue'
import Dimensions from '@/components/composables/Dimensions.js'
import eventBus from '@/eventBus.js'

export default {
  setup() {
    const dimensions = Dimensions.useDimensions()
    return dimensions
  },
  name: 'AreaDimension',
  components: { InlineCalculator, DimensionBadge },
  mixins: [DimensionMixin],
  computed: {
    // :value="inputValue"
    // @blur="handleBlur"
    // @input="handleInput"
  },
  watch: {
    isLinked() {
      this.tempHighlight = true
      c.throttle(() => (this.tempHighlight = false), { delay: 3000, key: this.value.abbr })
    },
    value(a, b) {
      if (a.equation !== b.equation) {
        this.tempHighlight = true
        c.throttle(() => (this.tempHighlight = false), { delay: 3000, key: this.value.abbr })
      }
    }
  },
  props: {
    full: {
      type: Boolean,
      default: false
    },
    inputSize: {
      default: 'text-md'
    }
  },
  data() {
    return {
      inputValue: this.value.equation || `${this.value.value}` || '0',
      hasChanged: '',
      showCalc: false,
      isHighlighted: false,
      tempHighlight: false
    }
  },
  mounted() {
    eventBus.$on(`closeDimensions`, this.handleClose)
    eventBus.$on(`openDimensionsAbbr-${this.reference}-${this.abbr}`, this.highlight)
  },
  beforeUnmount() {
    eventBus.$off(`closeDimensions`, this.handleClose)
    eventBus.$off(`openDimensionsAbbr-${this.reference}-${this.abbr}`, this.highlight)
  },
  methods: {
    handleClose() {
      this.isHighlighted = false
    },
    highlight() {
      this.isHighlighted = true
      c.throttle(() => c.scrollTo(this.$el), { delay: 100 })
      // setTimeout(() => {
      //   this.isHighlighted = false;
      // }, 20000);
    },
    async handleShowCalc() {
      this.showCalc = true
      await this.$nextTick()
      this.$refs.calc.focus()
    },
    handleInput(equation) {
      this.hasChanged = true
      this.inputValue = equation
    },
    handleBlur() {
      setTimeout(() => {
        const eq = this.inputValue

        let value
        const isEq = c.isEquation(eq, this.dimensions)

        if (c.isEquation(eq, this.dimensions)) {
          value = this.getDimensionValue(eq, this.dimensions, this.measure)
        } else {
          value = c.toNum(eq)
        }

        if (
          c.eq(value, this.value.value) &&
          ((!isEq && !c.isEquation(this.value.equation, this.dimensions)) ||
            eq === this.value.equation)
        )
          return

        this.setDimension(value, eq)
      })
      this.showCalc = false
      this.hasChanged = false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.area-dimension--container {
  input[type='text'],
  .qtyfield {
    font-weight: bold;
    background: transparent;
  }

  .area-dimension--color-badge {
    border-radius: 0.25em;
    padding: 0.1em 0.5em;
    color: $pitch-black;
    font-size: 0.95em;
    line-height: 1.1;

    code {
      color: $pitch-black;
    }
  }
}
</style>
