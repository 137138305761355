<script setup>
import { watch, ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import useSearch from '@/components/composables/Search'
import GlobalSearchSection from '@/components/globalSearch/GlobalSearchSection.vue'
import InputIcon from 'primevue/inputicon'
import IconField from 'primevue/iconfield'
import InputText from 'primevue/inputtext'
import Chip from 'primevue/chip'

const {
  query,
  loading,
  fetch,
  clearSearch,
  searchModal,
  searchInput,
  grouped,
  types,
  filterTypes,
  isQuery,
  filterOptions
} = useSearch()

const today = ref([])
const pastWeek = ref([])
const pastThirtyDays = ref([])
const enableRecentSearch = ref(false)

const $store = useStore()

const searchRecent = async () => {
  const startOfToday = moment().startOf('day').unix()
  const endOfToday = moment().endOf('day').unix()
  const todayFilters = types.value.reduce((acc, type) => {
    acc[type] = [
      {
        [`${type}_time_last_modified`]: `>${startOfToday}&&<${endOfToday}`
      }
    ]
    return acc
  }, {})
  today.value = await fetch({
    filters: todayFilters,
    multiple: true
  })

  const startOfWeek = moment().subtract(1, 'w').unix()
  const startOfWeekFilters = types.value.reduce((acc, type) => {
    acc[type] = [
      {
        [`${type}_time_last_modified`]: `>${startOfWeek}&&<${startOfToday}`
      }
    ]
    return acc
  }, {})
  pastWeek.value = await fetch({
    filters: startOfWeekFilters,
    multiple: true
  })

  const startOfThirtyDays = moment().subtract(30, 'd').unix()
  const thirtyDaysFilters = types.value.reduce((acc, type) => {
    acc[type] = [
      {
        [`${type}_time_last_modified`]: `>${startOfThirtyDays}&&<${startOfWeek}`
      }
    ]
    return acc
  }, {})
  pastThirtyDays.value = await fetch({
    filters: thirtyDaysFilters,
    multiple: true
  })
}

const toggleFilter = (filter) => {
  const { value } = filter
  const index = filterTypes.value.indexOf(value)
  if (index !== -1) {
    filterTypes.value.splice(index, 1)
    return
  }
  filterTypes.value.push(value)
}

watch(
  () => filterTypes.value,
  () => {
    if (!requested.value) return
    if (!isQuery.value && enableRecentSearch.value) searchRecent()
    if (isQuery.value) fetch()
  }
)

watch(
  () => query.value,
  () => {
    if (!requested.value) return
    c.throttle(
      () => {
        fetch()
      },
      { delay: 1000 }
    )
  }
)

const requested = ref(false)
const openHandler = () => {
  filterTypes.value = types.value
  if (enableRecentSearch.value) searchRecent()
  requested.value = true
}
</script>

<template>
  <Modal
    ref="searchModal"
    :zIndex="$store.state.modal.topZIndex + 10"
    :closeable="true"
    :moveable="false"
    :pt="{
      header: '!py-5 !px-5 w-full !flex-row !bg-cement-100',
      root: '!w-[100vw] md:!w-[80vw] md:!max-w-[80vw] !h-full min-h-[100vh] md:!min-h-[85vh]',
      content: '!bg-cement-100 !h-full'
    }"
    @open="openHandler"
    :isFocus="true"
    size="lg"
  >
    <template #body v-if="requested">
      <!-- Search bar and filter chips -->
      <div class="form-inline flex flex-col mb-4">
        <div
          class="w-full font-normal flex justify-center items-stretch flex-row gap-2 text-nowrap overflow-hidden relative"
        >
          <IconField iconPosition="left" class="w-full">
            <InputIcon>
              <font-awesome-icon :icon="['far', 'search']" class="text-pitch-black text-lg" />
            </InputIcon>
            <InputText
              ref="searchInput"
              :placeholder="'Search everything'"
              class="h-full text-xl"
              v-model.lazy="query"
              :ptOptions="{ mergeProps: true }"
              :pt="{
                root: '!h-14 !text-cool-gray-700 !hover:border-pitch-black !hover:bg-pitch-black !hover:text-level-yellow !rounded-md'
              }"
            />
          </IconField>
          <font-awesome-icon
            v-if="isQuery"
            @click.native="clearSearch"
            class="text-surface-950 hover:text-surface-600 transition absolute right-2 top-3 cursor-pointer p-1 rounded-full"
            icon="xmark"
          ></font-awesome-icon>
        </div>
        <div class="d-flex mt-4">
          <Chip
            class="text-sm font-normal mr-2 mb-2 cursor-pointer !px-4 !py-4"
            :class="
              filterTypes.includes(option.value)
                ? '!bg-blue-print-500/50 !hover:bg-blue-print-300 !text-white'
                : 'hover:bg-cool-gray-300'
            "
            :label="option.text"
            :removable="!!filterTypes.includes(option.value)"
            :key="option.value"
            :ptOptions="{ mergeProps: true }"
            :pt="{ root: '!leading-tight !px-2 max-h-6', label: '!my-0' }"
            @click.native="() => toggleFilter(option)"
            v-for="option in filterOptions"
          />
        </div>
      </div>

      <!-- Global search -->
      <GlobalSearchSection class="mt-2" v-if="!loading && isQuery" :group="grouped" />
      <!-- Recent search -->
      <div v-if="!loading && !isQuery">
        <GlobalSearchSection :group="today" title="Today" />
        <GlobalSearchSection :group="pastWeek" title="Past week" />
        <GlobalSearchSection :group="pastThirtyDays" title="Past 30 days" />
      </div>
      <!-- Loading section -->
      <div v-if="loading" class="animate-fadeIn">
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem" class="mb-2"></Skeleton>
        <Skeleton height="3rem"></Skeleton>
      </div>
      <!-- Prompt for more results -->
      <div
        class="flex flex-col w-full h-full flex items-center justify-center px-6 py-12 gap-y-4"
        v-if="!loading && !isQuery"
      >
        <font-awesome-icon :icon="['fal', 'keyboard']" class="text-cement-700 text-5xl" />
        <span class="text-xl text-cement-700"
          >Type to search for clients, quotes, invoices, assemblies, and files.</span
        >
      </div>
    </template>
  </Modal>
</template>
