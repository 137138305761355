import { computed, ref } from 'vue'
import { getAudience } from '@/../imports/api/Item'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'

export default function useSelections(normalized) {
  const norm = ref(normalized)
  const rootRefId = NormalizeUtilities.getNormalizedRootRefId(norm.value)

  const preferredOrder = computed(() => {
    return NormalizeUtilities.sortNatural(norm.value)
  })

  const shouldShowItem = (ref) => {
    const item = norm.value[ref]
    return item && item.oMeta?.itemType !== 'task' && ref !== rootRefId
  }

  const quoteItemRefs = computed(() => {
    return preferredOrder.value
  })

  const metaRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) => norm.value[ref].oMeta?.itemType && norm.value[ref].oMeta?.itemType !== 'costItem'
    )
  })

  const variationRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) =>
        norm.value[ref].oVariations?.variationTypes?.length ||
        norm.value[ref].oVariations?.selectedItem ||
        norm.value[ref].oVariations?.parentId
    )
  })

  const optionalRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) => norm.value[ref].cost_item_is_optional || norm.value[ref].assembly_is_optional
    )
  })

  const excludedRefs = computed(() => {
    return quoteItemRefs.value.filter(
      (ref) =>
        (norm.value[ref].type === 'cost_item' && !norm.value[ref].cost_item_is_included) ||
        (norm.value[ref].type === 'assembly' && !norm.value[ref].assembly_is_included)
    )
  })

  const inputRefs = computed(() => {
    return Object.keys(norm).filter((ref) => norm.value[ref]?.oInputRequired?.inputs?.length)
  })

  const addonRefs = computed(() => {
    return quoteItemRefs.value.filter((ref) => norm.value[ref].aoAddons?.length)
  })

  const interactiveRefs = computed(() => {
    const refs = [
      ...optionalRefs.value,
      ...variationRefs.value,
      ...inputRefs.value,
      ...addonRefs.value
    ]
    return _.uniq(refs)
      .filter((ref) => shouldShowItem(ref) && !excludedRefs.value.includes(ref))
      .sort((a, b) => preferredOrder.value.indexOf(a) - preferredOrder.value.indexOf(b))
  })

  const standardRefs = computed(() => {
    return quoteItemRefs.value.filter((ref) => !interactiveRefs.value.includes(ref))
  })

  const allRefs = computed(() => {
    return _.uniq([...interactiveRefs.value, ...standardRefs.value])
      .filter((ref) => shouldShowItem(ref))
      .sort((a, b) => preferredOrder.value.indexOf(a) - preferredOrder.value.indexOf(b))
  })

  const hiddenAssemblyRefs = computed(() => {
    return allRefs.value.filter((ref) => {
      const item = norm.value[ref]
      const vis = item.oMeta?.viewOptions?.pres?.isVisible ?? 1
      return item.type === 'assembly' && vis === 0
    })
  })

  const hiddenAssemblyChildren = computed(() => {
    const visited = new Set()
    const childrenRefs = new Set()

    const collectHiddenChildren = (assemblyRefs) => {
      assemblyRefs.forEach((ref) => {
        if (visited.has(ref)) return
        visited.add(ref)

        const item = norm.value[ref]
        if (item?.aoChildren?.length) {
          item.aoChildren.forEach((childRef) => {
            childrenRefs.add(childRef)
            const isChildAssembly = norm.value[childRef]?.type === 'assembly'
            if (isChildAssembly) {
              collectHiddenChildren([childRef])
            }
          })
        }
      })
    }

    collectHiddenChildren(hiddenAssemblyRefs.value)
    return Array.from(childrenRefs)
  })

  const hiddenCostItems = computed(() => {
    return allRefs.value.filter((ref) => {
      const item = norm.value[ref]
      const vis = (item?.oViewOptions?.pres ?? item?.oMeta?.viewOptions?.pres ?? {})?.isVisible ?? 1
      return item.type === 'cost_item' && vis === 0
    })
  })

  const hiddenRefs = computed(() => {
    const hiddenAssembliesSet = hiddenAssemblyRefs.value
    const hiddenChildrenSet = hiddenAssemblyChildren.value
    const hiddenItems = hiddenCostItems.value

    const combinedHiddenSet = new Set([
      ...hiddenAssembliesSet,
      ...hiddenChildrenSet,
      ...hiddenItems
    ])

    return Array.from(combinedHiddenSet)
  })

  const allVisibleRefs = computed(() => {
    return allRefs.value.filter((ref) => {
      if (hiddenRefs.value.includes(ref)) return false
      return ref
    })
  })

  return {
    quoteItemRefs,
    variationRefs,
    optionalRefs,
    excludedRefs,
    inputRefs,
    addonRefs,
    interactiveRefs,
    standardRefs,
    allRefs,
    hiddenAssemblyRefs,
    hiddenAssemblyChildren,
    hiddenRefs,
    allVisibleRefs,
    metaRefs,
    shouldShowItem,
    preferredOrder,
    getAudience
  }
}
