<template>
  <div v-if="!loading" class="w-100">
    <!-- Status Filter -->
    <div v-if="filters?.length > 0" class="transaction-list--filter flex justify-end mb-2">
      <choose
        :static-set="filters"
        v-model="status"
        @input="onSelectStatus"
        :value="status"
        placeholder="Select status"
        btnClass="sm info"
      />
    </div>

    <!-- Transaction Table -->
    <DataTable
      v-if="response?.data?.length > 0"
      :value="response.data"
      :loading="isloading"
      dataKey="id"
      class="overflow-hidden"
      tableStyle="min-width: 50rem"
      :rowHover="true"
      removableSort
    >
      <Column
        v-for="col in columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :sortable="col.sortable"
        :pt="{
          root: { class: 'border-b border-gray-200' },
          headerCell: { class: 'bg-white text-gray-600 font-medium py-4 px-6' },
          bodyCell: { class: 'py-4 px-6 border-b border-gray-100' }
        }"
      >
        <template #body="{ data }">
          <template v-if="col.field === 'metadata.invoiceId' && showInvoiceColumn">
            <a
              v-if="data?.object && (data.object === 'payment_intent' || data.object === 'charge')"
              @click="() => openInvoice(data?.metadata?.invoiceId)"
              v-tooltip="`Click to edit invoice #${data?.metadata?.invoiceId}`"
              class="cursor-pointer text-blue-500 hover:text-blue-700"
            >
              {{
                data?.metadata?.invoiceId
                  ? $f.truncate(data?.metadata?.invoiceId.toString(), 12)
                  : ''
              }}
            </a>
          </template>
          <template v-else-if="col.type === 'currency'">
            <span class="text-gray-900">{{
              $f.currency(col.transform ? col.transform(data) : data?.[col.field])
            }}</span>
            <span class="text-sm text-gray-500 ml-1">{{ $f.uppercase(data?.currency) }}</span>
          </template>
          <template v-else-if="col.type === 'date'">
            <span class="text-gray-600">{{ $f.datetime(data?.[col.field]) }}</span>
          </template>
          <template v-else-if="col.type === 'status'">
            <Chip
              :label="$f.capitalize(data?.displayStatus || data?.status || '')"
              :pt="{ root: '!rounded' }"
              :ptOptions="{ mergeProps: true }"
              :class="[
                'px-3 py-1 rounded text-sm font-medium',
                data?.status === 'completed' || data?.status === 'approved'
                  ? 'bg-green-100 text-green-800'
                  : data?.status === 'pending'
                    ? 'bg-gray-100 text-gray-800'
                    : 'bg-yellow-100 text-yellow-800'
              ]"
              v-tooltip="
                data?.failure_message ||
                (data?.next_action && data?.next_action?.type?.replaceAll('_', ' '))
              "
            />
          </template>
          <template v-else-if="col.type === 'text'">
            <span class="text-gray-900">{{ data?.[col.field] }}</span>
          </template>
        </template>
      </Column>
    </DataTable>

    <!-- Loading State -->
    <div v-if="response && loading" class="loader flex items-center justify-center p-8">
      <spinner class="mr-4" :loading="1" size="2em" />
      <p class="text-gray-600">Loading..</p>
    </div>

    <!-- Empty State -->
    <p class="text-gray-500 p-8 text-center" v-if="response?.data?.length === 0">No results</p>

    <!-- Pagination -->
    <div class="mt-3 flex justify-end gap-2">
      <Btn :disabled="!response || page === 1" @click="() => loadMore('previous')"> Previous </Btn>
      <Btn :disabled="!response || !response.has_more" @click="() => loadMore('next')"> Next </Btn>
    </div>
  </div>
  <div v-else class="w-100 flex justify-center">
    <spinner :loading="1" size="2em" />
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Chip from 'primevue/chip'
import CounterpartyMixin from '../counterparty/CounterpartyMixin'

export default {
  name: 'TransactionList',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ['payment', 'intent', 'charge', 'payout', 'failed', 'paid'].includes(value)
    },
    balanceAccount: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    defaultFilter: {
      type: String,
      default: ''
    }
  },
  components: {
    DataTable,
    Column,
    Chip
  },
  mixins: [CounterpartyMixin],
  data() {
    return {
      response: null,
      isloading: false,
      cached: {},
      status: this.defaultFilter,
      page: 1
    }
  },
  computed: {
    showInvoiceColumn() {
      return ['payment', 'intent', 'charge', 'failed'].includes(this.type)
    },
    showFeeColumns() {
      return ['payment', 'intent', 'charge', 'failed'].includes(this.type)
    },
    showEstimatedArrival() {
      return ['payout', 'paid'].includes(this.type)
    },
    columns() {
      const baseColumns = [
        {
          field: 'metadata.invoiceId',
          header: 'Invoice',
          sortable: true,
          class: 'text-center',
          show: this.showInvoiceColumn,
          type: 'invoice'
        },
        {
          field: 'amount',
          header: 'Amount',
          sortable: true,
          type: 'currency',
          transform: this.getTransformedAmount
        },
        {
          field: 'clientFee',
          header: 'Client Fee',
          sortable: true,
          class: 'text-center',
          type: 'currency',
          show: this.showFeeColumns,
          transform: this.getClientFee
        },
        {
          field: 'application_fee_amount',
          header: 'Application Fee',
          sortable: true,
          class: 'text-center',
          type: 'currency',
          show: this.showFeeColumns,
          transform: this.getApplicationFee
        },
        {
          field: 'serviceFee',
          header: 'Service Fee',
          sortable: true,
          class: 'text-center',
          type: 'currency',
          show: this.showFeeColumns,
          transform: this.getServiceFee
        },
        {
          field: 'created',
          header: 'Date',
          sortable: true,
          type: 'date',
          class: 'text-center'
        },
        {
          field: 'arrival_date',
          header: 'Estimated Arrival',
          sortable: true,
          type: 'date',
          class: 'text-center',
          show: this.showEstimatedArrival
        },
        {
          field: 'priority',
          header: 'Priority',
          sortable: true,
          class: 'text-center',
          show: this.type === 'payout',
          type: 'text'
        },
        {
          field: 'status',
          header: 'Status',
          sortable: true,
          class: 'text-center',
          type: 'status'
        }
      ]

      return baseColumns.filter((col) => col.show === undefined || col.show)
    }
  },
  methods: {
    onSelectStatus() {
      this.handleFetchTransactions()
    },
    storeCache() {
      this.cached[this.page] = this.response
    },
    getCache() {
      return this.cached[this.page]
    },
    async loadMore(action = 'next') {
      if (!this.response?.data || this.isloading) return

      const filter = {
        type: this.type,
        order: this.getSortOrder(),
        balanceAccount: this.balanceAccount
      }

      if (action === 'next') {
        this.page += 1
        filter.starting_after = this.response.next_page || this.response.data.slice(-1)[0]?.id
      } else {
        this.page -= 1
        if (this.response.previous_page) {
          filter.starting_after = this.response.previous_page
        } else {
          const endingBefore = this.response.data[0]?.id
          if (this.page > 1) filter.ending_before = endingBefore
        }
      }

      filter.page = this.page

      const cache = this.getCache()
      if (cache) {
        this.response = cache
        return
      }

      if (this.status) filter.status = this.status

      await this.fetchTransactionsData(filter)
    },
    getSortOrder() {
      return `${this.type === 'payout' ? 'transfer_time_created' : 'transaction_time_created'} desc`
    },
    async fetchTransactionsData(filter) {
      this.isloading = true
      try {
        const { payload } = await this.fetchTransactions(filter)
        this.response = payload
        this.storeCache()
      } catch (error) {
        console.error('Error fetching transactions:', error)
      } finally {
        this.isloading = false
      }
    },
    async handleFetchTransactions() {
      const filter = {
        type: this.type,
        status: this.status,
        order: this.getSortOrder(),
        balanceAccount: this.balanceAccount
      }
      await this.fetchTransactionsData(filter)
    },
    openInvoice(id) {
      return this.$store.dispatch('modal/open', {
        modal: 'Invoice',
        objects: [
          {
            invoice_id: id
          }
        ]
      })
    },
    formatFeeAmount(amount) {
      if (!amount) return 0
      return amount
    },
    getTransformedAmount(data) {
      if (data?.type === 'payout' && typeof data.amount === 'number') {
        if (data.direction === 'incoming') {
          return data.amount / 100
        }

        return -(data.amount / 100)
      }
      return data?.amount
    },
    getClientFee(data) {
      return this.formatFeeAmount(data?.fee_details?.[0]?.amount || 0)
    },
    getApplicationFee(data) {
      return this.formatFeeAmount(data?.application_fee_amount || 0)
    },
    getServiceFee(data) {
      return this.formatFeeAmount(data?.serviceFee || 0)
    }
  },
  mounted() {
    this.handleFetchTransactions()
  },
  watch: {
    reload(newVal) {
      if (newVal === true) this.handleFetchTransactions()
    }
  }
}
</script>
