<template>
  <EntitySelect v-if="id" :id="id" type="quote" store="Quote" @selected="selectedHandler">
    <template #default="{ refId }">
      <QuotePresentation ref="body" :refId="refId" store="Quote" type="quote" />
    </template>
  </EntitySelect>

  <container v-else-if="!loading">
    <card-section>
      <card>
        <template #icon>
          <font-awesome-icon icon="check" />
        </template>
        <template #title>Nothing left to approve!</template>
        <p class="mb-4">There is currently nothing to approve on this project.</p>

        <btn-group>
          <btn severity="primary-black" size="lg" @click="() => $store.dispatch('to', 'project')">
            <template #icon>
              <font-awesome-icon icon="arrow-left" />
            </template>
            Go back to project dashboard
          </btn>
        </btn-group>
      </card>
    </card-section>
  </container>

  <Loader v-else :loading="1" />
</template>

<script>
import QuotePresentation from '../quote/presentation/QuotePresentation.vue'
import PageMixin from '../mixins/Page'
import PublicGatewayPage from '../mixins/PublicGatewayPage'
import EntitySelect from '@/components/ui/EntitySelect.vue'
import Loader from '@/components/ui/Loader.vue'

export default {
  mixins: [PageMixin, PublicGatewayPage],
  data() {
    return {
      loading: 1,
      refId: null
    }
  },
  computed: {
    id() {
      return this.$route.params.id || this.$store.state.session.scope.quote
    }
  },
  methods: {
    selectedHandler({ refId }) {
      this.refId = refId
      this.loading = 0
      requestAnimationFrame(() => this.markSeen())
    },
    markSeen() {
      const cid = this.$store.state.Quote.normalized[this.refId]?.change_order_id
      if (!cid) return
      return this.$store.dispatch('ChangeOrder/markMultiple', {
        markAs: 'seen',
        selected: [
          {
            type: 'change_order',
            change_order_id: cid
          }
        ],
        go: false,
        alert: false
      })
    }
  },

  components: { QuotePresentation, Loader, EntitySelect }
}
</script>
