<script setup>
import { defineProps, ref } from 'vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'

const props = defineProps(['refId', 'store', 'type'])
const { cost_type_is_subcontracted, vendorId, vendor_name } =
  EntityComputedFields.useEntityComputedFields({
    store: props.store,
    refId: props.refId,
    type: props.type
  })

const refVendor = ref(null)
</script>

<template>
  <div class="text-sm leading-snug flex flex-col gap-4">
    <Choose
      :order="[['vendor_name', 'asc']]"
      ref="refVendor"
      headless
      v-model="vendorId"
      schema="cost_type:vendor_id"
    />

    <span class="font-medium text-sm">
      <font-awesome-icon icon="truck-arrow-right" class="mr-2" />
      Subcontracted costs
    </span>

    <p>
      This item is <strong>{{ cost_type_is_subcontracted ? '' : 'not' }}</strong> subcontracted.
      Subcontracted items are items where the work or delivery will be assigned to an outside
      company (subcontractor).
    </p>

    <Inplace>
      <template #display>More on subcontracted items</template>

      <template #content>
        <div class="text-sm leading-snug flex flex-col gap-4">
          <p>
            You can mark that a subcontracted item includes materials or labor, but you don't need
            to know what the mix is. If you need to specify the mix between materials and labor
            create two separate items, one for labor and one for materials, both subcontracted.
          </p>

          <p>
            You can assign a vendor/subcontractor now as a default, and then you can change it at
            anytime.
          </p>
        </div>
      </template>
    </Inplace>

    <label
      class="flex items-center content-center gap-2 font-medium"
      v-if="cost_type_is_subcontracted"
    >
      Default subcontractor
      <a
        class="cursor-pointer underline underline-offset-4 font-medium hover:border-blue-print-500 border-2 border-transparent rounded-sm -mx-1 -my-0.5 px-1 py-0.5"
        @click="refVendor.open()"
        >{{ vendor_name || 'None' }}</a
      >
    </label>
    <label class="flex items-center content-center gap-2 font-medium">
      Subcontracted
      <InputSwitch v-model="cost_type_is_subcontracted" :trueValue="1" :falseValue="0" />
    </label>
  </div>
</template>

<style scoped lang="scss"></style>
