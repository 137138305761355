<script setup>
import { computed } from 'vue'
import { useSidePanel } from '@/stores/sidepanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'
import { useStore } from 'vuex'
import QuoteSidepanel from '@/components/Sheets/quote/estimating/QuoteSidepanel.vue'
import { useActivityChannels } from '@/components/composables/ActivityChannels'

const sidePanelStore = useSidePanel()

const $store = useStore()
const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({ trackChanges: false })
const { getFormattedChannelName, getTotalChannelNotificationsLength } = useActivityChannels()

const { quote_id } = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})

const modules = computed(() => $store.state.session.company?.aoModules ?? {})

const showInvoicing = computed(() => modules.value?.invoice === '1')

const items = computed(() => [
  // {
  //   label: 'Project dashboard',
  //   icon: 'house-building',
  //   tab: 'Progress',
  // },
  {
    label: 'Pre-production',
    expanded: true,
    items: [
      {
        label: 'Estimate',
        icon: 'table',
        tab: 'Estimate',
        desc: 'Start here to build your estimate, or make changes once the project has started.'
        // items: [
        //   {
        //     label: 'Dimensions',
        //     icon: 'ruler-triangle',
        //     tab: 'Dimensions',
        //     desc: 'Confirm and enter dimensions here.',
        //   },
        // ],
      },
      {
        label: 'Preview',
        tab: 'Preview',
        icon: 'eye',
        desc: 'See what your customer will see, and make changes to the presentation before you send.'
      },
      {
        label: 'Takeoff',
        icon: 'ruler-triangle',
        tab: 'Takeoff',
        desc: 'Review and modify takeoffs dimensions.'
      },
      {
        label: 'Selections',
        icon: 'swatchbook',
        tab: 'Selections',
        desc: 'Review and modify customer selections.'
      }
    ]
  },

  {
    label: 'Production',
    expanded: true,
    items: [
      {
        label: 'Budget',
        key: 'Budget',
        icon: 'calculator',
        tab: 'Budget',
        desc: 'Manage your profitability on this job using budgeted and actual costs.  See labor and material costs.'
        // items: [
        //   {
        //     label: 'Items',
        //     icon: 'table',
        //     tab: 'Estimate',
        //     desc: 'See all the items and assemblies in this project. Make changes to automatically generate change orders, which can be sent for approval to your client.',
        //     items: [
        //       {
        //         label: 'Dimensions',
        //         key: 'Dimensions',
        //         icon: 'ruler-triangle',
        //         tab: 'Dimensions',
        //         desc: 'See the dimensions for this project.',
        //       },
        //     ],
        //   },
        //   {
        //     label: 'Change order history',
        //     key: 'Change orders',
        //     icon: 'list-timeline',
        //     tab: 'History',
        //     desc: 'Review the history and change orders for this project.',
        //   },
        // ],
      },
      {
        label: 'Materials',
        key: 'Materials',
        icon: 'box',
        tab: 'Materials'
      },
      {
        label: 'Progress',
        icon: 'list',
        tab: 'Progress',
        desc: 'Manage the completion progress, client payments and vendor payments for this project.'
      },
      {
        label: 'Change orders',
        icon: 'timeline-arrow',
        tab: 'Changes',
        desc: 'Review a timeline of change-orders made to this estimate.'
      },
      {
        label: 'Schedule',
        icon: 'chart-gantt',
        tab: 'Schedule',
        desc: 'Manage the automated schedule for each part of the job, item by item for each subcontractor.'
      },
      ...(showInvoicing.value
        ? [
            {
              label: 'Invoices',
              icon: 'file-invoice',
              tab: 'Invoices',
              desc: 'Review all invoices for this project.'
            }
          ]
        : [])
    ]
  },
  {
    label: 'Project',
    expanded: true,
    items: [
      {
        label: 'Project files',
        tab: 'Files',
        icon: 'folder-open',
        desc: 'One folder to store all the project files, contracts, floorplans, drawings, pictures and other files.'
      },
      {
        label: 'Project settings',
        tab: 'Settings',
        icon: 'cog',
        desc: 'Change the address, and other settings related to this project.'
      }
      // {
      //   label: 'Project history',
      //   icon: 'history',
      //   tab: 'History',
      //   desc: 'Review a timeline of change-orders made to this estimate.'
      // }
    ]
  }
])

const onChannelClick = (channel) => {
  $store.commit({
    type: 'SET_PREVIEW',
    preview: {
      channel_type: channel.channel_type,
      channel_type_id: channel.channel_type_id
    }
  })
}

const channels = computed(() => {
  const channelId = `QUOTE-${quote_id.value}`
  const filteredChannels = Object.values($store.state.activityChat.channels)
    .filter((channel) => {
      return channel.channel_id === channelId || channel.parent_channel_id === channelId
    })
    .sort((a) => (a.parent_channel_id === 'NULL' ? 1 : -1))
  const allChannels = filteredChannels.reduce((acc, channel) => {
    if (channel) {
      if (channel.channel_type !== 'QUOTE_CLIENT') {
        acc[channel.channel_id] = channel
      } else {
        const existing = Object.values(acc).find(
          (tabChannel) => tabChannel.channel_type === 'QUOTE_CLIENT'
        )
        if (!existing) {
          acc[channel.channel_id] = channel
        } else {
          if (existing.created_at < channel.created_at) {
            acc[channel.channel_id] = channel
            delete acc[existing.channel_id]
          }
        }
      }
    }
    return acc
  }, {})

  return [
    {
      divider: true
    },
    {
      label: 'Messages',
      highlight: false,
      starred: true,
      icon: 'walkie-talkie',
      class: 'font-medium text-surface-800',
      containerClass: 'font-medium text-surface-800 !-ml-2'
    },
    ...Object.values(allChannels).reduce((acc, channel) => {
      const channelId = channel.channel_id
      const channelName = getFormattedChannelName(channel)

      // client channel //
      if (
        channel?.parent_channel_id !== 'NULL' &&
        $store.state.activityChat.channels[channel?.parent_channel_id]
      ) {
        acc.push({
          label: `${channel.client_fname} ${channel.client_lname}`,
          avatar: `${channel.client_fname} ${channel.client_lname}`,
          members: [
            {
              user_id: channel.client_user_id
            }
          ],
          badge: getTotalChannelNotificationsLength(channelId),
          command: () => onChannelClick(channel),
          class: 'text-sm font-normal text-surface-700 dark:text-white/80',
          containerClass: 'font-medium text-surface-800 -ml-1'
        })
      } else {
        acc.push({
          label: `${channelName} (internal)`,
          badge: getTotalChannelNotificationsLength(channelId),
          icon: 'walkie-talkie',
          command: () => onChannelClick(channel),
          class: 'text-sm font-normal text-surface-700 dark:text-white/80',
          containerClass: 'font-medium text-surface-800 -ml-1'
        })
      }

      return acc
    }, [])
  ]
})

const menuItems = computed(() => [...items.value, ...channels.value])
</script>

<template>
  <div class="flex flex-col max-h-full w-full overflow-hidden">
    <div
      class="md:hidden px-4 h-[60px] w-full m-0 flex flex-row justify-between items-center border-b border-cement-600 shrink-0 grow-0"
    >
      <span class="text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>
    <SidePanelMenu
      :model="menuItems"
      :allowStarred="false"
      class="overflow-y-auto grow shrink-[2]"
    />
    <QuoteSidepanel class="border-t border-cement-600 overflow-y-auto shrink-0 max-h-[60vh]" />
  </div>
</template>
