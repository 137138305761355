<script setup>
import { computed } from 'vue'
import FocusBar from '../layout/header/FocusBar.vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import StringField from '@/components/ui/Calculator/StringField.vue'
import QuoteSave from '@/components/headers/QuoteSave.vue'
import { useRouter } from 'vue-router'
import { useMediaQuery } from '@/composables/mediaQuery'
import eventBus from '@/eventBus'

const $router = useRouter()
const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const {
  quote_name: qname,
  client,
  client_id,
  quote_id,
  quote_status
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})

const { smallFormat } = useMediaQuery()

const isPublicQuote = computed(() => {
  return $router.currentRoute.value.name === 'Presentation External'
})

const emitToggleSidebar = () => {
  eventBus.$emit('toggle-presentation-sidebar', true)
}
</script>

<template>
  <FocusBar :hideSaveButtons="isPublicQuote" v-if="!(isPublicQuote && !smallFormat)">
    <template #middle v-if="quote_status">
      <div
        v-if="!isPublicQuote && !smallFormat"
        class="flex justify-center items-center gap-x-2 max-w-full w-full"
      >
        <PersonAvatar
          v-if="client"
          :key="client.user_fname"
          :id="client_id"
          type="client"
          channelType="QUOTE_CLIENT"
          :channelTypeId="`${quote_id}-${client.client_user_id}`"
          :name="`${client.user_fname || ''} ${client.user_lname || ''}`"
        />
        <!--        <font-awesome-icon icon="file-signature" />-->
        <StringField v-model="qname" placeholder="Name this estimate" class="truncate" />
      </div>
      <div v-else-if="isPublicQuote && smallFormat">In person approval</div>
    </template>
    <template #middle v-else>
      <Loader :loading="1" />
    </template>

    <template #saveStatus v-if="quote_status">
      <div class="flex justify-end items-center gap-8">
        <QuoteSave />
      </div>
    </template>
    <template #right v-if="quote_status">
      <Btn
        v-if="smallFormat"
        class="grow-0 shrink-0 !size-10"
        size="lg"
        link
        @click="emitToggleSidebar"
      >
        <font-awesome-icon icon="bars-sort" size="lg" />
      </Btn>
    </template>
  </FocusBar>
</template>

<style scoped lang="scss"></style>
