<template>
  <Card
    class="payment-method-card text-center group w-64"
    :button="enabled"
    :selected="selected === method.value"
  >
    <div
      :invoiceId="invoiceId"
      @click="selectMethod"
      class="flex flex-col items-center w-full h-full justify-between payment-method-card--wrapper"
    >
      <h2>{{ $t(method.text) }}</h2>
      <div :class="`payment-method-icon ${method.value}`" :style="bankIconStyle"></div>
      <div v-if="enabled">
        <div v-if="enabled && !method.message" class="payment-method-fee text-center">
          <div v-if="fee">
            <span>+ {{ $f.currency(fee) }}</span>
          </div>

          <div v-else>
            <span>{{ $t('No fee') }}</span>
          </div>
        </div>
        <div class="payment-method--message" v-if="method.message">
          <span>{{ method.message }}</span>
        </div>
      </div>
      <div v-else class="payment-method-message">
        <span>Unavailable</span>
      </div>
      <btn
        @click="selectMethod"
        :invoiceId="invoiceId"
        btnClass="btn info w-full"
        class="btn info w-full"
        severity="primary"
      >
        <span v-if="enabled">{{ $t('Select') }}</span>
        <span v-else>Currently unavailable</span>
      </btn>
    </div>
  </Card>
</template>

<script>
export default {
  name: 'PaymentMethodCard',
  emits: ['selectMethod'],
  data() {
    return {}
  },
  computed: {
    bankIconStyle() {
      return {
        backgroundImage: `url(${this.method.icon})`
      }
    }
  },
  props: {
    fee: {
      type: Number
    },
    method: {
      type: Object,
      required: true
    },
    invoiceId: {
      type: Number
    },
    enabled: {
      type: Boolean,
      default: true
    },
    selected: {
      type: String
    }
  },
  methods: {
    /**
     * Emit a payment method has been selected
     */
    selectMethod() {
      if (!this.enabled) return
      this.$emit('selectMethod', this.method)
    },
    /**
     * On exit set the payment method selected to empty
     */
    onExit() {
      this.$emit('selectMethod', { value: '' })
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.payment-method-icon {
  height: 100px;
  width: 150px;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
  &.bank-transfer {
    width: 120px;
  }
  &.manual {
    width: 100px;
  }
}
.payment-method-icon.card {
  box-shadow: none !important;
}
.payment-method--message {
  max-width: 130px;
  text-align: center;
}
.payment-method-card {
  min-height: 300px;
  .content,
  .card-text,
  .payment-method-card--wrapper,
  .payment-method-card--wrapper > span {
    min-height: 300px;
  }
  .payment-method-card--wrapper > span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .card-body {
    padding: 1em !important;
  }
}
</style>
