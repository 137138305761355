<template>
  <page title="Invoices">
    <grid
      :showDefaultFilterPresets="false"
      type="invoice"
      :multiple="true"
      :filterPresets="gridPresets"
      :permanentFilters="{
        quote_id: project.quote_id,
        client_id: project.client_id
      }"
      :filters="{
        invoice_status: '!x&&!i&&!d&&!h'
      }"
      :showActions="false"
      :createNew="false"
      :visible="['invoice_preview', 'quote_id', 'invoice_status']"
    />
  </page>
</template>

<script setup>
import { provide, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const project = computed(() => store.state.session.quote)
provide('project', project)
</script>
