<script setup>
import { ref, defineProps } from 'vue'

// Props definition
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  desc: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: null
  }
})

// Refs for state management
const hovering = ref(false)

// Methods to handle mouse events
const handleMouseover = () => {
  hovering.value = true
}

const handleMouseout = () => {
  hovering.value = false
}
</script>

<template>
  <div
    :class="[
      'flex flex-col justify-between gap-2 bg-surface-100 p-4 rounded-lg shrink-0 grow-0 h-[215px] w-full md:w-[300px] 2xl:w-[360px] select-none cursor-pointer transition-all',
      {
        'shadow-md': hovering
      }
    ]"
    @mouseover="handleMouseover"
    @mouseout="handleMouseout"
  >
    <div class="flex justify-between items-start">
      <slot name="title">
        <h3 class="flex justify-start items-center gap-2 !leading-none !text-xl">
          {{ props.title }}
        </h3>
      </slot>
      <div
        class="flex justify-center items-center p-1 rounded-lg transition-all w-12 h-12 shrink-0 grow-0 -mr-1 -mt-1"
        v-if="props.icon || $slots.icon"
        :class="{
          'bg-surface-200': hovering
        }"
      >
        <slot name="icon">
          <font-awesome-icon
            :icon="hovering ? 'arrow-up-right' : props.icon"
            size="2xl"
            fixed-width
          />
        </slot>
      </div>
    </div>

    <slot>
      <div class="leading-tight text-md text-surface-500 font-light">
        {{ props.desc }}
      </div>
    </slot>
  </div>
</template>
