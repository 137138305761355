<template>
  <Page>
    <!-- Warnings -->
    <danger
      class="xs error-card mt-8"
      :flat="true"
      :rounded="false"
      :hideTitle="true"
      :noBorder="true"
      v-if="requirements.counterpartyStatus === 's'"
    >
      <span
        >Your payment account has not been verified and therefore you cannot receive payments
        yet.</span
      >
    </danger>
    <warning
      class="xs error-card mt-8"
      :flat="true"
      :rounded="false"
      :hideTitle="true"
      :noBorder="true"
      v-else-if="requirements.counterpartyStatus === '#'"
    >
      <span
        >Payouts and transfers are disabled for this account until the account is verified. To
        receive payouts please make sure to
        <a @click="() => addExternalAccount()"><u>add a bank account</u></a
        >. Or you may have to upload a bank statement.</span
      >
    </warning>

    <CounterpartyDashboardHeader
      type="payments"
      :merchant="merchant"
      :counterparty="counterparty"
      :bodyLoading="bodyLoading"
      :autoPayouts="false"
      @withdraw-money="handleWithdrawMoney"
      @send-to-bank="handleSendToBank"
    />

    <PayfacDashboardSkeleton v-if="bodyLoading" />
    <TabView v-else :scrollable="true">
      <!-- Activities -->
      <TabPanel v-if="!bodyLoading">
        <template #header> Activities </template>
        <CounterpartyActivities
          ref="activities"
          :accounts="bankAccounts"
          :merchant="merchant"
          :owners="owners"
          :counterparty="counterparty"
          :balances="balances"
          :balancesLoading="balancesLoading"
          @balances-updated="handleBalancesUpdated"
        />
      </TabPanel>

      <!-- Payment Methods -->
      <TabPanel v-if="!bodyLoading">
        <template #header> Payment Methods </template>
        <PaymentMethods
          :merchant="merchant"
          :accounts="bankAccounts"
          type="payments"
          @accounts-updated="handleAccountsUpdated"
        />
      </TabPanel>

      <!-- Profile -->
      <TabPanel v-if="!bodyLoading">
        <template #header> Profile </template>
        <CounterpartyProfile :merchant="merchant" />
      </TabPanel>

      <!-- Owners -->
      <TabPanel v-if="!bodyLoading">
        <template #header> Owners </template>
        <CounterpartyOwnerList
          :existingOwners="owners"
          :merchant="merchant"
          :counterparty="counterparty"
        />
      </TabPanel>

      <!-- Required Actions -->
      <TabPanel v-if="!bodyLoading">
        <template #header>
          Required Actions
          <font-awesome-icon
            v-if="badgeRequirementCount"
            :icon="['fas', 'circle-exclamation']"
            class="text-deep-red-500 text-xs self-start ml-1"
          />
        </template>
        <CounterpartyRequirements
          :merchant="merchant"
          :owners="owners"
          :counterparty="counterparty"
          :requirements="requirements"
        />
      </TabPanel>

      <!-- Settings -->
      <TabPanel v-if="!bodyLoading">
        <template #header> Settings </template>
        <CounterpartySettings :merchant="merchant" :counterparty="counterparty" />
      </TabPanel>
    </TabView>
  </Page>
</template>

<script>
import CounterpartyDashboardHeader from './CounterpartyDashboardHeader.vue'
import CounterpartyActivities from './CounterpartyActivities.vue'
import CounterpartyProfile from './CounterpartyProfile.vue'
import CounterpartyRequirements from './CounterpartyRequirements.vue'
import CounterpartyMixin from '../CounterpartyMixin'
import CounterpartySettings from './CounterpartySettings.vue'
import CounterpartyOwnerList from './CounterpartyOwnerList.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import PaymentMethods from './PaymentMethods.vue'
import PayfacMixin from '../../PayfacMixin'
import PayfacDashboardSkeleton from './PayfacDashboardSkeleton.vue'

export default {
  name: 'CounterpartyDashboard',
  data() {
    return {
      tab: ['Activity'],
      bodyLoading: false,
      owners: [],
      counterparty: {},
      merchant: {},
      bankAccounts: {},
      requirements: {},
      ownerErrors: [],
      balances: [],
      balancesLoading: true
    }
  },
  /**
   * Init loading the counterparty details
   */
  async created() {
    this.bodyLoading = true
    // first fetch the full payee
    const { payload } = await this.fetchPayee()
    // includes sub merchant data (external details), counterparty (our entity) and bank accounts
    const { merchant, counterparty, bankAccounts, owners } = payload
    this.merchant = merchant
    this.counterparty = counterparty
    this.bankAccounts = bankAccounts
    this.owners = owners
    await this.setRequirements()

    // Load balances
    try {
      const { payload: balances } = await this.fetchBalances()
      this.balances = balances
      this.balancesLoading = false
    } catch (e) {
      console.error('Error loading balances:', e)
    }

    this.bodyLoading = false
    this.ownerErrors = this.owners.reduce((acc, owner) => [...acc, ...owner.errors], [])
  },
  computed: {
    /**
     * Tally the requirements to display as badge count
     */
    badgeRequirementCount() {
      return this.requirements &&
        (this.requirements.paymentRequirements.length > 0 ||
          this.requirements.payoutRequirements.length > 0)
        ? [...this.requirements.paymentRequirements, ...this.requirements.payoutRequirements].length
        : null
    }
  },
  mixins: [CounterpartyMixin, PayfacMixin],
  methods: {
    async setRequirements() {
      // Now fetch and see if there are any requirements to complete merchant KYC
      const { payload: requirements } = await this.payeeRequirements()
      this.requirements = requirements
    },
    /**
     * Handle balances updates from child component
     */
    handleBalancesUpdated(updatedBalances) {
      this.balances = updatedBalances
    },
    /**
     * Handle Withdraw Money button click
     */
    handleWithdrawMoney() {
      this.$refs.activities?.openPayoutModal()
    },
    /**
     * Handle Send to Bank button click
     */
    handleSendToBank() {
      this.$refs.activities?.openThirdPartyTransferModal()
    },
    /**
     * Get the class to display for the merchant status badge
     */
    getStatusClass(status) {
      let className = 'bg-dark'
      switch (status.toLowerCase()) {
        case 'active':
          className = '!bg-matcha-500'
          break
        case 'restricted':
          className = '!bg-deep-red-500'
          break
        case 'charges enabled':
          className = '!bg-yellow-700'
          break
        case 'pending payment':
          className = '!bg-yellow-700'
          break
        default:
          className = '!bg-surface-500'
      }
      return className
    }
  },
  components: {
    CounterpartyActivities,
    CounterpartyProfile,
    CounterpartyRequirements,
    CounterpartySettings,
    CounterpartyOwnerList,
    PayfacDashboardSkeleton,
    TabView,
    TabPanel,
    PaymentMethods,
    CounterpartyDashboardHeader
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-container.full-width {
  .cc-container.container-element {
    max-width: 100% !important;
  }
}

.error-card {
  min-height: 64px;
}
</style>
