import { toValue } from 'vue'
import Dimensions from '@/components/composables/Dimensions.js'

export default {
  useCostItemEquationVariables(args) {
    const { norm } = args

    const { possibleDimensions, getConvertedDimensions, getMeasureForUnitOfMeasure } =
      Dimensions.useDimensions()

    const getCalculatorVariablesByRefId = (refId, measure = 'ft', allDimensions = true) => {
      const nn = norm.value
      const poss = toValue(possibleDimensions)

      const obj = nn[refId]
      if (!obj) return {}
      const parentId = /quote|assembly/.test(obj.type) ? obj.refId : obj.parentRefId
      const dimensions = nn[parentId]?.oDimensions ?? {}

      // first, convert provided dimensions into a common base measure,
      // based on the measure provided, ft/m/mm etc

      let all = {}

      let convertedDimensions = dimensions
      if (getMeasureForUnitOfMeasure(measure) !== 'count') {
        convertedDimensions = getConvertedDimensions(dimensions, measure, allDimensions)
      }

      all = {
        ...(obj.oSiblingsVariables ?? {}),
        ...convertedDimensions
      }

      Object.keys(all).forEach((dim) => {
        if (typeof all[dim] !== 'object') {
          all[dim] = {
            abbr: String(dim).startsWith('project') ? dim : 'parent_' + dim,
            value: all[dim],
            dimension_icon: 'hashtag'
          }
        }
      })

      // sort by qty
      const sorted = Object.keys(all)
      sorted.sort((a, b) => {
        const aa = all[a]
        const bb = all[b]

        if (aa.measure === measure && bb.measure !== measure) return -1
        if (aa.measure !== measure && bb.measure === measure) return 1

        return bb.value - aa.value
      })

      return sorted.reduce(
        (acc, abbr) => ({
          ...acc,
          [abbr]: {
            ...(poss[abbr] ?? {}),
            ...all[abbr],
            color: poss[abbr]?.color || '0b4cff'
          }
        }),
        {}
      )
    }

    return {
      getCalculatorVariablesByRefId,
      possibleDimensions
    }
  }
}
