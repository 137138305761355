<template>
  <div class="review-all select-none">
    <div class="review-all--header">
      <div class="review-all-title">
        {{ $t('Category') }} /
        {{ $t('Product') }}
      </div>
      <div class="review-all--left text-left">
        <div class="review-all-title text-right">
          {{ $t('Price') }}
        </div>
      </div>
    </div>
    <ReviewAllItem
      v-for="ref in normalized[parentRefId].aoChildren"
      :key="ref"
      :object="normalized[ref]"
      :parentRefId="parentRefId"
      :normalized="normalized"
      :presentationSettings="presentationSettings"
      :refId="ref"
    />

    <div v-if="!isPriceSummary" class="total-sums">
      <div
        class="total-net total-sum"
        v-if="quote.quote_profit_net >= 0.01 && presentationSettings.showBundledProfit"
      >
        <span
          >{{
            l(
              typeof presentationSettings.showBundledProfit === 'string'
                ? presentationSettings.showBundledProfit
                : $t('Management fee')
            )
          }}:</span
        >
        <span class=" ">{{
          $f.currency(
            quote.quote_price_net - quote.quote_total_cost_net + (quote.quote_discount_net || 0)
          )
        }}</span>
      </div>
      <div class="total-net total-sum" v-if="quote.quote_discount_net > 0.0">
        <span>{{ l('Discount') }}:</span>
        <span class="discount">{{ $f.currency(quote.quote_discount_net) }}</span>
      </div>
      <div class="total-net total-sum" v-if="quote.quote_price_tax > 0">
        <span>{{ l('Subtotal') }}:</span>
        <span class="">{{ $f.currency(quote.quote_price_net) }}</span>
      </div>
      <div v-for="tax in visibleTaxSums" :key="tax.key" class="total-tax total-sum">
        <span class="tax-name">
          {{ tax.name }}:
          <small>
            {{
              $t('tax on {taxOn} derived from {taxType}', {
                taxOn: friendlyNameTaxOn(tax.on),
                taxType: friendlyNameTaxType(tax.type)
              })
            }}
          </small>
        </span>
        <span style="white-space: nowrap">
          {{ $f.currency(tax.sum) }}
        </span>
      </div>
      <div class="total-gross total-sum !px-0">
        <span>{{ l(paymentTerms === 'loan' ? 'Loan payment' : 'Project total') }}:</span>
        <span>
          <span class="flex flex-column items-end" v-if="paymentTerms === 'loan'">
            {{ $f.currency(monthlyPayment) }}/{{ $t('month') }}
            <small
              class="cancel-loan text-muted mt-1"
              @click="eventBus.$emit('updatePaymentTerms', 'pay')"
            >
              <u>{{ $t('Cancel loan') }}</u>
            </small>
          </span>
          <span v-else>{{ $f.currency(quote.quote_price_gross, 2) }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewAllItem from './ReviewAllItem.vue'
import TranslationMixin from '../presentation/languages/TranslationMixin'
import LoanMixin from '../../mixins/LoanMixin'
import eventBus from '../../../eventBus'

export default {
  name: 'ReviewAll',
  mixins: [TranslationMixin, LoanMixin],
  data() {
    return {
      amount: 0
    }
  },
  watch: {},
  computed: {
    eventBus() {
      return eventBus
    },
    quote() {
      return this.normalized[this.parentRefId]
    },
    visibleTaxSums() {
      return Object.values(this.quote.oTaxSums).filter(
        (tax) => Math.abs(tax.pcnt) >= 0.01 && Math.abs(tax.sum) >= 0.01
      )
    }
  },
  created() {
    this.amount = this.quote.quote_price_gross
  },
  methods: {
    friendlyNameTaxOn(taxOn) {
      if (taxOn === 'all') return this.l('prices')
      if (taxOn === 'cost') return this.l('costs')
      if (taxOn === 'profit') return this.l('profits')

      return taxOn
    },
    friendlyNameTaxType(taxType) {
      if (taxType === 'ihlt') return this.l('labor')
      if (taxType === 'mt') return this.l('materials')
      if (taxType === 'slt') return this.l('subcontracting')

      return this.$t('general')
    }
  },
  components: {
    ReviewAllItem
  },
  props: {
    normalized: {
      type: Object
    },
    presentationSettings: {
      type: Object
    },
    parentRefId: {
      type: String,
      required: true
    },
    paymentTerms: {
      type: String,
      default: 'pay'
    },
    isPriceSummary: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.review-all {
  .cancel-loan {
    cursor: pointer;
    &:hover {
      color: $blue-print-700;
    }
  }
}
.review-all--header {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0.5em;
  border-bottom: solid 1px $cool-gray-400;
  font-weight: 500;
  > div:first-of-type {
    max-width: 342px;
  }
  > div {
    flex: 1;
  }
}

.total-sums {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  margin-left: auto;

  .total-sum {
    padding: 0.5em;
    display: flex;
    justify-content: space-between;
    // border-bottom: solid 1px $cool-gray-400;
    > span:nth-of-type(2) {
      display: flex;
      align-items: flex-end;
    }
  }

  .total-tax {
    .tax-name {
      display: flex;
      flex-direction: column;
    }
  }

  .total-gross {
    font-size: 1.3em;
    font-weight: 500;
  }
}
</style>
