<script setup>
import Sheets from '@/components/Sheets/Sheets.vue'
import { computed, defineProps, ref, onMounted, watch, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router'
import NormalizeUtilities from '../../../../../imports/api/NormalizeUtilities.js'
import Auditing from '../../../../../imports/api/Auditing/index.js'
import Dimensions from '@/components/composables/Dimensions.js'
import FieldSetters from '@/components/composables/EntityFields/FieldSetters.js'
import eventBus from '@/eventBus.js'

const refSheet = ref(null)
const reloadKey = ref(0)

const props = defineProps({
  type: { type: String, required: true },
  // @todo remove showCategories and splitter from this compeonnt and put it in ItemList instead
  showCategories: { type: Boolean, default: false },
  fetchOnMount: { type: Boolean, required: true, default: true },
  title: { type: String, required: false, default: 'List' },
  icon: { type: String, default: 'table' },
  filters: { type: Object, default: () => ({}) },
  fetchMethod: { type: String, default: 'search' }, // search | filter
  rowProcessor: { type: Function, default: (row) => row },
  searchPhrase: { type: String, default: '' },
  columns: { type: Array, required: true },
  freeze: { type: Number, default: 0 },
  adhocRows: { type: Array, default: () => [] },
  superHeaders: { type: Array, default: () => [] },
  limit: { type: Number, default: 100 },
  sort: { type: Array, default: () => [] },
  saveSimultaneously: { type: Boolean, default: false },
  session: {
    type: Object,
    required: true,
    default: () => ({ save: true, key: 'Build16', saveCollapseGroups: true })
  },
  collapseGroups: { type: Object, default: () => ({}) },
  // Drop non listed fields to save memory on large lists
  restrictFields: { type: Array, default: null },
  audit: {
    type: Object,
    default: () => ({
      delay: 200,
      shouldAudit: (/* entity */) => true
    })
  },
  create: { type: Function, default: null },
  update: { type: Function, default: null },
  move: { type: Function, default: null },
  fetchRows: { type: Function, default: null },
  selectedRowOptions: {
    type: Array,
    default: () => [
      {
        name: 'Delete',
        icon: 'trash',
        action: (rows, { sheet }) => {
          sheet.deleteRows(rows)
          sheet.selectedRows.value = []
          sheet.gripRow.value = null
        },
        multiple: true,
        single: true
      },
      {
        name: 'Duplicate',
        icon: 'copy',
        action: (rows, { sheet }) => {
          sheet.duplicateRows(rows)
        },
        multiple: false,
        single: true
      }
    ]
  }
})
const emit = defineEmits([
  'fetched',
  'isDirty',
  'create',
  'delete',
  'update',
  'changes',
  'change',
  'removedRows',
  'scroll',
  'expandCollapseGroup'
])

if (
  !props.columns.find((col) => col.field === 'type') ||
  !props.columns.find((col) => col.idField)
) {
  throw new Error(
    `There must be a column with field: 'type', and another one with idField: true that maps to the entities id field`
  )
}

const $store = useStore()
const { possibleDimensions } = Dimensions.useDimensions()

const rows = ref([])
const items = ref({})

const rowIndexesById = computed(() =>
  rows.value.reduce(
    (acc, row, index) => ({
      ...acc,
      [idGetter(row)]: index
    }),
    {}
  )
)

const getDefaultFormatting = (col) => {
  if (/name/.test(col.field)) {
    return {
      width: 250,
      align: 'left'
    }
  }

  return {}
}

const explicitChanges = ref({})
const fullChanges = ref({})
const equationChanges = ref({})

// Changes to bundle before audit
const unauditedChanges = ref({})
// Changes to bundle before sending back into the Sheet component to redraw
const undrawnChanges = ref({})

const getSheetRows = () => {
  // must mix in any changes into the rows, if the table
  // is reloading for whatever reason

  return rows.value.map((row) => {
    const id = idGetter(row)
    if (id in fullChanges.value) {
      const mappedChanges = mapEntityToRow(
        {
          ...(fullChanges.value[id] ?? {}),
          type: row.type,
          [idFieldGetter.value(row)]: id
        },
        true
      )

      row = {
        ...row,
        ...mappedChanges
      }
    }

    return row
  })
}

const auditChanges = () => {
  const ids = Object.keys(unauditedChanges.value)
  if (!ids.length) return

  let fullSet = NormalizeUtilities.mergeChanges(fullChanges.value, unauditedChanges.value)

  let auditedChanges = []
  for (let id of ids) {
    if (!props.audit.shouldAudit(items.value[id])) continue

    const [, auditChanges] = Auditing.cascadeDependencies(
      {
        [id]: {
          ...items.value[id],
          ...fullSet[id]
        }
      },
      id,
      {},
      possibleDimensions.value
    )
    auditedChanges.push(auditChanges)
  }

  const allAuditChanges = NormalizeUtilities.mergeChanges(...auditedChanges)
  undrawnChanges.value = NormalizeUtilities.mergeChanges(unauditedChanges.value, allAuditChanges)
  fullChanges.value = NormalizeUtilities.mergeChanges(fullChanges.value, undrawnChanges.value)

  // flush
  unauditedChanges.value = {}

  emit('change', {
    cellChange: {},
    fullChanges: Object.keys(allAuditChanges).reduce(
      (acc, id) => ({
        ...acc,
        [id]: {
          ...allAuditChanges[id],
          // Make sure the basiscs are there too, type, entityId and sheet id if they are different
          [`${items.value[id].type}_id`]: items.value[id][`${items.value[id].type}_id`] ?? null,
          [idFieldGetter.value(items.value[id])]: id,
          type: items.value[id].type
        }
      }),
      {}
    ),
    explicitChanges: {},
    items: Object.keys(allAuditChanges).map((id) => items.value[id])
  })
}
watch(unauditedChanges, () => c.throttle(auditChanges, { delay: props.audit.delay }))

let returnAuditedChanges = () => {}

let handleCellChange = () => {}

const getDefaultColumn = (col, colIndex) => {
  const fieldName = col.field ?? col.mapField?.toString() ?? ''

  let colDef = {
    formatting: getDefaultFormatting(col),
    title:
      col.title || c.ucfirst((col.field ?? '').replace(props.type, '').replace('_', ' ').trim()),
    ...col
  }

  if (col.mapTo ?? col.choose) {
    colDef = {
      ...col,
      choose: {
        schema: `${col.mapTo}:${col.field}`,
        allowCreate: true,
        ...col.choose
      },
      formatting: {
        width: 100,
        align: 'left',
        ...col.formatting
      }
    }
  } else if (/_hours/.test(fieldName)) {
    colDef = {
      ...col,
      formatting: {
        width: 120,
        format: 'hours',
        align: 'center',
        ...col.formatting
      }
    }
  } else if (/_qty/.test(fieldName)) {
    colDef = {
      ...col,
      formatting: {
        width: 80,
        format: 'number',
        align: 'right',
        ...col.formatting
      }
    }
  } else if (/percent/.test(fieldName)) {
    colDef = {
      ...col,
      formatting: {
        width: 100,
        format: 'percentWhole',
        align: 'right',
        ...col.formatting
      }
    }
  } else if (/markup|_net|_gross|_tax$/.test(fieldName)) {
    colDef = {
      ...col,
      formatting: {
        width: 100,
        format: 'currency',
        align: 'right',
        ...col.formatting
      }
    }
  } else if (/_is_|_has_/.test(fieldName)) {
    colDef = {
      formatting: {
        width: 40
      },
      ...col,
      checkbox: {
        unchecked: {
          icon: col.icon ?? 'square'
        },
        checked: {
          color: col.color ?? null,
          background: col.background ?? col.color ?? null,
          icon: col.icon ?? 'square-check'
        }
      }
    }
  } else if (/file_ids?/.test(fieldName)) {
    colDef = {
      title: col.title || 'Files',
      field: col.field,
      formatting: {
        width: 40
      },
      attachments: {
        props: () => ({
          idList: true,
          dropzone: 'row'
        })
      }
    }
  } else if (/desc|notes/.test(`${fieldName}-${col.title ?? ''}`)) {
    colDef = {
      title: col.title || 'Desc',
      field: col.field,
      ...col,
      formatting: {
        wordWrap: true,
        width: 40,
        align: 'left',
        verticalAlign: 'top',
        preventDefaultDraw: true,
        draw: ({ ctx, clipTo, text: desc, drawIcon, blueTrans, drawText }) => {
          if (!desc) {
            return ctx
          }

          drawIcon(ctx, clipTo, 'input-text', blueTrans)

          drawText(ctx, [41, 0, 400, 40], desc, {
            fontSize: 14,
            align: 'left',
            verticalAlign: 'top'
          })
        },
        ...(col.formatting ?? {})
      }
    }
  }

  return {
    ...colDef,
    field: col.field ?? `adhoc-${colIndex}`,
    disabled: ({ value, rowData, cell }) => {
      const disabled = (col.disabled ?? (() => false))(rowData, value, cell)

      return disabled
    },
    readOnly: ({ value, rowData, cell }) => {
      const readonly = (col.readOnly ?? (() => false))(rowData, value, cell)

      return readonly
    },
    conditionalFormatting:
      colDef.conditionalFormatting ??
      ((value, cell, rowData) => {
        const readonly = (col.readOnly ?? (() => false))(rowData, value, cell)

        if (readonly) {
          return {
            preset: 'readonly'
          }
        }

        const disabled = (col.disabled ?? (() => false))(rowData, value, cell)

        if (disabled) {
          return {
            preset: 'disabled'
          }
        }
      }),
    onChange: handleCellChange
  }
}

const columns = computed(() =>
  props.columns
    // if hidden, map but don't make a column
    .filter((col) => !col.hidden)
    .map((col, index) => getDefaultColumn(col, index))
)
const hiddenColumns = computed(() =>
  props.columns
    // if hidden, map but don't make a column
    .filter((col) => col.hidden)
    .map((col, index) => getDefaultColumn(col, index + columns.value.length))
)
const combinedColumns = computed(() => [...columns.value, ...hiddenColumns.value])

const sheets = computed(() => {
  return [
    {
      title: props.title,
      icon: props.icon,
      rows: getSheetRows(),
      columns: [...columns.value],
      collapseGroups: props.collapseGroups,
      superHeaders: props.superHeaders
    }
  ]
})

const idColumn = computed(() => combinedColumns.value.find((col) => col.idField))
const idFieldGetter = computed(() => idColumn.value.mapField ?? (() => idColumn.value.field))
const idGetter = (obj) => obj[idFieldGetter.value(obj)] ?? obj[idColumn.value.field]

const mapEntityToRow = (entity, onlyProvided = false) => {
  return {
    id: idGetter(entity),
    ...combinedColumns.value.reduce((acc, col) => {
      const mappedField = col.mapField ? col.mapField(entity) : col.field
      const provided = mappedField in entity

      if (!provided && onlyProvided) return acc

      const val = mappedField in entity ? (col.get ?? ((v) => v))(entity[mappedField]) : null

      return {
        ...acc,
        [col.field]: val
      }
    }, {})
  }
}

const mapRowToEntity = (rowData) => {
  const entity = {}
  for (let field in rowData) {
    const colDef = combinedColumns.value.find((col) => col.field === field)

    if (!colDef) continue

    const mappedField = colDef.mapField ? colDef.mapField(rowData) : colDef.field
    const getter = colDef.set ?? ((v) => v)
    const val = getter(rowData[field])
    entity[mappedField] = val
  }

  entity.id = idGetter(entity)

  return entity
}

const importRows = async (
  set,
  replace = true,
  pos = 0,
  addToSheet = false,
  placeholderRow = null,
  parentId = null
) => {
  const position = pos === -1 ? rows.value.length : pos

  let processed = set.map(props.rowProcessor)
  items.value = {
    ...(!replace ? items.value : {}),
    ...processed.reduce((acc, row) => {
      const id = idGetter(row)
      acc[id] = row
      return acc
    }, {})
  }

  const mapped = processed.map(mapEntityToRow, false)

  if (replace) rows.value = mapped
  else rows.value.splice(position, 0, ...mapped)

  if (addToSheet && refSheet.value) await addRowsToSheet(mapped, position, placeholderRow, parentId)
}

const store = c.titleCase(props.type)
const fetched = ref(false)
const offset = ref(0)
const lastFetchedOffset = ref(null)

let awaiting = null
const fetchRows = async (addToSheet = false, force = false) => {
  // If was already fetched, skip
  if (!force && lastFetchedOffset.value === offset.value) return
  lastFetchedOffset.value = offset.value

  if (awaiting) await awaiting

  const run = async () => {
    refSheet.value?.setLoadingRow(refSheet.value?.rowsMap.length - 1)
    let set = []
    if (props.fetchRows) {
      set = await props.fetchRows({
        filters: props.filters,
        searchPhrase: props.searchPhrase,
        limit: props.limit,
        offset: offset.value,
        order: props.sort
      })
    } else {
      const res = await $store.dispatch(`${store}/${props.fetchMethod}`, {
        filters: props.filters,
        searchPhrase: props.searchPhrase,
        limit: props.limit,
        offset: offset.value,
        order: props.sort
      })
      set = res.set
    }
    emit('fetched', set)
    await importRows(set, false, -1, addToSheet)
    refSheet.value?.setLoadingRow(null)
    offset.value += set.length
    fetched.value = true
  }

  awaiting = await run()

  return awaiting
}

const createFirst = async () => {
  refSheet.value?.addRow(-1, true)
  // await props.create()
  // await fetchRows()
}

onMounted(() => {
  if (props.fetchOnMount) fetchRows()
})

// Easy to use from outside to force in changes that were derived elsewhere etc
// this will not trigger a re-audit
// Changes must be normalized with the id as the refId
const setItemFields = (changes) => {
  explicitChanges.value = NormalizeUtilities.mergeChanges(explicitChanges.value, changes)
  fullChanges.value = NormalizeUtilities.mergeChanges(fullChanges.value, changes)
  undrawnChanges.value = NormalizeUtilities.mergeChanges(undrawnChanges.value, changes)
}

handleCellChange = async (payload) => {
  const {
    field,
    id,
    current: { raw, eq = raw }
  } = payload

  const colDef = combinedColumns.value.find((col) => col.field === field)

  if (!colDef) return

  const mappedField = colDef.mapField ? colDef.mapField(items.value[id]) : colDef.field
  const getter = colDef.set ?? ((v) => v)
  const val = getter(raw)

  const type = items.value[id].type
  const entityIdField = `${type}_id`
  const normed = {
    [id]: {
      type,
      // If the entity_id is not the same as the id field provided, for example
      // if there aer unsaved entities in the mix without an official _id yet, then
      // us whatever idField was provided. These two rows could output the same
      // if the idfield == =entityfield and this item is saved etc.
      [entityIdField]: items.value[id][entityIdField] ?? null,
      [idFieldGetter.value(items.value[id])]: id
    }
  }

  const context = {
    $store,
    norm: normed,
    store: c.titleCase(type)
  }
  let fieldSetChanges = {}
  let fieldSetExplicitChanges = {}
  // Get field setter if found, and add those changes to unauditedChanges, and full changes
  if (FieldSetters[type][mappedField]) {
    ;({ changes: fieldSetChanges, explicitChanges: fieldSetExplicitChanges } = await FieldSetters[
      type
    ][mappedField]({
      ...context,
      norm: normed,
      object: {
        ...items.value[id],
        ...(fullChanges.value[id] ?? {}),
        ...normed[id],
        type
      },
      value: val,
      equation: eq,
      equations: {
        ...(items.value[id].oEquations ?? {}),
        ...(fullChanges.value[id]?.oEquations ?? {}),
        ...(normed[id]?.oEquations ?? {})
      },
      skipAudit: true
    }))
    fieldSetChanges = { [id]: fieldSetChanges }
    fieldSetExplicitChanges = { [id]: fieldSetExplicitChanges }
  } else {
    // Set the field itself for when there is no field setter
    normed[id][mappedField] = val
    fieldSetExplicitChanges = { ...normed }
  }

  // Handle all direct changes. There are no computed cells in this case, so all changes are explicit
  // when they originate from the Sheets component
  explicitChanges.value = NormalizeUtilities.mergeChanges(
    explicitChanges.value,
    normed,
    fieldSetExplicitChanges
  )

  const currentChanges = NormalizeUtilities.mergeChanges(normed, fieldSetChanges)
  // Also add to full changes
  fullChanges.value = NormalizeUtilities.mergeChanges(fullChanges.value, currentChanges)

  // This differs from the explicit changes, because this one gets flushed
  // everytime there is an audit that happens
  unauditedChanges.value = NormalizeUtilities.mergeChanges(unauditedChanges.value, currentChanges)

  if (c.isEquation(eq)) {
    equationChanges.value = NormalizeUtilities.mergeChanges(equationChanges.value, {
      [id]: {
        [mappedField]: eq
      }
    })
  } else if (equationChanges.value[id]?.[mappedField]) {
    equationChanges.value = NormalizeUtilities.mergeChanges(equationChanges.value, {
      [id]: {
        [mappedField]: null
      }
    })
  }

  emit('change', {
    cellChange: payload,
    fullChanges: currentChanges,
    explicitChanges: fieldSetExplicitChanges,
    items: Object.keys(normed).map((id) => items.value[id])
  })
}

let listen = true

const setSheetRowValues = (rowFormatNormChangesById = {}) => {
  listen = false
  refSheet.value.setFieldValues(rowFormatNormChangesById)
  // flush
  listen = true
}

const setSheetItemFields = (itemFormatNormChanges = fullChanges.value) => {
  const ch = _.imm(itemFormatNormChanges)
  if (!Object.keys(ch)) return

  const ids = Object.keys(ch)

  const processedChanges = {}

  for (let id of ids) {
    processedChanges[id] = {}
    ch[id].type = items.value[id].type // type required
    const idField = `${items.value[id].type}_id`
    ch[id][idField] = id // type required
    ch[id].id = id // type required

    processedChanges[id] = mapEntityToRow(ch[id], true)
  }

  setSheetRowValues(processedChanges)
}

returnAuditedChanges = () => {
  const ch = _.imm(undrawnChanges.value)
  undrawnChanges.value = {}
  return setSheetItemFields(ch)
}
watch(undrawnChanges, (uc) => {
  if (!Object.keys(uc) || !listen) return
  c.throttle(() => returnAuditedChanges(), { delay: 200 })
})

const isDirty = computed(() => Object.keys(fullChanges.value).length)
watch(isDirty, (is) => emit('isDirty', is))
watch(fullChanges, () => emit('changes', fullChanges.value))

const getBackupKey = () =>
  `el-bkp-${props.type}-${JSON.stringify(props.filters ?? {}).replace(/_/g, '-')}`
const saveBackup = () => {
  const hasChanges = isDirty.value

  if (!hasChanges) return

  const key = getBackupKey()
  const bundle = {
    fullChanges: fullChanges.value,
    explicitChanges: explicitChanges.value,
    time: Date.now(),
    key
  }

  eventBus.$emit(`entitylist-saving-backup-${key}`)
  c.setCacheItem(key, bundle, 'bkup', $store.state.session, Date.now() + 60 * 1000 * 60 * 24 * 7)
  eventBus.$emit(`entitylist-saved-backup-${key}`)
}

const flushBackup = () => {
  const key = getBackupKey()
  eventBus.$emit(`entitylist-flushing-backup-${key}`)
  c.removeCacheItem(key, 'bkup', $store.state.session)
  eventBus.$emit(`entitylist-flushed-backup-${key}`)
}

// retain changes
watch([fullChanges, explicitChanges], () => {
  c.throttle(() => saveBackup())
})

const restoreBackup = async (bkp) => {
  eventBus.$emit(`entitylist-restoring-${bkp.key}`)

  explicitChanges.value = NormalizeUtilities.mergeChanges(
    explicitChanges.value,
    bkp.explicitChanges ?? {}
  )
  fullChanges.value = NormalizeUtilities.mergeChanges(fullChanges.value, bkp.fullChanges ?? {})
  undrawnChanges.value = NormalizeUtilities.mergeChanges(undrawnChanges.value, bkp.fullChanges)

  emit('changes', bkp.fullChanges)
  emit('change', {
    cellChange: {},
    fullChanges: bkp.fullChanges,
    explicitChanges: {},
    items: Object.keys(bkp.fullChanges).map((id) => items.value[id])
  })

  eventBus.$emit(`entitylist-restored-${bkp.key}`)
}

const getBackup = () => c.getCacheItem(getBackupKey(), 'bkup', $store.state.session)

const checkBackups = async () => {
  const bkp = getBackup()

  if (bkp) {
    eventBus.$emit(`entitylist-checking-backup-${bkp.key}`)
    const result = await $store.dispatch('modal/asyncConfirm', {
      message: `A backup found for this list from ${c.format(bkp.time, 'datetime')}. Restore backup?`,
      yes: 'Load backup',
      no: 'Discard backup'
    })

    if (result === false) flushBackup()

    if (result === true) restoreBackup(bkp)
    eventBus.$emit(`entitylist-checked-backup-${bkp.key}`)
  }
}

watch(
  fetched,
  (is, was) => {
    if (is && !was) c.throttle(() => checkBackups())
  },
  { immediate: true }
)

// Register the `onBeforeRouteLeave` hook
onBeforeRouteLeave(async (to, from, next) => {
  if (!isDirty.value) return next()

  const result = await $store.dispatch('modal/asyncConfirm', {
    message: 'You have unsaved changes. Would you like to save or discard before going?',
    yes: 'Save and leave',
    no: 'Leave without saving',
    cancel: 'Stay'
  })

  if (result === 'close') {
    next(false) // Block navigation
  } else if (result === false) {
    next() // Allow navigation
  } else if (result === true) {
    await save()
    next() // Allow navigation
  }
})

const importIds = (savedSet, originalSet) => {
  listen = false
  const changesToEmit = []
  for (let i = 0; i < savedSet.length; i++) {
    const savedItem = savedSet[i]
    const originalItem = originalSet[i] // sjhould be same indexes from server

    const sheetId = idGetter(originalSet[i])

    const entityIdField = `${originalItem.type}_id`
    const newId = savedItem[entityIdField]

    if (newId !== originalItem[entityIdField]) {
      // update id
      items.value[sheetId][entityIdField] = newId // items list local

      const rowChanges = mapEntityToRow(
        {
          [entityIdField]: newId
        },
        true
      )

      const rowIndex = rowIndexesById.value[sheetId]
      rows.value[rowIndex] = {
        // rows local
        ...rows.value[rowIndex],
        ...rowChanges
      }

      // But also needs to be added to the sheet for display if it is a column etc
      const changeSet = { [sheetId]: rowChanges }
      changesToEmit.push(changeSet)
      setSheetItemFields(changeSet)
    }
  }

  // We don't wan tthis to go into our fullChanges object or trigger an isDiry (because we just saved)
  // so we want to silently change everyhthing locally, and emit changes up the chain so the changes
  // can be registered by any listening parents
  const merged = NormalizeUtilities.mergeChanges(...changesToEmit)
  emit('changes', merged)
  emit('change', {
    cellChange: {},
    fullChanges: merged,
    explicitChanges: {},
    items: Object.keys(merged).map((id) => items.value[id])
  })

  listen = true
}

const save = async (force = false, preSaveFilter = () => true, postSaveHook = null) => {
  if (!isDirty.value && !force) return { set: [] }

  const justInCase = _.imm({
    fullChanges: fullChanges.value,
    explicitChanges: explicitChanges.value,
    equationChanges: equationChanges.value
  })

  try {
    // if type and id are not included we need to add those
    let selected = [
      // items that are saved already
      ...Object.keys(fullChanges.value)
        .filter((key) => {
          const type = fullChanges.value[key].type ?? items.value[key]?.type
          return type && items.value[key]?.[`${type}_id`]
        })
        .map((key) => {
          const type = fullChanges.value[key]?.type ?? items.value[key]?.type
          return {
            ...fullChanges.value[key],
            type,
            [`${type}_id`]: items.value[key]?.[`${type}_id`]
          }
        }),
      // items that are new and not saved yet
      ...Object.values(items.value)
        .filter((item) => item.type && !item[`${item.type}_id`])
        .map((item) => ({
          ...item,
          ...fullChanges.value[idGetter(item)],
          type: item.type
        }))
    ]
    const waiter = $store.dispatch('CostType/partialUpdate', {
      // can do any store, because it will take the 'type' from the objects in selected, and route correctly
      selected: selected.filter(preSaveFilter),
      alert: false,
      simultaneous: props.saveSimultaneously
    })

    const savePayload = await waiter
    importIds(savePayload.set, selected)

    fullChanges.value = {}
    explicitChanges.value = {}
    flushBackup()

    if (postSaveHook) postSaveHook(selected)

    return savePayload
  } catch (e) {
    console.log(e)

    fullChanges.value = NormalizeUtilities.mergeChanges(justInCase.fullChanges, fullChanges.value)
    explicitChanges.value = NormalizeUtilities.mergeChanges(
      justInCase.fullChanges,
      explicitChanges.value
    )
    equationChanges.value = NormalizeUtilities.mergeChanges(
      justInCase.fullChanges,
      equationChanges.value
    )

    throw e
  }
}

const movedRowsHandler = async (payload) => {
  const { changes: sheetChanges, parentId } = payload
  await props.move?.(payload)

  // make sure all of these are now inside of parentId:
  const ids = sheetChanges[parentId].childrenIds
  const changes = ids.map((id) => ({
    field: 'parentId',
    id,
    current: { raw: parentId }
  }))

  changes.forEach((ch) => handleCellChange(ch))
}

const addedRowsHandler = async (payload) => {
  const { rowIds, parentId, addedFrom, rowIndexes } = payload
  if (!listen) return

  refSheet.value.deselect(false)

  const placeholderRow = +(rowIndexes[0] ?? 0)
  refSheet.value.rowLoading = placeholderRow

  if (props.create) {
    let af = refSheet.value.getRowData(addedFrom) ?? null
    af = af && { ...items.value[af.id], ...mapRowToEntity(af) }
    try {
      await props.create({
        ...payload,
        rowItems: rowIds.reduce(
          (acc, row) => ({
            ...acc,
            [row]: mapRowToEntity(refSheet.value.getRowData(row))
          }),
          {}
        ),
        parentId,
        placeholderRow,
        addedFrom: {
          rowIndex: addedFrom,
          item: af
        }
      })
    } catch (e) {
      // aborted, delete placeholder row
      listen = false
      refSheet.value.rowLoading = null
      refSheet.value.deleteRows([placeholderRow])
      listen = true
      // Handle error here
      throw e
    }
  }
  // Generate default item, fetch full item if duplicating
  // Check for onCreate first, call that, otherwise:
  // Save immediately
  // Rereference with entity id
  //

  refSheet.value.rowLoading = null
}

const duplicatedRowsHandler = async (payload) => {
  const { newRow: rowIndex, copiedRow: addedFrom, parentId } = payload
  if (!listen) return

  refSheet.value.deselect(false)

  const placeholderRow = +(rowIndex ?? 0)
  refSheet.value.rowLoading = placeholderRow

  if (props.create) {
    let af = refSheet.value.getRowData(addedFrom) ?? null
    af = af && { ...items.value[af.id], ...mapRowToEntity(af) }
    try {
      await props.create({
        duplicating: true,
        isParent: refSheet.value.isRowParent(addedFrom),
        rowItems: [rowIndex].reduce(
          (acc, row) => ({
            ...acc,
            [row]: { ...af, ...mapRowToEntity(refSheet.value.getRowData(row)) }
          }),
          {}
        ),
        parentId,
        addedFrom: {
          rowIndex: addedFrom,
          item: af
        }
      })
    } catch (e) {
      // aborted, delete placeholder row
      listen = false
      refSheet.value.rowLoading = null
      refSheet.value.deleteRows([placeholderRow])
      listen = true
      // Handle error here
      throw e
    }
  }
  // Generate default item, fetch full item if duplicating
  // Check for onCreate first, call that, otherwise:
  // Save immediately
  // Rereference with entity id
  //

  refSheet.value.rowLoading = null
}

const returnAddedRows = async (rowIndexes, position = 0, parentId = null, placeholder = null) => {
  listen = false
  const sh = refSheet.value

  const sibling = placeholder ?? (position - 1 < 0 ? 0 : position - 1)
  const siblingId = sh.rowsMap[sibling]?.id ?? null
  const sheetIndex = sh.getRowSheet(position < 1 ? 0 : position - 1) ?? 0
  const parent = parentId ?? sh.dataSheets[sheetIndex].collapseGroups?.rootId ?? 'null'
  let childPosition =
    (siblingId === parent || position === 0
      ? 0
      : sh.dataSheets[sheetIndex].collapseGroups?.groups?.[parent]?.childrenIds.indexOf(siblingId) +
        1) ?? null

  if (placeholder) sh.deleteRows([placeholder])

  let ds = [...sh.dataSheets]
  let rm = [...sh.rowsMap]
  let cd = { ...sh.cellData }

  let fakeOriginalRow = _.uniqueId('ro-')
  let currentRow = position

  const highlightRefs = []
  const cgs = []

  for (let rowIndex of rowIndexes) {
    const rowData = { ...rows.value[rowIndex] }
    const id = rowData.id
    const groupKey = null
    ;({
      dataSheets: ds,
      rowsMap: rm,
      cellData: cd
    } = sh.addNewRow(
      {
        dataSheets: ds,
        rowsMap: rm,
        cellData: cd
      },
      {
        rowIndex: currentRow,
        childPosition,
        sheetIndex,
        pointer: fakeOriginalRow,
        id,
        rowData: { ...(rows.value[rowIndex] ?? {}) },
        rowEquations: {},
        parentId: parent,
        groupKey,
        formatting: currentRow.formatting ?? {},
        calculateCells: true
      }
    ))
    childPosition++

    const isParent = props.collapseGroups?.isParent?.(rowData) ?? false
    if (isParent) {
      let id
      ;({
        dataSheets: ds,
        rowsMap: rm,
        id
      } = sh.createParentFromRow(
        { dataSheets: ds, rowsMap: rm },
        {
          rowIndex: currentRow
        }
      ))
      cgs.push(id)
    }

    currentRow += 1
    fakeOriginalRow += 1
  }

  ;({ dataSheets: ds } = sh.rebuildCollapseGroupsFromParentChild(
    {
      dataSheets: ds,
      rowsMap: rm
    },
    { sheetIndex: 0 }
  ))

  sh.setDataSheets(ds)
  sh.setRowsMap(rm)
  sh.setCellData(cd)

  cgs.map((id) => sh.collapseGroup(id))
  sh.parseCellData()

  highlightRefs.forEach((refId) => sh.tempHighlight(refId))
  await sh.triggerRedraw()
  listen = true
}

const addRowsToSheet = async (rows, pos = -1, placeholder = null, parentId = null) => {
  const position = pos === -1 || (!pos && pos !== 0) ? rows.length : pos

  listen = false

  const positions = Array(rows.length)
    .fill(position)
    .map((p, i) => i + p)

  await returnAddedRows(positions, position, parentId, placeholder)
  listen = true
}

const reloadItem = async (item, placeholderRow = null, pos = placeholderRow ?? -1) => {
  let position = pos === -1 || (!pos && pos !== 0) ? rows.value.length : pos

  await importRows([item], false, position)

  listen = false
  if (placeholderRow) {
    refSheet.value.deleteRows([placeholderRow])
  }

  const rowData = rows.value[position]

  await returnAddedRows([position], position, props.collapseGroups?.getParent?.(rowData) ?? null)
  listen = true
}

const removeItemsFromIds = (sheetIds) => {
  let newItems = {}
  const newRows = []

  for (let id in items.value) {
    if (!sheetIds.includes(id)) {
      newItems[id] = items.value[id]
      newRows.push(rows.value[rowIndexesById.value[id]])
    }
  }

  rows.value = newRows
  items.value = newItems
}

const removedRowsHandler = async (payload) => {
  if (!listen) return
  const { ids: sheetIds } = payload

  // map to type and ids
  const selected = sheetIds
    .map((id) => ({
      type: items.value[id].type,
      [`${items.value[id].type}_id`]: items.value[id][`${items.value[id].type}_id`],
      id: items.value[id][`${items.value[id].type}_id`]
    }))
    .filter(({ id }) => id)

  if (!selected.length) {
    removeItemsFromIds(sheetIds)
    emit('removedRows', payload)
    return
  } // if it is not saved items, then delete without issue

  const savedToLibrary = selected.filter((obj) => obj.cost_type_id || obj.assembly_id)
  if (
    savedToLibrary.length &&
    (await $store.dispatch('modal/asyncConfirm', {
      message: `Would you like to delete ${savedToLibrary.length} items from your library?`
    }))
  ) {
    $store.dispatch(`${c.titleCase(selected[0].type)}/delete`, {
      selected,
      confirm: false,
      alert: false
    })
  }

  removeItemsFromIds(sheetIds)
  emit('removedRows', payload)

  // reloadKey.value += 1
}

const expandCollapseGroupHandler = (payload) => {
  emit('expandCollapseGroup', payload)
}

const scrollHandler = (payload) => {
  if (!props.limit) return // no limit so no need to paginate

  const buffer = 30
  if (payload.rowCount - payload.bottomRow < buffer) {
    fetchRows(true)
  }

  c.throttle(
    () => {
      emit('scroll', {
        ...payload,
        offset: offset.value,
        lastFetchedOffset: lastFetchedOffset.value,
        bottomParent: refSheet.value.getRowParent(payload.bottomRow),
        bottomChildPosition: refSheet.value.dataSheets?.[0]?.collapseGroups?.groups?.[
          refSheet.value.getRowParent(payload.bottomRow)
        ]?.rows?.indexOf(payload.bottomRow),
        topParent: refSheet.value.getRowParent(payload.topRow)
      })
    },
    { delay: 100, debounce: true }
  )
}

const sheetKey = computed(
  () =>
    `${reloadKey.value}-${combinedColumns.value.map((col) => col.title).join(',')}-${props.columns.length}`
)

defineExpose({
  items,
  rows,
  isDirty,
  save,
  reloadItem,
  fullChanges,
  refSheet,
  setItemFields,
  importRows,
  flushBackup
})
</script>

<template>
  <div class="relative flex flex-row overflow-hidden">
    <Sheets
      v-if="fetched"
      v-show="rows.length"
      @addedRows="addedRowsHandler"
      @removedRows="removedRowsHandler"
      @movedRows="movedRowsHandler"
      @duplicatedRows="duplicatedRowsHandler"
      @scroll="scrollHandler"
      @expandCollapseGroup="expandCollapseGroupHandler"
      :key="sheetKey"
      ref="refSheet"
      :selectedRowOptions="props.selectedRowOptions"
      :sheets="sheets"
      :freeze="freeze"
      :session="props.session"
    >
      <template #overlay>
        <slot name="overlay"></slot>
      </template>
      <template #after>
        <slot name="after"></slot>
      </template>
      <template #sheetOption>
        <slot name="sheetOption"></slot>
      </template>
      <template #sheetOptionAfter>
        <slot name="sheetOptionAfter"></slot>
      </template>
    </Sheets>
    <div v-if="fetched && !rows.length" class="w-full flex justify-center items-center">
      <Automation :closable="false" class="max-w-[600px] flex flex-col gap-2">
        <div class="text-xl font-medium">No items in your catalog yet!</div>
        You can create, edit and manage your items and assemblies right here, in bulk. Create
        categories, and drag and drop to stay organized.

        <div>Create a new item to get started!</div>
        <Btn :action="() => createFirst()" severity="primary" size="xl">
          <font-awesome-icon icon="fa-solid fa-plus" />
          Create new item
        </Btn>
      </Automation>
    </div>
    <div
      class="p-4 flex gap-2 flex-col justify-start items-stretch w-full h-full"
      v-else-if="!fetched"
    >
      <Skeleton shape="rectangle" height="100" class="grow-1 min-h-10" />
      <Skeleton shape="rectangle" height="100" class="grow-1 min-h-10" />
      <Skeleton shape="rectangle" height="100" class="grow-1 min-h-10" />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
