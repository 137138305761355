<template>
  <div
    :style="
      !editable && !filteredList.length
        ? {
            padding: 0,
            margin: 0,
            height: 0,
            width: 0
          }
        : {}
    "
    :class="editable ? 'addon_list-editing' : ''"
  >
    <fade>
      <div v-if="(scrolledIn && filteredList.length) || editable">
        <!--        <div class="addon_list-title" v-if="title">-->
        <!--          <font-awesome-icon-->
        <!--            icon="chevron-double-up"-->
        <!--            class="text-info"-->
        <!--            style="font-size: 2em"-->
        <!--            fixed-width-->
        <!--          />-->
        <!--          <SuggestionButton v-if="title" class="info">-->
        <!--            <template v-if="totalChose > 0"-->
        <!--              >{{ totalChose }} {{ l('other customers recently upgraded their') }}-->
        <!--              {{ $f.truncate(title, 60) }}</template-->
        <!--            >-->
        <!--            <template v-else>{{ l('Other options for') }} {{ $f.truncate(title, 60) }}</template>-->
        <!--          </SuggestionButton>-->
        <!--        </div>-->
        <!--    Selected addon    -->
        <div class="flex justify-start items-end gap-4 px-2" v-if="orientation === 'horizontal'">
          <div
            v-if="!optional && includePrice < 0.01"
            :class="{
              'md:pr-8 md:mr-8 md:border-r border-surface-100': !isLocked
            }"
          >
            <div
              class="hidden md:flex items-center justify-center p-4 z-8 min-w-[200px] max-w-[200px] min-h-[280px] cursor-pointer !border-2 border-transparent rounded bg-surface-100"
            >
              <span
                class="text-center text-surface-500 font-light text-lg leading-tight flex justify-center items-center gap-1"
                >Make a selection <font-awesome-icon icon="arrow-right"
              /></span>
            </div>
          </div>

          <!--    Upgrade options container      -->
          <div class="relative flex-col overflow-auto" v-if="!isLocked">
            <span
              v-if="!optional || orientation === 'horizontal'"
              class="sticky left-0 mb-2 text-pitch-black text-xs font-light"
              :class="{
                'hidden md:flex': isAddonGroup,
                flex: !isAddonGroup,
                'px-2': orientation === 'horizontal'
              }"
            >
              {{ optionsNumber }} other {{ optionsNumber === 1 ? 'option' : 'options' }}
            </span>
            <span
              class="sticky left-0"
              :class="{ 'flex md:hidden mb-3': isAddonGroup, hidden: !isAddonGroup }"
            >
              Make a selection below
            </span>
            <div
              class="max-w-full flex items-center justify-start gap-4 md:gap-6 p-1"
              ref="scrollContainer"
            >
              <template v-for="(addon, index) in filteredList" :key="index">
                <quote-presentation-addon-item
                  :targetRefId="reference"
                  :currentPrice="currentPrice"
                  :editable="editable"
                  :addon="addon"
                  :dimensions="dimensions"
                  @number-chose="(chval) => countHandler(index, chval)"
                  @disabled="() => disableAddon(addon)"
                  @addon="(modified) => (index > 0 ? modifyAddon(addon, modified) : null)"
                  @get="(object) => get(addon)"
                  @selected-addon="setSelectedAddon"
                />

                <div v-if="editable" class="addon_list-item-options">
                  <btn rounded class="lg more px-0 p-2 danger" @click="() => removeAddon(addon)">
                    &times;
                  </btn>
                </div>
              </template>

              <div class="w-2 md:w-4 shrink-0">&nbsp;</div>

              <div class="addon_list-options" v-if="editable">
                <traverse
                  :embue="embueCreate"
                  :externalCreate="true"
                  v-if="upgrades && target.type !== 'quote'"
                  ref="upgradeTraverse"
                >
                  <template #button>
                    <btn :loading="loading" @click="addUpgradeItem" class="btn round secondary">
                      <template #icon>
                        <font-awesome-icon icon="plus" fixed-width />
                      </template>
                      Add upgrade item
                    </btn>
                  </template>
                </traverse>
                <traverse
                  :embue="embueCreate"
                  :externalCreate="true"
                  v-if="optionals && (target.type === 'assembly' || target.type === 'quote')"
                  ref="optionalTraverse"
                >
                  <template #button>
                    <btn :loading="loading" @click="addOptionalItem" class="btn round secondary">
                      <template #icon>
                        <font-awesome-icon icon="plus" fixed-width />
                      </template>
                      Add optional item
                    </btn>
                  </template>
                </traverse>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-stretch justify-start gap-4" v-else>
          <div v-if="!optional && includePrice < 0.01">
            <div
              class="hidden md:flex items-center justify-center p-4 z-8 cursor-pointer !border-2 border-transparent rounded bg-surface-100"
            >
              <span
                class="text-center text-surface-500 font-light text-lg leading-tight flex justify-center items-center gap-1"
                >Make a selection <font-awesome-icon icon="arrow-down"
              /></span>
            </div>
          </div>

          <!--    Upgrade options container      -->
          <div class="relative flex-col overflow-visible" v-if="!isLocked">
            <span
              v-if="!optional"
              class="sticky left-0 mb-1 text-pitch-black text-xs font-light"
              :class="{ 'hidden md:flex': isAddonGroup, flex: !isAddonGroup }"
            >
              {{ optionsNumber }} other {{ optionsNumber === 1 ? 'option' : 'options' }}
            </span>
            <span
              class="sticky left-0"
              :class="{ 'flex md:hidden mb-3': isAddonGroup, hidden: !isAddonGroup }"
            >
              Make a selection below
            </span>
            <div
              class="max-w-full flex flex-col items-stretch justify-start gap-4 md:gap-4"
              ref="scrollContainer"
            >
              <template v-for="(addon, index) in visibleFilteredList" :key="index">
                <quote-presentation-addon-item
                  :orientation="orientation"
                  :targetRefId="reference"
                  :currentPrice="currentPrice"
                  :editable="editable"
                  :addon="addon"
                  :dimensions="dimensions"
                  @number-chose="(chval) => countHandler(index, chval)"
                  @disabled="() => disableAddon(addon)"
                  @addon="(modified) => (index > 0 ? modifyAddon(addon, modified) : null)"
                  @get="(object) => get(addon)"
                  @selected-addon="setSelectedAddon"
                />

                <div v-if="editable" class="addon_list-item-options">
                  <btn rounded class="lg more px-0 p-2 danger" @click="() => removeAddon(addon)">
                    &times;
                  </btn>
                </div>
              </template>

              <Btn
                @click="showAll = 1"
                v-if="!showAll && filteredList.length > visibleFilteredList.length"
                size="lg"
                unstyled
                class="border-2 border-blue-print-400 text-blue-print-400 flex text-center items-center text-lg font-medium py-4 rounded-sm"
                >Show {{ filteredList.length - visibleFilteredList.length }} more options</Btn
              >

              <div class="addon_list-options" v-if="editable">
                <traverse
                  :embue="embueCreate"
                  :externalCreate="true"
                  v-if="upgrades && target.type !== 'quote'"
                  ref="upgradeTraverse"
                >
                  <template #button>
                    <btn :loading="loading" @click="addUpgradeItem" class="btn round secondary">
                      <template #icon>
                        <font-awesome-icon icon="plus" fixed-width />
                      </template>
                      Add upgrade item
                    </btn>
                  </template>
                </traverse>
                <traverse
                  :embue="embueCreate"
                  :externalCreate="true"
                  v-if="optionals && (target.type === 'assembly' || target.type === 'quote')"
                  ref="optionalTraverse"
                >
                  <template #button>
                    <btn :loading="loading" @click="addOptionalItem" class="btn round secondary">
                      <template #icon>
                        <font-awesome-icon icon="plus" fixed-width />
                      </template>
                      Add optional item
                    </btn>
                  </template>
                </traverse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fade>
  </div>
</template>

<script>
import QuotePresentationAddonItem from './QuotePresentationAddonItem.vue'
import BtnMixin from '../../mixins/Button'
import AddonListMixin from '../addons/AddonListMixin'
import TranslationMixin from './languages/TranslationMixin'
import CostItem from '../../../../imports/api/schemas/CostItem.js'
import CurrencyFilter from '@/components/mixins/CurrencyFilter.js'
import Auditing from '../../../../imports/api/Auditing/index.js'

/**
 * Emits:
 * -countItems number of items in filtered list
 * -PresentationMoreEmphasis (refId)
 */
export default {
  name: 'QuotePresentationAddonList',
  mixins: [BtnMixin, AddonListMixin, TranslationMixin, CurrencyFilter],
  components: {
    QuotePresentationAddonItem
    /* ChangeAudit, */
  },
  emits: [''],
  async beforeMount() {
    this.possibleDimensions = await this.$store.dispatch('Dimension/getPossibleDimensions', {
      full: true
    })
  },
  data() {
    return {
      showAll: 0,
      numberChose: [],
      possibleDimensions: {},
      selectedAddon: null
    }
  },
  computed: {
    optional() {
      return this.target.cost_item_is_optional || this.target.assembly_is_optional
    },
    includePrice() {
      return CostItem.getIncludedPrice(
        this.target,
        this.norm,
        this.possibleDimensions,
        Auditing.cascadeDependencies
      )
    },
    included() {
      return this.target.cost_item_is_included || this.target.assembly_is_included
    },
    totalChose() {
      return Object.values(this.numberChose).reduce((acc, num) => acc + c.toNum(num || 0), 0)
    },
    isAddonGroup() {
      return (
        this.selectedAddon?.addon.isgrp ||
        (!this.selectedAddon?.addon.id && !this.selectedAddon?.addon.livePriceRef)
      )
    },
    visibleFilteredList() {
      if (this.showAll) return this.filteredList
      return this.filteredList.slice(0, 4)
    },
    optionsNumber() {
      return this.filteredList.filter((addon) => {
        const id = addon.id ?? addon.livePriceRef
        return id !== this.selectedAddon?.addon.id && id !== this.selectedAddon?.addon.livePriceRef
      }).length
    },
    isLocked() {
      return this.selectedAddon?.addon?.bulk?.oMeta?.lockOptions
    }
  },
  methods: {
    viewDetails() {
      return this.selectedAddon?.viewDetails?.()
    },
    countHandler(index, count = 0) {
      const ch = _.immutable(this.numberChose)
      ch[index] = count
      this.numberChose = ch
    },
    setSelectedAddon(item) {
      const object = this.filteredList.find((addon) =>
        item.addon.livePriceRef
          ? item.addon.livePriceRef === addon.livePriceRef
          : addon.id === item.addon.id
      )
      if (object) {
        this.selectedAddon = { ...item, addon: { ...object } }
        if (object.image_external)
          this.selectedAddon.addonImage = `${import.meta.env.VITE_S3_AUTOCOST_BUCKET_ENDPOINT}/${object.image_external}`
      }
    }
  },
  props: {
    showCurrent: {
      default: true
    },
    orientation: {
      default: 'horizontal'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
