<template>
  <div
    class="review-all--item odd:bg-surface-100/50 even:bg-white tabular-nums"
    v-if="refId && isVisibleItem(refId)"
  >
    <component
      :is="
        object.item_type === 'assembly' || object.type === 'assembly'
          ? 'ReviewAllAssembly'
          : 'ReviewAllCostItem'
      "
      :object="object"
      :parentRefId="parentRefId"
      :normalized="normalized"
      :presentationSettings="presentationSettings"
      :refId="refId"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ReviewAllItem',
  components: {
    ReviewAllAssembly: defineAsyncComponent(() => import('./ReviewAllAssembly.vue')),
    ReviewAllCostItem: defineAsyncComponent(() => import('./ReviewAllCostItem.vue'))
  },
  data() {
    return {
      objectLocal: this.object
    }
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    presentationSettings: {
      type: Object
    },
    store: {
      type: String,
      default: 'Quote'
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    normalized: {
      type: Object
    }
  },
  methods: {
    getVisibility(refId) {
      const item = this.normalized[refId]
      return {
        ...(item?.oViewOptions?.pres ?? {}),
        ...(item?.oMeta?.viewOptions?.pres ?? {})
      }
    },
    isVisibleItem(ref) {
      const viewOptions = this.getVisibility(ref)
      return viewOptions.isVisible ?? true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.review-all--item {
  width: 100%;
}
</style>
