<template>
  <div class="py-6" v-if="activeTab.includes('settings')">
    <!-- Name -->
    <CardSection v-if="!verified">
      <CardList class="mb-2">
        <CardListField v-if="!selectedCompany || !selectedCompany.company_email">
          <span>Email</span>
          <UniqueField
            v-model="vendor_email"
            schema="user:user_email"
            :validate="{
              type: 'email'
            }"
            :unique="{
              type: 'user',
              field: 'user_email',
              except: vendor_user_id ? [vendor_user_id] : [],
              exact: true
            }"
          >
            <div class="flex flex-col justify-center items-center">
              <template v-if="found.aoCompanies.length">
                <strong>Compan{{ found.aoCompanies.length > 1 ? 'ies' : 'y' }} found:</strong>
                <SuggestionButton
                  v-for="company in found.aoCompanies"
                  @click="
                    () => {
                      foundUserHandler(found)
                      foundCompanyHandler(company)
                    }
                  "
                  :key="company.company_id"
                >
                  <span class="text-info"
                    >Add {{ found.user_fname }} {{ found.user_lname.substr(0, 1) }} from
                    {{ company.company_name }} as vendor?</span
                  >
                </SuggestionButton>
              </template>
              <template v-if="found">
                <strong class="mt-2">User found:</strong>
                <SuggestionButton @click="foundUserHandler(found)">
                  <span class="text-info"
                    >Create a new company for {{ found.user_fname }}
                    {{ found.user_lname.substr(0, 1) }} as vendor?</span
                  >
                </SuggestionButton>
              </template>
            </div>
          </UniqueField>
        </CardListField>
      </CardList>

      <CardList>
        <CardListField v-if="requestName">
          <span> Company name </span>
          <Field :required="true" schema="company:company_name" v-model="company_name" />
        </CardListField>

        <CardListField v-if="requestName">
          <span> Company short name </span>
          <Field schema="company:company_name_short" v-model="company_name_short" />
        </CardListField>

        <CardListField v-if="requestAddress">
          <span> Address </span>
          <Field schema="company:company_address" v-model="company_address" />
        </CardListField>

        <CardListField v-if="requestAddress">
          <span> Unit/Suite </span>
          <Field schema="company:company_suite" v-model="company_suite" />
        </CardListField>

        <CardListField v-if="requestCity">
          <span> City </span>
          <Field schema="company:company_city" v-model="company_city" />
        </CardListField>

        <CardListField v-if="requestCity">
          <span> State/prov </span>
          <!-- <choose
            :return-array="false"
            :allowDeselect="false"
            schema="vendor:province_id"
            :default="company.province_id || $store.state.session.user.province_id"
            v-model="province_id"
          /> -->
        </CardListField>

        <CardListField v-if="requestPostal">
          <span> Postal/zip code </span>
          <Field schema="company:company_postal" v-model="company_postal" />
        </CardListField>

        <CardListField v-if="requestPhone">
          <span> Phone number </span>
          <Field schema="company:company_phone" v-model="company_phone" />
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection>
      <Card v-if="verified">
        <div class="flex flex-row items-center">
          <font-awesome-icon icon="badge-check" />
          <h4 class="ml-1">That company has verified their preferred contact details.</h4>
        </div>
      </Card>
      <CardList>
        <CardListField v-if="requestName">
          <span> Vendor crew size </span>
          <Field schema="vendor:vendor_crew_size" v-model="vendor_crew_size" />
        </CardListField>
        <CardListField>
          <span>
            Vendor trade types

            <small class="text-info">
              Enter the kind of work this company does. Can select multiple.
            </small>
          </span>
          <choose
            multiple
            :validate="{ required: false }"
            schema="company:trade_type_ids"
            v-model="trade_type_ids"
          />
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection>
      <template #title>
        <div class="flex flex-row items-center">
          <font-awesome-icon icon="user" />
          <h4 class="ml-1">Add contact (optional)</h4>
        </div>
      </template>
      <CardList>
        <CardListField>
          <span>First name</span>

          <Field v-model="user_fname" schema="user:user_fname" />
        </CardListField>
        <CardListField>
          <span>Last name</span>

          <Field v-model="user_lname" schema="user:user_lname" />
        </CardListField>

        <CardListField>
          <span>Email</span>

          <UniqueField
            v-model="user_email"
            schema="user:user_email"
            :validate="{
              type: 'email'
            }"
            :unique="{
              type: 'user',
              field: 'user_email',
              except: vendor_user_id ? [vendor_user_id] : [],
              exact: true
            }"
          >
            <SuggestionButton @click="foundUserHandler(found)">
              User already exists.
              <span class="text-info"
                >Add {{ found.user_fname }} {{ found.user_lname.substr(0, 1) }} as contact?
              </span>
            </SuggestionButton>
          </UniqueField>
        </CardListField>

        <CardListField>
          <span>Phone</span>
          <Field
            v-model="user_phone"
            schema="user:user_phone"
            :class="{ '!border-red-400 border-2 text-red-500': !phoneIsValid }"
          />
        </CardListField>
      </CardList>
    </CardSection>

    <CardSection v-if="user && vendor_user_id">
      <Card>
        <template #icon>
          <font-awesome-icon icon="user" />
        </template>

        <template #title>
          <div class="flex flex-row items-center">
            <Btn rounded class="more px-0 p-2 dark" @click="removeUser()"
              ><font-awesome-icon icon="remove"
            /></Btn>
            <h4 class="ml-1">
              Using {{ user.user_fname }} {{ user.user_lname.substr(0, 1) }} as contact.
            </h4>
          </div>
        </template>
      </Card>
    </CardSection>
  </div>

  <div v-if="activeTab.includes('bids')">
    <Automation class="my-6" :closable="false">
      Subcontractor bidding coming soon, allow subcontractors submit bids for your designated items
      and assemblies
    </Automation>
  </div>

  <div v-if="activeTab.includes('activities')">
    <activities
      :showBackupsFor="{
        vendor: vendor_id
      }"
      :filters="{
        target_type: 'vendor',
        target_id: vendor_id
      }"
    />
  </div>
</template>

<script>
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'
import Activities from '../ui/activities/Activities.vue'
import UniqueField from '../ui/UniqueField.vue'
import SuggestionButton from '../ui/SuggestionButton.vue'
import ActivityChannels from '@/components/mixins/ActivityChannels'
import { isValidPhoneNumber } from 'libphonenumber-js'
import eventBus from '@/eventBus.js'

export default {
  mixins: [ObjectManipulator('vendor', false), BodyMixin, ActivityChannels],
  data() {
    return {
      startingTab: ['Settings'],
      user: null,
      selectedCompany: {}
    }
  },
  watch: {
    company_email() {
      this.user_email = this.user_email || this.company_email
    },

    company_phone() {
      this.user_phone = this.user_phone || this.company_phone
    },

    company_city() {
      this.user_city = this.user_city || this.company_city
    },

    company_address() {
      this.user_address = this.user_address || this.company_address
    },

    company_postal() {
      this.user_postal = this.user_postal || this.company_postal
    },

    company_suite() {
      this.user_suite = this.user_suite || this.company_suite
    }
  },
  computed: {
    activeTab() {
      const rtq = this.$route.query && this.$route.query.tab
      if (rtq) return c.makeArray(rtq)
      return ['settings']
    },
    verified() {
      return (
        this.selectedCompany.company_is_verified ||
        ((this.company_has_email || this.company_has_users) &&
          this.selectedCompany.company_city &&
          this.company_has_phone &&
          (this.company_has_users || this.company_is_vendor_count || this.company_is_client_count))
      )
    },
    requestName() {
      return !this.verified && !this.selectedCompany.company_name
    },
    requestEmail() {
      return !this.verified && !this.selectedCompany.company_email
    },
    requestPhone() {
      return !this.verified && !this.selectedCompany.company_phone
    },
    requestCity() {
      return !this.verified && !this.selectedCompany.company_city
    },
    requestPostal() {
      return !this.verified && !this.selectedCompany.company_postal
    },
    requestAddress() {
      return !this.verified && !this.selectedCompany.company_address
    },
    requestContact() {
      return !this.verified && !this.selectedCompany.company_has_users && !this.vendor_user_id
    },
    phoneIsValid() {
      if (this.user_phone) {
        return isValidPhoneNumber(this.user_phone, this.user_country?.toUpperCase())
      }
      return true
    }
  },
  methods: {
    async foundCompanyHandler(company, email) {
      const { set } = await this.$store.dispatch('User/search', {
        filters: {
          user_email: email
        }
      })
      if (set.length) {
        this.foundUserHandler(set[0])
      }
      this.selectedCompany = company
      this.vendor_company_id = company.company_id
      this.selectedCompany.company_is_verified = 1
    },
    foundUserHandler(user) {
      if (!user) return
      this.user = user
      this.vendor_user_id = user.user_id
    },
    removeUser() {
      this.user = null
      this.vendor_user_id = null
    },
    companyChangeHandler(co) {
      if (!co) return

      this.fill(co)
    },
    afterSelect() {
      this.companyChangeHandler(this.company)
      this.company_city = this.company_city || this.$store.state.session.company.company_city
    },
    initCompanyCompanyChannel() {
      const companyId = this.$store.state.session.company.company_id
      if (!companyId) return
      this.createCompanyCompanyChannel(companyId, this.vendor_company_id, this.company_name)
    }
  },
  components: {
    Activities,
    UniqueField,
    SuggestionButton
  },
  props: {
    forceFull: {
      default: true
    }
  },
  mounted() {
    eventBus.$on(`${this.uid}-selected`, this.initCompanyCompanyChannel)
  },
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
