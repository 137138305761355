export function getLeadRotationSteps(object, store) {
  return [
    {
      name: 'Copy lead form url to clipboard',
      short: 'Copy link',
      action: (obj) => {
        store.dispatch(
          'copyToClipboard',
          `${import.meta.env.VITE_FE_PROTOCOL}${import.meta.env.VITE_FE_BASE}/leadForm/${obj.lead_rotation_id}`
        )
      },
      glyph: 'copy',
      color: 'green-500'
    }
  ]
}
