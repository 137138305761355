<template>
  <div class="quote-presentation--inputs">
    <CardList>
      <CardListField v-if="inputRequired && colorInput">
        <span>
          Choose a color for this item
        </span>

        <SelectionColor
            v-model="colorInputData" placeholder="No color selected" />
      </CardListField>

      <CardListField v-if="inputRequired && textInput">
        <span>
          {{textInputMessage}}
        </span>

        <Field type="textarea"
               placeholder="N/A"
               style="text-align: right !important"
               :validate="{ required: true }"
               v-model="textInputValue"
               />
      </CardListField>
    </CardList>
  </div>
</template>

<script>
  import PresentationCostItemMixin from '../PresentationCostItemMixin';
  import ItemInputsMixin from '../../item/ItemInputsMixin';
  import { defineAsyncComponent } from 'vue'

  export default {
    name: 'QuotePresentationInputs',
    mixins: [PresentationCostItemMixin, ItemInputsMixin],
    data() {
      return {
        uid: c.uniqueId(),
      };
    },
    computed: {
    },
    components: {
      SelectionColor: defineAsyncComponent(() => import('../../../ui/SelectionColor/SelectionColor.vue')),
    },
    props: {
      object: {
        type: Object,
        required: true,
      },
      deselectOnDestroy: {
        default: false,
      },
      store: {
        type: String,
      },
      parentRefId: {
        type: String,
      },
      refId: {
        type: String,
      },
      editable: {
        type: [Number, Boolean],
      },
      presentationSettings: {
        type: Object,
      },
      artificialMultiplier: {
        type: Number,
        default: 1,
      },
    },
    mounted() {
    },
    beforeUnmount() {
    },
  };
</script>

<style lang='scss' rel='stylesheet/scss'>
</style>
