<template>
  <div v-if="!isNotItem(object)" class="review-all--costItem">
    <div class="cost-item--name basis-4/5 grow-1 shrink-1">
      {{ object.item_name || object.cost_type_name || $t('No item name') }}
    </div>
    <!--    <div class="cost-item&#45;&#45;img-wrapper">-->
    <!--      &lt;!&ndash;      <div class="cost-item&#45;&#45;img" v-if="fileUrls.length">&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;        <img&ndash;&gt;&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;          @click="() => $refs.gallery.preview(fileUrls[0])"&ndash;&gt;&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;          v-if="itemPictures.length || images.length || fileUrls.length"&ndash;&gt;&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;          :src="fileUrls[0]"&ndash;&gt;&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;          class="cost-item-img"&ndash;&gt;&ndash;&gt;-->
    <!--      &lt;!&ndash;&lt;!&ndash;        />&ndash;&gt;&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--    </div>-->
    <div
      :style="{
        marginLeft:
          $store.state.session.deviceWidth > 400 ? `-${object.asAssemblyPath.length * 0.75}em` : `0`
      }"
      class="cost-item-content gap-12 mr-0.5"
    >
      <div class="cost-item--qty text-xs xl:text-md justify-end items-center hidden md:flex">
        <template v-if="showQty">
          <template v-if="object.cost_type_is_fee"> 1 {{ $t('each') }} </template>
          <template v-else> {{ $f.number(qty) }} {{ units }} </template>
        </template>
      </div>
      <div
        class="cost-item--price min-w-20 basis-20 grow shrink-0 whitespace-nowrap"
        v-if="qty > 1 || qty < 1 || showPrice || !qty"
        :class="!qty ? 'excluded' : ''"
      >
        <template v-if="!qty">
          {{ $t('not included') }}
        </template>
        <template v-else-if="showPrice">
          {{ currency(price || 0) }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PresentationCostItemMixin from '../presentation/PresentationCostItemMixin'
import TranslationMixin from '../presentation/languages/TranslationMixin'
import ItemPicturesMixin from '../presentation/ItemPicturesMixin'
import { isNotItem } from '@/../imports/api/Item'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'

export default {
  name: 'ReviewAllCostItem',
  mixins: [PresentationCostItemMixin, TranslationMixin, ItemPicturesMixin],
  data() {
    return {
      objectLocal: this.object
    }
  },
  methods: {
    currency: formatCurrencySymbol
  },
  components: {},
  props: {
    object: {
      type: Object,
      required: true
    },
    parentRefId: {
      type: String
    },
    refId: {
      type: String
    },
    presentationSettings: {
      type: Object
    },
    store: {
      type: String,
      default: 'Quote'
    },
    artificialMultiplier: {
      type: Number,
      default: 1
    },
    normalized: {
      type: Object
    }
  },
  setup() {
    return {
      isNotItem
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
$thumbnail-width: 50px;
.review-all--costItem {
  width: 100%;
  padding: 0.25em 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: solid 1px $surface-200;
  padding-left: 0.75em;
  @media (max-width: 576px) {
    justify-content: flex-start;
    padding-left: 0.4em;
  }
  .cost-item--img-wrapper {
    min-width: 350px;
    @media (max-width: 576px) {
      min-width: initial;
    }
  }
  .cost-item-content {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    .cost-item--name {
      flex: 4;
    }
    .cost-item--price,
    .cost-item--qty {
      text-align: right;
    }
    .cost-item--qty {
      text-align: right;
    }
  }
  .cost-item--img {
    width: $thumbnail-width;
    height: $thumbnail-width;
    overflow: hidden;
    border-radius: 0.5em;
    border: solid 1px $cool-gray-400;
    img {
      max-width: $thumbnail-width;
    }

    @media (max-width: 576px) {
      display: none;
    }
  }
}
</style>
