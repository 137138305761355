<script>
import { ref, computed, onMounted, getCurrentInstance, watch } from 'vue'
import { useStore } from 'vuex'
import { useDeviceStore } from '@/stores/device'
import TieredMenu from 'primevue/tieredmenu'
import FocusBar from '@/components/layout/header/FocusBar.vue'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields'
import RouteEntityContext from '@/components/composables/RouteEntityContext'
import InvoicePDFMixin from '@/components/invoice/InvoicePDFMixin.js'

export default {
  mixins: [InvoicePDFMixin],
  components: {
    TieredMenu,
    FocusBar
  },
  setup() {
    const { refId, type, storeName, object } = RouteEntityContext.useRouteEntityContext({
      trackChanges: false
    })

    const { invoice_id } = EntityComputedFields.useEntityComputedFields({
      refId: refId,
      store: storeName,
      type: type
    })

    const instance = ref(getCurrentInstance())

    const $store = useStore()
    const deviceStore = useDeviceStore()
    const refToggleButton = ref(null)
    const menu = ref()
    const toggle = () => {
      menu.value.toggle({ currentTarget: refToggleButton.value.$el })
    }

    const hasAccessToExporter = ref(false)

    const download = () => {
      // savePDF()
      instance.value.proxy.savePDF()
    }

    const sendInvoice = () => {
      return $store.dispatch('Invoice/send', { refId: refId.value })
    }

    const syncInvoice = () => {
      return $store.dispatch('ExportToken/sync', {
        id: invoice_id.value,
        type: 'invoice',
        exporter: 'quickbooks'
      })
    }

    const invoiceActions = computed(() => {
      const actions = [
        {
          icon: 'fa fa-cloud-arrow-down',
          class: '!text-level-yellow',
          command: download,
          label: 'Download'
        }
      ]
      if (hasAccessToExporter.value) {
        actions.splice(1, 0, {
          icon: 'fa fa-sync',
          class: '!text-level-yellow',
          command: syncInvoice,
          label: 'Sync with QuickBooks'
        })
      }
      return actions
    })

    onMounted(async () => {
      const { payload } = await $store.dispatch('ExportToken/hasAccess', {
        type: 'quickbooks'
      })
      const { value: verification } = payload
      hasAccessToExporter.value = verification
    })

    watch(object, () => {
      if (!invoice_id.value) return
      instance.value.proxy.invoice = object.value
      instance.value.proxy.renderDoc()
    })

    return {
      deviceStore,
      refToggleButton,
      menu,
      toggle,
      invoiceActions,
      sendInvoice,
      download,
      syncInvoice,
      invoice_id,
      refId
    }
  }
}
</script>

<template>
  <FocusBar>
    <template #middle>
      <div class="justify-center items-center hidden md:flex">
        <div v-if="invoice_id" class="text-xl">Invoice #{{ invoice_id }}</div>
        <div v-else>New invoice</div>
      </div>
    </template>
    <template v-if="invoice_id" #right>
      <div>
        <Btn
          ref="refToggleButton"
          class="rounded-r-none"
          size="lg"
          severity="primary-black"
          :action="sendInvoice"
        >
          <template #icon>
            <font-awesome-icon icon="paper-plane" />
          </template>
          Send
        </Btn>
        <Btn
          ref="refToggleButton"
          :class="[!deviceStore.isMobile ? 'rounded-l-none' : '']"
          @click="toggle"
          severity="primary-black"
          size="lg"
        >
          <font-awesome-icon :icon="deviceStore.isMobile ? 'ellipsis' : 'chevron-down'" />
        </Btn>
        <TieredMenu ref="menu" id="overlay_tmenu" :model="invoiceActions" popup />
      </div>
    </template>
  </FocusBar>
</template>

<style scoped lang="scss"></style>
