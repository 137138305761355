import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { useStore } from 'vuex'
import { useAuthentication } from '@/composables/authentication'

export async function redirectWhenLoggedIn(to) {
  try {
    await getCurrentUser()
    if (to.query.redirect) {
      return { path: to.query.redirect }
    }
    return { name: 'Home' }
  } catch (e) {
    console.debug('No user found, rendering page')
  }
}

export async function handleOAuthCallback(to) {
  const store = useStore()
  if (!to.query.error) {
    try {
      const { tokens } = await fetchAuthSession()
      await store.dispatch('ajax', {
        path: '/auth/exchangeToken',
        setToken: true,
        data: {
          accessToken: tokens.accessToken.toString(),
          idToken: tokens.idToken.toString()
        }
      })
      await store.dispatch('getBaseValues')
      return { name: 'Home' }
    } catch (e) {
      await store.dispatch('alert', {
        error: true,
        message:
          'Unable to sign you in with your social account. Please try again or contact support.'
      })
      return { name: 'Logout' }
    }
  } else {
    await store.dispatch('alert', {
      error: true,
      message: to.query.error_description
    })
    return { name: 'Logout' }
  }
}

export async function logoutIfAuthenticated() {
  const { logout } = useAuthentication()
  try {
    await getCurrentUser()
    await logout()
  } catch (e) {
    // User is probably already logged out
    console.debug('User is likely already logged out.')
  }

  return { name: 'Login' }
}

export async function checkSubscriptionStatus() {
  const store = useStore()
  const { payload } = await store.dispatch('ajax', {
    path: 'subscription/getProductSubscriptionStatus',
    data: {
      module_id: 14,
      company_id: store.state.session.company.company_id
    }
  })
  const isModuleEnabled = payload.is_module_enabled

  if (!isModuleEnabled) {
    return { name: 'Home' }
  }
}

export async function forceBaseValues() {
  const store = useStore()
  await store.dispatch('getBaseValues', { alert: false })
}

export async function enterSuperScope() {
  const store = useStore()
  await store.dispatch('enterSuperScope')
}
