// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'phase_schedule',

  skipAudit: true,

  fields: {
    phase_schedule_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      visible: false
    },
    phase_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'phase',
      visible: false
    },
    company_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'company',
      visible: false
    },
    quote_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: 'quote',
      visible: false
    },
    phase_schedule_start_date: {
      type: 'int',
      filter: true,
      format: false
    },
    phase_schedule_duration: {
      type: 'int',
      filter: true,
      format: false
    },
    phase_schedule_status: {
      type: 'string',
      filter: true,
      format: false
    },
    phase_schedule_creator: {
      type: 'int',
      filter: true,
      format: false
    },
    phase_schedule_owner: {
      type: 'int',
      filter: true,
      format: false
    },
    phase_schedule_time_created: {
      type: 'date',
      filter: true,
      format: false,
      mapTo: false
    },
    phase_schedule_time_last_modified: {
      type: 'date',
      filter: true,
      format: false,
      mapTo: false
    }
  }
}
