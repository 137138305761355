import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export const useActivityItem = (props) => {
  // ======== Composables ======== //
  const store = useStore()
  const router = useRouter()
  // ====================== //

  // ======== Data ======== //
  const fetchedOTarget = ref(null)
  // ====================== //

  // ======== Computed ======== //
  const currentUserId = computed(() => store.state.session.scope.user.toString())
  const activityCreatorId = computed(() => props.activity?.activity_creator)
  const activityCreator = computed(() => props.activity?.oCreator)
  const isUserActivity = computed(() => activityCreatorId.value === currentUserId.value)
  const activityId = computed(() => props.activity?.activity_id)
  const activityFileIds = computed(() => props.activity?.file_ids)
  const activityFiles = computed(() =>
    activityFileIds.value.map((file) => {
      if (file instanceof File) {
        return file.objectURL
      }
      return `${import.meta.env.VITE_S3_ACTIVITY_CHAT_BUCKET_ENDPOINT}/${file}`
    })
  )
  const hasFiles = computed(() => activityFileIds.value.length > 0)
  const activityMentions = computed(
    () =>
      (props.activity?.activity_mentions &&
        _.imm(props.activity.activity_mentions).sort(
          (a, b) => a.mention_start_index - b.mention_start_index
        )) ||
      []
  )
  const activityMessage = computed(() => {
    let baseMessage = props.activity?.activity_notification_heading
    let textOffset = 0

    activityMentions.value.forEach((mention) => {
      const replaceText = `<span onclick="
          document.getElementById('${'activity-' + activityId.value}')
          .dispatchEvent(
            new CustomEvent('onMentionClick', {
              detail: { 
                mention_type: '${mention.mention_type}', 
                mention_type_id: '${mention.mention_type_id}' 
              }
            })
          )" 
          id="activity-mention"
        >@${mention.mention_text}</span>`
      let message = baseMessage?.slice(0, mention.mention_start_index + textOffset)
      message += replaceText
      message += baseMessage?.slice(
        mention.mention_start_index + mention.mention_text.length + 1 + textOffset,
        baseMessage.length
      )
      baseMessage = message
      textOffset += replaceText.length - (mention.mention_text.length + 1)
    })

    return baseMessage?.replaceAll(/(>|^)((.|\n)*?)(<|$)/g, (match) => {
      return match.replaceAll(' ', '&nbsp;').replaceAll('\n', '<br>')
    })
  })
  const activityBaseType = computed(() => props.activity.activity_base_type)
  const activityType = computed(() => props.activity.activity_type)
  const activityTypeId = computed(() => props.activity.activity_type_id)
  const activityCreatorName = computed(
    () => `${activityCreator.value?.user_fname} ${activityCreator.value?.user_lname}`
  )
  const activityDescription = computed(() => props.activity.activity_desc)
  const activityTargetObject = computed(
    () => fetchedOTarget.value || props.activity.activity.oTarget
  )
  const activityTimeCreated = computed(() => props.activity.activity_time_created)
  const prevActivityTimeCreated = computed(() => props.prevActivity?.activity_time_created)
  const parentActivityId = computed(() => props.activity.parent_activity_id)
  const parentActivity = computed(() => props.activity.parent_activity)
  const hasSeenActivity = computed(
    () => !('has_member_viewed' in props.activity) || props.activity.has_member_viewed
  )
  const hasSeenPrevActivity = computed(
    () =>
      !props.prevActivity ||
      !('has_member_viewed' in props.prevActivity) ||
      props.prevActivity.has_member_viewed
  )
  const hasNotification = computed(
    () => store.state.activityChat.channelNotificationsObject?.[props.channelId]?.[activityId.value]
  )
  const formattedTimeCreatedHour = computed(() => {
    const date = new Date(activityTimeCreated.value * 1000)
    return new Intl.DateTimeFormat('default', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }).format(date)
  })
  const formatedTimeCreatedDate = computed(() => {
    const date = new Date(activityTimeCreated.value * 1000)
    return date.toLocaleDateString('default', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  })
  const oTarget = computed(() => fetchedOTarget.value || props.activity.oTarget)
  const targetType = computed(() => props.activity.target_type)
  const targetTypeId = computed(() => props.activity.target_id)
  const targetName = computed(
    () => oTarget.value[`${targetType.value?.toLowerCase()}_name`] || null
  )
  const showChanges = computed(() => {
    return (
      targetType.value === 'change_order' ||
      (oTarget.value?.aoExplicitChanges &&
        oTarget.value?.aoExplicitChanges.length &&
        !Array.isArray(oTarget.value.aoExplicitChanges[0])) ||
      (oTarget.value?.aoFullChanges &&
        oTarget.value?.aoFullChanges.length &&
        !Array.isArray(oTarget.value.aoFullChanges[0]))
    )
  })
  const denormalizedChanges = computed(() => {
    let diff
    if (
      oTarget.value &&
      Array.isArray(oTarget.value.aoFullChanges) &&
      oTarget.value.aoFullChanges.length
    ) {
      diff = oTarget.value.aoFullChanges[0]
    }

    return diff ? _.denormalizeDiffSet(diff) : {}
  })
  const onMentionClick = async (mentionType, mentionTypeId) => {
    const channelType = props.channelId.split('-')[0]
    const channelId = props.channelId.split('-')[1]

    if (mentionType === 'ITEM') {
      let quoteId = null

      if (channelType === 'QUOTE') {
        quoteId = channelId
      }

      if (channelType === 'QUOTE_CLIENT') {
        quoteId = channelId.split('-')[0]
      }

      if (router.currentRoute.value.name === 'ClientChannel') {
        store.commit({
          type: 'SET_SCOPE',
          scope: {
            ...store.state.session.scope,
            quote: quoteId
          }
        })

        await store.dispatch('getBaseValues')
      }

      if (
        router.currentRoute.value.name === 'ClientChannel' ||
        router.currentRoute.value.name === 'Project preview'
      ) {
        await router.push({
          name: 'Project preview',
          params: {
            scopeRoute: `${quoteId}-${store.state.session.quote.quote_name.replaceAll(' ', '')}`
          },
          query: {
            itemId: mentionTypeId
          }
        })

        return store.commit({
          type: 'SET_PREVIEW',
          preview: {}
        })
      }

      await router.push({
        name: 'Proposal',
        params: {
          id: quoteId
        },
        query: {
          tab: 'Preview',
          itemId: mentionTypeId
        }
      })
    }
  }
  // ====================== //

  return {
    fetchedOTarget,
    currentUserId,
    activityCreatorId,
    activityCreator,
    activityCreatorName,
    isUserActivity,
    activityId,
    activityFileIds,
    activityFiles,
    hasFiles,
    activityMessage,
    activityBaseType,
    activityType,
    activityTypeId,
    activityDescription,
    activityTargetObject,
    hasSeenActivity,
    hasSeenPrevActivity,
    hasNotification,
    activityTimeCreated,
    prevActivityTimeCreated,
    formattedTimeCreatedHour,
    formatedTimeCreatedDate,
    parentActivityId,
    parentActivity,
    oTarget,
    targetType,
    targetTypeId,
    targetName,
    showChanges,
    denormalizedChanges,
    onMentionClick
  }
}

export default useActivityItem
