<template>
  <!-- Unsent -->
  <div v-if="status === statuses.UNSENT" class="flex flex-col gap-y-5">
    <p>
      Can't find what you're looking for with AutoCost? We're always working to expand our catalog
      of live price, localized items.
    </p>
    <div class="flex flex-col w-full">
      <p>
        Let us know what you need and we'll do our best to update the AutoCost catalog to include
        it:
      </p>
      <textarea v-model="request" rows="5" class="border rounded p-2 mt-2" />
    </div>
    <div class="flex flex-row w-full gap-x-2">
      <btn class="w-full" severity="secondary" size="lg" @click="$emit('cancel')">
        <icon icon="xmark" />
        Cancel
      </btn>
      <btn
        class="w-full"
        severity="bolster"
        size="lg"
        @click="submitRequest"
        :disabled="request === ''"
      >
        <icon icon="paper-plane" />
        Send
      </btn>
    </div>
  </div>

  <!-- Sending -->
  <div v-else-if="status === statuses.SENDING" class="flex items-center justify-center w-full">
    <spinner :loading="1" size="2em" />
  </div>

  <!-- Sent -->
  <div
    v-else-if="status === statuses.SENT"
    class="flex flex-col items-center justify-center text-center w-full gap-y-2 mt-4"
  >
    <p class="text-2xl">Request received!</p>
    <p>We'll fulfill your request as soon as possible.</p>
    <icon icon="circle-check" class="text-4xl my-4" />
    <btn class="w-full" severity="bolster" size="lg" @click="$emit('cancel')"> Continue </btn>
  </div>

  <!-- Failed -->
  <div
    v-else-if="status === statuses.FAILED"
    class="flex flex-col items-center justify-center text-center w-full gap-y-2 mt-4"
  >
    <p class="text-2xl">Uh oh...</p>
    <p>
      We ran into some technical difficulties sending your request. Please refresh and try again.
    </p>
    <icon icon="circle-exclamation" class="text-4xl my-4" />
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue'
import { useStore } from 'vuex'

const $store = useStore()
const $emit = defineEmits(['cancel'])
const request = ref('')

const statuses = {
  UNSENT: 'unsent',
  SENDING: 'sending',
  SENT: 'sent',
  FAILED: 'failed'
}
const status = ref(statuses.UNSENT)

const submitRequest = async () => {
  status.value = statuses.SENDING
  try {
    await $store.dispatch('ajax', {
      path: '/live_price/requestLivePriceUpdate',
      data: {
        request: request.value,
        zipcode: $store.state.session.company.company_postal,
        email: $store.state.session.user.user_email
      }
    })
  } catch (e) {
    status.value = statuses.FAILED
    console.error(e)
    return
  }
  status.value = statuses.SENT
}
</script>
