<script setup>
import { computed, reactive } from 'vue'
import { useSidePanel } from '@/stores/sidepanel'
import SidePanelMenu from '@/components/layout/panel/SidePanelMenu.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useActivityChannels } from '@/components/composables/ActivityChannels'

const $store = useStore()
const $router = useRouter()
const sidePanelStore = useSidePanel()
const { getFormattedChannelName, getTotalChannelNotificationsLength } = useActivityChannels()
const items = reactive([
  {
    label: 'Invoice details',
    icon: 'file-invoice-dollar',
    tab: 'details'
  },
  {
    label: 'PDF preview',
    icon: 'user',
    tab: 'pdf'
  }
])

const onChannelClick = (channel) => {
  $store.commit({
    type: 'SET_PREVIEW',
    preview: {
      channel_type: channel.channel_type,
      channel_type_id: channel.channel_type_id
    }
  })
}

const channels = computed(() => {
  const channelId = `INVOICE-${$router.currentRoute.value.params.id}`
  const filteredChannels = Object.values($store.state.activityChat.channels).filter((channel) => {
    return channel.channel_id === channelId || channel.parent_channel_id === channelId
  })

  return [
    {
      divider: true
    },
    {
      label: 'Messages',
      highlight: false,
      starred: true,
      class: 'font-normal text-surface-500'
    },
    ...filteredChannels.reduce((acc, channel) => {
      const channelId = channel.channel_id
      const channelName = getFormattedChannelName(channel)

      if (
        channel?.parent_channel_id !== 'NULL' &&
        $store.state.activityChat.channels[channel?.parent_channel_id]
      ) {
        return acc
      }

      acc.push({
        label: channelName,
        badge: getTotalChannelNotificationsLength(channelId),
        icon: 'house-building',
        command: () => onChannelClick(channel),
        class: 'text-sm font-normal text-surface-700 dark:text-white/80',
        containerClass: 'font-medium text-surface-800 -ml-1'
      })
      return acc
    }, [])
  ]
})

const menuItems = computed(() => [...items, ...channels.value])
</script>

<template>
  <div class="w-full h-screen flex flex-col">
    <div
      class="md:hidden px-4 h-[60px] w-full m-0 flex flex-row justify-between items-center border-b border-cement-600"
    >
      <span class="text-[20px] semi-bold">Menu</span>
      <font-awesome-icon
        icon="times"
        size="lg"
        class="cursor-pointer"
        @click.native="sidePanelStore.toggle"
      />
    </div>
    <SidePanelMenu :model="menuItems" :allowStarred="false" />
  </div>
</template>

<style scoped lang="scss"></style>
