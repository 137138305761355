<template>
  <component
    v-if="item && Object.keys(item).length > 0"
    :is="component"
    :item="item"
    :parentRefId="parentRefId"
    :normalized="normalized"
    :selected="selected"
    :readonly="readonly"
    :expanded="expanded"
    :invoiced="invoiced"
    :refId="refId"
  />
</template>

<script>
import ItemizedListMixin from './ItemizedListMixin'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'ItemizedListItem',
  data() {
    return {
      objectLocal: this.item
    }
  },
  computed: {
    component() {
      const type =
        this.item.item_type === 'assembly' || this.item.type === 'assembly'
          ? 'ItemizedListAssembly'
          : 'ItemizedListCostItem'

      return defineAsyncComponent(() => import(`../itemized/${type}.vue`))
    }
  },
  mixins: [ItemizedListMixin]
}
</script>
