<script setup>
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import ItemLaborCosts from '@/components/composables/ItemLaborCosts.js'

const props = defineProps(['refId', 'store', 'type'])

const $store = useStore()
const ecf = EntityComputedFields.useEntityComputedFields({
  store: props.store,
  refId: props.refId,
  type: props.type
})
const {
  laborRate,
  crewSize,
  hours,
  minutes,
  seconds,

  isLtAutoCost,
  laborFormatter
} = ItemLaborCosts.useItemLaborCosts({
  ...props,
  ...ecf,
  $store
})
const {
  cost_type_name,
  unitOfMeasureId,
  cost_type_is_subcontracted,
  cost_type_has_labor,
  laborTypeId,
  labor_type_name
} = ecf
const unitChoose = ref(null)
const refLaborChoose = ref(null)
</script>

<template>
  <div class="text-sm leading-snug flex flex-col gap-4">
    <Choose
      ref="unitChoose"
      headless
      v-model="unitOfMeasureId"
      schema="cost_type:unit_of_measure_id"
    />
    <Choose
      ref="refLaborChoose"
      headless
      v-model="laborTypeId"
      schema="cost_type:labor_type_id"
      :formatter="laborFormatter"
    />

    <template v-if="cost_type_is_subcontracted">
      <span class="font-medium text-sm">
        <font-awesome-icon icon="truck-arrow-right" class="mr-2" />
        Subcontracted item
      </span>

      <p>
        This item is subcontracted. To modify labor options directly, make this item not
        subcontracted.
      </p>

      <label class="flex items-center content-center gap-2 font-medium">
        Subcontracted
        <InputSwitch v-model="cost_type_is_subcontracted" :trueValue="1" :falseValue="0" />
      </label>
    </template>
    <template v-else-if="!cost_type_has_labor">
      <span class="font-medium text-sm">
        <font-awesome-icon icon="user-helmet-safety" class="mr-2" />
        No labor set
      </span>

      <p>There are no labor costs associated with this item currently.</p>

      <label class="flex items-center content-center gap-2 font-medium">
        Include labor
        <InputSwitch v-model="cost_type_has_labor" :trueValue="1" :falseValue="0" />
      </label>
    </template>
    <template v-else>
      <span class="font-medium text-sm">
        <font-awesome-icon icon="hourglass-clock" class="mr-2" />
        Labor hours
      </span>
      <p>
        For <span class="font-medium">one person</span> to complete
        {{ isEach ? 'the install of' : '' }}
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          :format="useImperial ? 'imperial' : 'number'"
          v-model="divisor"
        />
        <a
          class="cursor-pointer underline underline-offset-4 font-medium hover:border-blue-print-500 border-2 border-transparent rounded-sm -mx-1 -my-0.5 px-1 py-0.5"
          @click="unitChoose.open()"
          >{{ unitName }}</a
        >
        of <span class="font-medium">{{ cost_type_name }}</span> it takes
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          format="number"
          v-model="hours"
        />h
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          format="number"
          v-model="minutes"
        />m
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          format="number"
          v-model="seconds"
        />s (time).
      </p>

      <span class="font-medium text-sm">
        <font-awesome-icon icon="user-helmet-safety" class="mr-2" />
        Type of crew
      </span>
      <p>
        This work needs to be done by a
        <a
          class="cursor-pointer underline underline-offset-4 font-medium hover:border-blue-print-500 border-2 border-transparent rounded-sm -mx-1 -my-0.5 px-1 py-0.5"
          @click="refLaborChoose.open()"
          >{{ labor_type_name
          }}<Icon
            icon="svg:autocostWhite"
            class="inline ml-1 bg-matcha-500 rounded-full aspect-square h-6 p-0.5"
            v-if="isLtAutoCost"
            v-tooltip="
              'This is an AutoCost rate managed by Bolster. It is costed based on your location and updated regularly.'
            "
        /></a>
        person or crew. On average, they cost you about
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          format="$"
          v-model="laborRate"
        />
        per person, per hour.
      </p>

      <p>
        There {{ crewSize === 1 ? 'is' : 'are' }} usually
        <CalculatorFormatted
          class="inline font-medium underline underline-offset-4"
          format="number"
          v-model="crewSize"
        />
        {{ crewSize === 1 ? 'person' : 'people' }} per crew.
      </p>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
