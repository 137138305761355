import { onMounted, onBeforeUnmount, ref, watch, getCurrentInstance, computed } from 'vue'

export default {
  useCanvasEvents(args) {
    const { canvas, getElementFromCoords, disableMouse, emit } = args

    const getCanvasCoordsFromCanvasEvent = (e) => {
      const rect = canvas.value?.getBoundingClientRect()
      const x = Math.max(0, e.clientX - rect.left)
      const y = Math.max(0, e.clientY - rect.top)
      return { x, y }
    }

    const getEventElement = (e) => {
      const canvCoords = getCanvasCoordsFromCanvasEvent(e)
      const { x, y } = canvCoords
      const element = getElementFromCoords(x, y)
      const { x: elX, y: elY, height, width } = element.position

      const percX = width === 0 ? 0 : (x - elX) / width
      const percY = height === 0 ? 0 : (y - elY) / height
      return {
        x,
        y,
        height,
        width,
        xPercentage: percX,
        yPercentage: percY,
        element,
        event: e
      }
    }

    const disabledEvents = ref([])

    const eventCache = ref({})
    const mousemoveElement = computed(() => eventCache.value.mousemoveElement)
    const mousemoveEvent = computed(() => eventCache.value.mousemoveEvent)

    const dragoverElement = computed(() => eventCache.value.dragoverElement)

    const disableEventType = (type) => {
      disabledEvents.value = [...disabledEvents.value, type]
    }

    const enableEventType = (type) => {
      disabledEvents.value = disabledEvents.value.filter((et) => et !== type)
    }

    watch(
      dragoverElement,
      (after, before) => {
        const beforeExists =
          before && before.element && !before.element.name.toLowerCase().includes('bounds')

        const afterExists =
          after && after.element && !after.element.name.toLowerCase().includes('bounds')

        const different = true

        if (
          beforeExists &&
          (!afterExists || different) &&
          !disabledEvents.value.includes('dragleave')
        ) {
          const elementTitleBefore = c.titleCase(before.element.name)
          emit(`dragleave${elementTitleBefore}`, before)
        }
        if (
          afterExists &&
          (!beforeExists || different) &&
          !disabledEvents.value.includes('dragover')
        ) {
          const elementTitleAfter = c.titleCase(after.element.name)
          emit(`dragover${elementTitleAfter}`, after)
        }
      },
      {
        flush: 'pre'
      }
    )

    watch(
      mousemoveElement,
      (after, before) => {
        const beforeExists = before && before.element

        const afterExists = after && after.element

        const different = true

        /* beforeExists
        && afterExists
        && (before.element.name !== after.element.name
          || before.element.position.col !== after.element.position.col
          || before.element.position.row !== after.element.position.row
          || before.element.position.height !== after.element.position.height
          || before.element.position.width !== after.element.position.width
          || before.element.position.y !== after.element.position.y
          || before.element.position.x !== after.element.position.x); */

        if (
          beforeExists &&
          (!afterExists || different) &&
          !disabledEvents.value.includes('mouseout')
        ) {
          const elementTitleBefore = c.titleCase(before.element.name)
          emit(`mouseout${elementTitleBefore}`, before)
        }
        if (
          afterExists &&
          (!beforeExists || different) &&
          !disabledEvents.value.includes('mouseover')
        ) {
          const elementTitleAfter = c.titleCase(after.element.name)
          emit(`mouseover${elementTitleAfter}`, after)
        }
      },
      {
        flush: 'pre'
      }
    )

    const $this = getCurrentInstance().proxy
    const handleWindowScroll = async () => {
      eventCache.value.mousemoveElement = null
      if (mousemoveEvent.value) {
        $this.$nextTick(() => {
          c.throttle(
            () => {
              eventCache.value.mousemoveElement = { ...getEventElement(mousemoveEvent.value) }
            },
            { delay: 50, debounce: true }
          )
          c.throttle(
            () => {
              eventCache.value.mousemoveElement = { ...getEventElement(mousemoveEvent.value) }
            },
            { delay: 200 }
          )
        })
      }
    }

    const emitForEvent = (event, eventCoords) => {
      const titleCase = c.titleCase(eventCoords.element.name)
      let eventType = event.type

      if (
        (event.type === 'mouseup' ||
          event.type === 'mousedown' ||
          event.type === 'click' ||
          event.type === 'dblclick') &&
        event.button !== 0
      ) {
        eventType = 'contextmenu'
      }

      const toEmit = { ...eventCoords, originalEvent: event }
      // 'mouseupCanvas' etc
      const genericEventName = `${eventType}Canvas`
      emit(genericEventName, toEmit)

      // 'clickColHeading' etc
      const eventName = `${eventType}${titleCase}`
      emit(eventName, toEmit)

      return event.preventDefault()
    }

    const handleCanvasEvent = (event) => {
      const eventType = event.type
      if (eventType === 'drop') {
        event.preventDefault()
        event.stopPropagation()
      }
      if (disabledEvents.value.includes(eventType)) return false
      if (disableMouse.value) return false
      const eventCoords = getEventElement(event)

      const titleCase = c.titleCase(eventCoords.element.name)
      const titleType = c.titleCase(eventType)
      const functionName = `handle${titleCase}${titleType}`

      // clear out disabled:
      if (eventType === 'click') disabledEvents.value = []

      // handleCellClick, handleColHeadingHover etc
      if (typeof functionName === 'function') {
        eval(`if (${functionName}) ${functionName}(eventCoords);`)
      }

      // 'clickElement', 'mouseoverElement' etc
      const cacheEventVar = `${eventType}Element`
      eventCache.value[cacheEventVar] = eventCoords

      // clickEvent, mouseoverEvent etc
      const coordsCacheVar = `${eventType}Event`
      eventCache.value[coordsCacheVar] = event

      return emitForEvent(event, eventCoords)
    }

    const eventsToHandle = [
      'mousedown',
      'mouseup',
      'mousemove',
      'click',
      'dblclick',
      'contextmenu'
      // 'dragover',
      // 'drop'
    ]

    onMounted(() => {
      const refCanv = canvas.value
      eventsToHandle.forEach((eventType) => {
        refCanv?.addEventListener(eventType, handleCanvasEvent)
      })

      window.addEventListener('scroll', handleWindowScroll)
      window.addEventListener('wheel', handleWindowScroll)
      window.addEventListener('click', handleWindowScroll)
    })

    onBeforeUnmount(() => {
      const refCanv = canvas.value
      eventsToHandle.forEach((eventType) => {
        refCanv?.removeEventListener(eventType, handleCanvasEvent)
      })

      window.removeEventListener('scroll', handleWindowScroll)
      window.removeEventListener('wheel', handleWindowScroll)
      window.removeEventListener('click', handleWindowScroll)
    })

    return {
      eventCache,
      mousemoveElement,
      mousemoveEvent,
      disableEventType,
      enableEventType,
      disabledEvents
    }
  }
}
