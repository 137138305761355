<template>
  <div
    class="!pt-6 max-w-[1200px] mx-auto"
    :class="{
      'opacity-50 pointer-events-none': loading
    }"
    :key="presentationTemplateId"
  >
    <h2>Theme settings</h2>

    <div class="flex flex-col w-full gap-12">
      <div class="flex flex-wrap items-start justify-start gap-x-20 mt-5">
        <div class="flex flex-col justify-start items-start w-60">
          <span :class="labelClass">Background image</span>
          <ImageSelector
            size="md"
            classes="flex items-start justify-start w-full"
            :value="backgroundFileId"
            @input="(v) => (backgroundFileId = v)"
          >
            <template #upload="{ change }">
              <div
                @click="change"
                class="bg-surface-300/40 cursor-pointer flex flex-col h-24 w-24 justify-center hover:bg-surface-300 rounded-md items-center"
              >
                <font-awesome-icon class="text-surface-900" icon="plus" />
                <span class="text-surface-900 leading-tight text-center text-xs font-light"
                  >Add</span
                >
              </div>
            </template>
          </ImageSelector>
        </div>

        <div class="flex flex-col justify-start items-start w-60 max-w-60">
          <span :class="labelClass">Hero image</span>
          <ImageSelector
            size="md"
            classes="flex items-center justify-center w-full"
            :value="headingFileId"
            @input="(v) => (headingFileId = v)"
          >
            <template #upload="{ change }">
              <div
                @click="change"
                class="bg-surface-300/40 cursor-pointer flex flex-col h-24 w-24 justify-center hover:bg-surface-300 rounded-md items-center"
              >
                <font-awesome-icon class="text-surface-900" icon="plus" />
                <span class="text-surface-900 leading-tight text-center text-xs font-light"
                  >Add</span
                >
              </div>
            </template>
          </ImageSelector>
        </div>

        <div class="flex flex-col justify-start items-start w-60 max-w-60">
          <span :class="labelClass">Logo image</span>
          <ImageSelector
            size="md"
            classes="flex items-start justify-start w-full"
            v-model="logoFileId"
            @input="(v) => (logoFileId = v)"
          >
            <template #upload="{ change }">
              <div
                @click="change"
                class="bg-surface-300/40 cursor-pointer flex flex-col h-24 w-24 justify-center hover:bg-surface-300 rounded-md items-center"
              >
                <font-awesome-icon class="text-surface-900" icon="plus" />
                <span class="text-surface-900 leading-tight text-center text-xs font-light"
                  >Add</span
                >
              </div>
            </template>
          </ImageSelector>
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <span :class="labelClass">Credentials</span>
          <span :class="subLabelClass"
            >Build trust with your customer by listing business licence number, insurance number tax
            number or other credentials.</span
          >
        </div>

        <div class="max-w-[500px]">
          <div class="flex flex-col gap-1 pt-2 mt-1">
            <div
              v-for="(prop, index) in creds"
              :key="`${prop[0]}-${index}`"
              class="flex items-center justify-start py-1 px-0 rounded w-fit relative group"
            >
              <font-awesome-icon
                fixed-width
                icon="badge-check"
                :class="['text-lg !w-6 shrink-0', { 'group-hover:hidden': true }]"
              />
              <button
                class="hidden group-hover:block cursor-pointer"
                @click.stop.prevent="removeCredential(index)"
              >
                <font-awesome-icon
                  icon="trash"
                  fixed-width
                  class="text-deep-red-400 !w-6 shrink-0"
                />
              </button>

              <div class="flex flex-col ml-1">
                <StringField
                  :classes="['text-xs font-medium leading-none -ml-0.5']"
                  :value="creds[index][0]"
                  @input="(v) => setCred(index, 0, v)"
                  placeholder="Credential name"
                />
                <StringField
                  :classes="['text-sm text-pitch-back leading-none font-light -ml-0.5 -mt-1.5']"
                  :value="creds[index][1]"
                  @input="(v) => setCred(index, 1, v)"
                  placeholder="Credential ID or #"
                />
              </div>
            </div>

            <div
              @click="addCredential"
              class="bg-surface-200 rounded-sm flex justify-center items-center w-16 gap-2 cursor-pointer py-2 hover:bg-blue-print-400 hover:text-surface-0 !h-fit"
              v-tooltip="
                'Add credential, licence or insurance number? Your client won\'t see this button'
              "
            >
              <font-awesome-icon icon="badge-check" />
              <font-awesome-icon icon="plus" />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <span :class="labelClass">Recognition</span>
          <span :class="subLabelClass"
            >Show off your awards, recognition, membership, badges or seals.</span
          >
        </div>

        <div class="mt-2">
          <FileList v-model="badges" :id-list="true" />
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <span :class="labelClass">Attachments</span>
          <span :class="subLabelClass"
            >Include attachments by default for every proposal that uses this theme.</span
          >
        </div>

        <div class="mt-2">
          <FileList v-model="alwaysIncludedFileIds" :id-list="true" />
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <span :class="labelClass">Default send message</span>
          <span :class="subLabelClass"
            >Create a personalized plain-text message that will pre-populate when you send a
            proposal that uses this theme.</span
          >
        </div>

        <div class="mt-2 bg-surface-100/50 p-2 rounded-sm z-[499]">
          <StringField v-model="defaultChangeOrderMessage" multiline />
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <span :class="labelClass">Cover letter</span>
          <span :class="subLabelClass"
            >Design rich text and content that goes right at the top of your proposal for your
            client to see.</span
          >
        </div>

        <div class="mt-2 bg-surface-100/50 p-2 rounded-sm relative overflow-visible">
          <NotionEditor
            :class="['z-[499] -ml-[42px]', {}]"
            v-model="coverLetter"
            placeholder="Type or paste content to add a cover letter that will be applied to every project using this theme!"
            tooltip="Cover letter (theme)"
          />
        </div>
      </div>

      <div v-if="termsAndConditions">
        <div class="flex flex-col">
          <span :class="labelClass">Presentation-specific terms & conditions</span>
          <span :class="subLabelClass"
            >Set terms and conditions here that apply ONLY to proposals that use this presentation.
            Your client will need to accept these terms in order to accept your
            quote/estimate/proposal.</span
          >
          <span :class="subLabelClass"
            >This feature has been deprecated. Please put all your terms and conditions in the
            company-wide terms and conditions in estimating settings.</span
          >
        </div>

        <div class="mt-2 bg-surface-100/50 p-2 rounded-sm">
          <Field v-model="termsAndConditions" type="textarea" />
        </div>
      </div>

      <div>
        <div class="flex flex-col">
          <span :class="labelClass">Estimate visibility settings</span>
          <span :class="subLabelClass"
            >Set the default visibility settings for pricing, quantity, and other aspects of this
            estimate. These can be overridden on individual items from the presentation view.
          </span>
        </div>

        <div class="mt-2">
          <card-list>
            <card-list-field>
              <span>
                <span
                  >Show project taxes and subtotal
                  <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
                /></span>
              </span>
              <Toggle
                severity="info"
                :value="showSubtotal ?? 1"
                @input="
                  (v) => {
                    showSubtotal = v
                  }
                "
              />
            </card-list-field>

            <card-list-field>
              <span>
                <span>Expand assemblies by default? </span>
              </span>
              <Toggle
                severity="info"
                :value="assemblyInitialState ?? 0"
                @input="(v) => (assemblyInitialState = v)"
              />
            </card-list-field>
            <!--        <card-list-field >-->
            <!--          <span>-->
            <!--            <span >Override assembly-level presentation settings</span>-->
            <!--            <small>-->
            <!--              When enabled, the settings in this presentation will override all localized itemized-->
            <!--              pricing settings that are set on an assembly-by-assembly basis.-->
            <!--            </small>-->

            <!--            <small>-->
            <!--              When this is disabled, assembly-level itemized pricing-->
            <!--              <strong>and</strong> presentation-level itemized pricing must <strong>both</strong> be-->
            <!--              enabled in order for it to show.-->
            <!--            </small>-->

            <!--            <small>-->
            <!--              This is useful for example, if you are creating a master document that you can-->
            <!--              download for your internal use where you want to show the quantities, costs and prices-->
            <!--              even in assemblies where itemized pricing is turned off for the client's view.-->
            <!--            </small>-->
            <!--          </span>-->

            <!--          <Toggle severity="info" v-model="forceTemplateSettings" />-->
            <!--        </card-list-field>-->

            <card-list-field>
              <span>
                <span
                  >Show quantities by default
                  <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
                /></span>
              </span>
              <Toggle severity="info" v-model="showQuantities" />
            </card-list-field>

            <card-list-field>
              <span>
                <span>
                  Show item/assembly pricing by default
                  <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
                /></span>
                <small>
                  If disabled, then only the total price of the proposal will be shown. Can be
                  overridden on an item by item basis.
                </small>
              </span>

              <Toggle severity="info" v-model="showItemizedPrices" />
            </card-list-field>

            <template v-if="showItemizedPrices">
              <card-list-field>
                <span>
                  <span class="flex"
                    >Show line item prices by default
                    <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
                  /></span>

                  <small> Can be overridden on an item by item basis. </small>
                </span>

                <Toggle severity="info" v-model="showCostItemPrices" />
              </card-list-field>

              <card-list-field>
                <span>
                  <span class="flex"
                    >Show assembly prices by default
                    <Tag class="ml-2" severity="info" fill="solid" value="Recommended "
                  /></span>
                  <small> Can be overridden on an assembly by assembly basis. </small>
                </span>

                <Toggle severity="info" v-model="showAssemblyPrices" />
              </card-list-field>
            </template>

            <!--            <card-list-field>-->
            <!--              <span>-->
            <!--                <span>Show item specific taxes</span>-->
            <!--                <small>-->
            <!--                  Display the taxes breakdown or bundle into a general tax rate. If disabled, only-->
            <!--                  the total tax amount is shown.-->
            <!--                </small>-->
            <!--              </span>-->
            <!--              <Toggle severity="info" v-model="showItemSpecificTax" />-->
            <!--            </card-list-field>-->
          </card-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineAsyncComponent } from 'vue'
import Tag from 'primevue/tag'
import { usePresentationManager } from '@/components/quote/presentation/usePresentationManager.js'
import FileList from '@/components/ui/FileList.vue'
import ImageSelector from '@/components/ui/ImageSelector.vue'
import StringField from '@/components/ui/Calculator/StringField.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const NotionEditor = defineAsyncComponent(
  () => import('@/components/ui/DocumentEditor/NotionEditor.vue')
)
NotionEditor.compatConfig = { MODE: 3 }
const props = defineProps({
  tab: {
    type: String,
    default: 'settings-presentation'
  },
  refId: {
    required: true
  },
  store: {
    default: 'Quote',
    required: true
  },
  type: {
    default: 'quote'
  }
})

const {
  alwaysIncludedFileIds,
  badges,
  backgroundFileId,
  coverLetter,
  termsAndConditions,
  defaultChangeOrderMessage,
  creds,
  setCred,
  addCredential,
  removeCredential,
  showSubtotal,
  assemblyInitialState,
  showQuantities,
  showItemizedPrices,
  showCostItemPrices,
  showAssemblyPrices,
  // showDocumentDescriptions,
  headingFileId,
  logoFileId,
  presentationTemplateId,
  loading
  // templateFull
} = usePresentationManager({
  refId: props.refId,
  store: props.store,
  autoSave: true
})

const labelClass = 'mt-2 text-base font-medium text-xl'
const subLabelClass = 'text-sm text-surface-500'
</script>

<style lang="scss" rel="stylesheet/scss"></style>
