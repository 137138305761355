<template>
  <CardList class="h-full w-full">
    <card-list-field>
      <div class="!w-full !max-w-full">
        <span> Feature product name </span>
        <small class="text-info"> Name of the feature product. </small>
        <field class="!w-full !max-w-full" :validate="{ required: true }" v-model="productName" />
      </div>
    </card-list-field>
    <card-list-field>
      <div class="!w-full !max-w-full">
        <span> Feature product Description </span>
        <small class="text-info"> Description of the feature product. </small>
        <field
          class="!w-full !max-w-full"
          :validate="{ required: true }"
          v-model="productDescription"
        />
      </div>
    </card-list-field>
    <card-list-field>
      <span>
        Default stripe product
        <small class="text-info"> The Stripe Product associated with this feature product. </small>
      </span>
      <Choose
        :force="true"
        :return-array="false"
        v-model="defaultStripeProductId"
        schema="subscription:mock"
        searchMethod="searchStripeProducts"
        :formatter="
          (product) => ({
            html: `${product.name} (${product?.default_price?.type} ${product.default_price?.unit_amount || 'n/a'} ${product?.default_price?.currency})`,
            text: `${product.name} (${product?.default_price?.type} ${product.default_price?.unit_amount || 'n/a'} ${product?.default_price?.currency})`,
            value: product.id
          })
        "
        :filters="{ country: 'usa' }"
      />
    </card-list-field>
  </CardList>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import CardList from '@/components/ui/Cards/CardList.vue'

// ===== composables ===== //
const store = useStore()
// =================== //

// ===== props ===== //
const props = defineProps({
  id: {
    type: String,
    required: false,
    default: ''
  },
  name: {
    type: String,
    required: false,
    default: ''
  },
  description: {
    type: String,
    required: false,
    default: ''
  },
  stripeId: {
    type: String,
    required: false,
    default: ''
  }
})
// =================== //

// ===== emits ===== //
const emit = defineEmits(['isDirty'])
// =================== //

// ===== data ===== //
const productName = ref(props.name)
const productDescription = ref(props.description)
const defaultStripeProductId = ref(props.stripeId)
// =================== //

// ===== computed ===== //
const isDirty = computed(() => {
  return Boolean(
    productName.value &&
      (productName.value !== props.name ||
        productDescription.value !== props.description ||
        defaultStripeProductId.value !== props.stripeId)
  )
})
// =================== //

// ===== methods ===== //
const save = () => {
  if (props.id) {
    return store.dispatch('Subscription/editFeatureProduct', {
      id: props.id,
      featureProductName: productName.value,
      featureProductDescription: productDescription.value,
      defaultStripeProductId: defaultStripeProductId.value
    })
  }
  return store.dispatch('Subscription/createFeatureProduct', {
    featureProductName: productName.value,
    featureProductDescription: productDescription.value,
    defaultStripeProductId: defaultStripeProductId.value
  })
}
// =================== //

// ===== expose ===== //
defineExpose({
  save
})
// =================== //

// ===== watch ===== //
watch(isDirty, (val) => {
  emit('isDirty', val)
})
// =================== //
</script>
