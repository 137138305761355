<template>
  <div
    id="price-summary"
    class="bg-flame-white text-base md:font-light md:rounded-sm md:border-t-2 md:border-l-2 md:border-surface-200 p-4 md:p-6"
    ref="quoteSummary"
  >
    <div class="mb-12">
      <ReviewAll
        :presentationSettings="presentationSettings"
        :normalized="normalized"
        :parentRefId="normRefId"
        :isPriceSummary="true"
      />
    </div>
    <div class="flex flex-col justify-start gap-1">
      <div class="w-full flex flex-col gap-1">
        <div class="w-full flex justify-between tabular-nums">
          <span>{{ l('Items included') }}</span>
          <span>{{ includedItems }}</span>
        </div>
        <div class="w-full flex justify-between tabular-nums" v-if="excludedItems">
          <span>{{ l('Items excluded') }}</span>
          <span>{{ excludedItems }}</span>
        </div>
        <div class="w-full flex justify-between tabular-nums" v-if="optionalItems">
          <span>{{ l('Optional items') }}</span>
          <span>{{ optionalItems }}</span>
        </div>
        <div class="w-full flex justify-between tabular-nums" v-if="totalItems">
          <span>{{ l('Total items') }}</span>
          <span>{{ totalItems }}</span>
        </div>
      </div>
      <div>
        <div
          class="w-full flex justify-between"
          v-if="quote_profit_net >= 0.01 && mergedSettings.showBundledProfit"
        >
          <span>{{
            l(
              typeof mergedSettings.showBundledProfit === 'string'
                ? mergedSettings.showBundledProfit
                : 'Management fee'
            )
          }}</span>
          <span>
            {{ currency(quote_price_net - quote_total_cost_net + (quote_discount_net || 0)) }}</span
          >
        </div>
        <div class="w-full flex flex-col gap-1">
          <div class="w-full flex justify-between tabular-nums" v-if="quote_discount_net > 0.0">
            <span>{{ l('Discount') }}</span>
            <span class="discount">-{{ currency(quote_discount_net) }}</span>
          </div>
          <div
            class="w-full flex justify-between tabular-nums"
            v-if="quote_price_tax > 0 && showSubtotal"
          >
            <span>{{ l('Subtotal') }}</span>
            <span>{{ currency(quote_price_net) }}</span>
          </div>
          <hr v-if="(taxes.length || quote_price_tax) && showSubtotal" class="my-3" />
          <template v-if="presentationSettings.showItemSpecificTax && showSubtotal">
            <div v-for="tax in taxes" :key="tax.key">
              <div class="w-full flex justify-between tabular-nums">
                <span>{{ tax.name }}</span>
                <span style="white-space: nowrap">{{ currency(tax.sum) }}</span>
              </div>
              <span
                class="text-sm md:text-xs text-surface-600 md:text-surface-500"
                v-if="languageSelected.includes('en_')"
              >
                Tax on {{ friendlyNameTaxOn(tax.on) }} derived from
                {{ friendlyNameTaxType(tax.type) }}
              </span>
            </div>
          </template>
          <template v-else-if="showSubtotal">
            <div class="w-full flex justify-between tabular-nums">
              <span>{{ l('Tax') }}</span>
              <span class="tabular-nums">{{ currency(quote_price_tax) }}</span>
            </div>
          </template>
          <hr class="my-3" />
          <div class="w-full flex justify-between font-medium mr-6 tabular-nums">
            <span>{{ l('Estimated price') }}</span>
            <span class="tabular-nums">{{ currency(quote_price_gross) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import TranslationMixin from './languages/TranslationMixin'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields'
import { useMediaQuery } from '@/composables/mediaQuery'
import { useStore } from 'vuex'
import UseSelections from '@/components/composables/UseSelections'
import ReviewAll from '@/components/quote/review/ReviewAll.vue'
import { formatCurrencySymbol } from '@/components/mixins/CurrencyFilter'

export default defineComponent({
  name: 'PriceSummary',
  components: { ReviewAll },
  mixins: [TranslationMixin],
  props: {
    changeOrderNotApproved: Boolean,
    isInQuoteEditor: Boolean,
    countOptions: Number,
    exclusions: Array,
    presentationSettings: Object,
    mergedSettings: Object,
    quoteId: Number,
    normRefId: String,
    normalized: Object
  },
  emits: ['close-summary'],
  setup(props, { emit }) {
    const store = useStore()

    const currency = formatCurrencySymbol

    const quoteRefId = computed(() => props.normRefId)
    const norm = computed(() => props.normalized)

    const { smallFormat } = useMediaQuery()

    const selections = UseSelections(norm.value)

    const hiddenRefs = selections.hiddenRefs.value

    const filteredRefs = computed(() =>
      selections.allRefs.value.filter((ref) => !hiddenRefs.includes(ref))
    )

    const totalItems = computed(() => filteredRefs.value.length - selections.metaRefs.value.length)

    const optionalItems = computed(
      () => selections.optionalRefs.value.filter((ref) => !hiddenRefs.includes(ref)).length
    )

    const excludedItems = computed(
      () => selections.excludedRefs.value.filter((ref) => !hiddenRefs.includes(ref)).length
    )

    const includedItems = computed(
      () => totalItems.value - optionalItems.value - excludedItems.value
    )

    const showSubtotal = computed(() => props.presentationSettings.showSubtotal ?? true)

    const {
      quote_profit_net,
      quote_total_cost_net,
      quote_discount_net,
      quote_price_tax,
      quote_price_net,
      quote_tax,
      quote_price_gross,
      currency_iso,
      provinceId,
      taxId,
      oTaxSums
    } = EntityComputedFields.useEntityComputedFields({
      refId: quoteRefId.value,
      type: 'quote',
      store: 'Quote'
    })

    const taxes = computed(() =>
      Object.values(oTaxSums.value).filter(
        (tx) => Math.abs(tx.pcnt) >= 0.01 && Math.abs(tx.sum) >= 0.01
      )
    )

    const emitCloseSummary = () => {
      emit('close-summary')
    }

    const friendlyNameTaxOn = (taxOn) => {
      if (taxOn === 'all') return 'prices'
      if (taxOn === 'cost') return 'costs'
      if (taxOn === 'profit') return 'profits'

      return taxOn
    }

    const friendlyNameTaxType = (taxType) => {
      if (taxType === 'ihlt') return 'labor'
      if (taxType === 'mt') return 'materials'
      if (taxType === 'slt') return 'subcontracting'

      return 'general'
    }

    const priceSummaryClasses = computed(() => {
      if (smallFormat.value) {
        return '!right-0 max-w-screen w-screen inset-x-0 bottom-0'
      }
      if (props.changeOrderNotApproved && !props.isInQuoteEditor) {
        return 'right-64 max-w-[450px] bottom-[90px]'
      }
      return 'right-4 max-w-[450px] bottom-[90px]'
    })

    const zIndex = computed(() => {
      return store?.state?.modal?.topZIndex + 1000
    })

    const positioningClasses = computed(() => {
      return props.changeOrderNotApproved && !props.isInQuoteEditor ? 'right-64' : 'right-4'
    })

    return {
      smallFormat,
      quote_profit_net,
      quote_total_cost_net,
      quote_discount_net,
      quote_price_tax,
      quote_price_net,
      quote_tax,
      quote_price_gross,
      currency_iso,
      taxes,
      emitCloseSummary,
      friendlyNameTaxOn,
      friendlyNameTaxType,
      priceSummaryClasses,
      zIndex,
      positioningClasses,
      optionalItems,
      excludedItems,
      includedItems,
      totalItems,
      provinceId,
      taxId,
      hiddenRefs,
      currency,
      showSubtotal
    }
  }
})
</script>
