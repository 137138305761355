import { ref, computed, onBeforeUnmount, toValue, isRef } from 'vue'

import { useStore } from 'vuex'
import eventBus from '@/eventBus.js'
import Changes from '../../../imports/api/Changes/Changes.js'

export default {
  useChangeWatcher(args) {
    const {
      store: rstore = null,
      refId: rrefid,
      changeHandler = () => {},
      resetHandler = () => {},
      $store = useStore(),
      norm = computed(() => $store.state[rstore]?.normalized)
    } = args

    if (!isRef(norm)) throw new Error('norm must be a ref')

    const store = computed(() => toValue(rstore))
    const usingStore = computed(() => !!store.value)
    const refId = computed(() => toValue(rrefid))
    const unauditedChanges = computed(() => $store.state[store.value].unauditedChanges)
    const isAuditing = computed(() => $store.state[store.value].auditing)
    const isProcessing = computed(() => $store.state[store.value].processing)
    const loading = computed(
      () =>
        c.divide(
          1,
          (Object.keys(unauditedChanges.value).length || isAuditing.value || isProcessing.value) * 2
        ) * 100
    )

    const listening = ref(true)

    const ignore = () => {
      listening.value = false
    }

    const listen = () => {
      listening.value = true
    }

    const changeManager = ref(null)

    const attempts = ref(0)

    const stateChangeHandler = async (cm, refIds, changes, isDirtyFn, added, removed) => {
      if (!listening.value) return null

      const isDirty = (
        await c.throttle(async () => ({ dirty: await isDirtyFn() }), { key: cm.uid, delay: 200 })
      ).dirty
      changeHandler({ changeManager: cm, refIds, changes, isDirty, added, removed })
    }

    const stateResetHandler = async (cm, refIds, changes, isDirtyFn, added, removed) => {
      if (!listening.value) return null

      const isDirty = (
        await c.throttle(async () => ({ dirty: await isDirtyFn() }), { key: cm.uid, delay: 200 })
      ).dirty
      resetHandler({ changeManager: cm, refIds, changes, isDirty, added, removed })
    }

    const initiateStateWatcher = (cm = null) => {
      if (!usingStore.value || !cm) {
        changeManager.value = new Changes(norm.value, norm.value, 'root')
        changeManager.value.addChangeWatcher(stateChangeHandler)
        changeManager.value.addResetWatcher(stateResetHandler)
        eventBus.$emit('sheet-change-watcher-active')
      } else {
        changeManager.value = cm
        $store.commit({
          type: `${store.value}/ADD_CHANGE_WATCHER`,
          changeManager: cm,
          watcher: stateChangeHandler
        })
        $store.commit({
          type: `${store.value}/ADD_RESET_WATCHER`,
          changeManager: cm,
          watcher: stateResetHandler
        })
        eventBus.$emit('sheet-change-watcher-active')
      }
    }

    const getChangeManager = async () => {
      if (!usingStore.value) initiateStateWatcher()
      else {
        attempts.value += 1
        let cm
        if (
          !refId.value ||
          !(cm = await $store.dispatch(`${store.value}/getChangeManager`, { refId: refId.value }))
        ) {
          if (attempts.value > 10) {
            console.error('ChangeManager not found')
            return
          }

          setTimeout(() => {
            getChangeManager()
          }, 1000)

          return
        }

        initiateStateWatcher(cm)
      }
    }

    onBeforeUnmount(() => {
      if (usingStore.value) {
        if (changeManager.value && stateChangeHandler) {
          $store.commit({
            type: `${store.value}/REMOVE_CHANGE_WATCHER`,
            changeManager: changeManager.value,
            watcher: stateChangeHandler
          })
        }

        if (changeManager.value && stateResetHandler) {
          $store.commit({
            type: `${store.value}/REMOVE_RESET_WATCHER`,
            changeManager: changeManager.value,
            watcher: stateResetHandler
          })
        }
      } else if (changeManager.value) {
        if (changeManager.value.removeChangeWatcher)
          changeManager.value.removeChangeWatcher(stateChangeHandler)
        if (changeManager.value.removeResetWatcher)
          changeManager.value.removeResetWatcher(stateResetHandler)
      }
    })

    return {
      loading,
      listen,
      ignore,
      changeManager,
      initiate: () => getChangeManager()
    }
  }
}
