<template>
  <div class="flex flex-col gap-2 mx-4">
    <!-- Format -->
    <div v-if="value.formats" class="flex flex-col gap-2">
      <div class="text-md font-medium">Format</div>
      <div v-for="(format, index) in Object.keys(value.formats)" :key="index">
        <div class="flex flex-row gap-3">
          <!-- eslint-disable -->
          <Checkbox v-model="value.formats[format].search" @input="setUnapplied(true)" />
          <div>{{ value.formats[format].name }}</div>
        </div>
      </div>
    </div>

    <!-- Vendors -->
    <div v-if="value.vendors" class="flex flex-col gap-2">
      <div class="text-md font-medium mt-6">AutoCost suppliers</div>
      <div v-for="(vendor, index) in Object.keys(value.vendors)" :key="index">
        <div class="flex flex-row gap-3">
          <!-- eslint-disable -->
          <Checkbox v-model="value.vendors[vendor].search" @input="setUnapplied(true)" />
          <div>{{ value.vendors[vendor].vendorName }}</div>
        </div>
      </div>
    </div>

    <Btn
      v-show="unapplied"
      severity="bolster"
      size="md"
      class="mt-4 animate-fadeIn"
      @click="applyFilters"
    >
      Apply filters
    </Btn>
  </div>
</template>

<script>
export default {
  name: 'TraverseAutoCostFilters',
  emits: ['distanceInput'],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      unapplied: false
    }
  },
  methods: {
    setUnapplied(value) {
      this.unapplied = value
    },
    applyFilters() {
      this.setUnapplied(false)
      this.$emit('applyFilters', this.value)
    }
  }
}
</script>
