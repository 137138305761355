<template>
  <div class="flex flex-col items-stretch justify-start gap-12">
    <card-section class="mt-0">
      <template #subLabel>
        <span class="font-sans text-base text-cool-gray-400 font-normal pl-2">
          {{ l('In order to continue, review items and terms below') }}.
        </span>
      </template>

      <div class="w-full flex flex-col gap-12">
        <ReviewAll
          v-if="$attrs && $attrs.normalized"
          :presentationSettings="$attrs.presentationSettings"
          :parentRefId="$attrs.parentRefId"
          :paymentTerms="paymentTerms"
          :normalized="$attrs.normalized"
        />

        <div>
          <h6 class="mt-0 text-xl font-medium leading-none">{{ l('Terms and conditions') }}</h6>
          <p class="mb-4 font-sans text-base text-cool-gray-400 font-normal">
            {{ l('Review and accept to the terms below') }}.
          </p>
          <Card class="terms-viewport-text shadow-none !border-1 border-cool-gray-200">
            <div>
              <p
                style="font-size: 0.9em !important; font-weight: 500 !important; line-height: 1.5"
                v-html="
                  termsHtml ||
                  `<ul><li>${l('You agree to accept and proceed with the project as detailed')}</li></ul>`
                "
              ></p>
            </div>

            <p class="text-sm font-normal text-cool-gray-400 mb-4">
              {{
                l(
                  `Please read the terms and conditions and indicate your acceptance by entering your name as a signature below, and
          clicking the "I accept the terms and conditions" button at the bottom of this checkout page.  Acceptance of the
          agreement means that you are bound by its terms. You will be provided with a copy of your signed agreement in your dashboard for your records that you can access at any time.`
                )
              }}
            </p>
          </Card>
        </div>

        <div class="flex justify-stretch items-start flex-wrap w-full">
          <div class="basis-1/2 shrink">
            <SignatureField :signature="signature" @signature="handleSignature" />
          </div>
          <div class="basis-1/2 shrink flex flex-col gap-4 pl-6">
            <div class="text-pitch-black mb-1 leading-tight flex gap-2">
              <font-awesome-icon icon="signature" size="2xl" fixed-width class="min-w-12" />
              You will be given access to your dashboard after approving where you can access your
              signed documents at any time.
            </div>

            <div class="text-pitch-black mb-1 leading-tight flex gap-2">
              <font-awesome-icon icon="comment" size="2xl" fixed-width class="min-w-12" />
              You can communicate directly with your contractor from your project portal, and from
              any device.
            </div>

            <div class="text-pitch-black mb-1 leading-tight flex gap-2">
              <font-awesome-icon icon="calendar" size="2xl" fixed-width class="min-w-12" />
              See what's happening and see what's coming up. Track and approve the progress of your
              project at any time from you project portal.
            </div>
          </div>
        </div>

        <div class=""></div>
      </div>
    </card-section>

    <div
      v-if="canUserApproveQuote && signature"
      class="flex flex-col-reverse xl:flex-row xl:justify-end xl:items-center gap-2 pb-20"
    >
      <btn severity="tertiary-borderless" @click="accept">
        {{ l('I do not accept the terms and conditions') }}
      </btn>
      <btn
        class="block py-3 bg-blue-print-400"
        :action="accept"
        size="2xl"
        :disabled="!signature"
        severity="info"
      >
        {{ l('I accept the terms and conditions') }}
      </btn>
    </div>

    <ScrollIndicator />
  </div>
</template>

<script>
import BodyMixin from '../mixins/Body'
import TranslationMixin from '../quote/presentation/languages/TranslationMixin'
import ReviewAll from '../quote/review/ReviewAll.vue'
import LoanMixin from '../mixins/LoanMixin'
import SignatureField from '@/components/ui/fields/SignatureField.vue'
import { useSession } from '@/stores/session'
import eventBus from '@/eventBus'
import PaymentRequestsMixin from '@/components/payments/PaymentRequestsMixin'
import ScrollIndicator from '@/components/ScrollIndicator.vue'

export default {
  name: 'BodyTerms',
  mixins: [BodyMixin, TranslationMixin, LoanMixin, PaymentRequestsMixin],
  components: {
    ReviewAll,
    SignatureField,
    ScrollIndicator
  },
  emits: ['termsAccepted', 'declined'],
  methods: {
    async accept() {
      const sessionStore = useSession()
      const { usedMagicLink } = sessionStore

      if (!this.canUserApproveQuote) return

      if (this.paymentTerms === 'loan') {
        await this.applyForLoan()
      }

      const inPersonApproval = this.$route.name === 'Presentation External'

      const hasPaymentsSetup = await this.getPaymentMethods()

      if ((usedMagicLink && hasPaymentsSetup) || inPersonApproval) {
        await this.authenticateUser()
      } else {
        await this.approveQuote()
      }
    },
    approveQuote() {
      this.termsAccepted({ terms: this.combinedPlainText, signature: this.signature })
    },
    decline() {
      if (!this.canUserApproveQuote) return

      this.$emit('declined')
    },
    updatePaymentTerms(terms) {
      this.paymentTerms = terms
    },
    handleSignature(signatureName) {
      this.signature = signatureName
    },
    async authenticateUser() {
      await this.$store.dispatch('Quote/twoFactorAuth', {
        refId: this.$store.state.session.quote.refId,
        go: false
      })
    },
    async getPaymentMethods() {
      const showAlert = false
      return await this.fetchPaymentMethods(showAlert)
    }
  },
  data() {
    return {
      paymentTerms: 'pay',
      signature: null
    }
  },
  computed: {
    genericTerms() {
      return ''
    },
    combinedPlainText() {
      return `${this.companyTermsPlainText || this.$store.state.session.company.company_terms_and_conditions || ''}
${this.additionalTerms}
${this.genericTerms}`.trim()
    },
    termsHtml() {
      const lines = c
        .removeHtml(this.combinedPlainText)
        .trim()
        .replace(/(?:\r\n|\r|\n){2,}/, '\r\n') // replace multi lines to just one line
        .split(/(?:\r\n|\r|\n)/g)

      let html = '<ul>'
      lines.forEach((line) => {
        if (line.trim() !== '') {
          html = `${html}${
            /^\[.*?\]/.test(line)
              ? `</ul><h6>${line.replace(/\[(.*?)\]/, '$1')}</h6><ul>`
              : `<li>${line}</li>`
          }`
        }
      })
      html = `${html}</ul>`

      return html.replace('<ul></ul>', '')
    },
    companyName() {
      return this.company || this.$store.state.session.company.company_name_short
    },
    canUserApproveQuote() {
      return (
        this.$route.name === 'Presentation' ||
        !this.$store.getters.isCompanyUser ||
        this.$store.state.session.user.user_is_super_user
      )
    }
  },
  props: {
    company: {
      // => companyName
      default: null
    },
    companyTermsPlainText: {
      default: null
    },
    additionalTerms: {
      default: null
    },
    termsAccepted: {
      type: Function,
      required: true
    }
  },
  mounted() {
    eventBus.$on('updatePaymentTerms', this.updatePaymentTerms)
    eventBus.$on('termsSaved', () => {
      this.termsAccepted({ terms: this.combinedPlainText, signature: this.signature })
    })
  },
  created() {
    this.paymentTerms = this.$attrs.paymentTerms
  },
  beforeUnmount() {
    eventBus.$off('updatePaymentTerms', this.updatePaymentTerms)
    eventBus.$off('termsSaved', this.approveQuote)
  }
}
</script>
