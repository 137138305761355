<template>
  <preview
    v-bind="$props"
    :object="object"
    @click="() => $emit('click')"
    :right="right"
    :avatar="avatar"
  >
    <template #image>
      <PersonAvatar
        :id="object.oClient.client_id"
        type="client"
        :name="object.oClient.client_name"
        class="mx-2"
      />
    </template>
    <template #primary>
      <div class="!text-pitch-black">Invoice #{{ object.invoice_id }}</div>
    </template>
    <template #secondary>
      <span class="!text-surface-400">{{ $f.currency(object.invoice_gross) }}</span>
    </template>
  </preview>
</template>

<script>
import Preview from './Base.vue'
import PreviewMixin from './PreviewMixin'

export default {
  mixins: [PreviewMixin],
  name: 'PreviewInvoice',
  emits: ['click'],
  data() {
    return {}
  },
  computed: {
    icon() {
      if (this.object && this.object.invoice_status === 'e') {
        return 'check'
      }
      return 'file-invoice'
    },
    classStatus() {
      return c.statusColors[this.object.invoice_status]
    },
    classStatusQuote() {
      return c.statusColors[this.object.oQuote.quote_status]
    },
    status() {
      if (
        this.object.invoice_time_due < new Date().valueOf() &&
        this.object.invoice_status === 'o'
      ) {
        return 'PAST DUE'
      }
      return c.format(this.object.invoice_status, 'status')
    },
    statusQuote() {
      return c.format(this.object.oQuote.quote_status, 'status')
    }
  },
  props: {
    object: {
      required: true
    }
  },
  methods: {
    openQuoteModal() {
      this.$store.dispatch('modal/open', {
        modal: {
          name: 'Quote',
          objects: [this.object.oQuote]
        }
      })
    },
    openClientModal() {
      this.$store.dispatch('modal/open', {
        modal: {
          name: 'Client',
          objects: [this.object.oClient]
        }
      })
    }
  },
  components: { Preview }
}
</script>

<style rel="stylesheet/scss" lang="scss"></style>
