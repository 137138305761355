import { ref, computed } from 'vue'

const STORAGE_KEY = 'counterparty_balances_meta'

export function useCounterpartyBalances() {
  const loaded = ref(false)
  const balanceCards = ref([])

  /**
   * Meta key for localStorage
   */
  const metaKey = computed(() => {
    return `${balanceCards.value
      .map((card) => card.title)
      .sort()
      .join('_')}`
  })

  /**
   * Sets card visibility and ordering based on local storage data.
   * If no local storage data is found, or it is outdated, sets defaults.
   */
  function setCardMeta() {
    if (!localStorage.getItem(STORAGE_KEY)) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({}))
    }
    let cardMeta = JSON.parse(localStorage.getItem(STORAGE_KEY))
    let balanceCardMeta = cardMeta[metaKey.value]

    // If no local storage for these cards is found, create defaults
    if (
      !balanceCardMeta ||
      _.isEmpty(balanceCardMeta) ||
      balanceCards.value.length !== Object.keys(balanceCardMeta).length
    ) {
      balanceCardMeta = {}
      balanceCards.value.forEach((card, index) => {
        balanceCardMeta[card.title] = {
          visible: card.visible,
          order: index
        }
      })
      cardMeta[metaKey.value] = balanceCardMeta
      localStorage.setItem(STORAGE_KEY, JSON.stringify(cardMeta))
    }

    // Otherwise, update cards with stored meta data
    else {
      // Update visibilities
      Object.keys(balanceCardMeta).forEach((key) => {
        const index = balanceCards.value.findIndex((card) => card.title === key)
        if (!balanceCards.value[index]) {
          localStorage.removeItem(STORAGE_KEY)
          return setCardMeta()
        }
        balanceCards.value[index].visible = balanceCardMeta[key].visible
        balanceCards.value[index].order = balanceCardMeta[key].order
      })
      // Sort based on ordering
      balanceCards.value = balanceCards.value.sort((a, b) => a.order - b.order)
    }
  }

  /**
   * Toggle card visibility and update localStorage
   */
  function toggleCardVisibility(card) {
    if (!localStorage.getItem(STORAGE_KEY)) return

    let cardMeta = JSON.parse(localStorage.getItem(STORAGE_KEY))
    let balanceCardMeta = cardMeta[metaKey.value]
    balanceCardMeta[card.title].visible = !card.visible

    cardMeta[metaKey.value] = balanceCardMeta
    localStorage.setItem(STORAGE_KEY, JSON.stringify(cardMeta))
    setCardMeta()
  }

  /**
   * Update card ordering and save to localStorage
   */
  function setCardOrdering(event) {
    if (!localStorage.getItem(STORAGE_KEY) || !event) return

    let cardMeta = JSON.parse(localStorage.getItem(STORAGE_KEY))
    let balanceCardMeta = cardMeta[metaKey.value]

    let index = 0
    event.from.children.forEach((element) => {
      balanceCardMeta[element.id].order = index
      index++
    })

    cardMeta[metaKey.value] = balanceCardMeta
    localStorage.setItem(STORAGE_KEY, JSON.stringify(cardMeta))
    setCardMeta()
  }

  /**
   * Initialize balance cards with data
   */
  function initializeBalanceCards(balances, isPayfacEnabled) {
    const cards = [
      {
        title: 'Account Balance',
        description: 'Processed payments ready for transfer',
        icon: 'wallet',
        type: 'currency',
        visible: true,
        size: 1,
        currentAmount: balances.total_balance || 0,
        previousAmount: null,
        timePeriod: '',
        biggerIsBetter: true
      },
      {
        title: 'Incoming funds',
        description: 'Payments approved but funds not yet available for payouts',
        icon: 'money-bill-transfer',
        type: 'currency',
        visible: true,
        size: 1,
        currentAmount: balances.total_pending || 0,
        previousAmount: null,
        timePeriod: '',
        biggerIsBetter: true
      },
      {
        title: isPayfacEnabled ? 'Funds withdrawn' : 'Transferring',
        description: 'Funds from your wallet are on their way to your bank account',
        icon: 'money-from-bracket',
        type: 'currency',
        visible: true,
        size: 1,
        currentAmount: balances.total_in_transit || 0,
        previousAmount: null,
        timePeriod: '',
        biggerIsBetter: true
      }
    ]

    if (!isPayfacEnabled) {
      cards.push({
        title: 'Deposited',
        description: 'Funds now available in your bank account',
        icon: 'university',
        type: 'currency',
        visible: true,
        size: 1,
        currentAmount: (balances.total_paid || 0) * -1,
        previousAmount: null,
        timePeriod: '',
        biggerIsBetter: true
      })
    }

    balanceCards.value = cards
    setCardMeta()
    loaded.value = true
  }

  return {
    loaded,
    balanceCards,
    initializeBalanceCards,
    toggleCardVisibility,
    setCardOrdering
  }
}
