export const SET_HIDE_NAV = 'SET_HIDE_NAV'
export const ALERT_ADD = 'ALERT_ADD'
export const ALERT_REMOVE = 'ALERT_REMOVE'

export const SET_DISABLE_SCROLLING = 'SET_DISABLE_SCROLLING'

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'
export const MODAL_CLOSE_ALL = 'MODAL_CLOSE_ALL'
export const MODAL_FOCUS = 'MODAL_FOCUS'
export const FILE_MODAL_ALTER = 'FILE_MODAL_ALTER'

export const PHONE_OPEN = 'PHONE_OPEN'
export const PHONE_CLOSE = 'PHONE_CLOSE'
export const PHONE_SAVE = 'PHONE_SAVE'
export const ADD_DEVICE = 'ADD_DEVICE'
export const ADD_CONNECTION = 'ADD_CONNECTION'
export const CHANGE_TAGS = 'CHANGE_TAGS'
export const PHONE_READY = 'PHONE_READY'
export const SET_DISABLE_CLICKAWAY = 'SET_DISABLE_CLICKAWAY'
export const SET_ENABLE_CLICKAWAY = 'SET_ENABLE_CLICKAWAY'

export const VIDEO_OPEN = 'VIDEO_OPEN'
export const VIDEO_CLOSE = 'VIDEO_CLOSE'
export const VIDEO_UPDATE = 'VIDEO_UPDATE'
export const VIDEO_CREATE = 'VIDEO_CREATE'

export const SEARCH_OPEN = 'SEARCH_OPEN'
export const SEARCH_CLOSE = 'SEARCH_CLOSE'
export const SEARCH_SET_Z = 'SEARCH_SET_Z'

/**
 * Types that are namespaced based on the object,
 *   The object type/ namespace is in camelCase.
 *   for example: 'quote/ADD_FETCHED' and 'costType/SET_SELECTED'
 */
export const CLEAR = 'CLEAR'

export const ADD_FETCHED = 'ADD_FETCHED'
export const ADD_FETCHED_SET = 'ADD_FETCHED_SET'
export const REMOVE_FETCHED = 'REMOVE_FETCHED'
export const UNSET_FETCHED = 'UNSET_FETCHED'
export const SET_AUDITING = 'SET_AUDITING'
export const SET_PROCESSING = 'SET_PROCESSING'

export const ADD_CHANGES = 'ADD_CHANGES'
export const ADD_EXPLICIT_CHANGES = 'ADD_EXPLICIT_CHANGES'
export const RESET_CHANGES = 'RESET_CHANGES'
export const SET_ORIGINAL = 'SET_ORIGINAL'
export const SET_WATCHERS = 'SET_WATCHERS'
export const DESTROY_WATCHERS = 'DESTROY_WATCHERS'
export const ENSURE_CHANGE_MANAGER = 'ENSURE_CHANGE_MANAGER'
export const REMOVE_CHANGE_MANAGER = 'REMOVE_CHANGE_MANAGER'
export const ADD_CHANGE_WATCHER = 'ADD_CHANGE_WATCHER'
export const REMOVE_CHANGE_WATCHER = 'REMOVE_CHANGE_WATCHER'
export const SET_CURRENT_CHANGE_MANAGER = 'SET_CURRENT_CHANGE_MANAGER'
export const SET_ORIGINAL_CHANGE_MANAGER = 'SET_ORIGINAL_CHANGE_MANAGER'
export const RESET_CHANGE_MANAGER = 'RESET_CHANGE_MANAGER'
export const TRIGGER_WATCHERS_CHANGE_MANAGER = 'TRIGGER_WATCHERS_CHANGE_MANAGER'
export const ADD_RESET_WATCHER = 'ADD_RESET_WATCHER'
export const REMOVE_RESET_WATCHER = 'REMOVE_RESET_WATCHER'

export const ADD_NORMALIZED = 'ADD_NORMALIZED'
export const REMOVE_NORMALIZED = 'REMOVE_NORMALIZED'
export const REMOVE_ORPHANED = 'REMOVE_ORPHANED'
export const SET_NORMALIZED = 'SET_NORMALIZED'
export const UNSET_NORMALIZED = 'UNSET_NORMALIZED'

export const REGISTER_COMPONENT = 'REGISTER_COMPONENT'
export const DEREGISTER_COMPONENT = 'DEREGISTER_COMPONENT'

export const ADD_SELECTED = 'ADD_SELECTED'
export const REMOVE_SELECTED = 'REMOVE_SELECTED'
export const SET_SELECTED = 'SET_SELECTED'
export const UNSET_SELECTED = 'UNSET_SELECTED'
export const ADD_TRASH = 'ADD_TRASH'
export const EMPTY_TRASH = 'EMPTY_TRASH'

export const ADD_PROCESSING_HOLD = 'ADD_PROCESSING_HOLD'
export const REMOVE_PROCESSING_HOLD = 'REMOVE_PROCESSING_HOLD'

export const META = 'META'

export const FIELD = 'FIELD'
export const FIELDS = 'FIELDS'
export const SET_EXPLICIT_CHANGES = 'SET_EXPLICIT_CHANGES'
export const CLEAR_EXPLICIT_CHANGES = 'CLEAR_EXPLICIT_CHANGES'
export const FIELD_EQUATION = 'FIELD_EQUATION'
export const FIELD_EQUATION_UNSET = 'FIELD_EQUATION_UNSET'
export const FIELD_CHANGE_TRACKING = 'FIELD_CHANGE_TRACKING'

export const FETCHED_BASICS = 'FETCHED_BASICS'
export const SESSION_LOGIN = 'SESSION_LOGIN'
export const AUTH_FAILED = 'AUTH_FAILED'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'
export const SET_COUNTS = 'SET_COUNTS'
export const SET_BACKGROUND = 'SET_BACKGROUND'
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT'
export const SET_DEVICE_SIZE = 'SET_DEVICE_SIZE'
export const SET_APP_TYPE = 'SET_APP_TYPE'
export const CKEDITOR_ADDED = 'CKEDITOR_ADDED'
export const SET_FOCUS_STATUS = 'SET_FOCUS_STATUS'
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS'
export const SET_SLIDE_SUBNAV = 'SET_SLIDE_SUBNAV'
export const SET_SCOPE = 'SET_SCOPE'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_PERSISTENT_USER_TOKEN = 'SET_PERSISTENT_USER_TOKEN'
export const SET_SHORT_TOKEN = 'SET_SHORT_TOKEN'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_ID_TOKEN = 'SET_ID_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const SHIFT_REQUEST_QUEUE = 'SHIFT_REQUEST_QUEUE'
export const ADD_REQUEST_QUEUE = 'ADD_REQUEST_QUEUE'
export const REMOVE_REQUEST_QUEUE = 'REMOVE_REQUEST_QUEUE'
export const SET_SCOPABLE_OBJECTS = 'SET_SCOPABLE_OBJECTS'
export const SET_LOADING = 'SET_LOADING'
export const SET_USER_ANNOUNCEMENT_FLAG = 'SET_USER_ANNOUNCEMENT_FLAG'
export const SET_PREVIEW = 'SET_PREVIEW'

export const SET_CHANNELS_LOADING = 'SET_CHANNEL_LOADING'
export const SET_CHANNELS = 'SET_CHANNELS'
export const SET_CHANNEL_OBSERVER = 'SET_CHANNEL_OBSERVER'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATIONS_OBJECT = 'SET_NOTIFICATIONS_OBJECT'
export const SET_CHANNEL_NOTIFICATIONS = 'SET_CHANNEL_NOTIFICATIONS'
export const SET_CHANNEL_NOTIFICATIONS_OBJECT = 'SET_CHANNEL_NOTIFICATIONS_OBJECT'
export const SET_HAS_NOTIFICATIONS = 'SET_HAS_NOTIFICATIONS'
export const SET_NOTIFICATION_OBSERVER = 'SET_NOTIFICATION_OBSERVER'
export const ADD_CHANNEL_NOTIFICATION = 'ADD_CHANNEL_NOTIFICATION'
export const ADD_CHANNEL_NOTIFICATION_OBJECT = 'ADD_CHANNEL_NOTIFICATION_OBJECT'
export const ADD_CHANNEL = 'ADD_CHANNEL'
export const REMOVE_CHANNEL = 'REMOVE_CHANNEL'
export const SET_CHANNEL_ACTIVITIES_OBSERVER = 'SET_CHANNEL_ACTIVITIES_OBSERVER'
export const SET_CHANNEL_ACTIVITIES_NEXT_TOKEN = 'SET_CHANNEL_ACTIVITIES_NEXT_TOKEN'
export const SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN = 'SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN'
export const ADD_CHANNEL_ACTIVITY = 'ADD_CHANNEL_ACTIVITY'
export const UPDATE_CHANNEL_ACTIVITY = 'UPDATE_CHANNEL_ACTIVITY'
export const REMOVE_CHANNEL_ACTIVITY = 'REMOVE_CHANNEL_ACTIVITY'
export const PUSH_CHANNEL_ACTIVITY = 'PUSH_CHANNEL_ACTIVITY'
export const SET_CHANNEL_ACTIVITIES = 'SET_CHANNEL_ACTIVITIES'
export const SET_CHANNEL_HAS_MORE_ACTIVITIES = 'SET_CHANNEL_HAS_MORE_ACTIVITIES'
export const SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES = 'SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES'
export const ADD_SEEN_ACTIVITY = 'ADD_SEEN_ACTIVITY'
export const ADD_CHANNELS = 'ADD_CHANNELS'
export const REMOVE_CHANNELS = 'REMOVE_CHANNELS'
export const SET_CHANNEL_ACTIVITIES_CONNECTED = 'SET_CHANNEL_ACTIVITIES_CONNECTED'

export const ADD_UNAUDITED_CHANGES = 'ADD_UNAUDITED_CHANGES'
export const CLEAR_UNAUDITED_CHANGES = 'CLEAR_UNAUDITED_CHANGES'
export const SET_FORCE_AUDIT = 'SET_FORCE_AUDIT'
export const UNSET_FORCE_AUDIT = 'UNSET_FORCE_AUDIT'

export const ADD_EDIT_HOLD = 'ADD_EDIT_HOLD'
export const REMOVE_EDIT_HOLD = 'REMOVE_EDIT_HOLD'
export const SET_SEARCH_PHRASE = 'SET_SEARCH_PHRASE'

export const TRAVERSE_ALTER = 'TRAVERSE_ALTER'

export const SET_CHATS = 'SET_CHATS'
export const ADD_NEW_CHAT = 'ADD_NEW_CHAT'
export const ADD_NEW_MESSAGE_TO_CHAT = 'ADD_NEW_MESSAGE_TO_CHAT'
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES'
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING'
export const SET_LAST_TIME_ACTIVE = 'SET_LAST_TIME_ACTIVE'
export const ADD_CHAT_USER = 'ADD_CHAT_USER'
export const SET_SUBSCRIPTION_MESSAGE_OBSERVER = 'SET_SUBSCRIPTION_MESSAGE_OBSERVER'
export const SET_SUBSCRIPTION_CHAT_OBSERVER = 'SET_SUBSCRIPTION_CHAT_OBSERVER'
export const CLOSE_SUBSCRIPTIONS = 'CLOSE_SUBSCRIPTIONS'
export const TOGGLE_PAYWALL = 'TOGGLE_PAYWALL'
export const SAVE_PAYLOAD_REQUEST = 'SAVE_PAYLOAD_REQUEST'

export const BANNER_ADD = 'BANNER_ADD'
export const BANNER_TOGGLE = 'BANNER_TOGGLE'
export const BANNER_UPDATE = 'BANNER_UPDATE'
export const BANNER_CLOSE = 'BANNER_CLOSE'

export const SET_CLIENT_RATING_DATA = 'SET_CLIENT_RATING_DATA'
export const SET_CONTRACTOR_RATING_DATA = 'SET_CONTRACTOR_RATING_DATA'
export const UPDATE_CLIENT_OWN_RATINGS = 'UPDATE_CLIENT_OWN_RATINGS'
export const UPDATE_CONTRACTOR_OWN_RATINGS = 'UPDATE_CONTRACTOR_OWN_RATINGS'
export const UPDATE_CLIENT_RATINGS_BY_OTHERS = 'UPDATE_CLIENT_RATINGS_BY_OTHERS'
export const UPDATE_CONTRACTOR_RATINGS_BY_OTHERS = 'UPDATE_CONTRACTOR_RATINGS_BY_OTHERS'
export const UPDATE_OUTSTANDING_CLIENT_PROJECTS = 'UPDATE_OUTSTANDING_CLIENT_PROJECTS'
export const UPDATE_OUTSTANDING_CONTRACTOR_PROJECTS = 'UPDATE_OUTSTANDING_CONTRACTOR_PROJECTS'

export const UPDATE_EVENT_NOTIFICATIONS = 'UPDATE_EVENT_NOTIFICATIONS'
export const ADD_EVENT_NOTIFICATIONS = 'ADD_EVENT_NOTIFICATIONS'
export const CLEAR_EVENT_NOTIFICATIONS = 'CLEAR_EVENT_NOTIFICATIONS'

export const SET_COUNTERPARTY_DATA = 'SET_COUNTERPARTY_DATA'
export const SET_LIGHT_KYC_DATA = 'SET_LIGHT_KYC_DATA'
export const SET_DECISION_MAKERS_DATA = 'SET_DECISION_MAKERS_DATA'
