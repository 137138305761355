<script setup>
import TieredMenu from 'primevue/tieredmenu'
import Saving from '@/components/headers/Saving.js'
import { computed, ref, watchEffect, onMounted } from 'vue'
import RouteEntityContext from '@/components/composables/RouteEntityContext.js'
import EntityComputedFields from '@/components/composables/EntityFields/EntityComputedFields.js'
import { useStore } from 'vuex'
import UnapprovedChanges from '@/components/Sheets/quote/estimating/UnapprovedChanges'
import { useRouter } from 'vue-router'
import usePresentation from '@/components/quote/presentation/Presentation'

const Router = useRouter()
const $store = useStore()
const refChanges = ref(null)
const { refId, changes, isDirty, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: true
})
const { isInPresentation } = usePresentation()
const { save /* resetChanges */ } = Saving.useSaving({
  refId,
  changes,
  isDirty,
  type,
  storeName,
  refChanges
})
const {
  quote_id,
  quote_status,
  change_order_client_has_approved,
  change_order_company_has_approved,
  change_order_id,
  change_order_time_sent,
  change_order_time_seen,
  quote_state,
  quote_is_change_order,
  quote_time_sent
} = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})
const { unapprovedItems } = UnapprovedChanges.useUnapprovedChanges({
  refId,
  store: 'Quote'
})

const label = ref('Save')
const severity = ref('bolster')
const disabled = ref(false)
const action = ref(() => {})

const canApproveForCompany = computed(() => $store.state.session.user.aUserPerms.quote.approve)
const needsManagerApproval = computed(() => !change_order_company_has_approved.value)
const needsClientApproval = computed(() => !change_order_client_has_approved.value)
const isRequestForEstimate = computed(() => quote_state.value === 'lead')
const unsentChanges = computed(() => !change_order_time_sent.value)
const isChangeOrder = computed(() => quote_is_change_order.value)

const scheduleNotifications = computed(
  () =>
    $store.state.eventNotification &&
    $store.state.eventNotification.notifications &&
    $store.state.eventNotification.notifications.schedule
)

const handleSend = async () => {
  if (
    isDirty.value &&
    (await $store.dispatch('modal/asyncConfirm', {
      message: 'Do you want to save your changes before you send?'
    }))
  )
    save()
  await $store.dispatch('modal/open', {
    modal: 'QuoteSend',
    objects: [{ quote_id: quote_id.value }]
  })
}

const approveChanges = () => {
  change_order_company_has_approved.value = 1
  $store.dispatch('ChangeOrder/markApprovedByCompany', {
    id: change_order_id.value
  })
}

const mutedclass = '!text-surface-400'
const activeclass = '!text-level-yellow'

const saveActions = computed(() => {
  const formattedItems = []

  if (isDirty.value) {
    formattedItems.unshift({
      label: 'Save estimate changes',
      icon: 'floppy-disk',
      class: activeclass,
      command: save,
      priority: 1
    })
  } else {
    formattedItems.push({
      label: 'No changes to save.',
      icon: 'floppy-disk',
      class: mutedclass
    })
  }

  return formattedItems
})

const approvalActions = computed(() => {
  const formattedItems = []

  if (canApproveForCompany.value && needsManagerApproval.value) {
    formattedItems.unshift({
      label: 'Approve as manager',
      icon: 'circle-check',
      class: activeclass,
      command: approveChanges
    })
  } else if (needsManagerApproval.value) {
    formattedItems.unshift({
      label: 'Requires manager approval',
      icon: 'circle-check',
      class: activeclass,
      command: () => {}
    })
  } else {
    formattedItems.unshift({
      label: 'No approvals required',
      icon: 'circle-check',
      class: mutedclass,
      command: () => {}
    })
  }
  return formattedItems
})
const sendActions = computed(() => {
  const formattedItems = []

  if (!change_order_time_sent.value && !isInPresentation.value) {
    formattedItems.unshift({
      label: 'Preview and send',
      icon: 'eye',
      class: activeclass,
      command: () => Router.push({ query: { ...Router.currentRoute.query, tab: 'Preview' } }),
      priority: 1
    })
  } else if (
    needsClientApproval.value &&
    unsentChanges.value &&
    unapprovedItems.value.length &&
    !needsManagerApproval.value &&
    !isRequestForEstimate.value &&
    !isDirty.value
  ) {
    const label =
      (isChangeOrder.value &&
        needsClientApproval.value &&
        unsentChanges.value &&
        'Send approval request with message') ||
      (isChangeOrder.value &&
        needsClientApproval.value &&
        !unsentChanges.value &&
        'Resend approval request') ||
      (needsClientApproval.value && 'Send with message')
    formattedItems.push({
      label,
      icon: 'message-plus',
      class: activeclass,
      command: handleSend,
      priority: 1
    })
  } else {
    formattedItems.push({
      label: 'Resend',
      icon: 'message-plus',
      class: activeclass,
      command: handleSend
    })
  }

  return formattedItems
})
const scheduleActions = computed(() => {
  const formattedItems = []

  if (scheduleNotifications.value?.length > 0 && /k|f/.test(quote_status.value)) {
    formattedItems.push({
      label: 'Send schedule notifications',
      icon: 'chart-gantt',
      class: activeclass,
      command: () => {
        $store.dispatch('EventNotification/sendByType', {
          type: 'schedule',
          quoteId: quote_id.value
        })
      },
      priority: 1
    })
  } else {
    formattedItems.push({
      label: 'No schedule updates.',
      icon: 'chart-gantt',
      class: mutedclass
    })
  }
  return formattedItems
})

const items = computed(() => {
  const formattedItems = []

  formattedItems.push(...saveActions.value)
  formattedItems.push(...approvalActions.value)
  formattedItems.push(...sendActions.value)
  formattedItems.push(...scheduleActions.value)

  // Find the first priority item, then duplicate it at the start
  const priority = formattedItems.find((fi) => fi.priority)
  if (priority) formattedItems.unshift(priority)

  return formattedItems
})

const icon = ref(null)
const setSteps = () => {
  const found = items.value.find((item) => item.class === activeclass)

  label.value = 'All up to date'
  severity.value = 'secondary'
  disabled.value = true
  action.value = () => {}

  if (found) {
    label.value = found.label
    icon.value = found.icon
    severity.value = 'bolster'
    disabled.value = false
    action.value = found.command
  }
}

const refToggleButton = ref(null)
const toggle = () => {
  menu.value.toggle({ currentTarget: refToggleButton.value.$el })
}
const menu = ref()

watchEffect(() => {
  setSteps()
})

onMounted(() => {
  c.throttle(
    () =>
      $store.dispatch('EventNotification/checkForNotifications', {
        type: 'schedule',
        quoteId: quote_id.value
      }),
    { delay: 25000 }
  )
})

const status = computed(() => {
  const stat = { name: 'Pending estimate', status: quote_status.value }
  switch (quote_status.value) {
    case 'k':
      stat.name = 'Booked project'
      break
    case 'g':
      stat.name = 'Completed project'
      break
    case 'f':
      stat.name = 'In-progress project'
      break
    case 'd':
    case 'h':
    case 'x':
    case 'b':
      stat.name = 'Declined project'
      break
  }

  if (isDirty.value) {
    stat.additional = `Unsaved draft changes`
    stat.severity = 'danger'
  } else if (
    !quote_time_sent.value &&
    !change_order_time_sent.value &&
    !/k|f/.test(quote_status.value)
  ) {
    stat.additional = `Never sent`
    stat.severity = 'danger'
  } else if (
    !change_order_time_sent.value &&
    (canApproveForCompany.value || change_order_company_has_approved.value)
  ) {
    stat.additional = /k|f/.test(stat.status)
      ? `Changes not yet sent`
      : `Latest revision not yet sent`
    stat.severity = 'danger'
  } else if (
    !change_order_time_seen.value &&
    change_order_time_sent.value &&
    !change_order_client_has_approved.value
  ) {
    stat.additional = `Sent, but not yet seen by the client`
    stat.severity = 'warning'
  } else if (
    quote_is_change_order.value &&
    !change_order_time_seen.value &&
    change_order_time_sent.value
  ) {
    stat.additional = `Changes sent, but not yet seen by the client`
    stat.severity = 'warning'
  } else if (
    change_order_time_sent.value &&
    !quote_is_change_order.value &&
    !change_order_client_has_approved.value
  ) {
    stat.additional = `Awaiting client approval`
  } else if (
    change_order_time_sent.value &&
    quote_is_change_order.value &&
    !change_order_client_has_approved.value
  ) {
    stat.additional = `Awaiting client approval for changes`
  } else if (!change_order_company_has_approved.value && !change_order_time_sent.value) {
    stat.additional = `Requires manager approval before sending`
    stat.severity = 'warning'
  } else if (scheduleNotifications.value?.length) {
    stat.additional = `Unsent schedule changes`
    stat.severity = 'warning'
  }

  return stat
})
</script>

<template>
  <div class="flex gap-4">
    <div
      class="flex-col gap-1 whitespace-nowrap justify-center items-end px-4 border-r border-surface-200 select-none pointer-events-none hidden 2xl:flex"
    >
      <tag
        severity="booked"
        :class="[
          'text-surface-900/70 ',
          {
            'bg-color-pending/20 border-color-pending': status.status === 'p',
            'bg-color-booked/20 border-color-booked': status.status === 'k',
            'bg-color-project/20 border-color-project': status.status === 'f'
          }
        ]"
      >
        {{ status.name }}
      </tag>
      <span
        v-if="status.additional"
        class="text-[8pt] leading-none text-right flex justify-end items-center whitespace-nowrap flex-nowrap gap-1"
      >
        <font-awesome-icon
          icon="fas fa-circle"
          :class="[
            'text-[5pt]',
            {
              'text-deep-red-400': status.severity === 'danger',
              'text-orange-500': status.severity === 'warning',
              'text-green-500': status.severity === 'success',
              'text-surface-200': !status.severity
            }
          ]"
        />{{ status.additional }}</span
      >
    </div>

    <div class="flex">
      <Btn
        class="rounded-r-none hidden lg:inline-flex"
        :class="{ '!inline-flex': isInPresentation }"
        :action="action"
        :disabled="disabled"
        :severity="severity"
        size="lg"
        hotkey="cmd-s"
        ><font-awesome-icon :icon="icon" />{{ label }}
      </Btn>
      <Btn
        ref="refToggleButton"
        class="lg:rounded-l-none"
        @click="toggle"
        :disabled="disabled"
        :severity="severity"
        size="lg"
      >
        <span class="lg:hidden">
          <font-awesome-icon icon="ellipsis" />
        </span>
        <span class="hidden lg:inline-block">
          <font-awesome-icon icon="chevron-down" />
        </span>
      </Btn>

      <TieredMenu ref="menu" id="overlay_tmenu" :model="items.slice(1)" popup />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
