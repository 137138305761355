<template>
  <div class="h-full w-full mx-auto px-safe-4 lg:px-8 flex flex-col gap-8">
    <PageHeader title="Import items" />
    <ImportItems />
  </div>
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import ImportItems from '@/components/importers/ImportItems.vue'
import PageHeader from '@/components/layout/page/PageHeader.vue'

export default {
  components: {
    PageHeader,
    ImportItems
  },
  mixins: [PageMixin],
  name: 'ImportItemsPage'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
