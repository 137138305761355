import ItemPicturesMixin from './ItemPicturesMixin'
import eventBus from '../../../eventBus'

export default {
  mixins: [ItemPicturesMixin],
  methods: {
    setField(field, value) {
      eventBus.$emit('PresentationModifyItem', {
        refId: this.object.refId,
        changes: {
          [field]: value
        }
      })
    },
    getField(field) {
      return this.object[field]
    }
  },
  computed: {
    isIncluded() {
      return this.object.cost_item_is_included || this.object.assembly_is_included
    },
    showInputs() {
      return (
        this.object.type !== 'assembly' &&
        this.object.oInputRequired &&
        this.object.oInputRequired.inputs &&
        this.object.oInputRequired.inputs.length
      )
    },
    expandableLocal() {
      return this.expandable && this.$slots.default && this.$slots.default.length && this.isIncluded
    },
    showFooter() {
      return (
        this.optional ||
        this.showCost ||
        this.showPrice ||
        this.showQty ||
        !this.expandableLocal ||
        this.showDimensions
      )
    },
    showDimensions() {
      return (
        this.object.type === 'assembly' &&
        this.presentationSettings.showAssemblyDimensions &&
        this.object.asDimensionsUsed.length &&
        (Object.values(this.object.oDimensions).filter((dim) => dim.showCustomer).length ||
          this.editable)
      )
    },
    showMiddleContent() {
      return (
        (this.itemPictures.length && this.isVisible) ||
        this.desc ||
        this.isAnUpgrade ||
        this.showInputs ||
        this.hasProperties ||
        this.$slots.default ||
        this.object.cost_type_is_variation_parent
      )
    },
    $() {
      return this.$store.getters.$
    },
    $a() {
      return this.$store.getters.$a
    },
    $b() {
      return this.$store.getters.$b
    },
    upgradesAllowed() {
      const rootRefId = c.getNormalizedRootRefId(
        this.$store.state[this.store].normalized,
        this.object.refId
      )
      return (
        String(this.$store.state[this.store].normalized[rootRefId].quote_is_upgrading_allowed) !==
        '0'
      )
    },
    hasProperties() {
      return (
        this.properties &&
        this.properties.length &&
        this.properties[0] &&
        this.properties[0][0] &&
        (typeof this.properties[0][0] === 'string' || this.properties[0][0].length)
      )
    },
    truncated() {
      return String(this.desc).length > 150
    },
    countAddons() {
      if (this.object.type === 'assembly') {
        return (
          this.object.quote_count_addons_available ||
          (this.object.aoAddons && this.object.aoAddons.length)
        )
      }
      return (
        this.object.cost_item_count_addons_available ||
        (this.object.aoAddons && this.object.aoAddons.length)
      )
    },
    /* files() {
     return ((this.object && this.object.aoFiles) || [])
     .map(r => this.$store.state[this.store].normalized[r]);
     },
     fileIds: {
     get() {
     return this.object.file_ids;
     },
     set(ids) {
     eventBus.$emit('PresentationModifyItem', {
     refId: this.object.refId,
     changes: {
     file_ids: ids,
     },
     });
     },
     }, */
    /* itemPictures() {
     // const ids = this.object.file_ids.map(id => c.buildDefaultObject('file', { file_id: id }));
     return (this.object.file_ids.length
     ? this.object.file_ids
     : [...(this.object.file_id ? [this.object.file_id] : [])])
     .map(id => c.buildDefaultObject('file', { file_id: id }));
     // const ids = [];
     // return [
     //   ...this.files,
     //   ...(this.object.file_id ? [c.buildDefaultObject('file', {
     //     file_id: this.object.file_id,
     //   })] : []),
     // ].filter((file) => {
     //   // De-duplicate
     //   const id = String(file.file_id);
     //   const inc = !ids.includes(id);
     //   ids.push(id);
     //   return !c.isempty(id) && inc;
     // });
     }, */
    viewOptions() {
      return (this.object.oViewOptions && this.object.oViewOptions.pres) || {}
    },
    showAddons() {
      return (
        !this.presentationSettings.showConfigurator && (this.showUpgrades || this.addons.length)
      )
    },
    upgradeKey() {
      return `${this.object.type}:${
        this.object.type === 'assembly' ? this.object.assembly_id : this.object.cost_type_id
      }`
    },
    isAnUpgrade() {
      return this.object.addon_is_upgraded || this.object.item_is_upgraded
      // return this.object.upgradesOriginalKey
      //   && this.object.upgradesOriginalKey !== this.upgradeKey;
    },
    addons: {
      get() {
        const norm = this.$store.state[this.store].normalized
        const obj = norm[this.object.refId]
        return _.imm(obj.aoAddons)
      },
      set(aoAddons) {
        eventBus.$emit('PresentationModifyItem', {
          refId: this.object.refId,
          changes: {
            aoAddons
          }
        })
      }
    },
    showMiddlePics() {
      const count = this.fileIds.length + this.images.length
      if (this.$store.state.session.deviceWidth < 800) return false
      else if (count > 1) return false
      return true
    },
    showEmphasis() {
      return (
        (this.object.cost_type_emphasis !== null && this.object.cost_type_emphasis > 0) ||
        (this.object.assembly_emphasis !== null &&
          this.object.assembly_emphasis > -2 &&
          this.object.assembly_emphasis < 0)
      )
    },
    optional: {
      get() {
        return this.object.cost_item_is_optional || this.object.assembly_is_optional
      },
      set(v) {
        eventBus.$emit('PresentationModifyItem', {
          refId: this.object.refId,
          changes: {
            [`${this.object.type === 'assembly' ? 'assembly' : 'cost_item'}_is_optional`]: v
          }
        })
      }
    },
    showItemized() {
      return true // deprecrated for individual settings instead
    },
    parent() {
      return this.$store.state[this.store].normalized[this.object.parentRefId]
    },
    showPrice() {
      if ('price' in this.viewOptions && this.viewOptions.price !== null) {
        return this.viewOptions.price
      }

      return (
        this.showItemized &&
        this.presentationSettings.showCostItemPrices &&
        this.presentationSettings.showItemizedPrices
      )
    },
    showCost() {
      if ('cost' in this.viewOptions && this.viewOptions.cost !== null) {
        return this.viewOptions.cost
      }

      return (
        this.showItemized &&
        !this.presentationSettings.showBundledProfit &&
        this.presentationSettings.showCosts
      )
    },
    showQty() {
      if ('qty' in this.viewOptions && this.viewOptions.qty !== null) {
        return this.viewOptions.qty
      }

      return (
        this.presentationSettings.showQuantities &&
        (this.qty > 1 || this.units.toLowerCase() !== 'each')
      )
    },
    excluded() {
      return c.eq(this.qty, 0)
    },
    desc() {
      return c.removeHtml(this.object.cost_type_desc || this.object.quote_notes)
    },
    prodDesc() {
      return c.removeHtml(
        this.object.cost_type_production_notes || this.object.quote_production_notes
      )
    },
    name() {
      return this.object.cost_type_name || this.object.assembly_name
    },
    qty() {
      return (
        (this.object.cost_item_qty_net_base || this.object.quote_qty_net_base || 0) *
        this.artificialMultiplier
      )
    },
    cost() {
      return (
        (this.object.cost_item_total_cost_net_base || this.object.quote_total_cost_net_base) *
        this.artificialMultiplier
      )
    },
    units() {
      return String(this.object.unit_of_measure_abbr || 'each').toLowerCase()
    },
    price() {
      if (this.presentationSettings.showBundledProfit) {
        return this.cost
      }

      return (
        (this.object.cost_item_price_net_base || this.object.quote_subtotal_net || 0) *
        this.artificialMultiplier
      )
    },
    properties() {
      return c.makeArray(this.object.aoProperties || [])
    },
    showProperties() {
      return (
        (this.object.length && this.isVisible) ||
        (this.object.aoProperties &&
          this.object.aoProperties.length &&
          this.object.aoProperties[0] &&
          this.object.aoProperties[0][0] &&
          this.object.aoProperties[0][0].length)
      )
    }
  }
}
