import _ from './Helpers'
import Objects from './Objects'

const { getConstructor } = Objects

const defaultExpiry = 60 * 1000 * 60 * 8 // 8 hrs
const mangle = false

const manglefn = mangle ? _.mangle : (a) => a
const unmanglefn = mangle ? _.unmangle : (b) => b

const clearCacheOfType = (type) => {
  const storage = _.getStorage()

  // Get all related types to clear too
  const constructor = getConstructor(type)
  const otherTypes = constructor && constructor.cachedTypes ? constructor.cachedTypes : []
  const allTypes = _.uniq([...otherTypes, type])

  Object.keys(storage).forEach((key) => {
    try {
      if (
        new RegExp(`^c\\.cache\\.(\\d+)\\.(\\d+)\\|(${allTypes.join('|')})\\|`).test(
          unmanglefn(key)
        )
      ) {
        _.removeStorageItem(key)
      }
    } catch (e) {
      // nothing
    }
  })
}

const generateCacheKeyPrefix = (sessionState = null, type = null) =>
  `c.cache.${sessionState && sessionState.company ? sessionState.company.company_id : 'any'}.${sessionState && sessionState.user ? sessionState.user.user_id : 'any'}|${type || 'any'}|`

const generateCacheKey = (key, type = null, sessionState = null) =>
  manglefn(`${generateCacheKeyPrefix(sessionState, type)}${key}`)

const generateCacheValue = (value, expire) => manglefn(JSON.stringify({ value, expire }))

const setCacheItem = (
  key,
  value,
  type = null,
  sessionState = null,
  expire = new Date().valueOf() + defaultExpiry
) => {
  _.setStorage(generateCacheKey(key, type, sessionState), generateCacheValue(value, expire))
  return true
}

const removeCacheItem = (key, type, sessionState) =>
  _.removeStorageItem(generateCacheKey(key, type, sessionState))

const isCacheExpired = (key, type = null, sessionState = null) => {
  const fullKey = generateCacheKey(key, type, sessionState)
  const rawValue = unmanglefn(_.getStorage(fullKey))
  let value = rawValue
  try {
    value = JSON.parse(rawValue)
  } catch (error) {
    // Do nothing
  }

  if (!value || !(typeof value === 'object') || !value.value) {
    return false
  }

  return value.expire && value.expire < new Date().valueOf()
}

const getCacheItem = (key, type = null, sessionState = null) => {
  const fullKey = generateCacheKey(key, type, sessionState)
  const rawValue = unmanglefn(_.getStorage(fullKey))

  let value = rawValue
  try {
    value = JSON.parse(rawValue)
  } catch (error) {
    // Do nothing
  }

  if (!value || !(typeof value === 'object') || !value.value) {
    return null
  }

  if (value.expire && value.expire < new Date().valueOf()) {
    // expired
    removeCacheItem(fullKey) // cleanup
    return null
  }

  return value.value
}

export default {
  getCacheItem,
  setCacheItem,
  clearCacheOfType,
  removeCacheItem,
  isCacheExpired
}
