<script setup>
import { useSidePanel } from '@/stores/sidepanel'
import useSearch from '@/components/composables/Search'
import bolsterLogo from '@/assets/logos/Bolster_Highlight_Yellow.svg'
import usePresentation from '@/components/quote/presentation/Presentation'

const sidePanel = useSidePanel()

const { open: openSearch } = useSearch()

const { isInPresentation } = usePresentation()
</script>

<template>
  <header class="w-full bg-pitch-black pt-safe-0">
    <div
      v-if="!isInPresentation"
      class="w-full h-[60px] px-safe-4 py-1 bg-pitch-black flex md:hidden flex-row justify-between items-center"
    >
      <img :src="bolsterLogo" class="h-9" alt="Bolster wordmark" />
      <div class="flex flex-row items-center gap-4 text-flame-white text-2xl">
        <font-awesome-icon class="h-11 w-11" icon="search" @click.native="openSearch" />
        <font-awesome-icon
          v-if="$route.meta.menuContext"
          class="h-11 w-11"
          icon="bars"
          @click.native="sidePanel.toggle"
        />
      </div>
    </div>
    <component v-if="$route.meta.header" :is="$route.meta.header" class="px-safe-1 py-1 lg:px-4" />
  </header>
</template>

<style scoped lang="scss"></style>
