<template>
  <tr>
    <td class="live-price-changes-col border-r border-b px-4 py-2">
      <span v-tooltip="costItemName">
        {{ $f.truncate(costItemName, 32) }}
      </span>
    </td>
    <td v-if="deviceWidth > 500" class="live-price-changes-col border-r border-b pr-4 py-2">
      <p class="tabular-nums">
        {{ newHoursPerUnit.toFixed(2) }}
      </p>
    </td>
    <td v-if="deviceWidth > 850" class="live-price-changes-col border-r border-b pr-4 py-2">
      <div class="flex flex-row items-center justify-end gap-2 w-full *:tabular-nums">
        <font-awesome-icon
          v-if="diffLaborRate > 0.0099"
          icon="chevron-up"
          class="text-matcha-500 text-sm"
        />
        <font-awesome-icon
          v-else-if="diffLaborRate < -0.0099"
          icon="chevron-down"
          class="text-red-500 text-sm"
        />
        <p
          :class="
            diffLaborRate > 0.0099
              ? 'text-matcha-500'
              : diffLaborRate < -0.0099
                ? 'text-red-500'
                : ''
          "
          v-tooltip="
            `${diffLaborRate >= 0.0099 ? '+' : '-'} $${$f.currency(Math.abs(diffLaborRate))}`
          "
        >
          ${{ $f.currency(newLaborRate) }}
        </p>
      </div>
    </td>
    <td class="live-price-changes-col border-r border-b pr-4 py-2">
      <div class="flex flex-row items-center justify-end gap-2 w-full *:tabular-nums">
        <font-awesome-icon
          v-if="diffLaborCost > 0.0099"
          icon="chevron-up"
          class="text-matcha-500 text-sm"
        />
        <font-awesome-icon
          v-else-if="diffLaborCost < -0.0099"
          icon="chevron-down"
          class="text-red-500 text-sm"
        />
        <p
          :class="
            diffLaborCost > 0.0099
              ? 'text-matcha-500'
              : diffLaborCost < -0.0099
                ? 'text-red-500'
                : ''
          "
          v-tooltip="
            `${diffLaborCost >= 0.0099 ? '+' : '-'} $${$f.currency(Math.abs(diffLaborCost))}`
          "
        >
          ${{ $f.currency(newLaborCost) }}
        </p>
      </div>
    </td>
    <td class="live-price-changes-col border-r border-b pr-4 py-2">
      <div class="flex flex-row items-center justify-end gap-2 w-full *:tabular-nums">
        <font-awesome-icon
          v-if="diffMaterialCost > 0.0099"
          icon="chevron-up"
          class="text-matcha-500 text-sm"
        />
        <font-awesome-icon
          v-else-if="diffMaterialCost < -0.0099"
          icon="chevron-down"
          class="text-red-500 text-sm"
        />
        <p
          :class="
            diffMaterialCost > 0.0099
              ? 'text-matcha-500'
              : diffMaterialCost < -0.0099
                ? 'text-red-500'
                : ''
          "
          v-tooltip="
            `${diffMaterialCost >= 0.0099 ? '+' : '-'} $${$f.currency(Math.abs(diffMaterialCost))}`
          "
        >
          ${{ $f.currency(newMaterialCost) }}
        </p>
      </div>
    </td>
    <td v-if="deviceWidth > 925" class="live-price-changes-col border-r border-b pr-4 py-2">
      <div class="flex flex-row items-center justify-end gap-2 w-full *:tabular-nums">
        <font-awesome-icon
          v-if="diffCombinedCost > 0.0099"
          icon="chevron-up"
          class="text-matcha-500 text-sm"
        />
        <font-awesome-icon
          v-else-if="diffCombinedCost < -0.0099"
          icon="chevron-down"
          class="text-red-500 text-sm"
        />
        <p
          :class="
            diffCombinedCost > 0.0099
              ? 'text-matcha-500'
              : diffCombinedCost < -0.0099
                ? 'text-red-500'
                : ''
          "
          v-tooltip="
            `${diffCombinedCost >= 0.0099 ? '+' : '-'} $${$f.currency(Math.abs(diffCombinedCost))}`
          "
        >
          ${{ $f.currency(newCombinedCost) }}
        </p>
      </div>
    </td>
    <td class="live-price-changes-col border-r border-b pr-4 py-2">
      <div class="flex flex-row items-center justify-end gap-2 w-full *:tabular-nums">
        <font-awesome-icon
          v-if="diffMarkup > 0.0099"
          icon="chevron-up"
          class="text-matcha-500 text-sm"
        />
        <font-awesome-icon
          v-else-if="diffMarkup < -0.0099"
          icon="chevron-down"
          class="text-red-500 text-sm"
        />
        <p
          :class="
            diffMarkup > 0.0099 ? 'text-matcha-500' : diffMarkup < -0.0099 ? 'text-red-500' : ''
          "
          v-tooltip="`${diffMarkup >= 0.0099 ? '+' : '-'} ${$f.currency(Math.abs(diffMarkup))}%`"
        >
          {{ ((1 - 1 / newMarkup) * 100).toFixed(2) }}%
        </p>
      </div>
    </td>
    <td class="live-price-changes-col border-b pr-4 py-2">
      <div class="flex flex-row items-center justify-end gap-2 w-full *:tabular-nums">
        <font-awesome-icon
          v-if="diffPrice > 0.0099"
          icon="chevron-up"
          class="text-matcha-500 text-sm"
        />
        <font-awesome-icon
          v-else-if="diffPrice < -0.0099"
          icon="chevron-down"
          class="text-red-500 text-sm"
        />
        <p
          :class="
            diffPrice > 0.0099 ? 'text-matcha-500' : diffPrice < -0.0099 ? 'text-red-500' : ''
          "
          v-tooltip="`${diffPrice >= 0.0099 ? '+' : '-'} $${$f.currency(Math.abs(diffPrice))}`"
        >
          ${{ $f.currency(newPrice) }}
        </p>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'LivePriceChange',
  mounted() {},
  data() {
    return {}
  },
  computed: {
    deviceWidth() {
      return this.$store.state.session.deviceWidth
    },

    costItemName() {
      return this.norm.cost_type_name
    },
    // Labor rate
    oldLaborRate() {
      return this.norm.labor_type_rate_net
    },
    newLaborRate() {
      if (this.change.labor_type_rate_net) return Number(this.change.labor_type_rate_net)
      return this.norm.labor_type_rate_net
    },
    diffLaborRate() {
      return this.newLaborRate - this.oldLaborRate
    },

    // Material cost
    oldMaterialCost() {
      return this.norm.cost_matrix_materials_cost_net
    },
    newMaterialCost() {
      if (this.change.cost_matrix_materials_cost_net) {
        return Number(this.change.cost_matrix_materials_cost_net)
      }
      return this.norm.cost_matrix_materials_cost_net
    },
    diffMaterialCost() {
      return this.newMaterialCost - this.oldMaterialCost
    },

    // hours per unit
    oldHoursPerUnit() {
      return this.norm.cost_type_hours_per_unit
    },
    newHoursPerUnit() {
      if (this.change.cost_type_hours_per_unit) return Number(this.change.cost_type_hours_per_unit)
      return this.norm.cost_type_hours_per_unit
    },
    diffHoursPerUnit() {
      return this.newHoursPerUnit - this.oldHoursPerUnit
    },

    // labor details
    hasLabor() {
      return this.norm.cost_type_has_labor && this.norm.labor_type_rate_net
    },

    // labor cost
    oldLaborCost() {
      return this.norm.cost_matrix_labor_cost_net
    },
    newLaborCost() {
      if (this.hasLabor) {
        return this.newHoursPerUnit * this.newLaborRate
      }
      return 0
    },
    diffLaborCost() {
      return this.newLaborCost - this.oldLaborCost
    },

    // combined cost
    oldCombinedCost() {
      return this.oldLaborCost + this.oldMaterialCost
    },
    newCombinedCost() {
      return this.newLaborCost + this.newMaterialCost
    },
    diffCombinedCost() {
      return this.newCombinedCost - this.oldCombinedCost
    },

    // markup
    oldMarkup() {
      return this.norm.cost_matrix_markup_net
    },
    newMarkup() {
      if (this.livePriceUpdate === 'markup') {
        return this.oldPrice / (this.newMaterialCost + this.newLaborCost)
      }
      return this.oldMarkup
    },
    diffMarkup() {
      const newProfitPercent = (1 - 1 / this.newMarkup) * 100
      const oldProfitPercent = (1 - 1 / this.oldMarkup) * 100
      return newProfitPercent - oldProfitPercent
    },

    // price
    oldPrice() {
      return this.norm.cost_matrix_rate_net
    },
    newPrice() {
      if (this.livePriceUpdate === 'price') {
        return (this.newMaterialCost + this.newLaborCost) * this.oldMarkup
      }

      return this.oldPrice
    },
    diffPrice() {
      return this.newPrice - this.oldPrice
    }
  },
  methods: {},
  props: {
    change: {
      type: Object
    },
    norm: {
      type: Object
    },
    livePriceUpdate: {
      type: String,
      default: 'price'
    }
  },
  components: {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.live-price-changes-col {
  overflow: hidden;
  position: relative;
  &:first-child {
    max-width: 15em;
    text-align: end;
  }
  text-align: end;

  @media (max-width: 576px) {
    font-size: 0.9em;
    padding: 0.5em 0.5em;
  }

  .positive-change {
    position: absolute;
    top: 8px;
    right: 20%;
    color: green;
    font-size: 0.8em;
  }

  .negative-change {
    position: absolute;
    top: 8px;
    right: 20%;
    color: red;
    font-size: 0.8em;
  }
}
</style>
