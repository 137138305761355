/**
 * Composable to view services/features available based on scope/authed user
 */

import { computed, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
// import all the services based on scope
import { services as companyServices } from '@/components/services/company'
import { services as franchisorServices } from '@/components/services/franchisor'
import { services as superServices } from '@/components/services/super'
import { services as clientServices } from '@/components/services/client'
import { services as vendorServices } from '@/components/services/vendor'
import { services as userServices } from '@/components/services/user'

export const initialState = {
  assurelyUrl: null,
  allServices: {},
  menuContext: null
}

// global state
const context = reactive({
  ...initialState
})

export default () => {
  const store = useStore()

  onMounted(async () => {
    // ge the Assurely link for the nav links
    // @todo store.state.session.company?.company_id may not be hydrated on load
    if (store.state.session.company?.company_id && context.menuContext === 'company') {
      const { payload } = await store.dispatch('ajax', {
        path: `company/getAssurelyUrl/${store.state.session.company.company_id}`
      })
      context.assurelyUrl = payload.url
    }
  })

  // modules company has access to
  const modules = computed(
    () => (store.state.session.company && store.state.session.company.aoModules) || {}
  )

  // all the services for each scope type
  context.allServices = {
    company: companyServices({ store, modules }),
    franchisor: franchisorServices({ store, modules }),
    super: superServices({ store, modules }),
    client: clientServices({ store, modules }),
    vendor: vendorServices({ store, modules }),
    user: userServices({ store, modules })
  }

  // the service for the current scope based on route
  const services = computed(() => {
    return context.allServices[context.menuContext]
  })

  // map the service item for the nav menu
  const mapService = (l) => {
    const filterPresets = l.filterPresets || []
    const subLinks = (l.links || []).map((ll) => mapService(ll))
    return {
      label: l.title,
      key: l.title,
      command: () => store.dispatch('to', l.to),
      icon: l.icon,
      page: l.to,
      tag: l.tag,
      desc: l.desc,
      items: [
        ...filterPresets.map((fp) => ({
          icon: 'filter',
          label: fp.title,
          key: fp.title,
          class: 'text-cement-800',
          filters: fp.filters,
          desc: fp.description,
          page: l.to,
          command: () => {
            store.dispatch('view', {
              page: l.to,
              type: l.type,
              filters: fp.filters,
              delay: 0
            })
          }
        })),
        ...subLinks
      ]
    }
  }

  // formatted service items for the side nav
  const serviceItems = computed(() => {
    const si = []
    if (!services.value) return []
    services.value.features.forEach((service) => {
      si.push({
        label: service.title,
        icon: service.icon,
        key: service.title,
        ...(service.to ? { command: () => store.dispatch('to', service.to) } : {}),
        ...(service.links && service.links.length > 0
          ? { items: (service.links || []).map((l) => mapService(l)) }
          : {})
      })
    })
    return si
  })

  return {
    // return the composable state as reactive
    ...toRefs(context),
    services,
    modules,
    serviceItems
  }
}
