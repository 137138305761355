<script setup>
import { ref, computed, defineEmits, defineExpose, watch } from 'vue'
import EntityList from '@/components/Sheets/quote/list/EntityList.vue'
import { useStore } from 'vuex'
import Auditing from '../../../../imports/api/Auditing/index.js'
import Dimensions from '@/components/composables/Dimensions.js'
import CostType from '@/components/bodies/CostType.vue'
CostType.compatConfig = { MODE: 3 }
import Header from '@/components/bodies/CostItem/Header.vue'
import _ from '../../../../imports/api/Helpers.js'
import ChangeTracker from '@/components/bodies/UtilityComponents/ChangeTracker.vue'
import Assembly from '@/components/bodies/Assembly.vue'
import { useRoute } from 'vue-router'

const $store = useStore()
const $route = useRoute()

const emit = defineEmits(['isDirty'])
const refEntityList = ref(null)

const editingItem = ref(null)
const refModal = ref(null)

const loading = ref(0)

const itemEditorProps = ref(null)

const modalChanges = ref({})
const modalChangesHandler = (changes) => {
  modalChanges.value = changes.allRaw?.[itemEditorProps.value.refId] ?? {}
}
const editItem = async (id, type = 'cost_type') => {
  loading.value = 1
  deselectAll()
  const store = _.titleCase(type)
  let { object } = await $store.dispatch(`${store}/fetch`, {
    id
  })
  if (object.cost_type_is_parent) {
    loading.value = 0
    return
  }
  object = {
    ...object,
    ...(refEntityList.value?.fullChanges?.[id] ?? {})
  }
  editingItem.value = id
  const { refId } = await $store.dispatch(`${store}/selectBlank`, {
    object: object,
    type
  })
  itemEditorProps.value = { store, type, refId }
  setTimeout(() => refModal.value.open())

  setTimeout(() => {
    loading.value = 0
  })
}

const deselectAll = async () => {
  await Promise.all([
    ...Object.keys($store.state.CostType.normalized).map((r) =>
      $store.dispatch(`CostType/deselect`, { refId: r })
    ),
    ...Object.keys($store.state.Assembly.normalized).map((r) =>
      $store.dispatch(`Assembly/deselect`, { refId: r })
    )
  ])
}

const handleEditorCancel = () => {
  refModal.value.close()
  c.throttle(() => deselectAll(), { delay: 250 })
}
const handleEditorSave = async () => {
  // Grab item, denormalize it
  const { store, refId, type } = itemEditorProps.value

  if (type !== 'assembly') {
    c.throttle(
      async () => {
        refEntityList.value?.setItemFields({ [editingItem.value]: modalChanges.value })
        editingItem.value = null
        c.throttle(() => deselectAll(), { delay: 250 })
      },
      { delay: 400 }
    )
    return refModal.value.close()
  }

  await $store.dispatch('Assembly/saveFromAssembly', {
    refId,
    store,
    asSuper: false,
    asNew: false
  })
  editingItem.value = null
  c.throttle(() => deselectAll(), { delay: 250 })
  return refModal.value.close()
}

const columns = ref([
  // {
  //   field: 'id',
  //   mapField: ({ type }) => (type === 'assembly' ? 'assembly_id' : 'cost_type_id'),
  //
  //   formatting: {
  //     width: 250,
  //     align: 'left',
  //     borders: {
  //       right: {
  //         thickness: 0
  //       }
  //     }
  //   }
  // },
  {
    field: 'name',
    mapField: ({ type }) => (type === 'assembly' ? 'assembly_name' : 'cost_type_name'),

    formatting: {
      width: 250,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    }
  },

  {
    field: 'metaType',
    title: ' ',
    action: (cellValue, rowData) => {
      editItem(rowData.id, rowData.type)
    },
    actionText: '',
    tooltip: 'Edit...',
    formatting: {
      width: 40,
      align: 'center',
      borders: {
        right: {
          thickness: 1
        },
        left: {
          thickness: 1
        }
      },
      preventDefaultDraw: true,
      draw: ({
        ctx,
        clipTo,
        text: metaType,
        drawIcon,
        lightGrayTrans,
        // lightGray,
        getRowData
      }) => {
        let icon = 'cube'

        let color = lightGrayTrans
        const { id } = getRowData()
        const obj = { ...refEntityList.value.items[id] }

        let iconType = metaType || 'cube'
        if (obj.type === 'assembly') {
          iconType = 'cubes'
        } else if (
          obj.cost_type_is_parent &&
          refEntityList.value.refSheet.collapsedGroups.includes(id)
        ) {
          iconType = 'folder'
        } else if (
          obj.cost_type_is_parent &&
          !refEntityList.value.refSheet.collapsedGroups.includes(id)
        ) {
          iconType = 'folder-open'
        } else if (obj.cost_type_is_fee) {
          iconType = 'Fee'
        } else if (obj.cost_type_is_variation_parent || obj.cost_type_is_addon_group) {
          iconType = 'Variation'
        }
        // else if (obj.cost_type_is_addon_group) {
        //   iconType = 'Option'
        // }

        // const id = obj.cost_type_id

        // if (id && obj.cost_type_hash === obj.cost_type_current_hash) {
        //   icon = 'catalog-saved'
        //   color = lightGray
        // } else if (id && obj.cost_type_hash !== obj.cost_type_current_hash) {
        //   icon = 'catalog-save-changes'
        //   color = lightGray
        // } else {
        switch (iconType) {
          case 'Fee':
            icon = 'percent'
            break
          case 'Variation':
            icon = 'swatchbook'
            break
          // case 'Option':
          //   icon = 'cubes'
          //   break
          case 'Assembly':
            icon = 'cubes'
            break
          case 'AutoCost':
            icon = 'circle-dollar'
            break
          case 'Task':
            icon = 'check-square'
            break
          case 'Cost Item':
            icon = 'cube'
            break
          case 'Gallery':
            icon = 'images'
            break
          case 'Text':
            icon = 'align-left'
            break
          default:
            icon = iconType
            break
        }
        // }

        drawIcon(ctx, clipTo, icon, color)
        return ctx
      }
    }
  },
  {
    title: 'Desc',
    tooltip: 'Sales description',
    formatting: {
      wordWrap: true,
      width: 40,
      align: 'left',
      verticalAlign: 'top',
      preventDefaultDraw: true,
      draw: ({ ctx, clipTo, text: desc, drawIcon, blueTrans, drawText }) => {
        if (!desc) {
          // drawIcon(ctx, clipTo, 'input-text', lightGrayTrans);
          return ctx
        }

        drawIcon(ctx, clipTo, 'input-text', blueTrans)

        drawText(ctx, [41, 0, 400, 40], desc, {
          fontSize: 14,
          align: 'left',
          verticalAlign: 'top'
        })
      }
    },
    field: 'desc',
    mapField: ({ type }) => (type === 'assembly' ? 'quote_notes' : 'cost_type_desc')
  },
  {
    title: 'Pics',
    field: 'file_ids',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },

  {
    superHeader: 'Production details',
    title: 'Construction stage',
    field: 'stage_id',
    mapTo: 'stage',
    choose: {
      // filters: {
      //   'company'
      // },
      // schema: 'unit_of_measure:unit_of_measure_id',
      // order: [
      //   ['company_id', 'desc'],
      //   [
      //     'unit_of_measure_is_metric',
      //     $store.state.session.company.country_id <= 2 ? 'asc' : 'desc'
      //   ]
      // ]
    },
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  // {
  //   title: 'Prod',
  //   mapField: ({ type }) =>
  //     type === 'assembly' ? 'quote_production_notes' : 'cost_type_production_notes',
  //   disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent,
  //   tooltip: 'Production notes',
  //   formatting: {
  //     wordWrap: true,
  //     width: 40,
  //     align: 'left',
  //     verticalAlign: 'top',
  //     preventDefaultDraw: true,
  //     draw: ({ ctx, clipTo, text: desc, drawIcon, blueTrans, drawText }) => {
  //       if (!desc) {
  //         // drawIcon(ctx, clipTo, 'input-text', lightGrayTrans);
  //         return ctx
  //       }
  //
  //       drawIcon(ctx, clipTo, 'input-text', blueTrans)
  //
  //       drawText(ctx, [41, 0, 400, 40], desc, {
  //         fontSize: 14,
  //         align: 'left',
  //         verticalAlign: 'top'
  //       })
  //     }
  //   }
  // },

  {
    title: 'Qty formula',
    titleColSpan: 2,
    field: 'dims',
    // onDraw: ({ redrawCells, col, row }) => redrawCells([[col + 1, row], [col + 2, row]], true),
    actionText: '      ',
    formatting: {
      align: 'left',
      width: 60,
      bold: true,
      borders: {
        right: {
          thickness: 0
        }
      },
      preventDefaultDraw: true,
      draw: ({ ctx, clipTo, drawIcon, drawText, getRowData }) => {
        const newClipTo = clipTo
        newClipTo[3] = 40
        const rowData = getRowData()

        if (
          rowData.cost_type_is_parent ||
          !rowData.cost_type_qty_equation ||
          rowData.type === 'assembly'
        )
          return ctx

        const dims = getDimensionsInEquation(rowData.cost_type_qty_equation)
        const color = getBlendedDimensionColor(dims)
        drawIcon(ctx, newClipTo, 'ruler-triangle', color)

        // Draw text for dim
        const text = `${dims.slice(0, 2).join(',')}${dims.length > 2 ? '+' : ''}`
        drawText(ctx, [clipTo[3] / 2, 8, clipTo[2], 20], text, {
          fontSize: 10,
          align: 'left',
          verticalAlign: 'top',
          color
        })

        // only do the wrapper if cost item
        if (rowData.type !== 'cost_type') return ctx

        const padding = 5
        const br = 3
        const [x, y, width, height] = clipTo

        const rheight = height - padding * 2 // Height adjusted for padding
        const rwidth = width - padding // Width adjusted for padding
        const xx = x + padding // X coordinate
        const yy = y + padding // Y coordinate

        // Draw the rectangle with rounded corners
        ctx.beginPath()
        ctx.moveTo(xx + br, yy) // Adjust starting point for border radius
        ctx.lineTo(xx + rwidth, yy)
        // ctx.arcTo((xx + rwidth), yy, (xx + rwidth), (yy + br), br);
        // ctx.lineTo((xx + rwidth), (yy + rheight) - br);
        // ctx.arcTo((xx + rwidth), (yy + rheight),
        //   (xx + rwidth) - br, (yy + rheight), br);
        ctx.moveTo(xx + rwidth, yy + rheight)
        ctx.lineTo(xx + br, yy + rheight)
        ctx.arcTo(xx, yy + rheight, xx, yy + rheight - br, br)
        ctx.lineTo(xx, yy + br)
        ctx.arcTo(xx, yy, xx + br, yy, br)
        ctx.lineJoin = 'round'
        ctx.lineCap = 'round'

        ctx.lineWidth = 2
        ctx.strokeStyle = color
        ctx.stroke()
      }
    },
    variables: () => possibleDimensions.value,
    computedValue: (rowData) => {
      const dims = [...(rowData.dimLinks || [])]
      const text = `${dims.slice(0, 2).join(',')}${dims.length > 2 ? '+' : ''}`
      return text
    }
  },
  {
    title: ' ',
    field: 'cost_type_qty_equation',
    onDraw: ({ redrawCells, col, row }) =>
      redrawCells(
        [
          [col - 1, row],
          [col + 1, row]
        ],
        true
      ),
    formatting: {
      width: 150,
      align: 'left',
      font: 'monospace',
      fontSize: 14,
      format: (value) => {
        return value ? `=${value.replace(/^=/, '')}` : ''
      },
      borders: {
        right: {
          thickness: 0
        },
        left: {
          thickness: 0
        }
      },
      preventDefaultDraw: false,
      draw: ({ text, ctx, clipTo, getRowData }) => {
        const rowData = getRowData()

        if (
          rowData.cost_type_is_parent ||
          !rowData.cost_type_qty_equation ||
          rowData.type === 'assembly'
        )
          return ctx

        const dims = getDimensionsInEquation(text)
        const color = getBlendedDimensionColor(dims)

        const padding = 5
        const [x, y, width, height] = clipTo

        const rheight = height - padding * 2 // Height adjusted for padding
        const rwidth = width // Width adjusted for padding
        const xx = x // X coordinate
        const yy = y + padding // Y coordinate

        // Draw the rectangle with rounded corners
        ctx.beginPath()
        ctx.moveTo(xx, yy) // Adjust starting point for border radius
        ctx.lineTo(xx + rwidth, yy)
        ctx.moveTo(xx + rwidth, yy + rheight)
        ctx.lineTo(xx, yy + rheight)

        ctx.lineWidth = 2
        ctx.strokeStyle = color
        ctx.stroke()
      }
    },
    variables: () => possibleDimensions.value
  },

  {
    title: 'Units',
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    choose: {
      schema: 'unit_of_measure:unit_of_measure_id',
      order: [
        ['unit_of_measure_is_linkable', 'desc'],
        ['unit_of_measure_is_metric', $store.state.session.company.country_id <= 2 ? 'asc' : 'desc']
      ]
    },
    formatting: {
      align: 'left',
      borders: {
        left: {
          thickness: 0,
          color: 'transparent'
        }
      },
      width: 60,
      bold: true,
      format: (...args) => `${args[2].type === 'assembly' ? 'each' : `${args[3]}`}`,
      deformat: (value) => value.replace(/^\/\s?/, ''),
      draw: ({ ctx, clipTo, getRowData }) => {
        const rowData = getRowData()

        if (
          rowData.cost_type_is_parent ||
          !rowData.cost_type_qty_equation ||
          rowData.type === 'assembly'
        )
          return ctx

        const dims = getDimensionsInEquation(rowData.cost_type_qty_equation)
        const color = getBlendedDimensionColor(dims)
        const padding = 5
        const br = 3
        const [x, y, width, height] = clipTo

        const rheight = height - padding * 2 // Height adjusted for padding
        const rwidth = width - padding // Width adjusted for padding
        const xx = x // X coordinate
        const yy = y + padding // Y coordinate

        // Draw the rectangle with rounded corners
        ctx.beginPath()
        ctx.moveTo(xx, yy) // Adjust starting point for border radius
        ctx.lineTo(xx + rwidth - br, yy)
        ctx.arcTo(xx + rwidth, yy, xx + rwidth, yy + br, br)
        ctx.lineTo(xx + rwidth, yy + rheight - br)
        ctx.arcTo(xx + rwidth, yy + rheight, xx + rwidth - br, yy + rheight, br)
        ctx.moveTo(xx + rwidth - br, yy + rheight)
        ctx.lineTo(xx, yy + rheight)
        // ctx.arcTo(xx, (yy + rheight), xx, (yy + rheight) - br, br);
        // ctx.lineTo(xx, (yy + br));
        // ctx.arcTo(xx, yy, (xx + br), yy, br);
        ctx.lineJoin = 'round'
        ctx.lineCap = 'round'

        ctx.lineWidth = 2
        ctx.strokeStyle = color
        ctx.stroke()
      }
    },
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent,
    readOnly: ({ type }) => type === 'assembly'
  },

  {
    title: 'SKU',
    field: 'cost_type_sku'
  },

  {
    superHeader: 'Materials costs',
    title: 'Materials',
    field: 'cost_type_has_materials',
    icon: 'box-taped',
    tooltip: 'Toggle if materials are included in this item',
    background: c.getCssColor('materials').replace(/\)$/, ',0.2)'),
    color: c.getCssColor('materials'),
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Default supplier',
    field: 'vendor_id',
    mapTo: 'vendor',
    formatting: {
      width: 150,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    title: 'Purchase format',
    field: 'purchase_unit_of_measure_id',
    choose: {
      order: [
        ['unit_of_measure_is_linkable', 'desc'],
        ['unit_of_measure_name', 'asc']
      ]
    },
    mapTo: 'cost_type',
    formatting: {
      width: 150,
      format: (...args) => `Bought by the ${args[3] || 'unit'}`,
      deformat: (value) => value
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 3,
    superHeader: 'Materials costs',
    title: 'Coverage per purchase unit',
    field: 'cost_type_materials_purchase_qty_per_unit',
    formatting: { width: 80, bold: true, borders: { right: { thickness: 0 } } },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    choose: {
      order: [
        ['unit_of_measure_is_linkable', 'desc'],
        ['unit_of_measure_is_metric', $store.state.session.company.country_id <= 2 ? 'asc' : 'desc']
      ]
    },
    formatting: {
      width: 50,
      bold: true,
      borders: { left: { thickness: 0 }, right: { thickness: 0 } }
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    superHeader: 'Materials costs',
    title: 'Purchase unit (box etc)',
    field: 'purchase_unit_of_measure_id',
    choose: {
      order: [['unit_of_measure_name', 'asc']]
    },
    mapTo: 'cost_type',
    formatting: {
      width: 100,
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3] || 'unit'}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    superHeader: 'Materials costs per unit',
    title: 'Purchase cost',
    field: 'cost_type_materials_purchase_price_net',
    formatting: {
      width: 80,
      borders: { right: { thickness: 0 } }
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    superHeader: 'Materials costs',
    title: 'Purchase unit (box etc)',
    field: 'purchase_unit_of_measure_id',
    choose: {
      order: [['unit_of_measure_name', 'asc']]
    },
    mapTo: 'cost_type',
    formatting: {
      width: 100,
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3] || 'unit'}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    superHeader: 'Materials costs per unit',
    title: 'Unit materials cost',
    field: 'cost_matrix_materials_cost_net',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } },
      format: 'currency'
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3] || 'unit'}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },

  {
    superHeader: 'Materials costs',
    title: 'Waste factor %',
    field: 'cost_type_material_waste_factor_net',
    get: (entityValue) => entityValue * 100,
    set: (tableValue) => tableValue / 100,
    formatting: {
      format: (v) => `${c.format(Math.round(v), 'currency', 0)}%`,
      deformat: (v) => `${c.format(c.toNum(v, 20, true), 'currency', 20)}`
    },
    disabled: ({
      type,
      cost_type_is_parent,
      cost_type_has_materials,
      cost_type_is_subcontracted
    }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_materials ||
      cost_type_is_subcontracted
  },

  {
    title: 'Labor',
    field: 'cost_type_has_labor',
    tooltip: 'Toggle if labor is included in this item',
    icon: 'user-helmet-safety',
    background: c.getCssColor('labor').replace(/\)$/, ',0.2)'),
    color: c.getCssColor('labor'),
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Labor type',
    field: 'labor_type_id',
    mapTo: 'labor_type',
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    title: 'Hourly labor cost',
    field: 'labor_type_rate_net',
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    title: 'Man-hours per unit',
    field: 'cost_type_hours_per_unit',
    format: 'hours',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } }
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3] || 'unit'}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    titleColSpan: 2,
    title: 'Unit labor costs',
    field: 'cost_matrix_labor_cost_net',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } },
      format: 'currency'
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3] || 'unit'}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ type, cost_type_is_parent, cost_type_has_labor, cost_type_is_subcontracted }) =>
      type === 'assembly' ||
      cost_type_is_parent ||
      !cost_type_has_labor ||
      cost_type_is_subcontracted
  },
  {
    title: 'Sub',
    field: 'cost_type_is_subcontracted',
    tooltip: 'Toggle if this item is subcontracted',
    icon: 'truck-arrow-right',
    background: c.getCssColor('subcontractor').replace(/\)$/, ',0.2)'),
    color: c.getCssColor('subcontractor'),
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    title: 'Default sub',
    field: 'vendor_id',
    mapTo: 'vendor',
    formatting: {
      width: 150,
      align: 'left',
      borders: {
        right: {
          thickness: 0
        }
      }
    },
    disabled: ({ type, cost_type_is_parent, cost_type_is_subcontracted }) =>
      type === 'assembly' || cost_type_is_parent || !cost_type_is_subcontracted
  },

  {
    title: 'Subcontractor costs',
    titleColSpan: 2,
    field: 'subcosts',
    formatting: {
      bold: true,
      align: 'right',
      borders: { right: { thickness: 0 } },
      format: 'currency'
    },
    mapField: ({ type }) =>
      type === 'assembly' ? 'quote_materials_cost_net' : 'cost_matrix_materials_cost_net',
    disabled: ({ type, cost_type_is_parent, cost_type_is_subcontracted }) =>
      type === 'assembly' || cost_type_is_parent || !cost_type_is_subcontracted
  },
  {
    field: 'unit_of_measure_id',
    mapTo: 'unit_of_measure',
    formatting: {
      borders: { left: { thickness: 0 } },
      format: (...args) => `per ${args[3] || 'unit'}`,
      deformat: (value) => value.replace(/^\/\s?/, '')
    },
    disabled: ({ type, cost_type_is_parent, cost_type_is_subcontracted }) =>
      type === 'assembly' || cost_type_is_parent || !cost_type_is_subcontracted
  },
  {
    superHeader: 'Totals',
    title: 'Your unit costs',
    mapField: ({ type }) =>
      type === 'assembly' ? 'quote_total_cost_net' : 'cost_matrix_aggregate_cost_net',
    readOnly: ({ type }) => type === 'assembly',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },
  {
    superHeader: 'Totals',
    title: 'Profit %',
    mapField: ({ type }) => (type === 'assembly' ? 'quote_markup_net' : 'cost_matrix_markup_net'),
    get: (entityValue) => c.markupToMargin(entityValue) * 100,
    set: (tableValue) => c.marginToMarkup(tableValue / 100),
    formatting: {
      // Since it is always whole percentage numbers, no value transformation required here
      format: (v) => `${c.format(v, 'currency', 2)}%`,
      deformat: (v) => `${c.format(c.toNum(v, 20, true), 'currency', 20)}`
    },
    readOnly: ({ type }) => type === 'assembly',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },
  {
    superHeader: 'Totals',
    title: 'Client unit price',
    mapField: ({ type }) => (type === 'assembly' ? 'quote_subtotal_net' : 'cost_matrix_rate_net'),
    readOnly: ({ type }) => type === 'assembly',
    disabled: ({ cost_type_is_parent }) => cost_type_is_parent
  },
  {
    title: 'Item-specific tax',
    field: 'tax_id',
    mapTo: 'tax',
    choose: {
      filters: {
        province_id: `${$store.state.session.company.province_id}||NULL`
      }
    },
    disabled: ({ type, cost_type_is_parent }) => type === 'assembly' || cost_type_is_parent
  },
  {
    idField: true,
    field: 'id',
    mapField: ({ type }) => (type === 'assembly' ? 'assembly_id' : 'cost_type_id'),
    hidden: true
  },
  {
    idField: true,
    field: 'dimLinks',
    mapField: ({ type }) => (type === 'assembly' ? 'asDimensionsUsed' : 'asDimensionsLinked'),
    hidden: true
  },
  {
    field: 'parentId',
    mapField: () => 'parent_cost_type_id',
    hidden: true
  },
  { field: 'childrenIds', hidden: true },
  { field: 'cost_type_is_parent', hidden: true },
  { field: 'cost_type_is_variation_parent', hidden: true },
  { field: 'variation_parent_cost_type_id', hidden: true },
  { field: 'type', hidden: true }
])

const superHeaders = computed(() => [
  {
    title: 'Materials costing',
    icon: 'box-taped',
    span: [10, 19],
    formatting: {
      background: c.getCssColor('materials').replace(/\)$/, ',0.2)')
    },
    expanded: false
  },
  {
    title: 'Labor costing',
    icon: 'user-helmet-safety',
    span: [21, 26],
    formatting: {
      background: c.getCssColor('labor').replace(/\)$/, ',0.2)')
    },
    expanded: false
  },
  {
    title: 'Subcontractor costing',
    icon: 'truck-arrow-right',
    span: [28, 30],
    formatting: {
      background: c.getCssColor('subcontractor').replace(/\)$/, ',0.2)')
    },
    expanded: false
  }
])

const companyId = computed(() => $store.state.session.company.company_id)

const routeHasCompanyScope = computed(
  () => $route.meta?.scopesAllowed && $route.meta?.scopesAllowed.includes('company')
)

const confirmScope = computed(
  () => (routeHasCompanyScope.value && companyId.value) || !routeHasCompanyScope.value
)

const filters = computed(() => ({
  company_id: companyId.value,
  parent_cost_type_id: 'NULL'
}))

const collapseGroups = {
  sortField: 'location',
  rootId: null,
  isParent: ({ cost_type_is_parent, cost_type_is_variation_parent }) =>
    !!(cost_type_is_parent || cost_type_is_variation_parent),
  getParent: ({ parentId, variation_parent_cost_type_id }) =>
    variation_parent_cost_type_id || parentId || null,
  isExpanded: () => false,
  parentFormatting: {
    preset: 'heading',
    bold: true
  }
}

const restrictFields = [
  ...Object.keys(c.getConstructor('cost_type').getComputedDependants()),
  ...columns.value.reduce(
    (acc, col) => [
      ...acc,
      ...(col.field ? [col.field] : []),
      ...(col.mapField
        ? [col.mapField({ type: 'assembly' }), col.mapField({ type: 'cost_type' })]
        : [])
    ],
    []
  )
]

const isDirtyHandler = (isDirty) => emit('isDirty', isDirty)
const save = () => refEntityList.value?.save?.()

const create = async (payload = {}) => {
  let chosenType = null
  let isCategory = false
  if (payload?.duplicating) {
    const isAssembly = payload.addedFrom.item.type === 'assembly'

    if (isAssembly) chosenType = 'assembly'
    else chosenType = 'item'

    isCategory = chosenType === 'item' && Object.values(payload.rowItems)?.[0]?.cost_type_is_parent
  } else {
    chosenType = await $store.dispatch('modal/asyncConfirm', {
      choices: [
        {
          title: 'New item',
          icon: 'cube',
          desc: 'Create a regular item, that has some sort of cost and price associated to it. You can add items to your estimates or assemblies.',
          value: 'item'
        },
        {
          title: 'New assembly',
          icon: 'cubes',
          desc: 'Create an assembly which is just a templated group of items and other assemblies. You can re-use your assemblies inside of other assemblies, or just add them to new estimates.',
          value: 'assembly'
        },
        {
          title: 'New category',
          icon: 'folder-open',
          desc: 'Create a new category to organize your catalog items.',
          value: 'category'
        }
      ],
      message: 'Choose what you would like to create'
    })
  }

  if (!chosenType) throw new Error('Aborted') // will trigger entitylist to delete placeholder

  deselectAll()
  switch (chosenType) {
    case 'item':
      await createItem(payload, isCategory)
      break
    case 'assembly':
      await createAssembly(payload)
      break
    case 'category':
      await createItem(payload, true)
      break
  }
}

const { possibleDimensions, getDimensionsInEquation, getBlendedDimensionColor } =
  Dimensions.useDimensions()
const createItem = async (payload = {}, category = 0) => {
  const { rowItems = null, parentId = null, addedFrom = null, rowIndex: rrow = null } = payload

  let placeholderRow = Object.keys(rowItems ?? {})?.[0] ? +Object.keys(rowItems ?? {})?.[0] : null
  let rowIndex = rrow ?? +(Object.keys(rowItems ?? {})?.[0] ?? refEntityList.value.rows.length) // stick at bottom if not provided
  let item = Object.values(rowItems ?? {})?.[0] ?? {}
  let embue = {}
  embue.cost_type_id = null
  // Check if we should duplicate addedFrom

  const af = addedFrom?.item ?? null
  embue.parent_cost_type_id = parentId ?? af?.parent_cost_type_id ?? null

  if (af?.cost_type_is_variation_parent) {
    $store.dispatch('alert', {
      error: true,
      message:
        'Cannot add items to variations here. Edit the variation item directly to add/remove items'
    })
    return
  }

  const duplicateFromRow =
    !category &&
    af?.type === 'cost_type' &&
    !af?.cost_type_is_parent &&
    !af?.cost_type_is_variation_parent

  embue.cost_type_name = `${duplicateFromRow && af?.cost_type_name ? `${af?.cost_type_name} copy ` : `New ${category ? 'category' : 'item'} `} ${c.format(Date.now(), 'datetime')}${Date.now()}`

  item = {
    ...((duplicateFromRow &&
      af && {
        ...af,
        ...(refEntityList.value.fullChanges?.[af.cost_type_id] ?? {})
      }) || {
      cost_type_has_materials: !category && 1,
      cost_matrix_materials_cost_net: !category && 1,
      cost_type_is_parent: category && 1
    }),
    ...embue
  }
  ;({ object: item } = await $store.dispatch('CostType/buildDefaultObject', {
    embue: item,
    type: 'cost_type'
  }))
  ;({ a: item } = Auditing.cascadeDependencies({ a: item }, 'a', {}, possibleDimensions.value)[0])
  ;({ object: item } = await $store.dispatch('CostType/save', {
    go: false,
    changes: false,
    object: item
  }))

  c.throttle(() => deselectAll(), { delay: 250 })
  if (item) refEntityList.value.reloadItem(item, placeholderRow, +rowIndex)
}

const createAssembly = async (payload) => {
  const { rowItems = null, parentId = null } = payload

  let placeholderRow = +Object.keys(rowItems ?? {})?.[0] ?? null
  let rowIndex = +Object.keys(rowItems ?? {})?.[0] ?? 0 // stick at top if not provided
  let item = Object.values(rowItems ?? {})?.[0] ?? {}

  let created
  if (payload.duplicating) {
    ;[[{ object: created }]] = await $store.dispatch('Assembly/duplicate', {
      selected: [
        {
          ...item,
          parent_cost_type_id: parentId,
          type: 'assembly'
        }
      ],
      go: false,
      alert: false
    })
  } else {
    // opens up modal, creates, returns saved, then reload using that
    created = await $store.dispatch('create', {
      type: 'assembly',
      go: false,
      embue: {
        type: 'assembly',
        parent_cost_type_id: parentId
      }
    })
  }

  if (created)
    refEntityList.value.reloadItem({ ...created, type: 'assembly' }, +placeholderRow, +rowIndex)
}

const rowProcessor = (row) => {
  let processed = row
  // if (processed.type === 'cost_type') {
  //   ;[{ a: processed }] = Auditing.cascadeDependencies({ a: processed }, 'a')
  // }
  return processed
}

const limit = ref(50)
const expandCollapseGroupHandler = ({ collapseGroupId, rowIndex }) => {
  // get row of parent, add
  fetchFromCategory(collapseGroupId, rowIndex)
}

const sort = ref([
  ['cost_type_is_parent', 'desc'],
  ['type', 'asc'],
  ['parent_cost_type_id', 'desc'],
  ['assembly_name', 'asc'],
  ['cost_type_name', 'asc']
])

const offsets = ref({})
const lastFetchedOffsets = ref({})
const finishedGroups = ref([])
const awaiting = {}

const fetchFromCategory = async (collapseGroupId) => {
  if (collapseGroupId === 'null' || !collapseGroupId || collapseGroupId === 'root') return // don't handle root fetch
  if (finishedGroups.value.includes(collapseGroupId)) return // don't handle finished groups

  if (awaiting[collapseGroupId]) await awaiting[collapseGroupId]

  const offset = (offsets.value[collapseGroupId] ??= 0)

  // Don't search the same set twice
  if (lastFetchedOffsets.value?.[collapseGroupId] === offset) return
  lastFetchedOffsets.value[collapseGroupId] = offset

  const run = async () => {
    const grp = refEntityList.value.refSheet.dataSheets[0].collapseGroups.groups[collapseGroupId]
    refEntityList.value?.refSheet?.setLoadingRow(grp.rows[grp.rows.length - 1])
    const { set } = await $store.dispatch(`CostType/search`, {
      filters: {
        ...filters.value,
        parent_cost_type_id: collapseGroupId
      },
      // searchPhrase: sp,
      limit: limit.value,
      offset: offset,
      order: sort.value
    })
    if (set.length < limit.value) finishedGroups.value.push(collapseGroupId)
    offsets.value[collapseGroupId] += set.length

    const rowIndex = grp.rows[grp.rows.length - 1] + 1
    await refEntityList.value.importRows(set, false, rowIndex, true, null, collapseGroupId)
    refEntityList.value?.refSheet?.setLoadingRow(null)
  }

  awaiting[collapseGroupId] = run()

  return awaiting[collapseGroupId]
}

const groups = computed(() =>
  Object.values(refEntityList.value?.refSheet?.dataSheets?.[0]?.collapseGroups?.groups ?? {})
)
const firstVisibleRow = computed(() => refEntityList.value?.refSheet?.visibleRowRange?.[0] ?? -1)
const lastVisibleRow = computed(() => refEntityList.value?.refSheet?.visibleRowRange?.[1] ?? -1)

const visibleExpandedGroups = computed(() => {
  const [fvr, lvr] = [firstVisibleRow.value, lastVisibleRow.value]

  return groups.value.filter((group) => {
    if (!group.id || group.id === null || group.id === 'NULL' || group.id === 'null') return false // don't handle root fetch
    if (group?.rows?.length <= 1) return false // first row is just the parent's row, means it's not expanded

    const lastGroupRow = group?.rows?.[group.rows?.length - 1]

    if (lastGroupRow >= fvr && lastGroupRow <= lvr) {
      return true
    }

    return false
  })
})

watch(visibleExpandedGroups, (groups) => {
  for (let group of groups) {
    fetchFromCategory(group.id)
  }
})

defineExpose({
  save,
  refEntityList
})
</script>

<template>
  <EntityList
    v-if="confirmScope"
    ref="refEntityList"
    showCategories
    @isDirty="isDirtyHandler"
    type="cost_type"
    title="Items and assemblies"
    :filters="filters"
    :rowProcessor="rowProcessor"
    :create="create"
    icon="box-open-full"
    :columns="columns"
    :collapseGroups="collapseGroups"
    :superHeaders="superHeaders"
    :restrictFields="restrictFields"
    @expandCollapseGroup="expandCollapseGroupHandler"
    :limit="limit"
    :freeze="1"
    :sort="sort"
  >
    <template #sheetOptionAfter>
      <Btn severity="tertiary" :action="() => create({ rowIndex: 0 })">
        <font-awesome-icon icon="plus" />
        Create
      </Btn>
    </template>
    <template #overlay>
      <Loader :loading="loading" />
    </template>
    <template #after>
      <MiniModal
        :key="itemEditorProps?.type"
        ref="refModal"
        :full="itemEditorProps?.type === 'assembly'"
        :fixed="itemEditorProps?.type === 'assembly'"
        :scrollable="false"
        :size="itemEditorProps?.type !== 'assembly' ? 'sm' : 'full'"
        v-if="editingItem !== null"
        :pt="{
          closeButton: { class: '!w-0 !p-0 !m-0 !h-0' }
        }"
        :closeable="false"
        :showCloseButton="false"
      >
        <template #header>
          <div class="flex justify-between items-start w-full">
            <Header :show-save-button="false" v-if="editingItem" v-bind="itemEditorProps" />
            <ChangeTracker v-bind="itemEditorProps" @changes="modalChangesHandler">
              <template #default="{ isDirty }">
                <div class="flex justify-start gap-1 items-center">
                  <Btn v-if="isDirty" size="large" severity="tertiary" :action="handleEditorCancel">
                    Cancel
                  </Btn>
                  <Btn v-if="isDirty" size="large" severity="bolster" :action="handleEditorSave">
                    Save changes
                  </Btn>
                  <Btn v-else size="large" severity="tertiary" :action="handleEditorCancel">
                    Done
                  </Btn>
                </div>
              </template>
            </ChangeTracker>
          </div>
        </template>
        <Assembly
          v-bind="itemEditorProps"
          v-if="itemEditorProps.type === 'assembly'"
          ref="assemblyEditor"
          :show-title="false"
        />
        <CostType v-bind="itemEditorProps" v-else ref="costTypeEditor" :show-title="false" />
      </MiniModal>

      <div class="py-2">
        <Btn
          :action="() => create()"
          unstyled
          size="sm"
          class="min-w-[250px] opacity-80 hover:opacity-100 bg-surface-200 text-surface-500 rounded-md hover:bg-surface-300 hover:text-surface-900 text-sm py-2 font-medium"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
          Create new item
        </Btn>
      </div>
    </template>
  </EntityList>
</template>
