<template>
  <div
    class="flex flex-col gap-12 w-full justify-start overscroll-contain overflow-y-scroll items-center pb-40"
    ref="fullContainer"
  >
    <div
      class="flex flex-col items-center justify-center w-full shrink-0 h-auto min-h-[700px] 2xl:h-[550px] bg-pitch-black text-white"
    >
      <div
        class="w-full max-w-[1300px] flex justify-stretch items-center flex-wrap 2xl:flex-nowrap py-4 px-8 x2xl:p-0 gap-y-6"
      >
        <div class="basis-full 2xl:basis-1/3">
          <h1 class="!text-white text-3xl font-medium">
            <font-awesome-icon icon="fas fa-circle" size="xs" /> Welcome, {{ name }}
          </h1>
          <p class="text-white text-lg leading-snug opacity-80">
            From first lead to final payment -- take your business to the next level with
            construction intelligence.
          </p>
        </div>
        <div class="basis-full 2xl:basis-2/3">
          <div
            v-if="milestonesIncomplete[0]"
            class="rounded-md overflow-hidden !bg-[#075fff] p-5 w-full shadow-lg hover:shadow-md h-fit 2xl:h-[300px] transition-all select-none cursor-pointer"
          >
            <div
              class="flex flex-col md:flex-row justify-between items-stretch w-full h-full gap-6"
            >
              <div
                :class="[
                  {
                    'basis-1/2': milestonesIncomplete[0]?.video || milestonesIncomplete[0]?.pic,
                    'basis-full': !(milestonesIncomplete[0]?.video || milestonesIncomplete[0]?.pic)
                  },
                  ' flex flex-col justify-between h-full gap-6'
                ]"
              >
                <h2 class="!text-white text-2xl font-medium !leading-none">
                  {{ milestonesIncomplete[0]?.title }}
                </h2>
                <p class="text-white leading-tight text-lg opacity-70">
                  {{ milestonesIncomplete[0]?.desc }}
                </p>
                <div class="flex flex-col gap-1 items-start">
                  <div class="text-xs opacity-60 leading-none">
                    {{ milestonesIncomplete[0]?.time }}
                  </div>
                  <Btn
                    v-if="!milestonesIncomplete[0]?.automatic"
                    :action="milestonesIncomplete[0]?.action"
                    unstyled
                    class="text-pitch-black bg-level-yellow px-2 py-1 rounded-sm leading-tight text-2xl font-medium hover:gap-4 flex gap-2 transition-all text-left justify-start"
                  >
                    Start now <font-awesome-icon icon="arrow-right" />
                  </Btn>
                  <div
                    v-else
                    class="text-xl font-medium opacity-75 flex justify-start items-center gap-2"
                  >
                    <font-awesome-icon icon="sparkles" /> Automatic
                  </div>
                </div>
              </div>
              <div
                class="h-fit 2xl:max-h-[300px] overflow-hidden -m-5"
                v-if="milestonesIncomplete[0]?.video || milestonesIncomplete[0]?.pic"
              >
                <video
                  class="w-full md:w-auto"
                  id="402660f5-da8b-add4-7b89-9028d27a6d83-video"
                  v-if="milestonesIncomplete[0]?.video"
                  autoplay=""
                  loop=""
                  height="100"
                  width="400"
                  :style="{ backgroundImage: `url('${milestonesIncomplete[0]?.video}')` }"
                  muted=""
                  playsinline=""
                  data-wf-ignore="true"
                  data-object-fit="cover"
                >
                  <source :src="milestonesIncomplete[0]?.video" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div v-else class="w-full flex justify-center items-center">
            <div
              class="2xl:max-h-[350px] overflow-hidden !bg-[#075fff] flex justify-center items-center w-fit !p-4 rounded-lg"
            >
              <video
                id="402660f5-da8b-add4-7b89-9028d27a6d83-video"
                autoplay=""
                loop=""
                height="100"
                width="400"
                :style="{ backgroundImage: `url('${randomVid}')` }"
                muted=""
                playsinline=""
                data-wf-ignore="true"
                data-object-fit="cover"
              >
                <source :src="randomVid" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full max-w-[1300px] flex flex-col justify-stretch items-stretch flex-wrap 2xl:flex-nowrap p-4 x2xl:p-0 gap-y-12"
    >
      <div class="flex justify-center 2xl:flex-nowrap flex-wrap w-full gap-12">
        <div class="flex flex-col 2xl:basis-3/4 basis-full gap-4">
          <h1>Essentials</h1>
          <div class="flex 2xl:justify-start justify-center items-start gap-4 flex-wrap">
            <FeatureCard
              v-for="card in quickActions"
              :key="card.title"
              @click.native="() => card.action()"
              :title="card.title"
              :desc="card.desc"
              :icon="card.icon"
            />
          </div>
        </div>
        <div class="flex flex-col 2xl:basis-1/4 basis-full gap-2 items-center max-w-[670px]">
          <!--          <h3 class="pl-3">Support</h3>-->
          <!--          <div>Getting started video</div>-->

          <div
            class="w-full rounded-sm flex justify-center items-center overflow-hidden relative w-[400px] h-[150px]"
            :style="{ backgroundImage: `url('/gettingstarted.png`, backgroundSize: 'cover' }"
          >
            <!--            <video-->
            <!--              autoplay="true"-->
            <!--              loop=""-->
            <!--              height="100"-->
            <!--              width="400"-->
            <!--              muted="true"-->
            <!--              playsinline="true"-->
            <!--              data-wf-ignore="true"-->
            <!--              data-object-fit="cover"-->
            <!--            >-->
            <!--              <source :src="gettingStartedVid" type="video/mp4" />-->
            <!--            </video>-->

            <div
              @click="playFull = gettingStartedVid"
              class="absolute inset-0 bg-blue-print-500/70 flex flex-col items-center justify-center text-white cursor-pointer hover:bg-surface-800/60 hover:ring-2 hover:ring-blue-print transition-all"
            >
              <font-awesome-icon icon="fas fa-play" size="2xl" />
              Getting started - 5 min
            </div>
          </div>

          <div
            class="bg-surface-800/80 fixed inset-0 flex flex-col gap-4 justify-center items-center z-[10000]"
            v-if="playFull"
          >
            <video
              class="w-full md:w-[80vw]"
              autoplay="true"
              loop="true"
              controls
              playsinline="true"
              data-object-fit="cover"
            >
              <source :src="playFull" type="video/mp4" />
            </video>

            <Btn @click="playFull = null">Close</Btn>
          </div>
          <Btn
            unstyled
            class="bg-surface-100 hover:bg-surface-200/50 py-4 flex justify-between items-center px-4 rounded-sm w-full max-w-[670px]"
            :action="openSupportChat"
          >
            <div class="flex justify-start items-center gap-2">
              <img :src="BolsterBlackIcon" class="max-w-4" />
              Bolster support chat
            </div>
            <font-awesome-icon icon="arrow-up-right" />
          </Btn>
          <PanelCard class="w-full max-w-[670px]">
            <template #title>
              <h4 class="!font-medium flex gap-2 items-center !m-0 !leading-none">
                <font-awesome-icon icon="graduation-cap" />Bolster academy
              </h4>
            </template>
            <div>Learn how to use all of Bolster's features with quick and easy videos.</div>
            <template #footer>
              <div class="w-full flex justify-start items-end">
                <Btn
                  to="academy"
                  severity="link"
                  class="hover:gap-3 text-left justify-start !p-0 transition-all"
                >
                  Check it out <font-awesome-icon icon="arrow-right" />
                </Btn>
              </div>
            </template>
          </PanelCard>

          <div
            class="2xl:mt-8 flex justify-center items-center 2xl:flex-col 2xl:justify-start 2xl:items-stretch flex-wrap max-w-[670px] 2xl:max-w-full 2xl:h-[250px]"
          >
            <span
              class="!leading-tight text-center basis-1/2 grow-0 shrink-0 text-2xl 2xl:grow 2xl:shrink 2xl:max-h-fit mt-4"
            >
              From new lead to final payment, a complete end-to-end workflow.
            </span>
            <div class="basis-1/2 grow-0 2xl:grow 2xl:shrink max-w-[370px] 2xl:basis-auto">
              <img src="/point-to-point.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-stretch gap-4 w-full"
      v-if="milestones.length !== milestonesIncomplete.length"
    >
      <div class="flex mx-auto max-w-[1300px] 2xl:w-[1300px] p-4 pb-0">
        <div class="flex flex-col gap-1">
          <h1 class="!m-0 !leading-none">Account setup next steps</h1>
          <span class="text-surface-500">
            {{ milestones.length - milestonesIncomplete.length }} of
            {{ milestones.length }} complete</span
          >
        </div>
      </div>

      <div
        v-if="milestonesIncomplete.length"
        class="relative w-full flex gap-4 snap-x flex-wrap items-center justify-center pb-4 shrink-0 px-4"
      >
        <div
          class="shrink-0 snap-end"
          :style="{
            width: `${(containerWidth - 1300) / 2}px`,
            minimumWidth: `${(containerWidth - 1300) / 2}px`
          }"
        ></div>
        <div
          v-for="milestone in milestonesIncomplete"
          :key="milestone.name"
          :class="[
            'snap-start p-4 rounded-md max-w-full sm:max-w-[360px] h-[200px] flex flex-col gap-4 justify-between cursor-ban select-none',
            {
              'bg-surface-100 text-pitch-black': milestone.complete,
              'bg-[#075fff] text-surface-100': !milestone.complete,
              'opacity-75': milestone.locked
            }
          ]"
        >
          <div class="text-xl font-medium leading-none">
            {{ milestone.name }}
          </div>

          <div class="leading-tight opacity-70">
            {{ milestone.desc }}
          </div>

          <div class="flex justify-between items-end">
            <div>
              <div
                v-if="milestone.locked"
                class="gap-2 flex justify-start items-end font-medium leading-none opacity-70"
              >
                <font-awesome-icon icon="lock" /> {{ milestone.lockedMessage }}
              </div>
              <Btn
                :action="milestone.action"
                v-else-if="!milestone.automatic"
                unstyled
                class="gap-1 flex justify-start items-center hover:gap-3 transition-all"
              >
                Get started <font-awesome-icon icon="arrow-right" />
              </Btn>
              <div
                v-else
                class="gap-2 flex justify-start items-end font-medium leading-none opacity-70"
              >
                <font-awesome-icon icon="sparkles" /> Automatic
              </div>
            </div>

            <div class="bg-pitch-black px-2 py-1 text-xs rounded-sm">Incomplete</div>
          </div>
        </div>
      </div>

      <div
        class="relative w-full flex flex-col gap-2 items-center snap-x pb-4 scroll-pl-6 scroll-pr-6 px-4 shrink-0"
      >
        <div
          class="shrink-0 snap-end -mr-2"
          :style="{
            width: `${(containerWidth - 1300) / 2}px`,
            minimumWidth: `${(containerWidth - 1300) / 2}px`
          }"
        ></div>
        <div
          v-for="milestone in milestonesComplete"
          :key="milestone.name"
          :class="[
            'snap-start p-4 rounded-md w-full md:w-[450px] xl:w-[670px] h-16 shrink-0 flex flex-col gap-4 justify-center items-between select-none',
            {
              'bg-surface-100 text-pitch-black': milestone.complete
            }
          ]"
        >
          <div class="text-xl font-medium leading-none flex justify-between items-center">
            {{ milestone.name }}

            <div
              class="leading-tight text-xl font-medium text-blue-print flex justify-start items-center gap-1"
            >
              <font-awesome-icon icon="trophy" class="text-blue-print" size="sm" />
              Complete
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import OnboardingMilestones from '@/components/composables/OnboardingMilestones.js'
import { useStore } from 'vuex'
import FeatureCard from '@/components/ui/FeatureCard.vue'
import BolsterBlackIcon from '@/assets/SVG/Bolster_Icon_Black.svg'
FeatureCard.compatConfig = { MODE: 3 }
import { getQuotesAwaitingPaymentStatesFromApprovals } from '@/apollo-client/requests/approval'

const store = useStore()

const gettingStartedVid = 'https://video.bolsterbuilt.com/gettingstarted.mp4'
const playFull = ref(null)

const name = computed(() => store.state.session.user.user_fname)
const { isMilestoneComplete, completeMilestone } = OnboardingMilestones.useOnboardingMilestones()

const checkComplete = async () => {
  const completions = await Promise.all(
    milestones.value.map((m) => (m.complete ? 1 : m.checkComplete?.() ?? 0))
  )

  for (let i = 0; i < milestones.value.length; i++) {
    // if not already completed, but should be, trigger a completion
    const m = milestones.value[i]
    if (!m.complete && completions[i]) completeMilestone(m)
  }
}

onMounted(() => {
  setTimeout(() => {
    checkComplete()
  }, 1000)
})

function createQuote(type, embue) {
  return store.dispatch('create', {
    type: 'quote',
    embue: { ...embue },
    go: false
  })
}

const vids = [
  'https://video.bolsterbuilt.com/estimating2.mp4',
  'https://video.bolsterbuilt.com/pm.mp4',
  'https://video.bolsterbuilt.com/getpaid.mp4'
  // 'https://video.bolsterbuilt.com/gettingstarted.mp4'
]
const randomVid = computed(() => {
  const randomIndex = Math.floor(Math.random() * vids.length)
  return vids[randomIndex]
})

const openSupportChat = () =>
  store.dispatch('to', `messages/SUPPORT/${store.state.session.company.company_id}`)
const fullContainer = ref(null)
const containerWidth = ref(0)
onMounted(() => {
  containerWidth.value = fullContainer.value.getBoundingClientRect().width
})

const quickActions = ref([
  {
    title: 'View pipeline',
    icon: 'columns-3',
    route: 'pipeline',
    desc: 'Your whole business in one view. See where every opportunity, project and payment is at. Designed just for construction.',
    action: () => store.dispatch('to', 'pipeline')
  },
  {
    title: 'View insights',
    icon: 'chart-simple',
    route: 'metrics',
    desc: 'Get visual insights on the performance of your business. See your metrics and track the value Bolster brings you -- in real-time.',
    action: () => store.dispatch('to', 'metrics')
  },
  {
    title: 'Create new estimate',
    icon: 'file-signature',
    route: 'quotes',
    desc: 'In Bolster, project management is automated thanks to accurate, interactive proposals -- and estimating is easy.',
    action: () => createQuote()
  },
  {
    title: 'Add more users',
    icon: 'users',
    route: 'users',
    desc: `Keep your whole team connected in the office or from their truck. Chat and tasks connected to projects make managing your team a cinch.`,
    action: () => store.dispatch('to', 'users')
  }
])

const startTime = 1733503228

const milestones = computed(() => [
  {
    name: 'Onboarding',
    title: 'Complete onboarding',
    desc: '',
    score: 100,
    time: '30 mins',
    action: () => {},
    complete: 1,
    points: 100
  },
  {
    name: 'Send your first estimate',
    title: 'Send your first estimate',
    desc: 'See how easy it is to build an accurate and interactive estimate for the first time. Guided tutorials and pre-built templates will help you break ground.',
    score: 25,
    time: '10 mins',
    action: () => createQuote(),
    complete: isMilestoneComplete('Send your first estimate'),
    checkComplete: async () =>
      (
        await store.dispatch('ajax', {
          path: `quote/count`,
          data: {
            filters: {
              quote_time_sent: `>${startTime}`
            }
          }
        })
      )?.payload?.count,
    points: 25,
    video: 'https://video.bolsterbuilt.com/estimating2.mp4'
  },
  {
    name: 'Add a lead form',
    title: 'Add a lead form to your website',
    desc: 'Pipe your leads right into Bolster, and track their progress all the way to final payment. Custom forms for your website, or QR codes for print materials.',
    score: 15,
    time: '15 mins',
    complete: isMilestoneComplete('Add a lead form'),
    checkComplete: async () =>
      (
        await store.dispatch('ajax', {
          path: `client/count`,
          data: {
            filters: {
              lead_rotation_id: '!NULL',
              client_time_created: `>${startTime}`
            }
          }
        })
      )?.payload?.count ||
      (
        await store.dispatch('ajax', {
          path: `lead_rotation/count`
        })
      )?.payload?.count,
    action: () => store.dispatch('to', 'lead_rotations'),
    points: 15
  },
  {
    name: 'Book your first job',
    title: 'Book your first job',
    desc: `When you send a quote, your customer will be notified to review and book it. As soon as they approve your quote, this will be marked complete automatically!`,
    score: 150,
    time: 'No time',
    automatic: true,
    complete: isMilestoneComplete('Send payment request'),
    action: () => {}, // learn('paymentrequest'),
    points: 150,
    checkComplete: async () =>
      (
        await store.dispatch('ajax', {
          path: `quote/count`,
          data: {
            filters: {
              quote_status: 'k||f',
              quote_time_booked: `>${startTime}`
            }
          }
        })
      )?.payload?.count,
    video: 'https://video.bolsterbuilt.com/pm.mp4',
    locked: !isMilestoneComplete('Send your first estimate'),
    lockedMessage: 'Send an estimate first'
  },
  {
    name: 'Send payment request',
    title: 'Send payment request',
    desc: 'From a booked project, mark some items as completed and it will send a payment request to your customer -- then this step will automatically be marked complete.',
    score: 150,
    time: '1 min',
    video: 'https://video.bolsterbuilt.com/getpaid.mp4',
    complete: isMilestoneComplete('Send payment request'),
    checkComplete: async () =>
      (
        await getQuotesAwaitingPaymentStatesFromApprovals({
          quoteIds: (
            await store.dispatch('Quote/search', {
              fields: ['quote_id'],
              order: [['quote_id', 'desc']]
            })
          ).set.map((quote) => quote.quote_id),
          userId: store.state.session.company.company_id
        })
      )?.length,
    action: () => store.dispatch('to', 'pipeline'),
    points: 150,
    locked: !isMilestoneComplete('Book your first job'),
    lockedMessage: 'Needs booking first'
  },
  {
    name: 'Receive payment',
    title: 'Receive payment',
    desc: `This is the easiest step to complete -- you don't need to do anything! Which goes to show how easy collecting payments is for you -- now that you're in Bolster.`,
    score: 200,
    time: 'No time',
    checkComplete: async () =>
      (
        await store.dispatch('ajax', {
          path: `invoice/count`,
          data: {
            filters: {
              invoice_time_paid: `>${startTime}`
            }
          }
        })
      )?.payload?.count,
    automatic: true,
    complete: isMilestoneComplete('Send payment request'),
    points: 200,
    locked: !isMilestoneComplete('Send payment request'),
    lockedMessage: 'Send payment request first'
  }
  // {
  //   name: 'Setup Bolster card',
  //   title: 'Setup your Bolster card',
  //   score: 80,
  //   time: '5 mins',
  //   complete: isMilestoneComplete('Setup Bolster card'),
  //   action: () => {},
  //   points: 80
  // }
])

const milestonesIncomplete = computed(() =>
  milestones.value.filter((milestone) => !milestone.complete)
)

const milestonesComplete = computed(() =>
  milestones.value.filter((milestone) => milestone.complete)
)
</script>
