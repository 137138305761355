/**
 * This file is for storing all the client services/features offered in the client portal
 * topDropDown - is for the side panel top context menu options
 * bottomDropDown - is for the side panel bottom context menu options
 *
 * This content was placed here as one central location to change the user type features, services and menu items
 */
const services = ({ store }) => {
  const topDropDown = {
    title: 'Project',
    links: [
      {
        label: 'Switch projects',
        command: () => store.dispatch('to', '/home')
      }
    ]
  }

  const bottomDropDown = {
    title: `${store.state.session.user.user_fname} ${store.state.session.user.user_lname}`,
    links: [
      {
        label: 'Edit profile',
        command: () => store.dispatch('to', 'profile')
      },
      {
        label: 'Switch projects',
        command: () => store.dispatch('to', '/home')
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        command: () => store.dispatch('logout')
      }
    ]
  }

  const features = [
    {
      title: 'Progress',
      icon: 'list',
      to: 'customer/project/progress',
      desc: "Stay on top of construction progress and payments. Easily track project status and manage payments all in one place.'"
    },
    {
      title: 'Preview',
      icon: ['fal', 'bag-shopping'],
      to: 'customer/project/preview',
      desc: 'Easily shop your construction estimate.'
    },
    {
      title: 'Estimate History',
      icon: 'clock-rotate-left',
      to: 'customer/project/history',
      desc: 'Create and manage quotes'
    },
    ...(store.state.session?.company?.aoModules?.invoice === '1'
      ? [
          {
            title: 'Invoices',
            icon: 'file-invoice',
            to: 'customer/project/invoices',
            desc: 'Manage invoices for the project'
          }
        ]
      : [])
  ]
  return {
    features,
    topDropDown,
    bottomDropDown
  }
}

export { services }
