<template>
  <div
    :class="[
      'transition-all absolute inset-0 m-1 rounded-sm cursor-pointer group/bg flex justify-start items-start',
      {
        'pointer-events-none': props.show
      }
    ]"
    @click.stop.prevent="emit('click')"
  >
    <div
      :class="[
        'animate-fadeIn  z-[499] ring-4 absolute inset-0 rounded-sm pointer-events-none',
        {
          'hidden group-hover/bg:flex': !props.show,
          flex: props.show,
          'ring-blue-print-400/80 ': !value,
          'ring-deep-red-400/80 ': value,
          'justify-start flex-row-reverse': props.position === 'right'
        }
      ]"
    >
      <div
        :class="[
          'h-fit w-fit pb-[4px] ',
          {
            'bg-blue-print-400/80 ': !value,
            'bg-deep-red-400/80 ': value,
            'rounded-br-md rounded-tl-sm pr-[4px] ': props.position !== 'right',
            'rounded-bl-md rounded-tr-sm pl-[4px] pb-[4px] ': props.position === 'right'
          }
        ]"
      >
        <div
          class="h-fit bg-surface-600 flex flex-col rounded overflow-hidden cursor-pointer w-8 divide-y divide-surface-300 !pointer-events-auto"
        >
          <template v-if="!props.actions?.length || value || props.image">
            <div
              v-tooltip="'Remove this picture from the theme'"
              :class="[btnClass, 'bg-deep-red-400']"
              @click.stop.prevent="value = null"
              v-if="value"
            >
              <font-awesome-icon icon="trash" />
            </div>
            <div
              v-tooltip="'Change this picture'"
              :class="[btnClass]"
              @click.stop.prevent="selectFile"
            >
              <font-awesome-icon icon="image" />
            </div>
          </template>
          <template v-if="props.actions">
            <div
              v-for="(action, index) in props.actions"
              :key="index"
              v-tooltip="action.tooltip"
              :class="[btnClass, action.class ?? '']"
              @click.stop.prevent="action.action"
            >
              <font-awesome-icon :icon="action.icon" />
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="props.tooltip"
        :class="[
          '!text-white px-2 pb-1 leading-none  h-fit font-medium !pointer-events-auto whitespace-nowrap max-w-full overflow-hidden text-ellipsis',
          {
            'bg-blue-print-400/80 ': !value,
            'bg-deep-red-400/80 ': value,
            'rounded-br-sm mr-2': props.position !== 'right',
            'rounded-bl-sm ml-2': props.position === 'right'
          }
        ]"
      >
        {{ props.tooltip }}
      </div>
    </div>

    <FileList v-show="false" ref="fl" v-model="singleFile" />
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, defineModel, ref } from 'vue'
import FileList from '@/components/ui/FileList.vue'

const value = defineModel()
const props = defineProps(['tooltip', 'actions', 'show', 'position', 'image'])
const emit = defineEmits(['click'])
const fl = ref(null)

const btnClass =
  'p-1 text-white text-sm w-8 h-8 flex items-center justify-center hover:bg-surface-700'

const selectFile = () => fl.value.requestFile()

const singleFile = computed({
  get: () => [value.value],
  set: (val) => {
    value.value = val?.[0]?.file_id ?? null
  }
})
</script>
