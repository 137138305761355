export default {
  data() {
    return {
      initQuoteChannelLoading: true,
      initInvoiceChannelLoading: true
    }
  },
  methods: {
    getChannel(type, typeId) {
      return this.$store.dispatch('activityChat/getChannelByType', {
        channelType: type,
        channelTypeId: typeId
      })
    },
    createChannel(
      type,
      typeId,
      channelName = '',
      clientUserId = null,
      clientFname = null,
      clientLname = null,
      channelStatus = null
    ) {
      const companyId = this.$store.state.session.company.company_id
      return this.$store.dispatch('activityChat/postChannel', {
        companyId,
        channelType: type,
        channelTypeId: typeId,
        channelName: channelName || `${type}: ${typeId}`,
        clientUserId,
        clientFname,
        clientLname,
        channelStatus
      })
    },
    createChildChannel(
      parentChannelId,
      type,
      typeId,
      channelName = '',
      clientUserId = null,
      clientFname = null,
      clientLname = null,
      channelStatus = null
    ) {
      const companyId = this.$store.state.session.company.company_id
      return this.$store.dispatch('activityChat/postChannel', {
        companyId,
        channelType: type,
        channelTypeId: typeId,
        channelName: channelName || `${type}: ${typeId}`,
        parentChannelId: parentChannelId,
        clientUserId,
        clientFname,
        clientLname,
        channelStatus
      })
    },
    addChannelUser(channelId, userId, userFName, userLName, channelType) {
      return this.$store.dispatch('activityChat/postChannelMember', {
        channelId,
        userId,
        userFName,
        userLName,
        channelType
      })
    },
    getChannelUser(channelId, userId) {
      return this.$store.dispatch('activityChat/getChannelMember', {
        channelId,
        userId
      })
    },
    getInvoiceChannel(invoiceId) {
      return this.getChannel('INVOICE', invoiceId)
    },
    getQuoteChannel(quoteId) {
      return this.getChannel('QUOTE', quoteId)
    },
    getCompanyChannel(companyId) {
      return this.getChannel('COMPANY', companyId)
    },
    getQuoteClientChannel(quoteId, clientUserId) {
      return this.getChannel('QUOTE_CLIENT', `${quoteId}-${clientUserId}`)
    },
    createInvoiceChannel(invoiceId, invoiceName) {
      return this.createChannel('INVOICE', invoiceId, invoiceName)
    },
    createQuoteChannel(
      quoteId,
      quoteName,
      clientUserId,
      clientUserFName,
      clientUserLName,
      quoteStatus
    ) {
      return this.createChannel(
        'QUOTE',
        quoteId,
        `${quoteName}`,
        clientUserId,
        clientUserFName,
        clientUserLName,
        quoteStatus
      )
    },
    createCompanyChannel(companyId, companyName) {
      return this.createChannel('COMPANY', companyId, companyName)
    },
    createQuoteClientChannel(
      quoteChannelId,
      quoteId,
      quoteName,
      clientId,
      clientFName,
      clientLName,
      quoteStatus
    ) {
      return this.createChildChannel(
        quoteChannelId,
        'QUOTE_CLIENT',
        `${quoteId}-${clientId}`,
        `${quoteName} (Internal)`,
        clientId,
        clientFName,
        clientLName,
        quoteStatus
      )
    },
    createCompanyCompanyChannel(company1Id, company2Id, companyName) {
      return this.createChannel('COMPANY_COMPANY', `${company1Id}-${company2Id}`, companyName)
    },
    getNotifications(userId, limit, nextToken) {
      return this.$store.dispatch('activityChat/getNotificationsByUser', {
        userId,
        limit,
        nextToken
      })
    },
    getAllNotifications(userId) {
      return this.$store.dispatch('activityChat/getAllNotificationsByUser', {
        userId
      })
    },
    getChannels(userId, limit, nextToken) {
      return this.$store.dispatch('activityChat/getChannelsByUser', {
        userId,
        limit,
        nextToken
      })
    },
    getAllChannels(userId) {
      return this.$store.dispatch('activityChat/getAllChannelsByUser', {
        userId
      })
    },
    getInitChannels(userId) {
      return this.$store.dispatch('activityChat/getInitChannelsByUser', {
        userId
      })
    },
    subscribeToNewChannels(userId, toastService) {
      return this.$store.dispatch('activityChat/subscribeToNewChannels', {
        userId,
        toastService
      })
    },
    unsubscribeToNewChannels() {
      return this.$store.dispatch('activityChat/unsubscribeToNewChannels')
    },
    subscribeToNotifications(userId, toastService) {
      return this.$store.dispatch('activityChat/subscribeToNotifications', {
        userId,
        toastService
      })
    },
    unsubscribeToNotifications() {
      return this.$store.dispatch('activityChat/unsubscribeToNotifications')
    },
    async initInvoiceChannel(userId, userFName, userLName, invoiceId, invoiceName) {
      // get quote channel //
      if (!invoiceId) return
      let invoiceChannel = await this.getInvoiceChannel(invoiceId.toString())
      if (!invoiceChannel) {
        invoiceChannel = await this.createInvoiceChannel(invoiceId.toString(), invoiceName)
      }

      this.initInvoiceChannelLoading = false
    },
    async initQuoteChannel(
      userId,
      userFName,
      userLName,
      quoteId,
      quoteName,
      clientUserId,
      clientUserFName,
      clientUserLName,
      quoteStatus
    ) {
      await Promise.all([
        (async () => {
          if (!quoteId) return
          // get quote channel //
          let quoteChannel = await this.getQuoteChannel(quoteId.toString())
          if (!quoteChannel) {
            quoteChannel = await this.createQuoteChannel(
              quoteId.toString(),
              quoteName,
              clientUserId,
              clientUserFName,
              clientUserLName,
              quoteStatus
            )
          }
        })(),
        (async () => {
          if (!quoteId) return
          // get quote client channel //
          let quoteClientChannel = await this.getQuoteClientChannel(
            quoteId.toString(),
            clientUserId.toString()
          )
          if (!quoteClientChannel) {
            quoteClientChannel = await this.createQuoteClientChannel(
              `QUOTE-${quoteId.toString()}`,
              quoteId.toString(),
              quoteName,
              clientUserId.toString(),
              clientUserFName,
              clientUserLName,
              quoteStatus
            )
          }
        })()
      ])

      this.initQuoteChannelLoading = false
    },
    async initCompanyChannel(companyId, companyName) {
      // get company channel //
      let companyChannel = await this.getCompanyChannel(companyId.toString())
      if (!companyChannel) {
        companyChannel = await this.createCompanyChannel(companyId.toString(), companyName)
      }
    },
    async initActivityNotifications(userId) {
      await this.getAllNotifications(userId)
      await this.subscribeToNotifications(userId)
      await this.subscribeToNewChannels(userId)
    },
    async initActivityChannels(userId) {
      await this.getInitChannels(userId)
    }
  }
}
