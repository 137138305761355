<template>
  <Modal
    ref="modal"
    size="md"
    v-bind="$props"
    :clickAway="true"
    :has-header-border="true"
    :isDirty="bodyIsDirty"
  >
    <template #header> Feature Product </template>
    <template #body>
      <SuperCreateFeatureProductBody
        ref="body"
        :id="productId"
        :name="featureProductName"
        :description="featureProductDescription"
        :stripeId="stripeId"
      />
    </template>
    <template #footer>
      <save-or-cancel
        :isDirty="bodyIsDirty"
        :loading="loading"
        @save="saveClick"
        @cancel="cancelClick"
      />
    </template>
  </Modal>
</template>

<script>
import ObjectModalMixin from './ObjectModalMixin'
import SuperCreateFeatureProductBody from '../bodies/SuperCreateFeatureProductBody.vue'

export default {
  name: 'ModalSuperCreateFeatureProduct',
  mixins: [ObjectModalMixin],
  components: {
    SuperCreateFeatureProductBody
  },
  props: {
    modal: {
      type: Object,
      required: false
    }
  },
  computed: {
    productId() {
      return this.modal.embue.id
    },
    featureProductName() {
      return this.modal.embue.featureProductName
    },
    featureProductDescription() {
      return this.modal.embue.featureProductDescription
    },
    stripeId() {
      return this.modal.embue.stripeId
    }
  }
}
</script>
